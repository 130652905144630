.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  margin-bottom: 0;
  @include media-breakpoint(sm) {
    justify-content: center;

  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    @include media-breakpoint(md) {
       align-items: center;
    }

    &-item {
      display: block;
      margin: 0 4px;

      @include media-breakpoint(md) {
        margin: 0 3px;
      }
      
      &:last-child {
        margin-right: 0;
      }

      &.dots {
        margin: 0;

        .pagination__list-link {
          padding: 0;
          background-color: transparent;
          color: $color-white;
          line-height: 1;
          letter-spacing: 3px;

          .dots {
            display: inline-block;
            width: 6px;
            height: 6px;
            border: 1px solid $color-white;
            border-radius: 50%;
            margin: 0 2px;

            @include media-breakpoint(md) {
              width: 4px;
              height: 4px;
            }
          }
        }
      }

      &.active .pagination__list-link {
        background-color: $color-yellow;
        // color: $color-blue-200;

        &:hover {
          background-color: darken($color-yellow, 10%);
        }
      }
    }

    &-link {
      border: none;
      display: inline-block;
      background-color: $color-white;
      min-width: 30px;
      height: 30px;
      text-align: center;
      padding: 0;
      font-size: 14px;
      font-weight: 700;
      color: $color-blue-700;
      border-radius: $border-radius-md;
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;

      @include media-breakpoint(md) {
        min-width: 26px;
        height: 26px;
        font-size: 12px;
        line-height: 18px;
        padding: 3px 0;
      }

      @include media-breakpoint(md) {
        min-width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 16px;
        padding: 2px 0;
      }

      &:hover {
        background-color: darken($color-white, 10%);
        color: $color-blue-700;
      }

      &.increase-width {
        width: auto;
        padding: 5px 10.6px;
      }
    }

    &-prev,
    &-next {
      border: none;
      display: inline-block;
      background-color: unset;
      border: 1px solid #ffffff;
      border-radius: $border-radius-md;
      font-size: $md;
      font-weight: 400;
      color: $color-white;
      line-height: 22px;
      padding: 3px 8px;
      transition: all 0.3s;
      height: 30px;

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 18px;
        padding: 4px 8px;
      }

      @include media-breakpoint(sm) {
        font-size: 12px;
        line-height: 16px;
      }

      &:not(:disabled):hover {
        background-color: darken($color-blue-100, 5%);
        color: $color-white;
      }

      &:disabled {
        opacity: .26;
      }
    }
  }
}

// New Pagination

.goals-pagination {
  @include flex-box(center, center);
  gap: 0 16px;
  margin-top: 20px;

  .pagesDots {
    @include flex-box(center, center);
    gap: 0 10px;
  }
}

.goals-card-pagination {
  @include flex-box(center, center);
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 19px;

  .splide__pagination {
    position: unset;
    padding: 1px 16px 0px;
    display: flex;
    gap: 10px;

    li {
      width: 16px;
      height: 16px;

      button {

        @-moz-document url-prefix() {
          position: relative;
          top: -4px;
        } 
      }
    }
    
    .goals-pagination-dot {
      width: 16px;
      height: 16px;
      margin: 0;
      background-color: #707070;
      opacity: 1;

      &.is-active {
        background-color: #ffe000;
      }
    }

    .goals-pagination-dot.is-active {
      transform: unset !important;
    }
  }

  .pagination-arrow {
    border: none;
    background: transparent;
  }
  .pagination-arrow:disabled {
    background-color: unset;
    color: unset;
    border-color: unset;
    border: none;

    svg {
      path {
        fill: #707070;
      }
    }
    
  }

  .splide__arrow--prev, .splide__arrow--next {
    position: unset;
    padding: 0;
    
    svg {
      transform: unset;
    }
  }

  .splide__arrow--next {
  }
}
