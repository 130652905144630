.SupportCenter {
  margin-top: 27px;
  position: relative;

  &__Header {
    border-radius: 8px 0px 0px 8px;
    width: 71%;
    // box-shadow: 5px 1px 5px -2px rgba(0, 0, 0, 0.8);
    position: relative;
    z-index: 9;

    @media (max-width: 992px) {
      width: 100%;
      border-radius: 8px 8px 0px 0px;
    }

    // .shadow-svg {
    //     position: absolute;
    //     right: 0px;
    //     top: -3px;
    // }

    &__Assigneedropdown {
      .assessment-selects__menu {
        z-index: 2;

        .assessment-selects__option {
          line-height: normal;
        }
      }
      .assessment-selects__control {
        @media (max-width: 1180px) {
          width: 128px;
        }
        @media (max-width: 420px) {
          width: 210px;
        }
      }
    }
    &__Statusdropdown {
      .assessment-selects__control {
        @media (max-width: 1180px) {
          width: 128px;
        }
        @media (max-width: 420px) {
          width: 210px;
        }
      }
    }
    &__titleRow {
      height: 60px;
      border-top-right-radius: 8px;
      padding: 10px 30px 10px 14px;

      @media (max-width: 1180px) {
        padding: 10px;
      }
      @media (max-width: 992px) {
        padding: 0px;
        flex-wrap: wrap;
        height: auto;
      }

      .backArrowImage {
        height: 40px;
        width: 40px;

        @media (max-width: 1180px) {
          height: 32px;
          width: 30px;
        }
      }

      .title {
        font-size: 22px;
        color: #fff;
        margin-left: 0px;
        font-weight: 700;
        font-family: $font-openSans;
        line-height: normal;
        margin-bottom: 1px;

        @media (max-width: 1180px) {
          font-size: 18px;
          margin-left: 4px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

      .SupportCenter__Header__Assigneedropdown {
        @media (max-width: 550px) {
          width: 49%;
        }

        @media (max-width: 480px) {
          width: 100%;
          justify-content: flex-end;
          padding-right: 10px;
        }

        @media (max-width: 375px) {
          padding-right: 0;
        }

        .assessment-selects__wrapper {
          z-index: 99;
        }

        .assessment-selects__wrapper.assignee-zIndex {
          z-index: 999;
        }

        @media (max-width: 550px) {
          span {
            width: 70px;
          }

          .assessment-selects__wrapper {
            span {
              width: auto;
            }
          }
        }
      }

      .SupportCenter__Header__Statusdropdown {
        @media (max-width: 550px) {
          width: 49%;
        }

        @media (max-width: 550px) {
          span {
            width: 70px;
          }

          .assessment-selects__wrapper {
            span {
              width: auto;
            }
          }
        }

        @media (max-width: 480px) {
          width: 100%;
          justify-content: flex-end;
          padding-right: 10px;
        }

        @media (max-width: 375px) {
          padding-right: 0;
        }
      }

      .newTicketBtn {
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: $border-radius-md;
        outline: none;
        color: white;
        font-size: 16px;
        height: 33px;
        width: 138px;

        @media (max-width: 420px) {
          width: 100%;
        }
      }

      .reload-icon {
        width: 39px;
        height: 33px;
        background-color: #b9b9b9;
        border-radius: 9px;

        .reloadPage {
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    &__stats {
      padding: 4px 43px;
      font-weight: 700;
      margin-bottom: 0px;
      border-bottom: 1px solid $theme-border-color;
      border-top: 1px solid $theme-border-color;
      @media (max-width: 1180px) {
        padding: 5px 25px;
      }
    }
    &__ticketInfo {
      padding: 11px 43px;

      @media (max-width: 1180px) {
        padding: 15px 25px;
      }
      @media (max-width: 768px) {
        padding: 0px;
        flex-wrap: wrap;
      }

      @media (max-width: 600px) {
        display: block !important;
        padding-bottom: 12px;
      }

      .ticket-stats {
        @media (max-width: 767px) {
          width: 49%;
          margin: 0 !important;
          padding-left: 25px;
          justify-content: flex-start;
          margin-top: 12px !important;
          padding: 0 25px;
        }

        @media (max-width: 600px) {
          width: 100%;
        }
      }

      .infoText {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-right: 12px;
        width: 138px;
        text-align: center;
        margin-bottom: 0px;

        @media (max-width: 1180px) {
          font-size: 16px;
        }

        @media (max-width: 767px) {
          width: 100%;
          max-width: 150px;
          text-align: left;
        }

        @media (max-width: 600px) {
          max-width: 250px;
        }

        @media (max-width: 520px) {
          text-align: start;
          width: auto;
          order: 2;
          margin-right: 0;
          margin-left: 20px;
          min-width: 175px;
        }
      }
      .infoNumber {
        height: 70px;
        width: 70px;
        background-color: #ffed7f;
        font-size: 32px;
        font-weight: 700;
        border-radius: 50%;
        @media (max-width: 1180px) {
          font-size: 22px;
          height: 50px;
          width: 50px;
        }
      }
    }
  }
  &__status {
    border-radius: 0px 8px 8px 0px;
    padding: 20px 26px 13px 14px;
    width: 28.888%;
    position: relative;

    @media (max-width: 1180px) {
      padding: 15px;
    }
    @media (max-width: 992px) {
      width: 100%;
      border-radius: 0px 0px 8px 8px;
    }

    .shadow-svg {
      position: absolute;
      left: -11px;
      top: -2px;
      opacity: 0.7;
      height: auto;
    }
    &__heading {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 13px;
    }
    &__progress {
      .progressElements {
        margin-bottom: 6px;
        .textprogress {
          margin-bottom: 0px;
          width: 90px;
          text-align: end;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;

          @media (max-width: 1400px) {
            font-size: 12px;
          }
          @media (max-width: 1180px) {
            width: 130px;
          }
        }
        &__progressBars {
          width: 200px;
          height: 18px;
          position: relative;
          margin-left: 15px;
          @media (max-width: 992px) {
            width: 100%;
          }
          span {
            width: 100%;
          }
          .SubmittedBar {
            background-color: #eadb4a;
            box-shadow: 2px 2px 3px #00000029;
            border-radius: 0px 80px 80px 0px;
            height: 18px;
            position: absolute;
          }
          .ProgressBar {
            background-color: #194792;
            box-shadow: 2px 2px 3px #00000029;
            border-radius: 0px 80px 80px 0px;
            height: 18px;
            position: absolute;
          }
          .MoreInfo {
            background-color: #c44538;
            box-shadow: 2px 2px 3px #00000029;
            border-radius: 0px 80px 80px 0px;
            height: 18px;
            position: absolute;
          }
          .Resolved {
            background-color: #46afb8;
            box-shadow: 2px 2px 3px #00000029;
            border-radius: 0px 80px 80px 0px;
            height: 18px;
            position: absolute;
          }
        }
      }
    }

    &__footer {
      margin-top: 6px !important;

      .activeTime {
        p {
          margin-right: 10px;
          cursor: pointer;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          @media (max-width: 1440px) {
            font-size: 12px;
          }
        }

        .active {
          font-weight: 800;
        }
      }
      .centeredElement {
        width: 48%;
        justify-content: space-between;
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          font-family: "Cabin", sans-serif;
          // @media(max-width: 1440px) {
          //     font-size: 12px;
          // }
        }
      }
      .maxElement {
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          font-family: "Cabin", sans-serif;
          // @media(max-width: 1440px) {
          //     font-size: 12px;
          // }
        }
      }
    }
  }
  .SupportTicket {
    margin-top: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: $border-radius-md;
    // background-color: #537AB7;
    &__header {
      border-radius: 8px 8px 0px 0px;
      padding-right: 19px;

      .section-search-field {
        @media (max-width: 480px) {
          justify-content: flex-end;
          width: 100%;
        }
      }

      .css-13cymwt-control {
        padding-top: 1px;
        padding-left: 2px;
      }
    }

    .borderRadius {
      border-radius: $border-radius-md;
    }

    &__table {
      min-width: 1080px;
      &__thead {
        height: 30px;

        tr:first-child td:first-child {
          border-top-left-radius: 8px; /* Top-left corner */
        }

        tr:first-child td:last-child {
          border-top-right-radius: 8px; /* Top-right corner */
        }

        td {
          background-color: #b9b9b9;
          color: #323233;
          font-weight: 700;
          padding: 0 19px;
        }

        .SupportTicketCircle {
          width: 20px;
        }

        .summary {
          width: 380px;
          padding-left: 0px;
        }

        .lastUpdate,
        .createdTime {
          width: 168px;
        }

        .submitter {
          width: 140px;
        }

        .SupportTicketstatus {
          width: 165px;
        }

        .assignee {
          width: 170px;
        }
      }

      &__tbody {
        tr {
          height: 37px;

          &:last-child{

            td {
              &::before {
                display: none;
              }
            }

            .SupportTicket__table__tbody__status {

              .assessment-selects__wrapper {
                
                .assessment-selects__menu {
                  margin-top: -53px !important;
                }
              }
            }
          }
          td {
            background-color: #b9b9b9;
            color: $color-gray-950;
            position: relative;
            padding: 7px 13px 7px 16px;

            div {
              p {
                color: $color-gray-950;
              }
            }

            &::before {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              max-width: 1141px;
              height: 1px;
              background-color: $color-gray;
            }
          }

          td:first-child {
            &::before {
              width: calc(100% - 19px);
              right: 0;
              left: unset;
            }
          }

          td:last-child {
            &::before {
              width: calc(100% - 19px);
            }
          }

          .assignee-name {
            position: relative;

            p {
              width: 170px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        tr:last-child {
          td:first-child {
            border-radius: 0px 0px 0px 8px;
          }
          td:last-child {
            border-radius: 0px 0px 8px 0px;
          }

          .notfound__data {
            border-radius: 0px 0px 8px 8px !important;
          }
        }

        .summary {
          padding-left: 0px;
        }

        .redCircle {
          height: 16px;
          width: 16px;
          background-color: #c44538;
          border-radius: 50%;
          margin: 0;
          margin-right: 10px;
          box-shadow: 1px 1px 0px #00000029;
        }

        .yellowCircle {
          height: 16px;
          width: 16px;
          background-color: #ffc107;
          border-radius: 50%;
          margin: 0;
          margin-right: 10px;
          box-shadow: 1px 1px 0px #00000029;
        }

        &__status {
          position: relative;
          .Subbmitted,
          .InProgress,
          .NeedMoreInfo,
          .Resolved {
            background-color: #eadb4a;
            margin-bottom: 0px;
            color: #424242;
            width: auto;
            max-width: 115px;
            border-radius: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            padding: 0 12px;
            padding-top: 1px;
            display: inline-block;
          }

          .InProgress {
            background-color: #194792;
            color: #fff;
          }

          .NeedMoreInfo {
            background-color: #c44538;
            color: #fff;
            width: auto;
            max-width: 137px;
          }

          .Resolved {
            background-color: #46afb8;
            color: #fff;
          }

          .dropdown {
            margin-left: 0;
            .assessment-selects__control {
              width: 95px;
              background-color: #c44538;
              border: none !important;
              border-radius: 40px !important;
              min-height: 23px !important;

              .assessment-selects__value-container {
                .assessment-selects__placeholder {
                  color: #ffffff;
                  font-size: 14px;
                  padding-left: 6px;
                }

                .assessment-selects__single-value {
                  font-size: 14px !important;
                  color: #ffffff !important;
                  padding-left: 6px;
                }
              }

              .assessment-selects__indicators {
                background-image: url("../../../assets/img/white-down-arrow.svg");
                background-position: 0 8px;
                .assessment-selects__indicator {
                  padding-left: 15px;
                }
              }
            }

            .assessment-selects__menu {
              width: 95px;
            }
            
          }
        }
      }
    }
  }

  .chatSessionTable {
    table {
      min-width: 1160px;
      thead {
        .admin {
          width: 185px;
          padding-left: 0px;
        }
        .districs {
          width: 290px;
        }
        tbody {
          :last-child {
            :first-child {
              border-radius: $border-radius-md;
            }
          }
        }
      }
    }
  }
  &__ChatImage {
    position: absolute;
    right: 90px;
    z-index: 99;
    bottom: 1px;

    @media (max-width: 576px) {
      right: 30px;
    }

    .chat-box {
      border-radius: 50%;
      height: 101px;
      width: 101px;
      left: 8px;
      top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .userCounter {
        color: #fff;
        font-size: 17px;
        font-family: "Nunito", sans-serif;
        text-align: center;
      }
    }

    @media print {
      display: none;
    }

    img {
      width: 100%;
    }

    > .chatDot {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 45px;
      left: 90.6px;
      z-index: 99;
    }

    .active {
      background-color: #46afb8;
    }

    .inactive {
      background-color: #c44538;
    }

    .yellow {
      background-color: #ffed7f;
    }

    .chat-box-btn {
      @include flex-box(center, center);
      gap: 11px;
      width: 177px;
      height: 46px;
      border-radius: 40px;
      background-color: $theme-bg-color-chaticon;

      &:focus-visible {
        background-color: white;
      }

      .chatDot {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        display: block;
        z-index: 99;
      }

      .active {
        background-color: #46afb8;
      }

      .inactive {
        background-color: #c44538;
      }

      .yellow {
        background-color: #ffed7f;
      }

      .chat-btn-text {
        font-size: $md + 1;
        font-weight: 700;
        color: white;
        font-family: $font-openSans;
        padding-top: 1px;
      }

      svg {
        width: 18.8px;
        height: 12px;
        margin-top: 1px;
      }
    }
  }
}

.showChatBox {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: flex-end;
  .chatBox {
    background-color: #578dde;
    position: fixed;
    bottom: -7px;
    z-index: 9999;
    width: 100%;
    max-width: 1242px;
    height: auto;
    padding: 14px 32px 45px 31px;
    border-radius: 8px 8px 0px 0px;
    -webkit-box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
    box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
    left: 0;
    right: 0;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 16px;
      max-width: 552px;
    }
    @media (max-width: 576px) {
      padding: 8px;
      max-width: calc(100% - 25px);
    }

    .chatBox__header {
      position: relative;
      left: -12px;
      margin-bottom: 32px !important;

      @media (max-width: 576px) {
        left: 0;
      }

      .chatbox-chatIcon {
        padding-top: 6px;
        position: relative;
        right: -25px;

        @media (max-width: 576px) {
          right: 0;
        }
      }
    }

    &__dropDownIcon {
      rotate: 180deg;
      img {
        height: 40px;
        width: 40px;
        position: relative;
        top: 11px;

        @media (max-width: 576px) {
          display: none;
        }
      }
    }
    &__alphaHeading {
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }

    &__notifications {
      background-color: white;
      height: 473px;
      border-radius: $border-radius-md;
      margin-top: 8px;
      display: flex;
      flex-direction: column-reverse;
      padding: 0 26px 6px 13px;
      overflow-y: auto;
      scrollbar-width: 2px;
      position: relative;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        width: 2px;
        border-radius: 100px;
        background-color: gray;
      }

      @media (max-width: 767px) {
        height: 350px;
      }

      @media (max-width: 575px) {
        height: 325px;
      }

      @media (max-height: 770px) {
        height: 250px;
      }

      @media (max-height: 640px) {
        height: 180px;
      }

      @-moz-document url-prefix() {
        @media (max-width: 767px) {
          height: 350px;
        }

        @media (max-width: 575px) {
          height: 325px;
        }

        @media (max-height: 640px) {
          height: 180px;
        }
      }

      .expire-chat {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(1px);

        .chat-unavailable {
          width: 100%;
          max-width: 517px;
          margin: 0 auto;
          background-color: #424242;
          box-shadow: (0px 3px 6px rgba(0, 0, 0, 0.565));
          border-radius: $border-radius-md;
          padding: 8px 18px 10px 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 110px;
          margin: auto;

          span {
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            color: white;
          }

          .create-supportTicket {
            background-color: #40afb9;
            padding: 3.5px 16px 2.5px 17px;
            border-radius: 41px;
            cursor: pointer;
            margin: 5px auto 0px;
            display: block;
            width: 100%;
            max-width: 233px;
          }
        }
      }

      &__box {
        height: 100%;
      }

      &__name {
        width: 46px;
        height: 42px;
        background-color: #c44538;
        border-radius: $border-radius-md;
        margin-right: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: white;

        @media (max-width: 767px) {
          width: 38px;
          height: 26px;
          border-radius: $border-radius-md;
          font-size: 12px;
        }
      }

      &__name2 {
        width: 46px;
        height: 42px;
        background-color: #537ab7;
        border-radius: $border-radius-md;
        margin-right: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: white;

        @media (max-width: 767px) {
          width: 38px;
          height: 26px;
          border-radius: $border-radius-md;
          font-size: 12px;
        }
      }

      &__time {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #919191;
        margin-bottom: 0;
        width: 85px;
        text-align: right;
        margin-left: 10px;

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &__messageWidth {
        width: calc(100% - 55px);
        display: flex;

        @media (max-width: 767px) {
          width: 82%;
        }
      }

      &__message {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #424242;
        margin-bottom: 0;
        width: calc(100% - 95px);

        @media (max-width: 991px) {
          font-size: 15px;
          line-height: 18px;
        }

        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .no-chat-img {
        width: 46px;
        height: 42px;
        margin-right: 9px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin: auto;
        }
      }

      .text {
        letter-spacing: 0px;
        color: #424242;
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        padding-top: 2px;
        margin-left: 0 !important;
      }
    }

    .chatbox-typing {
      display: inline-flex;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: white;
        margin: 0;
        background-color: #919191;
        padding: 3px 14px 3px 18px;
        border-radius: 24px;
        position: relative;
        top: -7px;
      }
    }

    &__notification {
      background-color: #fff;
      height: 473px;
      width: 100%;
      border-radius: $border-radius-md;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        height: 350px;
      }

      @media (max-width: 575px) {
        height: 325px;
      }

      @media (max-height: 770px) {
        height: 250px;
      }

      @media (max-height: 640px) {
        height: 180px;
      }

      div {
        background-color: #ffed7f;
        padding: 10px 17px 7px;
        border-radius: 24px;
        box-shadow: (0px 3px 6px rgba(0, 0, 0, 0.565));

        @media (max-width: 768px) {
          padding: 5px 12px;
        }

        @media (max-width: 576px) {
          width: 90%;
          margin: 0 auto;
          padding: 4px 8px;
        }

        p {
          color: #131313;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 0;

          @media (max-width: 768px) {
            font-size: 14px;
            padding: 5px;
          }
          @media (max-width: 576px) {
            font-size: 12px;
            text-align: center;
            margin-left: 0px;
            margin-right: 0px;
            padding: 0;
          }

          @media (max-width: 400px) {
            font-size: 9px;
          }
        }
      }

      .chat-unavailable {
        width: 100%;
        max-width: 517px;
        margin: 0 auto;
        background-color: #424242;
        box-shadow: (0px 3px 6px rgba(0, 0, 0, 0.565));
        border-radius: 41px;
        padding: 6.5px 5px 4.5px 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          color: white;
          margin-right: 10px;
        }

        .create-supportTicket {
          background-color: #40afb9;
          padding: 3.5px 16px 2.5px 17px;
          border-radius: 41px;
          cursor: pointer;
          margin-right: 0;
        }
      }
    }

    &__alphaHeading {
      font-family: $font-openSans;
    }
    &__backTime {
      position: relative;
      margin-top: 3px;
      margin-left: 4px;
      span {
        content: "";
        height: 20px;
        width: 20px;
        position: absolute;
        border-radius: 50%;

        @media (max-width: 576px) {
          height: 16px;
          width: 16px;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        margin-left: 30px !important;
      }

      .active {
        background-color: #46afb8;
        color: white;
      }

      .not-active {
        background-color: #eadb4a;
      }

      .unavailable {
        background-color: red;
      }

      .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
      }

      .chat-status-text {
        width: 84px;
        height: 19px;
        margin-left: 32px;
        border-radius: $border-radius-md;
      }
    }

    .chat-skeleton-header {
      animation: skeleton-loading 1s linear infinite alternate;
    }

    @keyframes skeleton-loading {
      0% {
        background-color: #686c7732;
      }
      100% {
        background-color: #c4d8f743;
      }
    }
    &__inputField {
      textarea {
        width: 100%;
        height: 68px;
        padding: 10px;
        outline: none;
        border: none;
        border-radius: $border-radius-md;
        font-size: 18px;
        resize: none;

        @media (max-width: 576px) {
          height: 45px;
          padding: 6px;
          font-size: 12px;
        }
      }
    }
    &__SendBtn {
      display: flex;
      justify-content: flex-end;
      &__btn {
        width: auto;
        height: 33px;
        background-color: #46afb8;
        border-radius: $border-radius-md;
        outline: none;
        border: none;
        color: #ffffff;
        margin-top: 10px;
      }

      &__btn:disabled {
        opacity: 0.4;
        cursor: default;
        background-color: #46afb8;
      }
    }
  }
}

.showAdminChatBox .chatBox__centerBtns {
  max-width: 700px;
  width: 100%;
}

.showAdminChatBox {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: flex-end;

  .chatBox {
    background-color: #578dde;
    position: fixed;
    bottom: 0;
    z-index: 999999;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    height: auto;
    right: 0;
    left: 0;
    padding: 19px 30px 46px 30px;
    border-radius: 8px 8px 0px 0px;

    @media (max-width: 1260px) {
      max-width: 97.77%;
    }

    .mob-spacing {
      @media (max-width: 767px) {
        padding: 0 16px;
      }

      @media (max-width: 575px) {
        padding: 0 8px;
      }
    }

    .chatbox-chatIcon {
      position: relative;
      top: -9px;
    }

    &__inputField {
      @media (max-width: 767px) {
        padding: 0 16px;
      }

      @media (max-width: 575px) {
        padding: 0 8px;
      }
    }

    .downArrow {
      position: relative;
      top: -8px;
      left: -15px;
      margin-right: 0;

      @media (max-width: 767px) {
        left: -7px;
      }
    }

    .availableDropdown {
      width: 100%;
      max-width: 175px;
      height: 26px;
      margin-left: 15px;
      position: relative;
      background-color: #46afb8;
      border-radius: 40px;
      font-size: 14px;
      font-weight: 400;
      color: white;
      border: 0;
      outline: none;

      @media (max-width: 575px) {
        margin-left: 0px;
        margin-top: 16px;
      }

      select {
        background-color: #46afb8;
        border-radius: 13px;
        font-size: 14px;
        font-weight: 400;
        color: white;
        width: 100%;
        padding: 4px 35px 3px 17px;
        border: 0;
        outline: none;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../../img/down-arrow-white.png");
        background-repeat: no-repeat;
        background-size: 11px;
        background-position: right 16px top 11px;
      }

      img {
        position: absolute;
        top: 11px;
        right: 16px;
        width: 11px;
      }
    }

    .availableDropdown_back_in {
      background-color: #ffc107;

      select {
        background-color: #ffc107;
        color: #424242;
        background-image: url("../../img/drop-down-select-black.svg");
      }
    }

    .availableDropdown_unavailable {
      background-color: #c44538;

      select {
        background-color: #c44538;
        color: white;
      }
    }

    .inputFeild {
      margin-top: 16px;
    }

    .info {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1.5px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: white;
      margin-left: 14px;

      @media (max-width: 575px) {
        margin-top: 16px;
      }
    }

    &__showProfiles {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      height: 82px;

      &__profile {
        width: 100%;
        max-width: 200px;
        border-radius: $border-radius-md;
        cursor: pointer;
        position: relative;
        @media (max-width: 767px) {
          max-width: 150px;
        }

        &__headerActive {
          background-color: #eadb4a;
          border-radius: 8px 8px 0px 0px;
          padding-top: 6px;
          padding-bottom: 4px;
          padding-left: 19px;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #424242;
          margin-bottom: 0px;

          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        &__header {
          background-color: #424242;
          border-radius: 8px 8px 0px 0px;
          padding-top: 6px;
          padding-bottom: 4px;
          padding-left: 19px;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: white;
          margin-bottom: 0px;

          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        &__header-red {
          background-color: #c44538;
          border-radius: 8px 8px 0px 0px;
          padding-top: 6px;
          padding-bottom: 3px;
          padding-left: 19px;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          color: white;
          margin-bottom: 0px;

          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .flashName {
          animation: blinker 0.5s linear infinite;
          animation-iteration-count: 2;
          color: red;
        }

        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }

        &__bodyActive {
          background-color: $theme-bg-color-dark;
          padding-left: 19px;
          padding-right: 15px;
          padding-top: 6px;
          padding-bottom: 5px;
          border-radius: 0px 0px 8px 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 767px) {
            padding-left: 10px;
            padding-right: 12px;
          }

          &__nameActive {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: white;
            margin-bottom: 0px;
            width: 121px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 16px;
              width: 90px;
            }
          }
          &__name2Active {
            font-size: 16px;
            font-weight: 800;
            line-height: 22px;
            color: white;
            margin-bottom: 0px;
            margin-top: 1px;
            width: 121px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 16px;
              width: 90px;
            }
          }
          &__timeActive {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: white;
            margin-bottom: 0px;

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
        &__notifications {
          background-color: white;
          height: 420px;
          border-radius: $border-radius-md;
          margin-top: 8px;
          display: flex;
          flex-direction: column-reverse;
          padding: 0 13px;
          overflow-y: auto;

          &__box {
            height: 100%;
          }

          &__name {
            width: 46px;
            height: 42px;
            background-color: #c44538;
            border-radius: $border-radius-md;
            margin-right: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: white;

            @media (max-width: 767px) {
              width: 38px;
              height: 26px;
              border-radius: $border-radius-md;
              font-size: 12px;
            }
          }

          &__name2 {
            width: 46px;
            height: 42px;
            background-color: #537ab7;
            border-radius: $border-radius-md;
            margin-right: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: white;

            @media (max-width: 767px) {
              width: 38px;
              height: 26px;
              border-radius: $border-radius-md;
              font-size: 12px;
            }
          }

          &__time {
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            color: #919191;
            margin-bottom: 4px;

            @media (max-width: 767px) {
              font-size: 14px;
              line-height: 18px;
            }
          }

          &__messageWidth {
            width: 100%;

            @media (max-width: 767px) {
              width: 82%;
            }
          }

          &__message {
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            color: #424242;
            margin-bottom: 0;

            @media (max-width: 991px) {
              font-size: 15px;
              line-height: 18px;
            }

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
        &__body {
          background-color: #b9b9b9;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-left: 19px;
          padding-right: 15px;
          border-radius: 0px 0px 8px 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 767px) {
            padding-left: 10px;
            padding-right: 12px;
          }

          &__name {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #424242;
            margin-bottom: 0px;
            width: 121px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 16px;
              width: 90px;
            }
          }
          &__name2 {
            font-size: 16px;
            font-weight: 800;
            line-height: 22px;
            color: #424242;
            margin-bottom: 0px;
            margin-top: 1px;
            width: 121px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
          &__time {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #424242;
            margin-bottom: 0px;

            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        &__messages {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          background-color: #c44538;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 400;
          color: white;
          position: absolute;
          top: -12px;
          right: -12px;
        }

        &__close {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          background-color: #424242;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 17px;
          color: white;
          position: absolute;
          top: -12px;
          left: -12px;
        }

        .animate-wrapper {
          display: block;
          padding-right: 4px;
          text-align: left;
        }
      }

      .noActiveChat {
        opacity: 0.4;
        .chatBox__showProfiles__profile__header {
          height: 29px;
        }

        .chatBox__showProfiles__profile__body {
          height: 53px;
        }
      }

      .disableBox {
        opacity: 0.5;

        .disableBody {
          height: 53px;
        }
      }

      &__moreProfiles {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: white;
        padding-left: 30px;
        padding-bottom: 15px;

        @media (max-width: 767px) {
          padding-left: 15px;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 6;
        }
      }
    }

    &__notifications {
      background-color: white;
      height: 420px;
      border-radius: $border-radius-md;
      margin-top: 8px;
      display: flex;
      flex-direction: column-reverse;
      padding: 0 26px 6px 13px;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: rgba($color-black, 0.12);
      }

      @media (max-width: 767px) {
        height: 350px;
      }

      @media (max-width: 575px) {
        height: 325px;
      }

      @media (max-height: 770px) {
        height: 250px;
      }

      @media (max-height: 640px) {
        height: 180px;
      }

      &::-webkit-scrollbar {
        width: 12px;

        @include media-breakpoint(lg) {
          width: 8px;
        }

        @include media-breakpoint(md) {
          width: 6px;
        }
      }

      &::-webkit-scrollbar-thumb {
        width: 8px;
        border-radius: 10px;
        background-color: rgba($color-black, 0.23);

        @include media-breakpoint(lg) {
          width: 6px;
          border: 1px solid rgba($color-blue-100, 0.4);
        }

        @include media-breakpoint(md) {
          width: 4px;
        }
      }

      &__box {
        height: 100%;
      }

      &__name {
        width: 46px;
        height: 42px;
        background-color: #c44538;
        border-radius: $border-radius-md;
        margin-right: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: white;

        @media (max-width: 767px) {
          width: 38px;
          height: 26px;
          border-radius: $border-radius-md;
          font-size: 12px;
        }
      }

      &__name2 {
        width: 46px;
        height: 42px;
        background-color: #537ab7;
        border-radius: $border-radius-md;
        margin-right: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: white;

        @media (max-width: 767px) {
          width: 38px;
          height: 26px;
          border-radius: $border-radius-md;
          font-size: 12px;
        }
      }

      &__time {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #919191;
        margin-bottom: 0px;
        width: 85px;
        margin-left: 10px;
        text-align: right;

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &__messageWidth {
        width: 100%;
        max-width: calc(100% - 55px);
        display: flex;

        @media (max-width: 767px) {
          width: 82%;
        }
      }

      &__message {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #424242;
        margin-bottom: 0;
        width: calc(100% - 95px);

        @media (max-width: 991px) {
          font-size: 15px;
          line-height: 18px;
        }

        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .noChat {
        padding-bottom: 5px;

        .no-chat-img {
          width: 46px;
          height: 42px;
          margin-right: 9px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin: auto;
          }
        }

        .text {
          letter-spacing: 0px;
          color: #424242;
          font-family: "Nunito", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          padding-top: 2px;
          margin-left: 0 !important;
        }
      }

      .chatbox-chatSkeleton {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 0px;

        .profile {
          width: 46px;
          height: 42px;
          border-radius: $border-radius-md;
          margin-right: 9px;
          animation: skeleton-loading 1s linear infinite alternate;
        }

        .text {
          width: calc(100% - 55px);
          height: 46px;
          border-radius: $border-radius-md;
          animation: skeleton-loading 1s linear infinite alternate;
        }
      }

      @keyframes skeleton-loading {
        0% {
          background-color: #686c7732;
        }
        100% {
          background-color: #c4d8f743;
        }
      }
    }

    &__actionBtns {
      padding: 6px 22px 5px 22px;
      background-color: $theme--bg-color-seaGreen;
      font-size: 16px;
      font-weight: 400;
      color: white;
      border-radius: $border-radius-md;
      cursor: pointer;
      // outline: none;
      border: unset;

      @media (max-width: 1279px) {
        padding: 6px 15px;
      }

      @media (max-width: 1260px) {
        font-size: 14px;
        padding: 6px 10px;
      }

      @media (max-width: 1023px) {
        font-size: 12px;
      }

      @media (max-width: 767px) {
        padding: 4px 10px;
      }
    }

    .quick_response_btn {
      width: 100%;
      max-width: 220px;

      @media (max-width: 1260px) {
        max-width: 170px;
      }
    }

    &__actionBtns:disabled {
      opacity: 0.4;
      cursor: default;
      background-color: $theme--bg-color-seaGreen-light;
    }

    &__centerBtns {
      // background-color: #194792;
      border-radius: $border-radius-md;
      margin-left: 43px;
      margin-right: 43px;
      justify-content: center;

      @media (max-width: 1180px) {
        margin-left: 20px;
        margin-right: 20px;
        max-width: 580px;
      }

      @media (max-width: 991px) {
        max-width: 385px;
        margin-left: 10px;
        margin-right: 10px;
      }

      @media (max-width: 767px) {
        margin-top: 8px;
      }

      &__btn {
        padding: 6px 10px 5px 10px;
        font-size: 16px;
        font-weight: 800;
        color: white;
        border: 0px;
        background-color: $theme-bg-color;
        text-align: center;
        position: relative;

        &:last-child {
          border-radius: 0px 5px 5px 0px;
          width: 100%;
          max-width: 115px;
        }

        @media (max-width: 1279px) {
          padding: 6px 12px;
        }

        @media (max-width: 1023px) {
          font-size: 14px;
        }

        @media (max-width: 1023px) {
          font-size: 12px;
        }

        @media (max-width: 767px) {
          padding: 4px 10px;
        }
      }

      .sent-info {
        border-radius: 5px 0px 0px 5px;
        width: 100%;
        font-weight: 400;
        background-color: #424242;
        max-width: initial;
        width: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .deleteBtn {
        background-color: #194792;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
          outline: none;
          border: none;
          height: 17px;
          font-size: 12px;
          border-radius: 40px;
          margin-left: 15px;
          background-color: #c44538;
          min-width: 100px;
          padding: 0px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white !important;
          font-weight: 400;
          line-height: 14px;
          font-family: "Nunito", sans-serif;

          &:hover {
            color: white;
          }
        }
      }

      &__btn:focus {
        background-color: #424242;
        font-weight: 400;
      }
    }

    @media (max-width: 767px) {
      padding: 16px 0;
      max-width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    @media (max-width: 576px) {
      max-width: calc(100% - 24px);
    }

    &__dropDownIcon {
      rotate: 180deg;
      img {
        height: 40px;
        width: 40px;
        position: relative;
        top: 20px;

        @media (max-width: 576px) {
          display: none;
        }
      }
    }
    &__alphaHeading {
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }

    &__notification {
      background-color: #fff;
      height: 268px;
      width: 100%;
      border-radius: $border-radius-md;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        background-color: #ffed7f;
        color: #131313;
        padding: 5px 8px;
        border-radius: $border-radius-md;
        font-weight: 700;
        font-size: 18px;
        text-shadow: 0px 2px 6px #00000090;

        @media (max-width: 768px) {
          font-size: 14px;
          padding: 5px;
        }
        @media (max-width: 576px) {
          text-align: center;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
    &__backTime {
      position: relative;
      margin-top: 3px;
      padding-left: 3px;
      width: auto;
      max-width: 190px;
      height: 20px;
      span {
        content: "";
        background-color: #46afb8;
        height: 20px;
        width: 20px;
        position: absolute;
        border-radius: 50%;

        @media (max-width: 576px) {
          height: 16px;
          width: 16px;
        }
      }
      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 0px;
        margin-left: 32px;
        cursor: pointer;
      }

      .chatStatusText_default {
        color: #46afb8;
      }
      .chatStatusText_yellow {
        color: #ffc107;
      }
      .chatStatusText_red {
        color: #c44538;
      }

      .redCircle {
        background-color: #c44538;
        content: "";
        height: 20px;
        width: 20px;
        position: absolute;
        border-radius: 50%;

        @media (max-width: 576px) {
          height: 16px;
          width: 16px;
        }
      }

      .yellowCircle {
        background-color: #ffc107;
        content: "";
        height: 20px;
        width: 20px;
        position: absolute;
        border-radius: 50%;

        @media (max-width: 576px) {
          height: 16px;
          width: 16px;
        }
      }

      .AvailableDropdown {
        margin-left: 26px;
        border: none;
        background: transparent;
        color: white;

        &:focus-visible {
          outline: none;
          border: none;
        }

        option {
          color: #fff;
          background-color: #537ab7;
        }
      }

      .available-tooltip {
        position: absolute;
        left: 24px;
        min-width: 290px;
        height: 68px;
        overflow: hidden !important;
        display: none;
      }

      .available-tooltip:hover {
        display: block !important;
      }

      .online-admin {
        position: absolute;
        top: 0px;
        left: 0px;
        min-width: 290px;
        background-color: rgb(42 42 42 / 80%);
        border-radius: $border-radius-md;
        padding: 10px 20px;
        z-index: 999;
        max-height: 90px;
        height: auto;
        overflow: auto;

        .admin-status {
          padding: 2px 12px;
          border-radius: 40px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #424242;
          margin-bottom: 0;
          display: block;
          margin: 0 auto;
          margin-right: 0;
        }

        .available {
          background-color: #46afb8;
          color: white;
        }

        .not-available {
          background-color: #eadb4a;
        }

        .admin-name {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          color: white;
          margin-bottom: 0px;
          width: 120px;
          margin-left: 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .chat-skeleton-header {
        animation: skeleton-loading 1s linear infinite alternate;
      }

      @keyframes skeleton-loading {
        0% {
          background-color: #686c7732;
        }
        100% {
          background-color: #c4d8f743;
        }
      }

      .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
      }

      .chat-status-text {
        width: 84px;
        height: 19px;
        margin-left: 32px;
        border-radius: $border-radius-md;
      }
    }

    &__backTime:hover .available-tooltip {
      display: block;
    }

    .chat-skeleton-header {
      animation: skeleton-loading 1s linear infinite alternate;
    }

    @keyframes skeleton-loading {
      0% {
        background-color: #686c7732;
      }
      100% {
        background-color: #c4d8f743;
      }
    }

    .chat-status-box {
      width: 145px;
      height: 26px;
      margin-left: 15px;
      border-radius: 13px;
    }

    &__showProfiles {
      border-radius: $border-radius-md;
      @media (max-width: 767px) {
        overflow: auto;
        padding-left: 16px;
        padding-top: 16px;
        margin-top: 10px;
      }

      @media (max-width: 575px) {
        padding-left: 20px;
      }

      .chatbox-active {
        width: 200px;
        position: relative;

        @media (max-width: 767px) {
          width: 160px;
        }
      }

      .show-profile-skeleton {
        width: 200px;
        height: 82px;
        border-radius: $border-radius-md;
        margin-right: 10px;
        background-color: #b9b9b9;
        animation: skeleton-loading 1s linear infinite alternate;
      }

      @keyframes skeleton-loading {
        0% {
          background-color: #3b415032;
        }
        100% {
          background-color: #7d8a9e43;
        }
      }

      .show-profile-header-skeleton {
        width: 200px;
        height: 29px;
        border-radius: 8px 8px 0px 0px;
        margin-right: 10px;
        background-color: #b9b9b9;
        animation: skeleton-loading 1s linear infinite alternate;
      }

      @keyframes header-skeleton-loading {
        0% {
          background-color: #3b415032;
        }
        100% {
          background-color: #7d8a9e43;
        }
      }
    }
    &__inputField {
      margin-bottom: 6px;

      textarea {
        width: 100%;
        height: 68px;
        padding: 10px 13px;
        outline: none;
        border: none;
        border-radius: $border-radius-md;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.55px;
        resize: none;
        color: #424242;
      }

      textarea::placeholder {
        color: #919191;
      }
    }
    &__SendBtn {
      display: flex;
      justify-content: flex-end;

      &__btn {
        width: 73px;
        height: 33px;
        background-color: #46afb8;
        border-radius: $border-radius-md;
        outline: none;
        border: none;
        color: #ffffff;
        margin-top: 10px;
      }
    }

    .chatbox-footer-skeleton {
      width: 700px;
      height: 33px;
      border-radius: $border-radius-md;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    @keyframes skeleton-loading {
      0% {
        background-color: #686c7732;
      }
      100% {
        background-color: #c4d8f743;
      }
    }

    .chatbox-typing {
      display: inline-flex;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: white;
        margin: 0;
        background-color: #919191;
        padding: 3px 14px 3px 18px;
        border-radius: 24px;
        position: relative;
        top: -7px;
      }
    }
  }

  .quickResponse-chat {
    margin-top: 16px;

    @media (max-width: 2400px) and (min-width: 2150px) {
      margin-top: 15px;
    }

    @media (max-width: 2560px) and (min-width: 2401px) {
      margin-top: 12px;
    }
  }
}

.showAdminChatBox.archiveBox {
  
  .chatBox {
    position: absolute;
    margin: auto;
    width: 96%;
    border-radius: 20px;
    bottom: 0;
    top: 0;
    max-height: 640px;
  }

  .support-center-chat-session {
    padding: 20px 27px 17px 26px;
    max-height: fit-content;
    @-moz-document url-prefix() {
      max-height: 640px;
      height: 100%;
    }

    .downArrow {
      top: -7px;
      left: -10px;
    }

    .chatBox__alphaHeading {
      position: relative;
      left: -7px;
    }

    .chatbox-chatIcon {
      top: 0px;
    }

    .chatBox__notifications {
      margin-bottom: 4px;

      .message-row {
        padding: 5px 0px !important;
      }
    }
  }
}

.quickResponse {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 10px;

  button {
    width: auto;
    position: unset;
  }
}

.chatBox__notifications__handGestureIconWrapper {
  width: 43px;
  text-align: center;
}

.handGestureMsg {
  margin-left: 9px;
}

.chatBox__alphaHeading {
  font-size: 22px;
  font-family: $font-openSans;
  font-weight: 700;
  line-height: 25.78px;
  word-spacing: 0.85px;
}

.modalCrossBtn {
  position: absolute;
  top: -10px;
  left: -15px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  font-size: 25px;
  padding: 0;
  line-height: 30px;
}

.chat_items_list {
  max-width: 100%;
  width: 100%;
  background-color: #424242;
  border-radius: $border-radius-md;
  position: relative;
}
.chat_items_list .items-list {
  padding: 15px 20px 5px 0;
  font-family: "Nunito", sans-serif;
  display: grid;
  grid-template-columns: 35% 65%;
}
.chat_items_list .items {
  display: flex;
  padding-bottom: 7px;
  padding-left: 14px;
  padding-right: 10px;
  cursor: pointer;
}
.chat_items_list .item-bullet {
  background-color: #d8d8d8;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  font-size: 15px;
  line-height: 24px;
  color: #424242;
  text-align: center;
  font-weight: 700;
}
.chat_items_list .content {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 22px;
  margin: 0;
  padding-left: 11px;
  width: calc(100% - 24px);
}
.chat_items_list .close_btn {
  background-color: #3c809f;
  border-radius: 0px 4.5px 0px 4.5px;
  border: navajowhite;
  font-size: 16px;
  color: #fff;
  width: 220px;
  height: 33px;
  margin-top: -7px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.chatBox__centerBtns__btn {
  // background-color: transparent;
  border-radius: 0;
  max-width: initial;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.school-name {
  max-width: initial;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.classes-tooltip {
  position: absolute;
  background-color: rgba(42, 42, 42, 0.8);
  z-index: 999;
  border-radius: $border-radius-md;
  padding: 5px 10px;
  top: -55px;
  overflow: auto;
  backdrop-filter: blur(2px);

  p {
    margin-bottom: 0;
  }

  button {
    max-width: unset !important;
    width: 205px !important;
    background-color: unset;
    padding: 5px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.classes-tooltip::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .chat_items_list .items-list {
    display: block;
    width: 100%;
  }
  .chat_items_list .close_btn {
    position: relative;
    margin-top: 5px;
  }
  .chat_items_list .content {
    font-size: 14px;
  }
}

.chatCloseModal {
  .modal-content {
    width: 100%;
    max-width: 350px;
    position: relative;
    overflow: unset;
    background-color: #424242;
    color: #fff;
    padding: 10px 0 0 0;
    border-radius: 10.5px;
  }
}

.ticketBorder {
  &__crossModalButton {
    &__crossModalInnerButton {
      width: 25px;
      height: 25px !important;
      font-size: 15px;
      font-weight: 900;
      overflow: hidden;
      background-color: #c44538;
      color: #fff;
      display: flex;
      justify-content: center;
      border: 3px solid white;
      border-radius: 50%;
      position: absolute;
      top: -15px;
      left: -15px;
      cursor: pointer;
    }
  }

  &__modalHeaderr {
    &__ticketBorder1 {
      &__modalheader-title {
        font-size: 24px !important;
        line-height: 36px !important;
        margin-bottom: 0;
        padding-left: 25px !important;
        font-weight: 400 !important;
      }
    }
  }
  &__ticketBorder2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .noButton,
    .yesButton {
      width: 49%;
      background-color: #194792 !important;
      color: #fff !important;
      border: 0 !important;
      text-align: center;
      padding: 10px 0;
      font-size: 20px !important;
      font-weight: 600;
    }
    .yesButton {
      background-color: #46afb8 !important;
    }
  }
  .d-flex {
    background-color: #b9b9b9;
    padding: 25px 5px 15px 5px;
    border-radius: 0 0 8px 8px;
  }
}

.request {
  width: 183px;
  position: absolute;
  right: 0px;
  z-index: 99;
  right: 0px;
  bottom: 0;
  width: 192px;

  img {
    width: 100%;
  }

  .req-number {
    position: absolute;
    font-size: 50px;
    font-weight: 400;
    line-height: 59px;
    color: white;
    left: 40px;
    top: 13px;
    font-family: "Helvetica-Nueue", sans-serif;
  }

  .req-text {
    position: absolute;
    font-size: 17px;
    font-weight: 400;
    color: white;
    right: 56px;
    top: 42px;
    font-family: "Helvetica-Nueue", sans-serif;
  }
}

.chatBox {
  background-color: $theme-bg-color-lightest !important;
  &__showProfiles {
    &__profile {
      &__body {
        padding: 6px 16px 5px 19px !important;

        .group-btns {
          background-color: #b9b9b9 !important;
          width: 100%;
          justify-content: space-between;
          padding: 7.5px 0;
        }
        .noButton,
        .yesButton {
          width: 48%;
          background-color: #194792 !important;
          color: #fff !important;
          border: 0 !important;
          text-align: center;
          padding: 5px 0;
          font-size: 14px !important;
          font-weight: 600;
          position: relative;

          @media (max-width: 767px) {
            font-size: 12px !important;
            padding: 4px 0px;
          }

          .spinner-border {
            width: 14px;
            height: 14px;
            border-width: 2px;
            // position: absolute;
            // right: 4px;
            // top: 7px;
          }

          .checked-done {
            position: absolute;
            left: 0;
            top: 2.5px;
            width: 10px;
            height: 18px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            right: 0;
            margin: 0 auto;
          }
        }
        .yesButton {
          background-color: #46afb8 !important;
        }
        &__ticketBorder__crossModalButton {
          &__ticketBorder__crossModalButton__crossModalInnerButton {
            width: 22px;
            height: 22px !important;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            font-weight: 900;
            overflow: hidden;
            background-color: #c44538;
            color: #fff;
            display: flex;
            justify-content: center;
            border: 3px solid white;
            border-radius: 50%;
            position: absolute;
            top: -12px;
            left: -15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.question-tooltip {
  position: fixed;
  bottom: 132px;
  right: 385px;
  width: 100%;
  max-width: 349px;
  margin-right: 0;
  z-index: 9999;

  .toolTip-body {
    position: relative;
    display: flex;
    align-items: center;
    padding: 3px 29px 4px 23px;
    background-color: #ffed7f;
    border-radius: 44.5px;
    height: 89px;
    animation-name: first-Tooltip;
    animation-duration: 1s;

    @keyframes first-Tooltip {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .toolTip-arrow {
      position: absolute;
      bottom: -40px;
      left: 150px;
      border-radius: $border-radius-md;
    }

    .aplus-img {
      width: 53.3px;
      height: 49.75px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #3e3e3e;
      margin-bottom: 0;
      width: 100%;
      max-width: 243px;
      padding-left: 12px;
    }
  }
}

.question-tooltip.chat-tooltip-position {
  position: absolute;
  right: 66px;
  bottom: 132px;
}

.chatIcon-container {
  width: 100%;
  max-width: 1240px;
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99999;

  &.open-modal-space-remove {
    right: 8px;
  }
}

.info-tooltip {
  padding: 5px 5px 3px 5px;
  width: 100%;
  max-width: 240px;
  text-align: left;
}

.normal-admin-skeleton {
  height: 473.2px;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 13px 6px;
  background-color: white;
  border-radius: $border-radius-md;

  .chatbox-chatSkeleton {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0px;

    .profile {
      width: 46px;
      height: 42px;
      border-radius: $border-radius-md;
      margin-right: 9px;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .text {
      width: calc(100% - 55px);
      height: 46px;
      border-radius: $border-radius-md;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }

  @keyframes skeleton-loading {
    0% {
      background-color: #686c7732;
    }
    100% {
      background-color: #c4d8f743;
    }
  }
}

.classes-tooltip {
  position: absolute;
  background-color: rgba(42, 42, 42, 0.8);
  z-index: 999;
  border-radius: $border-radius-md;
  padding: 5px 10px;
  top: -55px;
  overflow: auto;
  backdrop-filter: blur(2px);

  p {
    margin-bottom: 0;
  }

  button {
    max-width: unset !important;
    width: 205px !important;
    background-color: unset !important;
    padding: 5px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .tooltip-buttons {
    button {
      width: auto !important;
      max-width: 205px !important;
      text-align: left !important;
      padding: 5px 0px;
    }
  }
}

.tickets-tooltip {
  position: absolute;
  background-color: rgba(42, 42, 42, 0.8);
  z-index: 999;
  border-radius: $border-radius-md;
  padding: 5px 10px;
  top: -55px;
  overflow: auto;
  backdrop-filter: blur(2px);

  p {
    margin-bottom: 0;
  }

  button {
    max-width: unset !important;
    width: 205px !important;
    background-color: unset !important;
    padding: 5px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .tooltip-buttons {
    button {
      width: auto !important;
      max-width: 205px !important;
      text-align: left !important;
      padding: 5px 0px;
    }
  }
}

.tickets-tooltip::-webkit-scrollbar {
  display: none;
}

.classes-tooltip::-webkit-scrollbar {
  display: none;
}

.ticket-details-btn-container button {
  width: 145px !important;
  text-align: left !important;
}
