select {
  cursor: pointer;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: $color-white !important;
  font-size: inherit;
  color: $color-gray-900 !important;
  font-weight: 400;
}

.form-select {
  border-radius: $border-radius-md;
  @include ellipsis;

  &:focus,
  &:active {
    box-shadow: none;
  }

  &-dark {
    width: 53px;
  }

  &:disabled {
    background-color: $color-gray-800;
  }
}

select:disabled {
  opacity: 1;
  cursor: default;
}

.form-control {
  outline: none;
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid $color-light;
  border-radius: $border-radius-md;
  padding: 2px 13px;

  @include safari-suport {
    font-weight: 500;
    line-height: 1.5;
  }

  @include media-breakpoint(sm) {
    padding: 2px 8px;
  }

  &::placeholder {
    font-family: $font-nunito;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;

    @include safari-suport {
      font-weight: 500;
    }
  }

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $color-light;
  }
}


.table-dropdown {
  margin-right: 0 !important;

  &-toggle {
    // display: flex;
    // width: 143px;
    // background-image: url("./../../img/drop-down-select.svg");
    // background-repeat: no-repeat;
    // background-position: center right 10px;
    // background-color: $color-white !important;
    // border: 1px solid $color-blue-200 !important;
    // border-radius: $border-radius-md;
    // font-size: 14px;
    // // color: $color-blue-200 !important;
    // color: $color-gray-950 !important;
    // line-height: 20px;
    // padding: 5px 10px 0;
    background-color: transparent !important;
    border: 1px solid #9d9d9d !important;
    font-size: 14px;
    font-weight: 400;
    color: $color-gray-950 !important;
    width: 143px;
    height: 26px;
    border-radius: $border-radius-md;
    padding: 2px 11px 0;
    background-image: url(../../img/down-arrow.svg);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center right 10px;
    background-position-y: 9px;
    text-align: left;

    @include safari-suport {
      padding: 1px 6px 3px 6px;
    }

    @include media-breakpoint(md) {
      font-size: 12px;
      line-height: 18px;
    }

    &:hover {
      outline: none !important;
      box-shadow: unset !important;
    }

    > div {
      max-width: 110px;
      @include ellipsis;
    }
  }

  &-menu {
    min-width: 143px;
    padding: 3px 0;
    font-size: 14px;
    line-height: 20px;
    border-radius: $border-radius-md;

    &.show.top-start {
      transform: translate(0px, -32%) !important;
      z-index: 99;

      &-2 {
        transform: translate(0px, -40%) !important;
      }

      &-0 {
        transform: translate3d(0px, 26px, 0px) !important;
      }
    }

    &.show.middle-start {
      transform: translate(0px, -70%) !important;
      z-index: 99;
    }

    &.show.center-start-0 {
      transform: translate(0px, -65%) !important;
      z-index: 99;
    }

    &.show.center-start-1 {
      transform: translate(0px, -26%) !important;
      z-index: 99;
    }

    &.show.single-select {
      // transform: translate(0px, -62%) !important;
      z-index: 99;
    }
  }

  .adjustDropDownClass{
    transform: translate(0px, -104px) !important;
  }
  
  &-item {
    font-size: 14px;
    line-height: 20px;
    color: $color-blue-200;
    padding: 2px 16px;

    @include media-breakpoint(md) {
      font-size: 12px;
      line-height: 18px;
      padding: 2px 10px;
    }

    &:hover {
      color: $color-blue-200;
    }

    &:active {
      color: $color-white;
      text-decoration: none;
      background-color: #0d6efd;
    }
  }
}

.table-dropdown-student-summative {
  height: 33px;
  padding: 0 !important;
  width: 210px !important;
  &-toggle {
    background-color: white !important;
    color: #003b93 !important;
    background-image: url(../../img/down-arrow-white.svg);
    background-position: center right 10px;
    width: 100%;
    height: 33px;
    position: unset;
  }
}

// Welcome Edit Select
.welcome-edit-select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../../assets/img/select-icon-lg.svg");
  background-repeat: no-repeat;
  background-position: center right 18px;
  font-size: 18px;
  color: $color-gray-800;
  line-height: 23px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

// CMS Assessment Select
.cms-assessment-select {
  font-size: 18px;
  color: $color-gray-950;
  line-height: 22px;
  padding-top: 10px !important;
}

.dropdown-diabled {
  position: unset !important;
  border: 1px solid $color-blue-200;
  border-radius: $border-radius-md;
  font-size: 16px;
  color: $color-blue-200;
  // z-index: 4;
}

.css-qc6sy-singleValue {
  color: $color-blue-200 !important;
  min-height: 18px;
  @include ellipsis;
}

.css-1xh5kt4-placeholder {
  @include ellipsis;
}

.arrow-down-classname {
  position: relative;
  left: 10px;
}

.arrow-up-classname {
  position: relative;
  left: 10px;
  transform: rotate(180deg) translateY(1px);
  cursor: pointer;
}

// Header selects
.react-select {
  font-family: inherit;

  &__single-value {
    @include safari-suport {
      padding-top: 1px !important;
      padding-bottom: 3px !important;
    }
  }

  &__option {
    cursor: pointer !important;
  }

  &__indicator {
    &.react-select__dropdown-indicator {
      display: none !important;
    }
  }

  &__indicators {
    background-image: url("../../../assets/img/drop-down-select.svg");
    background-repeat: no-repeat;
    background-position: 2px 12px;
  }

  &__clear-indicator {
    display: none !important;
  }
}

// Limit Selects
.limit-selects {
  margin: 0 10px;

  @include media-breakpoint(md) {
    margin: 0 8px;
  }

  &__wrapper {
    outline: none !important;
    box-shadow: unset !important;
    transition: opacity 0.4s ease;
    pointer-events: auto !important;
  }

  &--is-disabled {
    transition: opacity 0.4s ease;
    opacity: 0.26;
  }

  &__option {
    cursor: pointer !important;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 53px;
    min-height: 26px !important;
    border-radius: $border-radius-md !important;
    // outline: none !important;
    box-shadow: unset !important;
    cursor: pointer !important;
  }

  &__value-container {
    padding: 0 0 0 4px !important;
  }

  &__single-value {
    margin: 0 !important;
    color: $color-gray-950!important;
  }

  &__indicators {
    background-image: url("../../../assets/img/limit-select-icon.svg");
    background-repeat: no-repeat;
    background-position: 0px 7px;

    svg {
      display: none;
    }
  }

  &__menu {
    width: 53px;
    color: $color-dark-300 !important;
    margin-top: 2px !important;
    padding: 0 !important;
    overflow: hidden;

    &-list {
      padding: 0 !important;
      line-height: 10px;
    }
  }
}
//sort select 

.sort-selects {
  margin: 0 10px;

  @include media-breakpoint(md) {
    margin: 0 8px;
  }

  &__wrapper {
    outline: none !important;
    box-shadow: unset !important;
    transition: opacity 0.4s ease;
    pointer-events: auto !important;
  }

  &--is-disabled {
    transition: opacity 0.4s ease;
    opacity: 0.26;
  }

  &__option {
    cursor: pointer !important;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    min-height: 26px !important;
    border-radius: $border-radius-md !important;
    // outline: none !important;
    box-shadow: unset !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border: 1px solid #858585;
  }

  &__value-container {
    padding: 0 0 0 4px !important;
  }

  &__single-value {
    margin: 0 !important;
    color: $color-gray-950!important;
  }

  &__indicators {
    background-image: url("../../../assets/img/limit-select-icon.svg");
    background-repeat: no-repeat;
    background-position: 0px 7px;

    svg {
      display: none;
    }
  }

  &__menu {
    width: 150px;
    color: $color-dark-300 !important;
    margin-top: 2px !important;
    padding: 0 !important;
    overflow: hidden;

    &-list {
      padding: 0 !important;
      line-height: 10px;
    }
  }
}

.assessment-selects {
  &__wrapper {
    outline: none !important;
    box-shadow: unset !important;
    margin-left: 10px;
  }

  &__option {
    cursor: pointer !important;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 208px;
    min-height: 26px !important;
    border-radius: $border-radius-md !important;
    outline: none !important;
    box-shadow: unset !important;
    cursor: pointer !important;

    @include media-breakpoint(lg) {
      width: 180px;
    }

    @include media-breakpoint(md) {
      width: 165px;
    }

    @include media-breakpoint(sm) {
      width: 150px;
    }
  }

  &__value-container {
    padding: 0 0 0 4px !important;
  }

  &__single-value {
    margin: 0 !important;
    color: $color-dark-300 !important;
  }

  &__indicators {
    background-image: url("../../../assets/img/limit-select-icon.svg");
    background-repeat: no-repeat;
    background-position: 0px 8px;

    svg {
      display: none;
    }
  }

  &__menu {
    width: 208px;
    color: $color-dark-300 !important;
    margin-top: 3px !important;
    padding: 0 !important;
    overflow: hidden;

    &-list {
      padding: 0 !important;
      line-height: 10px;
    }
  }
}

// Add Objective Select
.objective-select {
  &__value-container {
    padding: 0 0 0 4px !important;
    max-height: 26px;
    height: 26px;
  }

  &__input-container {
    height: 26px;
    margin: 0;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    min-height: 26px !important;
    padding-right: 4px;
    border-radius: $border-radius-md !important;
    outline: none !important;
    box-shadow: unset !important;
    cursor: pointer !important;
  }

  &__indicators {
    background-image: url("../../../assets/img/drop-down-select.svg");
    background-repeat: no-repeat;
    background-position: 0px 9px;

    svg {
      display: none;
    }
  }

  &__single-value {
    min-height: 26px;
    font-weight: 400;
    margin-top: -6px !important;
  }

  &__menu {
    font-weight: 400;
  }
}

.admin-error {
  .error-hint {
    top: -35px;
  }
}
// Limit Selects
.admin-selects {
  margin-top: 15px;

  .red-circle {
    top: 6px;
  }

  .margin-helptext {
    margin-left: 28px;
  }

  &__forward_back {
    display: flex;
    flex-direction: row;
    margin-left: -7px;
  }

  &__icon_left_forward {
    padding-top: 4px;
    padding-left: 10px;
  }

  &__icon_right_back {
    padding-top: 4px;
    padding-right: 10px;
  }

  &__wrapper {
    outline: none !important;
    box-shadow: unset !important;
    transition: opacity 0.4s ease;
  }
  &--is-disabled {
    transition: opacity 0.4s ease;
    opacity: 0.26;
  }

  &__option {
    cursor: pointer !important;
    padding-left: 16px !important;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 42px !important;
    border-radius: $border-radius-md !important;
    outline: none !important;
    box-shadow: unset !important;
    cursor: pointer !important;
  }

  &__value-container {
    padding: 0 0 0 4px !important;
  }

  &__input-container {
    padding-left: 10px !important;
  }

  &__placeholder {
    padding-left: 10px !important;
  }

  &__single-value {
    color: $color-dark-300 !important;
    padding-left: 10px !important;
  }

  &__indicators {
    background-image: url("../../../assets/img/select-icon-lg.svg");
    background-repeat: no-repeat;
    background-position: 0px 8px;
    padding-left: 22px;
    margin-top: 7px;
    svg {
      display: none;
    }
  }

  &__menu {
    width: 53px;
    color: $color-dark-300 !important;
    margin-top: 10px !important;
    overflow: hidden;
    // position: relative !important;
    &-list {
      padding: 0 !important;
    }
  }
  .no-admins {
    color: $color-black;
    text-align: center;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 8px;
    height: 15px;
  }
}

// Limit Selects
.grade-selects {
  margin: 0 10px;

  @include media-breakpoint(md) {
    margin: 0 8px;
  }

  &__wrapper {
    outline: none !important;
    box-shadow: unset !important;
    transition: opacity 0.4s ease;
    // margin-left: auto;
  }

  &--is-disabled {
    transition: opacity 0.4s ease;
    opacity: 0.26;
  }

  &__option {
    cursor: pointer !important;
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 115px;
    min-height: 26px !important;
    border-radius: $border-radius-md !important;
    outline: none !important;
    box-shadow: unset !important;
    cursor: pointer !important;
  }

  &__value-container {
    padding: 0 0 0 4px !important;
  }

  &__single-value {
    margin: 0 !important;
    color: $color-dark-300 !important;
  }

  &__indicators {
    background-image: url("../../../assets/img/limit-select-icon.svg");
    background-repeat: no-repeat;
    background-position: 0px 8px;

    svg {
      display: none;
    }
  }

  &__menu {
    width: 53px;
    color: $color-dark-300 !important;
    margin-top: 2px !important;
    padding: 0 !important;
    overflow: hidden;

    &-list {
      padding: 0 !important;
      line-height: 10px;
    }
  }
}

.highlighted-border {
  outline: 2px solid #2684ff !important;
}

.select-box {
  display: block;
  position: relative;

  select {
    border: 1px solid $color-blue-200;
    border-radius: $border-radius-md;
    height: 26px;
    padding: 0px 7px 0px 11px;
    color: $color-blue-200;
    font-size: $sm;
    text-transform: capitalize;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 100%;

    &::-ms-expand {
      display: none;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    right: 8px;
  }
}
