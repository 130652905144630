.secHeading {
    @include secHeading(0px, 7px);
    padding-left: 14px
}

.goals-sec {
    position: relative;

    .pagination {
        position: absolute;
        bottom: -44px;
        left: 0;
        right: 0;
    }

    .see-all-btn {
        font-size: $sm;
        font-weight: 700;
        line-height: 19px;
        color: white;
        border: 0;
        padding: 0;
        margin-left: 6px

       
    }
    .see-all-btn:focus-visible { 
        border: 2px solid white;
    }
}

.cardsContainer {
    @include flex-box(flex-start, flex-start);
    gap: 14px;
    position: relative;

    .splide {
        width: 100%;
    }

    .splide__track {
        width: 100%;
    }

    .splide__list {
        width: 100%;
    }

    .splide__slide {
    }

    .goals-card-skeleton {

        @media (max-width: 1200px) {
            &:nth-child(3) {
                display: none;
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            max-width: unset;

            &:nth-child(2) {
                display: none;
            }
        }
    }

    .permission {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flex-box(center, center);
        background-color: $theme-bg-color-lighter-variant2;
        opacity: 0.6;
        z-index: 1;
        padding: 10px;
        // &.tab-permission{
        //     padding-top: 0;
        //     top: 38px;
        // }
        &-text {
            background-color: rgba($color-black, 0.46);
            width: 524px;
            height: 101px;
            border-radius: $border-radius-md;
            padding: 19px 33px 21px 33px;
            padding-bottom: 21px;

            @include media-breakpoint(md) {
                padding: 10px 12px;
                height: auto;
            }
  
            p {
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                text-shadow: 0px 3px 6px rgba($color-black, 0.56);
                margin-bottom: 0;
            }
        }

        .classroom-permission {
            position: relative;
        }
        &:after {
            position: absolute;
            content: '';
        }
    }

    .permission-text.classroom-permission {
        background-color: rgba($color-black, 0.46);
        width: 524px;
        margin: 0 auto;
        height: 101px;
        border-radius: $border-radius-md;
        padding: 19px 33px 21px 33px;
        padding-bottom: 21px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;

        @include media-breakpoint(md) {
            padding: 10px 12px;
            height: auto;
            width: 95%;
        }

        p {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            text-shadow: 0px 3px 6px rgba($color-black, 0.56);
            margin-bottom: 0;
        }
    }
}


.goalsCard {
    background-color: $color-gray-500;
    padding: 10px 0px 0px 0px;
    height: 277px;
    width: 100%;
    max-width: 405px;
    overflow: hidden;

    &:focus-visible {
        outline: none;
        border: 2px solid white;
        padding: 4px;
    }

    @media (max-width: 1200px){
        max-width: 100%;
    }

    .card-title {
        font-size: $lg + 2;
        font-weight: 700;
        line-height: 28px;
        color: $color-gray-950;
        margin-bottom: 10px;
        @include ellipsis;
        padding-left: 17px;
        padding-right: 10px;
        font-family: $font-openSans;
        cursor: pointer;
        transition: left 3s linear, width 3s linear;
        position: relative;
        left: 0;
        width: 100%;
    }

    .animtated-title,
    .animated:hover{
      left: -100%;
      width: 200%;
    }

    .animated-title,
    .animate-now{
        left: -100%;
        width: 200%;
    }

    .card-body-container {
        @include flex-box(space-between, center);
        flex-direction: column;
        height: calc(100% - 37px);
        padding-bottom: 8px;
    }

    .card-desc {
        width: 100%;
        height: 83px;
        overflow: auto;
        padding-right: 15px;
        padding-left: 17px;
        position: relative;
        cursor: pointer;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none; 
        }

        p {
            font-size: $sm + 1;
            font-weight: 400;
            line-height: 20.46px;
            color: $color-gray-950;
            margin-bottom: 0px;
        }

        .more-data {
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            width: 100%;
            height: 30px;
            background-image: linear-gradient(transparent -10%, $color-gray-500 80%);
            // background: linear-gradient(180deg, $color-gray-500 0.14%, $color-gray-500 91.99%, rgba(153, 77, 122, 0.00) 97.35%);
        }
    }

    .more-data {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(transparent -10%, $color-gray-500 80%);
        // background: linear-gradient(180deg, $color-gray-500 0.14%, $color-gray-500 91.99%, rgba(153, 77, 122, 0.00) 97.35%);
    }

    .goals-checkboxs-container {
        padding-right: 16px;
        padding-left: 15px;
        position: relative;
        overflow: hidden;
        height: 47px;
        width: 100%;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none; 
        }

        >div {
            width: 100%;
            height: auto;
            overflow: hidden;
            @include flex-box(flex-start, flex-start);
            gap: 7px;
            flex-direction: column;
            padding-bottom: 0px
        }

        .goalCheckBox {
            @include flex-box(flex-start,center);
            gap: 0 9px;
            background-color: $theme-bg-color-dark;
            padding: 3px 13px;
            border-radius: $border-radius-md;
            width: 100%;
            height: 47px;


            &:focus-visible {
                outline: none;
                border: 2px solid white;
            }

            &:last-child {
                margin-bottom: 0;
            }

            p {
                font-size: $sm + 1;
                font-weight: 400;
                line-height: 18px;
                color: white;
                margin-bottom: 0px;
                width: calc(100% - 34px);
                padding-top: 1px;
            }

            .text-adjust {
                font-size: 13px;
                line-height: 14px;
            }
        }
    }

    .two-goals {
        height: 99px;
    }

    .three-goals {
        overflow: auto;
        height: 120px;
    }
}

.goals-card-skeleton {
    padding-left: 17px
}

.tools-sec {
    margin-top: 14px;

    @include media-breakpoint(md) {
        margin-top: 50px;
    }

    .tools-row {
        @include flex-box(flex-start, center);
        flex-wrap: wrap;
        gap: 20px 36px;
        padding-left: 34px;
        margin-top: 22px;

        @include media-breakpoint(sm) {
            justify-content: center;
            padding-left: 0;
        }
    
        .tool-box {
            text-align: center;
            width: auto;
            max-width: 170px;
            overflow: hidden;
    
            .tool-img {
                height: 65px;
                @include flex-box(center, flex-end);
            }

            .tool-img.skeleton {

                svg {
                    position: relative;
                    left: 2px;
                }
            }
    
            .tool-name {
                font-size: $md;
                font-weight: 400;
                line-height: 21.82px;
                color: white;
                margin-top: 10px;
                margin-bottom: 0;
                left: 0;
                position: relative;
                transition: left 1s linear, width 1s linear;
                @include ellipsis;
            }
        }
    }
}

.assessment-monitor-sec {
    margin-top: 11px;

    @include media-breakpoint(sm) {
        margin-bottom: 20px
    }

    .assessment-monitor-header {
        @include flex-box(space-between, flex-start);
        // padding-right: 31px;
        margin-top: 7px;

        @include media-breakpoint(lg) {
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            padding-right: 0;
        }

        .monitor {
            width: calc(100% - 504px);
            background-color: $color-gray-500;
            padding: 11px 15px 10px 20px;
            border-radius: $border-radius-md;
            @include flex-box(flex-start, center);
            gap: 15px 10px;
            width: 705px;
            position: relative;

            @include media-breakpoint(lg) {
                width: 100%;
                max-width: 705px;
            }

            @include media-breakpoint(md) {
                align-items: flex-start;
            }
            @media (max-width: 428px) {
                height: 106px;
            }

            .activity-monitor {
                width: 100%;
                @include flex-box(flex-start, flex-start);
                flex-wrap: wrap;
                gap: 10px 15px;
                padding-top: 3px;              

                @include media-breakpoint(sm) {
                    gap: 10px;
                    margin-bottom: 10px; 
                }

                &__assessmentsTags {
                    display: flex;
                    gap: 15px;
                    @media (max-width: 428px) {
                       position: absolute;
                       top: 70px;
                       gap: 12px;
                    }
                }

                .monitor-desc {
                    font-size: $md;
                    font-weight: 400;
                    line-height: 21.82px;
                    color: $color-gray-950;
                    margin: 0;
                    padding-right: 10px;
                }

                .monitor-assessment-student {
                    font-size: $sm;
                    font-weight: 400;
                    line-height: 19px;
                    color: white;
                    margin: 0;
                    // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.52);
                    padding: 2px 10px;
                    border-radius: 8px
                }

                .assessments-label {
                    background-color: #306fdc;
                }

                .student-online-label {
                    background-color: #214d94;
                }

            }

            .button-green {
                @include buttonGreen(88px, 33px, 6px 10px 4px, $border-radius-md);

                @include media-breakpoint(sm) {
                    display: block;
                    margin-left: auto;
                }
            }
        }

        .reports-global {
            width: 100%;
            max-width: 485px;
            @include flex-box(flex-end, flex-start);
            gap: 14px;
            margin-top: -5px;

            @include media-breakpoint(lg) {
                max-width: 100%;
               margin-top: 0;
            }

            @include media-breakpoint(sm) {
                display: block;
            }

            .secHeading {
                font-size: $md +1;
                line-height: 23.15px;
                padding-left: 0px;
            }

            .reports-buttons {
                width: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 11px;
                position: relative;
                top: -3px;

                @include media-breakpoint(sm) {
                    width: 100%;
                    top: 0;
                    margin-top: 11px;
                }

                &__groupStyle {
                  
                        @media (max-width: 400px) {
                            width: 100%;
                        }
                    
                }

                .report-button {
                    padding: 5px 13px 4px 13px;
                    border: 1px solid White;
                    border-radius: $border-radius-md;
                    background-color: transparent;
                    text-align: center;
                    font-size: $md;
                    font-weight: 400;
                    line-height: 21px;
                    color: white;

                    @media (max-width: 400px) {
                        width: 100%;
                    }
                }

                .stl {
                    .stl__dropdown-container {
                        width: 209px;
                        padding-top: 3px;
                    }
                }

                .reportCards__dropdownButton_menu {

                    .reportCards__dropdownButton_menu-list {
                        padding: 0;
                    }

                    .listItem:first-child {
                        border-radius: 8px 8px 0px 0px;
                    }

                    .listItem:last-child {
                        border-radius: 0px 0px 8px 8px;
                    }
                }
            }
        }
    }
}

.reportCards__dropdownButton__dropdown-container {
    padding: 0 !important;
    height: 33px;

    .reportCards__dropdownButton_placeholder {
        padding: 4px 10px 4px 10px;

        &.loading-reports {
            width: 132px;
            position: relative;

            img {
                position: absolute;
                top: -13px;
                right: -15px;
            }
        }
    }

    .verticalBar {
        height: 30px;
        
        .reportCards__dropdownButton__divider {
            margin-top: 4px;
            margin-right: 0;
        }

        span {
            padding-top: 4px;
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.summative-assessment-sec {
    margin-top: 2px;

    @include media-breakpoint(xl) {
        margin-top: 8px;
    }

    >.secHeading {
        margin-bottom: -20px;
    }

    .summative-assessment-table {
        margin-top: 0px;
        overflow: auto;

        &::-webkit-scrollbar {
            height: 10px;
          }

        table {

            .notfound__data {
                display: block;
                margin-top: 27px;
            }
            color: $color-gray-950;
            padding: 4px 14px 2.5px 11px;
            border: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .assessment-table-skeleton {
            margin-top: 20px;
        }

        .table-main {

            &:last-child {
                
                .main-tbody {

                    .reports-dropdown {
                        
                        .table-dropdown {

                            .dropdown-menu.table-dropdown-menu {
                                transform: translate(0px, -85px) !important;
                            }
                        } 
                    }
                }
            }
        }
    }
}

.formative-assessment-sec {
    margin-top: 31px;

    .assessment-completed {
        opacity: 0;
        transition: 0.4s all;
        margin: 0 !important;
    }

    .assessment-msg {
        opacity: 1;
        @include flex-box(center, center);

        &:first-child {
            border-radius: $border-radius-md $border-radius-md 0px 0px;
        }

        &:last-child {
            border-radius: 0px 0px $border-radius-md $border-radius-md;
        }

        p {
            font-size: $md;
            font-weight: 700;
            line-height: 21.82px;
            margin-bottom: 0;
            height: 0px;
        }
    }

    .assessment-moved {
        height: 83.23px;
        background-color: #0000001a;
        opacity: 1;

        p {
            height: auto;
            text-shadow: unset
        }
    }

    .assessment-filter-header {
        @include flex-box(flex-start, center);
        gap: 14px;
        flex-wrap: wrap;
        margin-bottom: 7px;
        padding-left: 14px;

        @include media-breakpoint(sm) {
            align-items: flex-end;
            position: relative;
        }

        .secHeading {
            padding-left: 0;
            margin-bottom: 0;

            @include media-breakpoint(sm) {
                font-size: 18px;
                line-height: 22px;
                width: calc(100% - 100px);
            }

            @media (max-width: 350px) {
                font-size: 15px;
                line-height: 18px;
            }
        }

        .filter-right {
            @include flex-box(flex-end, center);
            gap: 15px 24px;
            flex-wrap: wrap;
            padding-right: 32px;
            width: 100%;
            max-width: 585px;
            margin-left: auto;

            @include media-breakpoint(md) {
                padding-right: 0
            }

            @include media-breakpoint(sm) {
                max-width: 290px
            }

            .search-assment {
                @include flex-box(flex-start, center);
                gap: 0 10px;
                width: 242px;

                span {
                    margin-top: -2px;
                }

                input {
                    width: calc(100% - 26px);
                    border-radius: $border-radius-md;
                    padding: 4px 12px 3px;
                    border: 0;
                    font-size: $sm;
                    font-weight: 400;
                    line-height: 19.1px;
                    color: $color-gray-950;

                    &::placeholder {
                        font-size: $sm;
                        font-weight: 400;
                        line-height: 19.1px;
                        color: $color-gray-650;
                    }
                }
            }

            .schedule-dropdown {
                @include flex-box(flex-start, center);
                gap: 0 13.5px;
                width: 287px;

                p {
                    font-size: $md;
                    font-weight: 400;
                    line-height: 21.82px;
                    color: white;
                    margin-bottom: 0;
                }

                .select-box {
                    width: calc(100% - 79px);
                    height: 26px;

                    select {
                        font-size: $sm;
                        font-weight: 400;
                        line-height: 19.1px;
                        color: $color-gray-950;
                        padding: 4px 14px 2.5px 11px;
                        border: 0;
                        width: 100%;
                        height: 100%;

                        option {
                            color: $color-blue-200;
                        }
                    }
                }
            }
        }

        .see-completed-btn {
            display: inline-block;
            font-size: $sm;
            font-weight: 700;
            line-height: 19px;
            color: white;
            margin-top: 7px;

            &:focus-visible {
                border: 2px solid white;
            }

            @include media-breakpoint(sm) {
                position: absolute;
                top: -2px;
                right: 0;
            }

            @media (max-width: 350px) {
                top: -4px;
                font-size: 12px;
            }
        }
    }

    .accordion-container-animation {
        width: 100%;
        position: relative;
        right: 0%;
        transition: 0.2s all;

        @media(hover: hover) {
            &:hover {
                width: 101%;
                right: 0.5%;
            }
        }
    }

    .accordion-container {
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;

        &::-webkit-scrollbar {
            height: 10px;
          }

          &:focus-visible {
            &::before {
            background-color: transparent;
            }
          }
   
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: calc(100% - 38px);
            height: 1px;
            background-color: $color-gray;
            margin: 0 auto;
        }

        @media(hover: hover) {
            &:hover {
                // transform: scale3d(1.01211, 1.01211, 1);
            
                position: relative;
                box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
                z-index: 1;

                

                &::before {
                    background-color: $theme-bg-color-light;
                }

                table {
    
                        thead {
                            background-color: $theme-bg-color-light;
        
                            th {
        
                                .accordion-header {
        
                                    .accordion-arrow {
        
                                        svg {
                                            rect {
                                                stroke: white;
                                            }
                                        }
                                    }
        
                                    .report-number {
                                        color: white;
                                    }

                                    .not-sch-dark {
                                        background-color: $color-gray-500 !important;

                                        svg {

                                            path {

                                                stroke: #323233;
                                            }
                                        }

                                        p {
                                            color: #323233 !important;
                                        }
                                    }
        
                                    >span {
        
                                        .close-report-bar {
        
                                            span, div {
                                                color: white !important;
                                               
                                                
                                                p {
                                                    color: white !important;
                                                

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
        
                        .accordion-header-open {
                            background-color: $theme-bg-color-darker-variant2;
                        }
        
                        tr {
        
                            .read-caption {
                                background-color: $theme-bg-color-light;
        
                                span {
                                    color: white;
                                    // font-size: 101.5%;
                                }
                            }
        
                            .accordion-open-caption {
                                background-color: $theme-bg-color-darker-variant2
                            }
                        }
                }
            }
            
            
                &:hover {

                    table {

                        thead {

                            th {

                                .accordion-header {

                                    // .not-sch-dark {
                                    //     background-color: $color-gray-500 !important;
                                    // }

                                }
                            }
                        }
                    }
                }
            
        }


        &:nth-child(2) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        
        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            margin-bottom: 0;
        }

        table {
            border-radius: $border-radius-md;
            min-width: 1240px;

            thead {
                background-color: $color-gray-500;
                border-radius: 8px 8px 0px 0px;
            }

            .accordion-header-open {
                background-color: $theme-bg-color-darker-variant2;

                .accordion-header {
                    align-items: center;
                    height: auto;
                    padding-top: 5px !important;
                    padding-bottom: 8px !important;

                    .report-number {
                        color: white;
                    }
                }
            }

            th {
                
                .accordion-header {
                    @include flex-box(space-between, flex-start);
                    gap: 10px 17px;
                    padding: 8px 24px 14px 20px;
                    position: relative;

                    >span {
                        @include flex-box(flex-start, flex-start);
                    }

                    .accordion-arrow {
                        margin-right: 16px;
                        cursor: pointer;
                    }

                    .accordion-arrow-open {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        transition: 0.3s all;
                        padding-bottom: 0;
                        @include flex-box (center, center);
                        margin-right: 8px;
                        margin-left: -10px;

                        &:hover {
                            background-color: $color-blue-600;
                        }

                        svg {
                            width: 10px;
                            height: 12px;
                        }
                    }

                    .report-number {
                        font-size: $md;
                        font-weight: 700;
                        line-height: 22px;
                        color: $color-gray-950;
                        padding-top: 1px;

                        @include media-breakpoint(md) {
                            font-size: $sm;
                            line-height: 19.1px;
                        }

                        svg {
                            margin-top: -4px !important;
                            margin-left: 9px;
                        }

                        .power-stander {
                            position: relative;

                            .power-tooltip {
                                position: absolute;
                                top: 30px;
                                left: 0;
                                background-color: #000000;
                                font-size: $md;
                                line-height: 22px;
                                font-family: $font-nunito;
                                color: white;
                                border-radius: 8px;
                                display: none;
                                width: 270px;
                                padding: 6px 6px 4px;

                                @media (max-width: 450px) {
                                    top: 26px;
                                    font-size: 12px;
                                    line-height: 16px;
                                    border-radius: 4px;
                                    width: 202px;
                                    padding: 3px 4px 3px;

                                }
                            }

                            &:hover {
                                .power-tooltip {
                                    display: block;
                                }
                            }
                        }
                    }

                    .report-date {
                        border-radius: $border-radius-md;
                        background: $color-green-300;
                        padding: 2px 13px 2px 14px;
                        text-align: center;
                        width: auto;
                        max-width: 180px;
                        height: 23px;
                        margin-left: 23px;
                        @include flex-box(center, center);
                        gap: 0 9px;

                        svg {
                            width: 14px;
                            height: 14.5px;
                        }

                        p {
                            font-size: $sm;
                            font-weight: 400;
                            color: $color-gray-950;
                            // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.52);
                            line-height: unset;
                            margin-bottom: 0;
                            // padding-top: 1px;
                        }
                    }

                    >.complete-toggle-container {
                        padding-top: 9px;
                        transition: 0.4s all;
                        margin-left: 16px;
                        margin-top: 0px;
                        position: absolute;
                        top: 2px;
                        right: 24px;


                        p {
                            color: white;
                            margin-top: 0;
                            margin-right: 8px;
                        }

                        .switch {
                            margin-bottom: 0;
                        }

                        @include media-breakpoint(md) {
                            padding-left: 10px;
                        }
                    }

                    >.complete-toggle-container.close {
                        opacity: 0;
                    }

                    >.complete-toggle-container.open {
                        position: unset;
                        opacity: 1;
                        padding-top: 0px;
                    }
                }
            }

            tr {
                display: block;

                .read-caption {
                    padding-bottom: 13px;
                    background-color: $color-gray-500;
                    border-radius: 0px 0px 0px 0px;
                    margin-top: -1px;
                    padding-left: 0;
                    
                    span {
                        font-size: $md;
                        font-weight: 400;
                        line-height: 21.82px;
                        color: $color-gray-950;
                        display: block;
                        padding-top: 0px;
                        padding-right: 20px;
                        height: 22px;
                    }

                    .descWraper {
                        height: auto;
                        padding-left: 20px;
                        // transition: font-size 0.2s;
                        width: 100%;
                        max-width: 1239px;

                        span {
                            height: auto;
                            padding-right: 0;
                        }
                    }
                }

                .accordion-open-caption {
                    background-color: $theme-bg-color-darker-variant2;
                    border-radius: 0;
                    padding-bottom: 10px;

                    span {
                        color: white;
                    }
                }
                
                td {
                    width: 100%;
                    display: block;
                    padding-left: 20px;
                    padding-bottom: 10px;
                    background-color: $theme-bg-color-darker-variant2;
                    border-radius: 0px 0px 8px 8px;

                    .books-container {
                        @include flex-box(flex-start, center);
                        gap: 0 32px;
                        padding: 11px 13px 0px 22px;
                        transition: all 300ms linear;
                    }

                    .accordion-close {
                        position: relative;
                        top: -90px;
                        opacity: 0;
                        transition: 0.4s all;

                        .report-row {
                        }
                    }

                    .accordion-open {
                        top: 0;
                        height: 90px;
                        padding: 10px 15px 0px 17px;
                        opacity: 1;
                        transition: 0.850s all !important;
                        gap: 0 32px;
                        justify-content: space-between;
                        width: 100%;
                        max-width: 1220px;

                        .report-row {
                        }
                    }

                    .book-box {
                        position: relative;
                        padding: 9px 11px 9px 35px;
                        background-color: $color-gray-500;
                        border-radius: $border-radius-md;
                        width: 100%;
                        max-width: 372px;

                        .box-number {
                            position: absolute;
                            top: 12px;
                            left: -31px;
                            width: 56px;
                            height: 56px;
                            border-radius: 50%;
                            background-color: $theme-bg-color-medium;
                            padding: 5px;

                            span {
                                width: 100%;
                                height: 100%;
                                background-color: $yellow-200;
                                border-radius: 50%;
                                @include flex-box(center, center);
                                font-size: 28px;
                                font-weight: 700;
                                line-height: 38px;
                                color: #424242;
                                padding-top: 2px;
                            }
                        }

                        .book-detail {
                            @include flex-box(flex-start, center);
                            gap: 16px;
                            margin-bottom: 1px;

                            .book-name {
                                font-size: $md;
                                font-weight: 700;
                                line-height: 21.82px;
                                color: $color-gray-950;
                            }
                        }

                        .pdf-btns-group {
                            @include flex-box(flex-end, center);
                            gap: 10px;

                            .button-green {
                                font-size: 15px;
                                line-height: 20.46px;
                                color: $color-gray-950;
                                border: 1px solid $color-gray-1000;
                                border-radius: $border-radius-md;
                                width: 134px;
                                height: 28px;
                                background-color: unset;
                                padding: 3px 6px;
                                @include flex-box(center, center);
                                gap: 6px;

                                span {
                                    padding-top: 1px;
                                }
                            }
                        }
                    }

                    .book-box.disable {
                        opacity: 0.5;
                    }

                    .report-row {
                        padding: 4px 17px 2px 35px;
                        @include flex-box(flex-start, center);
                        width: 100%;
                        max-width: unset;
                        height: 79px;

                        .label {
                            font-size: $sm;
                            font-weight: 700;
                            line-height: 19px;
                            color: $color-gray-900;
                            display: block;
                            text-align: center;
                        }

                        .formativeAssessment-name {
                            display: inline-block;
                            width: 200px;
                            font-size: $md;
                            font-weight: 700;
                            line-height: 21.82px;
                            color: $color-gray-950;
                            padding-right: 10px;
                        }

                        .last-activity {
                            width: 126px;
                            height: 44px;
                            text-align: center;
                            margin-right: 30px;

                            .date-activity {
                                font-size: $md;
                                font-weight: 400;
                                line-height: 21.82px;
                                color: $color-gray-950;
                                display: block;
                                margin-top: 3px;
                            }
                        }

                        .progress-column {
                            width: 120px;
                            text-align: center;

                            .progressBlock {
                                display: block;
                                text-align: center;
                                margin-top: 1px;

                                svg {
                                    margin-top: -3px;
                                }

                                p {
                                    margin-top: 0px;
                                }

                                .disabled {
                                    margin-top: 3px;
                                }

                                .no-data-progress-skeleton {

                                    p {
                                        height: 18px;
                                        margin-top: 3px !important;
                                    }
                                }
                            }
                        }

                        .view-column {
                            width: 70px;
                            text-align: center;
                            margin-left: 10px;
                            cursor: unset;

                            svg {
                                margin-top: 6px;
                            }

                            img {
                                margin-top: 6px;
                                height: 24px;
                            }
                        }

                        .edit-column {
                            width: 70px;
                            text-align: center;
                            cursor: unset;

                            svg {
                                margin-top: 6px;
                                height: 24px;
                            }
                        }

                        .report-name-column {
                            width: 80px;
                            text-align: center;
                            margin-top: 6px;

                            .reportName {
                                border: 1px solid $color-gray-1000;
                                border-radius: $border-radius-md;
                                @include flex-box(center, center);
                                width: 54px;
                                height: 33px;
                                margin: 0 auto;
                                font-size: $md;
                                font-weight: 400;
                                line-height: 21.82px;
                                color: $color-gray-950;
                                padding-top: 2px;
                            }
                        }

                        .remediate-column {
                            width: 80px;
                            text-align: center;
                            cursor: unset;

                            .reme-person {
                                @include flex-box (center, center);
                                gap: 6px;
                                height: 24px;
                                font-size: $md;
                                font-weight: 700;
                                line-height: 20.82px;
                                color: $color-gray-950;
                                margin-top: 4px;
                            }
                        }

                        .available-students-column {
                            width: 115px;
                            text-align: center;
                            padding-top: 0px;
                            height: 70px;

                            span {
                                display: block;
                            }

                            .complete-toggle-container {
                                margin-top: 3px;

                                .switch {
                                    margin-bottom: 0px;
                                }
                            }

                            .many-students {
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 16px;
                                color: $color-gray-950;
                                display: block;
                                text-align: center;
                                height: 16px;
                                margin-top: 3px;
                            }
                        }

                        .monitor-button {
                            width: 112px;
                            padding-top: 4px;

                            .turnMonitorContainer {

                                svg {
                                    color: white;
                                    margin-top: -2px;
                                }

                                .turnMonitor {
                                    line-height: 14px;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    
                }

                .accordion-body-open {
                    height: 191px;
                    transition: 0.4s all;
                    overflow: hidden;
                    padding-bottom: 14px;
                }

                .accordion-body {
                    padding-bottom: 0;
                    height: 0;
                    transition: 0.850s all !important;
                }

                .monitor-button-skeleton {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.report-bar-container {
    position: absolute;
    top: 7px;
    right: 24px;
    opacity: 1;
    z-index: 1;
    // transition: 0.4s all;

    @include media-breakpoint(md) {
        overflow-y: hidden;
        overflow-x: auto;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.close-report-bar {
    @include flex-box(flex-end, center);
    gap: 10px;
    width: 560px;
    // height: auto;
    // opacity: 1;
    // overflow: unset;
    // transition: 0.4s all;

    .report-name {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: $color-gray-950;
        width: 64px;
        margin-top: 0px;
    }

    .last-activity {
        width: 72px;
        text-align: center;

        .label {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            color: $color-gray-950;
            margin-bottom: 0;
        }

        .date {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: $color-gray-950;
            margin-bottom: 0;
            margin-top: -3px;
        }
    }

    .progressBlock {
        width: 64px;
        height: 27px;
        text-align: center;
        margin-top: -10px;

        @include media-breakpoint(md) {
            margin-top: -6px;
        }

        svg {
            width: 100%;
            height: 13px;
        }

        p {
            font-size: 12px;
            font-weight: 700;
            line-height: 12px;
            color: $color-gray-950;
            margin-top: -1px;
        }
    }

    .progressBlock.skeleton {
        margin-top: -4px;

        div {
            width: 100%;
            height: 13px;
            background-color: #a1a1a1;
            border-radius: $border-radius-md;
        }

        p {
            width: 28px;
            height: 12px;
            background-color: #a1a1a1;
            border-radius: $border-radius-md;
            margin: 3px auto 0;
        }
    }

    .remediate-column {
        @include flex-box(center, center);
        gap: 4px;
        width: 36px;
        margin-top: 0px;

        span {
            font-size: $sm;
            font-weight: 700;
            color: $color-gray-950;
        }
    }

    .complete-toggle-container.small-toggle {
        padding-top: 0px;
        padding-left: 2px !important;
        margin-left: 0 !important;
        margin-top: 0px !important;

        p {
            color: $color-gray-950 !important;
            margin-top: 0;
            margin-right: 8px;
        }

        .switch {
            margin-bottom: 0;
        }
    }
}

.report-bar-container.show {
    display: none !important;
}