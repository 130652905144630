@mixin section {
  border-radius: $border-radius-md;
  // box-shadow: $section-shadow;
  overflow: hidden;
}

@mixin lineClamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flexcenter-both {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Media queris
@mixin media-breakpoint($breakpoint) {
  @if $breakpoint == sm {
    @media only screen and (max-width: 575.98px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (max-width: 767.98px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (max-width: 991.98px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media only screen and (max-width: 1199.98px) {
      @content;
    }
  }

  @if $breakpoint == xxl {
    @media only screen and (max-width: 1399.98px) {
      @content;
    }
  }
}

@mixin custom-break-poitn($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin desktop-up {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

// Support for Safari
@mixin safari-suport {
  @media only screen and (min-width: 1024px) {
    @media not all and (min-resolution:.001dpcm) {
      @content;
    }
  }
}

@mixin safari-print {
  @media (min-width: 10px) {
    @media not all and (min-resolution:.001dpcm) {
      @content;
    }
  }
}

// Mozilla Support
@mixin mozilla-suport {
  @-moz-document url-prefix() {
    @content;
  }
}

// Section Heading
@mixin secHeading($mt, $mb) {
  font-size: $xl;
  line-height: 29.96px;
  font-weight: 700;
  color: white;
  font-family: $font-openSans;
  margin-top: $mt;
  margin-bottom: $mb;
}

@mixin buttonGreen($w, $h, $padding, $radius) {
  width: $w;
  height: $h;
  padding: $padding;
  border-radius: $radius;
  font-size: $md;
  font-weight: 400;
  line-height: 21.82px;
  color: white;
  background-color: $color-green;
  border: unset;
}