.based-report {
  @include section;
  margin-top: 22px;

  &-container {
    background-color: $color-gray-500;
  }

  &__info {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 12px 15px;

    @include media-breakpoint(lg) {
      flex-direction: column;
      align-items: center;
    }

    &-wrapper {
      max-width: 348px;
      width: 100%;
      margin-top: 20px;
    }

    &-text {
      font-size: 16px;
      color: $color-gray-850;
      line-height: 20px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 14px;
      }
    }

    &-search {
      position: absolute;
      left: 25px;
      top: 15px;
      display: flex;

      @include media-breakpoint(lg) {
        order: 2;
        align-self: flex-start;
        position: relative;
        left: auto;
        top: auto;
        margin-top: 40px;
      }

      input {
        font-size: 14px;
        width: 185px;
        margin-left: 1px;
      }
    }
  }

  &__search {
    max-width: 666px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 21px auto 2px auto;

    @include media-breakpoint(md) {
      max-width: 100%;
      padding: 0 15px;
    }

    input {
      font-size: 14px;
      width: 185px;
      margin-left: 1px;

      @include media-breakpoint(md) {
        width: 160px;
      }
    }
  }

  &-main {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;

    @include media-breakpoint(md) {
      justify-content: flex-start;
      overflow-x: auto;
    }
  }

  &-table-container {
    max-width: 638px;
    width: 100%;

    @include media-breakpoint(md) {
      overflow-y: auto;
    }
  }

  &__table {
    color: $color-black;

    th {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      padding-top: 4px;
      padding-bottom: 4px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }
    }

    &-row {
      border-radius: $border-radius-md;
      overflow: hidden;

      &:nth-child(odd) {
        background-color: $color-gray;
      }

      td {
        vertical-align: middle;
        font-size: 16px;
        line-height: 21px;
        padding-top: 4.5px;
        padding-bottom: 4.5px;

        @include media-breakpoint(md) {
          font-size: 14px;
        }

        @include safari-suport {
          padding-top: 4px;
          padding-bottom: 5px;
        }
      }
    }

    &-id {
      min-width: 130px;
      text-align: center;
      vertical-align: bottom;
      border-radius: 8px 0 0 8px;

      @include media-breakpoint(md) {
        min-width: 120px;
      }
    }

    &-student {
      min-width: 200px;
      max-width: 200px;
      text-align: right;
      vertical-align: bottom;
      padding-right: 0px;
      padding-left: 0px;

      @include media-breakpoint(md) {
        min-width: 160px;
        max-width: 160px;
        padding-left: 2px;
      }
    }

    &-english {
      min-width: 158px;
      text-align: center;
      padding-left: 30px;

      @include media-breakpoint(md) {
        min-width: 70px;
        padding-left: 0;
      }
    }

    &-spanish {
      min-width: 140px;
      text-align: center;
      padding-right: 5px;
      border-radius: 0 8px 8px 0;

      @include media-breakpoint(md) {
        min-width: 70px;
      }
    }

    &-language {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border: 1px solid $color-blue-800;
      font-size: 16px;
      color: $color-blue-800;
      font-weight: 600;
      line-height: 20px;
      margin: 0 auto;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        border-color: darken($color-blue-800, 10%);
        color: darken($color-blue-800, 10%);
      }

      &.spanish {
        border-color: $color-green-600;
        color: $color-green-600;

        &:hover {
          border-color: darken($color-green-600, 7%);
          color: darken($color-green-600, 7%);
        }
      }
    }
  }

  &-detail-container {
    width: 100%;
    padding-top: 41px;

    @include media-breakpoint(lg) {
      padding-top: 0;
    }

    @include media-breakpoint(md) {
      min-width: 742px;
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    color: $color-black;

    &-header {
      display: flex;
      padding: 0 134px;

      @include media-breakpoint(xl) {
        padding: 0 80px;
      }

      @include media-breakpoint(lg) {
        padding: 0 20px;
      }

      @include media-breakpoint(md) {
        padding: 0 16px;
      }
    }

    th {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      padding-top: 1px;
      padding-bottom: 3px;
    }

    &-row {
      display: flex;
      padding: 0 134px;

      @media print {
        break-inside: avoid;

        @include mozilla-suport {
          padding: 0;
        }
      }

      @include media-breakpoint(xl) {
        padding: 0 80px;
      }

      @include media-breakpoint(lg) {
        padding: 0 20px;
      }

      @include media-breakpoint(md) {
        padding: 0 16px;
      }

      &:nth-child(odd) {
        background-color: $color-gray;
      }

      td {
        vertical-align: middle;
        font-size: 16px;
        line-height: 21px;
      }
    }

    &-objective {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 75px;
    }

    &-description {
      flex: 1;
      display: flex;
      align-items: flex-end;
      padding-left: 32px;
      padding-right: 32px;
    }

    &-weeks {
      width: 166px;
      text-align: center;
    }

    &-headerWeeks {
      display: flex;

      .week {
        width: 41px;
        padding-right: 2px;
      }
    }

    &-objectiveData {
      width: 75px;
      text-align: right;
      padding-top: 11px;
      padding-bottom: 10px;

      @include safari-suport {
        padding-top: 9px;
        padding-bottom: 12px;
      }
    }

    &-descriptionData {
      flex: 1;
      padding: 11px 32px 10px 32px;

      @include safari-suport {
        padding-top: 9px;
        padding-bottom: 12px;
      }

      &.margin {
        margin: 0 2px;
      }
    }

    &-attemptData {
      width: 150px;
      text-align: center;
      padding-top: 11px;
    }

    &-weeksData {
      display: flex;
      width: 166px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__week {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 41px;
    border-right: 2px solid $theme-border-color;
    padding-top: 13px;

    @include safari-suport {
      padding-top: 12px;
    }

    &:first-child {
      width: 43px;
      border-left: 2px solid $theme-border-color;
    }

    &-data {
      width: 16px;
      height: 16px;
      box-shadow: 2px 2px 1px rgba($color-black, 0.22);

      &.proficient {
        background-color: $color-green-600;
      }

      &.non-proficient {
        background-color: $color-red-200;
      }
    }
  }

  .box {
    width: 16px;
    height: 16px;
    margin-right: 7px;
    box-shadow: 2px 2px 1px rgba($color-black, 0.22);

    &.proficient {
      background-color: $color-green-600;
    }

    &.non-proficient {
      background-color: $color-red-200;
    }
  }
}

// Print
.based-report-print {
  padding-top: 18px;

  .box {
    width: 16px;
    height: 16px;
    margin-right: 7px;

    @media print {
      @include mozilla-suport {
        width: 14px;
        height: 14px;
      }
    }

    &.non-proficient {
      border: 1px solid $color-red-200;
    }

    &.check-marked {
      padding-left: 3px;
      height: 14px;
    }
  }

  .empty {
    width: 16px;
    height: 16px;
  }

  &__table {
    width: 100%;
  }

  &__main {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: $color-white;

    &__text {
      width: 100%;
      display: flex;
      justify-content: center;

      &__div {
        width: 100%;
        max-width: 348px;
        margin-top: 16px;
      }

      p {
        color: $color-gray-850;
      }
    }

    .info-section {
      height: 145px;
    }

    .detail-info {
      transform: translate(51%, 0%);
      height: 100px;
    }

    & .table {
      & .table-header {
        tr {
          display: flex;
          padding: 0 134px;

          @media print {
            @include mozilla-suport {
              padding: 0;
            }
          }

          &.download-row {
            padding: 0;
          }

          th {

            @media print {
              @include mozilla-suport {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }

        .maintext {
          font-size: 16px;
          font-weight: 700;
          color: $color-black !important;

          @media print {
            @include mozilla-suport {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }

        &__objective {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          width: 75px;

          @media print {
            @include mozilla-suport {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }

        &__description {
          flex: 1;
          display: flex;
          align-items: flex-end;
          padding-left: 32px;
          padding-right: 32px;

          @media print {
            @include mozilla-suport {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }

        &__nine {
          width: 166px;
          text-align: center;

          @media print {
            @include mozilla-suport {
              width: 124px;
              font-size: 10px;
              line-height: 16px;
            }
          }

          .weeks {
            display: flex;

            .week {
              width: 41px;
              text-align: center;
              padding-right: 2px;

              @media print {
                @include mozilla-suport {
                  width: 31px;
                }
              }
            }
          }
        }
      }

      .based-report__detail-row {
        margin: 0 !important;

        .based-report__detail-weeksData {

          .based-report__week {
            height: 100%;
            display: block;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }

      tr {
        display: flex;
        padding: 0 134px;
        border: none;
        background-color: unset !important;

        @media print {
          break-inside: avoid;

          @include mozilla-suport {
            padding: 0;
          }
        }

        &:last-child {

          .table__description::after,
          .table__nine::after {
            border: none !important;
          }
        }

        &.download-row {
          padding: 0;
        }
      }

      td {
        vertical-align: middle;
        font-size: 16px;
        line-height: 21px;
      }

      p {
        margin-bottom: 0;
      }

      .maintext {
        font-size: 16px;
        color: $color-black !important;
        line-height: 20px;

        @media print {
          @include mozilla-suport {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      &__objective {
        width: 75px;
        text-align: right;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &__description {
        flex: 1;
        position: relative;
        text-align: left;
        padding: 10px 32px;

        &::after {
          content: "";
          position: absolute;
          left: 32px;
          bottom: 0;
          width: 100%;
          border-bottom: 1px dashed $color-light-400 !important;
        }
      }

      &__nine {
        position: relative;
        display: flex;
        width: 166px;
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        @media print {
          @include mozilla-suport {
            width: 124px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          border-bottom: 1px dashed $color-light-400 !important;
        }

        .weeks {
          position: relative;
          display: flex;
          justify-content: center;
          min-height: 100%;
          padding: 0 !important;
          z-index: 4;

          div {
            div {
              margin-right: 0;
            }
          }
        }

        .weeks>div {
          display: flex;
          justify-content: center;
          width: 41px;
          border-right: 2px solid $theme-border-color;
          padding-top: 11px;

          @media print {
            @include mozilla-suport {
              border-right: 1px solid $theme-border-color;
              width: 30px;
            }
          }

          &:first-child {
            width: 43px;
            border-left: 2px solid $theme-border-color;

            @media print {
              @include mozilla-suport {
                border-left: 1px solid $theme-border-color;
                width: 31px;
              }
            }
          }
        }
      }
    }
  }
}

// Download
.based-report-download {
  &__main {
    border: none !important;
  }

  .report__levels {
    padding: 14px 0 24px 0;

    p {
      font-size: 11px;
    }
  }

  .box {
    width: 11px;
    height: 11px;
    margin-right: 6px;
    font-size: 8px;
    line-height: 11px;

    &.non-proficient {
      border: 1px solid $color-red-200;
    }

    &.cheked-mark {
      padding-left: 3px;
      transform: translateY(0px);
    }
  }

  .empty {
    width: 11px;
    height: 11px;
  }

  &__table {
    width: 100%;
  }

  &__main {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: $color-white;

    &__text {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 11px;
      line-height: 15px;

      &__div {
        width: 100%;
        max-width: 348px;
        margin-top: 16px;
        font-size: 11px;
        line-height: 15px;
      }

      p {
        color: $color-gray-850;
      }
    }

    .info-section {
      height: 145px;
    }

    .detail-info {
      transform: translate(51%, 0%);
      height: 100px;
      font-size: 11px;
      line-height: 15px;
    }

    & .table {
      & .table-header {
        tr {
          display: flex;
          padding: 0 60px;
        }

        .maintext {
          font-size: 11px;
          line-height: 15px;
          font-weight: 700;
          color: $color-black !important;
        }

        &__objective {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          width: 75px;
          font-size: 11px;
          font-weight: bold;
          line-height: 15px;
        }

        &__description {
          flex: 1;
          display: flex;
          align-items: flex-end;
          padding-left: 20px;
          padding-right: 20px;
          font-size: 11px;
          font-weight: bold;
          line-height: 15px;
        }

        &__nine {
          width: 106px;
          text-align: center;
          font-size: 11px;
          font-weight: bold;
          line-height: 15px;

          .weeks {
            display: flex;

            .week {
              width: 26px;
              text-align: center;
              padding-right: 2px;
            }
          }
        }
      }

      tr {
        display: flex;
        padding: 0 60px;
        border: none;

        &:last-child {

          .table__description::after,
          .table__nine::after {
            border: none !important;
          }
        }
      }

      td {
        vertical-align: middle;
        font-size: 11px;
        line-height: 15px;
      }

      p {
        margin-bottom: 0;
      }

      .maintext {
        font-size: 11px;
        line-height: 15px;
        color: $color-black;
      }

      &__objective {
        width: 75px;
        text-align: right;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      &__description {
        flex: 1;
        position: relative;
        text-align: left;
        padding: 8px 20px;

        &::after {
          content: "";
          position: absolute;
          left: 20px;
          bottom: 0;
          width: 100%;
          border-bottom: 1px dashed $color-light-400 !important;
        }
      }

      &__nine {
        position: relative;
        display: flex;
        width: 106px;
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          border-bottom: 1px dashed $color-light-400 !important;
        }

        .weeks {
          position: relative;
          display: flex;
          justify-content: center;
          min-height: 100%;
          padding: 0 !important;
          z-index: 4;

          div {
            div {
              margin-right: 0;
            }
          }
        }

        .weeks>div {
          display: flex;
          justify-content: center;
          width: 26px;
          border-right: 1px solid $theme-border-color;
          padding-top: 11px;

          &:first-child {
            width: 27px;
            border-left: 1px solid $theme-border-color;
          }

          &:last-child {
            border-right: 1px solid $theme-border-color;
          }
        }
      }
    }
  }
}

.standard-based-pdf {

  .based-report-print__main {

    .standard-based-report {
      border-color: #194792;

      .title {
        font-size: 14px !important;
      }

      .print-report__header-text {
        font-size: 14px !important;
      }
    }

    .report__levels.based__levels {

      .report__levels-container {
        width: 350px;

        .report__levels-title {
          font-size: 14px;
        }

        .report__levels-percentage {

          .report__levels-row {
            width: 250px;
            margin-left: -10px !important;

            .box {}

            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    .table {

      table {
        border-spacing: 0px !important;

        thead {

          // .based-report__detail-header {
          //   padding: 0 !important;
          // }
          th {
            font-size: 15px !important;
            line-height: 19px !important;
            font-weight: 700 !important;
            padding-left: 15px !important;
            padding-right: 15px !important;
          }

          .based-report__detail-weeks {
            width: 160px !important;
            min-width: 160px !important;
            max-width: 160px !important;

            .based-report__detail-headerWeeks {
              margin-bottom: -10px;

              .week {
                text-align: center !important;
                width: 40px !important;
              }
            }
          }

          .based-report__detail-objective {
            width: 120px !important;
            min-width: 120px !important;
          }

          .based-report__detail-description {
            width: 438px !important;
            min-width: 438px !important;
          }
        }

        tbody {

          // .based-report__detail-row {
          //   padding: 0 !important;
          // }

          tr {

            td {
              font-size: 14px !important;
              line-height: 16px !important;
              border: 0px !important;
              border-spacing: 0px !important;
              padding: 8px 15px !important;
              position: relative;

              &::before {
                content: "";
                position: absolute;
                bottom: 0;
                height: 1px;
                width: 100%;
                border-bottom: 1px dashed #e3e3e3 !important;
              }
            }

            .based-report__detail-objectiveData {
              width: 120px !important;
              min-width: 120px !important;

              &::before {
                display: none;
              }
            }

            .based-report__detail-descriptionData {
              width: 438px !important;
              min-width: 438px !important;
            }

            .based-report__detail-weeksData {
              width: 160px !important;
              min-width: 160px !important;
              max-width: 160px !important;

              &::before {
                width: calc(100% - 30px);
              }

              .based-report__week {
                text-align: center;
                padding: 0 5px;
                border: 0px !important;
                position: relative;
                width: 40px !important;

                img {
                  width: 12px;
                }

                .based-report__week-data {
                  border-radius: 0px !important;
                  width: 16px !important;
                  height: 16px !important;
                  margin: 0 auto;
                  display: block !important;
                  background-color: unset !important;
                  border: 1px solid $color-red-200;
                }

                &::before {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 1px;
                  height: calc(100% + 18px);
                  background-color: #194792;
                }

                &:first-child {

                  &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 1px;
                    height: calc(100% + 18px);
                    background-color: #194792;
                  }
                }
              }
            }

            &:last-child {

              td {
                &::before {
                  display: none;
                }
              }

              .based-report__detail-weeksData {

                // &::before {
                //   height: calc(100% + 14px);
                // }

                // &:first-child {

                //   &::after {
                //     height: calc(100% + 14px);
                //   }
                // }
              }
            }
          }
        }
      }
    }
  }
}

.performance-report-pdf {

  .print-report__header {

    .title {
      font-size: 12px !important;
      line-height: 18px !important
    }

    p {
      margin-bottom: 5px !important;
    }
  }

  .performance__perecentage-contentainer {

    .performance__perecentage-score {

      .summ_form_label {
        

        p {
          text-align: left !important;
          padding-left: 40px;
        }
      }

      .summ_form {

        p {

          .level-box {
            transform: translateY(2px) !important;
          }
        }
      }
    }
  }

  .accordion__content {



    .table-responsive {
      overflow: unset !important;
    }

    table {

      thead {

        .reportCardPerformanceDetails__table-completed {
          width: 280px !important;
          min-width: 280px !important;
          max-width: 280px !important;
          padding-right: 10px !important;
          margin-left: 0 !important;
        }

        .reportCardPerformanceDetails__table-date-attempts {
          width: 70px !important;
          min-width: 70px !important;
          max-width: 70px !important;
        }

        .reportCardPerformanceDetails__table-score {
          width: 145px !important;
          min-width: 145px !important;
          max-width: 145px !important;
          text-align: center !important;
        }

        .attempts-count {
          width: 70px !important;
          min-width: 70px !important;
          max-width:70px !important;
          text-align: left !important;
          padding-left: 10px;
        }

        .reportCardPerformanceDetails__table-attempt {
          width: 103px !important;
          min-width: 103px !important;
          max-width: 103px !important;
          text-align: center !important;
        }
      }

      tbody {

        tr {

          .completed-data {
            width: 280px !important;
            min-width: 280px !important;
            max-width: 280px !important;
            padding-right: 10px !important;
            margin-left: 0px !important;
          }

          .attempt-date-data {
            width: 70px !important;
            min-width: 70px !important;
            max-width: 70px !important;
          }

          .reportCardPerformanceDetails__table-attempts {
            width: 145px !important;
            min-width: 145px !important;
            max-width: 145px !important;
            padding-left: 45px !important;
            text-align: left !important;

            .variant {
              padding: 1px 3px;
              font-size: 10px;
              line-height: 10px;
              height: 16px;
              left: -2px;
              top: 6px;
            }

            &.attempts-count {
              padding-left: 32px !important;
              text-align: left !important;
              width: 70px !important;
              min-width: 70px !important;
              max-width: 70px !important;
            }


            .level-box {
              width: 14px !important;
              height: 14px !important;
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }
  }

}