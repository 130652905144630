.SupportTicket {
  position: relative;
  margin-top: 22px;

  &__TicketInfo {
    border-radius: $border-radius-md;

    &__Header {
      padding: 10px 16px;
      padding-right: 35px;

      @media (max-width: 576px) {
        flex-direction: column;
        padding-right: 16px;
      }

      &__text {
        color: #ffffff;
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        font-family: $font-openSans;
      }

      &__button {
        height: 33px;
        width: 132px;
        color: white;
        border: 1px solid white;
        outline: none;
        background-color: transparent;
        font-size: 16px;
        border-radius: $border-radius-md;

        @media (max-width: 576px) {
          width: 100%;
          margin-top: 20px;
        }
      }
    }

    &__table {
      min-width: 1200px;
      display: none;

      &__ticketMessageColumn {
        padding-left: 40px;
      }

      &__thead {
        background-color: #2a5391;
        height: 30px;
        font-weight: 700;

        tr {
          text-shadow: 0px 3px 6px #0000008e;

          .title {
            padding-left: 45px;
          }
        }
      }

      &__tbody {
        .redboxAlert {
          height: 37px;

          .redbox {
            content: "";
            height: 16px;
            width: 16px;
            background-color: #c44538;
            border-radius: 50%;
          }
        }

        &__info {
          .title {
            padding-left: 45px;
          }
        }

        .title-withbox {
          padding-left: 22px;
        }

        tr {
          height: 37px;

          .status-bg {
            background-color: #c44538 !important;
            margin: 0px;
            border-radius: 40px;
            text-align: center;
            font-size: 14px;
            padding: 2px 12px;
          }

          .bg-green {
            background-color: #46afb8 !important;
          }

          .Subbmitted,
          .InProgress,
          .NeedMoreInfo,
          .Resolved {
            background-color: #eadb4a;
            margin-bottom: 0px;
            color: #424242;
            width: 89px;
            border-radius: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
          }

          .InProgress {
            background-color: #194792;
            color: #fff;
          }

          .NeedMoreInfo {
            background-color: #c44538;
            color: #fff;
            width: 121px;
          }

          .Resolved {
            background-color: #46afb8;
            color: #fff;
          }
        }

        tr:nth-child(odd) {
          td:nth-child(1),
          td:nth-child(2),
          td:nth-child(3) {
            background-color: $theme-bg-color-lighter;
          }

          td:nth-child(4),
          td:nth-child(5) {
            background-color: #adadad;
            border-color: #adadad;
          }
        }

        tr:nth-child(even) {
          td:nth-child(4),
          td:nth-child(5) {
            background-color: #b9b9b9;
            border-color: #b9b9b9;
          }
        }

        tr:last-child {
          td:first-child {
            border-bottom-left-radius: 8px;
          }

          td:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
    .tableContainer {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &__messageViewer {
    margin-top: 22px;
    border-radius: $border-radius-md;

    &__header {
      border-radius: 8px 8px 0px 0px;
      padding-top: 13px;
      padding-bottom: 14px;
      padding-left: 20px;
      padding-right: 35px;

      @media (max-width: 576px) {
        flex-direction: column;
        padding-right: 22px;
      }

      &__text {
        font-weight: 500;
      }

      &__btn {
        border: 1px solid white;
        background-color: transparent;
        color: white;
        border-radius: $border-radius-md;
        outline: none;
        width: 95px;
        height: 33px;

        @media (max-width: 576px) {
          width: 100%;
          margin-top: 20px;
        }
      }

      .SupportTicket__messageViewer__text {
        color: #ffffff;
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        font-family: $font-openSans;
      }
    }

    &__body {
      @media (max-width: 768px) {
        flex-direction: column;
      }

      &__info {
        padding: 0px 23px 0px 24px;
        background-color: #b9b9b9;
        color: #000;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        width: 100%;
        max-width: 996px;
        position: relative;

        @media (max-width: 768px) {
          padding-bottom: 30px;
          border-bottom-left-radius: unset;
        }

        .tagline {
          border-bottom: 1px solid #707070;
          padding: 10px 0px 9.5px;
        }

        &__replied {
          background-color: #acacac;
          border-radius: $border-radius-md;
          padding: 10px 14px;
          margin-top: 50px;

          &__tag {
            background-color: #373c44;
            width: 208px;
            font-size: 14px;
            border-radius: 40px;
            text-align: center;
            display: flex;
            justify-content: center;
            position: relative;
            top: -20px;
            left: 16px;
            height: 18px;
            line-height: 19px;
          }

          &__message {
            color: #464646;

            span {
              p {
                color: #464646;
              }
            }
          }

          &__attachment {
            color: #424242;
            margin-right: 20px;
          }

          &__viewMessage {
            color: #194792;
            font-weight: 700;
          }
        }

        &__message-scroll {
          height: 492px;
          overflow-y: auto;
          .text-black {
            p {
              color: #464646;
            }

            ul,
            ol {
              li {
                color: #464646;
              }
            }
          }
        }

        &__message-scroll::-webkit-scrollbar {
          display: none;
        }

        &__selectMessage {
          color: #424242;
          font-size: 20px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin-bottom: 0;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .message-box {
          p {
            color: #000000;
          }
        }
      }

      &__attachments {
        background-color: #424242;
        width: 100%;
        max-width: 246px;
        padding: 11px 16px 43px 24px;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;

        @media (max-width: 768px) {
          width: 100%;
          max-width: 100%;
          border-radius: 0px 0px 8px 8px;
        }

        &__text {
          font-weight: 700;
          // text-shadow: 0px 3px 6px #0000008e;
        }

        &__Image {
          margin-top: 31px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          img {
            height: 83px;
            width: 66px;
            margin: 0 auto;
          }

          p {
            text-align: center;
            width: 130px;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 9.54px;
            overflow: hidden;
            margin-bottom: 0;
            height: 22px;
            padding: 0 !important;
          }
        }
      }
    }
  }

  &__chatSupportImg {
    position: fixed;
    right: 17%;
    bottom: 10px;
    z-index: 99;

    @media (max-width: 1290px) {
      right: 0px;

      img {
        height: 90px;
      }
    }
  }

  .bg-gray {
    background-color: #505050 !important;
  }

  .bg-gray-200 {
    background-color: #424242 !important;
  }

  .text-blue {
    color: #194792;
    font-weight: 700;
  }

  .pulse {
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(71, 136, 255, 0.4);
    animation: pulse 3s infinite;
  }

  .pulse:hover {
    animation: none;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.97);
      -moz-box-shadow: 0 0 0 0px rgba(71, 136, 255, 0.4);
      box-shadow: 0 0 0 0px rgba(71, 136, 255, 0.4);
    }

    70% {
      transform: scale(1);
      -moz-box-shadow: 0 0 0 12px rgba(71, 136, 255, 0);
      box-shadow: 0 0 0 12px rgba(71, 136, 255, 0);
    }

    100% {
      transform: scale(0.97);
      -moz-box-shadow: 0 0 0 0 rgba(71, 136, 255, 0);
      box-shadow: 0 0 0 0 rgba(71, 136, 255, 0);
    }
  }

  &__ReplayModal {
    width: 100%;
    max-width: 1073px;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $border-radius-md;
    padding-right: 0 !important;
    padding-left: 0 !important;
    z-index: 99999;
    display: flex !important;
    justify-content: center;
    align-items: center;
    overflow: unset;

    @media (max-width: 1119px) {
      max-width: 90%;
    }

    .modal-dialog-centered {
      min-height: auto !important;
      margin: 0;
      width: 100%;
    }

    .modal-content {
      background-color: rgba(54, 54, 54, 0.87) !important;
      width: 100%;
      border-radius: 0px;
      box-shadow: unset;
      background-color: unset;
      padding: 10px 20px;
      height: 93vh;
      overflow: auto;

      @media (max-width: 1023px) {
        padding: 10px;
      }
    }

    &__ModalHeader {
      font-size: 22px;
      font-weight: 500;
      line-height: 25.78px;
      color: white;
      font-family: $font-openSans;
    }

    &__BodyContent {
      padding: 0 6px 0 8px;
      display: flex;
      margin-top: 53px;

      @media (max-width: 767px) {
        display: block;
        margin-top: 30px;
      }

      .send_btn_width {
        width: 88px;
        max-width: unset;
      }
    }

    &__LeftSec {
      width: calc(100% - 246px);
      padding-right: 17px;

      @media (max-width: 1023px) {
        width: calc(100% - 190px);
        padding-right: 10px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
      }

      .add-admin-mb {
        margin-bottom: 45px;
      }

      &__submit {
        background-color: #424242;
        border-radius: $border-radius-md;
        padding: 29px 31px 7px 30px;
        margin-bottom: 36px;
        max-width: 100% !important;

        @media (max-width: 991px) {
          padding: 14px 20px 12px 20px;
        }

        @media (max-width: 767px) {
          padding: 12px 10px;
        }

        .label-top {
          background-color: #373c44;
          border-radius: 40px;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          color: white;
          padding: 0 17px;
          position: absolute;
          top: -8px;
          margin-bottom: 0;
        }

        .admin-detail {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-top: 5.5px;

          @media (max-width: 991px) {
            flex-wrap: wrap;
          }

          @media (max-width: 767px) {
            display: block;
          }

          .detail-cols {
            width: 100%;
            max-width: 275px;

            @media (max-width: 991px) {
              max-width: unset;
              width: 50%;
            }

            @media (max-width: 767px) {
              width: 100%;
            }

            .labels {
              font-size: 14px;
              font-weight: 700;
              line-height: 19px;
              color: white;
              margin-bottom: 5px;
              text-align: right;
              display: block;
              width: 100%;
              max-width: 90px;
            }

            .value {
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: white;
              margin-bottom: 5px;
              display: block;
              margin-left: 14px;
              word-break: break-word;
            }
          }

          .removeBtn {
            @media (max-width: 991px) {
              width: 50%;
              margin: 0 auto;
              margin-top: 15px;
            }

            @media (max-width: 767px) {
              width: 100%;
            }

            button {
              font-size: 16px;
              font-weight: 400;
              color: white;
              background-color: #c44538;
              border-radius: $border-radius-md;
              padding: 5.5px 22px;
              border: 0;

              @media (max-width: 991px) {
                width: 100%;
              }
            }
          }
        }

        .PacingCalender__SuggestedModel__Suggestion {
          margin-top: 19px;
          width: 121px;
          margin-bottom: 16px;
        }
      }

      &__ticketType {
        background-color: #424242;
        border-radius: 12px;
        margin-top: 36px;
        padding-left: 18px;
        padding-right: 14px;
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;

        @media (max-width: 767px) {
          padding: 12px 10px;
        }

        .modal__form-row {
          width: 100%;
          max-width: 100%;

          // @media (max-width: 991px) {
          //     max-width: 300px;
          // }

          // @media (max-width: 767px) {
          //     max-width: 100%;
          // }

          select {
            width: 354px;
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            color: #424242;
            position: absolute;
            top: -30px;

            @media (max-width: 991px) {
              width: 290px;
            }

            @media (max-width: 767px) {
              width: 100%;
              height: 36px;
              font-size: 14px;
              line-height: 16px;
            }
          }

          p {
            width: 100%;
            text-align: center;
            padding-top: 20px;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            color: white;
            margin: 33px 0px 56px 0px;
            text-shadow: 0px 3px 6px #0000008e;
          }

          .input-fields {
            position: relative;
            margin-top: 29px;
            width: 100%;

            label {
              font-size: 14px;
              font-weight: 700;
              line-height: 14px;
              color: white;
              padding: 2px 10.5px;
              position: absolute;
              top: -11px;
              text-align: center;
              height: 18px;

              @media (max-width: 767px) {
                font-size: 12px;
              }
            }

            input {
              font-size: 18px !important;
              font-weight: 400;
              line-height: 20px;
              color: #424242;
              border-radius: $border-radius-md;
              font-size: 14px;
              line-height: 16px;
              height: 42px;
              padding: 7px 12px;
            }
          }

          .page-number-field {
            width: 166px;
          }

          .input-fields:last-child {
            margin-top: 17px;
          }
        }

        .required-fields {
          max-width: 383px;
        }

        .required {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #d5d5d5;
          margin-bottom: 0;

          @media (max-width: 767px) {
            text-align: right;
            margin-top: 8px;
          }
        }
      }

      &__LeftContent {
        background-color: #acacac;
        padding: 11px 34px 23px 21px;
        border-radius: $border-radius-md;
        opacity: 1;
        min-height: 298px;
        position: relative;

        &__replyTo {
          background-color: #373c44;
          border-radius: 40px;
          padding: 0px 5px;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          color: white;
          width: 100%;
          max-width: 204px;
          position: absolute;
          top: -12px;
          margin-left: 5px;
          margin-bottom: 0px;
        }

        &__text {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #464646;
          margin-top: 12px;

          span {
            p {
              color: #464646;
            }
          }
        }

        .filesAttach {
          margin-right: 18px;
        }

        span {
          width: 13px;
          height: 16px;
          // display: block;
          margin-right: 6px;
        }

        .filesAttach span img {
          width: 100%;
          height: 100%;
          margin-top: -8px;
        }

        .filesAttach p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #424242;
        }

        .viewMessage {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          color: #194792;
        }
      }

      &__Editor {
        margin-top: 13px;

        .jodit-container {
          border: 0px;
          border-radius: 8px !important;
        }

        .jodit-toolbar__box {
          border-radius: $border-radius-md;
        }

        .jodit-workplace {
          background-color: #acacac !important;
          border-radius: 0px 0px 8px 8px;
          min-height: 343px !important;
        }

        .jodit-react-container {
          border-radius: $border-radius-md;
        }

        .jodit-wysiwyg {
          p {
            font-weight: 400;
            color: #464646;
          }
        }
      }

      .cancelBtn {
        padding: 6px 25px;
        border-radius: $border-radius-md;
        margin-top: 20px !important;
        font-family: "Nunito", sans-serif !important;
        margin-right: 15px !important;
      }

      .sendBtn {
        padding: 8px 18px;
        border-radius: $border-radius-md;
        border: 0;
        background-color: #46afb8 !important;
        font-family: "Nunito", sans-serif !important;
      }
    }

    &__RightSec {
      width: 246px;

      @media (max-width: 1023px) {
        width: 190px;
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        margin: 20px auto;
        display: block;
        justify-content: space-between;
      }

      .RightContentBottom {
        padding-left: 24px;
        margin-bottom: 0px;
        box-shadow: unset;
        border-radius: 12px;

        .SupportTicket__ReplayModal__RightSec__RightContent__Heading {
          margin-bottom: 33px;
        }
      }

      &__RightContent {
        padding: 11px 16px 23px 19px;
        background-color: #424242;
        border-radius: 12px;
        margin-bottom: 19px;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.22);

        @media (max-width: 767px) {
          width: 100%;
          padding: 11px 10px;
        }

        &__Heading {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          color: white;
          margin-bottom: 30px;
          text-shadow: 0px 3px 6px #0000008e;
        }

        &__Label {
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
          color: white;
          padding: 0px 12px;
          border-radius: 24px;
          background-color: #3c4046;
        }

        &__SubjectDropDown {
          display: flex;
          align-items: center;
          margin-top: 11px;

          select {
            width: 100%;
            max-width: 177px;
            height: 26px;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #01041b;
            border-radius: $border-radius-md;
            margin-right: 14.5px;
            border: none;
            box-shadow: none;
            background-color: #ffffff;
          }
        }

        .chatIcon {
          width: 19px;
          height: 17.5px;
          margin-top: 0px;
          display: block;
          cursor: pointer;
        }

        .close {
          width: 18px;
          height: 18px;
          display: block;
          margin-bottom: 15px;
          cursor: pointer;
        }

        &__Desc {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          color: #d5d5d5;
          max-width: 175px;
          width: 100%;
          margin: 21px auto 0 auto;

          @media (max-width: 767px) {
            margin-left: 0;
          }
        }

        &__FilesAttachment {
          width: auto;
          margin: 0 auto;
          text-align: center;
          position: relative;
          width: 100%;
          max-width: 130px;
          margin-bottom: 30px;
          cursor: pointer;

          &__File {
            width: 66px;
            height: 84px;
            margin: 0 auto;

            img {
              width: 100%;
              height: 100%;
            }

            &__FileName {
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              color: white;
            }
          }

          &__CloseChat {
            position: absolute;
            top: 25px;
            right: 0;
          }

          p {
            width: 130px;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            height: 22px;
            font-weight: 400;
            margin-top: 11px;
            margin-bottom: 0;
          }
        }

        &__FilesAttachment:last-child {
          margin-bottom: 12px;
        }
      }
    }
  }

  &__supportTicketsTables {
    height: auto;
    display: flex;

    @media (max-width: 1270px) {
      width: 1242px;
      overflow: auto;
    }

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      width: 750px;
    }

    &__table {
      &__tableLeft {
        width: 100%;
        max-width: 792px;
        margin-right: 20px;
        overflow: auto;

        @media (max-width: 1023px) {
          max-width: 100%;
        }

        .tableLeftLength {
          height: 185px;
          overflow: auto;
          border-radius: 0px 0px 0px 8px;

          // &__accordian {
          //   .rows:nth-child(odd) {
          //     background-color: $theme-bg-color-lighter;
          //   }
          // }

          .selected-row {
            background-color: $theme-bg-color-lighter-variant3;
            border-radius: 8px;
          }

          &__accordian {
            &__tableRight {
              display: block;
              height: 0px;
              overflow: hidden;

              @media (min-width: 1024px) {
                display: none;
              }
            }
          }
        }

        .tableLeftLength::-webkit-scrollbar {
          display: none;
        }

        &__row:nth-child(odd) {
          background-color: $theme-bg-color-darker;
          border-radius: $border-radius-md;
        }

        &__row {
          display: flex;
          align-items: center;
          height: 37px;
          cursor: pointer;

          @media (max-width: 1023px) {
            height: auto;
          }

          @media (max-width: 767px) {
            padding-right: 10px;
          }
        }

        &__row:last-child {
          .td:first-child {
            border-radius: 0px 0px 0px 8px;
          }
        }
      }

      &__tableLeft {
      }

      &__th {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: white;
        padding: 4px 0px;
      }

      &__td {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: white;
        padding: 7.5px 0px;

        .Subbmitted {
          background-color: #eadb4a;
          color: #424242;
          text-align: center;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0.5px 11.5px;
          border-radius: 40px;
          font-size: 14px;
          p {
            margin-bottom: 0px;
            color: #424242;
          }
        }

        .InProgress {
          background-color: #194792;
          color: #fff;
          text-align: center;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0.5px 11.5px;
          border-radius: 40px;
          font-size: 14px;

          p {
            margin-bottom: 0px;
          }
        }

        .NeedMoreInfo {
          background-color: #c44538;
          text-align: center;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0.5px 11.5px;
          border-radius: 40px;
          font-size: 14px;

          p {
            margin-bottom: 0px;
          }
        }

        .Resolved {
          background-color: #46afb8;
          text-align: center;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0.5px 12px;
          border-radius: 40px;
          font-size: 14px;

          p {
            margin-bottom: 0px;
          }
        }
      }

      &__tableRight {
        width: 100%;
        max-width: 450px;
        overflow: hidden;

        @media (max-width: 1023px) {
          display: none;
        }

        .tableRightLength {
          height: 185px;
          overflow: auto;
          border-radius: $border-radius-md;

          .classroom__tabs-empty {
            height: 100%;
          }
        }

        .tableRightLength::-webkit-scrollbar {
          display: none;
        }

        &__row {
          display: flex;
          align-items: center;
          background-color: #b9b9b9;
          height: 37px;

          .td {
            color: #424242;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            padding: 7.5px 0px;

            p {
              color: #424242;
            }
          }
        }

        &__row:last-child {
          .td:last-child {
            border-radius: 0px 0px 8px 0px;
          }
        }

        &__row:nth-child(odd) {
          background-color: #adadad;
        }

        .selected-row {
          background-color: #424242;

          .td {
            color: white !important;

            p {
              color: white !important;
            }
          }
        }
      }
    }

    .summary {
      width: 100%;
      max-width: 440px;
      padding-left: 19px;

      @media (max-width: 1023px) {
        max-width: 60%;
      }

      @media (max-width: 575px) {
        max-width: 48%;
        padding-left: 10px;
      }

      div {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 10px;

        span {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
          background-color: #c44538;
        }
      }

      p {
        font-weight: 700;
      }
    }

    .update {
      width: 100%;
      max-width: 190px;
      padding-left: 16px;

      @media (max-width: 1023px) {
        max-width: 20%;
      }

      @media (max-width: 767px) {
        max-width: 26%;
      }
    }

    .status {
      width: 100%;
      max-width: 155px;
      margin-left: 0;
      padding-left: 10px;
      margin-bottom: 0;

      @media (max-width: 1023px) {
        max-width: 20%;
      }

      @media (max-width: 767px) {
        max-width: 26%;
      }
    }

    .ticketMessage {
      width: 60%;
      padding-left: 15px;
      font-weight: 700;

      div {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 10px;

        span {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
          background-color: #c44538;
        }
      }
    }

    .sent {
      width: 40%;
      padding-left: 10px;
    }
  }
}

.new-ticket-modal {
  .modal-content {
    padding: 11px 20px 19px 20px;
    background: rgba(54, 54, 54, 0.87);
    box-shadow: 3px 3px 6px #0000008d;
    border-radius: 10.5px;
    max-width: 1073px;
    height: 95.5vh;

    @media (min-height: 950px) {
      min-height: auto;
      max-height: 909px;
      height: auto;
    }
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: $border-radius-md;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: lightgray;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: grey;
    }

    .ModalHeader {
      font-weight: 400;
      font-family: $font-openSans;
    }
  }

  .SupportTicket__ReplayModal__LeftSec {
    padding-right: 15px;

    .modal__form-row {
      padding-top: 11px;
    }

    .sendBtn {
      width: 88px;
      max-width: unset;
      margin-top: 20px !important;
    }
  }

  .PacingCalender__SuggestedModel__Suggestion {
    background-color: #46afb8;
  }
}

.submit-behalf-admin {
  .modal-content {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    padding: 11px 22px 15px 20px;

    .modal__header {
      padding: 0;

      .modal__header-title {
        line-height: 26px;
      }
    }

    .modal__body {
      padding: 22px 0px 0px 0px;

      .modal__form-row {
        .submit-behalf-dropdown {
          .css-b62m3t-container {
            height: 68px;

            .css-13cymwt-control {
              height: 100%;
            }
          }
        }
      }
    }

    .modal__footer {
      margin-top: 18px !important;
      padding: 0;
      position: relative;
      left: 6px;

      button {
        min-width: 100px;
      }

      .cancel-btn {
        margin-right: 17px !important;
      }
    }
  }
}

.contact-support-modal {
  .modal__header-support {
    padding: 12px 10px;

    .back-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      margin-right: 10px;
      cursor: pointer;

      img {
        width: auto;
        height: auto;
        margin: 0;
        position: unset;
      }
    }

    .back-arrow:hover {
      background-color: #2a5391;
    }

    .modal__header-title {
      line-height: 26px;
    }

    p {
      line-height: 21px !important;
      font-weight: 400 !important;
      margin-top: 3px;
    }
  }
}

.modal__footer{
  .support__footer{
    #save-button{
      width: 83.16px;
      background-color: RED;
    }
  }
}
.support-body{
  @media (max-width: 767px){
    padding-inline: 10px;
  }
}


.sec-boxShadow {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.22);
}

.SupportTicketDetails {
  .support-center-chat-session {
    margin: 0 auto !important;
  }
}

.SupportTicket__ReplayModal-backdrop {
  z-index: 99999;
}
