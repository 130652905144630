html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  padding: 0 !important;
}

a {
  text-decoration: none;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.table-responsive {
  position: relative;
  overflow-x: auto;
  overflow-y: auto !important;
}

.monitor-table-container {
  overflow-y: hidden !important;
  margin-top: 5px;
  min-height: 150px;
}

.vh-100 {
  min-height: 100vh !important;
}

.section {
  margin-top: 22px;
  @include section;
}

.light-bg {
  background-color: $theme-bg-color-lighter-variant2;
}

.light-bg-gray {
  background-color: $color-gray-500;
}

.black-font {
  color: $color-black;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.shadow-text {
  // text-shadow: $text-shadow;
}

.text-gray {
  color: $color-gray-300;
  font-weight: 400;
}

.text-danger {
  color: $color-orange-100 !important;
  font-weight: bold;
  text-shadow: $text-shadow;
}

.animated-fade {
  animation: fadeIn 1500ms ease-out;
}
// .css-tr4s17-option{
//   background-color: unset !important;
//   color: #000 !important
// }

.fadeOut{
  opacity:0;
  transition: opacity 2s;
}

.animated-fadeOut {
  transition: opacity 5s ease-in-out;
  opacity: 0.1;
}

.left200{
  left: 200px;
}

.animated-transition {
  // transition: right 10s ease-in-out !important ;
  // left: 100%; 
  transform: translate(-100%, 0);
  transition: transform 1s;
  will-change: transform;
}



.u-mb-22 {
  margin-bottom: 22px !important;
}

.u-mb-10 {
  margin-bottom: 10px !important;
}

.pe-12 {
  padding-right: 12px;
}

.ps-12 {
  padding-left: 12px;
}

.mr-3 {
  margin-right: 1rem;
  @include media-breakpoint(md) {
    margin-right: 10px;
  }
}

.dark-gray {
  background-color: $color-gray;
}

.text-shadow-none {
  text-shadow: unset !important;
  font-weight: 400 !important;
}

.position-inherit {
  position: inherit !important;
}

.question-mark-icon {
  max-width: 24px;
  height: auto;
}

.oag-bg {
  background-color: $color-blue-800;
}

.red-error-circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-orange-100;
}

.red-circle {
  position: absolute;
  top: 26px;
  left: 21px;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $color-orange-100;

  &.input-circle {
    left: 2px;
  }
}

.green-circle {
  position: absolute;
  top: 26px;
  left: 21px;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $color-green;
}

.spinner-border-sm {
  width: 15px;
  height: 15px;
  margin: 0 10px;
}

.custom-padding {
  input,
  textarea {
    padding: 13px 20px 11px 50px !important;
  }
}

.overlay-input {
  input {
    background-color: $color-gray-650 !important;
    font-size: 18px !important;
    color: $color-white !important;
  }
  .close-overlay {
    background-color: $color-gray-350;
    position: absolute;
    top: 26px;
    right: 13px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    line-height: 19px;
    padding-left: 4px;
    cursor: pointer;

    @include safari-suport {
      line-height: 16px;
    }
  }
}

.error-hint {
  position: absolute;
  right: 5px;
  top: -18px;
  display: flex;
  color: $color-white;
  align-items: center;

  &-password {
    right: 42px;
  }
}

.high_rating {
  background-color: $color-green !important;
}

.average_rating {
  background-color: $color-gray-800 !important;
}

.low_rating {
  // background-color: rgba($color-red, 0.29) !important;
  background-color: #c44538 !important;

  &.darker {
    // background-color: rgba(#be0f0f, 0.39) !important;
  background-color: #c44538 !important;
  }
}

.error-ball {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-color: $orange;
  border-radius: 50%;
}

.red_dot {
  background-color: $color-orange-100;
  width: 18px;
  height: 18px;
  border-radius: 50%;

  @include safari-suport {
    margin-top: 1px;
  }
}

.invalid-text {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: $color-orange-100;
  text-shadow: $text-shadow;
  margin-bottom: 0;
}

.warning-flag {
  @include flexcenter-both;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  color: $color-yellow;
  line-height: 22px;
  text-shadow: 0 3px 6px rgba($color-black, 0.56);

  img {
    margin-right: 5px;

    @include media-breakpoint(xl) {
      margin-right: 10px;
    }

    @include media-breakpoint(md) {
      max-width: 20px;
      height: auto;
      margin-right: 8px;
    }
  }
}

// Animated Ellipsis
.ellipsis-animated {
  display: block;
  overflow: hidden;

  &.animated-padding {
    padding: 0 5px;
  }

  &-text {
    position: relative;
    left: 0;
    width: 100%;
    text-align: right;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;

    &.overflow {
      overflow: hidden;
      white-space: nowrap;
    }

    &.animated {
      @include ellipsis;
      cursor: pointer;

      @media print {
        white-space: unset;
        overflow: visible;
        text-overflow: unset;
      }

      &:hover {
        left: -100%;
        width: 200%;
        transition: left 3s linear, width 3s linear;
      }
    }

    &.play-animation {
      left: -100%;
      width: 200%;
    }
  }
}

.ellipsis-animation:hover {
  animation: ellipsis-animation 10000ms ease;
  animation-delay: 3s;
}

.ellipsis-only{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis ;
}

/* Classroom Media */
.hh_ac_box_tp.hh_ac_box_tp_tr_bg {
  background-color: transparent;
}

.cl-md-tb-pdf-wrap {
  padding: 15px 20px;
  width: 100%;
}

.cl-md-tb-content {
  margin-left: 0;
  padding: 10px 20px;
}

.provied-para {
  position: relative;
  margin: auto;
  padding-top: 30px;
}

.progress-pagination .page-item.active .page-link {
  background-color: $color-yellow;
  color: $color-blue-200;
}

.progress-pagination .previous-nav .page-link,
.progress-pagination .next-nav .page-link {
  color: $color-white;
  background-color: $color-blue-100;
  border: 1px solid $color-blue-100;
  border-radius: $border-radius-md;
}

.btn-sbr .btn.btn-xd-c {
  margin-left: 10px;
}

.btn.btn-irr-b {
  border: 1px solid $color-white;
  color: $color-white;
  padding: 4px 20px;
}

.class-length-th {
  width: 5%;
  border-top-left-radius: 10px;
}

.rounded-row {
  border-top-left-radius: 15px;
}

.progress-table-container .KNdZ9 {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  text-align: center;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: $border-radius-md;
  background-color: $color-light-800;
  color: $color-light-800;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: $border-radius-md;
  background-color: $color-light-800;
  color: $color-light-800;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: $border-radius-md;
  background-color: $color-light-800;
  color: $color-light-800;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.verticaly-top {
  vertical-align: top;
}

.react-loading-skeleton {
  line-height: unset !important;
  margin-bottom: 1px;
}

.cursor-default {
  cursor: default !important;
}

.cursor-none {
  cursor: none !important;
}

.alert {
  border-radius: $border-radius-md;
  padding: 12px 16px;
}

.alert-dismissible .btn-close {
  padding: 14px 16px;
}

// Print CSS
.printData {
  height: 0;
  overflow: hidden;
  background-color: $color-white;
}

// Progress Css
.progressBlock {
  height: 41px;

  p {
      font-size: $sm + 1;
      font-weight: 700;
      line-height: 20.46px;
      color: $color-gray-950;
      margin-top: 4px;
      margin-bottom: 0;
      text-align: center;
  }

  svg {
    border-radius: $border-radius-md;
    margin-top: -5px;
  }

  .no-data-progress-skeleton {
    
    div {
      width: 98px;
      height: 19px;
      margin: 0 auto;
      // background-color: #4788ff66;
      background-color: #a1a1a1;
      opacity: 0.4;
      border-radius: $border-radius-md;
    }

    p {
      width: 35px;
      height: 20px;
      margin: 5px auto 0 !important;
      background-color: #a1a1a1;
      border-radius: $border-radius-md;
    }
  }
}

@media print {
  .printData {
    display: block;
    height: 100%;
    overflow: unset;

    div.divFooter {
      position: fixed;
      bottom: 0px;
      height: 20px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

@media print {
  @page {
    // US Letter Paper Size
    size: 216mm 279mm !important;

    // A4 Paper Size
    // size: 210mm 297mm !important;
    margin: 0.5cm 1cm;
  }

  // Firefox
  @include mozilla-suport {
    @page {
      // US Letter Paper Size
      size: 216mm 279mm !important;

      // A4 Paper Size
      // size: 210mm 297mm !important;
    }
    @page rotated {
      size: portrait !important;
    }
  }
}

.align-item-center {
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0 !important;
}

.mx-3 {
  @include media-breakpoint(md) {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}

.me-3 {
  @include media-breakpoint(md) {
    margin-right: 10px !important;
  }
}

.ms-3 {
  @include media-breakpoint(md) {
    margin-left: 10px !important;
  }
}

.n-back {
  background-color: $color-gray-900;
  border-radius: $border-radius-md;
  width: 31px;
  height: 27px;
  margin-top: -12px;
}
.n-back span {
  margin-left: 10px;
  font-size: 12px;
}

.chart-labels {
  display: flex;
  justify-content: space-around;
  padding-left: 16px;
  margin-top: -38px;
  padding-bottom: 30px;
  font-weight: bold;
  color: #7d7d7d;
  font-size: 14px;
}

.perc-data {
  padding-left: 77px;
  padding-right: 77px;
}

.hint-data {
  color: $color-black;
  margin-left: 5px;
  font-size: 14px;
}

.print-content {
  padding: 23px;
}

.box-wrap {
  background-color: $color-white-C7C7C7;
  border: 1px solid $color-gray-750;
  padding: 10px;
}
.school-name {
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 40px;
    position: relative;
    left: 0%;
    width: 100%;
    transition: left 3s linear, width 3s linear;
  }
  &:hover {
    p {
      text-overflow: unset;
      overflow: unset;
      left: -100%;
      width: 200%;
    }
  }
  select {
    z-index: 1;
  }
}

.disabled-item {
  opacity: 0.56;
}

.add-page-break {
  page-break-before: always;
}

.non-repeating-table-header-print {
  @media print {
    thead {
      display: table-row-group;
    }
  }
}

.opacity-0 {
  opacity: 0;
}

.ql-container {
  font-family: "Nunito", sans-serif !important;
  @include safari-suport {
    font-family: "nunito-for-safari", sans-serif;
  }
  .ql-editor ul > li::before {
    color: inherit;
  }
  .ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: unset;
    text-align: unset;
  }
  .ql-editor {
    line-height: 20px;
    .ql-size-large {
      font-size: 20px;
    }
    .ql-size-medium {
      font-size: 18px;
    }
    .ql-size-small {
      font-size: 16px;
    }
    .ql-size-extra-small {
      font-size: 14px;
    }
  }
}

.ql-editor {
  scrollbar-width: 12px;
  scrollbar-color: rgba($color-black, 0.23);

  @include media-breakpoint(lg) {
    scrollbar-width: 8px;
  }

  @include media-breakpoint(md) {
    scrollbar-width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($color-black, 0.12);
  }

  &::-webkit-scrollbar {
    width: 12px;

    @include media-breakpoint(lg) {
      width: 8px;
    }

    @include media-breakpoint(md) {
      width: 6px;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 10px;
    background-color: rgba($color-black, 0.23);
    border: 2px solid rgba($color-blue-100, 0.4);

    @include media-breakpoint(lg) {
      width: 6px;
      border: 1px solid rgba($color-blue-100, 0.4);
    }

    @include media-breakpoint(md) {
      width: 4px;
    }
  }
}

.spanNextToFraction {
  opacity: 1;
  color: #fff;
  caret-color: #707070;
}

.toolTip-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.56;
  border-radius: $border-radius-md;
  animation-name: toolTipbgAnimate;
  animation-duration: 1s;
  z-index: 999;

  @keyframes toolTipbgAnimate {
      from {opacity: 0%;}
      to {opacity: 57%;}
  }
}

.toolTip-bg-transparent {
  background-color: unset;
}

.toolTip-bg-transparent.transparent-fix {
  left: unset;
  bottom: unset;
  top: -74px;
  opacity: 1;
}

.facilitator-select {
  margin-top: 14px;

  &__label {
    position: absolute;
    // bottom: 32px;
    // right: 69px;
    bottom: 35px;
    left: 14px;
    display: inline-block;
    background-color: $label-bg;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: $color-white;
    border-radius: 20px;
    text-align: center;
    padding: 3px 12px;
    z-index: 5;

    @include media-breakpoint(sm) {
        font-size: 12px;
        left: 25px;
    }

    &.lg {
        padding: 3px 22px;
    }
  }

  &__indicators {
    background-image: url("../../../assets/img/select-icon-lg.svg");
    background-repeat: no-repeat;
    background-position: 0px 8px;
    padding-left: 22px;
    margin-top: 7px;
    svg {
      display: none;
    }
  }
}

.scroll-indicator {
  width: 25px;
  height: 40px;
  border: 2px solid #d9d9d9;
  border-radius: 50px;
  position: absolute;
  bottom: 15px;
  z-index: 1;
  right: 22px;
  margin: 0 auto;
  left: 0;
}

.scroll-indicator::before {
  content: '';
  position: absolute;
  top: .2em;
  left: 50%;
  width: 6px;
  height: 6px;
  background-color: rgba(203, 248, 251, 0.781);
  border-radius: 50%;
  transform: translate(-50%, 0);
  animation: scrollAnim 1.8s .5s infinite ease-in;
  box-shadow: 0px 0px 35px -5px rgba(70, 255, 224, 0.781);
}

/* Animation */
@keyframes scrollAnim {
  0% {
    transform: translate(-50%, 0) rotateX(0);
    opacity: 0;
  }

  5% {
    opacity: .5;
  }

  15% {
    opacity: 1;
  }

  90%,
  100% {
    opacity: 0;
  }

  100% {
    transform: translate(-50%, 30px) rotateX(30deg);
  }
}



// FIREFOX
// .withHeaderPrint.firefoxHeights {
//   height: 780px;
// }

// .withoutHeaderPrint.firefoxHeights {
//   height: 950px;
// }

// //CHROME
// .withHeaderPrint.chromeHeights{
//   min-height: 1420px;
// }

// .withoutHeaderPrint.chromeHeights{
//   min-height: 1620px;
// }

//SAFARI
// .based-report-print__table.withHeaderPrint.safariHeights {
//   height: 500px;
//   // background-color: yellow;
// }

// .based-report-print__table.withoutHeaderPrint.safariHeights {
//   height: 700px;
//   // background-color: pink;

// }

::-webkit-scrollbar-track {
  background-color: rgba($color-black, 0.12);
}

::-webkit-scrollbar {
  width: 12px;

  @include media-breakpoint(lg) {
    width: 8px;
  }

  @include media-breakpoint(md) {
    width: 6px;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;

  @include media-breakpoint(lg) {
    width: 6px;
  }

  @include media-breakpoint(md) {
    width: 4px;
  }
}

::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  background-color: rgb(185 185 185 / 65%);
  transition: all 3s;


  @include media-breakpoint(lg) {
    width: 6px;
    border: 1px solid rgba($color-blue-100, 0.4);
  }

  @include media-breakpoint(md) {
    width: 4px;
  }
}

.on-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: all 3s;
}

@media (min-width: 1260px) {
  .school-table-container .table-responsive {
    overflow: unset !important;
  }
  
  .school-table-container table tbody {
    overflow: unset !important;
  }

  // .progress-table-container .table-responsive {
  //   overflow: unset !important;
  // }
  
  .progress-table-container table tbody {
    overflow: unset !important;
  }
}

@media (min-width: 1245px) {
  .progress-table-container .table-responsive {
    overflow: unset !important;
  }
}

.Toastify__toast-container {
  z-index: 999999999 !important;
}


@mixin flex-box ($jc, $ai){
  display: flex;
  justify-content: $jc;
  align-items: $ai;
}

.button-green {
  @include buttonGreen(88px, 33px, 7px 10px 4px, 8px);
}
