.reportCards {
  margin-top: 28px;

  &-wrap {
    @include section;
  }

  &__header {
    padding: 11px 0px;
    width: 100%;

    &-student {
      padding-left: 17px;

      .reportCards__student-performance {
        .reportCards__student-performance-container {
          padding-left: 0px;
        }
      }
    }
  }

  &__dropdownButton {
    display: flex;
    align-items: flex-end;

    @media (max-width: 768px) {
      margin-top: 5px;
    }

    &__divider {
      width: 1px;
      background-color: white;
      /* Adjust color as needed */
      height: 22px;
      /* Adjust height as needed */
      margin-right: 10px;
    }

    &__dropdown-container {
      cursor: pointer;
      border: 1px solid #fff;
      padding: 4px 10px;
      min-width: 125px;
      border-radius: $border-radius-md;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .verticalBar {
        display: flex;
        flex-direction: row;
      }
    }

    &__dropdown-container-without-dropdown {
      min-width: 75px !important;
      text-align: center;
    }

    &_placeholder {
      padding: 0px 10px 0px 10px;
      color: #fff;
      display: inline-block;
      width: 100%;
    }

    &_menu_biggerWidth {
      min-width: 215px !important;
    }

    &_menu {
      min-width: 145px;
      height: auto;
      background-color: #fff;
      border-radius: 2px;
      position: absolute;
      z-index: 999;
      border-radius: $border-radius-md;
      margin-top: 5px;

      &-list {
        list-style: none;
        padding: 4px 0px;
        margin-bottom: 0px;

        .listItem {
          padding: 8px 12px;
          color: $color-blue-200;

          &:hover {
            background-color: $light-blue-background;
            cursor: pointer;
          }
        }
      }
    }
  }

  &__student-performance {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1260px) {
      flex-wrap: wrap;
      gap: 15px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      min-width: 405px;
      height: 150px;

      @media (max-width: 1260px) {
        min-width: unset;
        width: 100%;
        flex-wrap: wrap;
        gap: 3px 10px;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 35px;
        height: auto;
      }

      .add-top-margin {
        margin-top: 5px;

        @media (max-width: 1260px) {
          margin-top: 0;
        }

        @media (max-width: 400px) {
          margin-top: 5px;
        }
      }

      @media (max-width: 768px) {
        height: auto;
        padding-left: 20px;
      }

      @include media-breakpoint(sm) {
        padding-left: 0;
      }

      @media print {
        align-items: center;

        @include mozilla-suport {
          width: 300px;
          height: 110px;
        }
      }
    }

    &-margin30 {
      margin-top: 30px;
    }

    &-score {
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      width: 390px;

      &.spacing {
        padding-left: 50px;
      }

      p {
        img {
          margin-right: 14px;
          margin-bottom: 2px;
        }
      }

      p {
        span {
          &.add-space {
            margin-right: 14px;
          }
        }
      }

      @media (max-width: 860px) {
        flex-direction: column;
      }

      @include media-breakpoint(md) {
        flex-direction: row;
      }

      .summ_form_label {
        width: 100px;

        p {
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          text-align: right;
          font-family: $font-nunito;

          @media (max-width: 1260px) {
            text-align: left;
          }

          @media print {

            @include mozilla-suport {
              font-size: 16px !important;
              line-height: 21px !important;
            }
          }
        }
      }

      .summ_form {
        width: 280px;
        display: flex;
        justify-content: space-between;
        margin-left: 10px;

        @media (max-width: 400px) {
          margin-left: 0;
        }

        p {

          @media print {

            span {
              width: 15px !important;
              height: 15px !important;
            }

            @include mozilla-suport {
              font-size: 16px !important;
              line-height: 21px !important;
            }
          }
        }
      }

      @include media-breakpoint(sm) {
        width: 100%;
      }

      @media (max-width: 400px) {
        display: block;
      }

      @media print {
        @include mozilla-suport {
          width: 280px;
        }
      }

      p {
        margin: 0;
        line-height: 20px;
      }

      &.print p {
        color: $color-black;

        @include mozilla-suport {
          font-size: 12px;
          line-height: 1.4;
        }
      }
    }

    &-title {
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 5px;

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 18px;
      }

      &.print {
        text-shadow: unset;

        @include mozilla-suport {
          text-align: center;
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }

    .report-progress {
      @media (max-width: 1260px) {
        width: 100%;
        max-width: 795px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0 14px;
        height: 85px;
      }

      @media (max-width: 860px) {
        height: auto;
        height: 110px;
      }

      @include media-breakpoint(md) {
        height: auto;
      }
    }

    &-result {
      color: #b9b9b9;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
      margin-top: 10px;
    }

    .card-all-students {
      margin-top: 30px;
    }

    &-report-card {
      display: flex;
      flex-direction: column;
      width: 100%;

      .student-info {
        background-color: #b9b9b9;
        padding: 6px 14px 3px 14px;
        border-radius: $border-radius-md;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: fit-content;
        margin-bottom: 24px;

        .student-info-item {
          display: flex;
          align-items: center;
          color: black;
        }

        .student-info-item-status {
          display: flex;
          gap: 10px;
          align-items: center;
          color: black;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 21.82px;
            color: black;
            margin: 0;
          }
        }
      }

      &-text {
        display: flex;
        justify-content: space-between;

        .showAsRow {
          display: flex;
          flex-direction: row;
        }

        .showAsRowToggles {
          display: flex;
          flex-direction: row;
          margin-right: 8px;
          align-items: flex-end;
        }
      }

      &-text-student-detail {
        margin-right: 0;
      }

      &-dropdowns {
        margin-left: 40px;

        &-container {
          display: flex;
          flex-direction: row;
        }
      }

      /* Media query for mobile screens */
      @media (max-width: 768px) {
        margin-top: 0px;
        /* Change margin-top to 0px */

        &-text-student-detail {
          margin-right: 0px;
        }

        &-text {
          flex-direction: column;

          .showAsRow {
            display: flex;
            flex-direction: row;
          }

          .showAsRowToggles {
            justify-content: flex-end;
            flex-wrap: wrap;
            margin-right: 0px;
          }
        }

        &-dropdowns {
          margin-left: 20px;

          &-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          }
        }
      }
    }

    &-report-card-title {
      display: flex;
      align-items: center;
      /* Vertically center the content */
      font-family: $font-openSans;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0;
      text-align: left;
      text-transform: capitalize;
      height: 40px;
    }
  }
}

.dropdownsCommon {
  display: flex;
  flex-direction: column;
  gap: 0 13.5px;
  width: 100px;
  margin-right: 14px;

  &-width150 {
    width: 150px;
  }

  &-width200 {
    width: 200px;
  }

  &-width210 {
    width: 210px;
  }

  &-width250 {
    width: 250px;
  }

  &-width300 {
    width: 300px;
  }

  &-width400 {
    width: 400px;
  }

  &-label {
    color: white;
    font-size: 14px;
    font-family: Nunito;
    font-weight: 700;
    word-wrap: break-word;
    margin-left: 19px;
    padding: 5px 0px;
  }

  &-select-box {
    .red-circle {
      top: 6px;
    }

    .text-color-blue {
      color: #003b93;
    }

    .margin-helptext {
      margin-left: 28px;
    }

    &__wrapper {
      outline: none !important;
      box-shadow: unset !important;
      transition: opacity 0.4s ease;
    }

    &--is-disabled {
      transition: opacity 0.4s ease;
      opacity: 0.26;
    }

    &__option {
      cursor: pointer !important;
      padding-left: 16px !important;
      color: #4788ff !important;

      &--is-selected {
        color: white !important;
      }
    }

    &__control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 33px !important;
      border-radius: $border-radius-md !important;
      outline: none !important;
      box-shadow: unset !important;
      cursor: pointer !important;
    }

    &__value-container {
      padding: 0 0 0 4px !important;
    }

    &__input-container {
      padding-left: 10px !important;
    }

    &__placeholder {
      padding-left: 10px !important;
    }

    &__single-value {
      color: $blue-500 !important;
      padding-left: 10px !important;
      font-size: 15px;
      font-weight: 400px;
    }

    &__indicators {
      background-image: url("../../../assets/img/select-icon-blue.svg");
      background-repeat: no-repeat;
      background-position: 8px 12px;
      padding-left: 22px;

      svg {
        display: none;
      }
    }

    &__menu {
      width: 53px;
      color: $color-dark-300 !important;
      margin-top: 10px !important;
      overflow: hidden;

      // position: relative !important;
      &-list {
        padding: 0 !important;
      }
    }

    .no-admins {
      color: $color-black;
      text-align: center;
      font-weight: bold;
      margin-top: 4px;
      margin-bottom: 8px;
      height: 15px;
    }
  }
}

.studentPerformance {
  margin-top: 10px;
  color: white;
}

.reportStandardBased {
  margin-top: 0px;
  color: white;
  border-radius: 10px;
  /* Adjust the radius value as needed */
  // border-top-right-radius: 10px;

  &_based-reports-container {
    background-color: #b9b9b9;
    border-radius: $border-radius-md;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
    padding: 8px 20px;

    @include media-breakpoint(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    &.print {
      background-color: transparent;
      border-radius: 0;

      @include mozilla-suport {
        padding-left: 0;
      }
    }

    &-heading {
      display: flex;
      align-items: center;
    }

    &-title {
      font-family: $font-openSans;
      font-size: 20px;
      font-weight: bold;
      margin-right: 21px;

      &.print {
        line-height: 30px;
        color: $color-black;

        @include mozilla-suport {
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }

    &-select {
      display: flex;
      align-items: center;

      @include media-breakpoint(sm) {
        margin-left: auto;
        margin-top: 8px;
      }

      select {
        background-color: $color-gray-850;
        background-position: right 0px center;
        border-color: $color-gray-900;
        color: $color-dark-300;
        margin: 0 10px;
        height: 26px;
        line-height: 1;
        padding: 0 17px 0 2px;

        &:disabled {
          opacity: 0.26;
        }
      }
    }
  }
}

.reportCardPerformanceDetails {
  margin: 10px 0px 16px 0px;
  @include section;

  @include media-breakpoint(md) {
    margin: 16px;
    margin-top: 0;
  }

  &.print {
    margin: 0;
    box-shadow: unset;
    border-radius: 0;
    overflow: visible;
    margin-top: 30px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
    padding: 8px 20px;
    flex-wrap: wrap;
    gap: 10px;

    @include media-breakpoint(md) {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 0;
      padding-right: 0;
    }

    &.print {
      background-color: transparent;
      border-radius: 0;

      @include mozilla-suport {
        padding-left: 0;
      }
    }

    &-heading {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-right: 21px;
      font-family: $font-openSans;

      &.print {
        line-height: 30px;
        color: $color-black;

        @include mozilla-suport {
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }

    &-select {
      display: flex;
      align-items: center;

      @include media-breakpoint(sm) {
        margin-left: auto;
        margin-top: 8px;
      }

      select {
        background-color: $color-gray-850;
        background-position: right 0px center;
        border-color: $color-gray-900;
        color: $color-dark-300;
        margin: 0 10px;
        height: 26px;
        line-height: 1;
        padding: 0 17px 0 2px;

        &:disabled {
          opacity: 0.26;
        }
      }
    }
  }

  // TestResult Table
  &__table {
    th {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
      padding: 13px 10px 8px 10px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @include safari-suport {
        padding-top: 3px;
        padding-bottom: 5px;
      }

      @media print {
        @include mozilla-suport {
          font-size: 15px;
          line-height: 1.5;
        }
      }
    }

    td {
      vertical-align: middle;
      font-size: 16px;
      line-height: 20px;
      padding: 8px 10px;
      position: relative;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @include safari-suport {
        padding-top: 7px;
        padding-bottom: 9px;
      }

      @media print {
        @include mozilla-suport {
          font-size: 15px;
          line-height: 1.5;
          padding: 4px 10px;

          .level-box {
            width: 18px;
            height: 18px;
            transform: translateY(4px);
          }
        }
      }

      .variant {
        display: block;
        margin-left: 4px;
        border: 1px solid #3260d4;
        border-radius: 8px;
        padding: 1px 6px;
        font-size: 12px;
        line-height: 16px;
        color: #3260d4;
        text-align: center;
        height: 19px;
        position: absolute;
        left: -46px;
        margin-top: 1px;

        @media print {
          padding: 2px 6px 0px;
          margin-top: 4px;
        }

        @-moz-document url-prefix(){
          
          @media print {
            padding: 1px 6px;
          }
        }
      }
    }

    &_no-data {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 21px;
      padding: 8px;
    }

    // &-header {
    //   background-color: $color-blue-600;
    // }

    &-row {
      position: relative;

      &-border::after {
        content: "";
        position: absolute;
        left: 26px;
        /* Adjust for padding on the left */
        right: 26px;
        /* Adjust for padding on the right */
        bottom: 0;
        height: 1px;
        background-color: #adadad;
      }

      &-border:last-child::after {
        content: none;
      }

      .completed-data {
        text-align: right;
        padding-right: 62px !important;

        @include media-breakpoint(lg) {
          padding-right: 32px !important;
        }

        @media print {
          padding-right: 20px !important;

          @include mozilla-suport {
            min-width: 260px;
            width: 260px;
            padding-right: 20px !important;
          }
        }
      }

      td {

        @media print {

          .level-box {
            transform: translateY(4px) !important;
          }
        }
      }
    }

    &-attempts {
      text-align: left;
      padding-left: 40px !important;
    }

    &-completed {
      text-align: right;
      padding-right: 62px !important;
      width: 411px;
      min-width: 411px;

      @include media-breakpoint(lg) {
        min-width: 360px;
        width: 360px;
        padding-right: 32px !important;
      }

      @media print {
        min-width: 360px;
        width: 360px;
        padding-right: 20px !important;

        @include mozilla-suport {
          min-width: 360px;
          width: 360px;
          padding-right: 20px !important;
        }
      }
    }

    &-date-attempts {
      min-width: 145px;
      width: 145px;

      @include media-breakpoint(lg) {
        min-width: 108px;
        width: 108px;
      }

      @media print {
        min-width: 85px;
        width: 85px;

        @include mozilla-suport {
          min-width: 85px;
          width: 85px;
        }
      }

      img {
        margin-left: 6px;
      }
    }

    &-date {
      min-width: 140px;
      width: 140px;

      @include media-breakpoint(lg) {
        min-width: 108px;
        width: 108px;
      }

      @media print {
        @include mozilla-suport {
          min-width: 70px;
          width: 70px;
        }
      }

      img {
        margin-left: 6px;
      }
    }

    &-score {
      min-width: 180px;
      width: 180px;


    }

    .attempts-count {
      width: 140px;
      min-width: 140px;
    }

    &-attempt {
      min-width: 50px;
      width: 390px;
    }

    &-print {
      thead {
        background-color: transparent;
      }

      th,
      td {
        text-shadow: unset;
        color: $color-black;
        padding: 8px 10px;
      }
    }
  }

  &__footer {
    div {
      bottom: 8px !important;
    }
  }

  &-table-box {
    color: $color-gray-950;
    background-color: #b9b9b9;
    border-radius: $border-radius-md;
  }

  &-table-container {

    .table-responsive {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .reportCardPerformanceDetails__table {
    tbody {
      .progress__table-row {
        td {
          height: 37px;
        }

        &:first-child {
          td::before {
            display: block;
          }
        }
      }

      .skeleton {
        .className-data {
          width: 455px;
          min-width: 455px;
          padding-right: 73px !important;
          padding-left: 10px !important;
        }

        .date {
          width: 135px;
          padding: 13px 10px 8px;
        }

        .assesment-data {
          width: 130px;
          padding: 13px 10px 8px;
          text-align: left;

          &:last-child {
            width: 300px;
          }
        }
      }
    }
  }
}

.dropdownsCommonWithLabel {
  display: flex;
  flex-direction: column;
  gap: 0 13.5px;
  margin-bottom: 5px;

  &-width150 {
    width: 150px;
  }

  &-width200 {
    width: 200px;
  }

  &-width210 {
    width: 210px;
  }

  &-width250 {
    width: 250px;
  }

  &-width300 {
    width: 300px;
  }

  &-width400 {
    width: 400px;
  }

  &-label {
    background-color: #3c4046;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-left: 25px;
    margin-bottom: -7px;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    padding: 3px 12px;
    z-index: 5;
    position: relative;
  }

  &-select-box {
    .red-circle {
      top: 6px;
    }

    .text-color-blue {
      color: #003b93;
    }

    .margin-helptext {
      margin-left: 28px;
    }

    &__wrapper {
      outline: none !important;
      box-shadow: unset !important;
      transition: opacity 0.4s ease;
    }

    &--is-disabled {
      transition: opacity 0.4s ease;
      opacity: 0.26;
    }

    &__option {
      cursor: pointer !important;
      padding-left: 16px !important;
      color: #4788ff !important;

      &--is-selected {
        color: white !important;
      }
    }

    &__control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 43px !important;
      border-radius: $border-radius-md !important;
      outline: none !important;
      box-shadow: unset !important;
      cursor: pointer !important;
    }

    &__value-container {
      padding: 0 0 0 4px !important;
    }

    &__input-container {
      padding-left: 10px !important;
    }

    &__placeholder {
      padding-left: 10px !important;
    }

    &__single-value {
      color: $blue-500 !important;
      padding-left: 20px !important;
      font-size: 18px;
      font-weight: 400px;
    }

    &__indicators {
      background-repeat: no-repeat;
      background-position: 0px 18px;
      padding-left: 32px;

      svg {
        display: none;
      }

      color: $color-gray-950;
      background-image: url(../../../assets/img/down-arrow.svg);
      position: relative;
      left: -2px;
    }

    &__menu {
      width: 53px;
      color: $color-dark-300 !important;
      margin-top: 10px !important;
      overflow: hidden;
      position: relative !important;

      &-list {
        padding: 0 !important;
      }
    }

    .no-admins {
      color: $color-black;
      text-align: center;
      font-weight: bold;
      margin-top: 4px;
      margin-bottom: 8px;
      height: 15px;
    }
  }
}

.download-standard-based {
  .standard-based-report {
    .title {
      font-size: 12px;
      font-weight: 700;
      line-height: 11px;
      font-family: $font-openSans;
      margin-bottom: 5px;
    }

    .print-report__header-text {
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 12px !important;
      font-family: $font-openSans !important;
      margin-bottom: 5px;
    }
  }

  .report__levels.based__levels {
    .report__levels-container {
      width: 260px;

      .report__levels-title {
        text-align: center;
        font-size: 12px;
        color: #000;
        font-weight: bold;
        line-height: 14px;
        margin-bottom: 0;
      }

      .report__levels-percentage {
        width: 230px;

        .report__levels-row {
          p {
            font-size: 12px;
            color: #000;
            line-height: 12px;
            margin-bottom: 0;
          }

          .box {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .table {

    table {
      thead {
        th {
          font-size: 12px !important;
          line-height: 14px !important;
          padding: 3px 10px;

          .weeks {

            div {
              text-align: center !important;
            }
          }
        }

        .table-header__description {
          padding-left: 12px !important;
        }

        // .table-header__nine {
        //   width: 166px !important;
        //   min-width: 166px !important;

        //   .weeks {
        //     div {
        //       width: 40px !important;
        //     }
        //   }
        // }
      }

      tbody {
        tr {
          td {
            font-size: 12px !important;
            line-height: 14px !important;
            padding: 3px 10px;
          }

          .table__description {
            padding-left: 12px !important;
          }
        }
      }
    }
  }
}

.download-performance-report {
  // &-report {
  //   font-size: 50px !important;
  //   color: red !important;
  // }

  .accordion {
    .print-report__header {
      padding-bottom: 0px;

      .title {
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        font-family: $font-openSans;
        color: #000 !important;
      }

      .print-report__header-text {
        font-size: 10px !important;
        font-weight: 400 !important;
        line-height: 10px !important;
        font-family: $font-openSans !important;
        color: #000 !important;
      }
    }

    .accordion__content {

      .performance.print {

        .performance__perecentage-contentainer {
          width: 200px;

          .performance__perecentage-result {
            font-size: 12px;
            width: 320px;
            margin-right: -130px;
          }

          .performance__perecentage-title {
            font-size: 10px;
            margin-bottom: 0;
            line-height: 14px;
          }

          .performance__perecentage-score {

            .reportCards__student-performance-score {
              display: block;
              margin-left: 60px;

              .summ_form_label {

                p {
                  font-size: 10px;
                }
              }

              .summ_form {
                width: 200px;
                margin-left: 50px;
                margin-top: -5px;

                p {
                  font-size: 10px;

                  .level-box {
                    transform: translateY(3px);
                    width: 12px !important;
                    height: 12px !important;
                  }

                }
              }
            }
          }
        }
      }

      .testResults {
        margin-top: -10px;

        .testResults__header {
          .testResults__header-heading {

            .testResults__header-title {
              font-size: 14px;
              margin-top: 5px;
            }
          }
        }

        .testResults-table-container {
          .reportCardPerformanceDetails__table.testResults__table-print {
            .reportCardPerformanceDetails__table-header {
              .reportCardPerformanceDetails__table-row {
                th {
                  font-size: 12px;
                  padding: 3px 10px;
                }

                .reportCardPerformanceDetails__table-completed {
                  padding-right: 20px !important;
                  width: 230px !important;
                  min-width: 230px !important;
                }

                .reportCardPerformanceDetails__table-date-attempts {
                  width: 80px !important;
                  min-width: 80px !important;
                }

                .reportCardPerformanceDetails__table-score {
                  width: 80px;
                  min-width: 80px !important;
                }
              }
            }

            tbody {
              .reportCardPerformanceDetails__table-row {
                td {
                  font-size: 12px;
                  padding: 3px 10px;

                  .level-box {
                    transform: translateY(6px);
                  }
                }

                .completed-data {
                  padding-right: 20px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}