.settings-panel {
	position: absolute;
    top: 0px;
    right: 10px;
    background: #f7f7f7;
    border: 1px solid #ddd;
    border-top: none;
    padding: 20px;
    font-size: .8rem;
	box-shadow: 0 4px 4px $color-light-200;
	opacity: 0;
	z-index: -1;
	transition: top 0.5s linear, opacity 0.5s linear;
}

.settings-panel.volumeEnabled {
	top: 59px;
	opacity: 1;
	z-index: 9;
}

.settings-panel .volume-section, .settings-panel .speed-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.settings-panel .volume-section p, .settings-panel .speed-section p {
	color: $color-black;
	margin: 0;
}

.settings-panel .speed-section {
	margin-top: 10px;
}

.settings-panel .volume-section .volume-range {
	display: flex;
}

.settings-panel .speed-buttons button {
	padding: 5px;
	background-color: $color-white;
    color: $color-black;
    border: 1px solid #b7b7b7;
    text-align: center;
    box-sizing: content-box;
}

.settings-panel .speed-buttons button:hover, .settings-panel .speed-buttons button.active {
	background-color: #ddd;
}

.settings-panel .speed-buttons button:first-child {
	border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.settings-panel .speed-buttons button:last-child {
	border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ondemandtts{
	outline: 1px dotted #66aaff;
    cursor: pointer!important;
}

.playtts {
	background-color: rgb(170, 255, 96) !important;
}

.ondemandtts:hover{
	outline: 4px solid rgb(170, 255, 96);
}