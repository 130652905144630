$color-black: #000;
$color-dark: #0d0d0d;
$color-dark-200: #101010;
$color-dark-300: #01041B;

$color-white: #fff;
$color-light: #f1f1f1;
$color-light-50: #d3d3d3;
$color-light-100: #efefef;
$color-light-200: #E5E5E5;
$color-light-300: #e0e0e0;
$color-light-400: #e3e3e3;
$color-light-450: #DEDEDE;
$color-light-500: #BCBCBC;
$color-light-800: #abb1bb;

$color-yellow: #ffed7f;
$color-yellow-200: #eadb4a;

$color-dark-red: #69659c;
$color-red: #ff0000;
$color-red-200: #c44538;
$color-red-400: #FF0509;
$color-red-600: #762c2c;

$orange: #ff756e;
$color-orange-100: #ff8373;

$color-green: #46afb8;
$color-green-100: #94d1d6;
$color-green-300: #3c809f;
$color-green-400: #47afb8; 
$color-green-600: #3b792f;

$color-blue: #4c72ae;
$color-blue-50: #5371b7;
$color-blue-100: #537ab7;
$color-blue-200: #4788ff;
$color-blue-250: #4274F6;
$color-blue-300: #496faa;
$color-blue-400: #4069ab;
$color-blue-500: #2e5b9e;
$color-blue-600: #2a5391;
$color-blue-700: #194792;
$color-blue-800: #2d4194;
$color-blue-900: #013584;
$color-blue-950: #003584;
$color-blue-1000: #306FDC;

$color-gray: #ADADAD;
$color-gray-100: #B2B2B2;
$color-gray-200: #acacac;
$color-gray-300: #d5d5d5;
$color-gray-350: #C1C1C1;
$color-gray-400: #9a9a9a;
$color-gray-450: #9d9d9d;
$color-gray-500: #b9b9b9;
$color-gray-600: #aca9a9;
$color-gray-650: #6E6E6E;
$color-gray-700: #797979;
$color-gray-750: #707070;
$color-gray-800: #919191;
$color-gray-850: #6A6A6A;
$color-gray-900: #3e3e3e;
$color-gray-950: #323233;
$color-gray-1000: #858585;
$color-gray-1050: #30DBDB;

$color-yellow-FFA500: #FFA500;

$color-white-C7C7C7: #c7c7c7;

$color-brown: #454545;
$color-brown-100: #585858;

$modal-bg: #363636;

$label-bg: #3c4046;

$calculator-btn-color: #5E5858;
$calculator-btn-bg: #ecedef;

// Box Shadow
$section-shadow: 0 3px 12px rgba($color-black, 0.22);
$text-shadow: 0 3px 6px rgba($color-black, 0.52);

$black-transparent: rgba(#22272F, 0.87);

$light-blue-background : #DEEBFF;

$blue-500: #003b93;
$blue-200: #4788ff;
$yellow-200: #FFED7F;


$theme-bg-color: var(--bg-color);
$theme-bg-color-normal: var(--bg-color-normal);
$theme-bg-color-normal-variant2 : var(--bg-color-normal-variant2);
$theme-bg-color-lighter : var(--bg-color-lighter);
$theme-bg-color-lighter-variant2 : var(--bg-color-lighter-variant2);
$theme-bg-color-lighter-variant3: var(--bg-color-lighter-variant3);
$theme-bg-color-dark: var(--bg-color-dark);
$theme-bg-color-dark-variant2: var(--bg-color-dark-variant2);
$theme-bg-color-dark-variant3: var(--bg-color-dark-variant3);
$theme-bg-color-darker: var(--bg-color-darker);
$theme-bg-color-darker-variant2 : var(--bg-color-darker-variant2);
$theme-bg-color-medium: var(--bg-color-medium);
$theme-bg-color-light: var(--bg-color-light);
$theme-bg-color-light-variant2 : var(--bg-color-light-variant2);
$theme-bg-color-lightest : var(--bg-color-lightest);
$theme-bg-color-chatIcon : var(--bg-color-chatIcon);
$theme--bg-color-seaGreen : var(--bg-color-seaGreen);
$theme--bg-color-seaGreen-light : var(--bg-color-seaGreen-light);
$theme-border-color: var(--border-color);
$theme-border-color-lighter: var(--border-color-lighter);
$theme-border-color-light: var(--border-color-light);
$theme-bg-color-chaticon : var(--bg-color-chaticon);
$theme-modal-cancel-btn-border : var(--modal-cancel-btn-border);



// font sizes
$xl: 22px;
$lg: 18px;
$sm: 14px;
$md: 16px;

// border-radius
$border-radius-md: 8px;