table {
  width: 100%;

  th .font-medium {
    font-size: 14px;
  }

  tbody {
    transition: height 0.5s ease;
    overflow: hidden;
  }

  .dark-row {
    background-color: $theme-bg-color-lighter;
  }

  .dark-row-gray {
    background-color: $color-gray;
  }

  .light-row-gray {
    background-color: $color-gray-500;
  }

  .dark-row-blue {
    // background-color: $theme-bg-color-darker;

    td {
      background-color: $theme-bg-color-darker;
    }
  }

  .darker-row-border-first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .darker-row-border-last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .highlight-row {
    background-color: $color-gray;
  }

  .notfound__data {
    background-color: $theme-bg-color-medium;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0;
    border-radius: $border-radius-md;

    @include media-breakpoint(md) {
      font-size: 14px;
    }

    &-container {
      @include flexcenter-both;
      height: 107px;
    }
  }

  .no-admin {
    background-color: $color-gray-500;
    text-align: center;
    font-size: $md;
    font-weight: 700;
    line-height: 21.82px;
    color: $color-gray-950;
    padding: 9px 8px 8px;
    border-radius: $border-radius-md !important;
    height: 150px;

    @include safari-suport {
      padding-top: 7px;
      padding-bottom: 9px;
    }

    @include media-breakpoint(md) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .dark-table {
    background-color: #0000001a;
  }

  .no-admin.dark-table {
    color: white;
  }

  .vertical-bottom {
    vertical-align: bottom;
  }

  .vertical-center {
    vertical-align: middle;
  }

  .table-sort-icon {
    margin-left: 10px;
    cursor: pointer;
  }
}

// New Version Table

.main-tbody {
  background-color: $color-gray-500;
  border-radius: $border-radius-md;
  height: 107px;
  overflow: unset;
  display: table-caption;
}

.table-main {
  width: 1240px;
  margin-bottom: 11px;

  > thead {
    display: table-caption;
  }

  .assessment-date {
    width: 67px;
    vertical-align: middle;
    position: relative;
    display: table-cell;

    div {
      width: 92px;
      height: 49px;
      border-radius: 0px 0px $border-radius-md $border-radius-md;
      rotate: -90deg;
      font-size: $sm + 1px;
      font-weight: 400;
      line-height: 18px;
      color: white;
      padding-top: 8px;
      position: absolute;
      top: 29px;
      left: -23px;
    }

    .react-loading-skeleton {
      width: 92px !important;
      height: 49px !important;
      rotate: -90deg;
      position: absolute;
      top: 29px;
      left: -23px;
    }
  }

  .name {
    text-align: left;
    width: 240px;
    min-width: 240px;
  }

  .last-active {
    width: 125px;
    min-width: 125px;
    text-align: center;
    padding-left: 10px;
  }

  .student-assessed {
    width: 120px;
    min-width: 120px;
    padding-left: 10px;

    .progress-skeleton-loader {
      display: block;
      padding-top: 5px;
    }
  }

  .proficiency {
    width: 120px;
    min-width: 120px;
    padding-left: 10px;

    .progress-skeleton-loader {
      display: block;
      padding-top: 5px;
    }
  }

  .guide {
    width: 80px;
    min-width: 80px;
    padding-left: 10px;
  }

  .answer {
    width: 90px;
    min-width: 90px;
    padding-left: 10px;
  }

  .reports-dropdown {
    width: 155px;
    text-align: left;
    padding-left: 10px;

    select {
      width: 100%;
      height: 26px;
    }
  }

  .available-student {
    width: 115px;
    padding-left: 10px;
    padding-top: 16px;

    p {
      font-size: 12px;
      line-height: 16.37px;
      font-weight: 700;
      color: $color-gray-950;
    }
  }

  .monitor-button {
    width: 100px;

    .button-green {
      border-radius: $border-radius-md;
    }
  }

  th {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.1px;
    text-align: center;
    color: white;
    vertical-align: bottom;
  }

  tbody {
    tr {
      vertical-align: top;

      .name {
        padding-top: 19.5px;

        p {
          font-size: $md;
          font-weight: 700;
          line-height: 21.82px;
        }

        .open-until-label {
          background-color: rgb($color-blue-200, 0.56);
          padding: 3px 10px 2px;
          height: 23px;
          border-radius: $border-radius-md;
          font-size: $sm;
          font-weight: 400;
          line-height: 19.1px;
          text-align: center;
          color: white;
          display: inline-block;
          margin-top: 6px;
          // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.52);
        }

        .open-until-label-dark {
          background-color: #3260d4 !important;
        }
      }

      .last-active {
        padding-top: 19.5px;
      }

      .student-assessed,
      .proficiency {
        padding-top: 7px;
      }

      .guide,
      .answer {
        padding-top: 18.5px;

        svg {
          height: 20px;
        }
      }

      .reports-dropdown {
        padding-top: 16px;

        .table-dropdown.dropdown {
          height: 26px;

          .table-dropdown-toggle {
            color: $color-gray-900 !important;
          }
        }
      }

      .reports-dropdown.disabled {
        opacity: 0.5;
      }

      td {
        font-size: $md;
        font-weight: 400;
        line-height: 21.82px;
        text-align: center;
        color: $color-gray-950;

        p {
          color: $color-gray-950;
          margin: 0;
        }
      }

      .open-until-label {
        background-color: rgb($color-blue-200, 0.56);
        padding: 3px 10px 2px;
        height: 23px;
        border-radius: $border-radius-md;
        font-size: $sm;
        font-weight: 400;
        line-height: 19.1px;
        text-align: center;
        color: white;
        display: inline-block;
        margin-top: 6px;
        // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.52);
      }

      .bottom-text {
        text-align: left;
        padding-top: 4px;
        @include ellipsis;
      }

      .complete-toggle {
        text-align: right;
        padding-top: 6px;
        padding-right: 24px;

        .complete-toggle-container.small-toggle {
          @include flex-box(flex-end, center);

          p {
            font-size: 12px;
            font-weight: 700;
            line-height: 16.37px;
            color: $color-gray-950;
            margin-bottom: 0;
            margin-right: 8px;
            margin-top: -2px;
          }

          .switch {
            width: 31px;
            height: 18px;
            margin-bottom: 1px;

            .switch-slider {
              &::before {
                width: 15px;
                height: 15px;
                top: 1px;
                left: 1px;
              }
            }

            .switch-checkbox:checked + .switch-slider::before {
              transform: translateX(13px);
            }
          }
        }
      }

      .monitor-button {
        padding-top: 13px;

        .turnMonitorContainer {
          margin-top: 10px;
        }
      }
    }

    .last-active {
      padding-top: 19.5px;
    }

    .student-assessed,
    .proficiency {
      padding-top: 7px;
    }

    .guide,
    .answer {
      padding-top: 18.5px;

      svg {
        height: 20px;
      }
    }

    .reports-dropdown {
      padding-top: 16px;

      .table-dropdown.dropdown {
        height: 26px;

        button {
        &:focus-visible {
          border: 1px solid black;
        }
      }

        .table-dropdown-toggle {
          color: $color-gray-900 !important;
        }
      }
    }

    .reports-dropdown.disabled {
      opacity: 0.5;
    }

    td {
      font-size: $md;
      font-weight: 400;
      line-height: 21.82px;
      text-align: center;
      color: $color-gray-950;

      p {
        color: $color-gray-950;
        margin: 0;
      }
    }

    .open-until-label {
      background-color: $color-blue-200;
      padding: 2px 10px;
      border-radius: $border-radius-md;
      font-size: $sm;
      font-weight: 400;
      line-height: 19.1px;
      text-align: center;
      color: white;
      display: inline-block;
      margin-top: 6px;
    }

    .bottom-text {
      text-align: left;
      padding-top: 4px;
      @include ellipsis;
    }

    .complete-toggle {
      text-align: right;
      padding-top: 6px;
      padding-right: 24px;

      .complete-toggle-container.small-toggle {
        @include flex-box(flex-end, center);

        p {
          font-size: 12px;
          font-weight: 700;
          line-height: 16.37px;
          color: $color-gray-950;
          margin-bottom: 0;
          margin-right: 8px;
          margin-top: -2px;
        }

        .switch {
          width: 31px;
          height: 18px;
          margin-bottom: 1px;

          .switch-slider {
            &::before {
              width: 15px;
              height: 15px;
              top: 1px;
              left: 1px;
            }
          }

          .switch-checkbox:checked + .switch-slider::before {
            transform: translateX(13px);
          }
        }
      }
    }

    .monitor-button {
      padding-top: 13px;
    }
  }
}

.not-scheduled-table {
  tbody {
    tr {
      vertical-align: middle;
      height: 54px;
      background-color: $theme-bg-color-normal;
      border-radius: $border-radius-md;
      border-spacing: 11px;
      display: table-cell;

      .notSchedule-label {
        width: 111px;
        padding-top: 2px;

        .no-schedule-data {
          display: block;
          border-radius: 0px 8px 8px 0px;
          box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.25);
          padding: 5px 22px 4px 18px;
          width: 111px;
          height: 41px;
          font-size: $sm + 1;
          font-weight: 400;
          line-height: 16px;
          color: $color-gray-950;
        }
      }

      .name {
        padding-top: 0;
        font-size: $md;
        font-weight: normal;
        line-height: 21.82px;
        color: white;
        width: 175px;
        min-width: 175px;
        padding-left: 22px;
      }

      .open-date-td {
        text-align: left;
        width: 180px;
        min-width: 180px;

        .open-until-label {
          background-color: #3260d4;
          padding: 2px 10px;
          border-radius: $border-radius-md;
          font-size: $sm;
          font-weight: 400;
          line-height: 19.1px;
          text-align: center;
          color: white;
          display: inline-block;
          margin-top: 0px;
          height: 23px;
          // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.52);
        }
      }

      .message {
        width: 617px;
        max-width: 617px;

        p {
          text-align: left;
          font-size: $md;
          font-weight: 400;
          line-height: 21.82px;
          color: white;
          width: 617px;
          min-width: 617px;
          padding-left: 10px;
          @include ellipsis;
        }
        .resource-name-animate {
          transition: left 1s linear, width 3s linear !important;
          position: relative;
          left: 0;
      }
        
      }

      .availability-td {
        width: 155px;

        .open-date {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: center;
          color: white;
        }

        .available {
          font-size: $md;
          font-weight: 400;
          line-height: 21.82px;
          text-align: center;
          color: white;
        }
      }
    }
  }
}
