@font-face {
  font-family: "Helvetica-Nueue", sans-serif;
  src: url("../../fonts/HelveticaNeue-Regular.ttf");
}

.fade.modal.show {
  padding-right: 0 !important;
}

.modal {
  z-index: 99999999;
  &-backdrop {
    // background-color: #ffffff2b;
    background-color: #000000b3;
    z-index: 99999999;
    opacity: 1 !important;
  }
  
  .modal-dialog-centered {

    // @include media-breakpoint(sm) {
    //   min-height: unset;
    // }
  }

  .welcome-modal {
    .modal__body {
      .modal__form-welcome {
        .error-hint {
          right: 20px !important;
          top: 50px !important;
        }
      }

      .modal__form-row {
        .green-circle,
        .red-circle {
          top: 21px !important;
        }

        .welcome-edit-select {
          color: #000 !important;
        }
      }

      .custom-padding {
        input {
          padding: 13px 20px 11px 50px !important;
        }
      }

      .success-msg {
        height: 21px;
        color: rgb(249, 249, 102);
        font-size: 13px;
        opacity: 0;
        transition: all 0.7s;
      }

      .success-msg.fadeIn {
        opacity: 1;
        transition: all 0.7s;
      }

      .modal__editor {
        .green-circle,
        .red-circle {
          top: 405px !important;
          left: 40px !important;
        }
      }

      .custom-padding {
        .ql-editor {
          padding: 12px 15px 12px 40px !important;
        }
      }
    }
  }

  &-content {
    border: none;
    background-color: rgba($modal-bg, 0.87);
    border: 1px solid #ffffff;
    width: 426px;
    margin: 0 auto;
    border-radius: 10.5px;
    padding: 11px 16px 14px 19px;
    // box-shadow: 3px 3px 6px rgba($color-black, 0.55);

    @include media-breakpoint(sm) {
      padding: 16px 10px;
    }

    &-edit {
      width: 100%;
      max-width: 800px;

      @media only screen and (max-width: 825px) {
        margin-right: 12px;
        margin-left: 12px;
      }

      @include media-breakpoint(md) {
        width: calc(100% - 24px);
      }
    }
    &-cmsEdit {
      min-width: 577px;
      margin-bottom: 61px;
      height: 500px;
      overflow: hidden;

      @include media-breakpoint(xl) {
        margin-bottom: 53px;
      }
    }
    &-welcome {
      min-width: 700px;
    }
    &-blackProgress {
      width: 100%;

      @media only screen and (max-width: 894.99px) {
        
      }
    }
    &-contactSupport {
      min-width: 818px;
      padding: 0;

      @media only screen and (max-width: 842.99px) {
        min-width: calc(100% - 24px);
        margin-right: 12px;
        margin-left: 12px;
      }
    }
    &-transferStudent {
      min-width: 818px;

      @media only screen and (max-width: 848.99px) {
        min-width: calc(100% - 24px);
        margin-right: 12px;
        margin-left: 12px;
      }
    }
    &-risk-report {
      width: 100%;
    }

    &-changeIcon {
      width: 100%;
      max-width: 744px;

      .changeIconModal{

        .modal__header{
          padding: 0;
        }

        .modal__body {

          @include media-breakpoint(sm) {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }

      @include media-breakpoint(md) {
        max-width: 97%;
      }
      
      @include media-breakpoint(sm) {
        padding-inline: 14px;
      }
    }

    .reply-to-label {
      background-color: #373c44;
      display: inline-block;
      padding: 0px 19.5px;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      color: white;
      margin-left: 20px;
      margin-bottom: 3px;
    }
  }

  .resources-modal{
    .modal__footer{
      .success-btn{
        width: 84px;
        @include media-breakpoint(sm){
          width: 77px;
        } 
      }
    }
  }


  &-read {
    padding: 16px 11px;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 0;

    &-support {
      padding: 10px;
      max-height: 73px;
      min-height: 73px;

      @include media-breakpoint(md) {
        padding: 10px 0;
      }
    }

    &-log {
      padding: 10px 19px;
    }

    &-logDetail {
      padding: 3px 11px;
    }
    &-supportDetail {
      img {
        height: 22px;
        margin-right: 23px;
        margin-left: 5px;
        margin-top: 2px;
        cursor: pointer;

        @include media-breakpoint(md) {
          margin-right: 16px;
        }
      }
      p {
        font-size: 18px;
        line-height: 24px;

        @include media-breakpoint(md) {
          font-size: 16px;
          line-height: 20px;
        }

        @include media-breakpoint(sm) {
          font-size: 14px;
        }
      }
    }
    &-icon {
      display: inline-block;
      margin-right: 10px;
      margin-top: 1px;

      &.margin-top-2 {
        margin-top: 2px;

        @include safari-suport {
          margin-top: -1px;
        }
      }
    }
    &-iconQuestion {
      max-width: 24px;
      height: auto;
      margin-right: 10px;
    }
    &-title {
      font-family: $font-openSans;
      font-size: 21px;
      color: $color-white;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 20px;
        line-height: 27px;
      }

      @include media-breakpoint(sm) {
        font-size: 18px;
        line-height: 24px;
      }

      svg {
        margin-top: -4px;
      }
    }

    &-subTitle {
      padding-left: 45px;

      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 16px;
          line-height: 21px;
        }

        @include media-breakpoint(sm) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    &-paragraph {
      font-weight: bold;
      text-shadow: $text-shadow;
      margin-top: 4px;
    }
  }

  &__subheader {
    padding-top: 12px;
  }

  &__body {
    padding: 15px 0px;
    min-height: 88px;

    &.review-modal {
      padding: 14px 11px;
    }

    &.support-body {
      height: calc(100% - 146px);
    }

    &-text {
      line-height: 20px;
    }

    &-temporary {
      font-size: 14px;
      font-weight: bold;
      text-shadow: $text-shadow;
      line-height: 19px;
      margin-bottom: 9px;
    }

    &-password {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 11px;
    }

    &-objective {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      max-width: 279px;
      margin-bottom: 16px;
    }

    &-objectiveTitle {
      margin-right: 16px;

      p {
        text-align: right;
        font-size: 16px;
        color: $color-gray-500;
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    &-objectiveData {
      display: flex;
      flex-direction: column;
      height: 93px;
      width: 24px;
    }

    &-objectiveQuestions {
      height: 50px;
      border-left: 1px solid $color-white;
      padding: 2px 0;
      transform: rotate(225deg) translate(-14px, 15px);

      span {
        font-size: 14px;
        color: $color-white;
        line-height: 20px;
        writing-mode: vertical-rl;
        cursor: pointer;
        transform: translate(-1px, 1px);
      }
    }

    &-objectiveNumber {
      @include flexcenter-both;
      border-right: 1px solid $color-white;
      font-family: $font-cabin;
      height: 21px;
      font-size: 14px;
      color: $color-white;
      line-height: 20px;
    }

    &-objectiveIcon {
      @include flexcenter-both;
      border-right: 1px solid $color-white;
      height: 20px;
    }

    &-row {
      display: flex;
      max-width: 335px;
      margin: 0 auto;
      margin-top: 10px;
      padding-right: 8px;

      span {
        @include flexcenter-both;
        min-width: 28px;
        height: 28px;
        font-family: $font-cabin;
        font-size: 14px;
        color: $color-white;
        line-height: 20px;
        margin-right: 16px;
        margin-top: 5px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }

    &-circle {
      border-radius: 50%;
      background-color: $color-green-600;
    }

    &-rectangle {
      background-color: $color-red-200;
    }

    &-benchmark {
      @include flexcenter-both;
      margin-top: 5px;
      margin-bottom: 22px;

      span {
        @include flexcenter-both;
        min-width: 28px;
        height: 28px;
        background-color: $color-blue-800;
        border-radius: 50%;
        font-family: $font-cabin;
        font-size: 14px;
        color: $color-white;
        line-height: 20px;
        margin-left: 7px;
      }
    }

    .status {
      margin-left: 0;

      p {
        margin-bottom: 0;
      }
    }
  }

  .thisWeekTextBox {
    .modal__form {
      height: 262px;
      overflow: hidden;

      .editor-middle-body {
        overflow: hidden;

        .ql-container {
          overflow: hidden;
          height: 220px !important;

          .ql-editor {
            overflow: hidden;

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 21.3px;
              color: white;
              margin-bottom: 12px;
            }

            ul {
              li {
                font-size: 16px;
                font-weight: 400;
                line-height: 21.4px;
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .editGoalEditor {
    .modal__form {
      .editor-middle-body {
        .ql-container {
          .ql-editor {
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              color: white;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }

  &__editor {
    .row {
      padding-bottom: 10px;
    }
    .loading-spinner {
      position: absolute;
      left: 50%;
      top: 62%;
      transform: translate(-50%, -50%);
    }
  }
  &__tabs {
    padding: 0 11px;
  }
  &__footer {
    @include flex-box(flex-end, center);
    gap: 10px 17px;
    border: none;
    padding: 0px;
    margin-top: 17px !important;

    .inner-buttons {
      gap: 10px 20px;
      display: flex;
    }

    &.with-padding {
      padding: 0 11px;
    }

    &.support-footer {
      padding: 24px 18px 16px 18px;

      @include media-breakpoint(sm) {
        padding: 16px;
      }
    }

    &-log {
      padding: 22px 16px 16px;
    }

    .btn {
      height: 33px;
      padding: 2px 16px;

      @-moz-document url-prefix() {
        padding-top: 3px;
      }
    }

    .pause-btn-width{
      width: 83px;
      @include media-breakpoint(sm) {
        width: 76px;
      }
    }

    .cancel-btn {
      width: 100px;
      border: 1px solid #fff !important;
      color: #fff !important;
      background: none;
      background-color: unset !important;

      &:focus-visible {
        border: 1px solid #fff !important;
        color: #fff !important;
      }
      
      &:hover {
        background-color: unset !important;
      }

      &:active {
        background-color: unset !important;
      }
    }

    .delete-button-width {
      width: 93px;
    }
  }

  .delete-carmit-modal{
    .danger-btn{
      width: 93px;
      padding: 2px 16px;
      // @include media-breakpoint(sm) {
      //   width: 55px;
      // }
    }
  }
  .change-password-modal{
    .success-btn{
      width: 88px;
      @include media-breakpoint(sm) {
        width: 81px;
      }
    }

    @media (max-width: 395px) {
      margin-top: 50px !important;
    }
  }

  &__form {
    position: relative;
    width: 100%;
    &-row {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 14px;
      margin-top: 4px;
      &.disabled {
        opacity: 0.23;
      }
      label {
        position: absolute;
        top: 1px;
        left: 25px;
        display: inline-block;
        background-color: $label-bg;
        height: 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        color: $color-white;
        border-radius: 20px;
        text-align: center;
        padding: 3px 12px;
        // z-index: 5;

        @include media-breakpoint(sm) {
          font-size: 12px;
        }

        &.lg {
          padding: 3px 22px;
        }
      }
      .custom-label {
        background-color: transparent !important;
        top: -7px;
        left: 15px;

        .dropdown-diabled {
          .objective-select__control {
            width: 123px;
            height: 23px;

            .objective-select__value-container {
              .objective-select__single-value {
                font-size: 12px;
                color: $color-blue-200;
                min-height: unset;
                margin-left: -4px;
                height: 23px;
              }
            }
          }
        }
      }

      select {
        -webkit-appearance: none;
        appearance: none;
        background-image: url("./../../img/select-icon-lg.svg");
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: center right 19px;
        width: 100%;
        height: 42px;
        background-color: $color-white;
        border-radius: $border-radius-md;
        border: none;
        outline: none;
        opacity: 1;
        padding: 9px 21px;
        padding-right: 42px;

        @include safari-suport {
          padding: 7px 22px 11px 22px;
          line-height: 20px;
        }
        &.disabled {
          opacity: 0.56;
          background-color: $color-white !important;
        }
      }

      .select-with-error {
        padding-left: 50px;

        option {
          padding-left: 22px;
        }
      }
      input {
        width: 100%;
        height: 42px;
        background-color: $color-white;
        font-size: 16px;
        color: #262626;
        line-height: 21.82px;
        border-radius: $border-radius-md;
        border: none;
        outline: none;
        padding: 11px 20px;

        @include safari-suport {
          padding: 7px 22px 11px 22px;
        }
      }
      textarea {
        width: 100%;
        min-height: 150px;
        background-color: $color-white;
        color: 000000;
        font-size: 16px;
        border-radius: $border-radius-md;
        border: none;
        outline: none;
        padding: 11px 20px;

        @include media-breakpoint(md) {
          font-size: 14px;
          padding: 10px 16px;
        }
      }
      .area-min-height {
        min-height: 162px;
      }
      .search-select {
        position: relative;
        width: 100%;
        height: 42px;
        background-color: $color-white;
        color: $color-dark;
        border-radius: $border-radius-md;
        font-size: 18px;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        input {
          padding: 11px 20px;
          padding-right: 5px;

          &::placeholder {
            font-size: 16px;
            color: $color-black;
          }
        }

        &-icon {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          width: 50px;
          height: 100%;
          padding-left: 9px;
        }

        img {
          cursor: pointer;
        }
      }
    }
    .all-admins-container {
      max-height: 300px;
      overflow-y: auto;
      background-color: $color-white;
      border-radius: $border-radius-md;
      margin-top: 10px;
      .admin-item {
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: $color-light-50;
        }
        p {
          color: $color-black;
          margin-bottom: 0;
        }
      }
      .selected-admin {
        background-color: $color-light-50;
      }
    }
    .no-admins {
      color: $color-black;
      margin-bottom: 0;
      text-align: center;
      font-weight: bold;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    &-pill {
      position: absolute;
      bottom: 11px;
      left: 21px;
    }
    &-welcome {
      width: 383px;

      @include media-breakpoint(md) {
        max-width: 383px;
        width: 100%;
      }
    }
    &-switch {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1rem;
      p {
        font-weight: bold;
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
    &-switch-school-del {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px 8px;
      margin-bottom: 1rem;
      margin-top: -1rem;
      p {
        font-weight: bold;
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }

  &__password {
    &-row {
      padding-right: 38px;
    }

    &-btn {
      border: none;
      background-color: transparent;
      @include flexcenter-both;
      min-width: 26px;
      height: 40px;
      margin-left: 12px;
      margin-top: 13px;
      padding: 0;
      cursor: default !important;
    }

    &-eye {
      cursor: pointer;
    }

    &-text {
      color: $color-white;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 6px;
    }

    &-requirement {
      position: absolute;
      bottom: 15px;
      left: 22px;
      font-size: 12px;

      @media (max-width: 395px) {
        bottom: 62px;
      }
      
      p {
        color: $color-light-500;
        line-height: 14px;
        margin-bottom: 0;
        text-shadow: 0 3px 3px rgba($color-black, 0.58);

        span {
          font-size: 15px;
        }
      }
    }
  }
}

// Objective Modal
.objective-modal {
  .objective-modal-content {
    @include flex-box(space-between, flex-start);

    @media (max-width: 767px) {
      display: block;
    }
  }

  .modal-content {
    width: 100%;
    max-width: 779px;
    padding: 9px 11px 19px;
    border-radius: 10.5px;

    .objective-form {
      width: calc(100% - 318px);
      padding-right: 34px;

      @media (max-width: 767px) {
        width: 100%;
      }

      .modal__header {
        .modal__header-title {
          font-size: 21px;
          font-weight: 700;
          line-height: 28.6px;
          font-family: $font-openSans;
        }
      }

      .modal__body {
        padding: 21px 11px 3px;

        .modal__form {
          .modal__form-row {
            margin-top: 0px;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.add-ans-modal {

  .modal__header {
    align-items: flex-start;
    gap: 10px;

    .black__progress-icon {
      margin-top: 4px;
    }
  }

  .table-dropdown {
    padding-top: 2px;

      .table-dropdown-toggle {
        color: white !important;
        width: 79px;
        background-image: url(../../img/select-indicator.svg);
        border-color: white;
      } 

    .dropdown-menu {
      min-width: 100%;
    }
  }
}

.lesson-files {
  width: 100%;
  max-width: 318px;
  padding-right: 9px;
  @include flex-box(space-between, flex-start);
  flex-direction: column;
  height: 423px;

  @media (max-width: 767px) {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .lesson-footer {
    width: 100%;
    margin-top: 4px;
  }

  .power-standard {
    @include flex-box(space-between, center);
    gap: 8px;
    background-color: #00000033;
    border-radius: $border-radius-md;
    padding: 12px 18px;

    @media (max-width: 767px) {
      margin-top: 40px;
    }

    > div {
      @include flex-box(flex-start, center);
      gap: 11px;

      p {
        font-size: $md;
        font-weight: 700;
        line-height: 21.82px;
        margin-bottom: 0;
      }
    }

    .complete-toggle-container.small-toggle {
      .switch.studentCheakBox {
        margin-bottom: 0;
      }
    }
  }

  .footer-action-btn {
    @include flex-box(flex-end, center);
    gap: 15px;
    margin-top: 15px;

    .cancel-btn {
      padding: 6px 27px 5px;
      font-size: $md;
      font-weight: 400;
      line-height: 21.82px;
      color: white;
      background-color: unset;
      border: 1px solid white;
      border-radius: $border-radius-md;
      box-shadow: unset;

      &:active {
        background-color: unset !important;
      }

      &:focus-visible {
        background-color: unset !important;
      }
    }

    .save-btn {
      @include buttonGreen(97px, 33px, 6px 27px 5px, 8px);
    }
  }
}

.lesson-body-sec {
  width: 100%;

  .secHeading {
    font-size: $md;
    line-height: 21.82px;
    padding-left: 0;
    margin-bottom: 0;
  }

  .sec-desc {
    font-size: $sm;
    font-weight: 400;
    line-height: 19.1px;
    color: white;
    margin-bottom: 0;
  }

  .upload-file {
    @include flex-box(flex-start, center);
    gap: 16px;
    margin-top: 19px;

    .fileNumber {
      width: 47px;
      height: 47px;
      border-radius: 50%;
      background-color: $color-yellow;
      font-size: 28px;
      font-weight: 700;
      line-height: 37px;
      color: #424242;
      padding-top: 1px;
      @include flex-box(center, center);
    }

    .file-uploaded {
      background-color: $color-green;
    }

    .file-upload-detail {
      width: calc(100% - 63px);

      p {
        font-size: $md;
        font-weight: 700;
        line-height: 21.82px;
        color: white;
        margin-bottom: 3px;
      }

      .files-upload {
        @include flex-box(flex-start, center);
        gap: 5px;

        .file-pdf {
          width: auto;
          max-width: 50%;
          position: relative;
          @include flex-box(flex-start, center);
          gap: 5px;

          input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
          }

          input[type=file], 
            /* FF, IE7+, chrome (except button) */
            input[type=file]::-webkit-file-upload-button {
            /* chromes and blink button */
            cursor: pointer;
          }

          svg {
            cursor: pointer;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.1px;
            color: white;
            min-width: 86px;
          }
        }
      }
    }
  }
}

.lesson-file-modal {
  .modal-content {
    width: 100%;
    max-width: 550px;
    padding: 16px 24px;
    border-radius: 10.5px;
  }
}

.student-available-modal{
  .modal-dialog{
    .modal-content{
      .modal__footer{
        .success-btn{
          width: 141px;
          white-space: nowrap;
          @include media-breakpoint(sm) {
            width:128px;
          }
        }
      }
    }
  }
}

// Editor Customization
.quill {
  border-radius: 10px;
}

.ql-toolbar.ql-snow {
  background: $color-white;
  border-radius: $border-radius-md $border-radius-md 0px 0px;
}

.ql-container.ql-snow {
  background-color: $color-gray-500;
  border-radius: 0px 0px $border-radius-md $border-radius-md;
  color: black;

  .ql-editor {
      
    p {
      color: #000;
    }
  }
}

.ql-container .ql-snow p {
  color: $color-black;
}

.ql-container {
  height: 350px !important;
}

.modal-dialog {
  @media (min-width: 576px) {
    max-width: unset;
  }
}

.modal-edit-container > .modal-dialog {
  @media (min-width: 576px) {
    max-width: 818px;
    width: 100%;
  }
}

.modal-welcome-container > .modal-dialog {
  @media (min-width: 576px) {
    max-width: 700px !important;
  }
}

.modal-black-progress > .modal-dialog {
  @media (min-width: 576px) {
    max-width: 858px !important;
  }
}

.modal-black-progress.small > .modal-dialog {
  @media (min-width: 576px) {
    max-width: 429px !important;
  }
}

.modal-log-container > .modal-dialog {
  @media (min-width: 576px) {
    max-width: 788px !important;
    padding: 0 12px;
  }
}

.risk-report-modal > div {
  @media (min-width: 576px) {
    max-width: 842px !important;
    padding: 0 12px;
  }
}
.modal-content-risk-report {
  @media (min-width: 576px) {
    max-height: 635px;
  }
}

.modal-content-cmsEdit .modal__body {
  height: 377px;
  overflow: auto;
}

.modal-content-cmsEdit .modal__body::-webkit-scrollbar {
  display: none;
}

.cms-edit-modal {
  z-index: 9999999;
}

.cms-edit-modal-backdrop {
  z-index: 99999;
}

.newCommentCancel {
  background-color: #ff8373 !important;
  width: 103px;
  font-size: 16px;
  font-weight: 400;
  color: #000000 !important;
  border-radius: $border-radius-md !important;
}

.newCommentsave {
  width: 97px;
  height: 33px;
  background-color: #46afb8 !important;
  border-radius: $border-radius-md !important;
  font-size: 16px;
  font-weight: 400;
  color: white !important;
  border: 0px !important;
}

.newCommentsave:disabled {
  opacity: 0.52;
}

/* width */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.modal-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.modal-content::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
.modal-content::-webkit-scrollbar-thumb:hover {
  background: rgb(44, 44, 44);
}

.mastery-quiz-backdrop {
  background-color: unset;
}

.mastery-quiz {
  width: 100%;
  max-width: 818px;
  padding: 10px 20px 18px 20px;
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  background-color: rgb(54 54 54 / 0.95);
  z-index: 9999;

  .modal__header {
    display: block;
    padding: 0;

    .modal__header-title {
      line-height: 26px;
      top: 0;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 0;
      color: white;
      margin-top: 3px;
      font-family: $font-openSans;
    }
  }

  .modal__body {
    max-width: 702px;
    width: 100%;
    margin: 60px auto;
    padding: 0 0px 10px 38px;
    min-height: 267px;

    .radio-title {
      font-size: 19px;
      font-weight: 700;
      line-height: 26px;
      color: white;
      margin-bottom: 28px;
      width: 100%;
      max-width: 532px;
      margin-left: auto;
      margin-right: auto;
    }

    .mastery-radio-btn {
      display: flex;
      align-items: center;
      margin-bottom: 23px;
      padding-left: 100px;

      .radio-label {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 10px;
        width: 100%;
      }

      .radio-button {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 2px solid #537ab7;
        font-size: 20px;
        line-height: 22px;
        font-weight: 400;
        font-family: "Helvetica-Nueue", sans-serif;
        color: #537ab7;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1.3px;
      }

      input[type="radio"] {
        display: none;
      }

      input[type="radio"]:checked + .radio-label .radio-button {
        background-color: #537ab7;
        color: white;
      }

      .radio-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        color: white;
        font-family: "Helvetica-Nueue", sans-serif;
        margin-left: 12px;
        padding-bottom: 1px;
        width: auto;
        max-width: 90%;
      }
    }

    .mastery-radio-btn:last-child {
      margin-bottom: 0px;
    }

    .wrong-ans-correct {
      padding-top: 50px;
    }

    .quiz-answer {
      display: flex;

      .quiz-labels {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 12px;

        .wrongLabel {
          padding: 2px 12px;
          background-color: #c44538;
          border-radius: 11.5px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          height: 23px;
          margin-top: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 106px;
        }

        .correctLabel {
          padding: 2px 12px;
          background-color: #3b792f;
          border-radius: 11.5px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          height: 23px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 123px;
        }

        span:first-child {
          margin-bottom: 32px;
        }
      }
    }

    .answer {
      display: flex;
      margin-bottom: 28px;
      padding-left: 100px;

      .yourAnsWrong {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: #c44538;
        margin-right: 12px;
        font-size: 20px;
        font-weight: 400;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Helvetica-Nueue", sans-serif;
      }

      .correctAns {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: #3b792f;
        margin-right: 12px;
        font-size: 20px;
        font-weight: 400;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Helvetica-Nueue", sans-serif;
      }

      p {
        font-size: 20px;
        font-weight: 400;
        color: white;
        line-height: 24px;
        margin-bottom: 0px;
        min-width: 0;
        padding-top: 1px;
        width: auto;
        max-width: 90%;
      }

      .wrongLabel {
        padding: 2px 12px;
        background-color: #c44538;
        border-radius: 11.5px;
        font-size: 14px;
        font-weight: 400;
        color: white;
        margin-left: 9px;
        height: 23px;
        margin-top: 2px;
      }

      .correctLabel {
        padding: 2px 12px;
        background-color: #3b792f;
        border-radius: 11.5px;
        font-size: 14px;
        font-weight: 400;
        color: white;
        margin-left: 9px;
        height: 23px;
        margin-top: 2px;
      }
    }

    .answer:last-child {
      margin-bottom: 0px;
    }
  }

  .modal__footer {
    position: relative;
    margin-top: 0 !important;

    .cancel-btn {
      width: 100%;
      max-width: 100px;
      margin-right: 15px;
      position: relative;
      z-index: 44444;
      padding: 8px 26px;
      border: 0;
    }

    .next-btn {
      padding: 8px 11.5px;
      position: relative;
      z-index: 44444;
      border: 0;
    }

    .finishBtn {
      padding: 8px 25px;
    }

    .dots {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 3px;

      span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: block;
        margin-right: 16px;
        background-color: #d8d8d8;
      }

      .fill {
        background-color: #4788ff;
      }
    }
  }
}

.PacingCalender__SuggestedModel {
  width: 100%;
  max-width: 470px;

  .modal__header-title {
    padding: 0 !important;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    padding-left: 0 !important;
  }

  .modal__footer {
    padding: 0;
    flex-wrap: nowrap !important;

    @media (max-width: 428px) {
      flex-direction: column;
    }

    .btn {
      padding: 2px 14px;
      white-space: nowrap;

      @media (max-width: 428px) {
        width: 100%;
        max-width: 100%;
      }


    }
  }
}

.goals-modal {
  max-width: 834px;
  height: 674px;
  overflow: hidden;
  padding: 16px 22px 16px 25px;
  background-color: #3d4147f5;

  @include media-breakpoint(lg) {
    max-width: 97%;
    height: auto;
    max-height: 85vh;
    min-height: unset;
    overflow: auto;
  }

  @include media-breakpoint(sm) {
    padding: 12px 10px;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 20px 0px;
    }

    .left-sec {
      width: 100%;
      max-width: 400px;
      padding-right: 26px;

      @media (max-width: 767px) {
        max-width: 100%;
        padding-right: 0;
      }

      .backArrow {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -7px;
        cursor: pointer;
        position: relative;
        left: -12px;
        transition: all 0.3s;

        img {
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
        }
      }

      .backArrow:hover {
        background-color: #2a5391;
      }

      .heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 29.96px;
        font-family: $font-openSans;
        margin-bottom: 0px;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      .desc {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        font-family: $font-openSans;
        margin-bottom: 0;
        margin-top: 1px;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      .watch-video {
        display: flex;
        align-items: center;
        background-color: $theme-bg-color-lighter;
        border-radius: $border-radius-md;
        padding: 6px 20px 6px 12px;
        margin-top: 10px;
        height: 47px;
        display: none;

        .loader {
          width: 25px;
          height: 25px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }

          svg {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          color: white;
          width: calc(100% - 35px);
          margin-bottom: 0;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .steps-labels {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 2px;
        margin-top: 9px;

        @include media-breakpoint(sm) {
          margin-top: 0;
        }

        .step {
          padding: 3px 11px 2px 12px;
          border-radius: 40px;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          margin-right: 13px;
          margin-bottom: 12px;
          color: #424242;

          @include media-breakpoint(sm) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .step.not-complete {
          background-color: #b9b9b9;
        }

        .step.complete {
          background-color: #c9c9c9;
        }

        .goal-completed-label {
          background-color: #306fdc !important;
          color: white !important;
        }
      }

      .video-descs-container {
        position: relative;
      }

      .video-descs {
        margin-top: -2px;
        padding-left: 2px;
        padding-right: 22px;
        height: 565px;
        overflow-y: auto;
        scrollbar-width: none;

        @include media-breakpoint(md) {
          height: auto;
        }

        @include media-breakpoint(sm) {
          padding-right: 0;
        }

        p {
          font-size: $md;
          font-weight: 400;
          line-height: 21.82px;
          color: white;
          margin-bottom: 25px;

          @include media-breakpoint(sm) {
            font-size: $sm;
            line-height: 18px;
          }
        }

        p:last-child {
          margin-bottom: 0px;
        }
        #goal-text-desc {
          margin-bottom: 0;
        }

        .seeMore-btn {
          position: absolute;
          bottom: -2px;
          height: 100px;
          width: 100%;
          // background: linear-gradient(180deg, #D9D9D9 82.84%, rgba(217, 217, 217, 0) 100%);
          background-image: linear-gradient(transparent, #3d4147 96%);
          pointer-events: none;

          button {
            background-color: unset;
            border: 0;
            font-size: 18px;
            font-weight: 700;
            color: white;
            padding: 0;
            text-decoration: underline;
          }
        }
      }

      .video-descs::-webkit-scrollbar {
        display: none;
      }
    }

    .right-sec {
      width: 100%;
      max-width: 388px;
      margin-top: 4px;

      > .header {
        margin-top: 0px;
        margin-bottom: 13px;
        padding: 0 14px;

        .heading {
          font-size: 16px;
          font-weight: 700;
          line-height: 21.82px;
          color: white;
          margin-bottom: 0;
          font-family: $font-openSans;
        }

        .desc {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: white;
          margin: 2px 0;
          font-family: $font-openSans;
        }
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }

      .to-complete-goal {
        background-color: $color-gray-500;
        padding-top: 8px;
        padding-bottom: 11px;
        border-radius: $border-radius-md;
        height: auto;
        max-height: 490px;
        
        .heading-container{
          width: 100%;
          overflow: hidden;
          margin: 0 0px 0 0px;
          padding-left: 8px;
          
          > h3 {
            @include secHeading(0px, 3px);
            color: $color-gray-950;
            position: relative;
            @include ellipsis;
            padding-right: 14px;
            width: 100%;
            left: 0;
            transition: left 3s linear, width 3s linear;

            @include media-breakpoint(sm) {
              font-size: $lg;
              line-height: 22px;
              padding-left: 14px;
            }

          }
          .animated:hover{
            // left: -100% !important;
            // width: 200%;
            transition: left 3s linear, width 3s linear;
          }  
        }

        .task-complete-sec {
          padding-bottom: 0;
        }

        .goals-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          color: white;
          margin-top: 10px;
          margin-bottom: 0;
          padding: 3px 16px;
          background-color: $theme-bg-color-light;
        }

        .goals-body {
          padding: 0px 7px 0px 7px;
        }

        .complete-goals-body {
          padding: 0 7px;
        }

        .abc {
          margin-top: 10px;

          .header-total {
            padding: 0 13px;
            background-color: #b9b9b9;
            border-radius: $border-radius-md 8px 0px 0px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #424242;
          }

          .complete-goal-checkbox {
            border-radius: 0px 0px 8px 8px;
            margin-top: 0px;
          }
        }

        .coming-soon-top {
          position: relative;
        }

        .coming-soon {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          z-index: 1;
          border-radius: $border-radius-md 8px 8px 8px;
        }

        .coming-soon-text {
          left: 35%;
          text-align: center;
          position: absolute;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          color: white;
          margin-bottom: 0;
          padding: 0px 0px 0px 9px !important;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          margin-top: 1px;
          font-family: "Nunito", sans-serif;
          z-index: 2;
        }

        .complete-goal-checkbox {
          display: flex;
          align-items: center;
          background-color: $theme-bg-color-light;
          border-radius: $border-radius-md;
          padding: 11px 13px;
          margin-top: 7px;
          height: 47px;

          label {
            padding: 0;
            cursor: pointer;
          }

          h2 span {
            cursor: pointer;
          }

          input {
            display: none;
          }

          .checkbox {
            height: 25px;
            width: 25px;
            display: inline-block;
            position: relative;
            margin-right: 2px;
            background-color: #b9b9b9;
            border-radius: 50%;
            filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
          }

          [type="checkbox"]:checked + span:before {
            content: url("../../img/check-white.png");
            position: relative;
            top: 1px;
            left: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            width: 25px;
            height: 25px;
            padding-top: 0px;

            @media (max-width: 575px) {
              top: -4px;
              left: 1px;
              font-size: 26px;
            }

            @include mozilla-suport {
              top: 0px !important;
            }
          }

          [type="checkbox"]:checked + .checkbox {
            background-color: #46afb8;
          }

          .modal__header-title {
            font-size: $sm + 1;
            font-weight: 400;
            line-height: 18px;
            color: white;
            margin-bottom: 0;
            position: unset;
            padding: 0px 0px 0px 9px !important;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-top: 1px;
            font-family: $font-nunito;

            @include media-breakpoint(sm) {
              font-size: 12px;
              line-height: 15px;
            }
          }

          .text-adjust {
            font-size: 13px;
            line-height: 14px;
          }
        }

        .disable {
          opacity: 0.49;
        }

        .total-completed {
          padding: 7px 7px 0px 7px;

          .header-total {
            padding: 0 13px;
            background-color: #b9b9b9;
            border-radius: $border-radius-md 8px 0px 0px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #424242;
          }

          .goals-body {
            background-color: rgb(28 74 148 / 20%);
            border-radius: 0px 0px 8px 8px;
            padding: 6px 20px 6px 11px;

            .complete-goal-checkbox {
              background-color: unset;
              padding: 0;
              margin-top: 0;

              h2 {
                span {
                  line-height: 17px;
                }
              }
            }
          }

          .complete-goals-body {
            padding: 0;

            .complete-goal-checkbox {
              border-radius: 0px 0px 8px 8px;
              margin-top: 0;
            }
          }
        }
      }

      .task-complete-sec {
        max-height: 380px;
        overflow-y: auto;
        padding-bottom: 12px;
      }

      .task-complete-sec::-webkit-scrollbar {
        display: none;
      }

      .video-descs {
        margin-top: 12px;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 21.82px;
          color: #d9d9d9;
          margin-bottom: 25px;
        }
      }

      .rightText-margin {
        margin-top: 46px;
      }
    }

    .right-sec::-webkit-scrollbar {
      display: none;
    }
  }

  .goals-modal-checkbox {
    padding-left: 4px;
    padding-top: 2px;

    input {
      display: none;
    }

    .checkbox {
      height: 25px;
      width: 25px;
      display: inline-block;
      position: relative;
      margin-right: 2px;
      background-color: #b9b9b9;
      border-radius: 50%;
      filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.25));
    }

    [type="checkbox"]:checked + span:before {
      content: url("../../img/check-white.png");
      position: unset;
      top: 3px;
      left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      width: 23px;
      height: 23px;
      padding-top: 3px;

      @media (max-width: 575px) {
        top: -4px;
        left: 1px;
        font-size: 26px;
      }
    }

    [type="checkbox"]:checked + .checkbox {
      background-color: #46afb8;
    }
  }
}

.quiz-complete-modal {
  margin: 0 auto;
  text-align: center;
  min-height: 257px;
  padding-top: 63px;

  .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
    color: white;
    margin-bottom: 7px;
  }

  label {
    margin-top: 7px;
    margin-bottom: 28px;

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: white;
      text-align: left;
    }
  }

  .elements {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: white;

    @media (max-width: 575px) {
      font-size: 12px;
      padding-right: 10px;
    }
  }

  .quiz-complete-checkBox input {
    display: none;
    /* Hide the default checkbox */
  }

  /* Style the artificial checkbox */
  .quiz-complete-checkBox .checkbox {
    height: 23px;
    width: 23px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    background-color: #b9b9b9;
    border-radius: 50%;
  }

  /* Style its checked state...with a ticked icon */
  .quiz-complete-checkBox [type="checkbox"]:checked + span:before {
    content: url("../../img/check-white.png");
    position: unset;
    top: 3px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    width: 23px;
    height: 23px;
    padding-top: 3px;

    @media (max-width: 575px) {
      top: -4px;
      left: 1px;
      font-size: 26px;
    }
  }

  .quiz-complete-checkBox [type="checkbox"]:checked + .checkbox {
    background-color: #46afb8;
  }

  .correctBtn {
    padding: 2px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: white;
    background-color: #3b792f;
    border: 0;
    border-radius: 11.5px;
  }

  .wrongBtn {
    padding: 2px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: white;
    background-color: #c44538;
    border: 0;
    border-radius: 11.5px;
  }
}

.cmsAssessmentModal {
  .modal__header-title {
    top: 0px;
  }

  .modal__body {
    .modal__form {
      select {
        color: $color-gray-950;
        padding: 9px 42px 9px 22px;
      }
    }
  }

  .modal__footer {
    button {
      padding: 7px 17px;
    }
  }
}

.this-week-edit-modal {
  .modal-content {
    width: 491px;
    border-radius: 10.5px;
  }
}

.goal-edit-modal {
  max-width: 839px;
  height: 615px;
  overflow: hidden;
  padding: 16px 22px 16px 25px;
  background-color: #3d4148f5;

  @include media-breakpoint(md) {
    overflow: auto;
  }

  @include media-breakpoint(sm) {
    max-width: 97%;
  }

  .modal__editor {
    .row {
      padding-bottom: 0;

      @include media-breakpoint(md) {
        flex-direction: column;
      }

      .modal__form-row {
        margin-top: 0 !important;

        @include media-breakpoint(md) {
          margin-top: 12px;
        }

        .quill {

          @include media-breakpoint(md) {
            width: 100%;
          }

        }
      }

      .instruction-column {
        margin-top: -8px;

        @include media-breakpoint(md) {
          margin-top: 12px;
        }

        .quill  {

          .ql-container {
            height: 428px !important;
          }
        }
      }

      .ql-container {
        background-color: $color-gray-500;

        .ql-editor {

          p, span {
            word-break: break-all;
            color: $color-gray-950 !important;
          }
        }
      }
    }
  }

  .modal__footer {
    margin-top: 7px !important;
    padding-right: 0;
  }
}

.attempt-assign-modal {
  padding: 14px 19px 18px 14px;

  .modal__header {
    padding: 0;

    .yellow {
      background-color: #ffed7f;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      margin-right: 9px;
    }
  }
}

.satisfaction-survey-modal {
  width: 100%;
  max-width: 940px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 11px;
  padding-bottom: 14px;

  @media (max-width: 991px) {
    max-width: 90%;
  }

  .btn {
    border-radius: $border-radius-md !important;
  }

  .cancel-btn {
    width: 84px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .survey-no-thanks-btn {
    width: 107px;
    padding: 9px 6px;

    // &:hover {
    //     background-color: #cf3321 !important;
    // }
  }

  .survey-later-btn {
    width: 69px;
    margin-left: -2px;
    background-color: #ffed7f !important;
    padding-bottom: 5px;

    // &:hover {
    //     background-color: #cbbc2f !important;
    // }
  }

  .success-btn {
    width: 57px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 33px;
  }

  .modal__header-desc {
    color: #fff;
    font-family: $font-openSans;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 13px;
    min-height: 41px;
  }

  .modal__body {
    text-align: center;

    .ques-body-container {
      width: 100%;
      max-width: 760px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 451px;
      padding-bottom: 65px;

      @media (max-width: 991px) {
        height: auto;
      }

      .survey-ques {
        font-size: 19px;
        font-weight: 700;
        line-height: 25px;
        font-family: "Nunito", sans-serif;
        margin-bottom: 8px;
        width: auto;
        display: block;
        text-align: left;
        height: 44px;
        display: flex;
        align-items: flex-end;

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
          height: 40px;
        }

        p {
          display: inline;
          padding-left: 8px;
          margin-bottom: 0;
          font-size: 19px;
          font-weight: 700;
          font-family: "Nunito", sans-serif;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        span {
          position: relative;
          top: 2px;

          @media (max-width: 767px) {
            top: 0px;
          }
        }
      }

      .ques-scnd-line {
        align-items: flex-start;

        span {
          top: -2px;

          @media (max-width: 767px) {
            top: -1px;
          }
        }
      }

      .quiz-container {
        display: block;
        text-align: center;
        position: relative;

        @media (max-width: 991px) {
          border-radius: $border-radius-md;
          overflow-x: auto;
          overflow-y: unset;
        }
      }

      .ans-container {
        display: flex;
        justify-content: center;
        background: #282828;
        border-radius: $border-radius-md;
        padding: 17px 50px 15px 6px;
        overflow: auto;

        @media (max-width: 991px) {
          padding: 17px 30px 23px 30px;
          width: 747px;
        }

        @media (max-width: 576px) {
          justify-content: flex-start;
        }

        .quiz-labels {
          width: 100%;
          max-width: 263px;

          .radio-col-1 {
            text-align: right;
            font-size: 16px;
            font-weight: 400;
            font-family: "Helvetica-Nueue", sans-serif;
            line-height: 20px;
            color: white;
            position: relative;
            right: -6px;
            padding-top: 3px;
            height: 32px;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .radio-col-1:first-child {
            height: 40px;
            margin-bottom: 10px;
          }
        }

        .option {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          gap: 15px 9px;
          margin-bottom: 10px;

          &:first-child {
            .radio-col-2,
            .radio-col-3,
            .radio-col-4,
            .radio-col-5,
            .radio-col-6 {
              height: auto;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          .radio-col-2,
          .radio-col-3,
          .radio-col-4,
          .radio-col-5,
          .radio-col-6 {
            width: 83px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            font-family: "Helvetica-Nueue", sans-serif;
            line-height: 20px;
            color: white;
            height: 32px;

            input[type="radio"] {
              appearance: none;
              width: 27px;
              height: 27px;
              border: 2px solid #537ab7;
              border-radius: 50%;
              outline: none;
              transition: box-shadow 0.3s ease;
            }

            input[type="radio"]:before {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }

            input[type="radio"]:checked {
              border: 0;
            }

            input[type="radio"]:checked:before {
              background: #537ab7;
            }
          }
        }
      }
    }

    .skeleton-container {
      width: 100%;
      display: block;

      .skeleton {
        background-color: #5a5a5a;
        height: 32px !important;
        border-radius: $border-radius-md;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(8%, rgba(130, 130, 130, 0.2)),
          color-stop(18%, rgba(130, 130, 130, 0.3)),
          color-stop(33%, rgba(130, 130, 130, 0.2))
        );
        background: linear-gradient(
          to right,
          rgba(130, 130, 130, 0.2) 8%,
          rgba(130, 130, 130, 0.3) 18%,
          rgba(130, 130, 130, 0.2) 33%
        );
        background-size: 800px 100px;
        animation: wave-lines 2s infinite ease-out;
      }

      @keyframes wave-lines {
        0% {
          background-position: -468px 0;
        }
        100% {
          background-position: 468px 0;
        }
      }

      .quiz-container {
        display: block;

        .ans-container {
          display: flex;
          gap: 0 9px;

          .quiz-labels {
            width: 100%;
            max-width: 230px;

            .radio-col-1 {
              position: unset;
              margin-left: auto;
            }

            .radio-col-1:first-child {
              opacity: 0;
            }

            .radio-col-1:nth-child(2) {
              max-width: 90px;
            }

            .radio-col-1:nth-child(3) {
              max-width: 140px;
            }

            .radio-col-1:nth-child(4) {
              max-width: 190px;
            }

            .radio-col-1:nth-child(5) {
              max-width: 140px;
            }

            .radio-col-1:nth-child(6) {
              max-width: 190px;
            }
          }

          .ans-quiz {
            .option {
              .radio-col-2,
              .radio-col-3,
              .radio-col-4,
              .radio-col-5,
              .radio-col-6 {
                display: flex;
                align-items: center;

                span {
                  width: 27px;
                  height: 27px !important;
                  border-radius: 50%;
                  display: block;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }

      .survey-ques {
        width: 100%;
        max-width: 450px;
      }

      .ques-ans-sec {
        padding-bottom: 30px;
      }
    }

    .ques-ans-sec {
      width: 100%;
      max-width: 698px;
      margin: 0px auto;
      text-align: left;
      height: 451px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 64px;

      @media (max-width: 576px) {
        margin: 20px auto 40px;
      }

      .textarea-skeleton {
        height: 274px !important;
      }

      .ques {
        font-size: 19px;
        font-weight: 700;
        line-height: 26px;
        font-family: "Nunito", sans-serif;
        margin-bottom: 10px;
        height: 44px;
        display: flex;
        align-items: flex-end;

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
          height: 40px;
        }

        p {
          display: inline;
          padding-left: 8px;
          margin-bottom: 0;
          font-size: 19px;
          font-weight: 700;
          font-family: "Nunito", sans-serif;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        span {
          position: relative;
          top: 2px;

          @media (max-width: 767px) {
            top: 0px;
          }
        }
      }

      .ques-scnd-line {
        align-items: flex-start;

        span {
          top: -2px;

          @media (max-width: 767px) {
            top: 0px;
          }
        }
      }

      .ques.skeleton {
        height: 26px !important;
      }

      .ans-textarea {
        height: 274px;
        overflow: hidden;

        textarea {
          width: 100%;
          height: 100%;
          resize: none;
          background-color: #b0b0b0;
          font-size: 19px;
          font-weight: 400;
          line-height: 29px;
          font-family: "Nunito", sans-serif;
          padding: 11px 14px;
          border-radius: $border-radius-md;
          border: 0;

          @media (max-width: 576px) {
            font-size: 16px;
            line-height: 20px;
          }

          &:focus-visible {
            outline: none;
          }

          &::placeholder {
            font-size: 19px;
            font-weight: 400;
            line-height: 32px;
            color: #4e4e4e;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .modal__footer {
    justify-content: space-between !important;
    position: relative;

    @media (max-width: 600px) {
      margin-top: 40px !important;
      padding: 0;
    }

    @media (max-width: 420px) {
      flex-direction: column-reverse;
      justify-content: center;
      gap: 10px;
    }

    .btn {
      height: 33px;
    }

    .success-btn {
      background-color: #46afb8 !important;
      padding-bottom: 5px;

      // &:hover {
      //     background-color: #2f9ba5 !important;
      // }
    }

    .left-btn {
      width: 190px;

      @media (max-width: 420px) {
        width: 100%;
        justify-content: center;
      }
    }

    .pagination-btn {
      @media (max-width: 420px) {
        justify-content: center;
      }

      .cancel-btn {
        background-color: #537ab7;
        color: white;
        border: 0;
        padding-bottom: 5px;

        // &:hover {
        //     background-color: #476daa;
        // }
      }
    }

    .survey-pagination {
      // position: absolute;
      // left: 0;
      // right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      // bottom: 20px;

      @media (max-width: 675px) {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
      }

      span {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
        margin-right: 5px;
        background-color: #fff;
        text-align: center;
        font-family: $font-cabin;
        font-size: 14px;
        color: #6a6a6a !important;
      }

      .active {
        width: 30px !important;
        height: 30px !important;
        margin-right: 3px !important;
        font-size: 17px !important;
        font-weight: 700 !important;
        color: #000 !important;
      }

      .answered {
        border-color: #46afb8 !important;
        border: 2px solid transparent;
      }

      .disabled {
        opacity: 0.4;
        width: 22px;
        height: 22px;
      }
    }

    .pagination-skeleton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      @media (max-width: 675px) {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
      }

      div {
        width: 26px;
        height: 26px;
      }

      .skeleton {
        background-color: #5a5a5a;
        height: 27px !important;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(8%, rgba(130, 130, 130, 0.2)),
          color-stop(18%, rgba(130, 130, 130, 0.3)),
          color-stop(33%, rgba(130, 130, 130, 0.2))
        );
        background: linear-gradient(
          to right,
          rgba(130, 130, 130, 0.2) 8%,
          rgba(130, 130, 130, 0.3) 18%,
          rgba(130, 130, 130, 0.2) 33%
        );
        background-size: 800px 100px;
        animation: wave-lines 2s infinite ease-out;
      }

      @keyframes wave-lines {
        0% {
          background-position: -468px 0;
        }
        100% {
          background-position: 468px 0;
        }
      }
    }
  }
}

.modalBackdrop {
  background-color: #000000b3;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 999;
}

.activity-modal {
  background-color: #000000b3;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
}

.activity-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1036px;
  padding: 0 12px;
  margin: 69px auto 0px;
  z-index: 99999;
  height: auto;
  max-height: 78vh;
  overflow: hidden;

  @media (max-width: 1036px) {
    max-width: 1006px;
  }

  @include media-breakpoint(lg) {
    margin-top: 62px;
  }

  @include media-breakpoint(sm) {
    margin-top: 48px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .modal-heading {
    @include secHeading(0px, 16px);
    font-size: 20px;
    color: white;
    text-align: left;
    line-height: 27.24px;
    padding-left: 48px;

    @media (max-width: 1036px) {
      padding-left: 33px;
    }
  }

  .activity-table {
    border-radius: 0px 0px 8px 8px;
    overflow: auto;
    // overflow: unset;
    position: relative;
    height: auto;
    max-height: 68vh;

    .class-row-wrapper {
      width: 1002px;
      padding-left: 15px;

      @media (max-width: 1036px) {
        width: 100%;
        padding-left: 0;
      }
    }

      &:hover{
        position: relative;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.22);
        z-index: 1;
      }

    &::-webkit-scrollbar {
      display: none;
    }

    .select-class-row {
      // border-bottom: 1px solid $color-gray-800;
      @include flex-box(space-between, flex-start);
      padding: 10px 17px 10px 33px;
      width: 982px;
      height: 82px;
      overflow: hidden;
      background-color: $color-gray-500;

      &:last-child {
        border-radius: 0px 0px $border-radius-md $border-radius-md
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 1px;
        width: 100%;
        margin: 0 auto;
        background-color: $color-gray;
        transition: 0.2s all;
      }

      .subHeader-fieldLabel {
        font-size: $sm;
        font-weight: 700;
        line-height: 19.1px;
        color: $color-gray-900;
        margin-bottom: 0;
        text-align: left;
      }

      .classRoom {
        width: 125px;
        height: 60px;

        .subHeader-fieldLabel {
          height: 20.6px;
        }

        .class_name {
          font-size: $md;
          font-weight: 400;
          line-height: 21.82px;
          color: $color-gray-950;
          margin-bottom: 0;
          @include ellipsis;
          text-align: left;
          margin-top: 11px;
        }
      }

      .hour-column {
        width: 55px;
        text-align: center;
        height: 57px;

        .subHeader-fieldLabel {
          font-size: $sm - 1;
          text-align: center;
        }

        .chip {
          background-color: #cbcbcb;
          padding: 8px 9.5px 7px 9.5px;
          font-size: $sm;
          font-weight: 400;
          line-height: 12px;
          color: $color-gray-950;
          margin-bottom: 0;
          border-radius: $border-radius-md;
          display: inline-block;
          text-align: center;
          margin-top: 13px;
        }
      }

      .last-assessment {
        width: 130px;
        height: 60px;
        padding-left: 18px;

        .lastAssessment-date {
          font-size: $md;
          font-weight: 400;
          line-height: 21.82px;
          color: $color-gray-900;
          margin-bottom: 0;
          margin-top: 11px;
        }
      }

      .statusBarContainer {
        @include flex-box(space-between, center);
        gap: 10px 20px;

        .subHeader-fieldLabel {
          text-align: center;
          font-size: $sm;
          font-weight: 700;
          line-height: 19.1px;
          color: $color-gray-900;
          margin-bottom: 0;
        }

        .status {
          display: block;
          margin: 0;
          width: 98px;
        }

        .progressBlock {
          padding-top: 3px;
        }
      }
    }

    .selectClassDisabledRow {
      cursor: unset;

      .classRoom, .hour-column, .last-assessment {
        opacity: 0.4;
      }

      .statusBarContainer {

        .status  {
          opacity: 0.4;
          
          .progressBlock {

            .no-data-progress-skeleton {

              div {
                opacity: 0.6;
              }

              p {
                opacity: 0.6;
              }
            }
          }
        }
      }

      &:hover {
        width: 982px;
        background-color: $color-gray-500;
        right: 0;

        &::before {
          background-color: $color-gray;
        }

        .classRoom {

          .subHeader-fieldLabel {
            color: $color-gray-900;
          }

          .class_name {
            color: $color-gray-950;
          }
        }

        .hour-column {
        }

        .last-assessment {

          .lastAssessment-date {
            color: $color-gray-900;
          }
        }

        .statusBarContainer {

          .status  {
            
            .progressBlock {

              p {
                color: $color-gray-950;
              }
            }
          }
        }

        .subHeader-fieldLabel {
          color: $color-gray-900 !important;
        }
      }
    }
  }

  .accordion-classroom-animation{
    width: 100%;
    position: relative;
    right: 0%;
    transition: 0.2s all;
    align-items: flex-start;

    @media(hover: hover) {
      &:hover {
          width: 101%;
          right: 0.5%;
          z-index: 9999;
          background-color: $theme-bg-color-light;
          border-bottom: none;

          .classRoom{
            .subHeader-fieldLabel{
              color: white;
            }
            .class_name{
              color: white;
            }
          }
          .hour-column{
            .subHeader-fieldLabel{
              color: white;
            }
            // .chip{
            //   color: white;
            // }
          }

          .status {
            .subHeader-fieldLabel{
              color: white;
            }
          }

          .last-assessment {
            .lastAssessment-date{
              color: white;
            }
          }
          .progressBlock{
            p{
              color: white;
            }
          }
      }
      &:last-child {
        border-bottom: 0px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .classRoom{
      .class_name{
        margin-top: 10.62px;
      }
    }

    .hour-column{
      .subHeader-fieldLabel{
        height: 14px;
      }

      .chip{
        margin-top:13px;
        padding-bottom: 6px;
      }
    }
    .statusBarContainer{
      .status{
        .subHeader-fieldLabel{
          height: 20.6px;
        }
        .progressBlock{
          padding-top: 0px;
          p{
            margin-top: 2px;
          }
          .no-data-progress-skeleton{
            p{
              margin-top: 4px !important;
            }
            div{
              opacity: 1;
            }
          }

          // .no-data-progress-skeleton {
          //   opacity: 0.4;
          // }
        }
      }
    }
    @media(hover: hover) {
      &:hover {
          width: 1002px;
          right: 10px;
          z-index: 9999;
          background-color: $theme-bg-color-light;
          border-bottom: none;
          &::before {
            background-color: $theme-bg-color-light;
          }
          .classRoom{
            .subHeader-fieldLabel{
              color: white;
            }
            .class_name{
              color: white;
            }
          }
          .hour-column{
            .subHeader-fieldLabel{
              color: white;
            }
            // .chip{
            //   color: white;
            // }
          }
          .subHeader-fieldLabel{
            color: white;
          }
          .lastAssessment-date{
            color: white;
          }
          .progressBlock{
            p{
              color: white;
            }
          }
      }
      &:last-child {
        border-bottom: 0px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.formative-modal-content {
  width: 100%;
  max-width: 1240px !important;
  padding: unset;

  @media (max-width: 1260px) {
    max-width: 98% !important;
  }

  .formative-assessment-sec {
    margin-top: 0;

    span.accordion-header {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .accordion-container {
      border-radius: 10px;
      cursor: unset;

      table {
        width: 100%;
        min-width: 1238px;
      }

      &::before {
        display: none;
      }

      &:hover {
        transform: unset;
        box-shadow: unset;
        z-index: unset;

        table {

          .accordion-header {

            th {

              .accordion-header {

                .not-sch-dark {
                  background-color: #424242 !important;

                  svg {

                      path {

                          stroke: white;
                      }
                  }

                  p {
                      color: white !important;
                  }
                }
              }
            }
          }
          
          .read-caption {

            .descWraper {
              font-size: 16px;
              
              span {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.delete-tool-modal {

  .modal-content {
    width: 100%;
    max-width: 560px;

    .modal__footer {
      flex-wrap: wrap;
    }
  }
}

// .container-xl{
//   .header{
//     .header-container{
//       .subHeader-container  {
//         overflow: visible;
//         .activity-modal-container{
//           .activity-table{
//               overflow: unset;
//               position: relative;
//               &:hover{
//                 position: relative;
//                 box-shadow: 0 3px 12px rgba(0, 0, 0, 0.22);
//                 z-index: 1;
//               }
//             .accordion-classroom-animation{
//               width: 100%;
//               position: relative;
//               right: 0%;
//               transition: 0.2s all;
//               @media(hover: hover) {
//                 &:hover {
//                     width: 101%;
//                     right: 0.5%;
//                     z-index: 9999;
//                     background-color: $theme-bg-color-light;
//                     border-bottom: none;
//                     .classRoom{
//                       .subHeader-fieldLabel{
//                         color: white;
//                       }
//                       .class_name{
//                         color: white;
//                       }
//                     }
//                     .hour-column{
//                       .subHeader-fieldLabel{
//                         color: white;
//                       }
//                       .chip{
//                         color: white;
//                       }
//                     }
//                     .subHeader-fieldLabel{
//                       color: white;
//                     }
//                     .lastAssessment-date{
//                       color: white;
//                     }
//                     .progressBlock{
//                       p{
//                         color: white;
//                       }
//                     }
//                 }
//                 &:last-child {
//                   border-bottom: 0px;
//                   border-bottom-left-radius: 8px;
//                   border-bottom-right-radius: 8px;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
