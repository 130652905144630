.cms-breadcrumb{
    width: 100%;
    height: 60px;
    background: $color-blue-100;

    &-text{
        display: flex;
        align-items: center;
        max-width: 1460px;
        height: 100%;
        margin: 0 auto;
        padding: 0 26px;
    }

    &-paragraph{
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }
}