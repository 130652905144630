.MasteryVideos {
    background-color: unset;
    border-radius: 9px;
    margin-top: 18px;
 
    .masteryHeader {
        border-radius: 8px 8px 0px 0px;
    }

    &__header {
        background-color: unset;
        border-radius: 8px 8px 0px 0px;
        padding: 0 25px 0 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        @media (max-width: 575px) {
            height: auto;
            padding: 12px 20px;
            display: block;
        }


        h2 {
            font-size: 20px;
            line-height: 20px;
            font-family: $font-openSans;

            @media (max-width: 575px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
    .masteryHeader {
        background-color: unset;
        
        @media (max-width : 768px) {
            flex-wrap: wrap;
        }
    }

    &__padding {
        padding: 0px 22px 16px 22px;

        @include media-breakpoint(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__videoSec {
        padding: 15.5px 0px 33px 0px;
    }

    &__videoItemContainer {
        width: 100%;
        max-width: 857px;
        margin: 0 auto;
        // -webkit-box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
        // box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
        border-radius: 10px;
        position: relative;
        // background-color: $color-blue-100;
    }

    &__itemHeader {
        background-color: #3e3e3e;
        border-radius: 8px 8px 0px 0px;
        padding: 10px 21px 8px;

        p {
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            margin-bottom: 0;
        }
    }

    &__videoItems {
        padding: 0px 0px 0px;
        display: flex;
        justify-content: center;
        // background-color: $color-blue-100;
        border-radius: 10px;
        text-align: center;
        position: relative;
        overflow: hidden;

        @media (max-width : 640px) {
            padding: 20px;
        }
        @media (max-width : 575px) {
            padding: 0px;
        }
        .videoImg {
            width: 500px;
            height: 100%;
        }

        .videoTitle {
            width: 100%;
            position: absolute;
            top: 44px;
            left: 0;
            right: 0;
        }

        .playBigBtn {
            position: relative;
            top: 30px;
            right: 30px;
        }

        
    }

    .image-container {
        position: relative;/* Or whatever your image height is */
        right: 30px;
        top: 30px;

        svg {
            margin-top: -36px;
            margin-left: 14px;
        }
    }

    .image2 {
        position: unset;
        width: 69px;
        height: 79.3px;
        opacity: 1;
        transition: all 0.5s; /* Feel free to adjust the duration */
    }
    .image-container:hover .image2 {
        width: 87px;
        height: 100px;

    }



    &__discription {
        // box-shadow: 0 3px 12px rgb(0 0 0 / 22%);


        &__text {
            background-color: $color-gray-500;
            padding: 12px 15px 13px 25px;
            border-radius: $border-radius-md;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: white;
            margin-top: 12px;

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: $color-gray-900 !important;
                margin-bottom: 0px;
            }
        }


        h2 {
            font-size: 20px;
            font-weight: 700;
            line-height: 20px;
            color: white;
            font-family: $font-openSans;
        }
    }

    &__comment {
        margin-top: 36px;

        .limit-selects{
            width: 53px;
            .limit-selects__wrapper{
                .limit-selects__control{
                    .limit-selects__value-container{
                        padding-left: 6px !important;
                    }
                    .limit-selects__indicators {
                        .limit-selects__indicator{
                            padding-right: 9px !important;
                        }
                    }
                }
            }
        }

        .section-search {
            padding: 0%;
            width: 45%;
            z-index: 99999;
            position: relative;
            @media (max-width : 1200px) {
                width: 55%;
            }
            @media (max-width : 980px) {
                width: 65%;
            }
            @media (max-width : 820px) {
                width: 70%;
            }
            @media (max-width : 820px) {
                width: 80%;
            }
            @media (max-width : 768px) {
                width: 100%;
                justify-content: flex-end;
            }
            @media (max-width : 575px) {
                flex-direction: column;
                margin: 10px 0px;
            }
            .section-search-field {
                @media (max-width : 575px) {
                    margin: 10px 0px !important;
                }
             .form-control {
                @media (max-width : 575px) {
                    width: 100%;
                    max-width: 100%;
                    margin-right: 0px;
                }
            }
        }  
    }

    &__button {
        border: 1px solid white;
        border-radius: $border-radius-md;
        height: 33px;
        width: 143px;

        @media (max-width : 575px) {
            width: 100%;
            margin-top: 10px;
        }
    }

    &__container {
        border-radius: 0px 0px 8px 8px;
        overflow: hidden;
        margin-top: 10px;

        @include media-breakpoint(sm) {
            overflow: auto;
        }

        &__table {
            min-width: 420px;
            
            tr:nth-child(odd) {
                
                td {
                    background-color: $theme-bg-color-darker;
                }

                .emptySpace {
                    background-color: #537AB7
                }
            }

            tr {
                td {
                    p {
                        margin-bottom: 0px;
                    }
                }

                td:first-child {
                    border-radius: 40px 0 0 40px;
                }

                td:last-child {
                    border-radius: 0 40px 40px 0;
                }
            }

            &__name {
                padding: 9px 8px 9px 15px;
                vertical-align: top;
                width: 130px;
                min-width: 130px;

                @media (max-width: 575px) {
                    padding: 12px 20px;
                }

                .initials {
                    height: 42px;
                    border-radius: $border-radius-md;
                    width: 46px;
                    background-color: #C44538;

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        color: white;
                    }
                }

                p {
                    font-size: 24px;

                }

                .replayTd {
                    background-color : transparent !important;
                    margin-right : 15px ;

                    .emptySpace {
                        height : 20px ;
                        width : 20px ;
                        background-color : #496faa ;
                        opacity:0.5 ; 
                        border-radius:50%;   
                    }
                }
            }

            &__info {
                width: 95%;
                padding: 9px 15px 9px 7px;

                @media (max-width: 575px) {
                    padding: 12px;
                }

                .basic-info-color {
                    color: #D1D1D1;
                }

                .basic-info {
                    font-size: 14px;
                    line-height: 19px;
                }

                .comment {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: white;
                    word-break: break-all;
                    white-space: pre-wrap;

                }
            }
        }
    }
}
    .table-dropdown-container {
        position: absolute;
        left: 50%;
        top: 14px;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 440px;

        @media (max-width : 940px) {
            left: 70%;
        }
        @media (max-width : 768px) {
            top: 2px;
            position: relative;
            left: 0%;
            transform: none;
            width: 100%;
            justify-content: flex-end;
        }

        @media (max-width: 767px) {
            width: 55%;
        }

        @media (max-width: 575px) {
            max-width: 100%;
            width: 100%;
            margin-top: 12px;
        }

        .text-btn {
                padding: 0;
                img {
                    width: 15px;
                    height: 21px;
                }
            }
        .text-btn:disabled {
            opacity: 0.27;
        } 

        .table-dropdown {
                width: 330px;
                padding: 0 11px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;

                @media (max-width: 767px) {
                    width: 250px;
                }

                @media (max-width: 575px) {
                    width: 100%;
                }
                
            button {
                    width: 100%;
                    line-height: 21px;
                    padding: 5px 10px;
                }

                select {
                    width: 100%;
                    height: 32px;
                    border: 1px solid #4788FF;
                    border-radius: $border-radius-md;
                    padding: 4.5px 14px 5.5px 11.5px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #4788FF;
                    background-color: #fff;
                }

                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                }

                img {
                    position: absolute;
                    top: 14px;
                    right: 30px;
                }

            .table-dropdown-menu {
                width: 320px;

                .table-dropdown-item {
                    font-size: 14px;
                    color: #4788ff;
                    font-weight: 400;
                }
            }
        }
    }
}
.comment-text {
    display: inline-block;
    width: auto;
    position: relative;
    top: 10px;
    left: 23px;
    border-top-left-radius: 0 !important;
    background-color: #373C44 !important;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    z-index: 1;
    padding: 0 20px !important;
    border-radius: 40px !important;
    line-height: 18px;
}

.text-area {
    font-size: 18px;
    color: #6E6E6E;
    border-radius: $border-radius-md;
}

.text-area2 {
    font-size: 18px !important;
    color: #6E6E6E;
    border-radius: $border-radius-md;
    height: 132px !important;
    textarea {
        background-color: white !important;
        font-size: 18px !important;
        color: #6e6e6e !important;
        border-radius: $border-radius-md !important;
        height: 132px !important;
        min-height: 132px !important;
        border: 0px !important;
        padding: 16px 16px 16px 23px!important;
        font-family: 'Nunito', sans-serif !important;
    }

    strong {
        font-size: 18px;
        font-family: 'Nunito', sans-serif !important;
        font-weight: 400 !important;
        top: 6px !important;
        left: 8px !important;
    }

    textarea {
        overflow: auto !important;
    }

    textarea:focus {
        outline: none !important;
    }

    ul {
        height: auto !important;
        max-height: 210px !important;
        overflow: auto !important;
        background-color: rgba(34,39,47,.85) !important;
        backdrop-filter: blur(2px);
        border-radius: $border-radius-md !important;
        box-shadow: 0 3px 12px rgba(0,0,0,.22) !important;
        bottom: 13px !important;
        position: absolute !important;
        width: 350px !important;
        border: 0 !important;
        left: 0px !important;
        z-index: 99999999 !important;

        li {
            background-color: unset !important;
            cursor: pointer !important;
            padding: 8px 15px !important;
            color: #b9b9b9 !important;
        }

        li:hover {
            background-color: #496faa !important;
            color: white !important;
            transition: all .3s !important;
        }
    }

    ul::-webkit-scrollbar {
        display: none;
    }

    .textarea-child__control {
        height: 132px;

        .textarea-child__highlighter {
            height: 132px;
        }
    }
}

.text-area2::-webkit-scrollbar {
    display: none;
}

.comment_with_replies{
    position: relative !important;
    left: -59px !important;
    
    // @media (max-width: 575px) {
    //     left: -75px !important;
    // }
}

.commentReply {
    position: relative;
    left: -24px;
    
    // @media (max-width: 575px) {
    //     left: -40px !important;
    // }
}

.react-player__preview {
    // background-size: contain !important;
    // background-position: top !important;
    background-position-x: center !important;
    // background-position-y: 5px !important;
    background-repeat: no-repeat !important;
}
        
.mastery-commentSec-header {

    .icon {

        img {
            opacity: 0.4;
        }
    }

    input {
        opacity: 0.39;
    }

    .show-select {
        opacity: 0.39;
    }

    .limit-selects {
        opacity: 0.39;
        
    }
}

.mastery-video-noComments {
    background-color: #537AB7;
    border-radius: 40px !important;

    .notfound__data-container {
        height: 60px;
        justify-content: flex-start;

        .messageText {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: white;
            text-shadow: none;
            width: 90%;
            text-align: center;
            padding-left: 30px;

            span {
                font-weight: 700;
            }
        }
    }

    .newComment {
        width: 46px;
        height: 42px;
        background-color: $theme-bg-color-lighter;
        border-radius: $border-radius-md;
        position: relative;
        left: 16px;
        margin-right: 16px;
    }
}
