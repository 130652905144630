.summative-objective-analysis {
  color: $color-white;
  @include section;
  margin-top: 22px;

  @media print {
    color: $color-black;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
  }

  .ScaleContainerHeight {
    .schoolFirst-center {
      top: 0 !important;
      height: 100% !important;
    }
    .schoolFirst-top {
      margin-top: -30px !important;
    }
  }
  .scale-border-right {
    border-right: 1px solid $color-blue-800;
  }
  .show {
    z-index: 9999999 !important;
  }

  &__nearly-proficient {
    margin-right: 7px;
  }

  &__header-center {
    width: 613px;

    @include media-breakpoint(xl) {
      width: auto;
      margin-left: auto;
    }

    @include media-breakpoint(md) {
      order: 3;
      margin-top: 10px;
    }

    .dropdown {
      margin-left: 22px;

      @include media-breakpoint(xl) {
        margin-left: 0;
        margin-right: 16px !important;
      }

      @include media-breakpoint(md) {
        margin-left: 10px;
        margin-right: 0 !important;
      }
    }
  }

  @media print {
    box-shadow: none;
  }

  &__info-wrapper {
    position: relative;
  }

  &__objective {
    width: 85px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint(xl) {
      position: sticky;
      left: 96px;
      background-color: $color-gray-500;
    }

    @media print {
      height: unset;
    }

    &.dark-bg {
      @include media-breakpoint(xl) {
        background-color: $color-gray;
      }
    }

    @media print {
      @include mozilla-suport {
        width: 50px;
        height: 17px;
      }
    }

    .objective-width {
      display: inline-block;
      min-width: 80px;
      max-width: 85px;
      height: 22px;
      border: 1px solid transparent;
      border-radius: 40px;
      text-align: center;
      @include ellipsis;
      font-size: 16px;
      line-height: 20px;
      padding: 0;

      span {
        display: block;

        span {
          padding: 0 3px;
        }
      }

      &.high {
        background-color: $color-yellow-FFA500;
        box-shadow: 2px 1px 1px rgba($color-black, 0.22);

        @media print {
          box-shadow: unset;
          background-color: transparent;
          border: 1px dashed $color-yellow-FFA500;
        }
      }

      &.critical {
        background-color: $color-red-200;
        color: $color-white;
        box-shadow: 2px 1px 1px rgba($color-black, 0.22);

        @media print {
          box-shadow: unset;
          background-color: transparent;
          color: $color-black;
          border: 1px solid $color-red-200;
        }
      }

      @include safari-suport {
        line-height: 19px;
      }

      @media print {
        @include mozilla-suport {
          min-width: 50px;
          max-width: 50px;
          height: 14px;
          font-size: 8px;
          line-height: 1.5;
          padding: 0 2px;
        }
      }

      .annimation {
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        transition: 0.5s;
        -webkit-transition-timing-function: linear;
        -moz-transition-timing-function: linear;
        transition-timing-function: linear;
        position: relative;
        left: 0%;
        width: 67px;

        &:hover {
          left: -25px;
        }
      }
    }
  }

  &__no-question {
    min-width: 83px;
    text-align: center;
    position: relative;
    left: 6px;

    @include media-breakpoint(xl) {
      position: sticky;
      left: 181px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 37px;
      background-color: $color-gray-500;
    }

    &.dark-bg {
      @include media-breakpoint(xl) {
        background-color: $color-gray;
      }
    }

    @media print {
      @include mozilla-suport {
        left: 0;
        min-width: 50px;
        font-size: 10px;
        line-height: 1.5;
      }
    }
  }

  &__class-scale {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
    margin-bottom: 39px;

    .text {
      border-left: 1px solid $color-blue-800;
      border-right: 1px solid $color-blue-800;
      height: 16px;
    }

    p {
      width: 100%;
      text-align: center;
      border-top: 1px solid $color-blue-800;
      line-height: 0.1em;
      color: $color-blue-800;
      font-size: 12px;
      font-weight: 600;
      position: relative;
    }

    p span {
      background-color: $color-gray-500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 55px;
      min-width: 20px;
      width: 100%;
      display: block;
      padding-top: 15px;
      height: 27px;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: -16px;
    }
  }

  &__scale-width {
    padding-right: 3px;

    @media (min-width: 767px) and (max-width: 1242px) {
      width: 1242px;
    }

    @media (min-width: 767px) and (max-width: 990px) {
      top: 33px;
      justify-content: unset !important;
      position: relative;
      left: 282px;
    }
  }

  &__Oas-objective {
    display: flex;
    justify-content: center;
    margin: 8px 0;

    p {
      color: $color-black;
      margin-bottom: 0;
      font-weight: bold;

      @media print {
        @include mozilla-suport {
          font-size: 10px;
          line-height: 1.5;
        }
      }
    }
  }

  &__printHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #2e589c;
    height: 48px;
  }

  &__bold {
    font-weight: bold;
  }

  &__color6A6A6A {
    color: $color-gray-850;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-900;
    padding: 5px 32px 6px 15px;

    @include media-breakpoint(xl) {
      padding-right: 18px;
    }

    &-link {
      @include flexcenter-both;
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: $color-blue-600;
      }
    }

    &-icon {
      max-width: 45px;
      margin-right: 1px;
      transition: all 0.3s;
      transform: rotate(-90deg);
    }

    &-title {
      font-family: $font-openSans;
      font-size: 22px;
      font-weight: 700;
      line-height: 29px;
      color: $color-white;
    }

    &-text {
      font-family: $font-openSans;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 0;
    }

    &-guide {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0;

      @include media-breakpoint(xl) {
        position: relative;
        left: auto;
        top: auto;
        transform: translate(0, 0);
      }

      @include media-breakpoint(lg) {
        display: none;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
    }
  }

  &-content {
    background-color: $color-gray-500;
    border-radius: $border-radius-md;

    @media print {
      background-color: transparent;
    }
  }

  &__performance {
    position: relative;
    display: flex;
    padding: 18px 20px 18px 55px;

    @media print {
      flex-direction: row !important;

      @include mozilla-suport {
        padding: 8px 20px 8px 55px;
      }
    }

    .info-content {
      width: 100%;
      max-width: 340px;
      margin-top: 30px;

      @include media-breakpoint(xl) {
        margin-top: 0;
      }

      @media print {
        @include mozilla-suport {
          max-width: 200px !important;
          margin-top: 0px;
        }
      }

      p {
        color: $color-gray-850;

        @media print {
          color: $color-gray-850;

          @include mozilla-suport {
            font-size: 10px;
            line-height: 1.5;
          }
        }
      }

      &__isolate {
        font-weight: bold;

        @include media-breakpoint(md) {
          text-align: center;
        }

        @media print {
          display: none;
        }
      }
    }

    &-print {
      position: relative;
      display: flex;
      padding: 18px 20px 18px 55px;

      .info-content {
        width: 340px;
        margin-top: 19px;
      }
    }

    &__description-content {
      background-color: $color-white-C7C7C7;
      border: 1px solid $color-gray-750;
      height: 72px;
      width: 100%;
      max-width: 246px;
      position: relative;
      top: 107px;
      left: 14px;
      padding: 10px 15px;
      padding-bottom: 13px;

      @include media-breakpoint(xl) {
        top: 10px;
      }

      @include media-breakpoint(md) {
        margin-bottom: 90px;
      }

      @media print {
        top: 56px;
        background-color: transparent;
        border: none;

        @include mozilla-suport {
          top: 54px;
          height: 50px;
          padding: 5px 10px;
          font-size: 10px;
          line-height: 1.5;
        }
      }

      img {
        @media print {
          @include mozilla-suport {
            max-width: 16px;
            height: auto;
          }
        }
      }

      div:last-child {
        img {
          margin-right: 4px;
        }
      }

      span {
        color: $color-black;
        position: relative;
        top: 1px;
      }
    }

    @include media-breakpoint(xl) {
      flex-direction: column;
      align-items: center;
      padding: 16px 15px;
    }

    &-performance {
      background-color: $color-white-C7C7C7;
      width: 389px;
      border: 1px solid $color-gray-750;
      padding: 7px 19px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 25px;

      @include media-breakpoint(xl) {
        margin-left: 0;
      }

      @include media-breakpoint(md) {
        max-width: 360px;
        width: 100%;
      }

      @media print {
        border: none;
        background-color: transparent;
        padding-bottom: 0;
      }
    }

    &-average {
      background-color: $color-white-C7C7C7;
      width: 494px;
      border: 1px solid $color-gray-750;
      padding: 12px 10px 12px 30px;
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
      color: $color-black;
      line-height: 20px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @media print {
        @include mozilla-suport {
          font-size: 10px;
          line-height: 1.5;
        }
      }
    }

    &-percentage {
      max-width: 320px;
      margin: 8px auto 10px auto;

      @include media-breakpoint(md) {
        max-width: 300px;
      }

      @media print {
        max-width: 340px;

        @include mozilla-suport {
          width: 210px;
        }
      }
      div:nth-child(1) {
        @media print {
          p {
            margin-top: 3px;
          }
        }
      }
      div:nth-child(2) {
        p {
          margin-top: 4px !important;
        }
      }

      div:nth-child(4) {
        p {
          margin-top: 4px;
        }
      }

      div:nth-child(5) {
        p {
          margin-top: 4px;
        }
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 22px;

      p {
        font-size: 16px;
        color: $color-black;
        line-height: 20px;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }

        @media print {
          margin-top: 4px;

          @include mozilla-suport {
            font-size: 10px;
            line-height: 1.5;
          }
        }
      }
    }

    &-level {
      margin-right: 7px;
      width: 16px;
      height: 16px;
      box-shadow: 2px 2px 1px rgba($color-black, 0.22);

      @media print {
        width: 18px;
        height: 18px;
        box-shadow: unset;

        @include mozilla-suport {
          width: 12px;
          height: 12px;
          transform: translateY(2px);
        }
      }

      &.proficient {
        background-color: $color-green-600;

        @media print {
          background-color: transparent;
          border: 1px dashed $color-green-600;
          color: $color-black;
        }
      }

      &.non-proficient {
        background-color: $color-yellow-200;
        color: $color-black;

        @media print {
          background-color: transparent;
          border: 1px solid $color-yellow-200;
          color: $color-black;
        }
      }

      &.high {
        background-color: $color-yellow-FFA500;

        @media print {
          background-color: transparent;
          border: 1px dashed $color-yellow-FFA500;
          color: $color-black;
        }
      }

      &.critical {
        background-color: $color-red-200;

        @media print {
          background-color: transparent;
          border: 1px solid $color-red-200;
          color: $color-black;
        }
      }

      &.rounded {
        border-radius: 100% !important;
        font-size: 14px !important;
      }
    }

    &-avg {
      width: 16px;
      height: 16px;

      &.averagep {
        background-color: $color-blue-100;
        margin-left: 5px;
        margin-right: 10px;
      }

      &.averageo {
        margin-right: 17px;
      }
    }

    &-paragraph {
      font-size: 14px;
      color: $color-black;
      line-height: 20px;
      margin-bottom: 6px;
    }

    &-indicator {
      display: flex;
      align-items: center;
      max-width: 281px;
      padding-left: 5px;
      margin: 0 auto;
    }

    &-circle {
      @include flexcenter-both;
      min-width: 28px;
      height: 28px;
      background-color: $color-blue-800;
      border-radius: 50%;
      font-family: $font-cabin;
      font-size: 14px;
      line-height: 20px;
      margin-right: 7px;
    }

    &-indicatorText {
      font-size: 14px;
      color: $color-black;
      line-height: 20px;
      margin-bottom: 0;
    }

    &-objective {
      position: absolute;
      top: 17px;
      right: 32px;
      display: flex;
      align-items: center;

      @include media-breakpoint(xl) {
        order: -1;
        position: relative;
        top: auto;
        right: auto;
        margin-bottom: 16px;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: $color-brown;
        margin: 0 10px 0 8px;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }
    }

    &-rectangle {
      @include flexcenter-both;
      background-color: $color-green-600;
      width: 28px;
      height: 28px;
      font-family: $font-cabin;
      font-size: 14px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__search {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 4px 15px;

    @media only screen and (max-width: 866px) {
      flex-direction: column;
      align-items: center;
    }

    &-searcharea {
      position: absolute;
      left: 26px;
      display: flex;
      align-items: center;
      padding-top: 12px;

      @include media-breakpoint(xl) {
        padding-top: 4px;
        left: 15px;
      }

      @media only screen and (max-width: 866px) {
        order: 2;
        position: relative;
        top: unset;
        left: unset;
        padding-top: 0;
        margin-top: 12px;
      }
    }

    &-icon {
      margin-right: 10px;
    }

    &-input {
      width: 223px;
      background-color: $color-white;

      &.margin-left {
        margin-left: 27px;
      }
    }
  }

  &__data {
    width: 100%;
    padding-bottom: 20px;
    overflow-x: auto;

    @media Print {
      overflow-x: hidden;
    }

    @include media-breakpoint(xl) {
      max-height: 944px;
      padding-bottom: 0;
      overflow: auto;
      scrollbar-width: 0;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__question-img {
      position: relative;
      top: 60px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 58px;
      z-index: 9999;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -70px;
        height: 70px;
        width: 58px;
        background-color: $color-gray-500;
      }

      @include media-breakpoint(xl) {
        position: sticky;
        top: unset;
        left: 0;
        transform: translateY(67px);
        padding-bottom: 7px;
        background-color: $color-gray-500;
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      width: 1242px;
      margin-top: 25px;
      position: relative;
      top: -50px;
      padding-right: 10px;

      @include media-breakpoint(xl) {
        position: sticky;
        top: 50px;
        background-color: $color-gray-500;
        transform: translateY(-50px);
        padding-right: 0;
        z-index: 2;
      }

      @include mozilla-suport {
        padding-right: 14px;
      }

      @media print {
        top: 0;
        margin-top: 0;

        @include mozilla-suport {
          padding: 0;
          margin-left: 30px;
          width: unset;
        }
      }
    }

    &-objective {
      align-self: flex-end;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @include media-breakpoint(xl) {
        position: sticky;
        left: 58px;
        background-color: $color-gray-500;
        height: 92px;
        z-index: 2;

        &::before {
          content: "";
          position: absolute;
          left: -58px;
          top: -2px;
          width: 58px;
          height: 94px;
          background-color: $color-gray-500;
        }
      }

      @media print {
        @include mozilla-suport {
          width: 124px;
          padding-left: 24px;
          justify-content: flex-end;
        }
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 21px;
        font-size: 12px;
        color: $color-black;
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 0;

        @media print {
          @include mozilla-suport {
            font-size: 8px;
            line-height: 1.5;
          }
        }
      }

      .objective-title {
        width: 130px;
        text-align: center;
        position: relative;
        left: 26px;

        @media (min-width: 767px) and (max-width: 1100px) {
          width: 102px;
          left: 8px;
        }

        @media print {
          @include mozilla-suport {
            width: 50px !important;
            left: 0 !important;
          }
        }

        p {
          padding-left: 20px;

          @media print {
            @include mozilla-suport {
              padding-left: 4px;
            }
          }
        }
      }

      .questions-title {
        width: 95px;
        @media print {
          @include mozilla-suport {
            width: 60px;
          }
        }

        p {
          margin-bottom: 0;

          &:last-child {
            padding-left: 25px;

            @media print {
              @include mozilla-suport {
                padding-left: 5px;
              }
            }
          }

          span {
            font-size: 14px;
            padding-left: 3px;
          }
        }
      }
    }

    &-objectiveData {
      &.font-bold {
        font-weight: bold;
      }
    }

    &-icon {
      margin-right: 7px;
      cursor: pointer;
    }

    &-questions {
      display: flex;
      flex-direction: column;
      width: 28px;
      font-size: 14px;
      line-height: 20px;

      @media print {
        @include mozilla-suport {
          width: 22px;
          font-size: 8px;
        }
      }
    }

    &-text {
      position: relative;
      width: 31px;
      height: 50px;
      border-left: 1px solid $color-gray-750;
      text-align: right;
      color: $color-gray-850;
      padding: 2px 0;
      transform: rotate(225deg) translate(-14px, 17px);
      transition: all 1s ease;
      white-space: nowrap;
      text-overflow: ellipsis;
      top: 0px;
      left: -3px;

      @include mozilla-suport {
        transform: rotate(225deg) translate(-13.5px, 17.5px);
      }

      @media print {
        transform: rotate(225deg) translate(-14px, 17px);

        @include mozilla-suport {
          width: 24px;
          transform: rotate(225deg) translate(-15px, 16px) !important;
        }
      }

      &:hover {
        color: darken($color-gray-850, 15%);
        border-color: darken($color-gray-750, 15%);
      }

      .border-span {
        writing-mode: vertical-rl;
        transform: translateX(-10px) translateY(-3px);
        padding: 0px 0px 2px 0px;
        font-size: 13px;
        overflow: hidden;
        height: 60px;

        @media print {
          transform: translateX(-12px) translateY(-3px) !important;

          @include mozilla-suport {
            font-size: 9px;
            transform: translateX(-5px) translateY(-3px) !important;
          }
        }

        .animation-text {
          display: inline-block;
          height: 70px;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      &.font-bold {
        font-weight: bold;
      }
    }

    &-count {
      display: inline-block;
      height: 42px;
      text-align: center;
      font-family: $font-cabin;
      color: $color-brown;
      border-right: 1px solid $color-gray-750;

      svg {
        color: $color-gray-850;
      }

      .star {
        position: relative;
        top: -2px;
        left: 1px;

        @media print {
          @include mozilla-suport {
            max-width: 16px;
            height: auto;
            margin-left: 0;
            transform: translateX(-1px);
          }
        }
      }
    }

    &-benchmark {
      align-self: flex-end;
      min-width: 100px;
      width: 128px;
      padding-bottom: 2px;
      text-align: center;
      position: relative;
      left: 11px;

      @media print {
        @include mozilla-suport {
          left: 0;
          min-width: 80px;
          width: 80px;
        }
      }
    }

    &-benchmarkTitle {
      font-size: 12px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;

      @media print {
        @include mozilla-suport {
          font-size: 8px;
          line-height: 1.5;
        }
      }

      img {
        margin-left: 4px;
        cursor: pointer;
      }
    }

    &-score {
      text-align: center;
      font-size: 16px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
    }

    &-summative {
      align-self: flex-end;
      width: 119px;

      @media print {
        @include mozilla-suport {
          width: 60px;
        }
      }
    }

    &-summativeTitle {
      text-align: center;
      font-size: 14px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
      position: relative;
      left: 24px;

      @media print {
        left: 0px;

        @include mozilla-suport {
          font-size: 8px;
          line-height: 1.5;
        }
      }
    }

    &-proficiency {
      align-self: flex-end;
      min-width: 88px;
      text-align: center;
      font-size: 14px;
      color: $color-black;
      line-height: 20px;
      padding-left: 7px;
      position: relative;
      bottom: 4px;

      div {
        height: 37px;
        margin-top: 2px;

        @media print {
          height: unset;
        }
      }
    }
  }

  &-table-container {
    width: 1242px;
    animation: fadeIn 1500ms ease;
    overflow-y: auto;
    display: flex;
    max-height: 555px;
    position: relative;
    top: -50px;

    @include media-breakpoint(xl) {
      max-height: unset;
      overflow-y: visible;
      scrollbar-width: 0;
    }

    @media print {
      top: 0;
      max-height: 920px;
      animation: unset;
      opacity: 1 !important;
      overflow: hidden;
      scrollbar-width: 12px;
    }

    &.PrintPage {
      max-height: 1100px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-black, 0.2);
    }

    &::-webkit-scrollbar {
      width: 12px;

      @include media-breakpoint(xl) {
        display: none;
      }

      @media print {
        display: none;
      }
    }

    &::-webkit-scrollbar-thumb:vertical {
      width: 8px;
      border-radius: 10px;
      background-color: rgba($color-black, 0.23);
      border: 2px solid rgba($color-gray-500, 0.5);
    }

    &::-webkit-scrollbar:horizontal {
      height: 2px;

      @media print {
        height: 0px;
      }
    }

    &::-webkit-scrollbar-thumb:horizontal {
      height: 2px;
      background-color: rgba($color-black, 0.23);
    }

    &__sidebar {
      width: 58px;
      position: relative;

      @include media-breakpoint(xl) {
        position: sticky;
        left: 0;
      }

      @media print {
        @include mozilla-suport {
          width: 30px;
        }
      }
      .printBorderTop {
        background-color: $color-gray-750;
        -webkit-print-color-adjust: exact;
        position: absolute;
        width: 77px;
        height: 1px;

        @media print {
          @include mozilla-suport {
            width: 52px;
          }
        }
      }

      .print-structure {
        overflow: hidden;
        @media print {
          border-right: 0.5px solid $color-gray-750;
          border-bottom: 0.5px solid $color-gray-750;
        }
      }

      .after-line {
        border-top: 0.5px solid $color-gray-750;
        -webkit-print-color-adjust: exact;
        position: relative;
        width: 21px;
        top: -1.7px;
        left: 55px;
        border-left: 0.5px solid $color-gray-750;
        z-index: 9999999;

        @media print {
          @include mozilla-suport {
            top: -0.5px;
            left: 30px;
          }
        }
      }

      .print-structure {
        background-color: $theme-bg-color-dark-variant3;
        display: flex;
        flex-direction: column;

        @media print {
          background-color: transparent;
        }
      }

      .percentage {
        text-align: center;
        width: 58px;
        background-color: $theme-bg-color-lighter-variant3 !important;
        font-size: 14px;
        height: 37px;
        line-height: 37px;

        @media print {
          background-color: transparent !important;
          padding: 0%;
          width: 52px;

          @include mozilla-suport {
            width: 30px;
            height: 24px;
            font-size: 8px;
            line-height: 24px;
          }
        }
      }

      .main-content {
        overflow: hidden;
        display: flex;
        margin-bottom: 8px;
        text-align: center;
        position: relative;
        left: 18px;
        margin-top: 8px;

        @media print {
          margin: 0%;
          margin-top: 0%;
          margin-bottom: 0%;
          @include mozilla-suport {
            left: 7px;
          }
        }

        .content {
          transform: rotate(180deg);
          writing-mode: tb-rl;
          text-orientation: mixed;
          text-align: center;
          height: 100%;
          -webkit-transition-timing-function: linear;
          -moz-transition-timing-function: linear;
          transition-timing-function: linear;
          font-size: 14px;

          @media print {
            font-size: 10px;
          }

          &:hover {
            bottom: -100px;
          }
        }
      }
    }
  }

  &__table {
    display: block;
    width: calc(100% - 58px);
    color: $color-black;
    scrollbar-width: none;

    @media print {
      overflow: visible;

      overflow-x: hidden;

      @include mozilla-suport {
        width: 100%;
      }
    }

    &-summative-table-height {
      padding-bottom: 74px;

      @media print {
        padding-bottom: 0px;
      }

      @media (min-width: 767px) and (max-width: 1225px) {
        padding-bottom: 0;
      }
    }

    .dark-gray {
      background-color: $color-gray;

      @media print {
        background-color: transparent;
      }
    }

    .first-row td {
      padding-top: 1px !important;
    }

    tbody {
      display: block;
      overflow: visible;
    }

    tr {
      display: flex;
      justify-content: center;
      align-items: center;

      @include mozilla-suport {
        width: 1168px;
      }

      @media print {
        padding-right: 12px;

        @include mozilla-suport {
          padding-right: 0;
        }
      }

      td {
        vertical-align: middle;
      }
    }

    .print-body tr {
      @media print {
        @include safari-suport {
          width: 1108px;
        }

        @include mozilla-suport {
          width: 712px;
        }
      }
    }

    &-name {
      min-width: 220px;
      padding: 4.5px;
      text-align: right;
      padding-right: 34px;
      line-height: 28px;
    }

    &-data {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 28px;
      min-width: 28px;
      padding: 4.5px 1.5px;

      @media print {
        @include mozilla-suport {
          max-width: 22px;
          min-width: 22px;
          font-size: 8px;
          padding: 2px 1.5px;
        }
      }
    }

    &-numbers {
      @include flexcenter-both;
      width: 28px;
      height: 28px;
      font-family: $font-cabin;
      font-size: 14px;
      line-height: 20px;
      margin: 0 !important;

      @media print {
        @include mozilla-suport {
          width: 19px;
          height: 19px !important;
          font-size: 8px;
        }
      }

      &.proficient {
        background-color: $color-green-600;
        color: $color-white;

        @media print {
          background-color: transparent;
          border: 1px dashed $color-green-600;
          color: $color-black;
        }
      }

      &.non-proficient {
        background-color: $color-yellow-200;
        color: $color-gray-900;

        @media print {
          background-color: transparent;
          border: 1px solid $color-yellow-200;
          color: $color-black;
        }
      }
    }

    &-benchmark {
      min-width: 111px;

      @media print {
        @include mozilla-suport {
          min-width: 80px;
          width: 80px;
        }
      }
    }

    &-summative {
      min-width: 172px;

      @media print {
        @include mozilla-suport {
          min-width: 60px;
          width: 60px;
        }
      }
    }

    .average-content {
      top: 7px;

      @media print {
        left: -10px;

        @include mozilla-suport {
          left: -8px;
          font-size: 10px;
          line-height: 1.5;
        }
      }
    }

    &-questions {
      position: relative;
      left: 11px;

      @include media-breakpoint(md) {
        position: unset;
        left: unset;
        margin-left: 11px;
      }

      @media print {
        left: 25px;
        @include mozilla-suport {
          left: 0;
        }
      }

      &.high {
        background-color: $color-yellow-FFA500;
        color: $color-gray-900;

        @media print {
          background-color: transparent;
          border: 1px dashed $color-yellow-FFA500;
          color: $color-black;
        }
      }

      &.critical {
        background-color: $color-red-200;
        color: $color-white;

        @media print {
          background-color: transparent;
          border: 1px solid $color-red-200;
          color: $color-black;
        }
      }

      &.rounded {
        border-radius: 50% !important;
        height: 28px;
        width: 28px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;

        @media print {
          @include mozilla-suport {
            height: 20px;
            width: 20px;
            font-size: 8px;
            line-height: 1.5;
          }
        }
      }
    }
  }

  &__data-height {
    height: 37px;

    @media print {
      @include mozilla-suport {
        height: 26px;
      }
    }
  }

  &__tested {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1242px;
    color: $color-brown;

    &.dark-gray {
      background-color: $color-gray;
    }

    &-rectangle {
      width: 28px;
      height: 28px;

      &.advanced {
        background-color: $color-blue-800;
      }

      &.proficient {
        background-color: $color-green-600;
      }

      &.basic {
        background-color: $color-yellow-200;
      }

      &.below {
        background-color: $color-red-200;
      }
    }

    &-text {
      display: inline-block;
      font-weight: bold;
      padding-left: 7px;

      @media print {
        @include mozilla-suport {
          padding-left: 0;
        }
      }

      img {
        margin-right: 10px;
        color: $color-white;
        cursor: pointer;
      }
    }

    &-data {
      @include flexcenter-both;
      min-width: 31px;
      height: 37px;
      border-right: 1px solid $color-gray-750;
      font-size: 16px;
      line-height: 20px;

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }

    &-questions {
      display: flex;
      flex-direction: column;
      min-width: 28px;
      min-height: 200px;
      z-index: 9;
      width: 28px;

      @media print {
        @include mozilla-suport {
          min-width: 22px !important;
          width: 22px !important;
        }
      }
    }

    &-objective {
      display: inline-block;
      height: 60px;
      transform: rotate(225deg) translate(25px, 1.5px);
      border-left: 1px solid $color-gray-750;
      padding: 2px;
      position: relative;
      left: -1px;
      top: 0px;

      @media print {
        transform: rotate(225deg) translate(23px, 2px);
        left: 2px;
        top: -2px;

        @include safari-print {
          transform: rotate(225deg) translate(23px, 1px);
        }

        @include mozilla-suport {
          transform: rotate(225deg) translate(17.5px, 3px);
        }
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }

    &-objectiveData {
      font-size: 14px;
      color: $color-gray-850;
      line-height: 20px;
      writing-mode: vertical-rl;
      transform: translate(-2px, 0px);
      position: absolute;
      bottom: 0px;
      height: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media print {
        transform: translate(-5px, -0px);

        @include mozilla-suport {
          font-size: 8px;
          line-height: 1.5;
          transform: translate(0px, 0px);
        }
      }

      &.summative {
        @media print {
          transform: translate(-4px, -2px);
        }
      }

      &.objective {
        @media print {
          transform: translate(-3px, 2px);
        }
      }

      &.font-bold {
        font-weight: bold;
      }
    }

    &-belowData {
      display: inline-block;
      text-align: center;
      height: 60px;
      border-right: 1px solid $color-gray-750;
      margin-top: 3.5px;
      color: $color-gray-900;
      margin-left: 1px;

      @media print {
        min-width: 31px;
        margin-right: 1.5px;
      }

      &.basic-no {
        color: $color-red-600;
        font-weight: 700;
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      &.high {
        background-color: $color-yellow-FFA500;
        color: $color-gray-900;

        @media print {
          border: 1px dashed $color-yellow-FFA500 !important;
          background-color: transparent;
        }
      }

      &.critical {
        background-color: $color-red-200;
        color: $color-white;

        @media print {
          color: $color-gray-900;
          border: 1px solid $color-red-200 !important;
          background-color: transparent;
        }
      }

      &.rounded {
        border-radius: 50% !important;
        height: 22px;
        width: 22px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-size: 14px;

        @media print {
          width: 22px;
          min-width: 22px;
          height: 22px;
          transform: translateX(4px);

          @include mozilla-suport {
            font-size: 8px;
            width: 18px;
            min-width: 18px;
            height: 18px;
            transform: translateX(10px);
          }
        }
      }
    }
  }

  &__footer {
    background-color: $color-gray-500;
    width: 1242px;

    @include media-breakpoint(xl) {
      position: sticky;
      bottom: 0;
    }

    @media print {
      background-color: transparent;

      @include mozilla-suport {
        width: unset;
      }
    }

    &.move-up {
      transform: translateY(-50px);

      @include media-breakpoint(xl) {
        transform: translateY(0);
        margin-top: -50px;
      }

      @media print {
        transform: translateY(-2px);
        background-color: transparent;
      }
    }

    &-empty {
      width: 264px;

      @media print {
        width: 276px;

        @include mozilla-suport {
          width: 150px;
        }
      }
    }
  }

  .report-switch-span {
    white-space: nowrap;
    position: relative;
    width: 170px;
    left: 0;
  }

  .report-switch-tab {
    width: 170px !important;
    padding: 6px 5px !important;
    overflow: hidden;
    white-space: nowrap;
    display: block !important;

    @media only screen and (max-width: 790px) {
      width: 145px !important;
    }
  }

  .report-switch-slider {
    width: 175px !important;

    @media only screen and (max-width: 790px) {
      width: 160px !important;
    }
  }

  .report-switch {
    position: relative;
    background-color: $color-gray-800;
    width: 300px;
    height: 100%;
    padding: 3px;
    display: flex;
    align-items: unset;
    border-radius: 40px;

    @include media-breakpoint(xl) {
      margin-left: 16px;
    }

    // @media (min-width: 767px) and (max-width: 990px) {
    // 	// top: 35px;
    // }

    &.aog-switch {
      width: 100%;
      flex-wrap: wrap;
      max-width: 740px;
      padding: 3px 20px;

      @media (min-width: 767px) and (max-width: 1199px) {
        margin-left: 0;
        order: 2 !important;
      }

      @media only screen and (max-width: 790px) {
        padding: 3px 10px;
      }
    }

    &.sm {
      width: 134px;
    }

    * {
      z-index: 2;
    }

    &-radio {
      display: none;
      visibility: hidden;
    }

    input[type="radio"] {
      display: none;
    }

    input[id="radio-1"] {
      &:checked {
        & ~ .report-switch-slider {
          transform: translate(-8px, 0px);

          @media only screen and (max-width: 790px) {
            transform: translate(-4px, 0px);
          }
        }
      }
    }

    input[id="radio-2"] {
      &:checked {
        & ~ .report-switch-slider {
          width: 180px;
          transform: translate(170px, 0px);

          @media only screen and (max-width: 790px) {
            transform: translate(176px, 0px);
          }
        }

        & ~ .report-switch-slider.sm {
          width: 64px;
          transform: translateX(64px);
        }
      }
    }

    input[id="radio-3"] {
      &:checked {
        & ~ .report-switch-slider {
          width: 180px;
          transform: translate(350px, 0px);

          @media only screen and (max-width: 790px) {
            transform: translate(358px, 0px);
          }
        }

        & ~ .report-switch-slider.sm {
          width: 64px;
          transform: translateX(64px);
        }
      }
    }

    input[id="radio-4"] {
      &:checked {
        & ~ .report-switch-slider {
          width: 180px;
          transform: translate(530px, 0px);

          @media only screen and (max-width: 790px) {
            transform: translate(537px, 0px);
          }
        }

        & ~ .report-switch-slider.sm {
          width: 64px;
          transform: translateX(64px);
        }
      }
    }

    input[id="radio-5"] {
      &:checked {
        & ~ .report-switch-slider {
          width: 180px;
          transform: translate(16px, 26px);
          left: 0;

          @media only screen and (max-width: 790px) {
            transform: translate(5px, 26px);
          }
        }

        & ~ .report-switch-slider.sm {
          width: 64px;
          transform: translateX(64px);
        }
      }
    }

    input[id="radio-6"] {
      &:checked {
        & ~ .report-switch-slider {
          width: 180px;
          transform: translate(180px, 26px);

          @media only screen and (max-width: 790px) {
            transform: translate(187px, 0px);
          }
        }

        & ~ .report-switch-slider.sm {
          width: 64px;
          transform: translateX(64px);
        }
      }
    }

    input[id="radio-7"] {
      &:checked {
        & ~ .report-switch-slider {
          width: 180px;
          transform: translate(360px, 26px);

          @media only screen and (max-width: 790px) {
            transform: translate(370px, 0px);
          }
        }

        & ~ .report-switch-slider.sm {
          width: 64px;
          transform: translateX(64px);
        }
      }
    }

    input[id="radio-8"] {
      &:checked {
        & ~ .report-switch-slider {
          width: 180px;
          transform: translate(540px, 26px);

          @media only screen and (max-width: 790px) {
            transform: translate(552px, 0px);
          }
        }

        & ~ .report-switch-slider.sm {
          width: 64px;
          transform: translateX(64px);
        }
      }
    }

    &-tab {
      @include flexcenter-both;
      width: 148px;
      height: 27px;
      font-size: 16px;
      color: $color-white;
      line-height: 14px;
      border-radius: 40px;
      padding: 0;
      transition: color 0.15s ease-in;
      cursor: pointer;

      &.sm {
        width: 64px;
        text-align: center;
        padding: 3px 3px;
      }
    }

    &-slider {
      position: absolute;
      display: flex;
      height: 27px;
      width: 148px;
      background: $theme-bg-color-dark;
      transition: all 0.4s ease;
      border-radius: 40px;
      box-shadow: 1px 1px 3px rgba($color-black, 0.52);

      @media print {
        box-shadow: unset;
      }

      z-index: 1;

      &.sm {
        width: 64px;
      }
    }
  }

  &__footer-bottom {
    .footer-wrapper {
      display: flex;
      justify-content: center;
      width: 1242px;
      padding-right: 10px;

      @include media-breakpoint(xl) {
        padding-right: 0;
      }

      @media print {
        padding-right: 6px;

        @include mozilla-suport {
          padding-right: 0 !important;
          padding-left: 49px;
          width: 100%;
        }
      }

      @include mozilla-suport {
        padding-right: 14px !important;
        margin-left: 0;
      }
    }

    .report-name {
      font-size: 12px;
      line-height: 20px;
      color: $color-black;
      width: 238px;
      padding-right: 0px;
      padding-top: 6px;

      @include media-breakpoint(xl) {
        position: sticky;
        left: 0;
        width: 256px;
        clip-path: polygon(100% 0, 100% 32%, 48% 100%, 0 100%, 0 0);
        margin-left: -18px;
        background-color: $color-gray-500;
        z-index: 40;
      }

      @media print {
        @include mozilla-suport {
          max-width: 124px;
          min-width: 124px;
          font-size: 8px;
          margin-left: -14px;
          margin-right: 0 !important;
          line-height: 1.5;
          padding-top: 12px;
        }
      }
    }
  }

  .pathIcon-row-column {
    width: 38px;
    height: 37px;

    @include media-breakpoint(xl) {
      position: sticky;
      left: 58px;
      background-color: $color-gray-500;
    }

    @media print {
      height: unset;
    }

    &.dark-bg {
      @include media-breakpoint(xl) {
        background-color: $color-gray;
      }
    }

    @media print {
      @include mozilla-suport {
        width: 24px;
      }
    }
  }

  .pathIcon-row {
    margin-left: 8px;
    margin-right: 8px;

    @media print {
      @include mozilla-suport {
        margin-left: 4px;
        margin-right: 4px;
      }
    }

    img {
      @media print {
        @include mozilla-suport {
          width: 16px;
          height: auto;
        }
      }
    }
  }

  .table-dropdown-toggle {
    height: 33px;
    padding: 7px 6px;
    color: white !important;
  }

  .form-control {
    padding: 2px 0px 2px 8px !important;
  }
}

.summative-report-tabs {
  width: 100%;
  max-width: 960px;
  display: flex;
  position: relative;
  margin-left: auto;
}

.less-proficient {
  display: flex;
  justify-content: center;
  padding: 4.5px 1.5px;

  @media print {
    @include mozilla-suport {
      justify-content: flex-start;
      padding: 2px 1.5px;
      padding-left: 28px;
    }
  }
}

.tabs-margin {
  margin-top: 12px;
  margin-bottom: 20px;
  justify-content: center;

  @media only screen and (max-width: 1199px) {
    margin-top: 55px;
    margin-bottom: 0;
    padding-bottom: 30px;
  }

  @media (min-width: 767px) and (max-width: 990px) {
    margin-top: 0;
  }
}

@media (min-width: 767px) and (max-width: 1100px) {
  .summative-objective-analysis__data-header {
    padding-left: 27px;
  }
}

.new-scale {
  position: relative;
  margin-top: 45px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 1242px;

  @media print {
    margin-top: 50px;
    // margin-bottom: 60px;

    @include mozilla-suport {
      margin-bottom: 20px !important;
      width: unset;
    }
  }

  &__school {
    position: relative;
    display: flex;
    left: -11px;

    @media print {
      // left: 20px;
    }

    .schoolFirst {
      position: relative;

      .schoolFirst-center {
        width: 28px;
        height: 16px;
        top: -7px;
        border-left: 1px solid $color-blue-600;
        border-top: 1px solid $color-blue-600;
        border-right: 1px solid $color-blue-600;
        position: relative;
        left: 0.4px;
        @media print {
          border-left: 0.5px solid $color-blue-600;
          border-top: 0.5px solid $color-blue-600;
          border-right: 0.5px solid $color-blue-600;
        }
      }

      .text-first {
        position: relative;
        margin-left: auto;
        display: block;
        font-size: 12px;
        margin-top: -8px;
        white-space: nowrap;
        padding-left: 5px;
        color: $color-blue-600;
        font-weight: 600;
        @media print {
          font-weight: 100;
        }

        span {
          position: relative;
          z-index: 9999;
          padding-left: 2px;
          padding-right: 2px;
          background-color: $color-gray-500;

          @media print {
            background-color: $color-white;
          }
        }
      }

      .schoolFirst-top {
        width: 28px;
        height: 42px;
        border: 1px solid $color-blue-600;
        border-bottom: 0px;
        margin-top: -33px;
        position: relative;
        left: -0.6px;
        @media print {
          border: 0.5px solid $color-blue-600;
          border-bottom: none;
        }

        @media (min-width: 767px) and (max-width: 1242px) {
          left: -1.5px;
        }

        @media print {
          left: -1.5px;
          margin-top: -40px;
          height: 40px;
        }

        .text-top {
          position: relative;
          margin-left: auto;
          display: block;
          font-size: 12px;
          margin-top: 5px;
          white-space: nowrap;
          padding-left: 5px;
          color: $color-blue-600;
          font-weight: 600;
          @media print {
            font-weight: 100;
          }

          span {
            background-color: $color-gray-500;
            @media print {
              background-color: $color-white;
            }
          }
        }
      }

      .schoolFirst-bottom {
        width: 28px;
        border-left: 1px solid $color-blue-600;
        border-top: 1px solid $color-blue-600;
        position: relative;
        left: -1px;
        margin-top: 8px;

        @media (min-width: 1881px) {
          left: -1.1px;
        }
        @media print {
          border-left: 0.5px solid $color-blue-600;
          border-top: 0.5px solid $color-blue-600;
          margin-top: 0.5px;
          margin-left: -1px;
        }

        @media (min-width: 767px) and (max-width: 1242px) {
          left: -2.5px;
        }

        &::after {
          content: "";
          background-color: $color-blue-600;
          width: 2px;
          height: 1px;
          position: absolute;
          top: -1px;
          right: -2px;
        }

        @media print {
          left: -2.5px;
        }

        .text-bottom {
          position: relative;
          font-size: 12px;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-left: 5px;
          color: $color-blue-600 !important;
          font-weight: 600;
          display: inline-block;
          @media print {
            font-weight: 100;
          }

          span {
            background-color: $color-gray-500;
            position: relative;
            z-index: 111;

            @media print {
              background-color: $color-white;
            }
          }
        }
      }
    }

    .schoolFirst-align {
      width: 150px;
      border-left: 1px solid $color-blue-600;
      border-top: 1px solid $color-blue-600;
      border-right: 1px solid $color-blue-600;
      position: relative;
      top: -7px;
      height: 16px;
      display: flex;
      left: 1px;
      @media print {
        border-left: 0.5px solid $color-blue-600;
        border-top: 0.5px solid $color-blue-600;
        border-right: 0.5px solid $color-blue-600;
      }
    }

    .text-align {
      font-size: 12px;
      white-space: nowrap;
      padding-left: 5px;
      margin: 0 auto;
      display: block;
      margin-top: -8px;
      color: $color-blue-600 !important;
      font-weight: 600;
      position: relative;

      span {
        background-color: $color-gray-500;

        @media print {
          background-color: $color-white;
        }
      }
    }

    .border-right {
      border-right: 1px solid $color-blue-600;
      @media print {
        border-right: 0.5px solid $color-blue-600;
      }
    }
  }
}
.footerLoading {
  margin-top: -48px;
}
.objectiveLoading {
  margin-left: 48px;
  margin-top: -48px;
}
