.classroomTabs {
    @include flex-box(center, center);
    flex-wrap: wrap;
    gap: 15px 40px;
    margin-top: 18px;
    margin-bottom: -17px;

    @media (max-width: 1024px) {
        margin-top: 15px;
        margin-bottom: 20px;
        position: relative;
        z-index: 9;
    }

    @include media-breakpoint(md) {
        justify-content: flex-start;
    }

    @include media-breakpoint(sm) {
        gap: 15px 20px;
    }

    .tab {
        @include flex-box(flex-start, center);
        gap: 0 15px;
        z-index: 10;
        border: unset;
        background-color: unset;

        @include media-breakpoint(sm) {
            gap: 0px 8px;

            svg {
                width: 30px;
            }
        }

        p {
            font-size: $md;
            font-weight: 400;
            line-height: 21.82px;
            color: white;
            margin-bottom: 0;
        }
    }
}