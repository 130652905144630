.PacingCalender {
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    .pacing-dropdowns{
        display: flex;
        position: relative;
        @media (max-width: 860px) {
            width: 50%;
            margin-top: 20px;
            .pacingCalenderDropdown {
                top: 0;
                width: 100%;
                .table-dropdown-container {
                    width: 100%;
                    max-width: 100%;
                    .table-dropdown.dropdown {
                        width: 90%;
                    }
                }
            }
        }

        @media (max-width: 960px) {
            margin-top: 22px;
        }

        @media (max-width: 575px) {
            flex-direction: column;
            width: 100%;
            .pacingCalenderDropdown {
                width: 100%;
            }
        }
        
        @media (max-width: 400px) {
            margin-top: 8px;
        }

            .ProfessionalDev__devSummary__toolTip {
                width: 372px;
                bottom: unset;
                left: 40px;
                top: 80px;

                @media (max-width: 425px) {
                    width: auto;
                }

                .toolTip-body {

                    @media (max-width: 425px) {
                        height: 65px;
                    }

                    p {
                        @media (max-width: 425px) {
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
            }
    }
    
    .section-search {
        @media (max-width: 960px) {
            margin-top: 25px;
            height: 35.75px;
        }

        @media (max-width: 400px) {
            margin-top: 12px;
        }
    }
    
    .pacing-header-navigation{
        width: 100%;
        max-width: 433px;
        @media (max-width: 960px) {
            padding-top: 5px;
            padding-bottom: 5px;
            align-items: center;
            width: 100%;
        }

        .outline-btn {
            border-radius: $border-radius-md;
            margin-left: 18px;
            padding: 3px 11px 2px;
            width: 63px;
            height: 26px;
            font-size: $sm;
            line-height: 19px;
        }

        .desc {
            font-size: $md;
            font-weight: 400;
            line-height: 20px;
            color: $color-light-400;
            margin-top: 3px;
            margin-bottom: 0;
        }
    }
    
    .keyBox {
        width: 130px;
        padding: 0;
        margin-right: 30px;
        margin-left: 74px;

        @media (max-width: 960px) {
            position: absolute;
            top: 5px;
            right: 0;
            margin-right: 0;
            width: 148px;
        }

        @include media-breakpoint(md) {
            top: 7px;
        }


        .keyLabel {
            background-color: $color-gray-500;
            border-radius: 40px;
            padding: 1px 13px;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            color: $color-gray-950;
            display: inline-block;
            margin-bottom: 4px;
        }

        svg {
            width: 20px;
            height: 16px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: white;
            margin-bottom: 0px;
            padding-left: 13px;
        }
    }

    .pdAdminContainer {
        border-radius: unset;
        margin-top: 0px;
        margin-bottom: 0;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &__container {
        margin-top: 22px;
        // box-shadow: 0px 3px 12px #00000038;
        overflow: hidden;

        &__ChatBox {
            position: absolute;
            z-index: 999;
            right: 0;
        }

        &__header {
            padding: 0 28px 0px 14px;
            flex-wrap: wrap;

            @media (max-width: 960px) {
                height: auto;
                padding-bottom: 12px;
                position: relative;
            }

            @media (max-width: 768px) {
                padding: 0px 16px 10px;
            }

            .section-search {
                padding: 4px 0px 0px;
                background: transparent !important;
                z-index: auto;
                
                @media (max-width: 860px) {
                    width: 50%;
                    justify-content: flex-end;
                }

                @media (max-width: 575px) {
                    justify-content: space-between;
                    margin-top: 12px;
                    width: 100%;

                    .section-search-field {
                        width: 95%;
                    }

                    input {
                        max-width: 100%;
                        margin-right: 0;
                    }
                }

                

                button {
                    width: 105px;
                }
            }

            .title {
                @include secHeading(0px ,0px);

                @media (max-width: 1025px) {
                    line-height: 25px;
                }

                @media (max-width: 860px) {
                    width: 100%;
                }

                @media (max-width: 400px) {
                    font-size: 18px;
                }
            }

            .pacing-calendar-header-right {
                // width: calc(100% - 450px);
                width: 100%;
                max-width: 700px; 
                padding-top: 10px;
                margin-left: auto;

                @include media-breakpoint(lg) {
                    max-width: 100%;
                }

                @media (max-width: 650px) {
                    padding-top: 80px;
                    position: relative;
                }
                
                .switch-toggle-container {
                    @include flex-box(flex-end, flex-start);
                    gap: 24px;

                    @include media-breakpoint(sm) {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .complete-toggle-container {

                        p {
                            color: white;
                            margin-right: 14px;
                            margin-top: 0;
                        }

                        .switch {
                            margin-bottom: 0;

                            .switch-slider {

                                &::before {
                                    // top: 2px;
                                }
                            }
                        }
                    }
                }

                .dropdown-search-section {
                    @include flex-box(flex-end, center);
                    margin-top: 15px;

                    @include media-breakpoint(lg) {
                        justify-content: space-between;
                    }

                    @include media-breakpoint(sm) {
                        flex-direction: column;
                        align-items: unset;
                    }
                }

                .pacing-dropdowns {
                    
                    @media (max-width: 860px) {
                        max-width: 243px;
                    }

                    @include media-breakpoint(sm) {
                        max-width: 100%;
                    }

                    .pacingCalenderDropdown {

                        .table-dropdown-container {

                            .table-dropdown {
                                @media (max-width: 600px) {
                                    width: 100%;
                                }

                                >button {
                                    background-color: white !important;
                                    width: 100%;
                                    background-image: url(../../img/drop-down-select.svg);
                                    border: 1px solid #4788ff !important;
                                    height: 33px;
                                    background-position-y: 13px;
                                    border-radius: $border-radius-md;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__table {
            min-width: 1200px;

            tbody {
                position: relative;

                tr {
                    &:nth-child(2) {

                        td:first-child {
                            border-radius: 8px 0px 0px 0px;
                            display: table-cell !important;
                        }
    
                        td:last-child {
                            border-radius: 0px 8px 0px 0px;
                            display: table-cell !important;
                        }
                    }
    
                    &:last-child {
    
                        td:first-child {
                            border-radius: 0px 0px 0px 8px;
                            display: table-cell !important;
                        }
    
                        td:last-child {
                            border-radius: 0px 0px 8px 0px;
                            display: table-cell !important;
                        }
                    }
                }

                tr:nth-child(2).note {
                    
                    .PacingCalender__container__table__bodyrow__infotext {
                        border-radius: $border-radius-md $border-radius-md 0px 0px !important;
                    }
                }

                tr:last-child.note {
                    
                    .PacingCalender__container__table__bodyrow__infotext {
                        border-radius: 0px 0px 8px 8px !important;
                    }
                }
            }
            
            .td1 {
                width: 204px;
                padding-left: 0;
                // display: flex;
            }

            .bulbDiv {
            
                width: 83%;

                @-moz-document url-prefix() {
                   top: 0px;
                }

                p {
                    @-moz-document url-prefix() {
                        z-index: 99999;
                    }
                }

                img {
                    @-moz-document url-prefix() {
                        margin-top: 2px;
                    }
                }
            }

            .td2 {
                width: 368px;
                padding-left: 7px;
            }

            .td3 {
                width: 675px;
            }

            .td4 {
                width: 220px;
                position: relative;

                .pacing-calender-position-assessment {
                    width: 320px;
                    max-width: unset;
                    left: -610px;
                    bottom: unset;
                    top: -28px;
                }
            }

            &__headrow {
                background-color: unset !important;
                vertical-align: bottom;
                height: 37px;

                td {
                    // text-shadow: 2px 3px 6px #00000089;
                    padding: 4px 16px 4px 19px;
                }

                .td1 {
                    padding-left: 14px;
                }
            }

            .no-pacing-data {
                @include flex-box (flex-start, center);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 1px;
                left: -74px;
                pointer-events: none;
                z-index: 1;
                font-size: $md;
                font-weight: 700;
                line-height: 22px;
                color: white;
                margin-bottom: 0;
            }

            .no-pacing-data-pos-2 {
                top: -20px;
            }

            .no-pacing-data-pos-4 {
                top: -20px;
            }

            .imp-date-pos-above {
                top: -22px;
            }

            .imp-date-pos-below {
                top: 22px;
            }

            &__bodyrow {
                vertical-align: baseline;
                position: relative !important;

                &__infotext {
                    padding-left: 5px !important;
                    padding-top: 10px !important;
                    min-width: 200px;

                    .edit-important-date {
                        padding-bottom: 10px;
                        padding-left: 10px;
                        width: 30px;
                        height: 27px;
                    }

                    .add-important-date {
                        // padding-bottom: 13px;
                        padding-left: 10px;
                        width: 30px;
                        // height: 30px;
                    }
                }

                .checkCircle {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    left: -4px;
                    top: 8px;
                }

                .TickIcon {
                    height: 16px;
                    width: 22px;
                    margin-right: 8px;
                    margin-top: -2px;
                }

                .deleteIcon {
                    // filter: brightness(0) invert(1);
                    height: 22px;
                    width: 18px;
                    padding-bottom: 2px;
                }

                .allDataDel {
                    height: 15px;
                    width: 14px;
                    position: relative;
                    top: 1.3px;
                    left: -12px;
                }

                .leftSpace {
                    margin-left: 15px;
                    margin-top: -2px;
                }

                .disabled {
                    opacity: 0.5;
                }

                td {
                    padding: 6px 16px 4px 19px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21.82px;
                    color: white;

                    p {
                        margin-bottom: 0;
                    }
                }

                .td1 {
                    
                    img {
                        width: 12px;
                        margin-right: 8px;
                    }
                }

                .td2 {
                    position: relative;
                    vertical-align: top;

                    span {
                        padding: 3px 12px 1px 13px;
                        background-color: $color-green;
                        border-radius: $border-radius-md;
                        display: inline-flex;

                        .TickIcon {
                            width: 22px;
                            margin-right: 7px;
                            margin-top: 2px;
                        }

                        div {
                            width: 265px;
                            overflow: hidden;

                            p {
                                width: 100%;
                                font-size: $md;
                                font-weight: 400;
                                line-height: 22px;
                                color: white;
                                @include ellipsis;
                                position: relative;
                                left: 0%;
                                cursor: pointer;
                                transition: left 3s linear, width 3s linear;
                            }

                            .animated:hover{
                                left: -100%;
                                width: 210%;
                              }
                        }

                        .deleteIcon {
                            width: 19px;
                            height: 19px;
                            padding: 0;
                            margin-top: 1px;
                            margin-left: 7px;
                        }
                    }
                }

                .section-search-field {

                    .icon {
                        margin-right: 10px !important;

                        @media (min-width: 768px) {
                            margin-left: 35px !important;
                        }
                    }

                    input {
                        padding-top: 3px;
                        padding-bottom: 1px;
                        color: rgb(110, 110, 110);
                        font-weight: 400;
                        width: 185px;

                        &::placeholder {
                            font-weight: 400;
                            color: rgb(110, 110, 110);
                        }
                    }
                }
            }

            &__bodyrow {
                background-color: #0000001a;
                height: 37px;

                .td1 {
                    padding-top: 6px;

                    >span {
                        
                        span {
                            display: inline-block;
                            width: 118px;
                            height: 26px;
                            background-color: #d9d9d9;
                            border-radius: 0px $border-radius-md $border-radius-md 0px;
                            font-size: $md;
                            font-weight: 400;
                            color: $color-gray-950;
                            text-align: right;
                            padding: 3px 6px 0px 6px;
                        }
                    }

                    .outline-btn {
                        width: 51px;
                        height: 26px;
                        padding: 3px 8px 2px;
                        border-radius: $border-radius-md;
                        font-size: $sm;
                        margin-left: 12px;
                        line-height: 19px;
                    }

                    .opacity-disabled {
                        opacity: 0.25;
                    }
                }

                .td3 {
                    font-size: 15px;
                    line-height: 18px;
                    vertical-align: middle;
                    padding-top: 4px;
                    position: relative;
                }
                .assesment_not_completed {
                    background-color: #4788FF !important;
                }
            }

            .note {

                td {

                    span {
                        padding: 3px 12px 2px 10px;
                        background-color: #9080ff;
                        border-radius: $border-radius-md;
                        @include flex-box(flex-start, center);
                        display: inline-flex;
                        gap: 12px;
                    }
                }
            }
            
            // &__bodyrow:nth-child(2) {
                
            //     td:first-child {
            //         border-radius: 8px 0px 0px 0px;
            //         height: 53px;
            //     }
                
            //     td:last-child {
            //         border-radius: 0px 8px 0px 0px;
            //         height: 53px;
            //     }
            // }
            
            // &__bodyrow:last-child {
                
            //     td:first-child {
            //         border-radius: 0px 0px 0px 8px;
            //         height: 53px;
            //     }
                
            //     td:last-child {
            //         border-radius: 0px 0px 8px 0px;
            //         height: 53px;
            //     }
            // }

            // &__bodyrow:last-child td {
            //     border-radius: 0px 0px 8px 8px;
            // }

            // tbody:last-child {
            //     tr:last-child {
            //         td {
            //             border-radius: 0px 0px 8px 8px;
            //         }
            //     }
            // }
        }
    }

    .pacingCalenderDropdown {
        @media (max-width: 860px) {
            top: -32px;
        }

        @media (max-width: 768px) {
            top: -27px;
        }

        @media (max-width: 575px) {
            top: 10px;
        }

        .table-dropdown-container {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 243px;

            @media (max-width: 940px) {
                left: 70%;
            }

            @media (max-width: 575px) {
                width: 100%;
            }

            .text-btn {
                // width: 26px;
                // height: auto;
                padding: 0;

                // img {
                //     width: 100%;
                //     height: auto;
                // }
            }

            // .text-btn:first-child {
                
            //     svg {
            //         transform: rotate(-180deg);
            //     }
            // }

            // .text-btn:disabled {
            //     opacity: 0.27;
            // }

            .table-dropdown {
                width: 195px;
                padding: 0 11px;

                @media (max-width: 575px) {
                    width: 100%;
                }

                button {
                    width: 173px;
                    line-height: 21px;
                    padding: 5px 10px 5px 12px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #4788FF;

                }

                .table-dropdown-menu {
                    width: 175px;

                    @media (max-width: 575px) {
                        width: 94%;
                    }

                    .table-dropdown-item {
                        font-size: 14px;
                        color: #4788ff;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &__DeleteModel {
        &__clearMonth {
            background-color: $color-orange-100 !important;
            color: $color-black !important;
            padding: 5px 11px 5px !important;
            width: 146px;

            &:hover {
                color: $color-white;
                background-color: darken(#fe2100, 5%);
            }

            &.clear-assessment-btn {
                width: 160px;
            }

            &.clear-all-month {
                width: 98px;
            }
        }

        &__cancelBtn {
            padding: 6px 13px 3px;
            background-color: unset !important;
            border: 1px solid white;

            &:hover {
                border-color: white !important;
            }
        }
    }

    &__SuggestedModel {
        &__Createbtn {
            padding-left: 14px;
            padding-right: 14px;
            background-color: unset;
            border: 1px solid white;

            &:hover {
                color: $color-white;
                border-color: white;
            }

            &:active {
                color: $color-white !important;
                border-color: white !important;
            }

            @media (max-width: 400px) {
                width: 100%;
            }
        }

        &__Pacingdisabled {
            background-color: #4499a1 !important;
            color: white !important;
        }
        
        &__Suggestion {
            background-color: #4499a1 !important;
            padding: 7px 10px;
            width: 100%;
            max-width: 200px;
            border: none !important;
            color: white !important;

            &:hover {
                color: $color-white;
                background-color: darken(#4499a1, 5%);
            }

            @media (max-width: 400px) {
                max-width: 100%;
                width: 100% !important;
            }
        }

        &__text {
            p {
                a {
                    color: #43939b !important;
                }
            }
        }
    }

    &__AssessmentModel {
        .modal-content {
            width: 100% !important;
            max-width: 1040px;
            padding: 16px 24px;
            border-radius: 10.5px;

            .backBtn {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                transition: all .3s;
                margin-left: -8px;

                img {
                    width: 100%;
                    height: 100%;
                    transform: rotate(-90deg);
                }
            }

            h4 {
                font-weight: 500;
                line-height: 21px;
            }

            .backBtn:hover {
                background-color: #2a5391;
            }
        }

        &__container {
            &__main {
                background-color: #194792;
                min-width: 960px;
            }

            .sugessted-modal {

                .td1 {
                    min-width: 235px;
                    max-width: 235px;
                }

                .td2 {
                    width: 320px;
                    min-width: 320px;

                    .TickIcon {
                        margin-top: 2.5px;
                    }
                }
            }

            &__table {
                &__head {
                    .td1 {
                        width: 200px;
                    }

                    .td2 {
                        width: 250px;
                    }

                    .td3 {
                        width: 450px;
                    }

                    td {
                        font-size: 16px;
                        font-weight: 700;
                        text-shadow: 0px 3px 6px #00000089;
                    }
                }

                &__body {
                    tr {
                        vertical-align: baseline;
                        height: 74px;
                    }

                    tr:nth-child(even) {
                        background-color: #537ab7;
                    }

                    tr:nth-child(odd) {
                        background-color: $theme-bg-color-lighter;
                    }
                }
            }

            .headrow {
                background-color: unset !important;
            }
        }
    }

    &__SheduledAssesment {
        .modal-content {
            width: 100% !important;
            max-width: 1040px;
            padding: 16px 24px;
            border-radius: 10.5px;

            .modal__header-title {
                line-height: 25.78px;
            }

            .modal__header-desc {
                font-size: 18px;
                font-weight: 500;
                line-height: 21px;
                color: white;
                font-family: $font-nunito;
                margin-top: 3px;
                margin-bottom: 0;
            }

            .section-search-field {
                padding-right: 5px;

                .form-control {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    color: #6e6e6e;
                    padding-left: 12px;
                    padding-right: 12px;
                    &::placeholder {
                        font-weight: 400;
                    }
                }
            }

            .show-assessment-dropdown {
                justify-content: flex-end;
                margin-top: 12px !important;
                
                .limit-selects {
                    margin-right: 5px;
                    width: 100%;
                    max-width: 208px;
                }

                .limit-selects__control {
                    width: 208px;
                    padding-right: 2px;
                }
            }
        }

        .limit-selects__control {
            width: 165px;
        }

        .modal-content {
            width: 100% !important;
            max-width: 1059px;
            padding: 12px 22px 23px 24px;
            max-height: 90vh;
            border-radius: 10.5px;

            @include media-breakpoint(xl) {
                max-width: 97.3%;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .PacingCalender__SheduledAssesment__container  {
                padding-top: 0px;
                margin-bottom: 20px;
            }

            .adminProfessional__container__header__backAdmin__back {
                position: relative;
                left: -11px;

                .accordion__header-button {
                    margin-right: 0;
                }
            }
        }

        &__container {
            &__orderDropDown {
                border-radius: 8px;
                width: 108px;
                height: 19px;
                font-size: 13px;
                font-weight: 400;
                color: #01041b;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url(../../img/down-arrow.svg);
                background-repeat: no-repeat;
                background-position-x: 83px;
                background-size: 12px;
                background-position-y: 7px;
                border: 0px;
                line-height: 14px;
                padding: 1px 6px 0;
                button {
                    background-color: #fff;
                    color: #000;
                    border: none;
                    font-size: 12px;
                }

                .btn {
                    padding: 1px 10px;
                }

                .dropdown-toggle::after {
                    position: relative;
                    top: 2px;
                    left: 4px;
                }
            }

            .orderbyText {
                font-size: 13px;
            }

            &__main {
                // background-color: #194792;
                border-radius: $border-radius-md;
                min-width: 960px;
                padding-top: 8px;

                &__table {

                    td {
                        font-size: $md;
                        color: $color-gray-950 !important;

                        p {
                            color: $color-gray-950;
                        }
                    }

                    .td1 {
                        width: 332px;
                        min-width: 332px;
                        max-width: 332px;
                        padding-left: 35px !important;
                        display: table-cell;
                        padding-right: 0 !important;

                        img {
                            position: relative;
                            top: 3px;
                            width: 22px;
                            padding-right: 0 !important;
                            margin-right: 7px;
                        }

                        .TickIcon.hover-white {
                            display: none;
                        }

                        .scheduledLabel {
                            background-color: #3333338f;
                            border-radius: $border-radius-md;
                            border-width: 0px;
                            padding: 2px 7px 2px 10px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            color: white;
                            display: inline-block;
                            margin-top: 9px;
                            // text-shadow: 1px 1px 3px #00000084;
                        }

                        >span {
                            padding: 2px 5px;
                            display: block;
                            position: relative
                        }
                    }

                    .td2 {
                        width: 458px;
                        min-width: 458px;
                        font-size: $md - 1;
                        padding-left: 10px !important;
                        padding-right: 10px !important;

                        >span {
                            padding: 2px 5px;
                            display: block;
                            position: relative;
                        }
                    }

                    .td3 {
                        width: 220px;
                        min-width: 220px;
                    }

                    &__body {
                        // background-color: $color-gray-500;

                        .notfound__data {
                            background-color: $color-gray-500;
                            border-radius: 8px !important;

                            .notfound__data-container {

                                p {
                                    font-weight: 700;
                                }
                            }
                        }

                        tr {
                            vertical-align: top;

                            &:first-child {

                                td:first-child {
                                    border-radius: 8px 0px 0px 0px;
                                    display: table-cell;
                                }

                                td:last-child {
                                    border-radius: 0px 8px 0px 0px;
                                    display: table-cell;
                                }
                            }

                            &:last-child {

                                td:first-child {
                                    border-radius: 0px 0px 0px 8px;
                                    display: table-cell;
                                }

                                td:last-child {
                                    border-radius: 0px 0px 8px 0px;
                                    display: table-cell;
                                }
                            }

                            td {
                                padding-top: 6px !important;
                                padding-bottom: 4px !important;
                                position: relative;
                                background-color: $color-gray-500;

                                &:first-child {

                                    .divider-table {
                                        width: calc(100% - 19px);
                                        margin-left: auto;
                                        right: 0;
                                    }
                                }

                                &:last-child {

                                    .divider-table {
                                        width: calc(100% - 19px);
                                        margin-right: auto;
                                        left: 0;
                                    }
                                }

                                p {
                                    line-height: 22px;
                                }

                                .divider-table {
                                    position: absolute;
                                    bottom: 0px;
                                    left: 0;
                                    width: 100%;
                                    height: 1px;
                                    background-color: $color-gray;
                                    padding: 0;
                                    display: block;
                                }
                            }

                            .td2 {
                                color: $color-gray-950 !important;
                            }
                        }

                        .aditionalComment {
                            font-size: 14px;
                            margin-bottom: 0px;
                            margin-top: 12px;
                            background-color: #454648;
                            border-radius: 30px;
                            padding: 2px 8px;
                            width: 225px;
                        }
                    }

                    tr.schedule-assessment-hover:hover {

                        .td1 {
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                top: 6px;
                                left: 35px;
                                width: 100%;
                                height: calc(100% - 10px);
                                background-color: $color-blue-200;
                                border-radius: 8px 0px 0px 8px;
                            }

                            color: white !important;

                            p {
                                color: white !important;
                            }
                            
                            >span {
                            }

                            .TickIcon {
                                display: none;
                            }

                            .TickIcon.hover-white {
                                display: block;
                            }
                        }

                        .td2 {
                            color: white !important;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                top: 6px;
                                left: 0;
                                width: 100%;
                                height: calc(100% - 10px);
                                background-color: $color-blue-200;
                                border-radius: 0px 8px 8px 0px;
                            }

                            p {
                                color: white !important;
                            }
                            
                            >span {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.schedule-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    font-family: $font-openSans;
    color: white;
    margin-bottom: 0;
}

.summative-modal-header {
    // text-shadow: 0px 3px 6px rgb(0 0 0 / 54%);
    padding-left: 13px !important;
}

.PacingCalender__SheduledAssesment__container__main {
    thead {
        th {
            font-size: 16px;
            font-weight: 800 !important;
            line-height: 20px;
            // text-shadow: 0px 3px 6px rgb(0 0 0 / 54%);
            padding-top: 0 !important;
            padding-bottom: 6px !important;
        }

        .td1 {
            padding-left: 40px !important;
        }

        .td2 {
            padding-left: 15px !important;
            padding-right: 5px !important;
        }
    }
}

.PacingCalender__SheduledAssesment .modal-content {
    overflow: auto;
}