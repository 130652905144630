.Discussions {
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;

  .discussions {
    flex: 1;

    .frame_setting {
      width: 100%;
      height: 800px;
      border: none;
    }
  }

  .discussions-header-navigation {
    @media (max-width: 860px) {
      padding-top: 5px;
      padding-bottom: 5px;
      align-items: center;
      width: 100%;
    }
  }

  &__container {
    // background-color: #537ab7;
    border-radius: $border-radius-md;
    margin-top: 22px;
    // box-shadow: 0px 3px 12px #00000038;
    overflow: hidden;
    margin-bottom: -5px;

    .setting_border {
      border-radius: 8px;
    }

    &__ChatBox {
      position: absolute;
      z-index: 999;
      right: 0;
    }

    &__header {
      padding: 0% 16px;
      height: 60px;
      flex-wrap: wrap;

      @media (max-width: 860px) {
        height: auto;
      }

      @media (max-width: 768px) {
        padding: 10px 16px;
      }

      .title {
        font-size: 22px;
        font-weight: 700;
        line-height: 29px;
        color: white;
        font-family: $font-openSans;
        @media (max-width: 1025px) {
          line-height: 25px;
        }

        @media (max-width: 860px) {
          width: 100%;
        }
      }
    }

    &__body {
      background-color: #537ab7;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
      min-height: 250px;
    }
  }
}
