// Section Header
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid $theme-border-color;
  padding: 13px 18px;
  padding-right: 34px;

  @media (max-width: 1023px) {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @include media-breakpoint(xl) {
    padding-right: 18px;
  }

  &.md-column {
    @include media-breakpoint(md) {
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &.sm-column {
    @include custom-break-poitn(461px) {
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &.monitor-header {
    flex-wrap: wrap;
    gap: 10px;
    height: unset;
    padding: 2px 36px 7.55px 14px;
    
    @include media-breakpoint(lg) {
      // flex-direction: column;
      // align-items: flex-start;
      // justify-content: flex-start;
      height: auto;
      justify-content: flex-end;
    }

    @include media-breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.super-admin {
    @media only screen and (max-width: 850px) {
      height: auto;

      @media (max-width: 400px) {
        display: block;
      }
    }
  }

  &-heading {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &-title h3,
  h3 {
    font-family: $font-openSans;
    font-weight: 700;
  }

  &-paragraph {
    color: $color-gray-300;
    margin-bottom: 0;

    @include media-breakpoint(xxl) {
      font-size: 15px;
    }
  }

  .activity-progress-btns {
    button {
      &:focus-visible {
        outline: 2px solid black;
      }
    }

    @include custom-break-poitn(461px) {
      margin-left: auto;
      margin-top: 10px;
    }
  }

  .all-sessions-dropDown {
    width: 297px;
    margin-left: 19px;

    @include media-breakpoint(sm){
      width: 100%;
      margin-left: 0;
    }

    .searchable-selects {
      .searchable-selects__wrapper {
        .searchable-selects__control {
          background-color: transparent;
          border-color: $theme-border-color;
          border-radius: $border-radius-md;
          height: 43px;

          .searchable-selects__value-container {
            .searchable-selects__input-container {
              color: white;
              font-size: 22px;
            }
            .searchable-selects__single-value {
              font-size: 22px;
              font-weight: 400;
              font-family: $font-openSans;
              color: white;
            }
          }

          .searchable-selects__indicators {
            .searchable-selects__indicator {
              background-image: url(../../../assets/img/select-indicator.svg);
              background-repeat: no-repeat;
              width: 20px;
              margin-right: 26px;
              margin-top: 9px;

              svg {
                display: none;
              }
            }
          }
        }

        .searchable-selects__menu {
          .searchable-selects__menu-list {
            .searchable-selects__option {
              color: $color-blue-200;
            }

            .searchable-selects__option--is-selected {
              color: white;
            }
          }
        }

        .searchable-selects__option {
          // display: flex !important;
          // align-items: center;
          // gap: 16px;

          &>div {
            .active-dot {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              background-color: $color-green;
              display: block;
            }
          }

          
        }

        .searchable-selects__menu {

          &-list {

            &>div {
              // gap: 11px;
              // padding: 7px 16px;
              // cursor: pointer;

              &:hover {
                // background-color: #4788ff;

                // .option-text {
                //   color: white;
                // }
              }

              

              .option-text {
                width: calc(100% - 26px);
                // font-size: $md;
                // font-weight: 400;
                // color: $color-gray-950;
              }
            }
          }
        }
      }
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-left: unset;
      // width: 50%;
    }

    @include media-breakpoint(sm) {
      margin-left: unset;
    }

    &.md-end {
      @include media-breakpoint(md) {
        margin-left: auto;
        margin-top: 13px;
      }

      @include custom-break-poitn(461px) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    &.monitor-buttons {
      @include media-breakpoint(lg) {
        align-self: flex-end;
        // margin-top: 10px;
      }
    }

    &.super-admin {
      @media only screen and (max-width: 850px) {
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 350px;
      }
    }

    h3 {
      font-family: $font-openSans;
      font-weight: 500;

      @include media-breakpoint(xxl) {
        transform: translateX(-3px);
      }
    }
  }

  &-btnPosition {
    align-items: flex-start !important;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    max-width: 510px;
    gap: 12px 0px;
  }

  .sm-end {
    @include custom-break-poitn(461px) {
      margin-left: auto;
      margin-top: 13px;
    }
  }

  &-btnContainer {
    margin-left: 17px;

    @media (max-width: 1023px) {
      margin: 5px 0px 5px 10px;
    }

    @include media-breakpoint(lg) {
      margin-left: 16px;
    }

    @include media-breakpoint(md) {
      margin-left: 12px;
    }

    &.md-m-none {
      @include media-breakpoint(md) {
        margin-left: 0;
      }

      @include custom-break-poitn(461px) {
        margin-bottom: 12px;
      }
    }

    &.super-admin {
      @media only screen and (max-width: 850px) {
        margin-bottom: 12px;
      }
    }

    &.add-admin-btn {
      @include custom-break-poitn(425px) {
        margin-top: 12px;
      }
    }
  }

  &-counter {
    display: flex;
    align-items: center;
  }

  &-simulation {
    display: flex;
    align-items: center;
    margin: 0px 0px 0px auto;
  }

  &-beta-tag {
    background-color: #ffeb66;
    border-radius: 20px;
    padding: 5px 10px;
    color: #424242;
    margin-right: 18px;
    font-size: 14px;
  }

  &-link {
    @include flexcenter-both;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    transition: all 0.3;
    cursor: pointer;

    &:hover {
      background-color: $color-blue-600;
    }

    img {
      max-width: 45px;
      margin-right: 1px;
      transition: all 0.3s;
      transform: rotate(-90deg);
    }
  }

  &-backlink {
    padding: 0 !important;
    height: 33px;
    transform: translateX(-7px);

    @include media-breakpoint(xxl) {
      transform: translateX(-12px);
    }

    img {
      max-width: 40px;
      height: auto;
      transform: rotate(-90deg);
      margin-top: -6px;
    }
  }

  .shl-progress-report-btn {
    margin-right: 20px;

    @include media-breakpoint(md) {
      margin-right: 10px;
    }
  }
  .answer-section-header-heading{
    flex-wrap: nowrap !important;
  }
}


.monitor-header {
  padding-top: 2px;
}

// Section Search
.section-search {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 36px 0px 24px;
  z-index: 4;
  // pointer-events: none;

  &.monitor {
    padding-top: 10px;
    padding-right: 32px;
    padding-left: 26px;

    & p {
      line-height: 20px !important;
    }

    .section-header-btnPosition {
      margin-top: 2px;
    }
  }

  &.add-border {
    border-radius: 8px 8px 0px 0px;
  }

  @include media-breakpoint(xl) {
    padding: 14px 18px 2px 18px;
  }

  @include media-breakpoint(md) {
    padding: 12px 15px 2px 15px;
  }

  // @include media-breakpoint(sm) {
  //   padding: 12px 0px 2px 0px;
  // }

  &.class-admin-search {
    position: unset;
    z-index: unset;
  }

  &.formative-search {
    @include custom-break-poitn(450px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .section-search-field {
      @include custom-break-poitn(450px) {
        margin-bottom: 14px;
      }
    }
  }

  &.monitor {
    @include media-breakpoint(xl) {
      flex-flow: row wrap;
      align-items: center;
      gap: 10px;
    }

    @include media-breakpoint(md) {
      // padding: 14px 18px 2px 18px;
    }

    .section-header-buttons {
      @include media-breakpoint(xl) {
        margin-bottom: 16px;
      }
    }
  }

  &-monitor {
    display: flex;
    align-items: center;

    @include media-breakpoint(xl) {
      // margin-bottom: 16px;
    }
  }

  select {
    background-position: right 3px center;
    width: 53px;
    margin: 0 10px;
    height: 26px;
    line-height: 1;
    padding: 0 17px 0 5px;

    &.section-search-select-lg {
      width: 208px;
    }

    &:disabled {
      background-color: $color-white;
      opacity: 0.6;
    }
  }

  .show-classes-dropdown {
    font-size: $md - 2;
    line-height: 21px;
    font-weight: 400;
    color: $color-gray-950;

    span {
      padding-top: 1px;
    }

    .limit-selects {
      .limit-selects__wrapper {
          
        .limit-selects__control--is-focused {
          
          outline: 1px solid black !important;
        
      }
        .limit-selects__control {
          background-color: unset;
          border-color: #858585;
          padding-top: 2px;
        }
      }
    }
  }

  .limit-disabled {
    opacity: 0.6;
  }

  &-select-lg {
    width: 208px;
  }

  &-field {
    display: flex;
    align-items: center;

    @include media-breakpoint(md) {
      // margin-bottom: 20px;
    }

    &.animated {
      animation: fadeIn 800ms ease-in;
    }

    &.results-search {
      margin-left: 50px;

      @include media-breakpoint(md) {
        display: none;
      }
    }

    .form-control {
      max-width: 185px;
      padding: 4px 12px 3px 12px;
      height: 26px;
      font-size: 14px;
      font-weight: 400;
      color: #6e6e6e;
      background-color: white;
      pointer-events: auto;

      @include media-breakpoint(sm) {
        max-width: 158px;
        padding: 4px 8px 3px 8px;
        margin-right: 7px;
      }
    }

    .form-control::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #6e6e6e;
    }

    .results-input {
      max-width: 233px;
      width: 233px;
    }

    .icon {
      @include flex-box(flex-start, center);
      margin-right: 10px;

      @include media-breakpoint(sm) {
        margin-right: 10px;
      }
    }
  }

  .sec-header-dropdown {
    .table-dropdown {
      > button {
        background-image: url(../../../assets/img/drop-down-select-black.svg);
        background-repeat: no-repeat;
        background-position: 117px 11px;
        border: unset !important;
        height: 26px;
        padding-top: 4px;
      }
    }
  }

  .key-formula {
    @include flex-box(flex-end, center);
    gap: 26px;
    position: relative;

    .key-proficient {
      @include flex-box(flex-start, center);
      gap: 10px;
      opacity: 0;
      height: 0;
      transition: 0.4s all;

      .key-label {
        padding: 0px 13px 1px;
        background-color: $color-gray-500;
        border-radius: 40px;
        font-size: $sm;
        font-weight: 700;
        line-height: 17px;
        color: $color-gray-950;
      }

      .percentage {
        width: 28px;
        height: 28px;
        background-color: #ffe000;
        text-align: center;
        font-size: $sm + 1;
        font-weight: 400;
        line-height: 18px;
        color: #1e1e1e;
        @include flex-box(center, center);
        padding-top: 1px;
      }

      p {
        font-size: $sm;
        font-weight: 400;
        line-height: 19px;
        color: white;
        margin: 0;
      }
    }

    .show-key-proficient {
      opacity: 1;
      height: auto;
    }

    .formula-sheet-btn {
      @include flex-box(center, center);
      padding: 5px 11px 4px;
      border: 1px solid $theme-border-color;
      border-radius: $border-radius-md;
      height: 33px;

      img {
        width: 20px;
      }

      span {
        font-size: $md;
        font-weight: 400;
        line-height: 22px;
        color: white;
        display: inline-block;
        padding-left: 8px;
      }
    }
  }
}

.section-header-buttons.super-admin {
  .btn {
    &:focus-visible {
      outline: 2px solid black;
  }
  }
  @media only screen and (max-width: 767px) {
    width: 295px !important;
  }

  @media only screen and (max-width: 767px) {
    width: 100% !important;
  }
}

.management-accordian {
  .section-search {
    @media (max-width: 400px) {
      display: block;

      .section-search-field {
        @media (max-width: 400px) {
          margin-bottom: 12px;
        }
      }
    }

    .show-classes-dropdown {
      justify-content: end;
    }
  }
}
