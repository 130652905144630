.master-report {
  margin-top: 14px;
  @include section;

  @media print {
    box-shadow: none;
    background-color: transparent;
  }

  &-content {
    background-color: $color-gray-500;
    padding: 12px 0px 105px 0;
    border-radius: $border-radius-md;
  }
  &__info {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 55px;

    @include media-breakpoint(xl) {
      flex-direction: column;
      align-items: center;
      padding: 0 15px;
    }

    @media print {
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    }

    &-box {
      position: absolute;
      left: 55px;
      top: 22px;
      width: 100%;
      max-width: 348px;

      @include media-breakpoint(xl) {
        order: 1;
        position: relative;
        left: auto;
        top: auto;
      }

      @media print {
        position: absolute;
        left: 55px;
        top: 22px;
        padding-top: 20px;

        @include mozilla-suport {
          left: 0;
          max-width: 220px;
        }
      }

      p {
        font-size: 16px;
        color: $color-gray-850;
        line-height: 20px;
        margin-bottom: 0;

        @media print {
          @include mozilla-suport {
            font-size: 10px;
            line-height: 1.3;
          }
        }
      }
    }

    &-score {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $color-white-C7C7C7;
      border: 1px solid $color-gray-750;
      padding: 7px 19px;
      padding-bottom: 14px;

      @include media-breakpoint(xl) {
        order: 2;
        margin-left: 0;
        margin-top: 20px;
      }

      @media print {
        background-color: transparent;
        border: unset;
        margin-left: 20px;
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;

      @include media-breakpoint(md) {
        font-size: 14px;
        text-align: center;
      }

      @media print {
        @include mozilla-suport {
          font-size: 12px;
        }
      }
    }

    &-subtitle {
      font-size: 14px;

      @media print {
        @include mozilla-suport {
          font-size: 12px;
        }
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      width: 281px;

      @media print {
        @include mozilla-suport {
          font-size: 12px;
          width: 240px;
        }
      }
    }

    &-item {
      width: 16px;
      height: 16px;
      margin-right: 7px;
      margin-top: 2px;
      box-shadow: 2px 2px 1px rgb($color-black, 22%);

      @media print {
        box-shadow: unset;
      }
      &.advance {
        background-color: $color-blue-800;
        @media print {
          width: 18px;
          height: 18px;
          background-color: transparent;
          border: 1px dashed $color-blue-800;

          @include mozilla-suport {
            width: 12px;
            height: 12px;
            transform: translateY(3px);
          }
        }
      }
      &.proficient {
        background-color: $color-green-600;
        @media print {
          width: 18px;
          height: 17px;
          background-color: transparent;
          border: 1px dashed $color-green-600;

          @include mozilla-suport {
            width: 12px;
            height: 12px;
            transform: translateY(3px);
          }
        }
      }
      &.basic {
        background-color: $color-yellow-200;
        -webkit-print-color-adjust: exact;

        @media print {
          width: 18px;
          height: 15px;
          border: 1px solid $color-yellow-200;
          transform: translateY(2px);

          @include mozilla-suport {
            width: 12px;
            height: 12px;
            transform: translateY(3px);
          }
        }
      }
      &.belowBasic {
        background-color: $color-red-200;
        -webkit-print-color-adjust: exact;

        @media print {
          width: 18px;
          height: 17px;
          border: 1px solid $color-red-200;
          transform: translateY(2px);

          @include mozilla-suport {
            width: 12px;
            height: 12px;
            transform: translateY(3px);
          }
        }
      }
    }
  }
  &__search {
    max-width: 810px;
    width: 100%;
    margin: 0 auto;
    margin-top: 29px;

    @include media-breakpoint(lg) {
      padding: 0 16px;
    }

    &-bar {
      display: flex;
      input {
        width: 185px;
        font-size: 14px;
      }
    }
  }
  &-body {
    max-width: 790px;
    margin: 0 auto;
    padding-top: 17px;
    transition: width 1s ease;

    @media print {
      max-width: 920px;

      @include mozilla-suport {
        max-width: 100%;
      }
    }

    &.column-1 {
      max-width: 510px;
    }

    &.column-2 {
      max-width: 660px;

      @media print {
        max-width: 750px;
      }
    }

    &.column-4 {
      max-width: 940px;
    }

    @include media-breakpoint(lg) {
      max-width: 822px;
      padding: 17px 16px 0 16px;
    }

    @include media-breakpoint(md) {
      max-width: 800px;
      overflow-x: auto;
    }
  }

  &__selects {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @include media-breakpoint(md) {
      width: 730px;
    }

    @media print {
      @include mozilla-suport {
        justify-content: center;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    &-select {
      &:not(:first-child) {
        margin-left: 5px;
      }
      .studentTests__test__name {
        width: 106px;
        @include ellipsis;
        font-weight: bold;
        padding-left: 6px;
      }
    }
    &-classes {
      display: flex;
      align-items: center;
      width: 425px;
      justify-content: space-between;

      @media print {
        width: 550px;
        justify-content: center;
        padding-left: 10px;

        @include mozilla-suport {
          display: flex;
          max-width: 450px;
          width: 450px;
          justify-content: flex-start;
          padding: 0;
          padding-right: 15px;
        }
      }

      &.column-2 {
        @media print {
          @include mozilla-suport {
            width: 300px;
          }
        }

        .studentTests__test {
          @media print {
            @include mozilla-suport {
              transform: translateX(0);
            }
          }
        }
      }

      .studentTests__test {
        width: 145px;
        text-align: center;
        color: $color-black;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        margin-right: 5px;
        padding-bottom: 6px;

        &:last-child {
          margin-right: 0;
        }

        @media print {
          @include safari-suport {
            font-weight: 500;
          }

          @include mozilla-suport {
            transform: translateX(-10px);
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    @media print {
      &-classes {
        width: 465px;
        justify-content: flex-end;
        font-size: 10px;
      }
    }
  }
  &__table-container {
    width: 100%;
    max-height: 555px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 8px 8px 0 0;
    scrollbar-width: none;

    @include media-breakpoint(md) {
      width: 730px;
    }

    @media print {
      max-height: 100%;
      padding-bottom: 5px;
      border-bottom: 1px dashed #e6e6e6;
    }

    &.increase-height {
      max-height: 628px;
      padding-bottom: 55px;
    }
  }
  &__table {
    color: $color-dark-200;
    border: none;

    &-header {
      display: flex;
      align-items: flex-end;
    }
    &-id {
      width: 127px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      padding: 6px 0;

      @include media-breakpoint(lg) {
        width: 90px;
      }

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 18px;
      }

      @media print {
        @include mozilla-suport {
          width: 70px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &-name {
      width: 180px;
      text-align: right;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      padding: 6px 0;
      padding-left: 20px;

      @include media-breakpoint(lg) {
        width: 170px;
        padding-left: 16px;
      }

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 18px;
      }

      @media print {
        width: 230px;

        @include mozilla-suport {
          width: 150px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 37px;
      border: none !important;

      @media print {
        @include mozilla-suport {
          justify-content: center;
          height: 30px;
        }
      }

      &:nth-child(odd) {
        background-color: $color-gray;
        border-radius: 8px 0 0 8px;

        @media print {
          background-color: transparent;
        }
      }

      &:nth-child(even) {
        .master-report__table-marks td:first-child {
          border-radius: 0;
        }
      }

      @media print {
        @-moz-document url-prefix() {
          &:first-child > .master-report__table-marks {
            border-radius: 3px 8px 0 0 !important;
          }
        }
      }
    }
    &-studentId {
      width: 127px;
      height: 37px;
      border-radius: 8px 0 0 8px;
      text-align: center;
      font-size: 16px;
      line-height: 21px;
      padding: 8px 0;

      @include safari-suport {
        padding: 7px 0 9px 0;
      }

      @include media-breakpoint(lg) {
        width: 90px;
      }

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @media print {
        @include mozilla-suport {
          width: 70px;
          height: 30px;
          font-size: 10px;
          line-height: 16px;
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
    }

    &-studentName {
      width: 180px;
      height: 37px;
      font-size: 16px;
      color: $color-dark-200;
      line-height: 21px;
      padding: 8px 0 8px 20px;

      @include safari-suport {
        padding-top: 7px;
        padding-bottom: 9px;
      }

      @include media-breakpoint(lg) {
        width: 170px;
        padding-left: 16px;
      }

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @media print {
        width: 230px;
        @include mozilla-suport {
          width: 150px;
          height: 30px;
          font-size: 10px;
          line-height: 16px;
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
    }

    &-marks {
      width: 100%;
      height: 37px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $color-white;
      font-size: 16px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      &.column-1 {
        justify-content: flex-end;
      }

      &.column-2 {
        width: 353px;

        @media print {
          width: 300px;
        }
      }

      @media print {
        color: $color-brown;

        @include mozilla-suport {
          justify-content: space-between;
          max-width: 450px;
          height: 30px;
          padding-right: 15px;
        }
      }

      &__data {
        width: 147px;
        height: 37px;
        justify-content: center;
        text-align: center;
        padding: 7.5px 10.5px;
        background-color: $theme-bg-color-lighter;

        @media print {
          background-color: transparent;

          @include mozilla-suport {
            width: 140px;
            height: 30px;
            font-size: 10px;
            line-height: 16px;
            padding: 7px 0;
          }
        }

        @include safari-suport {
          padding-top: 7px;
          padding-bottom: 8px;
        }
      }

      &__no-value {
        width: 60px;
        text-align: center;

        @media print {
          @include mozilla-suport {
            display: inline-block;
            width: 50px;
          }
        }
      }
      &__value {
        width: 60px;

        @media print {
          @include mozilla-suport {
            width: 50px;
            display: inline-block;
          }
        }
      }
      &__box {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        margin-right: 7px;
        box-shadow: 2px 2px 1px rgb($color-black, 22%);
        background-color: $color-green-600;

        @media print {
          box-shadow: unset;
          @include mozilla-suport {
            margin-right: 2px;
          }
        }
        &.Proficient {
          background-color: $color-green-600;
          @media print {
            width: 17px;
            height: 18px;
            background-color: transparent;
            border: 1px dashed $color-green-600;

            @include mozilla-suport {
              width: 12px;
              height: 12px;
            }
          }
        }
        &.BelowBasic {
          background-color: $color-red-200;
          -webkit-print-color-adjust: exact;

          @media print {
            width: 18px;
            height: 18px !important;

            @include mozilla-suport {
              width: 12px;
              height: 12px !important;
            }
          }
        }
        &.Basic {
          background-color: $color-yellow-200;
          -webkit-print-color-adjust: exact;

          @media print {
            width: 18px;
            height: 19px;

            @include mozilla-suport {
              width: 12px;
              height: 12px;
            }
          }
        }
        &.Advanced {
          background-color: $color-blue-800;
          @media print {
            width: 17px;
            height: 18px;
            background-color: transparent;
            border: 1px dashed $color-blue-800;

            @include mozilla-suport {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
  &__rounded-border-top-left {
    border-top-left-radius: 4px;
  }
  &__rounded-border-top-right {
    border-top-right-radius: 4px;
  }
  &__header {
    margin-top: 18px;
    background-color: $color-gray-900;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 3px 15px 3px 15px;
    &__titles {
      h3 {
        color: $color-white;
        font-size: 22px;
      }
      h6 {
        color: $color-white;
        font-size: 16px;
      }
    }
  }
  &__results {
    width: 100%;
    background-color: $color-gray-500;
    color: $color-white;
    display: flex;
    justify-content: flex-end;
    max-height: 1024px;

    @media print {
      background-color: transparent;
      padding-right: 10px;
    }

    &.column-3 {
      @media print {
        background-color: transparent;
        transform: translateX(40px);
      }
    }

    @include media-breakpoint(md) {
      width: 730px;
    }

    &.move-up {
      transform: translateY(-55px);
      opacity: 0.94;
    }

    tbody tr {
      @media print {
        background-color: transparent;
      }

      &:nth-child(even) {
        background-color: $color-gray-700;

        @media print {
          background-color: transparent;
        }
      }
    }

    .result {
      display: flex;
      justify-content: flex-end;
      border-bottom: none;
      margin-left: auto;
      width: 100%;
      border-radius: 8px 0 8px 8px;

      @media print {
        border: unset;
        color: $color-brown;
        font-weight: bold;
        padding-right: 32px;
        margin-right: 12px;

        @include mozilla-suport {
          justify-content: flex-start;
          padding-left: 52px;
        }
      }

      &.column-2 {
        @media print {
          @include mozilla-suport {
            padding-left: 115px;
          }
        }
      }

      tbody {
        tr:first-child td:first-child {
          border-top-left-radius: 8px;
        }
        tr:last-child td:first-child {
          border-bottom-left-radius: 8px;
        }
        tr:last-child td:last-child {
          border-bottom-right-radius: 8px;
        }

        tr.background-dark {
          background-color: $color-gray-700;
        }
      }
      tr:nth-child(even) td {
        background-color: $color-gray-700;

        @media print {
          background-color: transparent;
        }
      }

      tr td {
        background-color: $color-brown-100;

        @media print {
          background-color: transparent;
        }
      }
      &__col {
        width: 145px;
        text-align: center;
        @media print {
          padding-left: 20px !important;
        }
      }
      td {
        background-color: $color-brown-100;
        height: 37px;
        border: none;
        vertical-align: middle;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;

        @media print {
          background-color: transparent;

          @include mozilla-suport {
            font-size: 10px;
            height: 28px;
          }
        }

        @include safari-suport {
          padding-bottom: 2px;
        }
      }

      &__col1 {
        width: 155px;
        text-align: end;
      }
    }
  }
  @media print {
    @-moz-document url-prefix() {
      &__results {
        margin-left: 55px !important;
      }
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .color537AB7 {
    background-color: $theme-bg-color-lighter-variant2 !important;
  }
}

tbody tr:first-child .master-report__table-marks td:first-child {
  border-top-left-radius: 8px;
}

tbody tr:first-child .master-report__table-marks td:last-child {
  border-top-right-radius: 8px;
}
