.modal {
    &__assesment {
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 16px;
        &-header {
            display: flex;
            align-items: center;
        }
        &-availablity {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 304px;
            padding: 14px 0;
            p {
                font-weight: bold;
                text-shadow: $text-shadow;
                margin-bottom: 0;
            }
        }
        &-date {
            padding: 14px 0;
            p {
                color: $color-gray-300;
                margin-bottom: 0;
            }
        }
    }
    &__table-container {
        max-width: 670px;
        width: 100%;
        height: 291px;
        margin: 0 auto;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
            background-color: rgba($color-black, 0.12);
        }
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            width: 6px;
            border-radius: 10px;
            background-color: rgba($color-black, 0.23);
            border: 2px solid rgba($modal-bg, 0.4);
        }

        &::-webkit-scrollbar:vertical {
            height: 0;
            width: 0;
            display: none;
          }
    }
    &__table {
        margin-bottom: 0;

        th {
            color: $color-white;
            padding: 0;
            padding-bottom: 5px;
        }
        &-name {
            min-width: 192px;
            width: 192px;
            text-align: right;
            padding-right: 37px !important;
        }
        &-monitor {
            min-width: 226px;
            width: 226px;
            text-align: right;
            padding-right: 29px !important;
        }
        &-edit {
            min-width: 240px;
            width: 240px;
            text-align: right;
            padding-right: 35px !important;
        }
        &-row {
            td {
                padding: 0 0 0 7px;
                font-size: 16px;
                color: $color-white;
                vertical-align: middle;
                p {
                    font-weight: 400;
                }
            }
            .name-data {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 37px;
                margin: 7.5px 0;
                p {
                    text-align: right;
                    margin-bottom: 0;
                }
            }
            .monitor-data {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 7px;
                p {
                    display: inline-block;
                    border-bottom: 1px solid $color-gray-800;
                    padding: 5px 0;
                    margin-bottom: 0;
                    box-shadow: 0 3px 0 rgba($color-black, 0.51);
                }
                input {
                    width: 70px !important;
                    display: inline-block;
                    border: none;
                    outline: none;
                    // border-bottom: 1px solid $color-gray-800;
                    padding: 3px 0 2px 0;
                    margin-bottom: 0;
                    // box-shadow: 0 3px 0 rgba($color-black, 0.51);
                    background: transparent;
                    font-size: 16px;
                    color: $color-white;
                    text-shadow: $text-shadow;

                    @include media-breakpoint(md) {
                        font-size: 14px;
                    }
                }
            }
            .edit-data {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 7px;
                p {
                    display: inline-block;
                    text-align: center;
                    border-bottom: 1px solid $color-gray-800;
                    padding: 5px 0;
                    margin-bottom: 0;
                    box-shadow: 0 3px 0 rgba($color-black, 0.51);
                }
                input {
                    width: 70px !important;
                    display: inline-block;
                    outline: none;
                    border: none;
                    // border-bottom: 1px solid $color-gray-800;
                    padding: 3px 0 2px 0;
                    margin-bottom: 0;
                    // box-shadow: 0 3px 0 rgba($color-black, 0.51);
                    background: transparent;
                    font-size: 16px;
                    color: $color-white;
                    text-shadow: $text-shadow;

                    @include media-breakpoint(md) {
                        font-size: 14px;
                    }
                }
            }
            .empty-data {
                display: block;
                width: 72px;
                text-align: center;
            }
        }
        &-inputs {
            position: relative;
            width: 72px;
            .error-circle {
                position: absolute;
                right: 1px;
                top: 8.5px;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-red;
            }
        }
    }
}