.objectives{

    &__filter{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 5px 15px 33px;
        flex-wrap: wrap;

        &-select{
            width: 248px;

            .modal__form-row {
                padding-top: 0px;

                label {
                    background-color: unset;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 19.1px;
                    color: white;
                    height: auto;
                    position: unset;
                    padding: 0;
                    text-align: left;
                    padding-left: 19px;
                    margin-bottom: 2px;
                }

                .form-select.welcome-edit-select {
                    background-image: url(../../../../assets/img/down-blue.svg);
                    background-size: 13px;
                    background-position: center right 12px;
                    background-position-y: 14px;
                    padding: 7px 40px 6px 19px !important;
                    font-size: 15px;
                    font-weight: 400;
                    color: $blue-500;
                    height: 33px;
                    border-radius: 4px;
                }
            }
        }

        .dropdownsCommon {

            .dropdownsCommon-select-box {
                
                .admin-selects__wrapper {
                    z-index: 99;
                }
            }
        }

        .searchSection {
            @include flex-box(flex-start, center);
            gap: 10px;
            width: 212px;
            padding-top: 27px;
            margin-left: auto;

            input {
                width: calc(100% - 27px);
                height: 26px;
                border-radius: $border-radius-md;
                padding: 4px 12px 3px;
                font-size: $sm;
                font-weight: 400;
                line-height: 18px;
                color: $color-gray-650;
                border: unset;
            }
        }

        .dropdownsCommon-select-box__menu{
            border-radius: 8px;
        }
    }

    &__objective{
        min-width: 275px;
        width: 275px;
        vertical-align: bottom;
        cursor: pointer;
        padding-left: 12px;
        &-data {
            text-align: right;
            padding: 9.5px 0 1px 12px !important;
            vertical-align: text-top;
            line-height: 21.82px !important;
        }
    }

    &__description{
        min-width: 638px;
        width: 638px;
        vertical-align: bottom;
        padding-left: 42px !important;

        &-data {
            vertical-align: text-top;
            padding-left: 42px !important;
            line-height: 21.82px !important;
        }
    }

    &__summative {
        min-width: 115px;
        width: 115px;
        text-align: center;
        padding-left: 29px;
        padding-right: 29px;

        &-data {
            text-align: center;
            font-weight: bold;
            vertical-align: text-top;
            padding-top: 9px !important;
            line-height: 21.82px !important;
            position: relative;

            .warning-icon {
                position: absolute;
                left: 11px;
                top: 9px;
            }
        }

        &__formative_click {
            cursor: pointer;
        }
    }

    &__options{
        min-width: 170px;
        width: 170px;
        vertical-align: bottom;

        &-data{
            vertical-align: top;

            >div {

                .fileBtn {

                    svg {
                        width: 12px;
                        height: 22px;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &__empty{
        @include flexcenter-both;
        height: 107px;
        background-color: $theme-bg-color-medium;
        border-radius: $border-radius-md;

        &-content{
            display: flex;
            align-items: center;
        }

        &-paragraph{
            font-weight: bold;
            margin: 0;
            // text-shadow: $text-shadow;
        }
    }

    &__table-row th {
        font-size: $md;
        font-weight: 700;
        line-height: 21.82px;
        vertical-align: bottom;
        padding: 0 10px 2px 10px;

        .table-dropdown {
  
            .table-dropdown-toggle {
              color: $color-blue-200 !important;
              padding-top: 2px;
              background-position-y: 10px;
              height: 26px;
              background-size: 12px;
              background-color: white !important;
              background-image: url(../../../img/drop-down-select.svg);
              border-radius: $border-radius-md;
              border: 1px solid $theme-bg-color-lighter-variant3 !important;
            }

            .table-dropdown-item.dropdown-item {
                color: $color-blue-200 !important;
                padding: 2px 11px;
            }
        }

        &:first-child {
            min-width: 40px;
            width: 40px;
            padding-left: 20px;
            padding-right: 0;
        }
    }
    &__objective-data {
  
        &.labled{
          padding-bottom: 30px;
        }
  
        &-name {
          display: block;
          margin: 6px 0;
          margin-top: 0;
          font-weight: bold;
          line-height: 21.82px;
        }
  
        &-highlight {
          display: inline-block;
          background-color: rgba($color-blue-700, 0.58);
          border-radius: $border-radius-md;
          font-size: 14px;
          line-height: 19px;
          padding: 2px 10px;
          text-shadow: 1px 1px 3px rgba($color-black, 0.52);
          font-weight: normal;
          margin-bottom: 7px;
        }
    }
}

.manage-objective-container {

    tbody {
        tr:nth-child(odd) {
        
            td {
                background-color: $theme-bg-color-dark-variant2;
            }
        }

        tr {
            td:first-child {
                border-radius: 8px 0px 0px 8px;
                display: table-cell;
                width: 40px;
                min-width: 40px;
                padding-left: 18px;
                padding-right: 0px;
            }
            td:last-child {
                border-radius: 0px 8px 8px 0px;
                display: table-cell;
            }

            td {
                padding: 9.5px 8px;
            }
        }
    }

    .management-table-container {

        .no-admin {
            height: 107px;
            background-color: $theme-bg-color-medium;
            border-radius: $border-radius-md;
            color: #FFFFFF;
        }
    }
}