// level box
.level-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  box-shadow: 2px 2px 1px rgba($color-black, 0.25);
  margin-right: 6px;

  @include safari-suport {
    transform: translateY(2px);
  }

  &-radius {
    border-radius: 40px;
  }

  &-A {
    background-color: $color-blue-800;
    transform: translateY(2px);
  }

  &-P {
    background-color: $color-green-600;
    transform: translateY(2px);
  }

  &-B {
    background-color: $color-yellow-200;
    transform: translateY(2px);
  }

  &-BB {
    background-color: $color-red-200;
    transform: translateY(2px);
  }

  &-NP {
    background-color: $color-yellow-200;
    transform: translateY(2px);
  }

  &-Proficient {
    background-color: $color-green-600;
    transform: translateY(2px);
  }

  &-Non-Proficient {
    background-color: $color-yellow-200;
    transform: translateY(2px);
  }

  &-print-A {
    border: 1px dashed $color-blue-800;
    box-shadow: unset;
    width: 18px;
    height: 18px;
    transform: translateY(2px);

    @include mozilla-suport {
      width: 12px;
      height: 12px;
    }
  }

  &-print-P {
    border: 1px dashed $color-green-600;
    box-shadow: unset;
    width: 18px;
    height: 18px;
    transform: translateY(2px);

    @include mozilla-suport {
      width: 12px;
      height: 12px;
    }
  }

  &-print-B {
    border: 1px solid $color-yellow-200;
    box-shadow: unset;
    width: 18px;
    height: 18px;
    transform: translateY(2px);

    @include mozilla-suport {
      width: 12px;
      height: 12px;
    }
  }

  &-print-BB {
    border: 1px solid $color-red-200;
    box-shadow: unset;
    width: 18px;
    height: 18px;
    transform: translateY(2px);

    @include mozilla-suport {
      width: 12px;
      height: 12px;
    }
  }

  &-print-NP {
    border: 1px solid $color-yellow-200;
    box-shadow: unset;
    width: 18px;
    height: 18px;
    transform: translateY(2px);

    @include mozilla-suport {
      width: 12px;
      height: 12px;
    }
  }
}

// Status
.status {
  display: flex;
  align-items: center;
  margin-left: 28px;

  @include media-breakpoint(xl) {
    order: 3;
    width: 100%;
    margin-left: 10px;
    // margin-bottom: 5px;
  }

  @media (max-width: 390px) {
    gap: 8px;
    margin-left: 0;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-right: 18px;

    @media (max-width: 390px) {
      margin-right: 0;
    }

    .form-check-input {
      background-color: transparent;
      width: 26px;
      height: 26px;
      border: 1px solid transparent;
      border-radius: 50%;
      margin: 0;
      margin-right: 6px;
      // box-shadow: 2px 2px 1px rgba($color-black, 0.22);

      @include media-breakpoint(md) {
        width: 24px;
        height: 24px;
      }

      &:checked[type="radio"] {
        background-image: url(../../../img/check-good.png);
        background-size: 16px;
        background-position: center;
      }

      &.offline {
        background-color: #686868;
        border-color: #686868;
      }

      &.online {
        background-color: $color-green;
        border-color: $color-green;
      }

      &.submitted {
        background-color: $color-yellow;
        border-color: $color-yellow;
      }
    }

    &-label {
      font-size: 14px;
      font-weight: 700;
    }
  }

  &-alert {
    background-color: rgba($color-yellow, 0.78);
    border-radius: $border-radius-md;
    padding: 6px 15px;
    box-shadow: 0 3px 6px rgba($color-black, 0.56);

    @include safari-suport {
      padding: 4px 12px 8px 12px;
    }

    @include media-breakpoint(md) {
      padding: 4px 12px;
    }

    @include media-breakpoint(sm) {
      padding: 4px 8px;
    }

    p {
      font-size: 18px;
      font-weight: bold;
      color: $color-black;
      line-height: 21px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 16px;
      }

      @include media-breakpoint(sm) {
        font-size: 14px;
      }

      span {
        @media only screen and (max-width: 1240.99px) {
          display: none;
        }
      }
    }
  }
}

// Progress Ratings
.progress__ratings {
  display: flex;
  align-items: center;
  gap: 7px;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 1px;

  li {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0;

    a,
    div {
      display: block;
      text-decoration: none;
      text-align: center;
      font-family: $font-cabin;
      font-size: 14px;
      line-height: 17px;
      color: $color-white;
      width: 100%;
      height: 100%;
      @include flex-box(center, center);

      &.high_rating {
        background-color: $color-green;
      }

      &.average_rating {
        background-color: $color-gray-800;
      }

      &.low_rating {
        background-color: rgba($color-red, 0.29);
      }

      &.bordered {
        border-color: $color-white;
      }

      &.no-answer {
        height: 23px;
      }

      &.active {
        border: 1px solid $color-white;
      }
    }
  }
}

.progress__ratings-large {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  font-family: $font-cabin;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 1.5px;

    a,
    div {
      display: block;
      height: 11px;
      text-decoration: none;
      text-align: center;
      color: $color-white;

      &.high_rating {
        background-color: $color-green !important;
      }

      &.average_rating {
        background-color: $color-gray-800;
      }

      &.low_rating {
        background-color: rgba($color-red, 0.29);
      }
      &.bordered {
        border-color: $color-white;
      }

      &.no-answer {
        height: 23px;
      }

      &.active {
        border: 1px solid $color-white;
      }
    }
  }
}

// Read Progress modal
.read__progress {
  &-slider {
    display: flex;
    align-items: center;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 39px;
    padding: 1px 3px;
    cursor: pointer;

    span {
      display: inline-block;
      width: 20px;
      text-align: center;
      font-size: 14px;
      line-height: 11px;
      color: $color-white;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    li {
      display: inline-block;
      background-color: $color-gray-800;
      width: 11px;
      height: 11px;
      margin: 1px;

      a {
        display: block;
        width: 11px;
      }
    }
  }

  &-resultList {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 3px;
      width: 24px;
      height: 24px;
      text-align: center;
      font-size: 14px;
      color: $color-white;
      line-height: 24px;
      cursor: default;

      &.active {
        border: 1px solid $color-white;
      }
    }
  }

  &-paragraph {
    margin: 18px 0;
    padding-left: 3px;
    line-height: 20px;
  }

  &-showCase {
    padding: 0 24px;
  }

  &-showList {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    width: 338px;
    padding: 0;
    margin: 0 auto;

    li {
      display: inline-block;
      background-color: $color-green;
      width: 11px;
      height: 11px;
      margin: 1px;

      &.fail-bg {
        background-color: rgba($color-red, 0.29);
      }

      &.average-bg {
        background-color: $color-gray-800;
      }

      &.active {
        border: 1px solid $color-white;
      }
    }
  }
}
