.SupportTicketDetails {
  &__header {
    padding: 9px 16px;
    padding-right: 35px;
    .assignBtn {
      width: 105px;
    }

    .attach-chat {
      width: 100% !important;
      max-width: 180px;
    }
    .chatHistoryBtn {
      width: 143px;
    }
  }

  &__infoTable {
    @media (max-width: 1180px) {
      width: 1220px;
      overflow: auto;
    }

    &__header {
      color: #fff;
      font-weight: 700;
      font-size: 16px;

      tr {
        height: 30px;

        .detailsColumn {
          width: 475px;
          min-width: 475px;
          padding-left: 28px !important;

          @media (max-width: 767px) {
            width: 350px;
            min-width: 350px;
          }
        }

        .admin-info {
          width: 220px;
          min-width: 220px;
        }

        .check-status {
          width: 275px;
          min-width: 275px;
        }

        .assign-to {
          width: 200px;
          min-width: 200px;
        }
      }
    }

    &__body {
      height: 107px;

      th {
        border-top: none; /* Remove border for top row */
        border-bottom: 1px solid #ddd; /* Add border for header cells */
      }

      tr:first-child td:first-child {
        border-top-left-radius: 8px; /* Top-left corner */
      }

      tr:first-child td:last-child {
        border-top-right-radius: 8px; /* Top-right corner */
      }

      tr:last-child td:first-child {
        border-bottom-left-radius: 8px; /* Bottom-left corner */
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 8px; /* Bottom-right corner */
      }

      tr {
        .detailsColumn {
          width: 445px;
          min-width: 445px;
          padding-left: 28px !important;

          @media (max-width: 767px) {
            width: 350px;
            min-width: 350px;
          }

          span {
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            color: #424242;
          }

          .ticket-details-column-text {
            width: 320px;
            .deleteBtn {
              display: flex;
              justify-content: center;
              align-items: center;

              .btn {
                outline: none;
                border: none;
                height: 18px;
                font-size: 12px;
                border-radius: 40px;
                margin-left: 8px;
                background-color: #c44538;
                min-width: 100px;
                padding: 0px;
                margin-right: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white !important;
                font-weight: 400;
                padding-top: 1px;
                font-family: "Nunito", sans-serif;

                &:hover {
                  color: white;
                }
              }
            }
          }

          p {
            width: 320px;
            text-align: left;
            font-weight: 400;
            text-transform: capitalize;
          }
        }

        .admin-info {
          width: 220px;
          min-width: 220px;

          .admin-info-w {
            width: 191px;
          }
        }

        .check-status {
          width: 275px;
          min-width: 275px;
        }

        .assign-to {
          width: 200px;
          min-width: 200px;
        }

        td {
          background-color: #b9b9b9;
          p {
            color: #424242;
            font-weight: 700;
            width: 191px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: 3s all;
          }

          .ellipsis-animation:hover {
            animation: ellipsis-animation 10000ms ease;
            animation-delay: 3s;
          }

          .tags {
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            color: white;
            padding: 2px 12px;
            background-color: #919191;
            border-radius: 40px;
            margin: 0 auto;
            margin-top: 12px;
            width: auto;
            max-width: 121px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }

          .resolved {
            width: 82px;
          }

          .active {
            background-color: #46afb8;
            margin: 0 auto;
            margin-top: 12px;
          }
        }
      }
    }
  }

  &__detailedInfo {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    &__messages {
      width: 49.11%;
      max-height: 272px;
      min-height: 272px;
      border-radius: $border-radius-md;
      overflow: hidden;

      @media (max-width: 1200px) {
        width: 100%;
        margin-top: 20px;
      }

      .messageTable {
        max-height: 215px;
        overflow: auto;

        @media (max-width: 576px) {
          width: 600px;
          overflow: auto;
        }
      }

      .messageTable::-webkit-scrollbar {
        display: none;
      }

      .messages-history {
        .SupportTicketDetails__detailedInfo__messages__table {
          .SupportTicketDetails__detailedInfo__messages__table__header {
            .SupportTicketCircle {
              width: 45px;
            }

            .column1 {
              padding-left: 0px;
            }
          }

          .SupportTicketDetails__detailedInfo__messages__table__body {
            .bullet-column {
              width: 45px;
              padding-left: 10px;
              vertical-align: middle;

              .redCircle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #c44538;
                margin: auto;
              }
            }

            .column1 {
              padding-left: 0px;
            }
          }
        }
      }

      &__header {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        padding: 0 25px 0 23px;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
          padding: 0 16px;
          padding-right: 35px;
        }

        @media (max-width: 576px) {
          padding: 12px;
          height: auto;
          flex-direction: column;
        }

        h2 {
          color: #ffffff;
          font-size: 22px;
          font-weight: 700;
          line-height: 26px;
          font-family: $font-openSans;
        }

        .section-search-field {
          .icon {
            margin-right: 9.52px;
          }

          .form-control {
            padding: 2px 12px;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      &__table {
        &__header {
          height: 30px;
          color: #ffffff;
          font-weight: 700;

          tr {
            height: 30px;
          }
        }

        .maintableRow {
          color: #ffffff;
          height: 37px;

          td {
            background-color: #424242 !important;
          }
        }

        .maintableRow:first-child {
          border-radius: 8px 8px 0px 0px;

          td:first-child {
            border-radius: 8px 0px 0px 0px;
          }

          td:last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }

        &__body {
          tr:nth-child(even) {
            background-color: #adadad;
          }

          // tr:last-child {

          //     td:first-child {
          //         border-radius: 0px 0px 0px 8px;
          //     }

          //     td:last-child {
          //         border-radius: 0px 0px 8px 0px;
          //     }
          // }

          .bodyRow {
            color: #424242;
            background-color: #b9b9b9;
            height: 37px;

            .column1 {
              p {
                color: #424242 !important;
              }
            }
          }

          .bodyRow:last-child {
            border-radius: 0px 0px 8px 8px;

            td:first-child {
              border-radius: 0px 0px 0px 8px;
            }

            td:last-child {
              border-radius: 0px 0px 8px 0px;
            }
          }
        }

        .column1 {
          padding-left: 41px;
          max-width: 200px;
        }
      }

      .historyTable {
        &__body {
          tr:nth-child(odd) {
            td {
              background-color: $theme-bg-color-darker;
            }
            td:first-child {
              border-top-left-radius: 8px; /* Top-left corner */
              border-bottom-left-radius: 8px;
            }
            td:last-child {
              border-top-right-radius: 8px; /* Top-left corner */
              border-bottom-right-radius: 8px; /* Top-left corner */
            }
          }

          tr:nth-child(even) {
            background-color: transparent;
          }

          .bodyRowHistory {
            color: #ffffff;
            height: 37px;
          }

          .maintableRowHistory {
            background-color: #424242;
            color: #ffffff;
            height: 37px;
          }

          .tags {
            font-size: 14px;
            padding: 2px 12px;
            border-radius: 40px;
            text-align: center;
          }

          .Subbmitted {
            background-color: #eadb4a;
            width: 89px;
            color: #424242;
          }

          .Resolved {
            background-color: #46afb8;
            width: 82px;
          }

          .NeedMoreInfo {
            background-color: #c44538;
            width: 140px;
          }

          .assigned {
            background-color: #424242;
            width: 165px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .InProgress {
            background-color: #194792;
            width: 103px;
          }

          .notfound__data-container {
            height: 183px;
          }
        }
      }
    }

    scrollbar-width: 3px;

    ::-webkit-scrollbar-track {
      background-color: rgba($color-black, 0.2);
    }

    ::-webkit-scrollbar {
      width: 3px;

      @media print {
        display: none;
      }
    }

    ::-webkit-scrollbar-thumb:vertical {
      width: 3px;
      border-radius: 10px;
      background-color: rgba($color-black, 0.23);
      border: 2px solid rgba($color-gray-500, 0.5);
    }

    ::-webkit-scrollbar:horizontal {
      height: 2px;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      height: 2px;
      background-color: rgba($color-black, 0.23);
    }
  }

  &__comment {
    margin-top: 36px;
    .section-search {
      width: 388px;
      position: relative;
      z-index: auto;

      @media (max-width: 670px) {
        margin-top: 15px;
      }

      @media (max-width: 480px) {
        width: 100%;
      }

      button {
        @media (max-width: 480px) {
          margin-bottom: 20px;
        }
      }
    }

    .header {
      border-bottom: 1px solid $theme-border-color;
      height: 60px;
      padding: 5px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 35px;
      margin-top: 22px;

      @media (max-width: 768px) {
        padding: 5px 16px;
        padding-right: 35px;
      }

      @media (max-width: 670px) {
        flex-direction: column;
        height: auto;
        padding: 12px;
      }
    }

    &__tablebody {
      .name {
        padding: 8px 8px 8px 16px;
        vertical-align: top;

        @media (max-width: 768px) {
          padding-left: 12px;
        }
      }

      .info {
        padding: 9px 16px 9px 8px;
      }
    }
    &__button {
      border: 1px solid white;
      border-radius: $border-radius-md;
      height: 33px;
      width: 143px;

      @media (max-width: 575px) {
        width: 100%;
        margin-top: 10px;
      }
    }

    &__container {
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;
      margin-top: 10px;

      &__table {
        tr:nth-child(odd) {
          td {
            background-color: $theme-bg-color-darker;
          }

          td:first-child {
            border-top-left-radius: 8px; /* Top-left corner */
            border-bottom-left-radius: 8px;
          }
          td:last-child {
            border-top-right-radius: 8px; /* Top-left corner */
            border-bottom-right-radius: 8px; /* Top-left corner */
          }

          .emptySpace {
            background-color: #537ab7;
          }
        }

        tr {
          td {
            p {
              margin-bottom: 0px;
            }
          }
        }

        &__name {
          padding: 9px 8px 9px 15px;
          vertical-align: top;
          width: 130px;
          min-width: 130px;

          @media (max-width: 575px) {
            padding: 12px 20px;
          }

          .initials {
            height: 42px;
            border-radius: $border-radius-md;
            width: 46px;
            background-color: #c44538;

            p {
              font-size: 18px;
              font-weight: 400;
              line-height: 24px;
              color: white;
            }
          }

          p {
            font-size: 24px;
          }

          .replayTd {
            background-color: transparent !important;
            margin-right: 15px;

            .emptySpace {
              height: 20px;
              width: 20px;
              background-color: $theme-bg-color-lighter;
              opacity: 0.5;
              border-radius: 50%;
            }
          }
        }

        &__info {
          width: 95%;
          padding: 9px 15px 9px 7px;

          @media (max-width: 575px) {
            padding: 12px;
          }

          .basic-info-color {
            color: #d1d1d1;
          }

          .basic-info {
            font-size: 14px;
            line-height: 19px;
          }

          .comment {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: white;
            white-space: pre-wrap;
            word-break: break-word;
          }
        }
      }
    }
  }

  .headerButtons {
    @media (max-width: 576px) {
      width: 100%;
    }
  }
}
