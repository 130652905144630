.breadcrumb {
  display: flex;
  padding: 0 8px;
  margin-top: 22px;

  @include media-breakpoint(md) {
    padding: 0;
    margin-top: 10px;
  }

  &__item {
    position: relative;
    height: 30px;
    padding: 4px 12px;
    margin-right: 9px;
    cursor: pointer;

    @include safari-suport {
      padding-top: 3px;
      padding-bottom: 8px;
    }

    @include media-breakpoint(md) {
      margin-top: 12px;
    }

    @include media-breakpoint(sm) {
      padding: 4px 8px;
    }

    p {
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 0;

      @include media-breakpoint(sm) {
        font-size: 12px;
      }
    }

    &.primary {
      background-color: $theme-bg-color-light-variant2;
      border-radius: 8px 0 0 8px;

      &::after {
        content: "";
        position: absolute;
        right: -19px;
        top: 0;
        border-top: 15px solid transparent;
        border-left: 19px solid $theme-border-color-light;
        border-bottom: 15px solid transparent;
        z-index: 2;
        height: 100%;

        @include mozilla-suport {
          right: -18px;
        }

        @media (min-width: 1800px) {
          border-left: 20px solid $theme-border-color-light;
        }
      }
    }

    &.secondary {
      background-color: $theme-bg-color-lighter;
      margin-left: -2px;
      padding-left: 26px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        border-top: 15px solid transparent;
        border-left: 19px solid $theme-bg-color;
        border-bottom: 15px solid transparent;
        z-index: 1;
        height: 100%;
      }
      &::after {
        content: "";
        position: absolute;
        right: -19px;
        top: 0;
        border-top: 15px solid transparent;
        border-left: 19px solid $theme-border-color-lighter;
        border-bottom: 15px solid transparent;
        z-index: 2;
        height: 100%;

        @include mozilla-suport {
          right: -18px;
        }
      }
    }
  }
}
