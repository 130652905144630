.auditStudentReport {
    @include section;
    margin-top: 22px;

    @media print {
        margin: 0;
        box-shadow: unset;
        border-radius: 0;
    }

    &__mainContainer {
        background-color: $color-gray-500;
        padding-top: 18px;

        @media print {
            background-color: transparent;
            height: 1599px;

            @include safari-print {
                height: 1449px;
            }

            @include mozilla-suport {
                height: 920px;
            }
        }
    }

    &__center {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 37px;
        padding: 0 20px;

        @include media-breakpoint(lg) {
            margin-bottom: 15px;
        }

        @include media-breakpoint(md) {
            flex-direction: column;
            align-items: center;
            padding: 0 16px;
        }

        @media print {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 28px;

            @include mozilla-suport {
                margin-bottom: 8px;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media print {
                background-color: transparent;
                border-color: transparent;
                position: relative;
            }
        }
    }

    &__search {
        position: absolute;
        left: 20px;
        top: 0;
        width: 220px;

        @include media-breakpoint(md) {
            order: 2;
            position: relative;
            left: unset;
        }

        &-input {
            width: 185px;
            outline: none;
            border-radius: $border-radius-md;
            border: none;
            padding-left: 12px;
            height: 26px;
            font-size: 14px;
        }
    }

    &__centerParagraph {
        &__total-students {
            width: 120px;
            height: 116px;

            @include media-breakpoint(md) {
                width: 100px;
                height: 100px;
            }

            @media print {
                @include mozilla-suport {
                    width: 75px;
                    height: 80px;
                }
            }

            &__numbers {
                font-size: 32px;
                font-weight: bold;
                line-height: 41px;
                margin-bottom: 0;

                @include media-breakpoint(md) {
                    font-size: 28px;
                    line-height: 36px;
                }

                @include media-breakpoint(sm) {
                    font-size: 24px;
                    line-height: 32px;
                }

                @media print {
                    color: $color-gray-900;

                    @include mozilla-suport {
                        font-size: 20px;
                        line-height: 1.4;
                    }
                }
            }
            &__text {
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                text-shadow: $text-shadow;

                @include media-breakpoint(md) {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media print {
                    color: $color-gray-900;
                    text-shadow: unset;

                    @include mozilla-suport {
                        font-size: 12px;
                        line-height: 1.4;
                    }
                }
            }
        }
    }

    &__warning {
        width: 239px;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;

        img {
            @media print {
                @include mozilla-suport {
                    width: 16px;
                    height: auto;
                }
            }
        }

        &__text {
            padding-left: 4px;
            color: $color-dark;

            @media print {
                @include mozilla-suport {
                    font-size: 10px;
                    line-height: 1.4;
                }
            }
        }

        @media print {
            justify-content: center;
            color: $color-black;
        }
    }

    &__table {
        @include media-breakpoint(md) {
            width: 692px;
            margin: auto;
        }

        .licensed-data {
            padding-left: 52px;
        }

        thead {
            position: sticky;
            background: $color-gray-500;
            top: 0;
            z-index: 99;

            @media print {
                background-color: transparent;
            }

            th {
                border-radius: 0;
                vertical-align: bottom;
                font-size: 16px;
                color: $color-black;
                line-height: 20px;
                padding-bottom: 5px;

                @include media-breakpoint(md) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media print {
                    @include mozilla-suport {
                        font-size: 10px;
                        line-height: 1.5;
                    }
                }
            }
        }

        tbody {
            tr {
                border-radius: $border-radius-md;

                &:nth-child(odd) td {
                    background-color: $color-gray;

                    @media print {
                        background-color: transparent;
                    }
                }

                td {
                    font-size: 16px;
                    line-height: 21px;
                    padding: 8px 10px;

                    @include media-breakpoint(md) {
                        font-size: 14px;
                        line-height: 18px;
                    }

                    @media print {
                        @include mozilla-suport {
                            font-size: 10px;
                            line-height: 1.5;
                            padding: 4px 10px;
                        }
                    }
                }

                .report__table-notFound {
                    border-radius: $border-radius-md;
                }
            }
        }
        &__row {
            &__studenttName {
                width: 206px;
                max-width: 206px;
                text-align: right;
                border-radius: 8px 0 0 8px;
                padding-left: 16px !important;
                padding-right: 0 !important;

                @media print {
                    width: 400px;
                    max-width: 400px;
                }

                img {
                    @media print {
                        width: 286px;
                        max-width: 286px;
                        @include mozilla-suport {
                            max-width: 12px;
                            height: auto;
                        }
                    }
                }
            }

            &__studentId {
                width: 203px;
                padding-left: 50px !important;

                @media print {
                    @include mozilla-suport {
                        width: 190px;
                        padding-left: 50px !important;
                    }
                }
            }

            &__assessment {
                width: 102px;
                text-align: center;

                @media print {
                    @include mozilla-suport {
                        width: 82px;
                    }
                }

                img {
                    @media print {
                        @include mozilla-suport {
                            width: 7px;
                            height: auto;
                        }
                    }
                }
            }
            &__lastTestDate {
                width: 181px;
                text-align: center;
                padding-right: 44px;
                padding-left: 44px;
                border-radius: 0 8px 8px 0;

                @media print {
                    @include mozilla-suport {
                        width: 160px;
                        padding-right: 30px;
                        padding-left: 30px;
                    }
                }
            }
            .fontBold {
                font-weight: 700;
            }
        }
    }
    &__overflow {
        overflow-y: auto;
        width: 692px;
        max-height: 800px;
        scrollbar-width: none;

        @include media-breakpoint(md) {
            width: 100%;
            overflow: auto;
        }

        @media print {
            width: 772px;
            overflow: visible;
            height: unset;
            max-height: unset;

            @include mozilla-suport {
                width: 520px;
                height: auto;
                max-height: auto;
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.warning-icon-print img {
    @media Print {
        height: 20px !important;
        width: 20px !important;
    }
}

.centerData {
    background-color: $color-blue-100;
    width: 239px;
    border: 1px solid $color-gray-700;

    @include media-breakpoint(md) {
        width: 200px;
    }

    @media print {
        background-color: transparent;
        border: none;

        @include mozilla-suport {
            width: 150px;
        }
    }
}
