header.header {
    
    @include media-breakpoint(lg) {
        padding-bottom: 10px;
        overflow: hidden;
        @include media-breakpoint(lg) {
             overflow: unset;
         }
    }
}

.mian_header.radious {
    border-radius: 8px 8px 0px 0px;
}

.container-xl{
    .header{
        .header-container{
            .subHeader-container  {
                .subHeader{
                        .header__dropdown-itemClass{
                            span{
                                small{
                                    // position: relative;
                                    // top: -2px
                                }
                            }
                        }
                    }
                }
            }
        }
}

.mian_header {
    @include flex-box(space-between, center);
    background-color: $yellow-200;
    padding: 11px 32px 9px 28px;
    border-radius: $border-radius-md;
    position: relative;
    transition: all 0.3s linear;
    min-height: 82px;

    @include media-breakpoint(lg) {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        padding: 0px 15px 15px;
        height: 75px;
        overflow: hidden;
        min-height: auto;

        @include media-breakpoint(sm) {
            gap: 14px;
            justify-content: flex-start;
            height: 62px;
        }
    }

    &.classroom-admin-radius {

        @media (max-width: 1012px) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .logo {
        svg {
            width: 243px;
        }

        @media (max-width: 767px) {

            img, svg {
                width: 210px;
            }
        }

        @include media-breakpoint(lg) {
            width: 100%;
            background-color: $yellow-200;
            position: relative;
            z-index: 10;
            padding-top: 14px;
            
            @include media-breakpoint(sm) {
                padding-top: 7px;
            }

            img {
                @include media-breakpoint(sm) {
                    width: 170px;
                }
            }
        }
    }

    .district-logo {
        margin-top: -2px;
        margin-left: 10px;
        text-align: center;
    }

    .nav-dropdown-sec {
        @include flex-box(flex-end, center);
        width: calc(100% - 250px);

        @include media-breakpoint(lg) {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            position: relative;
            top: -400px;
            transition: all 0.3s linear;
            opacity: 0;
            z-index: -1;
        }

        @include media-breakpoint(sm) {
            gap: 20px;
            
        }
    }

    .header-links {
        @include flex-box(center, center);
        cursor: pointer;
        gap: 10px;
        padding: 3px 13px 3px 15px;
        border-radius: 40px;
        height: 33px;
        z-index: 10;
        border: unset;
        background-color: unset;

        @include media-breakpoint(sm) {
            justify-content: flex-start;
            margin-bottom: 5px;
            padding: 3px 8px 2px 10px;
            width: 100%;
        }

        svg {
            margin-top: -2px;
        }
    
        .none-active {
            display: block;
        }
    
        .active {
            display: none;
        }

        p {
            font-size: $lg;
            font-weight: 400;
            line-height: 24.55px;
            color: $color-blue-700;
            margin-bottom: 0;

            @media (max-width: 1040px) {
                font-size: 15px;
            }

            @include media-breakpoint(sm) {
                padding-top: 2px;
            }
        }
    }
    
    .header-links.active {
        background-color: $theme-bg-color-dark;
        
        .none-active {
            display: none;
        }
    
        .active {
            display: block;
        }

        p {
            color: white;
        }
    }
    nav {
        @include flex-box(center, center);
        gap: 10px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: auto;
        width: 100%;
        max-width: 456px;
        margin: 0 auto;

        @include media-breakpoint(lg) {
            -webkit-order: 3;
            width: 100%;
            justify-content: center;
            position: relative;
        }

        @include media-breakpoint(sm) {
            display: block;
            max-width: 100%;
            margin: 0;
            position: unset;
        }
    }
    
    .header-dropDown {
        @include flex-box(flex-start, center);
        gap: 8px;
        cursor: pointer;
        width: 100%;
        border: unset;
        background: unset;
        padding: 0;

        .user-name-initials {
            width: calc(100% - 28px);
            @include flex-box(flex-start, center);

            .initials {
                width: 46px;
                height: 46px;
                border-radius: $border-radius-md;
                @include flex-box(center, center);
                background-color: $blue-200;
                position: relative;
                font-size: $lg;
                font-weight: 400;
                line-height: 24.55px;
                color: white;

                span {
                    position: absolute;
                    top: -7px;
                    left: -7px;
                    background-color: #C44538;
                    width: 22.855px;
                    height: 22.855px;
                    border-radius: 50%;
                    font-size: 12px;
                    font-weight: 400;
                    color: white;
                    @include flex-box(center, center);
                    padding-top: 2px;
                }
            }
            .userName-classroomAdmin {
                width: calc(100% - 46px);
                padding-left: 8px;
                
                .user-name {
                    font-size: $lg;
                    font-weight: 400;
                    line-height: 24.55px;
                    color: $blue-500;
                    margin-bottom: 0;
                    text-align: left;

                    @include media-breakpoint(lg) {
                        line-height: 15px
                    }
                }
                
                .admin {
                    font-size: $sm;
                    font-weight: 400;
                    line-height: 19.1px;
                    color: $blue-500;
                    margin-bottom: 0;
                    text-align: left;
                }
            }
        }
    }

    .dropDown-container {
        position: relative;
        width: 246px;

        @include media-breakpoint(lg) {
            -webkit-order: 2;
            width: 100%;
            border: 1px solid $color-blue-100;
            padding: 6px;
            border-radius: $border-radius-md;
        }

        .loginUser-dropDown {
            position: absolute;
            top: 75px;
            left: 0;
            width: 100%;
            background-color: $black-transparent;
            border-radius: $border-radius-md;
            color: #fff;
            box-shadow: 0 3px 12px rgba(0, 0, 0, 0.22);
            z-index: -1;
            transition: all linear 0.3s;
            padding: 6px 12.45px;
            opacity: 0;
            pointer-events: none;

            @include media-breakpoint(lg) {
                position: absolute;
                top: 160px;
                left: 0;
                right: 0;
                width: 100%;
                margin: 0 auto;
            }

            .dropDown-menu {
                color: #fff;
                transition: all 0.3s;
                cursor: pointer;
                font-size: $md;
                font-weight: 400;
                padding: 4px 5px;
                display: block;
                width: 100%;
                @include flex-box(flex-start, center);
                gap: 0 10px;

                &:hover {
                    background-color: $theme-bg-color-lighter;
                }

                &:focus-visible {
                    background-color: $theme-bg-color-lighter;
                }

                &:last-child {
                    padding-top: 8px;
                }

                .notification-count {
                    background-color: #C44538;
                    width: 22.855px;
                    height: 22.855px;
                    border-radius: 50%;
                    font-size: 12px;
                    font-weight: 400;
                    color: white;
                    @include flex-box(center, center);
                    padding-top: 1px;
                }

                .pin-color {
                    padding: 3px 6px;
                    border-radius: 40px;
                    background-color: $yellow-200;
                    font-size: 12px;
                    font-weight: 400;
                    color: black;
                    @include flex-box(center, center);
                    transition: all 0.3s;

                    &:hover {
                        background-color: unset;
                        color: white;
                    }
                }

                &.borderBottom {
                    border-bottom: 1px solid #ffffff29;
                    padding-bottom: 8px;
                }
            }

            .colorPanelContainer{
                padding: 4px 5px 8px;
                border-bottom: 1px solid #ffffff29;
                width: 100%;
                margin: 0 auto;

                @include media-breakpoint(lg) {
                    max-width: 98%;
                }

                p{
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 16px;
                    font-family: $font-openSans;
                }
                .colorPanel {
                    @include flex-box(flex-start, center);
                    gap: 0 14px;
                    margin-top: 8px;
    
                    // @include media-breakpoint(lg) {
                    //     justify-content: center;
                    // }
    
                    .colorBox {
                        cursor: pointer;
                        width: 31px;
                        height: 31px;
                        border: 1px solid #979797;
                        border-radius: 5px;
    
                    }
                }
            }
        }

        .show-dropdown {
            top: 56px;
            opacity: 1;
            z-index: 99999;
            pointer-events: all;

            @include media-breakpoint(lg) {
                top: 71px;
            }
        }
    }

    .navBar-toggler-btn {
        width: 42px;
        height: 34px;
        border: 1px solid $theme-border-color;
        border-radius: $border-radius-md;
        @include flex-box(center, center);
        flex-direction: column;
        gap: 6px;
        position: absolute;
        top: 21px;
        right: 15px;
        padding: 6px 10px;
        display: none;

        @include media-breakpoint(lg) {
            display: flex;
            z-index: 20;
        }

        @include media-breakpoint(sm) {
            width: 35px;
            height: 27px;
            padding: 4px 8px;
            top: 17px;
            gap: 4px;
        }

        span {
            display: block;
            width: 100%;
            height: 1px;
            background-color: black;
        }
    }
}

.school-admin-mng{
    border-radius: 8px !important;
    @media (max-width:787px){
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .nav-dropdown-sec{
        .header__top-arrow-animation{
            &::after{
                left: 114px;

                @media (min-width:792px) and (max-width: 991px){
                    left: 103px;
                    bottom: -19.5px;
                }

                @media (min-width:991px) and (max-width: 1439px){
                    left: 97px;
                }
                
                @media (max-width: 791px){
                    bottom: -19.5px;
                }
            }
        }
    }
}

.school-admin-activity{
    border-radius: 8px;
    @media (max-width:787px){
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    } 
}

.super-admin-mng {
    border-radius: 8px;
}

.classroom-admin-mng{
    border-radius: 8px !important;
}

.classroomAdminBorder {
    @media (max-width:880px){
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    }
}
.districtAdminBorder  {
    @media (max-width:787px){
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    }
}

.header-open {
    height: 200px;
    overflow: unset;

    @include media-breakpoint(sm) {
        height: 278px;
    }

    .nav-dropdown-sec {
        top: 0;
        opacity: 1;
        z-index: 10;
    }
}

.subHeader-container {
    text-align: center;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
}

.subHeader {
    @include flex-box(flex-start, flex-start);
    display: inline-flex;
    background-color: $color-gray-500;
    border-radius: 0px 0px 8px 8px;
    padding: 10px 22px 10px 21px;
    gap: 0 12px;

    @include media-breakpoint(lg) {
        padding-right: 15px;
    }

    @include media-breakpoint(md) {
        display: block;
    }

    .headerLeft {
        @include flex-box(flex-start, flex-start);
        width: auto;
        max-width: 100%;
        gap: 10px 11px;

        @include media-breakpoint(md) {
            width: 100%;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .last-assessment {

            .subHeader-fieldLabel {
                height: 20.6px;
            }

            .lastAssessment-date {
                padding-top: 11px;
            }
        }
    }

    .admin-dropDown {
        // width: 232px;

        .drop-down {
            width: 100%;

            .header__dropdown-item {
                width: 100%;
                margin: 0;
                list-style-type: none;
            }

            .header__dropdown-wrapper {
                gap: 16px;
                list-style-type: none;

                .header__dropdown-item {
                    width: 232px;
                    text-align: left;
                    margin: 0;
                    z-index: unset;

                    @include media-breakpoint(md) {
                        width: 210px;
                    }

                    @include media-breakpoint(sm) {
                        width: 100%;
                    }

                    small {
                        font-size: $sm;
                        font-weight: 700;
                        line-height: 19.1px;
                        color: $color-gray-900;
                        padding-left: 18px;
                        opacity: 1;
                        position: relative;
                        top: -2px;
                    }

                    small.disabled {
                        opacity: 0.5;
                    }

                    .dropdown-diabled {
                        border-color: #9d9d9d;
                        border: 0px;
                        height: 41px;
                        border-radius: 0;
                        z-index: 99;
                    }

                    .react-select__control {
                        background-color: $color-gray-500;
                        opacity: 1;
                        padding-top: 2px;
                        padding-left: 7px;
                        box-shadow: unset;
                        border-radius: 8px;
                        border: 1px solid #9d9d9d;
                        height: 100%;

                        .react-select__single-value {
                            font-size: $md;
                            font-weight: 400;
                            line-height: 21.82px;
                            color: $color-gray-950;

                            .dropDown-chip {
                                position: absolute;
                                top: 1px;
                                right: 11px;
                                width: 44px;
                                height: 27px;
                                background-color: #cbcbcb;
                                border-radius: $border-radius-md;
                                font-size: $sm;
                                font-weight: 400;
                                line-height: 13px;
                                color: $color-gray-950;
                                @include flex-box(center, center);
                            }
                        }

                        .react-select__input {
                            margin-top: -3px;
                        }

                        .react-select__indicators {
                            color: $color-gray-950;
                            background-image: url(../../../assets/img/down-arrow.svg);
                            position: relative;
                            left: -2px;
                            top: 3px !important;
                        }
                    }

                    .react-select__menu {
                        z-index: 999;
                    }

                    .react-select__control--is-disabled {
                        opacity: 0.5;
                    }

                    .classRoom-selectOption {
                        @include flex-box(space-between, center);
                        gap: 8px;

                        .selectOption-text {
                            padding-top: 1px;
                        }

                        .dropDown-chip {
                            width: 44px;
                            height: 27px;
                            background-color: #cbcbcb;
                            border-radius: $border-radius-md;
                            font-size: $sm;
                            font-weight: 400;
                            line-height: 12px;
                            color: $color-gray-950;
                            @include flex-box(center, center);
                            margin-left: auto;
                        }
                    }

                    .header__dropdown-zIndex {
                      z-index: 99999;

                      @media (max-width: 1228px) {
                        z-index: unset !important;
                      }
                    }
                }

                .header__dropdown-zIndex.mobileZindex {
                    @media (max-width: 1228px) {
                        z-index: 99999999 !important;
                      }

                 }



                .stl {
                    height: 41px;
                    margin-top: 21px;

                    .stl__dropdown-container {
                        height: 100%;
                        border-color: $color-gray-450;
                        color: $color-gray-950;
                        background-image: url(../../../assets/img/down-arrow.svg);
                        background-position-x: 178px;
                        background-position-y: 16px;
                        background-repeat: no-repeat;

                        .stl__dropdown-placeholder {
                            color: $color-gray-950 !important;
                        }

                        .dropdownIcon {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .last-assessment {
        width: 120px;
        text-align: center;

        .lastAssessment-date {
            font-size: $md -1;
            font-weight: 400;
            line-height: 21.82px;
            color: $color-gray-900;
            margin-bottom: 0;
            height: 41px;
            padding-top: 12px;
        }
    }

    .subHeader-fieldLabel {
        font-size: $sm;
        font-weight: 700;
        line-height: 19.1px;
        color: $color-gray-900;
        height: 20.67px
    }

    .statusBarContainer {
        @include flex-box(flex-end, center);
        flex-wrap: wrap;
        gap: 10px 20px;
        width: 100%;
        max-width: 570px;
        margin-left: auto;

        @include media-breakpoint(md) {
            width: 100%;
            gap: 10px;
        }

        .status {
            display: block;
            margin: 0;
            text-align: center;
            width: 98px;

            .progressBlock {
                height: 41px;
                margin-top: 0px;

                p {
                    font-size: $sm + 1;
                    font-weight: 700;
                    line-height: 18px;
                    color: $color-gray-950;
                    margin-top: 2px;
                    margin-bottom: 0;
                }

                .no-data-progress-skeleton {
                    margin-top: -3px;

                    div {
                        width: 100%;
                        height: 19px;
                    }

                    p {
                        width: 40px;
                        height: 18px;
                        margin-top: 4px !important;
                    }
                }
            }
        }

        .status.disabled {
            margin-top: 0;

            .subHeader-fieldLabel {
                opacity: 0.4;
            }

            .progressBlock {
                padding-top: 3px;

                .react-loading-skeleton {
                    position: relative;
                    top: -3px;
                }
            }
        }
    }
}

.two-dropdowns {
    .subHeader {
        width: 100%;

        @include media-breakpoint(lg) {
            display: block;
        }

        .headerLeft {


            .admin-dropDown {
                width: 100%;
                
                .drop-down {

                    .header__dropdown-wrapper {

                        .header__dropdown-item {
                            width: 252px;

                            @include media-breakpoint(lg) {
                                width: 50%;
                            }

                            @include media-breakpoint(sm) {
                                width: 100%;
                            }

                            .react-select__control {
                                padding-top: 2px;

                                .react-select__indicators {
                                    top: 2px !important;
                                }
                            }

                            small {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
        
        .statusBarContainer {
            margin: 15px auto 0;

            .status {

                .subHeader-fieldLabel {
                    height: unset;
                }
            }
            
            .status.disabled {

                .progressBlock {
                    padding-top: 0;

                    .react-loading-skeleton {
                        top: 0;
                    }
                }
            }
        }
    }
}

.three-dropdowns {

    .subHeader {
        width: 100%;
        align-items: center;

        @include media-breakpoint(lg) {
            display: block;
        }

        .headerLeft {
            width: 100%;
            max-width: 792px;

            @include media-breakpoint(lg) {
                max-width: 100%;
            }

            .admin-dropDown {
                width: 100%;
                
                .drop-down {

                    .header__dropdown-wrapper {
                        flex-wrap: wrap;

                        .header__dropdown-item {
                            width: 252px;

                            @include media-breakpoint(lg) {
                                width: 48%;
                            }

                            @include media-breakpoint(sm) {
                                width: 100%;
                            }

                            .react-select__control {
                                padding-top: 2px;

                                .react-select__indicators {
                                    top: 2px !important;
                                }
                            }

                            small {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        .activity-bar-dropDowns {
            max-width: 100%;
        }

        .statusBarContainer {
            width: 380px;
            gap: 10px 12px;
            flex-wrap: nowrap;
            margin: 0;
            
            @include media-breakpoint(lg) {
                width: 100%;
                max-width: 546px;
                margin: 15px auto 0;
                flex-wrap: wrap;
                justify-content: center;
            }

            .status {
                width: 64px;

                @include media-breakpoint(lg) {
                    width: 98px;
                }

                .progressBlock {
                    height: 27px;
                    margin-top: 6px;

                    @include media-breakpoint(lg) {
                        height: 41px;
                        margin-top: 4px;
                    }

                    svg {
                        width: 100%;
                        height: 12.3px;
                        display: block;

                        @include media-breakpoint(lg) {
                            height: unset;
                        }
                    }

                    p {
                        font-size: 12px;
                        line-height: 16.37px;
                        margin-top: 2px;
                        height: 11px;

                        @include media-breakpoint(lg) {
                            font-size: 15px;
                            line-height: 20.46px;
                            height: unset;
                            margin-top: 4px;
                        }
                    }

                    span {
                        
                        &:first-child {
                            display: block;
                            height: 12.28px !important;
                            margin-top: -4px;
                            
                            .react-loading-skeleton {
                                width: 100% !important;
                                height: 12.28px !important;
                                margin-top: -7px !important;

                                @include media-breakpoint(lg) {
                                    height: 19px !important;
                                    margin-top: -5px !important;
                                }
                            }
                        }

                        &:last-child {
                            display: block;
                            height: 11px !important;
                            margin-top: -1px;

                            .react-loading-skeleton {
                                width: 25px !important;
                                height: 11px !important;
                                margin: 6px auto 0 !important;

                                @include media-breakpoint(lg) {
                                    width: 40px !important;
                                    height: 20.45px !important;
                                    margin: 12px auto 0 !important;
                                }
                            }
                        }
                    }
                }
            }

            .status.disabled {

                .progressBlock {
                    padding-top: 0;

                    .react-loading-skeleton {
                        top: 0;
                    }

                    svg {
                        height: 27px;

                        @include media-breakpoint(lg) {
                            height: unset;
                        }
                    }

                    .no-data-progress-skeleton {
                        margin-top: -5px;

                        div {
                            width: 100%;
                            height: 12.28px;

                            @include media-breakpoint(lg) {
                                height: 19px;
                            }
                        }

                        p {
                            width: 25px;
                            height: 11px;
                            margin-top: 4px !important;

                            @include media-breakpoint(lg) {
                                width: 40px;
                                height: 20.45px;
                            }
                        }
                    }
                }
            }

            .subHeader-fieldLabel {
                font-size: 12px;
                line-height: 16.37px;
                height: unset;
                @include ellipsis;

                @include media-breakpoint(lg) {
                    font-size: 14px;
                    line-height: 19.1px;
                }
            }
        }
    }
}

.schoolAdmin-tab{
    .subHeader{
        @media(max-width:787px){
            width: 100%;
        }
        .admin-dropDown{
            @media(max-width:791px){
                width: 100%;
            }
        }
    }
}