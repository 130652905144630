// Activity Welcome
.activity__welcome {
  display: flex;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }

  &-leftCenter {
    display: flex;
    flex: 1;

    @include media-breakpoint(xl) {
      flex-direction: column;
      max-width: calc(100% - 120px);
    }

    @media only screen and (max-width: 1023px) {
      max-width: 100%;
    }
  }

  &-left {
    min-width: 165px;
    border-right: 1px solid $theme-border-color;
    padding: 29px 34px;

    @media only screen and (max-width: 1254px) {
      min-width: 120px;
      padding: 28px 10px;
    }

    @include media-breakpoint(xl) {
      border-right: none;
      padding: 16px;
      padding-bottom: 0;
    }

    @include media-breakpoint(md) {
      width: 100%;
      // flex: 0 0 100%;
    }
  }

  &-leftContent {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media only screen and (max-width: 1254px) {
      justify-content: space-between;
    }

    @include media-breakpoint(xl) {
      flex-direction: row;
      justify-content: center;
    }

    @include media-breakpoint(md) {
      height: auto;
    }

    img {
      margin-bottom: 8px;

      @include media-breakpoint(xl) {
        margin-bottom: 0;
        margin-right: 10px;
      }

      @include media-breakpoint(md) {
        max-width: 20px;
        height: auto;
        margin-right: 8px;
      }
    }

    svg {
      margin-bottom: 8px;

      @include media-breakpoint(xl) {
        margin-bottom: 0;
        margin-right: 10px;
      }

      @include media-breakpoint(md) {
        max-width: 20px;
        height: auto;
        margin-right: 8px;
      }
    }
  }

  &-leftLink {
    @include flexcenter-both;
    flex-direction: column;
    text-decoration: none;
    color: $color-white;
    width: 100px;
    height: 100px;
    border-radius: $border-radius-md;
    padding: 5px;
    transition: all 0.3s;
    outline: none;
    box-shadow: none;
    border: none;
    background-color: transparent;

    &:focus-visible {
      border: 2px solid white;
    }

    @media only screen and (max-width: 1254px) {
      width: 100px;
      height: 100px;
    }

    @include media-breakpoint(xl) {
      width: 136px;
      height: auto;
      flex-direction: row;
      padding: 12px 16px;
      margin: 0 8px;
    }

    @include media-breakpoint(md) {
      width: 106px;
      font-size: 14px;
      padding: 8px;
      margin: 0 5px;
    }

    &:not(:last-child) {
      margin-bottom: 18px;

      @include media-breakpoint(xl) {
        margin-bottom: 0;
      }
    }

    &:hover {
      color: darken($color-white, 10%);
    }

    &.active {
      background-color: $theme-bg-color-dark;
    }

    p {
      margin-bottom: 0;
      font-size: $md;
      font-weight: 400;
      color: $color-gray-950;
    }
  }

  &-leftLink.active {

    p {
      color: white;
    }
  }

  &-center {
    flex: 1;
  }

  &-content {
    padding: 16px 22px;
    
    p {
      font-size: $md;
      font-weight: 400;
      line-height: 21.82px;
      color: $color-gray-950;
    }

    @include media-breakpoint(xl) {
      padding: 20px 16px;
      padding-bottom: 0;
    }
  }

  &-title {
    border-bottom: 1px solid $theme-border-color;


    @media (max-width: 444px) {
      height: auto;
    }

    h4 {
      margin-bottom: 11px;
      color: $color-gray-950;
    }
  }

  &-centerText {
    max-height: 220px;
    margin-top: 15px;
    border: none !important;

    @media (min-width: 1024px) and (max-width: 1199px) {
      height: 160px !important;
    }
    

    &.activity-welcome-centerText {
      background-color: unset;
      overflow-y: auto;
      margin-top: 0;
      padding-top: 12px;
    }

    p {
      line-height: 19px;
    }

    .ql-editor{
      padding: inherit;
      padding-top: 0px;
      padding-bottom: 0;
    }
    @include media-breakpoint(xl) {
      padding-bottom: 14px;
    }

    p {
      margin-bottom: 0;

      a {
        white-space: nowrap;
        
        &:focus-visible {
         position: relative;
          &::after {
            content: '';
            border: 1px solid black !important;
            outline: 1px soild black !important;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
          }
        }
      }
    }

    ul {

      li {

        &::before {
          color: inherit !important;
        }

        a {
          white-space: nowrap;
          @media (max-width: 380px) {
            white-space: wrap;
          }
          
          &:focus-visible {
           position: relative;
            &::after {
              content: '';
              border: 1px solid black !important;
              outline: 1px soild black !important;
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
            }
          }
        }
      }
    }
  }
  

  &-right {
    flex-direction: column;
    width: 165px;
    border-left: 1px solid $theme-border-color;

    @media only screen and (max-width: 1254px) {
      width: 120px;
    }

    @include media-breakpoint(xl) {
      min-width: 120px;
      padding: 0;
    }

    @media only screen and (max-width: 1023px) {
      flex-direction: row;
      justify-content: center;
      border-top: 1px solid $theme-border-color;
      min-width: calc(100% - 32px);
      width: calc(100% - 32px);
      margin: 0 auto;
      border-left: none;
    }
  }

  &-rightActive,
  &-rightOnline {
    @include flexcenter-both;
    flex-direction: column;
    width: 100%;
    height: 50%;
    color: $color-white;
    padding: 22px 20px;

    @media only screen and (max-width: 1254px) {
      padding: 22px 10px;
    }

    @include media-breakpoint(md) {
      padding: 16px 10px;
    }

    .active-text-skeleton {
      margin-top: 12px;
      position: relative;
      top: 8px;
      text-align: center;
    }

    p {
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
      font-weight: bold;
      line-height: 24px;
      color: $color-gray-950;
      margin-top: 6px;

      @media only screen and (max-width: 1254px) {
        font-size: 16px;
        line-height: 20px;
      }

      @include media-breakpoint(md) {
        font-size: 14px;
      }
    }

    h2 {
      color: $color-gray-950;
    }
  }

  &-rightActive {
    border-bottom: 1px solid $theme-border-color;

    @media only screen and (max-width: 1023px) {
      border-bottom: none;
    }
  }
}

// Activity Status
.activity {
  &__status {
    padding: 0 22px;

    @media only screen and (max-width: 1254px) {
      padding: 0 16px;
    }

    @include media-breakpoint(md) {
      padding: 0;
    }

    &-header {
      justify-content: center;
      padding: 10px 0;
      border-bottom: 1px solid $theme-border-color;

      @include media-breakpoint(sm) {
        padding-bottom: 0;
      }

      @media (max-width: 550px) and (min-width: 444px) {
        height: 76px;
      }

      .btn {
        font-size: 18px;
        border-radius: 30px;
        padding: 4px 20px 6px;
        color: $color-gray-950;
        height: 33px;

        &:not(:last-child) {
          margin-right: 7px;

          @include media-breakpoint(md) {
            margin-right: 4px;
          }
        }

        @include media-breakpoint(xl) {
          margin: 0 5px;
        }

        @include safari-suport {
          padding-top: 5px;
          padding-bottom: 9px;
        }

        @include media-breakpoint(md) {
          padding: 5px 12px;
          font-size: 14px;
        }

        @include media-breakpoint(sm) {
          margin-bottom: 10px;
        }
        
        &:hover,
        &:focus {
          background-color: transparent;
          border-color: transparent;
          color: $color-white;
          
          @include desktop-up {
            background-color: $theme-bg-color-dark;
            border-color: $theme-border-color;
            color: $color-white;
          }
        }
        &:focus-visible {
            border: white;
        }

        &.active {
          color: $color-white;
          background-color: $theme-bg-color-dark;
          border-color: $theme-border-color;
        }
      }
    }
  }
}

.progress {
  &__table {
    position: relative;
    height: 100%;

    th,
    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 21.82px;
      color: $color-gray-950;
      // text-shadow: $text-shadow;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-headerRow {
      padding-left: 35px;

      th {
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }

    &-headerLast {
      padding-right: 48px !important;
      cursor: pointer;
    }

    &-headerFormative {
      padding-right: 6px !important;
    }

    &-headerFormative {
      padding-right: 6px !important;
    }

    &-scheduled {
      text-align: left !important;
      padding-left: 23px !important;
      font-size: 16px !important;

      p {
        font-size: 16px !important;
      }
    }

    &-badge {
      position: absolute;
      top: 51px;
      left: 0;
      background-color: $theme-bg-color-light-variant2;
      width: 35px;
      max-height: calc(100% - 51px);
      height: calc(100% - 51px);
      border-radius: 0 0 0 8px;
    }

    &-badgeContent {
      @include flexcenter-both;
      background-color: $color-gray-900;
      width: 100%;

      &:nth-child(even) {
        background-color: $color-gray-900;
      }

      p {
        text-align: center;
        transform: rotate(180deg);
        font-weight: 400;
        color: white;
        margin: 0;
        text-shadow: none;
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
      }
    }

    &-row:first-child {
      
      td::before {
        display: none;
      }
    }

    &-row {
      padding-left: 35px;

      &:last-child {

        td {
          
          &::before {
            display: none;
          }
        }
      }

      td {
        padding: 0 10px;
        max-height: 60px;
        height: 60px;
        position: relative;

        @include media-breakpoint(xxl) {
          padding: 0 5px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          max-width: 1141px;
          height: 1px;
          background-color: $color-gray;
        }

        .progressBlock {
          text-align: center;
        }
      }

      td:first-child {
        &::before {
          width: calc(100% - 19px);
          right: 0;
          left: unset;
        }
      }

      td:last-child {
        &::before {
          width: calc(100% - 19px);
        }
      }

      .no-data {
        color: $color-gray-950;
      }

      .className {
        position: relative;
        width: 244px;
        min-width: 244px;
        text-align: right;
        padding-right: 15px;
        padding-top: 0;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 5px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 16px;
          width: 20px;
          height: 14px;
        }

        &-data {
          max-width: 244px;
          text-align: right;
          padding-right: 15px !important;
          padding-left: 45px !important;

          @include media-breakpoint(xxl) {
            padding-right: 5px;
          }

          &-wrapper{
            text-align: right;
            width: 100%;
            overflow: hidden;
          }

          &-text{
            position: relative;
            @include ellipsis;
            font-size: $md;
            font-weight: 700;
            color: $color-gray-950;
            left: 0%;
            width: 100%;
            text-shadow: none;
            margin-bottom: 0;
            cursor: pointer;
            transition: left 3s linear, width 3s linear;

            @include safari-suport {
              transform: translateY(-1.5px);
            }

            @include media-breakpoint(md) {
              font-size: 14px;
              line-height: 18px;
            }

            &.animtated-title,
            &.animated:hover{
              left: -100%;
              width: 200%;
            }
          }

          &-badge {
            position: relative;
            left: 0;
            width: 100%;
            display: block;
            color: $color-gray-950;
            font-size: 14px;
            line-height: 20px;
            @include ellipsis;
            margin-top: 4px;
            transition: left 3s linear, width 3s linear;

            @include media-breakpoint(md) {
              font-size: 12px;
              line-height: 18px;
            }

            &.animtated-title,
            &.animated:hover{
              left: -100%;
              width: 200%;
            }
          }
        }
      }

      .hour-column {
        width: 68px;
        min-width: 68px;
        padding: 0;
        text-align: left;

        .chip {
          width: 44px;
          height: 27px;
          border-radius: $border-radius-md;
          background-color: #cbcbcb;
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          line-height: 13.762px;
          color: $color-gray-950;
          @include flex-box(center, center);
          padding: 2px 2px 0px 0px;
        }
      }

      .assesment {
        width: 130px;
        min-width: 130px;
        text-align: center;
        padding-top: 0;
        cursor: pointer;


        @include media-breakpoint(xxl) {
          min-width: 120px;
        }

        &-data {
          text-align: center;
          color: $color-gray-950;

          @include safari-suport {
            padding-bottom: 2px !important;
          }

          .warning-flag {
            font-weight: 400;
            text-shadow: unset;
            position: relative;
            width: 72px;
            margin: 0 auto;

            img {
              position: absolute;
              top: 0px;
              left: -24px;
              width: 22.5px;
              height: 19.3px;
            }
          }
        }
      }

      .proficiency {
        width: 125px;
        min-width: 125px;
        text-align: center;
        padding: 0;
        cursor: pointer;
        .arrow-down-classname{
          left: 3px;
        }
        .arrow-up-classname{
          left:  3px;
        }
      }

      .options {
        width: 185px;
        min-width: 185px;
        text-align: left;
        padding: 0 36px 0 10px;

        @include media-breakpoint(xxl) {
          min-width: 170px;
          width: 170px;
          padding: 0 16px 0 5px;
        }

        @include media-breakpoint(xl) {
          min-width: 143px;
          width: 143px;
        }

        .table-dropdown {

          .table-dropdown-toggle {
            border-color: #858585 !important;
          }
        }
      }

      .options.disabled {

        .table-dropdown {
          opacity: 0.5;
        }
      }

      .row-data-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 21.82px;
        color: $color-gray-950;
        padding-bottom: 4px;
        height: 31px;
        // text-shadow: $text-shadow;

        @include media-breakpoint(md) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .progressBlock {
      height: 41px;
    
      p {
          font-size: $sm + 1;
          font-weight: 700;
          line-height: 20.46px;
          color: $color-gray-950;
          margin-top: 4px;
          margin-bottom: 0;
          text-align: center;
          text-shadow: unset;
      }
    
      svg {
        border-radius: $border-radius-md;
      }
    }
  }
}

.arrow-down {
  width: 20px;
  height: 14px;
  cursor: pointer;
}

// New version
.activity__welcome-wrapper {

  >.section {
    margin-top: 26px;

    .activity__welcome {
      height: 279px;

      @media only screen and (max-width: 1023px) {
        height: auto;
      }

      canvas {
        height: 220px !important;

        @media (max-width: 1200px) and (min-width: 1023px) {
          height: 152px !important;
        }
      }
    }
  }
}

.activity__progress-wrapper {

  .section {
    margin-top: 0;

    .showProgressReportButton {
      @media (max-width : 420px) {
        flex-direction: column !important;
      }
      .activity-progress-btns  {
        @media (max-width : 420px) {
        margin-top: 12px !important;
        margin-bottom: 8px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      @media (max-width : 330px) {
        gap: 10px !important;
        display: block;
      }
      .exportCSVBtn {
        @media (max-width : 326px) {
          margin-top: 10px;
        }
      }
    }
    .activity-progress-tab {
      @media (max-width : 797px) {
      top: unset !important;
      bottom: 16px;
    }
  }
    }

    .section-header {
      padding: 16px 31px 15px 16px;   

      @include media-breakpoint(sm) {
        position: relative;
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 70px;
      }

      @media (max-width : 420px) {
        padding-bottom: 60px;
      }

      .section-header-title {
        margin-top: -1px;
      }

      .activity-progress-btns {
        @media (max-width: 461px) {
          margin-top: 0;
        }
      }
    }
  }
}

.activity-assessments {
  border-radius: $border-radius-md;

  .chart-title {
    position: relative;
    top: -23px;
    pointer-events: none;
    color: $color-gray-950;

    @media (max-width: 1023px) {
      top: 13px;
    }
  }
  .type-dropdown-container{
    @media (max-width: 767px) {
      justify-content: flex-end !important;
    }
    .dropdown.table-dropdown {

      .table-dropdown-toggle {
        width: 215px;
        background-position-y: 10px;
        padding: 2px 11px 0;
        font-size: 14px;
        line-height: 22px;
        margin-left: 20px;

        @media (max-width: 1199px) {
          margin-left: 7px;
        }

        span{
         
          font-weight: 700;
          
        }
      }
    } 
  }
  .progress-table-container{

    .table-responsive{
      .progress__table{

        .progress__table-body{
          .progress__table-row{
            .row-data-title{
              .arrow-up-classname{
                  top: -1.8px;
                  left: 3px;
                  margin-left: 4px;
                  @media (max-width: 767px){
                    top: -1px;
                  }
                }
              .arrow-down-classname{
                left: 3px;
                top: -1px;
                margin-left: 4px;
                @media (max-width: 767px){
                  top: 0px;
                }
              }
            }
          }
        }
      }
    }
  }

  .show-classes-dropdown {
    font-size: $md;
    line-height: 21px;
    font-weight: 400;
    color: $color-gray-950;
    @media (max-width: 767px) {
      justify-content: flex-end;
    }

    .limit-selects {

      .limit-selects__wrapper {

        .limit-selects__control {
          background-color: unset;
          border-color: #858585;
          padding-top: 2px;
        }
      }
    }

    .dropdown.table-dropdown {

      .table-dropdown-toggle {
        width: 155px;
        background-position-y: 10px;
        padding: 2px 11px 0;
        font-size: 14px;
        line-height: 22px;

      }
    } 
  }

  .sec-header-dropdown {

    .table-dropdown {

      .table-dropdown-toggle {
        border: 1px solid #9d9d9d !important;
        padding-top: 1px;
        background-position: 117px 9px;
      }
    }
  }

  .section-search{
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 12px 0px;
    }
     .show-classes-dropdown{
       @include media-breakpoint(sm) {
         justify-content: end;
       }
     }
   }
}

// .activity-assessments.chart {

//   .section-search {

//     .sec-header-dropdown {

//       .table-dropdown {

//         .table-dropdown-toggle {
//           background-color: white !important;
//         }
//       }
//     }

//     .show-classes-dropdown {

//       .limit-disabled {
//         color: white;
//       }

//       .limit-selects {
        
//         .limit-selects__control {

//           .limit-selects__single-value {
//             color: white !important;
//           }
//         }
//       }
//     }
//   }
// }
