.uploads {
  display: flex;
  justify-content: center;
  padding: 11px 16px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 116px;
    padding: 5px 0;

    @include media-breakpoint(sm) {
      min-height: 100px;
      height: auto;
    }

    &.bordered {
      max-width: 548px;
      width: 100%;
      border: 2px dashed $color-gray-500;
      border-radius: $border-radius-md;
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 0;
      color: $color-gray-950;
    }

    &-paragraph {
      text-align: center;
      color: $color-gray-950;
      margin: 10px 0 11px 0;

      img {
        display: inline-block;
        margin-right: 14px;
      }
    }

    &-choose {
      width: 126px;
      text-align: center;

      &:disabled {
        opacity: 1;
      }

      &.disabled {
        background-color: #46afb856;
        color: #ffffff56;
      }
    }

    &-buttons {
      margin-top: auto;
      button:first-child {
        margin-right: 21px;
      }
    }
  }

  &-file-name {
    text-align: center;
    word-break: break-all;
    color: $color-white;
    padding: 0 16px;
    margin-top: 4px;
    margin-bottom: 0;
  }

  &__pdf {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 116px;
      border: 2px dashed $color-gray-500;
      border-radius: $border-radius-md;
      padding: 5px;
      padding-top: 17px;

      @include media-breakpoint(sm) {
        height: auto;
      }
    }

    &-icon {
      @include flexcenter-both;
      flex-direction: column;
      height: 100%;

      .zip-icon {
        mix-blend-mode: darken;
      }

      img {
        width: 60px;
        height: auto;
      }

      p {
        color: $color-gray-500;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: 0;
        opacity: 0.26;
      }
    }

    &-button {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
