.questions{
    flex: 1;
    background-color: $color-white;
    height: calc(100vh - 193px);
    max-width: 896px;
    margin-right: auto;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 12px;
    }
      
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 20px $color-light-300;
      
        box-shadow: inset 0 0 12px $color-light-300; 
        border-radius: 10px;
    }
       
    &::-webkit-scrollbar-thumb {
        width: 8px;
        border: 2px solid $color-light-300;
        background: $color-gray-200; 
        border-radius: 7px;
    }

    &.switch-off-column {
        max-width: 1460px;
        width: 100%;
        margin: 0 auto;

        .questions-wrapper{
            max-width: 1460px;
            margin: 0 auto;
        }

        .questions__question{
            padding: 20px 34px;
            padding-bottom: 80px;

            @include media-breakpoint(xl) {
                padding: 20px 16px;
                padding-bottom: 80px;
            }
        }
    }

    &.changing-width{
        width: 82.2%;
    }

    &-wrapper{
        width: 100%;
        max-width: 896px;
        margin-right: auto;
    }

    &__dialogue{
        display: flex;
        background-color: rgba($color-light-200, .33);
        max-width: 522px;
        width: 100%;
        border-radius: $border-radius-md;
        padding: 10px 16px 9px 24px;
        margin-bottom: 13px;
        cursor: pointer;

        @include safari-suport {
            padding: 9px 16px 10px 24px;
        }

        @include media-breakpoint(xl) {
            padding: 10px 16px;
        }

        &-icon{
            margin-right: 20px;
            cursor: pointer;
        }

        p{
            font-size: 19px;
            color: $color-blue-100;
            line-height: 26px;
            margin-bottom: 0;

            @include safari-suport {
                line-height: 22px;
            }
        }

    }

    &__question{
        padding: 20px 27px;
        
        img {
            width: auto;
            max-width: 100%;
        }

        table tr td {
            margin-top: 10px;
            border: 1px solid #dadada;
            min-width: 2em;
            padding: 0.4em;
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
            vertical-align: middle;
        }

        @include media-breakpoint(xl) {
            padding: 20px 16px;
        }

        &-item{
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            p{
                margin-bottom: 0 !important;
            }

            &.instruction{
                align-items: flex-start;
                margin-bottom: 21px;
            }

            &.choice-c > .questions__question-icons .questions__question-suggest {
                padding-right: 1px;
            }

            &.choice-d > .questions__question-icons .questions__question-suggest {
                padding: 2px 1px 2px 2px;
            }

            &.choice-b > .questions__question-icons .questions__question-suggest {
                padding: 2px 0 2px 1px;
            }
        }

        &-icons {
            display: flex;
            align-items: center;
            height: 28px;
        }

        &-icon {
            margin-right: 12px;
            margin-top: -2px;
            cursor: pointer;
        }

        &-showHide {
            @include flexcenter-both;
            background-color: transparent;
            width: 26px;
            height: 27px;
            border: none;
            padding: 0;
            margin-right: 10px;
            margin-top: 0px;
            cursor: default !important;

            svg {
                cursor: pointer;
            }
        }

        &-instruction {
            font-size: 19px;
            font-weight: bold;
            color: $color-black;
            margin-bottom: 0;

            p{
                font-size: 20px;
                line-height: 20px;
                color: $color-black;
                font-weight: 700;
                margin: 0px;
                position: relative;
                top: 2px;
                width: 100%;
            }

            img{
                max-width: 100%;
                height: auto;
            }
        }

        &-suggest{
            @include flexcenter-both;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            border: 2px solid $color-blue-100;
            color: $color-blue-100;
            font-family: $font-arimo;
            padding: 0;
            cursor: pointer;

            p {
                font-size: 21px;
                color: $color-blue-100;
                line-height: unset;
                text-align: center;
            }
            
            &.fill {
                color: $color-white;
                background-color: $color-blue-100;

                p {
                    color: white;
                }
            }

            // &.first{
            //     padding: 1px 0 3px 0;
            // }
        }

        &-choice{
            flex: 1;
            font-size: 19px;
            line-height: 24px;
            color: $color-gray-750;
            font-weight: 400;
            margin: 0px;
            position: relative;
            top: 0px;
            padding-left: 12px;
            font-family: $font-arimo;

            p{
                width: auto;
                font-size: 19px;
                line-height: 20px;
                color: $color-gray-750;
                font-weight: 400;
                padding-left: 0;
                display: block;
                margin-top: 0px;                
            }

            img {
                max-width: 100%;
                width: auto;
                max-height: 106px;
            }

           
        }
        &-unsetMaxHeight{
            img { max-height: unset !important;}
         }
    }
}

// #choice3{
// 	padding-right: 1px;
//     padding-top: 1px;
// }

// #choice4{
//     padding-left: 1px;
// }