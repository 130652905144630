.cmsHeader{
    background: $color-yellow;
    border-radius: 0;
    position: relative;
    z-index: 99999;

    &-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.5px 25px;

        @include media-breakpoint(xxl) {
            padding: 6px 23px 6px 28px;
        }

        @include media-breakpoint(xxl) {
            padding: 6px 15px;
        }

        @media (max-width: 1015px) {
            justify-content: center;
        }

        @include media-breakpoint(lg) {
            justify-content: center;
            padding: 6px 0;
        }

        &.formative {
            @media (max-width: 1015px) {
                justify-content: space-between;
            }

            @include media-breakpoint(lg) {
                justify-content: space-between;
                padding: 6px 15px;
            }
        }
    }
    .logo-fix {
        padding: 6.5px 25px !important;
    }

    &__logo{
        max-width: 247px;
        width: 100%;

        @include media-breakpoint(md) {
            max-width: unset;
            width: 65px;
        }

        // @include media-breakpoint(xxl) {
        //     width: 80px;
        // }

        @media (max-width: 1015px) {
            display: none;
        }

        @include media-breakpoint(lg) {
            display: none;
        }

        .logo-desktop {
            width: 240px;
            height: 50px;

            @include media-breakpoint(md) {
                display: none;
            }

        }

        .logo-mobile {
            display: none;
            width: 65px;
            height: 46px;
            
            @include media-breakpoint(md) {
                display: block;
            }
        }

        &.formative {

            @media (max-width: 1015px) {
                display: block;
            }

            @include media-breakpoint(lg) {
                display: block;
            }

            .cmsHeader__logo-img {
                @include media-breakpoint(xxl) {
                    display: block;
                }
            }

            .cmsHeader__logo-sm {
                @include media-breakpoint(xxl) {
                    display: none;
                }
            }
        }

        &-link{
            display: block;
        }

        &-img{
            width: 217px;
            height: auto;

            @include media-breakpoint(xxl) {
                display: none;
            }
        }

        &-sm {
            display: none;

            @include media-breakpoint(xxl) {
                display: block;
            }
        }
    }

    &__numbers{
        width: 800px;
        min-width: 800px;
        margin: 0;
        padding-bottom: 6px;

        &.formative {
            width: 225px;
            min-width: 225px;
        }

        &-wrapper{
            width: 100%;
            height: 70px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            padding-top: 6px;
        }

        &-summative-size {
            width: 26px;
            height: 26px;
            margin: 3px;

            &.selected {
                border-color: $color-gray-800;
                transform: scale(1.2);

                button {
                    color: $color-black;
                    font-weight: 700;
                    padding-bottom: 1px;
                }
            }
        }

        &-formative-size {
            width: 35px;
            height: 35px;
            margin: 5px;

            button {
                font-size: 16px;
            }

            &.selected{
                border-color: $color-gray-800;
                transform: scale(1.26);

                button {
                    font-size: 16px;
                    color: $color-black;
                    font-weight: 700;
                    padding-bottom: 1px;
                }
            }
        }

        &-number{
            background-color: $color-white;
            border: 2px solid transparent;

            &.unsaved {
                border-color: $color-orange-100;
            }
        }

        &-btn{
            @include flexcenter-both;
            width: 100%;
            height: 100%;
            padding: 0px;
            font-size: 14px;
            line-height: 20px;
            color: $color-gray-850;
            font-weight: 400;
            font-family: $font-cabin;
            background-color: transparent;
            border: none;

            &:focus{
                outline: none;
            }
        }
    }

    &__user{
        width: 247px;

        .header__user {
            width: 100%;
        }

        &.summative {
            @include media-breakpoint(xxl) {
                width: 80px;
            }

            @media (max-width: 1015px) {
                display: none;
            }

            .login-user-info {
                @include media-breakpoint(xxl) {
                    display: none;
                }
            }

            .user-dropdown-icon {
                @include media-breakpoint(xxl) {
                    margin-right: 0;
                }
            }

            .login-user-dropdown {
                @include media-breakpoint(xxl) {
                    width: 200px;
                    right: 0 !important;
                }
            }
        }

        

        .login-account-info {
            @include media-breakpoint(lg) {
                margin-bottom: 0;
                border-radius: 0;
                border: none;
            }
        }

        &-content {
           @include media-breakpoint(xxl) {
            border: 1px solid $color-blue-100;
            border-radius: 10px;
            padding: 10px;
            padding-right: 0;
           }
        }
        
        &-wrapper{
            display: flex;
            align-items: center;
            text-decoration: none;

            &::after {
                display: none;
            }
        }

        &-box{
            @include flexcenter-both;
            background-color: $color-blue-100;
            width: 46px;
            height: 46px;
            border-radius: $border-radius-md;
            color: $color-white;
            font-size: 18px;
            line-height: 22px;
        }

        &-info{
            width: 147px;
            padding-left: 8px;
            color: $color-blue-700;
            margin-right: 17px;

            @include media-breakpoint(xxl) {
                display: none;
            }
        }

        &-name{
            font-size: 18px;
            color: inherit;
            line-height: 1;
            margin-bottom: 2px;
            padding-right: 6px;
            @include ellipsis;
        }

        &-detail{
            font-size: 14px;
            color: inherit;
            line-height: 1;
            margin-bottom: 0;
        }

        &-icon {
            @include media-breakpoint(xxl) {
                display: none;
            }
        }
    }
}
