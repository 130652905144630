.auditReport {
    @include section;
    margin-top: 22px;

    @media print {
        margin: 0;
        box-shadow: unset;
        border-radius: 0;
    }

    &__mainContainer {
        background-color: $color-gray-500;
        padding-top: 18px;
        border-radius: $border-radius-md;
        @media print {
            background-color: unset;
        }
    }

    &__header-info {
        position: relative;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint(lg) {
            flex-flow: row wrap;
            justify-content: center;
        }

        @media print {
            flex-flow: nowrap;
            justify-content: space-between;
        }
    }

    &__search {
        width: 297px;
        padding-left: 20px;

        @include media-breakpoint(xl) {
            width: 240px;
        }

        @include media-breakpoint(lg) {
            order: 3;
            width: 100%;
            margin-top: 10px;
        }

        @include media-breakpoint(md) {
            margin-bottom: 10px;
        }

        @media print {
            order: -1;
            width: 297px;

            @include mozilla-suport {
                width: 140px;
            }
        }

        &-input {
            width: 185px;
            outline: none;
            border-radius: $border-radius-md;
            border: none;
            padding-left: 12px;
            height: 26px;
            font-size: 14px;
        }
    }
    &__centerParagraph {
        max-width: 411px;
        width: 100%;
        margin-bottom: 16px;
        padding-top: 14px;

        @include media-breakpoint(lg) {
            margin: 0 16px 20px 16px;
        }

        @media print {
            margin-bottom: 16px;

            @include mozilla-suport {
                width: 270px;
            }
        }

        &__maintext {
            p {
                color: $color-gray-850;

                @media print {
                    @include mozilla-suport {
                        font-size: 10px;
                        line-height: 1.5;
                        margin-bottom: 0;
                    }
                }
            }
        }
        &__alertbox {
            border: 1px solid $color-gray-750;
            max-width: 341px;
            width: 100%;
            height: 49px;

            @media print {
                border-color: transparent;

                @include mozilla-suport {
                    width: 270px;
                    height: 32px;
                }
            }

            img {
                @media print {
                    @include mozilla-suport {
                        width: 16px;
                        height: auto;
                    }
                }
            }

            &__text {
                color: $color-black !important;
                margin-bottom: 0;
                margin-left: 5px;

                @media print {
                    @include mozilla-suport {
                        font-size: 10px;
                    }
                }
            }
        }
        &__total-students {
            width: 279px;
            margin-top: 9px;
            padding-right: 20px;

            @include media-breakpoint(xl) {
                width: 240px;
            }

            @include media-breakpoint(lg) {
                order: 2;
                display: flex;
                justify-content: center;
                width: 100%;
                padding-right: 0;
                margin-top: 0;
            }

            @media print {
                width: 279px;
                justify-content: flex-start;
                transform: translateX(-20px);

                @include mozilla-suport {
                    transform: translateX(10px) translateY(-20px);
                    width: 140px;
                }
            }

            .student__total-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $color-blue-100;
                width: 178px;
                height: 116px;
                border: 1px solid $color-gray-750;

                @include media-breakpoint(md) {
                    width: 130px;
                    height: 100px;
                }

                @media print {
                    background-color: transparent;
                    border-color: transparent;

                }
            }

            &__numbers {
                font-size: 32px;
                font-weight: bold;
                line-height: 41px;
                margin-bottom: 0;

                @include media-breakpoint(md) {
                    font-size: 28px;
                    line-height: 36px;
                }

                @include media-breakpoint(sm) {
                    font-size: 24px;
                    line-height: 32px;
                }

                @media print {
                    color: $color-gray-900;

                    @include mozilla-suport {
                        font-size: 20px;
                        line-height: 1.4;
                    }
                }
            }
            &__text {
                font-size: 18px;
                font-weight: bold;
                line-height: 24px;
                text-shadow: $text-shadow;
                text-align: center;
                margin-bottom: 0;

                @include media-breakpoint(md) {
                    font-size: 16px;
                    line-height: 20px;
                }

                @media print {
                    color: $color-gray-900;
                    text-shadow: unset;

                    @include mozilla-suport {
                        font-size: 12px;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    &__table {
        
        .licensed-data {
            display: inline-block;
            width: 100px;
            text-align: center;

            @include media-breakpoint(xl) {
                width: 90px;
            }

            @media print {
                @include mozilla-suport {
                    width: 80px;
                }
            }
        }

        thead {
            position: sticky;
            background: $color-gray-500;
            top: 0;

            th {
                vertical-align: bottom;
                font-size: 16px;
                color: $color-black;
                line-height: 20px;
                font-weight: bold;
                padding-bottom: 5px;

                @include media-breakpoint(md) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media print {
                    @include mozilla-suport {
                        font-size: 10px;
                        line-height: 1.4;
                    }
                }
            }
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: $color-gray;
                    @media print {
                        background-color: unset;
                    }
                }

                td {
                    font-size: 16px;
                    line-height: 21px;
                    padding: 8px 10px;

                    @include media-breakpoint(md) {
                        font-size: 14px;
                    }
                    
                    @media print {
                        @include mozilla-suport {
                            padding: 4px 6px;
                            font-size: 10px;
                            line-height: 1.4;
                        }
                    }
                }
            }
        }

        &__row {
            &__directName {
                min-width: 400px;
                width: 400px;
                padding-left: 50px;
                text-align: right;
                padding-right: 0 !important;

                @include media-breakpoint(md) {
                    min-width: 300px;
                    width: 300px;
                }
                
                @include safari-suport {
                    width: 280px;
                    min-width: 280px;
                }

                @media print {
                    @include mozilla-suport {
                        min-width: 180px;
                        width: 180px;
                        font-size: 10px;
                        line-height: 1.4;
                    }
                }
            }

            &__schoolName {
                width: 400px;
                min-width: 300px;
                text-align: right;
                padding-right: 0 !important;

                @include media-breakpoint(md) {
                    min-width: 260px;
                    width: 260px;
                }

                @media print {
                    @include mozilla-suport {
                        min-width: 250px;
                        width: 250px;
                        font-size: 10px;
                        line-height: 1.4;
                    }
                }

                span {
                    font-weight: bold;
                    cursor: pointer;
                }
            }
            &__enrolledStudent {
                min-width: 88px;
                width: 88px;
                text-align: center;

                @media print {
                    @include mozilla-suport {
                        min-width: 60px;
                        width: 60px;
                        font-size: 10px;
                        line-height: 1.4;
                    }
                }
            }
            &__testedStudent {
                min-width: 156px;
                width: 156px;
                text-align: center;

                @include media-breakpoint(xl) {
                    min-width: 90px;
                    width: 90px;
                }

                @media print {
                    @include mozilla-suport {
                        min-width: 100px;
                        width: 100px;
                        font-size: 10px;
                        line-height: 1.4;
                    }
                }
            }
            &__Licensed {
                padding-left: 28px;

                @include media-breakpoint(xl) {
                    padding-left: 18px;
                }

                @media print {
                    @include mozilla-suport {
                        font-size: 10px;
                        line-height: 1.4;
                    }
                }
            }
            &__warning {
                text-align: center;
                min-width: 50px;
                width: 50px;

                @include media-breakpoint(xl) {
                    min-width: 40px;
                    width: 40px;
                }

                @media print {
                    @include mozilla-suport {
                        min-width: 30px;
                        width: 30px;
                    }
                }

                img {
                    @media print {
                        @include mozilla-suport {
                            width: 16px;
                            height: auto;
                        }
                    }
                }
            }
            .fontBold {
                font-weight: 700;
            }
        }
    }
    &__overflow {
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .warningText {
        color: $color-red-600;

        .auditReport__table__row__schoolName {
            font-weight: bold !important;
        }
    }
}
