.data-text-print {
  position: absolute;
  left: 53px;
  top: 28px;
  width: 348px;

  p {
    color: $color-gray-850;
    line-height: 20px;
  }
}

.irr-summative-collapse-icon {
  width: 12px;
  height: 28px;
  padding-top: 6px;

  img {
    max-width: 20px;
    transition: all 0.3s;
    transform: rotate(-180deg);
    cursor: pointer;

    @include media-breakpoint(md) {
      max-width: 40px;
    }

    &.rotate,
    &.rotating {
      transform: rotate(90deg);
    }

    &.rotate-90 {
      transform: rotate(-90deg);
    }
  }

  svg {
    max-width: 20px;
    transition: all 0.3s;
    cursor: pointer;

    @include media-breakpoint(md) {
      max-width: 40px;
    }

    &.rotate,
    &.rotating {
      transform: rotate(180deg);
    }

    &.rotate-180 {
      transform: rotate(0deg);
    }
  }
}

.Irr-formative-firefox-print {
  font-size-adjust: 0.3;
  text-size-adjust: 50%;
  line-height: 1;

  .lineHeight1 {
    line-height: 1 !important;
  }

  .marginBottom0 {
    margin-bottom: 0px !important;
  }

  .footerTransformOnSingleColumn {
    transform: rotate(45deg) translate(-26px, -8px) !important;
  }

  .width-min-width-215 {
    width: 215px !important;
    min-width: 215px !important;
  }

  .height10px {
    height: 10px !important;
    margin: 0 auto !important;
  }

  //correct answers / incorrect etc
  .footer-left-div {
    margin-right: 0px !important;
    min-width: 180px !important;
    width: 180px !important;

    @include mozilla-suport {
      @media print {
        min-width: 145px !important;
        width: 145px !important;
      }
    }
  }

  .width5px {
    width: 25px !important;
  }

  .width15px-height15px {
    width: 15px !important;
    height: 15px !important;
    min-width: 15px;
    margin: 0 auto !important;
  }
}

.report {
  &__navigation {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 65px;
    bottom: 0;
    width: 120px;
    text-align: center;

    @include media-breakpoint(xl) {
      display: flex;
    }

    @include media-breakpoint(lg) {
      right: 18px;
    }

    &.summative-navigation {
      @include media-breakpoint(md) {
        transform: translateY(100%);
      }
    }

    p {
      color: $color-brown;
      line-height: 20px;
    }

    &-buttons {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    &-right {
      display: flex;
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      width: 40px;
      height: 30px;
      padding: 0;
      overflow: hidden;

      @include media-breakpoint(md) {
        width: 30px;
        height: 24px;
      }

      img {
        max-width: 44px;
        height: auto;

        @include media-breakpoint(md) {
          max-width: 32px;
        }
      }

      &:disabled {
        opacity: 0.3;
      }

      &.next {
        width: 30px;
        height: 40px;

        @include media-breakpoint(md) {
          width: 24px;
          height: 30px;
        }

        img {
          max-width: 34px;
          height: auto;

          @include media-breakpoint(md) {
            max-width: 26px;
          }
        }
      }
    }
  }

  &__Date {
    font-size: 14px;
    width: 88px;

    .DateInfo {
      position: relative;
      left: 7px;
      padding: 6px 0;
      color: #424242;

      @include media-breakpoint(md) {
        position: unset;
        left: unset;
        margin-left: 5px;
      }
    }
  }

  .itemResponseReport-Attempt {
    width: 109px;
    min-width: 109px;
    padding-left: 10px;

    .table-dropdown-toggle {
      width: 99px !important;
    }

    .table-dropdown-menu {
      min-width: 99px !important;
    }

    .table-dropdown-item {
      padding: 3px 10px !important;
    }
  }

  color: $color-white;
  @include section;
  margin-top: 22px;

  &__printHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #2e589c;
    height: 48px;
    padding-bottom: 15px;
  }

  &__bold {
    font-weight: bold;
  }

  &__color6A6A6A {
    color: $color-gray-850;
  }

  &__transparent_header {
    background-color: transparent !important;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 5px 32px 6px 15px;

    @include media-breakpoint(xl) {
      padding-right: 18px;
    }

    @include media-breakpoint(md) {
      height: auto;
      padding: 5px 15px 8px 8px;
    }

    &.based-header {
      @include custom-break-poitn(539px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &.soa-header {
      @include media-breakpoint(md) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .report__header-buttons {
        @include media-breakpoint(md) {
          order: 2;
          margin-left: auto;
        }
      }
    }

    .based-buttons {
      @include custom-break-poitn(539px) {
        margin-left: auto;
        margin-top: 8px;
      }
    }

    &-link {
      @include flexcenter-both;
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      transition: all 0.3s;
      cursor: pointer;

      @include media-breakpoint(lg) {
        min-width: 32px;
        width: 32px;
        height: 32px;
        margin-right: 4px;
      }

      &:hover {
        background-color: $color-blue-600;
      }
    }

    &-icon {
      max-width: 45px;
      margin-right: 1px;
      transition: all 0.3s;
      transform: rotate(-90deg);

      @include media-breakpoint(md) {
        max-width: 36px;
      }
    }

    &-title {
      font-family: $font-openSans;
      font-size: 22px;
      font-weight: 700;
      line-height: 29px;
      color: $color-white;

      @include media-breakpoint(xl) {
        font-size: 20px;
      }

      @include media-breakpoint(md) {
        font-size: 18px;
        line-height: 24px;
      }

      @include media-breakpoint(sm) {
        font-size: 16px;
        line-height: 21px;
      }

      .sub-title {
        @include media-breakpoint(lg) {
          display: none;
        }
      }
    }

    &-text {
      font-family: $font-openSans;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 20px;
      }

      @include media-breakpoint(sm) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &-guide {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0;

      @include media-breakpoint(xl) {
        position: relative;
        left: auto;
        top: auto;
        transform: translate(0, 0);
      }

      @include media-breakpoint(lg) {
        display: none;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
    }
  }

  // @media print {
  //   display: none;
  // }

  &-content {
    padding-top: 30px;
    background-color: $color-gray-500;
    border-radius: 8px;

    @media print {
      background-color: transparent;
      overflow: hidden;
    }
  }
  .summative-chart-legend {
    margin-bottom: 10px;
    .a1-test {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 8px;
      padding: 0 10px;
      div {
        background-color: #ffed7f;
        width: 50px;
        height: 22px;
      }
      p {
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
        color: #323233;
        margin-bottom: 0;
      }
    }
    .a2-test {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 8px;
      padding: 0 10px;
      div {
        background-color: #30dbdb;
        width: 50px;
        height: 22px;
      }
      p {
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
        color: #323233;
        margin-bottom: 0;
      }
    }
    .b-test {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 8px;
      padding: 0 10px;
      div {
        background-color: #46afb8;
        width: 50px;
        height: 22px;
      }
      p {
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
        color: #323233;
        margin-bottom: 0;
      }
    }
    .growth-test {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 8px;
      padding: 0 10px;
      div {
        background-color: #537ab7;
        width: 50px;
        height: 22px;
      }
      p {
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
        color: #323233;
        margin-bottom: 0;
      }
    }
  }
  &__performance {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 30px 15px;

    @include media-breakpoint(xl) {
      flex-direction: column;
      align-items: center;
      padding: 16px 15px;
    }

    &.summative-performance {
      @include media-breakpoint(md) {
        margin-bottom: 90px;
      }
    }

    &-guide {
      @include media-breakpoint(xl) {
        position: relative;
        left: auto;
        top: auto;
        order: -2;
        margin-bottom: 16px;
      }

      @include media-breakpoint(md) {
        max-width: 348px;
        width: 100%;
      }
    }

    &-performance {
      background-color: $color-white-C7C7C7;
      max-width: 389px;
      width: 100%;
      min-height: 142px;
      border: 1px solid $color-gray-750;
      padding: 7px 19px;

      @media print {
        background-color: transparent;
      }

      @include media-breakpoint(md) {
        padding: 6px 12px;
      }

      &.oag-performance {
        @include media-breakpoint(md) {
          margin-bottom: 90px;
        }
      }

      &.based-info {
        min-height: 94px;
        max-height: 94px;
      }

      &.summative-print {
        @include safari-suport {
          padding: 7px 16px;
        }
      }
    }

    &-average {
      background-color: $color-white-C7C7C7;
      max-width: 545px;
      width: 100%;
      border: 1px solid $color-gray-750;
      padding: 12px 10px 12px 30px;
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
      color: $color-black;
      line-height: 20px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 14px;
        text-align: center;
      }
    }

    &-percentage {
      max-width: 281px;
      margin: 8px auto 10px auto;

      @include media-breakpoint(md) {
        max-width: 250px;
      }

      @include media-breakpoint(sm) {
        max-width: 240px;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 22px;

      @include media-breakpoint(sm) {
        height: auto;
      }

      p {
        font-size: 16px;
        color: $color-black;
        line-height: 20px;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }
    }

    &-level {
      width: 16px;
      height: 16px;
      margin-right: 7px;
      box-shadow: 2px 2px 1px rgba($color-black, 0.22);

      @media print {
        box-shadow: unset;
      }

      &.advanced {
        background-color: $color-blue-800;
      }

      &.proficient {
        background-color: $color-green-600;
      }

      &.basic {
        background-color: $color-yellow-200;
      }

      &.below {
        background-color: $color-red-200;
      }
    }

    &-avg {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 16px;
      height: 16px;

      &.averagep {
        background-color: $color-blue-1000;
        margin-left: 5px;
        margin-right: 10px;

        &.ELA {
          background-color: $color-gray-1050;
        }
      }

      &.averageo {
        width: 27px;
        margin-left: -6px;
        margin-right: 10px;
      }
    }

    &-paragraph {
      font-size: 14px;
      color: $color-black;
      line-height: 20px;
      margin-bottom: 6px;
    }

    &-indicator {
      display: flex;
      align-items: center;
      max-width: 281px;
      padding-left: 5px;
      margin: 0 auto;

      &.oag-score {
        max-width: 300px;
      }
    }

    &-circle {
      @include flexcenter-both;
      min-width: 28px;
      height: 28px;
      background-color: $color-blue-800;
      border-radius: 50%;
      font-family: $font-cabin;
      font-size: 14px;
      line-height: 20px;
      margin-right: 7px;
    }

    &-indicatorText {
      font-size: 14px;
      color: $color-black;
      line-height: 20px;
      margin-bottom: 0;
    }

    &-objective {
      position: absolute;
      top: 17px;
      right: 32px;
      display: flex;
      align-items: center;

      @include media-breakpoint(xl) {
        order: -1;
        position: relative;
        top: auto;
        right: auto;
        margin-bottom: 16px;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: $color-brown;
        margin: 0 10px 0 8px;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }
    }

    &-rectangle {
      @include flexcenter-both;
      background-color: $color-green-600;
      width: 28px;
      height: 28px;
      font-family: $font-cabin;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: white;
      font-family: $font-cabin;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__search {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 11px 15px;

    @include media-breakpoint(xl) {
      height: 106px;
    }

    @include media-breakpoint(lg) {
      flex-direction: column;
      align-items: center;
    }

    &.oag-search {
      @include custom-break-poitn(504px) {
        align-items: flex-start;
        overflow-x: auto;
      }
    }

    &.formative-search {
      padding: 11px 0;
      justify-content: flex-start;
    }

    &-searcharea {
      position: absolute;
      left: 26px;
      top: 15px;
      display: flex;
      align-items: center;

      &.formative-searcharea {
        position: relative;
        left: auto;
        top: auto;
        margin-top: 4px;
      }

      @include media-breakpoint(xl) {
        left: 12px;
      }

      @include media-breakpoint(lg) {
        order: 2;
        position: relative;
        left: auto;
        top: auto;
        margin-top: 16px;
      }

      @include media-breakpoint(sm) {
        align-self: flex-start;
      }
    }

    &-icon {
      margin-right: 10px;
    }

    &-input {
      width: 212px;
      background-color: $color-white;

      @include media-breakpoint(md) {
        width: 180px;
      }

      &.margin-left {
        margin-left: 27px;
      }
    }
  }

  &__data {
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    // //FireFox
    // .withHeaderPrint.firefoxHeights {
    //   height: 1670px;
    // }

    // .withoutHeaderPrint.firefoxHeights {
    //   height: 1690px;
    // }

    // //CHROME
    // .withHeaderPrint.chromeHeights {
    //   height: 1670px;
    // }

    // .withoutHeaderPrint.chromeHeights {
    //   height: 1690px;
    // }

    &.summative-data {
      @include media-breakpoint(xl) {
        background-color: $color-gray-500;
        max-height: 1046px;
        padding-bottom: 0;
        overflow: auto;
      }
    }

    &.oag-data {
      @include media-breakpoint(xl) {
        position: relative;
        background-color: $color-gray-500;
        max-height: 1046px;
        padding-bottom: 0;
        overflow: auto;
      }
    }

    &.oag-print-overflow {
      overflow-y: unset;
    }

    &-header {
      display: flex;
      justify-content: center;
      min-width: 1242px;
      margin-top: 25px;

      &.summitive-data-header {
        @include media-breakpoint(xl) {
          position: sticky;
          top: -2px;
          background-color: $color-gray-500;
          z-index: 20;
        }
      }

      &.oag-header {
        padding-right: 12px;

        @include media-breakpoint(xl) {
          position: sticky;
          top: -2px;
          background-color: $color-gray-500;
          z-index: 20;
        }

        @include mozilla-suport {
          width: 1237px;
          padding-right: 17px;
        }
      }
    }

    &-objective {
      align-self: flex-end;
      min-width: 216px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 6px;
      margin-right: 1px;

      @include media-breakpoint(xl) {
        position: sticky;
        left: 0;
        justify-content: flex-end;
        background-color: $color-gray-500;
        height: 120px;
        z-index: 2;
        margin-top: -32px;
      }

      &.oag-objective {
        min-width: 210px;

        @include media-breakpoint(xl) {
          min-width: 235px;
          margin-left: -27px;
        }
      }

      p {
        display: flex;
        align-items: center;
        height: 21px;
        font-size: 16px;
        color: $color-gray-850;
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    &-objectiveData {
      &.font-bold {
        font-weight: bold;
      }
    }

    &-icon {
      margin-right: 7px;
      cursor: pointer;
    }

    &-questions {
      display: flex;
      flex-direction: column;
      width: 31px;
      height: 92px;
      font-size: 14px;
      line-height: 20px;

      &.oag-questions {
        height: 94px;
        position: relative;

        @media print {
          position: relative !important;
        }

        .week-label {
          position: absolute;
          top: -18px;
          background-color: $theme-bg-color-normal-variant2;
          border: 1px solid #707070;
          transform: skew(-45deg);
          width: 129px;
          left: 77px;

          p {
            margin-bottom: 0;
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            line-height: 19px;
            transform: skew(45deg);
            text-shadow: $text-shadow;
          }

          &::before {
            content: "";
            position: absolute;
            top: 19px;
            left: -1px;
            height: 18px;
            border: 1px solid #6a6969ab;
            rotate: 0.4deg;
          }
        }

        .week-label.step-2 {
          top: -43px;
          left: 102px;

          &::before {
            height: 43px;
          }
        }

        .week-label.step-3 {
          top: -68px;
          left: 127px;

          &::before {
            height: 68px;
          }
        }

        .week-label.step-4 {
          top: -94px;
          left: 153px;

          &::before {
            height: 94px;
          }
        }

        .week-label.step-5 {
          top: -120px;
          left: 179px;

          &::before {
            height: 120px;
          }
        }
      }
    }

    &-text {
      position: relative;
      width: 31px;
      height: 50px;
      text-align: right;
      color: $color-gray-850;
      border-left: 1px solid $color-gray-750;
      padding: 2px 0;
      transform: rotate(225deg) translate(-14px, 17px);
      transition: all 0.3s ease;

      @include mozilla-suport {
        transform: rotate(225deg) translate(-13.5px, 17px);
      }

      @media print {
        transform: rotate(225deg) translate(-14px, 17px);

        @include mozilla-suport {
          transform: rotate(225deg) translate(-13.7px, 17px);
        }
      }

      &:hover {
        color: darken($color-gray-850, 15%);
        border-color: darken($color-gray-750, 15%);
      }

      span {
        writing-mode: vertical-rl;
        cursor: pointer;
        transform: translateX(-9px);
        @include ellipsis;
        max-height: 50px;
      }

      &.oag-text {
        height: 70px;
        transform: rotate(225deg) translate(-29px, 0px);
        z-index: 9999;
        border-left: 0px;
        border-right: 1px solid $color-gray-750;

        @media print {
          transform: rotate(225deg) translate(-20px, 21px);
          top: 20px;
          margin-left: -5px;
        }

        span {
          writing-mode: vertical-rl;
          cursor: pointer;
          transform: translateX(0px);
          @include ellipsis;
          max-height: 74px;
        }
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      &.font-bold {
        font-weight: bold;
      }

      @-moz-document url-prefix() {
        @media print {
          width: 28px;
        }
      }
    }

    &-count {
      display: inline-block;
      height: 42px;
      text-align: center;
      font-family: $font-cabin;
      color: $color-brown;
      border-right: 1px solid $color-gray-750;

      svg {
        color: $color-gray-850;
      }

      &.oag-count {
        height: 20px;

        @media print {
          min-height: 24px !important;
          height: 24px !important;
        }
      }

      &.oag-border {
        border-right: 0px;
        border-left: 1px solid #707070;
      }
    }

    &-benchmark {
      align-self: flex-end;
      min-width: 133px;
      padding-left: 6px;
      padding-bottom: 2px;

      &.print-summative {
        min-width: 121px;
      }
    }

    &-benchmarkTitle {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;

      @media print {
        justify-content: center;

        @include safari-print {
          font-size: 9px;
        }
      }

      img {
        margin-left: 4px;
        cursor: pointer;

        @media print {
          @include safari-suport {
            width: 16px;
            height: auto;
          }
        }
      }
    }

    &-score {
      text-align: center;
      font-size: 16px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
    }

    &-summative {
      align-self: flex-end;
      min-width: 103px;
      padding: 0 2px 2px 8px;
    }

    &-summativeTitle {
      text-align: center;
      font-size: 14px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
    }

    &-proficiency {
      align-self: flex-end;
      min-width: 88px;
      text-align: center;
      font-size: 14px;
      color: $color-black;
      line-height: 20px;
      padding-left: 7px;
      position: relative;
      bottom: 4px;

      div {
        height: 37px;
        margin-top: 2px;
      }
    }

    &-Date {
      align-self: flex-end;
      min-width: 88px;
      text-align: center;
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      padding-left: 7px;
      position: relative;
      bottom: 4px;
    }

    .printDate {
      left: 10px;
    }

    .oag-proficiency {
      font-size: 14px;
      color: $color-black;
      font-weight: bold;
    }

    .average-score {
      width: 95px;
      justify-content: center;
    }
  }

  .classroom-summative-report {
    .report__data-questions {
      height: 109px;

      .report__data-text {
        height: 67px;
        transform: rotate(225deg) translate(-19px, 20px);

        span {
          max-height: 67px;
        }
      }

      .report__data-count {
        height: 21px;
      }
    }
  }

  &-table-container {
    min-width: 100%;
    animation: fadeIn 1500ms ease;
  }

  @-moz-document url-prefix() {
    &-table-container {
      animation: unset !important;
    }
  }

  &__table {
    display: block;
    width: 1242px;
    color: $color-black;
    scrollbar-width: 8px;

    @media print {
      scrollbar-width: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-black, 0.2);
    }

    &::-webkit-scrollbar {
      width: 12px;

      @media print {
        display: none;
      }
    }

    &::-webkit-scrollbar-thumb:vertical {
      width: 8px;
      border-radius: 10px;
      background-color: rgba($color-black, 0.23);
      border: 2px solid rgba($color-gray-500, 0.5);
    }

    &::-webkit-scrollbar:horizontal {
      height: 2px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      height: 2px;
      background-color: rgba($color-black, 0.23);
    }

    @media print {
      overflow: visible;
    }

    .oag_width-align {
      width: 1242px;
      padding-right: 12px;
    }

    &-oag-table-view {
      max-height: 555px;
      overflow-y: auto;

      @include media-breakpoint(xl) {
        max-height: unset;
        overflow-y: visible;
        scrollbar-width: 0;

        &::-webkit-scrollbar {
          display: none;
          width: 0;
        }
      }
    }

    &-oag-height {
      max-height: 629px;
      padding-bottom: 55px;

      @include media-breakpoint(xl) {
        max-height: unset;
        overflow: visible;
      }
    }

    &-Irr-height {
      max-height: auto;
      padding-bottom: 55px;
    }

    &-summative-table {
      max-height: 555px;
      overflow-y: auto;

      @include media-breakpoint(xl) {
        max-height: unset;
        overflow: visible;
        scrollbar-width: 0;

        &::-webkit-scrollbar {
          display: none;
          width: 0;
        }
      }
    }

    &-summative-table-height {
      max-height: 629px;
      padding-bottom: 55px;

      @include media-breakpoint(xl) {
        max-height: unset;
        overflow: visible;
      }
    }

    .dark-gray {
      background-color: $color-gray;

      @media print {
        background-color: transparent;
      }
    }

    .first-row td {
      padding-top: 1px !important;
    }

    tbody {
      width: 100%;
    }

    tr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1230px;
      padding-left: 12px;

      &.summative-row {
        @include media-breakpoint(xl) {
          width: 1240px;
          padding-right: 10px;
        }
      }

      &.oag-row {
        @include mozilla-suport {
          width: 1225px;
        }
      }

      @media print {
        width: 1242px;
        padding-left: 0;

        @include mozilla-suport {
          transform: translateX(12px);
        }
      }

      td {
        vertical-align: middle;
      }
    }

    &-name {
      min-width: 220px;
      width: 220px;
      padding: 4.5px;
      text-align: right;
      padding-right: 34px;
      line-height: 28px;

      @include safari-suport {
        padding-top: 3px;
        padding-bottom: 6px;
      }

      &.summative-table-name {
        @include media-breakpoint(xl) {
          position: sticky;
          left: 0;
          background-color: $color-gray-500;
        }

        &.dark-name {
          @include media-breakpoint(xl) {
            background-color: $color-gray;
          }
        }
      }

      &.oag-name {
        min-width: 197px;
        max-width: 197px;
        color: #101010;

        @include media-breakpoint(xl) {
          position: sticky;
          left: 0;
          background-color: $color-gray-500;
          min-width: 235px;
          max-width: 235px;
          margin-left: -39px;
        }

        &.dark-name {
          @include media-breakpoint(xl) {
            background-color: $color-gray;
          }
        }
      }
    }

    &-data {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 31px;
      min-width: 31px;
      padding: 4.5px 1.5px;

      .prof-text span {
        height: 14px;
      }
    }

    &-numbers {
      @include flexcenter-both;
      width: 28px;
      height: 28px;
      font-family: $font-cabin;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #424242 !important;
      margin: 0 !important;

      @media print {
        color: $color-black;
      }

      &.fw-12 {
        font-size: 12px !important;
      }

      &.fw-10 {
        font-size: 10px !important;
      }

      &.bg-c44538 {
        background-color: $color-red-200;
        color: white !important;
      }

      &.bg-194792 {
        background-color: $theme-bg-color-dark;
        color: white !important;
      }

      &.bg-eadb4a {
        background-color: #eadb4a;
        color: #424242 !important;
      }

      &.bg-3b792f {
        background-color: $color-green-600;
        color: white !important;
      }

      &.empty-percentage {
        color: black !important;
      }
    }

    &-benchmark {
      min-width: 111px;
    }

    &-summative {
      min-width: 128px;
      padding-right: 19px;
    }

    &-questions {
      margin-right: 6px;
    }

    &-questions-not-tested-correct {
      padding-right: 18px;
      padding-top: 8px;
      color: $color-black !important;
    }

    &-questions-not-tested-correct-bench {
      color: $color-black !important;
      padding-top: 8px;
    }

    &-questions-not-tested-benchmark {
      padding-right: 34px;
      padding-top: 8px;
      color: $color-black !important;
    }

    &-questions-not-tested-correct-bench-print {
      padding-right: 10px;
      color: $color-black !important;
    }

    &-questions-not-tested-benchmark-print {
      padding-right: 30px;
      color: $color-black !important;
    }

    &-notFound {
      font-weight: bold;
      color: $color-dark;
      line-height: 21px;
      text-shadow: $text-shadow;
      padding: 8px !important;

      &.radius {
        border-radius: 8px;
      }
    }
  }

  &__data-height {
    height: 37px;
  }

  &__tested {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1242px;
    color: $color-brown;

    &.oag-tested {
      padding-right: 103px;

      @include media-breakpoint(xl) {
        background-color: $color-gray-500;
      }

      @include mozilla-suport {
        padding-right: 107px;
        min-width: 1236px;
      }
    }

    &.gray-bg {
      @include media-breakpoint(xl) {
        background-color: $color-gray-500;
      }
    }

    &.dark-gray {
      background-color: $color-gray;

      @media print {
        background-color: transparent;
      }
    }

    &:nth-child(1) {
      background-color: rgba($color-gray-500, 0.94);
    }

    &.second-child {
      @include media-breakpoint(xl) {
        background-color: rgba($color-gray, 0.94);
      }
    }

    &-title {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 217px;
      height: 37px;
      padding: 0 4.5px;
      text-align: right;
      padding-right: 34px;
      font-weight: bold;
      line-height: 20px;
      margin-right: 1px;

      &.summative-tested-title {
        @include media-breakpoint(xl) {
          position: sticky;
          left: 0;
          background-color: $color-gray-500;
          z-index: 20 !important;
        }

        &.dark {
          @include media-breakpoint(xl) {
            background-color: $color-gray;
          }
        }
      }

      &.oag-title {
        min-width: 234px;
        margin-left: -23px;

        @include media-breakpoint(xl) {
          position: sticky;
          left: 0;
          background-color: $color-gray-500;
          z-index: 20 !important;
        }

        &.dark-bg {
          @include media-breakpoint(xl) {
            background-color: $color-gray;
          }
        }
      }

      &.oag-w-100 {
        min-width: 250px;
      }

      &.below {
        align-self: flex-start;
        z-index: 9;

        > div {
          position: relative;

          @include media-breakpoint(xl) {
            &::after {
              content: "";
              position: absolute;
              left: -38px;
              top: 32px;
              clip-path: polygon(0 0, 100% 0, 25% 100%, 0 100%);
              width: 220px;
              height: 170px;
              background-color: $color-gray-500;
            }
          }
        }
      }
    }

    &-rectangle {
      width: 28px;
      height: 28px;

      &.advanced {
        background-color: $color-blue-800;

        @media print {
          width: 27px;
          height: 27px;
          background-color: transparent;
          border: 1px dashed $color-blue-800;
        }
      }

      &.proficient {
        background-color: $color-green-600;

        @media print {
          width: 27px;
          height: 27px;
          background-color: transparent;
          border: 1px dashed $color-green-600;
        }
      }

      &.basic {
        background-color: $color-yellow-200;

        @media print {
          background-color: transparent;
          border: 1px solid $color-yellow-200;
        }
      }

      &.below {
        background-color: $color-red-200;

        @media print {
          background-color: transparent;
          border: 1px solid $color-red-200;
          transform: translateX(2px);
        }
      }
    }

    &-text {
      display: inline-block;
      width: 117px;
      padding-left: 7px;

      img {
        margin-right: 10px;
        color: $color-white;
        cursor: pointer;
      }

      &.oag-text {
        position: relative;
        width: 191px;
        padding-left: 0;

        @include media-breakpoint(xl) {
          &::after {
            content: "";
            position: absolute;
            left: -9px;
            top: 30px;
            clip-path: polygon(0 0, 100% 0, 28% 100%, 0 100%);
            width: 235px;
            height: 170px;
            background-color: $color-gray-500;
            z-index: -1;
          }
        }
      }

      &.singal {
        width: auto;
      }
    }

    &-data {
      @include flexcenter-both;
      min-width: 31px;
      height: 37px;
      border-right: 1px solid $color-gray-750;
      font-size: 16px;
      line-height: 20px;

      // remove overlapping of tooltip on OAG footer
      position: relative;
      z-index: 9999;

      &.oag-border-fix {
        border-right: 0px;
        border-left: 1px solid $color-gray-750;
      }

      &.oag-belowData {
        transform: translateX(1.8px) translateY(-0.5px);
        color: $color-red-600;

        @include mozilla-suport {
          transform: translateX(1.7px) translateY(-0.5px);
        }
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      .basic-no {
        color: $color-red-600;
        font-weight: 700;

        @media print {
          font-weight: 400;
        }
      }
    }

    &-questions {
      display: flex;
      flex-direction: column;
      min-width: 31px;
      max-width: 31px;
      z-index: 9;

      &.summative-questions {
        transform: translateY(-50px);
      }
    }

    &-objective {
      display: inline-block;
      height: 180px;
      transform: rotate(45deg) translate(-35.5px, -3px);
      padding: 2px;

      &.oag-print {
        transform: rotate(45deg) translate(-35.5px, -4px);

        @include safari-print {
          transform: rotate(45deg) translate(-36px, -3.5px);
        }

        @include mozilla-suport {
          transform: rotate(45deg) translate(-20px, 1px);
        }
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }

    &-objectiveData {
      width: 32px;
      min-height: 60px;
      border-left: 1px solid $color-gray-750;
      font-size: 14px;
      color: $color-gray-850;
      line-height: 20px;
      writing-mode: vertical-lr;
      cursor: pointer;
      transform: translate(-36px, 17px) rotate(180deg);
      padding-bottom: 16px;

      @include safari-suport {
        padding-left: 3px;
      }

      &.oag-border-fix {
        width: 21px;
        border-left: 0px;
        border-right: 1px solid $color-gray-750;
        transform: translate(-26px, 38px) rotate(180deg);
      }

      &.summative {
        @media print {
          width: 33px;
          transform: translate(-35px, 16px) rotate(180deg);
        }
      }

      &.objective {
        @media print {
          width: 33px;
          transform: translate(-35px, 16px) rotate(180deg);
        }
      }

      &.font-bold {
        font-weight: bold;
      }
    }

    &-belowData {
      display: inline-block;
      text-align: center;
      height: 45px;
      border-right: 1px solid $color-gray-750;
      padding-top: 9px;

      @include safari-suport {
        padding-top: 7px;
      }

      @media print {
        min-width: 33px !important;
        width: 33px !important;
      }

      &.basic-no {
        color: $color-red-600;
        font-weight: 700;

        @media print {
          font-weight: 400;
        }
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &-item-container {
    position: relative;
    background-color: $color-gray-500;
    margin-top: 14px;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    max-height: 1010px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media print {
      background-color: transparent;
      max-height: unset;
      overflow: visible;
    }

    &.formative-container {
      overflow: visible;
      max-height: auto;

      @include media-breakpoint(md) {
        overflow-x: auto;
      }

      .irr-summative-container {
        @include media-breakpoint(md) {
          width: 620px;
        }
      }
    }
  }

  &-item-content {
    width: 1242px;
  }

  &__numbers {
    position: sticky;
    top: -2px;
    background-color: $color-gray-500;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    width: 1242px;

    @media print {
      background-color: transparent;
      width: 100%;
      position: relative;
      top: unset;
    }

    &.formative-numbers {
      width: 100%;
      position: relative;
      top: unset;
    }

    &-name {
      min-width: 220px;
      width: 220px;
      padding: 0 45px 4px 20px;

      @include mozilla-suport {
        @media print {
          // background-color: red;
          width: 150px;
          max-width: 150px;
          min-width: 150px;
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }

      &.irr-name {
        padding-right: 38px;

        @include media-breakpoint(xl) {
          position: sticky;
          left: 0;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          background-color: $color-gray-500;
          min-width: 189px;
          width: 189px;
          padding-right: 7px;
          margin-right: 31px;
          z-index: 2;
        }
      }

      p {
        text-align: right;
        font-size: 16px;
        color: $color-black;
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    &-container {
      display: flex;
      // width: 155px;
      // min-width: 155px;

      &.formative-irr-print {
        transform: translateX(3px);
      }

      @include media-breakpoint(md) {
        min-width: 155px;
      }
    }

    &-count {
      @include flexcenter-both;
      width: 24px;
      height: 24px;
      font-family: $font-cabin;
      font-size: 14px;
      color: $color-brown;
      line-height: 20px;
      margin: 3.5px;

      &.question-bold {
        background-color: $color-gray-850;
        color: $color-white;
        border-radius: 50%;
      }

      &.question-border {
        background-color: $color-gray-850;
        border-radius: 50%;
        color: $color-white;
      }
    }

    &-attempt {
      min-width: 101px;
      width: 101px;
      padding-left: 34px;
      text-align: center;
      font-size: 16px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      padding-bottom: 5px;

      @include mozilla-suport {
        @media print {
          min-width: 75px;
          width: 75px;
        }
      }
    }

    &-score {
      min-width: 148px;
      width: 148px;
      padding-right: 29px;
      text-align: center;
      font-size: 16px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      padding-bottom: 5px;

      &.irr-score {
        min-width: 135px;
        width: 135px;
        padding-right: 16px;
      }

      &.irr-print-score {
        padding-right: 0;
      }

      &.formative-empty {
        min-width: 122px;
        width: 122px;
      }

      &.formative-score {
        padding-right: 0;
        min-width: 130px;
        width: 130px;
      }
    }
  }

  &__itemTable {
    display: block;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    tr {
      position: relative;
      width: 1242px;
      padding-left: 0;
    }

    td {
      vertical-align: middle;
      padding-top: 6.5px;
      padding-bottom: 6.5px;

      @include safari-suport {
        padding-top: 6px;
        padding-bottom: 7px;
      }
    }

    .data-report__numbers-container {
      width: 155px;
      min-width: 155px;
    }

    .dark-gray {
      background-color: $color-gray;
    }

    &-name {
      min-width: 220px;
      max-width: 220px;
      text-align: right;
      font-size: 16px;
      color: $color-dark-200;
      line-height: 24px;
      padding: 0 24px 0 20px;

      @include mozilla-suport {
        @media print {
          // background-color: yellow;
          width: 150px;
          max-width: 150px;
          min-width: 150px;
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }

      &.radius {
        border-radius: 8px 0 0 8px;
      }

      &.irr-table-name {
        @include media-breakpoint(xl) {
          position: sticky !important;
          left: 0;
          min-width: 190px;
          width: 190px;
          background-color: $color-gray-500;
          padding-right: 9px;
          margin-right: 34px;
          z-index: 2;
        }
      }

      &.dark-gray {
        background-color: $color-gray;

        @media print {
          background-color: transparent;
        }
      }
    }

    &-numbers {
      min-width: 31px;
      width: 31px;
      max-height: 37px;
      font-family: $font-cabin;
      font-size: 14px;
      color: $color-white;
      line-height: 20px;
      padding: 0;
    }

    &-rectangle {
      @include flexcenter-both;
      width: 24px;
      height: 24px;
      margin: 0 3.5px;

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      &.low {
        background-color: $color-red-600;
      }

      &.high {
        background-color: $color-green;
      }

      &.unattempted {
        background-color: $color-gray-800;
      }
    }

    &-attempt {
      min-width: 109px;
      width: 109px;
      padding-left: 16px;
      text-align: center;
      font-size: 16px;
      color: $color-dark-200;
      line-height: 20px;

      &.formative-attempt {
        min-width: 109px;
        width: 109px;
        padding-left: 10px;

        @include mozilla-suport {
          @media print {
            min-width: 95px;
            width: 95px;
          }
        }
      }

      .variant {
        display: block;
        margin-left: 4px;
        border: 1px solid #3260d4;
        border-radius: 8px;
        padding: 1px 6px;
        font-size: 12px;
        line-height: 16px;
        color: #3260d4;
        text-align: center;
        height: 19px;

        @media print {
          padding: 2px 6px 0px;
        }

        @-moz-document url-prefix() {
          @media print {
            padding: 0px 6px;
          }
        }
      }
    }

    &-score {
      min-width: 148px;
      width: 148px;
      text-align: center;
      font-size: 16px;
      color: $color-dark-200;
      line-height: 20px;

      &.score-align {
        min-width: 130px;
        width: 130px;
        text-align: right;
        padding-left: 15px;
        padding-right: 10px;

        @media print {
          text-align: center;
          padding-left: 0;
          padding-right: 0;
        }

        span {
          &:first-child {
            padding-left: 10px;
            display: inline-block;

            @media print {
              padding-left: 0;
            }
          }

          &:last-child {
            width: 62px;
            display: inline-block;
            padding-left: 0;
            margin-left: auto;
            margin-left: 4px;
            text-align: left;

            @media print {
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }

      &.radius {
        border-radius: 0 8px 8px 0;
      }

      &.irr-table-score {
        min-width: 135px;
        width: 135px;
      }
    }
  }

  &__total {
    display: flex;
    align-items: center;
    background-color: $color-gray-500;

    @media print {
      background-color: transparent;
    }

    &.opacity-94 {
      background-color: rgba($color-gray-500, 0.94);

      @media print {
        background-color: transparent;
      }
    }

    &.dark-gray {
      background-color: $color-gray;

      @media print {
        background-color: transparent;
      }

      &.opacity-94 {
        background-color: rgba($color-gray, 0.94);

        @media print {
          background-color: transparent;
        }
      }
    }

    &.radius {
      border-radius: 8px;
    }

    &.dark-after {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 37px;
        background-color: $color-gray;
        border-radius: 8px;
      }
    }

    &-title {
      position: relative;
      min-width: 220px;
      width: 220px;
      text-align: right;
      font-size: 16px;
      color: $color-brown;
      font-weight: bold;
      line-height: 20px;
      padding: 0 24px 0 20px;
      z-index: 4;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include mozilla-suport {
        @media print {
          // background-color: red;
          width: 150px;
          max-width: 150px;
          min-width: 150px;
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }

      .variant {
        background: #3260d4;
        padding: 2px 6px;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff !important;
        font-weight: 400;
        border-radius: $border-radius-md;
      }

      &.incorrect {
        align-self: flex-start;
        padding-top: 6.5px;
        padding-bottom: 6.5px;

        @include media-breakpoint(xl) {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 37px;
            clip-path: polygon(0 0, 100% 0, 26% 100%, 0 100%);
            width: 189px;
            height: 150px;
            background-color: $color-gray-500;
          }
        }
      }

      &.formative-title {
        padding-right: 24px;

        .variantChip {
          background: #3260d4;
          padding: 2px 9px;
          font-size: $sm;
          line-height: 19px;
          color: #ffffff;
          font-weight: 400;
          border-radius: $border-radius-md;
        }

        .variant {
          display: inline-block;
          margin-left: 9px;
          border: 1px solid #3260d4;
          border-radius: 8px;
          padding: 1px 6px;
          font-size: 12px;
          line-height: 16px;
          color: #3260d4;
          text-align: center;
          height: 19px;
          font-weight: normal;
        }
      }

      &.irr-total-title {
        padding-right: 44px;

        @include media-breakpoint(xl) {
          position: sticky;
          position: -webkit-sticky;
          background-color: $color-gray-500;
          left: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          min-width: 189px;
          width: 189px;
          height: 37px;
          padding-right: 9px;
          margin-right: 36px;
          z-index: 8;
        }
      }

      &.dark {
        background-color: $color-gray;
      }
    }

    &-rectangle {
      display: inline-block;
      min-width: 24px;
      height: 24px;
      margin-right: 7px;

      &.low {
        background-color: $color-red-600;
      }

      &.high {
        background-color: $color-green;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 4;
      // min-width: 155px;
      // width: 155px;
      height: 100%;

      &__DateEx {
        font-size: 10px;

        &__day {
          position: relative;
          top: -6px;
        }

        &__slash {
          background-color: $color-brown;
          transform: rotate(222deg);
          position: absolute;
          height: 14px;
          width: 0.5px;
          top: 13px;

          @media PRINT {
            width: 0.2px;
          }
        }

        &__month {
          position: relative;
          top: 8px;
        }
      }
    }

    &-total {
      @include flexcenter-both;
      min-width: 31px;
      width: 31px;
      height: 37px;
      border-right: 1px solid $color-gray-750;
      font-size: 16px;
      color: $color-brown;
      line-height: 20px;

      &.answer {
        margin-top: 0px;
        height: 100%;
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }

    &-incorrect {
      position: relative;
      display: flex;
      z-index: 4;
    }

    &-incorrectContainer {
      display: flex;
      flex-direction: column;
      min-width: 31px;
      width: 31px;
    }

    &-empty {
      min-width: 31px;
      width: 31px;
      height: 8px;
      border-right: 1px solid $color-gray-750;
    }

    &-objective {
      display: inline-block;
      width: 31px;
      height: 180px;
      transform: rotate(45deg) translate(-35.5px, -4px);
      padding: 2px;

      @media print {
        transform: rotate(45deg) translate(-35px, -4px);

        @include mozilla-suport {
          transform: rotate(45deg) translate(-34.5px, -3.5px);
        }
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }

    &-objectiveData {
      width: 18px;
      min-height: 60px;
      border-left: 1px solid $color-gray-750;
      font-size: 14px;
      color: $color-gray-850;
      line-height: 20px;
      writing-mode: vertical-lr;
      cursor: pointer;
      transform: translate(-22px, 17px) rotate(180deg);
      padding-bottom: 16px;

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      &.font-bold {
        font-weight: bold;
      }
    }
  }

  &__levels {
    display: flex;
    justify-content: center;
    padding: 31px 15px;

    &.based__levels {
      @media print {
        padding: 24px 15px;
      }
    }

    &-container {
      width: 371px;

      &.download {
        width: 280px;
      }
    }

    &-title {
      text-align: center;
      font-size: 16px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;

      @media print {
        @include mozilla-suport {
          font-size: 16px;
          line-height: 20px;
        }
      }

      @include media-breakpoint(md) {
        font-size: 14px;
      }
    }

    &-percentage {
      width: 285px;
      margin: 0 auto;
      padding: 10px 5px 10px 0;

      @media print {
        @include mozilla-suport {
          width: 285px;
        }
      }

      @include media-breakpoint(md) {
        width: 230px;
      }

      &.download-percentage {
        max-width: 186px;
        padding: 0;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 22px;

      .box {
        width: 18px !important;
        height: 18px !important;
      }

      p {
        font-size: 16px;
        color: $color-black;
        line-height: 20px;
        margin-bottom: 0;

        @media print {
          @include mozilla-suport {
            font-size: 14px;
            line-height: 18px;
          }
        }

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      .cheked-mark {
        transform: translateY(2px);
        width: 20px !important;
        height: 20px !important;
        padding-left: 4px;
      }
    }

    &-rectangle {
      min-width: 16px;
      width: 16px;
      height: 16px;
      margin-right: 7px;
      box-shadow: 2px 2px 1px rgba($color-black, 0.22);

      @media print {
        box-shadow: unset;
      }

      &.advanced {
        background-color: $color-blue-800;
      }

      &.proficient {
        background-color: $color-green-600;
      }

      &.basic {
        background-color: $color-yellow-200;
      }

      &.below {
        background-color: $color-red-200;
      }
    }

    &-text {
      text-align: center;
      font-size: 14px;
      color: $color-black;
      line-height: 20px;
      margin-bottom: 4px;

      @include media-breakpoint(md) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &-number {
      @include flexcenter-both;
      width: 31px;
      height: 27px;
      background-color: $color-gray-900;
      border-radius: 8px;
      margin-right: 7px;

      @include media-breakpoint(md) {
        width: 26px;
        height: 24px;
        margin-right: 5px;
      }
    }
  }

  // IRR Formative Report
  &__formative {
    max-width: 614px;
    margin: 0 auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include media-breakpoint(md) {
      max-width: 636px;
      padding: 0 16px;
    }

    &.irr-summative {
      @include media-breakpoint(md) {
        padding: 0;
      }

      .report__search {
        padding-left: 16px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-table {
      max-height: 555px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .Collapsible__trigger.is-open {
        display: block;
      }

      .Collapsible__trigger.is-closed {
        display: block;
      }

      &.table-height {
        max-height: 629px;
        padding-bottom: 55px;
      }

      tbody {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        tr {
          width: 100%;
        }
      }
    }
  }

  &__footer {
    width: 1242px;
    background-color: $color-gray-500;

    &.formative-footer {
      width: 100%;
    }

    &.move-up {
      transform: translateY(-55px);
      opacity: 0.94;

      @include media-breakpoint(xl) {
        transform: translateY(0);
        margin-top: -55px;
      }
    }

    &.irr-footer {
      position: sticky;
      bottom: 0;
      background-color: transparent;
      opacity: 1;
      z-index: 6;
      height: 281px;
    }

    &.summative-footer {
      @include media-breakpoint(xl) {
        position: sticky;
        bottom: 0;
        background-color: transparent;
        opacity: 1;
      }
    }

    &.summative-move-up {
      @include media-breakpoint(xl) {
        transform: translateY(0);
        margin-top: -55px;
      }
    }

    &.oag-footer {
      @include media-breakpoint(xl) {
        position: sticky;
        bottom: 0;
        background-color: transparent;
        opacity: 1;
      }
    }

    &.oag-move-up {
      @include media-breakpoint(xl) {
        transform: translateY(0);
        margin-top: -55px;
      }
    }
  }

  &__chart {
    padding: 0px 20px 32px 20px;
    margin-top: -23px;

    &-container {
      max-width: 1012px;
      height: 100%;
      max-height: 500px;
      margin: 0 auto;
    }

    &-skelton-item {
      width: 11.1%;
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }
    }
  }


  &__printChartSummative{
    padding: 17px 20px 17px 20px;

    &-containerSummativeChart {
      max-width: 920px;
      margin: 0 auto;
      @include mozilla-suport {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 100%;
      }
    }
    &-chart {
      width: 100%;

      @media print {
        @include mozilla-suport {
          transform: scale(0.8) translateY(-70px);
        }
      }
    }

  }


  &__printChart {
    padding: 27px 20px 27px 20px;

    &-container {
      max-width: 800px;
      margin: 0 auto;

      @include mozilla-suport {
        display: grid;
        grid-template-columns: 1fr;
        max-width: 100%;
      }
    }
    // &-containerSummativeChart {
    //   max-width: 920px;
    //   margin: 0 auto;
    //   @include mozilla-suport {
    //     display: grid;
    //     grid-template-columns: 1fr;
    //     max-width: 100%;
    //   }
    // }

    &-chart {
      width: 100%;

      @media print {
        @include mozilla-suport {
          transform: scale(0.8) translateY(-70px);
        }
      }
    }
  }
}

.soa-header {
  .summative-objective-analysis__header-center {
    width: auto;
    margin-left: auto;
    margin-right: 20px;

    .table-dropdown {
      .table-dropdown-toggle {
        border-color: white !important;
        background-image: url(../../../img/select-indicator.svg);
        background-position-y: 12px;
        padding: 6px;
        border-radius: 9px;
      }
    }
  }
}

// =================== OAG Report Styling
.oag-report {
  &__footer-objectives {
    background-color: transparent !important;
    position: relative;

    @include media-breakpoint(xl) {
      background-color: $color-gray-500 !important;
    }

    &.dark::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 37px;
      background-color: $color-gray;
    }
  }
}

.prof-text span {
  height: 14px;
}

.prof-text .small-text {
  justify-content: flex-end;
  padding-right: 4px;
  padding-top: 1px;
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
  color: #424242;
  font-family: $font-cabin;
  justify-content: flex-end;

  @media print {
    @include safari-print {
      transform: translateY(1px);
      font-size: 8px;
    }
  }
}

.proficiency-wrapper {
  padding-right: 15px;
}

.visibility-hidden {
  visibility: hidden;
}

.noObjective {
  color: $color-brown !important;
}

.oag-acitive-tab {
  background: $theme-bg-color-dark;
}

.report__table .oag_width-align tr .report__table-data {
  padding-top: 0px;
  padding-bottom: 0px;
}

.report__table .oag_width-align tr {
  padding-top: 4.5px;
  padding-bottom: 4.5px;

  @media print {
    @include mozilla-suport {
      transform: translateX(-1px);
    }
  }
}

.oag-no-border {
  border-right: 0px !important;
}

.oag-score .report__performance-indicatorText {
  font-size: 12px;
}

.oag-score .report__performance-indicatorText span {
  border-color: $color-gray-850 !important;
  position: relative;
  top: -2px;
  width: 7px;
  margin: 0 2px;
}

.report__performance-percentage.max-width {
  max-width: 290px;

  @include media-breakpoint(md) {
    max-width: 260px;
  }
}

.oag-score .report__performance-rectangle {
  flex-direction: column;
  background-color: #eadb4a;

  span {
    background-color: transparent !important;
  }

  @media print {
    background-color: transparent;
    border: 1px dashed $color-blue-800;
  }
}

.report__performance-objective.oag-nn-bg .report__performance-rectangle {
  background-color: #eadb4a;
  color: #424242;
}

.oag-prof-bg {
  display: inline-block;
  background-color: $color-blue-800;
  width: 28px;
  height: 28px;

  &.header-prof {
    margin-right: 7px;
    width: 16px !important;
    height: 16px !important;
  }

  @media print {
    background-color: transparent;
    // border: 1px dashed $color-blue-800;
  }

  &.shrink-border-oag {
    @media print {
      border-style: dotted;
    }
  }
}

.oag-non-prof {
  background-color: $color-red-200;

  @media print {
    background-color: transparent;
    border: 1px solid $color-red-200;
    width: 16px !important;
    height: 16px !important;
  }
}

.footer-non-prof {
  display: inline-block;
  border: 1px solid $color-red-200;
  width: 28px;
  height: 28px;
}

// ==================== Print Styling
.print {
  box-shadow: none;

  &__header {
    &-title {
      color: $color-black;
    }
  }

  &__itemTable {
    &-rectangle {
      color: $color-red-600;
      border: 0.5px solid $color-red-600;
      box-shadow: none;
    }

    &-check {
      color: $color-green-400;
      border: none;
      font-size: 11px;
    }

    &-dash {
      color: $color-gray-800;
      font-size: 8px;
    }
  }
}

// Items report animation
.faded-right-boxes {
  animation: fadeInRight 1s ease-in-out;
}

.faded-left-boxes {
  animation: fadeInLeft 800ms ease-in-out;
}

.report__itemTable-numbers img {
  display: none;
}

.irr-date {
  display: none;
}

.irr-main-div {
  width: 100%;
}

.irr-tick {
  display: none;
}

.header-print {
  display: none;
}

.container-2 {
  display: none;
}

.font-icon-dash {
  display: none;
}

.hidden {
  visibility: hidden;
}

.hide {
  display: none;
}

.Download-pdf-header {
  .print-report__header {
    position: relative;
    font-family: $font-openSans;
    border-bottom: 1px solid $theme-border-color !important;
    padding-bottom: 6px;
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;

      @include mozilla-suport {
        background-color: $theme-bg-color-dark;
      }
    }

    .title {
      font-family: $font-openSans;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @include safari-print {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-text {
      font-family: $font-openSans;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0;

      @include safari-print {
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
}

@media print {
  .report__search,
  .report__header-link,
  .footer__content-description,
  .report__header-buttons {
    display: none;
  }

  // .standard-based-report {
  //   margin-left: 65px !important;
  //   margin-right: 65px !important;
  // }

  .print-report__header {
    position: relative;
    font-family: $font-openSans;
    border-bottom: 1px solid $theme-border-color !important;
    padding-bottom: 6px;
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;

      @include mozilla-suport {
        background-color: $theme-bg-color-dark;
      }
    }

    .title {
      font-size: $md;
      font-weight: 700;
      line-height: 19px;
      font-family: $font-openSans;
    }

    .title {
      font-size: $lg;
      font-weight: 700;
      line-height: 25px;
      font-family: $font-openSans;

      @include mozilla-suport {
        font-size: 19px;
        line-height: 23px;
      }

      &.irr-print {
        @include mozilla-suport {
          font-size: 22px !important;
          line-height: 29px !important;
        }
      }
    }

    &-text {
      font-size: $md !important;
      font-weight: 400 !important;
      line-height: 22px;
      font-family: $font-openSans;
      margin-bottom: 0 !important;

      @include safari-suport {
        font-weight: 500 !important;
      }

      @include mozilla-suport {
        font-size: 17px !important;
        line-height: 18px !important;
      }

      &.irr-print {
        @include mozilla-suport {
          font-size: 16px !important;
          line-height: 21px !important;
        }
      }
    }

    .text-right {
      .print-report__header-text.text-right {
        font-size: $md !important;
        font-weight: 400 !important;
        line-height: 19px;
        font-family: $font-openSans;
        margin-top: 2px;
      }
    }
  }

  .report__total-total {
    width: 31.7px;
    height: 37px;

    @include mozilla-suport {
      @media print {
        width: 31px !important;
        min-width: 31px;
        // background-color: red !important;
      }
    }
  }

  .oag_table_width table tr {
    width: unset !important;
  }

  .report__numbers.irr {
    margin-left: 6px;

    @include mozilla-suport {
      margin-left: 0;
    }
  }

  .report__numbers-count {
    width: 24.8px;

    @include safari-suport {
      padding-left: 0.8px;
    }

    @include mozilla-suport {
      width: 24.6px !important;
      margin: 3px 3.2px !important;
      // background-color: red;
    }
  }

  .report__total-incorrectContainer {
    width: 31.7px;

    @include mozilla-suport {
      @media print {
        width: 31px !important;
        min-width: 31px;
        // background-color: red !important;
      }
    }
  }

  .irr-report-break {
    page-break-after: always;
  }

  .footer__content {
    box-shadow: none;
  }

  .hide {
    display: none;
  }

  .report-item-container {
    width: 95%;
  }

  .report {
    box-shadow: none;
  }

  .report__itemTable-numbers img {
    display: block;
  }

  .report__itemTable-rectangle.low {
    color: $color-red-600;
    border: 0.5px solid $color-red-600;
    box-shadow: none;
  }

  .irr-date {
    display: block;
    margin-left: 800px;
    text-align: end;
  }

  .irr-date .report__header-title {
    margin-left: 40px;
  }

  .irr-date .report__header-text {
    margin-left: 10px;
  }

  .response-first .report__header-title {
    font-size: $lg;
    line-height: 25px;
  }

  .report__header-text {
    font-size: $md;
    font-weight: 400;
    line-height: 22px;
    font-family: $font-openSans;
  }

  .print__header-title {
    font-size: $md;
    font-weight: 700;
    line-height: 19px;
    font-family: $font-openSans;
    text-align: right;
  }

  .report__header-text.print__header-title {
    font-weight: 400;
    margin-top: 2px;
  }

  .report__total-title .low {
    border: 0.5px solid $color-black;
  }

  .irr-tick {
    display: block;
  }

  .report {
    overflow: unset;
  }

  .report-item-container {
    overflow-x: unset;
  }

  table tbody {
    overflow: unset;
  }

  body {
    zoom: 60%;
  }

  .report__itemTable-numbers {
    padding-left: 0.2px;
    width: 28px;

    @include safari-print {
      min-width: 31.5px;
      width: 31.5px;
    }
  }

  .report__itemTable-rectangle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-white !important;
    width: 24.6px;
    height: 24px;

    @include safari-print {
      width: 24.5px;
    }

    @include mozilla-suport {
      width: 24.6px;

      @media print {
        margin: 0 2.5px !important;
        // background-color: red !important;
      }
    }
  }

  .yellowMellow {
    background-color: yellowgreen;
  }

  .irr-print-mozilla {
    @include mozilla-suport {
      height: 1650px;
      border: 1px solid transparent;
    }
  }

  .border-proficient {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px dashed $color-blue-800;
    min-width: 26px;
    height: 26px;

    &.print-multiple {
      background-color: $color-white !important;
      min-width: 26px;
      height: 26px;
      border: 1px dashed $color-gray;
    }

    &.large-box {
      width: 28px;
      height: 28px;
    }

    &.small-box {
      width: 28px;
      height: 30px !important;
    }
  }

  .border-non-proficient {
    border: 0.5px solid $color-red-200;
  }

  .header-print {
    display: block;
    color: $color-black;
    margin-left: 350px;
  }

  .report__table .oag_width-align tr {
    width: 1242px;
    padding-right: 12px !important;
  }

  .report__performance-objective {
    display: none;
  }

  .report__performance-performance {
    border: unset;
  }

  .data-text-print {
    display: block;
  }

  .report__table-data {
    max-width: 35px;
    min-width: 35px;
    padding: 4.5px 0px;
    margin-right: 1.5px;
  }

  .print-table-body-container {
    height: 100%;
  }

  .print-table-body {
    height: 100%;
    overflow: unset;
  }

  .report__data-questions {
    margin-right: 1.4px;
    min-width: 35px;
    width: 35px;

    @media print {
      position: relative;
    }
  }

  .report__table-data.summative-report-print {
    text-align: center;
    margin-right: 1.5px;
    padding-left: 2.5px !important;
    min-width: 35px;
    width: 35px;
  }

  .report__data-questions.oag-report-question {
    width: 31px !important;
    min-width: 31px !important;
    margin-right: 1.5px !important;

    .week-label {
      position: absolute;
      top: -18px;
      background-color: #1f4397;
      border: 1px solid #707070;
      transform: skew(-45deg);
      width: 129px;
      left: 106px;

      @media print {
        background-color: #1f4397 !important;
        top: -19px;
        left: 77px;

        @media (max-width: 991px) {
          top: -17px;
          left: 77px;
        }
      }

      @-moz-document url-prefix() {
        top: -15px;
      }

      @-moz-document url-prefix() {
        @media print {
          top: -17.5px;
          left: 78px;
        }
      }

      p {
        margin-bottom: 0;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        transform: skew(45deg);
        text-shadow: $text-shadow;

        @media print {
          color: #ffffff !important;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 19px;
        left: -1px;
        height: 18px;
        border: 1px solid #6a6969ab;
        transform: skew(-3deg);

        @media print {
        }
      }
    }

    .week-label.step-2 {
      top: -43px;
      left: 102px;

      &::before {
        height: 43px;
      }
    }

    .week-label.step-3 {
      top: -68px;
      left: 127px;

      &::before {
        height: 68px;
      }
    }

    .week-label.step-4 {
      top: -94px;
      left: 154px;

      &::before {
        height: 94px;
      }
    }

    .week-label.step-5 {
      top: -120px;
      left: 182px;

      &::before {
        height: 120px;
      }
    }

    @media print {
      @include safari-suport {
        transform: translateY(24px);
      }
    }
  }

  .oag-report .report__tested-data {
    width: 31px !important;
    min-width: 31px !important;
    margin-right: 1.5px !important;
  }

  .report__tested {
    margin-left: 12px !important;
  }

  .report__tested.oag-report {
    position: relative;
    left: -60px !important;
  }

  .print-report-last-row.report__tested-questions .report__tested-objective {
    margin-right: 1.5px !important;
    min-width: 34px !important;
    width: 34px !important;
  }

  .report__tested-questions {
    margin-right: 1.5px;
    min-width: 34px !important;
    width: 34px !important;
  }

  .report__tested-title {
    padding-right: 25px;
  }

  .report__tested-data {
    margin-right: 1.5px !important;
    min-width: 35px !important;
    width: 35px !important;
  }

  .oag-header-width {
    width: auto;
    margin-left: 230px !important;
  }

  .report__data-objective.oag-object {
    display: none;
  }

  .oag-print-table-body .report__table-data {
    margin-right: 1.5px;
    width: 31px;
    min-width: 31px;
  }

  .oag-proficiency {
    position: relative;
    left: 40px;
  }

  .report__table-benchmark div {
    justify-content: center;
  }

  .irrt-print {
    display: block;
  }

  .container-2 {
    display: block;
  }

  .faded-right-boxes,
  .faded-left-boxes {
    animation: unset;
  }

  .report__itemTable-rectangle.high {
    background: none;
    border: unset;
  }

  .font-icon-tick {
    color: $color-green-400;
    display: block;
    font-size: 11px;
  }

  .summative-font-icon-tick {
    color: $color-green-400;
  }

  .font-icon-dash {
    color: $color-gray-800;
    display: block;
    font-size: 8px;
  }

  .correcticon-tick {
    margin-top: 8px;
    margin-left: 7px;
  }

  .correct-bold {
    font-weight: 600;
  }

  .hiddenContent {
    visibility: visible;
  }

  .next_page_break {
    page-break-before: always;
  }

  .summative-header {
    width: 300px !important;
  }

  .oag-header {
    width: 350px !important;
  }

  .data-text-print.print-note {
    width: 340px;
  }

  .print-oag-score .report__table-numbers {
    color: $color-gray-900;
  }

  .report__data-text {
    margin-left: 4px;
  }

  .prof-text .small-text {
    height: 14px;
  }

  .oag-report-footer {
    position: fixed;
    bottom: 20px;
    left: 25%;
    transform: translateX(25%);
  }

  .oag-report-footer .oag-report-asterik {
    margin-left: 0px;
  }

  .summative-border-proficient {
    border: 1px dashed $color-green-600;
    font-size: 12px;
  }

  .summative-border-advanced {
    border: 1px dashed $color-blue-800;
    font-size: 12px;
    margin-right: 7px;

    @include safari-suport {
      font-size: 9px;
      font-weight: 300 !important;
    }

    &.rectangle {
      border-radius: 0;
    }

    &.circle-nn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      min-width: 32px;
      height: 32px;
    }
  }

  .summative-border-basic {
    font-size: 12px;
    border: 1px solid $color-yellow-200;
    min-height: 17px;
  }

  .summative-border-belowBasic {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 1px solid $color-red-200;
    min-height: 16px;
  }

  .color-3e3e3e {
    color: $color-gray-900;
  }

  .print-report-last-row {
    margin-top: 50px;
  }

  .report__data-objective {
    width: 230px !important;
    min-width: 230px !important;
    max-width: 230px !important;
    margin-right: 13px;
  }

  .report__table-name {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
    padding-right: 15px;
    margin-right: 5px;
  }

  .report__table-name.oag-name {
    width: 286px !important;
    min-width: 286px !important;
    max-width: 286px !important;
    padding-right: 39px;
    margin-right: 13px;
  }

  .report__tested-questions {
    margin-right: 1.5px !important;
    min-width: 35px !important;
    width: 35px !important;
  }

  .header-print.summative-class-format {
    margin-left: 750px;
  }

  .summative-date-format {
    text-align: right;
  }

  .oag-report .report__tested-questions {
    margin-right: 1.5px !important;
    min-width: 31px !important;
    max-width: 31px !important;
    transform: translateX(-1.8px) !important;
  }

  .print-report-last-row.report__tested-questions {
    width: 35px !important;
    min-width: 35px !important;
    margin-right: 1.5px !important;
  }

  .footer__content-help {
    width: 348px;
    text-align: left;
    letter-spacing: 0px;
    color: $color-gray-850;
    opacity: 1;
  }

  .report__tested-title.oag-w-100 {
    position: relative;
    left: -20px;
  }

  .print-report-last-row.oag-footer-set {
    width: 31px;
    min-width: 31px;
    margin-right: 1.5px;
  }

  .report__tested-objective.print__objectiveData {
    position: relative !important;
    left: 5px !important;
  }

  .report__tested.oag-report {
    justify-content: flex-start;
    margin-left: 110px !important;
  }

  .report__data-header.oag-header {
    justify-content: flex-start;
    margin-left: 68px;
  }

  .report__tested.oag-report .report__tested-title.oag-w-100 {
    padding-right: 20px;
    min-width: 230px !important;
    margin-right: 12px;
  }

  .report__data-questions.oag-report-question .report__data-text {
    transform: rotate(225deg) translate(-20.7px, 19.5px);
    position: relative;
    left: -1px;

    @include mozilla-suport {
      transform: rotate(225deg) translate(-20px, 20px);
    }
  }

  .report__data {
    overflow: visible;
  }

  .class-summative-report-print {
    overflow: hidden;

    .report__table {
      margin-left: auto;
      margin-right: auto;
    }
  }

  // @supports (-ms-ime-align: auto) {
  //   .report__data {
  //     overflow: hidden;
  //   }
  // }

  .report__data-header.oag {
    justify-content: flex-start;
    margin-left: 67px;
  }

  .report__tested-title.oag-w-100 {
    margin-right: 20px !important;
  }
}

@media print {
  @-moz-document url-prefix() {
    .report__total-count {
      display: flex !important;
      align-items: center !important;
      position: relative !important;
      z-index: 4 !important;
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .report__tested-questions {
      margin-right: 0.5px !important;
      width: 32px !important;
      min-width: 32px !important;
      max-width: 32px !important;
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .report__tested.oag-report .report__total-count .report__tested-objective {
      top: 0px !important;
      margin-top: -12px !important;
      margin-left: -10px !important;
    }
  }
}

.oag-test-date {
  margin-left: 38.1px;
}

.bottom-11px {
  bottom: 11.5px !important;
}

// Irr formative report (#coirrect score column adjustments)
.paddingRight-0 {
  padding-right: 15px !important;
  padding-left: 0px !important;

  @include mozilla-suport {
    padding-right: 50px !important;
  }
}

.paddingLeft-10 {
  padding-left: 15px !important;
  text-align: left !important;
}

.prof-text {
  .small-font {
    font-size: 12px;
    line-height: 14.58px;
  }
}

.oag-header .week-label:nth-child(2) {
  background-color: yellow;
}

.summative-chart-legend-print {
  margin-top: 54px;
  .a1-test {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 8px;
    padding: 0 10px;
    div {
      background-color: #ffed7f;
      width: 50px;
      height: 22px;
    }
    p {
      font-size: 17px;
      line-height: 23px;
      font-weight: 500;
      color: #323233;
      margin-bottom: 0;
    }
  }
  .a2-test {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 8px;
    padding: 0 10px;
    div {
      background-color: #30dbdb;
      width: 50px;
      height: 22px;
    }
    p {
      font-size: 17px;
      line-height: 23px;
      font-weight: 500;
      color: #323233;
      margin-bottom: 0;
    }
  }
  .b-test {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 8px;
    padding: 0 10px;
    div {
      background-color: #46afb8;
      width: 50px;
      height: 22px;
    }
    p {
      font-size: 17px;
      line-height: 23px;
      font-weight: 500;
      color: #323233;
      margin-bottom: 0;
    }
  }
  .growth-test {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 8px;
    padding: 0 10px;
    div {
      background-color: #537ab7;
      width: 50px;
      height: 22px;
    }
    p {
      font-size: 17px;
      line-height: 23px;
      font-weight: 500;
      color: #323233;
      margin-bottom: 0;
    }
  }
}

.formative-test,
.running-rate {
  @include flex-box(flex-start, center);
  gap: 0 8px;
  padding: 0 10px;

  div {
    width: 50px;
    height: 16px;
  }

  p {
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    color: $color-gray-950;
    margin-bottom: 0;
  }
}

.formative-test {
  div {
    background-color: $yellow-200;
  }
}

.running-rate {
  div {
    height: 16px;
    background-color: $color-blue-100;
  }
}

///////////////////////////////////////////////
