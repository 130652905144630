.edit-answers{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 310px;
    padding: 5px 10px;
    position: relative;

    @media only screen and (max-width: 894.99px) {
        padding: 5px 0;
    }

    @include media-breakpoint(md) {
        height: 404px;
    }

    @include custom-break-poitn(615px) {
        height: 524px;
    }

    @include custom-break-poitn(489px) {
        height: 764px;
    }

    &.small{
        align-items: center;
        height: 160px;
        padding-bottom: 0;
        padding-right: 32px;

        @include custom-break-poitn(375px) {
            height: 190px;
            padding-top: 40px;
        }
    }

    .dropdown.table-dropdown {
        position: absolute;
        left: 0;
        top: 6px;
    } 

    &__item{
        display: flex;
        justify-content: center;
        font-family: $font-cabin;
        margin: 3px 9px;

        @media only screen and (max-width: 894.99px) {
            margin: 3px 4px;
        }

        &-count{
            @include flexcenter-both;
            width: 24px;
            height: 24px;
            font-size: 14px;
            color: $color-white;
            margin-right: 3px;
        }

        &-answer{
            @include flexcenter-both;
            width: 24px;
            height: 24px;
            font-size: 14px;
            color: $color-white;
            border: 1px solid $color-gray-500;
            transition: all .2s ease;
            cursor: pointer;

            @media only screen and (max-width: 800px) {
                width: 23px;
                height: 23px;
            }

            &:not(:last-child) {
                margin-right: 6px;

                @media only screen and (max-width: 800px) {
                    margin-right: 5px;
                }
            }

            &.active{
                background-color: $color-blue-100;
                border-color: $color-blue-100;
            }
        }
    }

    &__textarea{
        width: 391px;
        margin: 18px auto 7px auto;

        @include media-breakpoint(sm) {
            width: 100%;
        }

        &.small {
            width: 385px;

            @include media-breakpoint(sm) {
                width: 100%;
            }
        }

        textarea {
            min-height: 118px;
        }
    }
}
