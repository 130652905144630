.print {
	&__header {
		display: block;
		margin-left: 840px;
		text-align: end;

		&-title {
			margin-left: 40px;
		}

		&-text {
			margin-left: 10px;
		}
	}
}

.objective-isolation {
	opacity: 0.2;
	font-weight: normal;
}

.print-report {
	&__header {
		border-bottom: 0.5px solid $color-black;
		color: $color-black;

		&-text {
			font-weight: bold;
			color: $color-black;
			font-size: 13px;
		}

		@-moz-document url-prefix() {
			p {
				font-size: 12px;
				line-height: 1;
			}
		}

		@-moz-document url-prefix() {
			h3 {
				font-size: 12px;
				line-height: 1;
			}
		}
	}
}
