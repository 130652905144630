// Contact Support
.support {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.last-support {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 14px;
  }

  &-container {
    animation: zoom-out 1200ms ease-in-out forwards;

    &.first-step {
      width: 389px;
    }

    &.third-step {
      width: 734px;
    }

    &.last-step {
      display: flex;
      flex-direction: column;
      max-width: 383px;
      width: 100%;
    }
  }

  &-title {
    text-align: center;
    font-size: 16px;
    color: $color-gray-300;
    line-height: 23px;

    @include media-breakpoint(md) {
      font-size: 14px;
      line-height: 20px;
    }

    &.third-title {
      text-align: left;
      margin-bottom: 6px;
      margin-left: 25px;
    }
  }

  &-description {
    font-size: 16px;
    line-height: 22px;
    color: $color-gray-300;
    margin-bottom: 0;
    padding: 0 6px;

    @include media-breakpoint(md) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba($color-gray-800, 0.38);
    height: 42px;
    font-size: 18px;
    color: $color-white;
    line-height: 20px;
    padding: 0 11px 0 20px;
    cursor: pointer;
    transition: all 0.3s ease;

    @include media-breakpoint(md) {
      font-size: 16px;
    }

    @include media-breakpoint(sm) {
      font-size: 14px;
    }

    &:hover {
      background-color: rgba($color-gray-800, 0.5);

      .support__steps-icon {
        margin-right: -10px;
      }
    }

    &.dark {
      background-color: rgba($color-brown, 0.38);

      &:hover {
        background-color: rgba($color-brown, 0.6);
      }
    }

    &.first {
      border-radius: 8px 8px 0 0;
    }

    &.last {
      border-radius: 0 0 8px 8px;
    }

    span {
      @include safari-suport {
        transform: translateY(-2px);
      }
    }

    &-icon {
      transform: rotate(180deg);
      transition: all 0.3s ease;
    }
  }

  &-textarea {
    margin-top: 5px !important;
    margin-bottom: 16px;

    textarea {
      min-height: 324px;
      resize: none !important;
      font-size: 18px;
      color: #424242;

      @include media-breakpoint(sm) {
        min-height: 280px;
      }
    }
  }

  &-textarea-medium {
    margin-top: 5px !important;

    textarea {
      min-height: 250px;
      resize: none !important;
    }
  }

  &__upload {
    margin-top: 40px;

    &-title {
      font-size: 16px;
      color: $color-gray-300;
      line-height: 22px;
      margin: 0 15px;
      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 116px;
      border: 2px dashed $color-gray-500;
      border-radius: $border-radius-md;
      padding: 8px 5px;
      padding-top: 18px;
    }

    &-wrapper {
      @include flexcenter-both;
      flex-direction: column;
      width: 100%;
      height: 100%;

      p {
        color: $color-gray-500;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: 0;
      }
    }

    &-icon {
      display: flex;
      justify-content: space-evenly;
      max-width: 385px;
      width: 100%;

      .icon {
        opacity: 0.17;
        width: 46px;
        height: auto;
      }
    }
  }

  &__fields {
    &-name {
      max-width: 354px;
      width: 100%;
    }

    &-number {
      width: 166px;
    }

    &-summary {
      max-width: 383px;
      width: 100%;

      input {
        color: #424242;
      }
    }
  }

  &__fieldset {
    position: relative;
    height: 123px;
    border: 1px solid $color-white;
    border-radius: $border-radius-md;

    &-legend {
      position: absolute;
      left: 24px;
      top: -11px;
      display: inline-block;
      background-color: #3c4047;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      margin: 0;
      padding: 0 12px;
    }

    &-info {
      padding: 16px;
    }

    &-row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      p {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 0;
      }
    }

    &-label {
      width: 95px;
      text-align: right;
      margin-right: 14px;
      font-weight: bold;

      @include media-breakpoint(sm) {
        width: 46px;
        margin-right: 12px;
      }
    }
  }

  &__details {
    display: flex;
    width: 100%;
    margin-top: 34px;
    padding: 0 30px;
    color: $color-white;
    max-height: 190px;
    height: 100%;

    @include media-breakpoint(md) {
      flex-direction: column;
      padding: 0;
    }

    &-left {
      width: calc(100% - 300px);
      height: 100%;
      padding-right: 8px;
      transform: translateY(70px);
      opacity: 0;
      animation: slideup 1200ms ease-in-out forwards;
      animation-delay: 100ms;
      overflow-y: auto;
      scrollbar-width: 6px;

      &::-webkit-scrollbar-track {
        background-color: $color-gray-900;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 10px;
        background-color: rgba($color-black, 0.23);
        border: 1px solid $modal-bg;
      }

      @include media-breakpoint(md) {
        order: 2;
        width: 100%;
        margin-top: 10px;
        padding: 0 15px;
      }
    }

    &-right {
      width: 300px;
      transform: translateY(-40px);
      padding-left: 10px;
      opacity: 0;
      animation: slide-down 1200ms ease-in-out forwards;
      animation-delay: 50ms;

      @include media-breakpoint(md) {
        order: 1;
        width: 100%;
        padding: 0 15px;
      }
    }

    &-row {
      margin-bottom: 8px;
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      text-shadow: $text-shadow;
      margin-bottom: 6px;
    }

    &-text {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 0;
      word-break: break-all;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 16px;

    @include media-breakpoint(sm) {
      padding-left: 0;
    }

    &.fourth-footer {
      justify-content: flex-end;
    }

    &-text {
      font-size: 16px;
      color: $color-gray-300;
      line-height: 20px;
      margin-bottom: 0;

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
