//// standard risk report

.standard-risk-report-print{
    //chrome
    .chromeHeights {
        height: 1570px !important;
    }

    //firefox
    .firefoxHeights {
        height: 900px !important;
    }

    //safari
    .safariHeights{
        height: 1315px;
    }
    
}

////grade frequency report
.grade-frequency-report {
    &-print {
        //chrome 
        .chromeHeights {
            height: 1650px;
        }

        //firefox
        .firefoxHeights {
            height: 2500px;
        }

        .safariHeights{
            height: 1400px;
        }
    }
}
//// School progress report
.school-progress-report {
    &-print {
        //chome
        .chromeHeights {
            height: 1650px;
        }
        
        //firefox
        .firefoxHeights {
            height: 2500px;
        }

        //safari
        .safariHeights{
            height: 1400px;
        }
    }
}

//// standard based report

.based-report-print {
    //firefox
    .withHeaderPrint.firefoxHeights {
        height: 800px;
    }

    .withoutHeaderPrint.firefoxHeights {
        height: 950px;
    }

    //chrome
    .withHeaderPrint.chromeHeights {
        min-height: 1000px;
    }

    .withoutHeaderPrint.chromeHeights {
        min-height: 1200px;
    }

    //safari
    .withHeaderPrint.safariHeights {
        min-height: 1150px;
    }

    .withoutHeaderPrint.safariHeights {
        min-height: 1350px;
    }
}

//// student progress report
.accordion {
    &__content {
        &.print {
            //chrome
            &.chromeHeights {
                height: 1600px;
            }

            //firefox
            &.firefoxHeights {
                height: 900px;
            }

            &.safariHeights{
                height : 1300px
            }
        }
    }
}

// class summative report
.class-summative-report-print {
    //FireFox
    .firefoxHeights {
        height: 1660px;
    }

    //CHROME
    .chromeHeights {
        height: 1650px;
    }

    //safari
    .safariHeights{
        height:1465px;
    }
}

// irr formative report
.irr-formative-report-print {
    
    //firefox
    .singleColumn.firefoxHeights {
        height: 1170px !important;
    }

    .multipleColumns.firefoxHeights {
        height: 1550px !important;
    }

    .firefoxHeights{
        height: 1550px;
    }

    //chrome
    .chromeHeights {
        height: 1550px;
        overflow: hidden;
    }

    //safari
    .safariHeights{
        height:1325px;
    }
}

// Item summative Reports

.irr-summative-report-print {
    //firefox
    .firefoxHeights {
        height: 1580px;
    }

    //chrome
    .chromeHeights {
        height: 1580px;
    }

    //safari
    .safariHeights{
        height:1529px;
    }
}

//  master report
.master-report-print {
    //firefox
    .withHeaderPrint.firefoxHeights {
        height: 900px;
    }

    .withoutHeaderPrint.firefoxHeights {
        height: 1000px;
    }

    //chrome
    .withHeaderPrint.chromeHeights {
        height: 1400px;
    }

    .withoutHeaderPrint.chromeHeights {
        height: 1500px;
    }

    //safari
    .withHeaderPrint.safariHeights {
        height: 1140px;
    }

    .withoutHeaderPrint.safariHeights {
        height: 1240px;
    }
}

//// Audit report
.audit-report-print {
    //firefox
    .firefoxHeights {
        height: 900px;
    }

    //chrome
    .chromeHeights {
        height: 1550px;
    }

    //safari
    .safariHeights{
        height : 1320px
    }
}

//// School audit report
.school-audit-report-print {
    //firefox
    .firefoxHeights {
        height: 900px;
    }

    //chrome
    .chromeHeights {
        height: 1550px;
    }

    //safari
    .safariHeights{
        height : 1325px
    }
}

//// summative objective analysis report

.summative-objective-analysis-report-print {
    //firefox
    .firefoxHeights {
        height: 950px;
    }

    //chrome
    .chromeHeights {
        height: 1650px;
    }

    //safariHeights
    .safariHeights{
        height :1375px
    }
}

//// objective analysis report

.objective-accountability-graph-report-print{

    

    //firefox
    .firefoxHeights {
        height: 1645px;
    }

    //chrome
    .chromeHeights {
        height: 1645px;
        overflow: hidden;
    }

    //safari
    .safariHeights {
        height: 1425px;
    }
}

// @-moz-document url-prefix() {
//     .oag-edge-height {
//         height: 1850px !important;
//     }
// }

    // @supports (-ms-high-contrast: none) and (-ms-high-contrast: active) {
        
    // }

    // @supports (-ms-accelerator:true) {
    //     /* IE Edge 12+ CSS styles go here */ 
    //     .oag-edge-height {
    //         height: 1850px !important;
    //     }
    //   }