.login {
  height: 100% !important;
  padding: 0 12px;

  &-background {
    background: url("../../img/new-login-bg-opt.jpg") no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;

    @media (max-width: 600px) {
      justify-content: space-between;
    }

    @include media-breakpoint(xl) {
      padding-top: 3rem;
    }

    @include media-breakpoint(sm) {
      padding-top: 22px;
    }
  }

  &-alert {
    max-width: calc(100% - 32px);
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint(md) {
      padding-right: 34px
    }
  }

  &__content {
    @include flexcenter-both;
    flex-direction: column;
    max-width: 100%;
    margin: 0;
    position: relative;
    top: 40px;
    @media (max-width: 767px) {
      top: -137px;
    }

    @media (max-width: 600px) {
      top: 0px;
      margin-top: 30px;
    }

    &-column {
      width: 100%;
        max-width: 635px;
    }
  }

  &__card {
    background-color: transparent;
    border: 0;
    border-radius: $border-radius-md;
    box-shadow: $section-shadow;
    backdrop-filter: blur(4px);

    &-header {
      background-color: $color-yellow;
      border-radius: 8px 8px 0 0;
      padding: 26px 21px 10px;
      box-shadow: 0 3px 12px rgba($color-black, 0.16);

      @include media-breakpoint(lg) {
        padding: 16px;
      }
    }

    &-logo img {
      width: 290px;

      @include media-breakpoint(lg) {
        width: 240px;
      }

      @include media-breakpoint(md) {
        width: 220px;
      }
    }

    &-text {
      text-align: right;
      margin-top: -12px;

      @include media-breakpoint(sm) {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 16px;
      }

      p {
        font-family: $font-openSans;
        color: $color-blue-700;
        font-size: 21px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 0;

        @include media-breakpoint(lg) {
          font-size: 20px;
          line-height: 26px;
        }

        @include media-breakpoint(md) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    &-description {
      margin-top: 11px;
      margin-bottom: 6px;
    }

    &-body {
      background-color: rgba($color-blue-200, 0.79);
      border-radius: 0px 0 8px 8px;
      padding: 16px;
      transition: all .4s ease;

      @include media-breakpoint(sm) {
        padding: 16px;
      }
    }

    &-inputs {
      padding: 0;

      p {
        text-align: left;
        font-weight: 700;
      }

      &.hide {
        display: none;
      }

      p {
        font-weight: 700;
      }
    }

    &-link {
      text-decoration: none;
      font-size: 16px;
      color: $color-white;
      font-weight: bold;
      transition: all 0.3s;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      &:hover {
        color: darken($color-white, 10%);
      }
    }

    &-btn {
      @include flexcenter-both;
      padding: 2px 0 0;
      background-color: $theme-bg-color-normal;
      width: 88px;
      height: 33px;
      color: white;
      font-size: 16px;
      line-height: 22px;
      margin-left: 15px;

      @include safari-suport {
        padding-bottom: 3px;
      }

      &:hover {
        background-color: darken($color-yellow, 10%);
      }
    }

    .back-to-login {
      padding: 2px 25px 0;
    }

    &-form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .form-control {
      font-size: 18px;
      border: 0;
      border-radius: $border-radius-md;
      height: 42px;
      color: $color-gray-900;
      font-weight: 400;
      padding-left: 20px;
      padding-right: 35px;

      @include safari-suport {
        padding-top: 0;
        padding-bottom: 4px;
      }

      @include media-breakpoint(sm) {
        padding-left: 14px;
        padding-right: 34px;
      }
    }

    .form-outline {
      position: relative;
      width: 63.52%;
      margin: 0 auto;
      margin-bottom: 10px;
      padding-top: 13px;

      @include media-breakpoint(sm) {
        width: calc(100% - 32px);
        margin-right: 0;
      }

      label {
        position: absolute;
        top: 1px;
        left: 23px;
        background-color: #363636;
        height: 18px;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        color: $color-white;
        border-radius: 20px;
        text-align: center;
        padding: 0px 10.30px 0px;

        // @media (min-width: 1280px) {
        //   padding-top: 1px;
        // }

        @media (max-width: 1440px) {
          padding-top: 1px;
        }

        @include media-breakpoint(sm) {
          left: 18px;
        }
      }

      .password-wrap {
        position: absolute;
        left: -40px;
        background-color: transparent;
        bottom: 0;
        @include flexcenter-both;
        border: 0;
        width: 28px;
        height: 40px;
        padding: 0;
        line-height: 1;
      }
    }
  }

  &__info {
    position: relative;
    background-color: rgba(#9080ff, 0.79);
    text-align: center;
    color: $color-white;
    border-radius: $border-radius-md;
    margin-top: 14px;
    padding: 9px 16px;
    box-shadow: 0 3px 12px rgba($color-black, 0.22);
    z-index: 1;
    backdrop-filter: blur(4px);

    @include media-breakpoint(md) {
      font-size: 14px;
    }
  }

  &__buttons {
    width: 81.6%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 13px;

    @include media-breakpoint(sm) {
      width: 100%;
      justify-content: space-between;
    }

    @media only screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-end;
      padding-top: 0;
    }

    &.forgot-password-btns {
      @include media-breakpoint(sm) {
        justify-content: flex-end;
      }
    }

    label {
      margin-right: 35px;

      @include media-breakpoint(xxl) {
        margin-right: 32px;
      }

      @include media-breakpoint(xl) {
        margin-right: 26px;
      }

      @include media-breakpoint(lg) {
        margin-right: 9px;
      }

      @media only screen and (max-width: 480px) {
        align-self: flex-start;
        margin-left: 32px;
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    &-clever {
      display: inline-flex;
      align-items: center;
      background: white;
      height: 33px;
      padding: 1px 14px 0px;
      gap: 13px;
      border: 0;
      border-radius: $border-radius-md;
      font-size: 16px;
      line-height: 22px;
      color: $color-blue-250;
      transition: all .3s;
      border: 1px solid #4274f6;

      @include media-breakpoint(xl) {
        padding: 1px 11px 0px;
        gap: 12px;
      }

      @include media-breakpoint(sm) {
        padding: 0 10px;
        gap: 7px;
      }

      &:hover {
        color: $color-white;
        background-color: darken($color-blue-250, 3%);
      }

      span {
        font-weight: 400;
        line-height: 22px;

        &:first-child {
          font-size: 22px;
          font-weight: bold;
          display: inline-flex;

          @include media-breakpoint(md) {
            font-size: 20px;
          }

          svg {

            path {
              transition: 0.3s all;
            }
          }
        }
      }

      &:hover {
        
        span {
          &:first-child {

            svg {
              
              path {
                fill: white;
              }
            }
          }
        }
      }

      hr {
        background-color: $color-blue-250;
        margin: 0;
        height: 24px;
        width: 1px;
        border: 0;
        opacity: 1;
      }

      &:hover {
        hr {
          background-color: white;
        }
      }

      @media (max-width: 425px) {
        font-size: 13px;
      }
    }
  }
}

.footer-login-page {

  .login__content {
    top: 0px;
  }

  .footer {
    padding-right: 0px;
  }

  .login-page {
    @media (max-height: 610px) {
      position: relative;
      margin-top: 40px;
    }

    @media (max-width: 991px) {
      @media (max-height: 850px) {
        position: relative;
        margin-top: 40px;
      }
    }

    @media (max-width: 600px) {
      position: relative;
      margin-top: 40px;
    }
  }
}