.calculator {
    position: absolute;
    left: 200px;
    bottom: 20px;
    background: $color-white;
    border-radius: 14px;
    width: 320px;
    transform: translateY(105%);
    box-shadow: 0 4px 8px 0 rgba($color-black, 0.2), 0 6px 20px 0 rgba($color-black, 0.2);
    opacity: 0;
    transition: 0.4s all;
    z-index: 120;

    @include media-breakpoint(xxl) {
        left: 179px;
    }

    @include media-breakpoint(xl) {
        left: 172px;
    }

    &.show {
        transform: translateY(0);
        opacity: 1;
    }

    &.hide {
        display: none;
    }

    &-icon {
        margin-top: 2px;
    }

    &__display {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 140px;
        background-color: $color-red-400;
        border-radius: 14px 14px 0 0;
        padding: 20px 0;

        &-input {
            width: 320px;
            word-wrap: break-word;
            padding: 0;
            border: none;
            background: transparent;
            color: $color-white;
            text-align: right;
            font-weight: 700;
            line-height: 60px;
            &:focus {
                outline: none;
                box-shadow: none;
                border: none;
            }

            &::placeholder {
                color: $color-white;
            }

            &.decrease-font {
                font-size: 30px;
            }
        }
    }


    &__buttons {
        padding: 20px;

        &-row {
            display: flex;
            align-items: center;

            &.right {
                justify-content: flex-end;
            }
        }

        &-bracket__btns{
            width: 30px !important;
            height: 30px !important;
            font-size: 15px !important;
            margin: 1px !important;
        }

        &-btn {
            width: 60px;
            height: 60px;
            float: left;
            padding: 0;
            margin: 5px;
            box-sizing: border-box;
            background: $calculator-btn-bg;
            border: none;
            font-size: 30px;
            line-height: 30px;
            border-radius: 50%;
            font-weight: 700;
            color: $calculator-btn-color;
            transition: all 0.3s;
            cursor: pointer;

            @include safari-suport {
                padding-bottom: 3px;
            }

            &.animate {
                animation: calulator-buttons 350ms ease-out;
            }

            &.animate-equal {
                animation: calulator-equal-button 350ms ease-out;
            }

            &.red-bg {
                background-color: $color-red-400;
                color: $color-white;

                @include safari-suport {
                    padding-bottom: 7px;
                }
            }

            &.clear {
                padding-top: 2.5px;

                @include safari-suport {
                    padding-top: 0;
                    padding-bottom: 2px;
                }
            }

            &.plus {
                padding-bottom: 1px;

                @include safari-suport {
                    padding-bottom: 7.5px;
                }
            }

            &.minus {
                padding-bottom: 4px;

                @include safari-suport {
                    padding-bottom: 8px;
                }
            }

            &.multiply {
                padding-top: 10px;

                @include safari-suport {
                    padding-top: 8px;
                }
            }

            &.decimal {
                padding-bottom: 12px;

                @include safari-suport {
                    padding-bottom: 20px;
                }
            }
        }
    }
    .calculator-display {
        color: $color-white;
        background: #1c191c;
        line-height: 130px;
        font-size: 6em;

        flex: 1;
    }
    .auto-scaling-text {
        padding: 0 30px;
        position: absolute;
        right: 0;
        transform-origin: right;
    }

    .wordBreakCalculator {
        word-break: break-all;
        height: 100px;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 8px;
			border-radius: 10px;
			background-color: rgba($color-black, 0.23);
			border: 2px solid rgba($color-gray-500, 0.5);
        }    
    }

    .lineHeight-20 {
        line-height: 25px;
        position: relative;
    }
}
