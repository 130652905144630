html {
  height: 100%;
  scroll-behavior: smooth;
}

:root {
  --bg-color: #003B93;
  --text-color: #fff;
  --bg-color-normal: #1e4397;
  --bg-color-normal-variant2: #1f4397;
  --bg-color-light: #214d94;
  --bg-color-light-variant2: #2e5b9e;
  --bg-color-lighter: #496faa;
  --bg-color-lighter-variant2: #537ab7;
  --bg-color-lighter-variant3: #4788ff;
  --bg-color-dark: #194792;
  --bg-color-dark-variant2: #194792;
  --bg-color-dark-variant3: #2a5391;
  --bg-color-darker: #003584;
  --bg-color-darker-variant2: #003584;
  --bg-color-medium: #013584;
  --bg-color-lightest: #3260d4;
  --bg-color-chaticon: #3260d4;
  --bg-color-seaGreen: #3c809f;
  --bg-color-seaGreen-light: #46afb8;
  --border-color: #194792;
  --border-color-lighter: #496faa;
  --border-color-light: #2e5b9e;
  --modal-cancel-btn-border: #194792;
}

body[data-theme="theme-GREY"] {
  --text-color: #fff;
  --bg-color: #323233;
  --bg-color-normal: #2d2d2e;
  --bg-color-normal-variant2: #2d2d2e;
  --bg-color-light: #424242;
  --bg-color-light-variant2: #282828;
  --bg-color-lighter: #424242;
  --bg-color-lightest: #6e6e6e;
  --bg-color-chaticon: #2d2d2e;
  --bg-color-lighter-variant2: #545454;
  --bg-color-lighter-variant3: #545454;
  --bg-color-dark: #323233;
  --bg-color-dark-variant2: #1A1A1A;
  --bg-color-dark-variant3: #3E3E3E;
  // --bg-color-darker: #1A1A1A;
  --bg-color-darker: #2D2D2e;
  --bg-color-darker-variant2: #2d2d2e;
  --bg-color-medium: #2d2d2e;
  --bg-color-seaGreen: #424242;
  --bg-color-seaGreen-light: #525252;
  --border-color: #4F4F4F;
  --border-color-lighter: #424242;
  --border-color-light: #282828;
  --modal-cancel-btn-border: #ffffff;
}

body {
  background-color: var(--bg-color) !important;
  font-family: $font-nunito;
  overflow-x: hidden;
  line-height: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust:exact;
  print-color-adjust: exact;

  @include safari-suport {
    font-family: "nunito-for-safari", sans-serif;
  }

  @include media-breakpoint(md) {
    font-size: 14px;
  }

  @media print {
    background-color: $color-white;
  }

  > svg {
  width: 100% !important;
  }
}

.container-xl {

  @media (min-width: 1200px) {
    max-width: 1264px;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding-top: 30px;
  @media print {
    min-height: unset;
  }
}

.hide-on-print{
  @media print {
    display: none;
  }
}

// CMS page wrapper
.pg-container {
	max-width: 1460px;
	width: 100%;
	margin: 0 auto;
}
