.mainContent{
    background-color: $color-light-100;
	min-height: calc(100vh - 88px);
    height: 100%;
    overflow: hidden;

    &-header{
        position: relative;
        display: flex;
        width: 100%;
        height: 60px;
        z-index: 9999;

        &-wrapper{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            max-width: 1460px;
            width: 100%;
            display: flex;
            align-items: center;

            @media (max-width: 1460px) {
                left: 0;
                position: relative;
                transform: none;
                z-index: 90;
            }
        }

        &-empty{
            width: 50%;
            background-color: $theme-bg-color-lighter-variant2;

            @media (max-width: 1460px) {
                display: none;
            }

            &:last-child{
                background-color: $theme-bg-color-dark;
            }
        }

        .empty-header-color {
            background-color: $color-blue-100;
        }

        &.changing-width{
            .mainContent-header-empty{
                flex: auto;
                width: auto;
            }
        }
    }

    &-grouping{
        position: relative;
        display: flex;
        width: 100%;
        height: 30px;
        z-index: 9999;

        &-wrapper{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            max-width: 1460px;
            width: 100%;
            display: flex;
            align-items: center;
            background-color: $color-gray-900;

            @media (max-width: 1460px) {
                left: 0;
                position: relative;
                transform: none;
                z-index: 90;
            }
        }

        &-empty{
            width: 50%;
            background-color: $color-gray-900;
            @media (max-width: 1460px) {
                display: none;
            }

            &:last-child{
                background-color: $color-gray-900;
            }
        }

        &__content{
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0px 0px 0px 50px;
            margin-right: auto;

            @include media-breakpoint(xl){
                padding: 0 40px;
            }
        }

        &__text{
            font-size: 18px;
            line-height: 20px;
            color: #fff;
            margin: 0px;
            font-weight: 600;
            margin-bottom: 0;
            margin-right: 15px;

            @include media-breakpoint(xl) {
                font-size: 22px;
            }

            @include media-breakpoint(lg) {
                font-size: 20px;
            }
        }

        &__container {
            display: flex;
            background-color: $color-gray-900;
            height: 30px;
        }

        &__group__share {
            display: flex;
            align-items: center;
        }

        &__group__questions_ids {
            display: flex;
            align-items: center;
        }

        &__group__questions__content{
            display: flex;
            align-items: center;
            width: 100%;
            height: 20px;
            margin: 5px;
            padding: 0px 16px 0px 16px;
            border-radius: 20px;
            background-color: $color-yellow-200;

            @include media-breakpoint(xl){
                padding: 16px 16px;
            }
        }

        &__group__questions__text{
            font-size: 15px;
            line-height: 15px;
            color: $color-gray-900;
            font-weight: 600;
            margin-bottom: 0;

            @include media-breakpoint(xl) {
                font-size: 18px;
            }

            @include media-breakpoint(lg) {
                font-size: 16px;
            }
        }

        &__group__share-select {
            appearance: none;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAKCAYAAABWiWWfAAAABHNCSVQICAgIfAhkiAAAAK9JREFUKFNjdOv4/4GBgWH+rgrGQiBNMnDo/y/A9pNhPyMDw0FG947/E4Am5APxgp0VjImkmAYzCKhH8T8TgwPQQAYGt/b/CxgZGeJJMRDdoN1ljBfAhpFqIDaDQGbADSPWQFwGYRhGyEB8BmE1DJeBhAzCaRi6gT/ZGQpB0Q+LNVBgY4t1lDBDV4CUbBj+MzB8BEU/LoPwugxmsGvXfwOm/8A09I9hw45Kxgf40iEA41toZ3BECewAAAAASUVORK5CYII=);
            background-repeat: no-repeat;
            background-size: 14px;
            background-position: right 10px center;
            background-color: #fff;
            border: none;
            height: 22px;
            font-size: 16px;
            color: #3e3e3e;
            line-height: 20px;
            padding: 1px 10px;
            border-radius: $border-radius-md;
        }

        &__group__share-select-not-grouped {
            width: 135px;
        }

        &__group__share-select-grouped {
            width: 65px;
        }

        &.changing-width{
            .mainContent-grouping-empty{
                flex: auto;
                width: auto;
            }
        }

        &__close_image{
            display: flex;
            background-color: $color-gray-900;
            height: 30px;
        }

        &__close_image__content{
            display: flex;
            align-items: center;
            // width: 100%;
            // margin: 5px 5px 5px 20px;
            // padding: 0px 16px 0px 16px;

            @include media-breakpoint(xl){
                padding: 16px 16px;
            }
        }

        &__close_image_src{
            width: 20px;
            height: 20px;

            @include media-breakpoint(xl) {
                width: 15px;
                height: 15px;
            }

            @include media-breakpoint(lg) {
                width: 15px;
                height: 15px;
            }
        }
    }

    &__answer{
        background-color: $theme-bg-color-lighter-variant2;
        width: 564px;
        height: 60px;

        @include media-breakpoint(lg) {
            width: 50%;
        }

        &.changing-width{
            width: 261px;

            width: auto;
            min-width: 564px;

            @media (max-width: 768px) {
                min-width: unset;
            }
        }

        &-content{
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 23px 0 28px;
            position: relative;

            @include media-breakpoint(xxl){
                padding: 0 15px;
            }

            .expand-desc {
                position: absolute;
                top: 16px;
                right: 10px;
                border: 0;
                padding: 0;
                background-color: unset;
                z-index: 10;
                transition: 0.4s all;
    
                svg {
                    transition: 0.4s all;
                }
    
                .open {
                    transform: rotateY(180deg);
                }
            }
        }

        &-link{
            @include flexcenter-both;
            min-width: 40px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            overflow: hidden;
            transition: all .3s;

            @include media-breakpoint(xl) {
                margin-right: 6px;
            }

            &:hover{
                background-color: $color-blue-600;
            }

            img{
                width: 45px;
                height: auto;
                transform: rotate(-90deg);
            }
        }

        &-paragraph{
            font-size: 24px;
            color: $color-white;
            font-weight: bold;
            line-height: 20px;
            width: calc(100% - 85px);
            display: flex;
            align-items: center;
            gap: 10px 14px;
            
            p {
                width: auto;
                //max-width: calc(100% - 140px);
                margin-bottom: 0;
                line-clamp: 2;
                cursor: auto !important;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                line-height: 25px;
            }



            .expand {
                width: 100%;
                max-width: 100%;
            }

            @include media-breakpoint(xl) {
                font-size: 22px;
            }

            @include media-breakpoint(lg) {
                font-size: 20px;
            }
            @media (max-width : 460px){
                font-size: 18px;
                padding-right: 40px;
            }
            @media (max-width : 374px){
                padding-right: 37px;
            }

            .dropdown {
                
                .table-dropdown-toggle {
                    width: 79px;
                    border-color: #ffffff !important;
                    color: #ffffff !important;
                    background-image: url(../../../img/select-indicator.svg);
                    background-position-y: 10px;
                }

                .dropdown-menu {
                    min-width: 100%;
                }
            }
        }

        .preview-class-name {
            width: calc(100% - 85px);
        }
    }

    &__question{
        display: flex;
        background-color: $theme-bg-color-dark;
        width: 896px;
        height: 60px;

        @media (max-width: 1460px) {
            flex: 1;
        }

        &.changing-width{
            flex: 1;
            width: auto;
        }

        &-content{
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 22px;
            margin-right: auto;

            @include media-breakpoint(xl){
                padding: 0 16px;
            }
        }

        &-content-right {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 375px;
            background-color: $color-gray-900;
            padding: 0 27px;

            @include media-breakpoint(xxl) {
                max-width: 327px;
                min-width: 327px;
                padding: 0 27px;
                padding-right: 15px;
            }

            @include media-breakpoint(xl) {
                max-width: 236px;
                min-width: 236px;
                padding: 0 15px;
            }
        }

        &-paragraph2{
            text-shadow: $text-shadow;
            margin: 0;
            margin-right: 16px;
            transform: translateY(-12px);
        }

        &-paragraph{
            font-size: 24px;
            line-height: 20px;
            color: $color-white;
            margin: 0px;

            @include media-breakpoint(xl) {
                font-size: 22px;
            }

            @include media-breakpoint(lg) {
                font-size: 20px;
            }
        }
    }

    .question-sec-hide {
        display: none;
    }

    &__main{
        display: flex;
        max-width: 1460px;
        width: 100%;
        min-height: calc(100vh - 190px);
        margin: 0 auto;
        z-index: 99;
        position: relative;

        &.switch-off-column {
            flex-direction: column;
        }

        .cal-main-container {
            position: absolute;
            left: 163px;
            bottom: 10px;

            .closeTooltipBtn {
                position: absolute;
                top: -14px;
                right: -14px;
                width: 28px;
                padding: 0;
                background-color: unset;
                border: 0;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

        }

        #desmos-calculator {
            

            .dcg-calculator-api-container {

                .dcg-basic-list-container {
                    flex-direction: column-reverse;
                    height: auto;
                    max-height: 300px;
                    overflow-y: auto;
                    border-right: 1px solid rgba(0,0,0,.2);

                    .dcg-basic-list-placeholder {
                        width: 0;
                        max-width: 0;
                        height: 0;
                        max-height: 0;
                        border: 0;
                    }
                }

                .dcg-basic-expression:first-of-type {
                    border-top: 0px;
                    margin-top: 0px;

                    &.dcg-focused {
                        border-top: 1px solid #2f72dc;
                    }
                }
            }

            button {
                padding: 0;
                background-color: unset;
                font-size: 18px;
                font-family: $font-openSans;
                border: 0;
                color: #888888;
                white-space: nowrap;
            }
        }
    }
}

.sqr-root_inline {
    display: inline !important;
}

.sqr-root {
    display: inline !important;
    margin: 0 5px;
}

.root {
    display: inline-block;
    vertical-align: middle;
    border-top: 1px solid;
    border-left: 1px solid;
    transform: skew(-15deg);
    transform-origin: bottom left;
    position: relative;
}
  
.root:before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 40%;
    width: 5px;
    left: -5px;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: skew(30deg);
    transform-origin: bottom right;
}
  
.radicand {
    display: inline-block;
    padding-left: 0.5em;
    transform: skew(15deg);
}

// Math Fraction
.math_frac {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    letter-spacing: 0.001em;
    text-align: center;
    margin-right: 5px;
    font-size: 19px;
    color: #707070;
    margin-left: 5px;
  
    span {
        display: block;
        padding: 0.1em;
    
        &.bottom {
            border-top: thin solid $color-black;
        }
    
        &.symbol {
            display: none;
        }
    }
}

// .math_frac ~ p {
//     display: inline !important;
// }

.math_frac_inline {
    display: inline !important;
}

.cms-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $color-gray-650;
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    font-size: 18px;
    color: $color-white;
    border-radius: $border-radius-md;
    padding-left: 50px;

    &-close {
        background-color: $color-gray-350;
        position: absolute;
        top: 12px;
        right: 13px;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        line-height: 16px;
        padding-left: 4px;
        cursor: pointer;

        @include safari-suport {
            line-height: 14px;
        }
    }

    span:nth-child(2) {
        @include safari-suport {
            transform: translateY(-2px);
        }
    }

    .red-circle {
        top: 12px;
    }
}

#chr-ques p.select div {
	min-width: 250px;
}

.tabs-bttns-full {
	position: relative;
	top: -30px;
}

.black-divider {
	background-color: $color-black;
	height: 30px;
}

.tabs-bttns {
	width: 50%;
	height: 60px;
}

.tabs-bttns button {
    @include flexcenter-both;
	width: 100%;
	height: 100%;
	font-family: $font-nunito;
	font-size: 24px;
	line-height: 20px;
	color: $color-black;
	border: unset;
}

.tabs-bttns button.tab-1 {
	background: $color-green-100;
}

.tabs-bttns.tab-2btn button {
	position: relative;
}

.tabs-bttns.tab-2btn button .del {
	position: absolute;
	right: 19px;
}

.tabs-bttns.tab-2btn .del {
	padding-left: 74px;
}

.student-essay {
	margin-top: 20px;
	text-align: center;
	margin-top: 140px;
	margin-left: 5px;
}

.student-essay h1 {
	font-size: 20px;
	color: $color-black;
	font-weight: semibold;
	margin: 0px;
}

.student-essay p {
	font-size: 20px;
	color: $color-black;
	font-weight: normal;
	margin: 0px;
	margin-bottom: 16px;
}

.eassy-para {
	padding-left: 24px;
}

.jodit-react-container {
    // font-size: 19px;
	color: $color-gray-750;
	background-color: $color-white;
}

.jodit-react-container p, .jodit-popup__content p {
	color: $color-gray-750;
}

.jodit-workplace {
	min-height: 250px !important;
    background-color: $color-white;
}

.jodit-wysiwyg {
	min-height: 200px !important;
    p {
        margin-bottom: 0;
    }
}
.fr-wrapper a {
 	display: none !important;
}

.fr-second-toolbar{
	display: none ;
}

.eassy-para p {
	font-size: 20px;
	color: $color-gray-750;
	font-weight: normal;
	margin: 0px;
	margin-bottom: 20px;
}

.eassy-para .para-no {
	display: inline-block;
	width: 70px;
}

.color-grey {
	color: $color-gray-800 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.archice-modal {
    position: absolute;
    top: 200px;
    top: 250px;
    left: 0px;
    flex-direction: row;
    z-index: 9;
}

.modal-alert {
    width: 33px;
    height: 35px;
    margin-right: 15px;
}

.modal-desc {
    width: 87%;
    font-size: 14px;
    line-height: 20px;
    color: $color-white;
}

.items-center {
    align-items: center;
}

.dragDefaultClass{
    cursor: move;
    transition: 0.1s !important;
}

.jodit-inline-fix {
    display: inline !important;
}