.answers{
    background-color: $color-white;
    width: 564px;
    max-width: 564px;
	margin-right: 0;
    position: relative;
    transition: 0.4s all;

    .tts p, .tts li {
        outline: 1px dotted $color-blue;
        cursor: pointer!important;
    }

    .tts p:hover, .tts li:hover {
        outline: 4px solid rgb(170, 255, 96);
    }


    @include media-breakpoint(lg) {
        width: 50%;
    }

    &.switch-off-column {
        height: 0;
    }

    &.changing-width{
        width: 17.8%;
    }

    &__passage{
        display: flex;
        justify-content: flex-start;
        background-color: $color-gray-900;
        padding: 4px 25px 4px 25px;
        height: 30px;

        @include media-breakpoint(xl){
            padding: 4px 20px;
        }

        &-paragraph{
            font-size: 16px;
            color: $color-white;
            line-height: 20px;
            font-weight: 600;
            margin-right: 15px;
            margin-bottom: 0px;
        }
        &-paragraph-toggle-container{
            @include media-breakpoint(xl) {
                min-width: 145px;
            }
        }

        &-share{
            display: flex;
            align-items: center;
            margin-left: 18px;
        }

        &-select{
            -webkit-appearance: none;
            appearance: none;
            background-image: url("./../../../img/drop-down-select.png");
            background-repeat: no-repeat;
            background-size: 14px;
            background-position: right 10px center;
            background-color: $color-white;
            border: none;
            width: 61px;
            height: 22px;
            font-size: 16px;
            color: $color-gray-900;
            line-height: 20px;
            padding: 1px 10px;
            border-radius: $border-radius-md;
        }

        &__grouped-container {
            display: flex;
            background-color: $color-gray-900;
            margin-left: 18px;
        }

        &__grouped__questions-content{
            display: flex;
            align-items: center;
            width: 100%;
            height: 18px;
            margin: 2px 5px;
            padding: 0px 16px 0px 16px;
            border-radius: 20px;
            background-color: $color-yellow-200;

            // @include media-breakpoint(xl){
            //     padding: 16px 16px;
            // }
        }

        &__grouping__close_image{
            display: flex;
            background-color: #3e3e3e;
        }

        &__grouping__close_image_content{
            display: flex;
            align-items: center;
        }

        &__grouping__close_image_src{
            width: 20px;
            height: 20px;
        }

        &__grouping__checkbox_opacity {
            opacity: 0.5;
        }

        &__grouped__questions-text{
            font-size: 15px;
            line-height: 15px;
            color: $color-gray-900;
            font-weight: 600;
            margin-bottom: 0;

            @include media-breakpoint(xl) {
                font-size: 18px;
            }

            @include media-breakpoint(lg) {
                font-size: 16px;
            }
        }
    }

    &-content{
        width: 100%;
        min-height: 100%;

        &.wrapper-overlay {
            position: relative;

            &::before{
                content: "";
                background-color: $color-gray-400;
                opacity: 0.6;
                z-index: 9;
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 101%;
            }
        }
    }

    &__editor{
        height: calc(100vh - 193px);
        border-right: 1px solid $color-light-100;
        overflow: auto;
        overflow-x: hidden;
        padding: 15px 14px;
        width: 100%;

        &.math-editor{
            height: calc(100vh - 284px);
        }

        p {
            color: $color-gray-750;
        }

        &::-webkit-scrollbar {
            width: 12px;
        }
          
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 20px $color-light-300;
          
            box-shadow: inset 0 0 12px $color-light-300; 
            border-radius: 10px;
        }
           
        &::-webkit-scrollbar-thumb {
            width: 8px;
            border: 2px solid $color-light-300;
            background: $color-gray-200; 
            border-radius: 7px;
        }

        &-tab{
            display: flex;
            padding: 10px 16px 9px 24px;
            background-color: rgba($color-light-200, .33);
            border-radius: $border-radius-md;
        }

        &-icon{
            cursor: pointer;
        }

        &-instructions{
            font-size: 19px;
            line-height: 26px;
            color: $color-blue-50;
            padding: 0px;
            padding-left: 20px;

            p{
                font-size: 19px;
                line-height: 26px;
                color: $color-blue-50;
                margin-bottom: 0px;
                cursor: pointer;
            }
        }

        &-edit{
            border-radius: $border-radius-md;
            margin-top: 15px;
            font-family: 'Arimo';
            font-size: 19px;
            color: $color-gray-750;
            background-color: $color-white;

            img {
                width: auto;
                max-width: 100%;
            }

            table tr td {
                margin-top: 10px;
                border: 1px solid #dadada;
                min-width: 2em;
                padding: 0.4em;
                -webkit-user-select: text;
                -moz-user-select: text;
                -ms-user-select: text;
                user-select: text;
                vertical-align: middle;
            }
            
            .ql-toolbar.ql-snow {
                border-radius: 0;
            }

            .ql-container {
                min-height: calc(100vh - 520px) !important;
                height: 100% !important;

                &.ql-snow {
                    border: none;
                }
            }

            .ql-editor {
                overflow-y: visible;
                padding: 17px 20px;

                p{
                    &:last-child {
                        margin-bottom: 70px;
                    }
                }
            }

            .jodit-react-container {
                
                .jodit-container {
                    border-radius: 8px;
                    overflow: hidden;
                }
            }
        }
    }

    .add-tabs-height {
        height: calc(100vh - 261px);
    }
}

.answer-sec-expand {
    width: 100%;
    max-width: 100%;

    .answers-content  {

        .react-tabs {

            .react-tabs__tab-list {
                float: unset;
                max-width: 100%;
            }
        }
    }
}

.jodit_sticky>.jodit-toolbar__box {
    top: -15px !important;
}