.management {
  margin-top: 22px;

  &-wrap {
    @include section;
    flex-wrap: wrap;

    .super-admin {
      .section-header-buttons {
        .accordion__header-btnContainer button {
          line-height: 22px !important;
          padding-top: 5px;
        }
        .outline-btn {
          padding-top: 5px;
        }
      }
    }
  }

  .section-header {
    @media (max-width: 767px) {
      display: block;
    }
  }

  &__table {
    margin-top: 27px;

    &_no-margin {
      margin-top: 0px;
    }

    // tbody {
    //   tr:nth-child(odd) {

    //     td {
    //       background-color: $theme-bg-color-dark-variant2;
    //     }
    //   }
    // }

    .skeleton-td {
      padding-right: 0 !important;

      span {
        .react-loading-skeleton {
          margin-bottom: 0;
        }
      }
    }

    &-row {
      th {
        padding: 10px 10px 3px 10px;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        // text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      td {
        padding: 7px 8px;
        line-height: 22px;
        vertical-align: top;

        @include safari-suport {
          padding: 5px 8px 9px 8px;
        }

        .ps-disabled {
          opacity: 0.2;
        }
      }

      td:first-child {
        border-radius: 8px 0px 0px 8px;
        display: table-cell;
      }

      td:last-child {
        border-radius: 0px 8px 8px 0px;
        display: table-cell;
      }

      .option-data-col {
        padding-left: 10px;
      }

      .admin-data {
        text-align: right;
        padding-right: 13px;
        padding-left: 16px;

        @include media-breakpoint(xl) {
          padding-right: 15px;
        }
      }

      .title-data {
        padding-left: 16px;

        @include media-breakpoint(xl) {
          padding-right: 15px;
        }
      }

      .email-data {
        padding-right: 5px !important;
        padding-left: 25px !important;

        @include media-breakpoint(xl) {
          padding-left: 15px;
        }
      }

      .login-data {
        text-align: center;
      }
    }

    &-admin-no-sort {
      width: 237px;
      vertical-align: bottom;
      text-align: right;
      padding: 10px 10px 5px 16px !important;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 220px;
        padding: 10px 15px 5px 5px !important;
      }
    }

    &-admin {
      width: 210px;
      min-width: 210px;
      max-width: 210px;
      vertical-align: bottom;
      text-align: right;
      padding: 10px 13px 3px 16px !important;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 220px;
      }

      .arrow-down-classname,
      .arrow-up-classname {
        margin-right: 9px;
      }
    }

    &-title {
      min-width: 180px;
      width: 180px;
      vertical-align: bottom;
      padding: 10px 0px 3px 16px !important;
    }

    &-email {
      min-width: 286px;
      width: 286px;
      vertical-align: bottom;
      padding: 10px 5px 3px 25px !important;
      cursor: pointer;
      @include media-breakpoint(xl) {
        padding: 10px 15px 3px 15px !important;
      }
    }

    &-login {
      width: 120px;
      min-width: 120px;
      text-align: center;
      vertical-align: bottom;
      cursor: pointer;

      @include media-breakpoint(xl) {
        min-width: 120px;
        width: 120px;
      }
    }

    &-editBtn {
      display: inline-block;
      border: none;
      margin: 0 20px 0 0;
      padding: 0 0px;
      cursor: pointer;

      &:focus-visible {
        outline: 2px solid black !important;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-options {
      min-width: 95px;
      vertical-align: bottom;
    }
  }
}

// District Table
.district {
  &__table {
    &-row {
      th {
        padding: 0;
        padding-bottom: 5px;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      td {
        text-align: center;
        padding: 7px 0;
        line-height: 23px;

        @include safari-suport {
          padding: 5px 0 7px 0;
        }
      }

      .name-data {
        text-align: right;
        font-weight: bold;
        padding-right: 14px;
      }

      .report-data {
        img {
          max-width: 16px;
          height: auto;
        }
      }

      .options-data {
        padding-left: 46px;

        @include media-breakpoint(xl) {
          padding-left: 30px !important;
        }
      }
    }

    &-name {
      width: 210px;
      min-width: 210px;
      max-width: 210px;
      padding-right: 13px !important;
      vertical-align: bottom;
      text-align: right;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 256px;
      }

      .arrow-down-classname,
      .arrow-up-classname {
        margin-right: 9px;
      }
    }

    &-last {
      min-width: 127px;
      width: 127px;
      vertical-align: bottom;
      text-align: center;
      cursor: pointer;
    }

    &-school {
      width: 122px;
      vertical-align: bottom;
      text-align: center;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 100px;
      }
    }

    &-report {
      min-width: 100px;
      width: 100px;
      text-align: center;
    }

    &-options {
      vertical-align: bottom;
      padding-left: 16px !important;

      @include media-breakpoint(xl) {
        min-width: 112px;
        padding-left: 30px !important;
      }
    }

    &-editBtn {
      display: inline-block;
      margin: 0 5px;
      padding: 0 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

// School Table
.school {
  &-table-container {
    margin-top: -13px;
    height: auto;

    @media (max-width: 1050px) {
      margin-top: 10px;
    }
  }

  &__table {
    &-row {
      th {
        padding: 0;
        padding-bottom: 5px;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        // text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      td {
        // padding: 7px 5px !important;
        line-height: 23px;

        @include safari-suport {
          padding: 5px 5px 7px 5px;
        }
      }

      .book-access-data {
        .table-dropdown {
          .table-dropdown-toggle {
            width: 95px;
          }
        }
      }

      .name-data {
        text-align: right;
        font-weight: bold;
        padding-right: 14px;

        @include media-breakpoint(xl) {
          padding-right: 5px;
        }
      }

      .district-data {
        text-align: left;
        font-weight: bold;
        padding-left: 10px !important;
        width: 206px;
        min-width: 206px;
        text-align: right;
        padding-right: 44px !important;
      }

      .report-data {
        text-align: center;

        img {
          max-width: 15px;
          height: auto;
        }
      }

      .book-access-data {

        .dropdown {

          .dropdown-menu {
            transform: unset !important;
            top: 100% !important;
            bottom: unset !important;
          }
        }
      }

      &:last-child {

        .book-access-data {
  
          .dropdown {
  
            .dropdown-menu {
              transform: unset !important;
              top: unset !important;
              bottom: 100% !important;
            }
          }
        }
      }

      &:nth-last-child(3) {

        .book-access-data {
  
          .dropdown {
  
            .dropdown-menu {
              transform: unset !important;
              top: unset !important;
              bottom: 100% !important;
            }
          }
        }
      }

      &:nth-last-child(2) {

        .book-access-data {
  
          .dropdown {
  
            .dropdown-menu {
              transform: unset !important;
              top: unset !important;
              bottom: 100% !important;
            }
          }
        }
      }
    }

    &-name {
      position: relative;
      width: 210px;
      max-width: 210px;
      min-width: 210px;
      padding-right: 13px !important;
      vertical-align: bottom;
      text-align: right;
      cursor: pointer;

      .arrow-down-classname,
      .arrow-up-classname {
        margin-right: 9px;
      }
    }

    &-last {
      width: 115px;
      min-width: 115px;
      text-align: center;
      vertical-align: bottom;
      cursor: pointer;
    }

    &-district {
      width: 235px;
      min-width: 235px;
      text-align: left;
      vertical-align: bottom;
      cursor: pointer;
      padding-left: 10px !important;
      text-align: right;
      padding-right: 44px !important;
    }

    &-class {
      width: 80px;
      min-width: 80px;
      text-align: center;
      vertical-align: bottom;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 70px;
      }
    }

    &-report {
      width: 104px;
      min-width: 104px;
      text-align: center;
    }

    &-available {
      width: 100px;
      min-width: 100px;
      text-align: center;
      vertical-align: bottom;
    }

    &-available-access {
      width: 95px;
      min-width: 95px;
      text-align: center;
      vertical-align: bottom;
    }

    &-options {
      vertical-align: bottom;
      min-width: 86px;
      // padding-left: 30px !important;
    }

    &-options-row {
      // padding-left: 30px !important;
    }
  }
}

// Classs Table
.class {
  &__table {
    &-row {
      th {
        padding: 0;
        padding-bottom: 5px;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      td {
        padding: 7px 0;
        line-height: 23px;

        @include safari-suport {
          padding: 6px 0 8px 0;
        }
      }

      .hour-column {
        width: 68px;
        min-width: 68px;
        padding: 0;
        text-align: left;
      }

      .chip {
        width: 44px;
        height: 27px;
        border-radius: 8px;
        background-color: #cbcbcb;

        font-size: 14px;
        font-weight: 400;
        line-height: 13.762px;
        color: #323233;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 2px 0px 0px;
      }

      .name-data {
        text-align: right;
        font-weight: bold;
        padding-right: 14px;

        @include media-breakpoint(xl) {
          padding-right: 4px;
        }
      }

      .options-data {
        padding-left: 45px;

        @include media-breakpoint(xl) {
          padding-left: 20px;
        }
      }
    }

    &-name {
      position: relative;
      min-width: 210px;
      width: 210px;
      max-width: 210px;
      padding-right: 13px !important;
      vertical-align: bottom;
      text-align: right;
      cursor: pointer;
      @include media-breakpoint(xl) {
        padding-right: 15px !important;
      }

      @include media-breakpoint(md) {
        min-width: 300px;
        width: 300px;
      }

      .arrow-down-classname,
      .arrow-up-classname {
        margin-right: 9px;
      }
    }

    &-grade {
      width: 76px;
      min-width: 75px;
      vertical-align: bottom;
      text-align: center;
      cursor: pointer;
    }

    &-last {
      width: 146px;
      text-align: center;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 120px;
      }
    }

    &-student {
      width: 82px;
      min-width: 82px;
      vertical-align: bottom;
      text-align: center;
      cursor: pointer;
    }

    &-options {
      min-width: 102px;
      padding-left: 16px !important;
      vertical-align: bottom;

      @include media-breakpoint(xl) {
        padding-left: 20px !important;
      }
    }
  }
}

// Students Table
.students {
  &-forclass-table-container {
    margin-top: -24px;

    @media only screen and (max-width: 1045px) {
      margin-top: 4px;
    }
  }

  &__table {
    margin-top: 30px;

    &-row {
      th {
        padding: 0;
        padding-bottom: 5px;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      td {
        padding: 7px 0px;
        line-height: 23px;

        @include safari-suport {
          padding: 6px 0px 8px 0;
        }
      }

      .name-data {
        text-align: right;
        font-weight: bold;
        padding-right: 13px;

        @include media-breakpoint(xl) {
          padding-right: 15px;
        }
      }

      .id-data {
        padding-left: 15px;
      }

      .options-data {
        padding-left: 39px;

        @include media-breakpoint(xl) {
          padding-left: 20px;
        }
      }
    }

    &-tools {
      text-align: center;
      font-size: 14px !important;
      padding-left: 204px !important;

      @include media-breakpoint(xl) {
        padding-left: 230px !important;
      }
    }

    &-name {
      position: relative;
      width: 210px;
      min-width: 210px;
      max-width: 210px;
      padding-right: 13px !important;
      text-align: right;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 265px;
        width: 265px;
        padding-right: 15px !important;
      }

      .arrow-down-classname,
      .arrow-up-classname {
        margin-right: 9px;
      }
    }

    &-id {
      min-width: 160px;
      width: 160px;
      padding-left: 15px !important;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 138px;
      }
    }

    &-school {
      width: 180px;
      min-width: 180px;
      cursor: pointer;
      padding-left: 16px !important;
    }

    &-class {
      width: 75px;
      min-width: 75px;
      text-align: center;
      cursor: pointer;
    }

    &-last {
      width: 110px;
      min-width: 110px;
      text-align: center;
      cursor: pointer;
    }

    &-clever {
      width: 140px;
      min-width: 140px;
      text-align: center;
      cursor: pointer;
    }

    &-calculator {
      text-align: center;
      min-width: 100px;
      width: 110px;
      vertical-align: bottom;
      cursor: pointer;
    }

    &-speech {
      min-width: 80px;
      width: 100px;
      text-align: center;
      cursor: pointer;
    }

    &-unenroll {
      min-width: 106px;
      width: 126px;
      text-align: center;
    }

    &-notes {
      min-width: 220px;
      width: 230px;
      vertical-align: bottom;
    }

    &-options {
      min-width: 102px;
      padding-left: 16px !important;

      @include media-breakpoint(xl) {
        padding-left: 20px !important;
      }
    }

    &-singleOption {
      min-width: 48px;
      vertical-align: bottom;
    }

    &-management {
      z-index: 10;
      td {
        padding: 6px 0px;
        line-height: 23px;
      }
    }
  }
}

// Checco Table
.checo {
  &__table {
    &-row {
      th {
        padding: 9px 10px 5px 10px;
      }

      th,
      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 21.82px;
        // text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      td {
        padding: 7px 8px;
        line-height: 23px;

        @include safari-suport {
          padding-top: 6px;
          padding-bottom: 8px;
        }
      }

      .name-data {
        text-align: right;
        padding-right: 13px;

        @include media-breakpoint(xl) {
          padding-right: 20px;
        }
      }

      .title-data {
        padding-left: 16px;

        @include media-breakpoint(xl) {
          padding-right: 15px;
        }
      }

      .options-data {
        padding-left: 13px;
      }
    }

    &-name {
      width: 210px;
      min-width: 210px;
      max-width: 210px;
      text-align: right;
      padding: 10px 13px 5px 16px !important;
      vertical-align: bottom;
      cursor: pointer;
      @include media-breakpoint(xl) {
        width: 240px;
        min-width: 240px;
        padding: 10px 20px 5px 10px !important;
      }

      .arrow-down-classname,
      .arrow-up-classname {
        margin-right: 9px;
      }
    }

    &-email {
      width: 249px;
      min-width: 240px;
      vertical-align: bottom;
      cursor: pointer;
    }

    &-login {
      width: 117px;
      text-align: center;
      vertical-align: bottom;
      cursor: pointer;
      @include media-breakpoint(xl) {
        min-width: 120px;
      }
    }

    &-superintendent {
      width: 96px;
      min-width: 96px;
      text-align: center;
    }

    &-options {
      padding-left: 15px !important;
      vertical-align: bottom;
    }

    &-title {
      width: 200px;
      vertical-align: bottom;
      padding: 10px 0 5px 16px !important;
    }
  }
}

// Admins Tables
.admins {
  &__table {
    &-row {
      th {
        padding: 10px 0 5px 0;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        // text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      td {
        padding: 7px 0;
        line-height: 23px;

        @include safari-suport {
          padding-top: 6px;
          padding-bottom: 8px;
        }
      }

      .name-data {
        text-align: right;
        padding-right: 14px;
      }

      .email-data {
        padding-left: 12px !important;
      }

      .title-data {
        padding-right: 10px;
        padding-left: 16px;
      }

      .options-data {
        padding-left: 24px;
        padding-right: 24px;

        &.class {
          padding-left: 14px;
        }
      }
    }

    &-name {
      position: relative;
      width: 207px;
      min-width: 207px;
      padding-right: 13px !important;
      vertical-align: bottom;
      text-align: right;
      cursor: pointer;
      @include media-breakpoint(xl) {
        width: 240px;
        min-width: 240px;
      }

      .arrow-down-classname,
      .arrow-up-classname {
        margin-right: 9px;
      }
    }

    &-email {
      width: 260px;
      min-width: 260px;
      vertical-align: bottom;
      padding-left: 12px !important;
      cursor: pointer;
    }

    &-admin {
      width: 96px;
      min-width: 96px;
      text-align: center;
    }

    &-school {
      width: 115px;
      vertical-align: bottom;
      text-align: center;
      cursor: pointer;
    }

    &-login {
      width: 105px;
      min-width: 105px;
      vertical-align: bottom;
      text-align: center;
      cursor: pointer;
      &.school-detail {
        width: 120px;
        min-width: 120px;
      }
    }

    &-class {
      width: 110px;
      min-width: 110px;
      vertical-align: bottom;
      text-align: center;
      cursor: pointer;
      &.class {
        width: 86px;
        min-width: 86px;
      }
    }

    &-title {
      min-width: 180px;
      width: 180px;
      vertical-align: bottom;
      padding: 10px 10px 5px 16px !important;
      cursor: pointer;
      &.school-detail {
        min-width: 200px;
        width: 200px;
      }
    }

    &-options {
      min-width: 104px;
      padding-left: 24px !important;
      vertical-align: bottom;

      @include media-breakpoint(xl) {
        padding-left: 20px !important;
      }

      &.class {
        padding-left: 14px !important;
      }
    }
  }
}

// Assesment
.assessment {
  &__header {
    &-availablite {
      // background-color: $color-blue-600;
      display: flex;
      align-items: center;
      padding: 4px 22px;
      padding-left: 24px;

      p {
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 0;
        // text-shadow: $text-shadow;

        @include media-breakpoint(md) {
          line-height: 20px;
        }

        @include safari-suport {
          transform: translateY(-2px);
        }
      }
    }
  }

  &__content {
    display: flex;
    margin-bottom: 20px;

    @include media-breakpoint(xl) {
      flex-direction: column;
    }
  }

  &__details {
    flex: 1;

    &-wrapper-50 {
      height: 222px;
      background-color: $color-gray-500;
      padding: 6px 22px;
      overflow-y: auto;
      scrollbar-width: 12px;
      scrollbar-color: rgba($color-black, 0.23);
      border-radius: $border-radius-md;
      flex-wrap: wrap;

      @include media-breakpoint(lg) {
        scrollbar-width: 8px;
      }

      @include media-breakpoint(md) {
        scrollbar-width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 12px;

        @include media-breakpoint(lg) {
          width: 8px;
        }

        @include media-breakpoint(md) {
          width: 6px;
        }
      }
      .zip-phone-wrapper {
        @media (max-width: 472px) {
          flex-wrap: wrap;
          gap: 16px;
          .assessment__details-item {
            .assessment__details-phone-num {
              width: 80px;
              text-align: end;
            }
          }
        }
      }

      &::-webkit-scrollbar-thumb {
        width: 8px;
        border-radius: 10px;
        background-color: rgba($color-black, 0.23);
        border: 2px solid $color-gray-500;

        @include media-breakpoint(lg) {
          width: 6px;
          border: 1px solid rgba($color-blue-100, 0.4);
        }

        @include media-breakpoint(md) {
          width: 4px;
        }
      }
    }

    &-wrapper {
      height: 222px;
      background-color: $color-gray-500;
      padding: 6px 22px;
      overflow-y: auto;
      scrollbar-width: 12px;
      scrollbar-color: rgba($color-black, 0.23);
      border-radius: 8px 0px 0px 8px;

      @include media-breakpoint(lg) {
        scrollbar-width: 8px;
      }

      @include media-breakpoint(md) {
        scrollbar-width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 12px;

        @include media-breakpoint(lg) {
          width: 8px;
        }

        @include media-breakpoint(md) {
          width: 6px;
        }
      }

      &::-webkit-scrollbar-thumb {
        width: 8px;
        border-radius: 10px;
        background-color: rgba($color-black, 0.23);
        border: 2px solid $color-gray-500;

        @include media-breakpoint(lg) {
          width: 6px;
          border: 1px solid rgba($color-blue-100, 0.4);
        }

        @include media-breakpoint(md) {
          width: 4px;
        }
      }
    }

    &-header {
      // background-color: $color-blue-600;
      padding: 4px 22px;

      @include safari-suport {
        padding-top: 3px;
        padding-bottom: 5px;
      }

      p {
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 0;
        // text-shadow: $text-shadow;
      }
    }

    &-item {
      display: flex;
      margin-bottom: 16px;

      p {
        color: $color-black;
        line-height: 21px;
        margin-bottom: 0;
      }
    }

    &-address {
      width: 80px;
      text-align: right;
    }
  }

  &__table-wrapper {
    width: 752px;

    @include media-breakpoint(xl) {
      width: 100%;
    }
  }

  &__table-content-50 {
    margin: 0px 20px;
    height: 222px;
    overflow-y: auto;

    @include media-breakpoint(lg) {
      overflow-x: auto;
    }

    @media (max-width:798px) {
      &::-webkit-scrollbar:vertical {
        display: none;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-black, 0.12);
    }

    &::-webkit-scrollbar {
      width: 12px;

      @include media-breakpoint(lg) {
        width: 8px;
      }

      @include media-breakpoint(md) {
        width: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      width: 8px;
      border-radius: 10px;
      background-color: rgba($color-black, 0.23);
      border: 2px solid rgba($color-blue-100, 0.4);

      @include media-breakpoint(lg) {
        width: 6px;
        border: 1px solid rgba($color-blue-100, 0.4);
      }

      @include media-breakpoint(md) {
        width: 4px;
      }
    }

    .assessment__table {

      tbody {

        tr:nth-child(odd) {
          td {
            background-color: $theme-bg-color-darker;
          }
        }
      }
    }
  }

  &__table-content {
    background-color: $color-gray-500;
    height: 222px;
    overflow-y: auto;
    border-radius: 0px 8px 8px 0px;

    @include media-breakpoint(lg) {
      overflow-x: auto;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-black, 0.12);
    }

    &::-webkit-scrollbar {
      width: 12px;

      @include media-breakpoint(lg) {
        width: 8px;
      }

      @include media-breakpoint(md) {
        width: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      width: 8px;
      border-radius: 10px;
      background-color: rgba($color-black, 0.23);
      border: 2px solid rgba($color-blue-100, 0.4);

      @include media-breakpoint(lg) {
        width: 6px;
        border: 1px solid rgba($color-blue-100, 0.4);
      }

      @include media-breakpoint(md) {
        width: 4px;
      }
    }
  }

  &__table {
    &-row {
      th {
        color: $color-white;
        padding: 12px 0 5px 0;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 21.82px;
        color: white;
        // text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      td {
        padding: 7.5px 5px;

        @include safari-suport {
          padding: 7px 5px 8px 5px;
        }

        p {
          margin-bottom: 0;
          font-weight: 400;
        }
      }

      .name-data {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
          text-shadow: none;
        }
      }

      .name-data-assessment {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 11px;

        p {
          text-shadow: none;
        }
      }

      .monitor-data {
        display: flex;
        justify-content: space-between;
        padding-left: 36px;

        p {
          width: 70px;
          text-shadow: none;
        }
      }

      .monitor-data-assessment-date {
        display: flex;
        justify-content: space-between;

        p {
          width: 73px;
          text-shadow: none;
        }
      }

      .edit-data {
        display: flex;
        justify-content: space-between;
        padding: 0 16px;

        p {
          width: 73px;
          text-shadow: none;
        }
      }

      .edit-data-assessment-dates {
        display: flex;
        justify-content: space-between;
        padding-right: 36px;

        p {
          width: 73px;
          text-shadow: none;
        }
      }
    }

    &-name {
      width: 210px;
      padding-left: 16px !important;
      padding-right: 5px !important;

      @include media-breakpoint(lg) {
        min-width: 200px;
      }
    }

    &-monitor {
      width: 221px;
      text-align: right;
      padding-right: 0px !important;

      @include media-breakpoint(lg) {
        min-width: 260px;
      }
    }

    &-edit {
      width: 291px;
      text-align: right;
      padding-right: 50px !important;

      @include media-breakpoint(lg) {
        min-width: 260px;
      }
    }
  }
}

// Performance
.performance {
  padding: 11px 18px;

  &.print {
    padding: 30px 0px 30px 0px;
  }

  &__perecentage {
    display: flex;
    justify-content: flex-end;

    &.print {
      justify-content: center;
      color: $color-black;
    }

    &-contentainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 351px;
      height: 150px;

      @media print {
        align-items: center;

        @include mozilla-suport {
          width: 351px;
          height: 150px;
        }
      }
    }

    &-title {
      font-weight: bold;
      line-height: 20px;
      text-shadow: $text-shadow;
      margin-bottom: 10px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      &.print {
        text-shadow: unset;

        @include mozilla-suport {
          text-align: left;
          font-size: 15px;
          line-height: 1.3;
        }
      }
    }

    &-score {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 280px;

      @include media-breakpoint(md) {
        width: 280px;
      }

      @media print {
        @include mozilla-suport {
          width: 280px;
        }
      }

      p {
        margin: 0;
        line-height: 20px;
      }

      &.print p {
        color: $color-black;

        @include mozilla-suport {
          font-size: 12px;
          line-height: 1.4;
        }
      }
    }

    &-result {
      font-size: 14px;
      color: $color-gray-500;
      line-height: 20px;
      text-shadow: $text-shadow;
      margin-top: 10px;
      margin-bottom: 0;

      @include media-breakpoint(sm) {
        font-size: 12px;
        margin-top: 6px;
      }

      &.print {
        text-shadow: unset;
        color: $color-black;

        @include mozilla-suport {
          font-size: 13px;
          line-height: 1.5;
        }
      }
    }
  }

  &-clark-container {
    padding: 20px 22px;
    padding-bottom: 4px;
  }

  &__clark {
    margin-bottom: 18px;
    @include section;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $color-gray-900;
      border-radius: 8px 8px 0 0;
      padding: 10px 19px;

      @include media-breakpoint(xxl) {
        padding: 10px 18px;
      }
    }

    &-heading {
      h3 {
        font-size: 20px;
        font-weight: bold;
      }

      span {
        display: inline-block;
        background-color: rgba($color-red, 0.29);
        border-radius: $border-radius-md;
        width: 60px;
        height: 25px;
        text-align: center;
        font-size: 12px;
        line-height: 17px;
        padding: 4px 0;
      }
    }
  }

  &__table {
    &-row {
      &.blue-row {
        // background-color: $color-blue-600;
        background-color: $theme-bg-color-dark-variant3;
      }

      th {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
        }
      }

      td {
        padding-top: 9px;
        padding-bottom: 9px;
      }

      .name-data {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;

        @include media-breakpoint(xl) {
          padding-right: 30px;
        }
      }

      .error-data {
        text-align: left;
        font-size: 12px;
        letter-spacing: 0px;
        color: $color-orange-100;
        line-height: 14px;
        text-shadow: $text-shadow;
        margin-bottom: 0;
      }
    }

    &-name {
      width: 270px;
      min-width: 260px;
      text-align: right;
      padding-right: 20px !important;
    }

    &-address {
      width: 170px;
      min-width: 170px;
    }

    &-secondAddess {
      width: 140px;
      min-width: 140px;
    }

    &-city {
      width: 104px;
      min-width: 104px;
    }

    &-state {
      width: 71px;
      min-width: 71px;
    }

    &-zip {
      width: 80px;
      min-width: 80px;
    }

    &-phone {
      width: 149px;
      min-width: 149px;
    }

    &-options {
      min-width: 78px;
    }

    &-error {
      min-width: 135px;
    }
  }
}

// Student test results
.testResults {
  margin: 0 21px 16px 21px;
  @include section;

  @include media-breakpoint(md) {
    margin: 16px;
    margin-top: 0;
  }

  &.print {
    margin: 0;
    box-shadow: unset;
    border-radius: 0;
    overflow: visible;
    margin-top: 30px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-900;
    border-radius: 8px 8px 0 0;
    padding: 8px 20px;

    @include media-breakpoint(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    &.print {
      background-color: transparent;
      border-radius: 0;

      @include mozilla-suport {
        padding-left: 0;
      }
    }

    &-heading {
      display: flex;
      align-items: center;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-right: 21px;

      &.print {
        line-height: 30px;
        color: $color-black;
        @include mozilla-suport {
          font-size: 19px;
          line-height: 1.5;
          margin-left: 12px !important;
        }
      }
    }

    &-select {
      display: flex;
      align-items: center;

      @include media-breakpoint(md) {
        margin-left: auto;
      }

      select {
        background-color: $color-gray-850;
        background-position: right 0px center;
        border-color: $color-gray-900;
        color: $color-dark-300;
        margin: 0 10px;
        height: 26px;
        line-height: 1;
        padding: 0 17px 0 2px;

        &:disabled {
          opacity: 0.26;
        }
      }
    }
  }

  // TestResult Table
  &__table {
    th {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      text-shadow: $text-shadow;
      margin-bottom: 0;
      padding: 4px 10px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @include safari-suport {
        padding-top: 3px;
        padding-bottom: 5px;
      }

      @media print {
        @include mozilla-suport {
          font-size: 10px;
          line-height: 1.5;
        }
      }
    }

    td {
      vertical-align: middle;
      font-size: 16px;
      line-height: 20px;
      padding: 8px 10px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @include safari-suport {
        padding-top: 7px;
        padding-bottom: 9px;
      }

      @media print {
        @include mozilla-suport {
          font-size: 10px;
          line-height: 1.5;
          padding: 4px 10px;
        }
      }
    }

    &-header {
      background-color: $color-blue-600;
    }

    &-row {
      .completed-data {
        text-align: right;
        padding-right: 73px !important;

        @include media-breakpoint(lg) {
          padding-right: 32px !important;
        }

        @media print {
          @include mozilla-suport {
            min-width: 200px;
            width: 200px;
            padding-right: 20px !important;
          }
        }
      }
    }

    &-attempts {
      text-align: left;
      padding-left: 40px !important;
    }

    &-completed {
      min-width: 395px;
      width: 395px;
      text-align: right;
      padding-right: 73px !important;

      @include media-breakpoint(lg) {
        min-width: 360px;
        width: 360px;
        padding-right: 32px !important;
      }

      @media print {
        @include mozilla-suport {
          min-width: 200px;
          width: 200px;
          padding-right: 20px !important;
        }
      }
    }

    &-date-attempts {
      min-width: 135px;
      width: 135px;

      @include media-breakpoint(lg) {
        min-width: 108px;
        width: 108px;
      }

      @media print {
        @include mozilla-suport {
          min-width: 70px;
          width: 70px;
        }
      }

      img {
        margin-left: 6px;
      }
    }

    &-date {
      min-width: 145px;
      width: 145px;

      @include media-breakpoint(lg) {
        min-width: 108px;
        width: 108px;
      }

      @media print {
        @include mozilla-suport {
          min-width: 70px;
          width: 70px;
        }
      }

      img {
        margin-left: 6px;
      }
    }

    &-score {
      min-width: 130px;
    }

    &-attempt {
      min-width: 50px;
    }

    &-print {
      thead {
        background-color: transparent;
      }
      th,
      td {
        text-shadow: unset;
        color: $color-black;
        padding: 8px 10px;
      }
    }
  }
  &__footer {
    div {
      bottom: 8px !important;
    }
  }
}

// fieldset student merge
.fieldset {
  border: 1px solid $color-white;
  top: 14px;
  border-radius: $border-radius-md;
  min-height: 150px;
  position: relative;

  &-legend {
    position: absolute;
    left: 30px;
    top: -11px;
    margin: 0px;
    padding: 0 10px;
    display: inline-block;
    background-color: #3c4047;
    width: fit-content;
  }

  &-empty {
    line-height: 150px;
    height: 150px;
    text-align: center;
  }

  &-empty > p {
    margin: 0;
    font-size: 17px;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
  }
}

// Student Pdf
.studentPdf {
  background-color: white;
  .print-report__header {
    border-bottom: 1px solid $theme-border-color;
    .title {
      font-size: 12px;
    }
    p {
      font-size: 10px;
    }
    h3,
    p {
      font-weight: 900;
      margin-bottom: 0px;
      color: $color-black;
      line-height: 16px;
    }
    margin-bottom: 4px;
    padding-bottom: 5px;
  }
  &__table {
    margin-top: 20px;
    thead {
      th {
        vertical-align: bottom;
        color: $color-black;
        font-weight: bold;
        padding-bottom: 5px;
        font-size: 10px;
        line-height: 16px;
      }
    }

    tbody {
      tr {
        td {
          padding: 6px 0px;
          font-size: 10px;
          line-height: 16px;
        }
      }
    }

    &__row {
      color: black;
      &__name {
        width: 250px;
        text-align: right;
        padding-right: 0 !important;
      }
      &__studentId {
        min-width: 150px;
        width: 88px;
        text-align: center;
      }
      &__notes {
        width: 250px;
        text-align: left;
      }

      .fontBold {
        font-weight: 700;
      }
    }
  }
}

.students__clever_text_button {
  background: none !important;
  border: none !important;
  color: white !important;
}

.stl-school-download-btn {
  width: 345px;

  .download-report__btn-text {
    .download-report__btn-close {
      margin-left: 45px;
    }
  }
}
