.SummativeStudentsReport {
    @include section;
    margin-top: 22px;

    @media print {
        box-shadow: unset;
    }

    &-content {
        background-color: $color-gray-500;
    }

    &__tableContainer {
        padding-top: 22px;

        @include media-breakpoint(md) {
            overflow-y: auto;
        }
    }

    &__table {
        thead {
            background: $color-gray-500;
            z-index: 99;

            @media print {
                background: transparent;
            }

            th {
                border-radius: 0;
                vertical-align: bottom;
                font-size: 16px;
                color: $color-black;
                line-height: 20px;
                padding-bottom: 5px;

                @include media-breakpoint(md) {
                    font-size: 14px;
                }

                @media print {
                    @include mozilla-suport {
                        font-size: 10px;
                        line-height: 1.5;
                    }
                }
            }
        }

        tbody {
            tr {
                border-radius: $border-radius-md;

                &:nth-child(odd) {
                    background-color: $color-gray;

                    @media print {
                        background-color: transparent;
                    }
                }

                td {
                    vertical-align: middle;
                    font-size: 16px;
                    line-height: 21px;
                    padding: 8px 0;

                    @include media-breakpoint(md) {
                        font-size: 14px;
                    }

                    @media print {
                        @include mozilla-suport {
                            font-size: 10px;
                            line-height: 1.5;
                            padding: 4px 0;
                        }
                    }
                }
            }
        }

        &__row {

            &__Name {
                width: 180px;
                padding-left: 16px !important;
                text-align: right;
                font-weight: bold;

                @media print {
                    @include mozilla-suport {
                        width: 140px;
                        padding-left: 30px !important;
                    }
                }

                @include media-breakpoint(md) {
                    min-width: 200px;
                }
            }

            &__proficiency {
                width: 180px;
                padding-left: 75px !important;
                position: relative;

                @media print {

                    @include safari-print {
                        padding-left: 60px !important;
                    }

                    @include mozilla-suport {
                        width: 140px;
                        padding-left: 40px !important;
                    }
                }

                @include media-breakpoint(md) {
                    min-width: 170px;
                    padding-left: 40px !important;
                }
            }

            &__studentId {
                text-align: right;
                width: 360px;

                @media only screen and (max-width: 1258px) {
                    width: auto;
                }

                @include media-breakpoint(md) {
                    min-width: 110px;
                }

                @media print {
                    @include mozilla-suport {
                        width: 240px;
                    }
                }
            }

            &__pecentage {
                width: 375px;
                padding-left: 9px;

                @media print {
                    @include mozilla-suport {
                        padding-left: 0 !important;
                    }
                }

                @include media-breakpoint(md) {
                    min-width: 116px;
                }
            }

            .fontBold {
                font-weight: 700;
            }

            .percentageAlignment {
                display: inline-block;
                width: 95px;
                text-align: center;

                @media print {
                    @include mozilla-suport {
                        width: 56px;
                    }
                }
            }

            .coloredBox {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 12px;
                transform: translateY(2px);
                box-shadow: 2px 2px 1px rgb($color-black, .22);

                @media print {
                    box-shadow: unset;

                    @include mozilla-suport {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
            .box-Below {
                background-color: $color-red-200;
                @media print {
                    -webkit-print-color-adjust: exact;
                    border: 1px solid $color-red-200;
                }
            }

            .box-Advanced {
                background-color: $theme-bg-color-dark;
                @media print {
                    background-color: transparent;
                    border: 1px dashed $color-blue-700;
                }
            }

            .box-Basic {
                background-color: $color-yellow-200;
                @media print {
                    -webkit-print-color-adjust: exact;
                    border: 1px solid $color-yellow-200;
                }
            }

            .box-Proficient {
                background-color: $color-green-600;
                @media print {
                    background-color: transparent;
                    border: 1px dashed $color-green-600;
                }
            }
        }
    }

    &__info {
        position: relative;
        padding: 0 55px;

        @include media-breakpoint(xl) {
            flex-direction: column;
            align-items: center;
            padding: 0 15px;
        }

        @media print {
            flex-direction: row;
            align-items: center;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
        }

        &-box {
            position: absolute;
            left: 55px;
            top: 22px;
            width: 100%;
            max-width: 348px;

            @include media-breakpoint(xl) {
                order: 1;
                position: relative;
                left: auto;
                top: auto;
            }

            @media print {
                position: absolute;
                left: 55px;
                top: 22px;
                padding-top: 20px;
            }

            p {
                font-size: 16px;
                color: $color-gray-850;
                line-height: 20px;
                margin-bottom: 0;
            }

            @-moz-document url-prefix() {
                p {
                    font-size: 10px !important;
                    line-height: 1.3 !important;
                }
            }
        }

        &-score {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $color-white-C7C7C7;
            border: 1px solid $color-gray-750;
            padding: 7px 19px;
            padding-bottom: 14px;

            @include media-breakpoint(xl) {
                order: 2;
                margin-left: 0;
                margin-top: 20px;
            }

            @include media-breakpoint(md) {
                padding: 15px;
            }

            @include media-breakpoint(sm) {
                padding: 15px 10px;
            }

            @media print {
                background-color: transparent;
                border: unset;
                margin-left: 20px;
            }
        }

        &-title {
            font-size: 16px;
            font-weight: 600;

            @include media-breakpoint(md) {
                font-size: 14px;
                text-align: center;
            }
        }

        @-moz-document url-prefix() {
            &-title {
                font-size: 10px !important;
            }
        }

        &-subtitle {
            font-size: 14px;

            @include media-breakpoint(md) {
                text-align: center;
            }

            @include media-breakpoint(sm) {
                font-size: 12px;
            }
        }

        @media print {
            @-moz-document url-prefix() {
                &-subtitle {
                    font-size: 10px !important;
                }
            }
        }

        &-row {
            display: flex;
            justify-content: space-between;
            width: 281px;

            @media print {
                @include mozilla-suport {
                    width: 180px;
                }
            }

            @include media-breakpoint(md) {
                width: 250px;
            }

            @include media-breakpoint(sm) {
                width: 240px;
            }
        }

        @media print {
            @-moz-document url-prefix() {
                &-row {
                    font-size: 10px !important;
                }
            }
        }

        &-item {
            width: 16px;
            height: 16px;
            margin-right: 7px;
            margin-top: 1px;
            box-shadow: 2px 2px 1px rgb($color-black, 22%);

            @media print {
                width: 18px;
                height: 18px;
                box-shadow: unset;
            }

            @media print {
                @include mozilla-suport {
                    width: 12px !important;
                    height: 12px !important;
                    transform: translateY(3px);
                }
            }

            &.advance {
                background-color: $color-blue-800;

                @media print {
                    background-color: transparent;
                    border: 1px dashed $color-blue-800;
                }
            }

            &.proficient {
                background-color: $color-green-600;

                @media print {
                    background-color: transparent;
                    border: 1px dashed $color-green-600;
                }
            }

            &.basic {
                background-color: $color-yellow-200;
                @media print {
                    -webkit-print-color-adjust: exact;
                }
            }

            &.belowBasic {
                background-color: $color-red-200;
                @media print {
                    -webkit-print-color-adjust: exact;
                }
            }
        }
    }

    &__uperRow {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 12px 25px;

        @include media-breakpoint(xl) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        &__searchbar {
            max-width: 644px;
            margin: 0 0 0 150px;
            padding-top: 17px;

            @include media-breakpoint(md) {
                max-width: 100%;
                padding: 15px;
                padding-bottom: 0;
            }

            &-container {
                display: flex;
                align-items: center;
                max-width: 214px;

                img {
                    margin-right: 10px;
                }

                .margin-left {
                    margin-left: 27px;
                }
            }
        }

        &__centerText {
            position: absolute;
            left: 64px;
            top: 34px;
            width: 348px;
            color: $color-gray-850;
            line-height: 20px;

            @include media-breakpoint(xxl) {
                max-width: 300px;
                width: 100%;
            }

            @include media-breakpoint(xl) {
                position: relative;
                top: unset;
                left: unset;
            }
        }
    }
}
 
.StudentSummativeReport .table-dropdown {
    width: 210px;
    margin-left: 15px !important;
    margin-right: 15px !important;

    @include media-breakpoint(xl) {
        width: 250px;
        padding: 0 10px;
    }

    @include media-breakpoint(lg) {
        width: 230px;
    }

    .table-dropdown-menu {
        width: 210px;

        @include media-breakpoint(xl) {
            width: 230px;
        }

        @include media-breakpoint(lg) {
            width: 210px;
        }
    }
}

.StudentSummativeReport .table-dropdown button {
    width: 100%;
    line-height: 21px;
    padding: 2px 10px;
}

.StudentSummativeReport .table-dropdown-container {
    position: absolute;
    left: 50%;
    top: 14px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    width: 390px;

    @include media-breakpoint(xl) {
        width: 294px;
    }

    @include media-breakpoint(lg) {
        width: 274px;
    }

    .text-btn {
        padding: 0;

        &:disabled {
            opacity: .27;
        }

        img {
            width: 22px;
            height: auto;
        }
    }
}
