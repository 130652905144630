.wrapper {
  position: fixed;
  cursor: pointer;
}

.tooltip {
  position: fixed;
  background: #333;
  color: white;
  box-shadow: 2px 2px 3px rgba(0 ,0, 0, 0.3);
  text-align: center;
  font-size: 16px;
  margin-left: -40px !important;
  margin-top: -15px !important;
  padding: 0px 5px !important;
  border-radius: 5px !important;
}

.contentProperty {
  color: #fff;
  display: inline;
  font-size: .85em;
  font-family: 'Roboto; Helvetica; Arial; sans-serif';
  padding: .3em 1em;
}
.arrow {
  position: absolute;
  width: 0;
  height: 0;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  border-top: solid rgba(0, 0, 0, 0.7) 5px;
}
.gapProperty {
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: -20px;
  pointer-events: none;
}
.Objective_tooltip_width {
  max-width: 350px;
  text-align: left;
}

#tooltip .tooltip {
  margin-left: -55px !important;
  margin-top: -45px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
