.header {
  // margin-top: 30px;

  // @include media-breakpoint(xl) {
  //   margin-top: 22px;
  // }

  @media print {
    display: none;
  }

  &-container {
    // box-shadow: $section-shadow;
    position: relative;

    // .toolTip-bg {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-color: #000000;
    //   opacity: 57%;
    //   border-radius: $border-radius-md;
    //   animation-name: toolTipbgAnimate;
    //   animation-duration: 1s;

    //   @keyframes toolTipbgAnimate {
    //     from {opacity: 0%;}
    //     to {opacity: 57%;}
    //   }
    // }

    // .toolTip-bg-transparent {
    //   background-color: unset;
    // }
  }

  &-wrapper{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__navbar {
    background-color: $color-yellow;
    border-radius: $border-radius-md;
    padding: 7px 15px;
    width: 100%;
    justify-content: space-between;

    &.radious {
      border-radius: 8px 8px 0 0;
    }

    &-brand {
      padding: 0;
      margin: 0;
    }

    &-logo {
      @include media-breakpoint(md) {
        text-align: center;
      }

      img {
        max-width: 217px;
        height: auto;

        @include media-breakpoint(md) {
          max-width: 170px;
        }
      }
    }

    .dropdown-support-center {
      display: flex;
      // height: 16px;
      align-items: center;

      .userCounter {
        background-color: #c44538;
        font-size: 12px;
        border-radius: 100%;
        display: inline-block;
        width: 23px;
        height: 23px;
        text-align: center;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        padding-right: 1px;
        padding-top: 1px;
      }

      .userMaxCounter {
        width: 26px;
        height: 26px;
      }

    }

    .username-box {
      position: relative;
      .userCounter {
        background-color: #c44538;
        font-size: 12px;
        line-height: 16px;
        border-radius: 100%;
        position: absolute;
        display: inline-block;
        width: 21px;
        height: 21px;
        text-align: center;
        line-height: 21px;
        top: -8px;
        left: -8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .userMaxCounter {
        width: 26px;
        height: 26px;
      }

      @include flexcenter-both;
      // background-color: $color-blue-100;
      width: 46px;
      height: 46px;
      border-radius: $border-radius-md;
      color: $color-white;
      font-size: 18px;
      line-height: 22px;

      @include media-breakpoint(md) {
        width: 40px;
        height: 40px;
        font-size: 16px;
      }
    }

    &-toggler {
      color: $color-blue-700 !important;
      border-color: $theme-border-color !important;
      font-size: 16px;
      padding: 4px 8px;
      transition: all 0.3s;

      @include media-breakpoint(md) {
        padding: 2px 6px;
        font-size: 14px;
      }

      &:hover {
        color: darken($color-blue-700, 10%);
        border-color: darken($color-blue-700, 10%);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 337px;

    @include media-breakpoint(xl) {
      width: 217px;
    }
  }

  &__menu{
    justify-content: flex-end;
    flex-grow: inherit;

    @include media-breakpoint(lg) {
      order: 1;
    }
  }

  &__user{
    display: flex;
    justify-content: flex-end;
    width: 337px;

    @include media-breakpoint(xl) {
      width: 247px;
    }

    @include media-breakpoint(lg) {
      order: 1;
      width: 100%;
    }
  }

  // Profile drodown
  &__dropdown {
    background-color: $color-gray-500;
    padding: 7px 6px 7px 6px;
    border-radius: 0 0 8px 8px;
    transition: all 0.4s;

    @include media-breakpoint(lg) {
      padding: 12px 10px 16px 10px;
    }

    @include media-breakpoint(sm) {
      padding: 16px;
      padding-top: 12px;
    }

    &-wrapper {
      display: flex;
      align-items: center;

      @include media-breakpoint(lg) {
        width: 100%;
        justify-content: space-between;
        padding-left: 6px;
      }

      @include media-breakpoint(sm) {
        flex-direction: column;
      }
    }

    &-inner {
      max-width: 1000px;
      margin: auto;

      @include media-breakpoint(xl) {
        max-width: 100%;
      }

      @include media-breakpoint(md) {
        padding-top: 20px;
      }

      .muskogee-box {
        @include media-breakpoint(lg) {
          display: none;
        }
      }
    }
    &-inner-admin {
      margin: auto;

      @include media-breakpoint(xl) {
        max-width: 100%;
      }
    }

    &-inner-csv {
      position: absolute;
      left: 50%;
      width: 250px;
      transform: translateX(-50%);

      @media only screen and (max-width: 1050px) {
        position: relative;
        left: unset;
        transform: translateX(0);
        margin-left: auto;
      }

      li {
        @include media-breakpoint(lg) {
          width: 100% !important;
        }
      }
    }

    &-flashing {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        animation: flash 1.5s linear;
        animation-iteration-count: 3;
        left: -1px;
        top: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 3px solid $color-blue-200;
        border-radius: $border-radius-md;
        pointer-events: none;
        z-index:2;
      }

      > .dropdown-diabled { 
        position: relative !important;
        z-index: 2 !important;
      }
    }

    &-list {
      ul {
        position: relative;
        @include flexcenter-both;
        flex-flow: row wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        flex-grow: 1;

        @include media-breakpoint(lg) {
          padding: 0;
        }

        @include media-breakpoint(md) {
          width: 100%;
        }

        &.management-list {

          @include media-breakpoint(md) {
            justify-content: space-between;
            align-items: flex-end;
          }

          @include media-breakpoint(sm) {
            flex-direction: column;
            align-items: center;
          }

          .stl {
            margin-top: 21px;
            position: absolute;
            right: 7px;
        
            .stl__dropdown-container{
               background-color: $color-white;
               border: 1px solid $color-blue-200;
               height: 33px;
               .stl__dropdown-placeholder {
                color: $color-blue-200;
               }               .dropdownIcon {
                filter: invert(52%) sepia(59%) saturate(3237%) hue-rotate(200deg) brightness(97%) contrast(108%);
               }
            }
            &__menu {
              &__list {
                  display: block;
                  padding: 4px 0px;
              }
            }
           
          }
        }

        .stl-school-dropdown {
          position: absolute;
          right: 7px;
          top: 22.5px;
        }
      }


      .form-select {
        background-image: url(../../img/drop-down-select.png);
        background-repeat: no-repeat;
        background-size: 14px;
        border: 1px solid $color-blue-200;
        border-radius: $border-radius-md;
        font-size: 16px;
        color: $color-blue-200;
        line-height: 24px;
        padding: 4px 15px;
        padding-left: 10px;
        cursor: pointer;

        @include media-breakpoint(md) {
          background-size: 9px;
          font-size: 14px;
        }
      }

      .district-logo {
        position: relative;
        left: -54px;
      }
    }

    &-item {
      position: relative;
      margin: 0 12.5px;
      z-index: 1111;

      @media (max-width: 991px) {
        z-index: unset;
      }

      @include media-breakpoint(xxl) {
        margin: 0 10px;
      }

      @include media-breakpoint(xl) {
        margin: 0 8px;
      }

      @include media-breakpoint(lg) {
        width: 50%;
        margin: 0 6px;
      }

      @include media-breakpoint(sm) {
        width: 100%;
        margin: 0;
      }

      span {
        line-height: 16px;
      }

      small {
        font-size: 14px;
        color: $color-gray-900;
        font-weight: 600;
        padding-left: 11px;

        &.disabled {
          opacity: 0.26;
        }
      }
    }

    &-itemSchool,
    &-itemTeacher {
      width: 250px;

      @include media-breakpoint(xxl) {
        width: 240px;
      }

      @include media-breakpoint(xl) {
        width: 236px;
      }

      @include media-breakpoint(lg) {
        width: 49%;
        margin: 0;
      }

      @include media-breakpoint(sm) {
        width: 100%;
      }

      &.management-dropdown {
        z-index: 4;
        
        @include media-breakpoint(lg) {
          width: 240px;
        }

        @include media-breakpoint(sm) {
          width: 100%;
        }
      }
    }

    &-itemGrade {
      width: 164px;

      @include media-breakpoint(lg) {
        width: 49%;
        margin: 0;
      }

      @include media-breakpoint(sm) {
        width: 100%;
      }
    }

    &-itemClass{
      width: 250px;

      @include media-breakpoint(xxl) {
        width: 240px;
      }

      @include media-breakpoint(xl) {
        width: 236px;
      }

      @include media-breakpoint(lg) {
        width: calc(50% - 12px);
      }

      @include media-breakpoint(sm) {
        width: 100%;
      }
    }
  }

  &__apply {
    &-btn {
      background-color: $color-blue-100;
      width: 88px;
      margin-top: 21px;

      @include media-breakpoint(xl) {
        width: 80px;
      }

      @include media-breakpoint(sm) {
        margin-top: 16px;
      }

      &:hover {
        color: $color-white;
        background-color: darken($color-blue-100, 10%);
      }
    }
  }
  &__top {
    position: relative;
  
    &-arrow {
      position: absolute;
      top: 56px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 450px;
      height: 20px;
  
      @include media-breakpoint(lg) {
        display: none;
      }
  
      &-animation {
  
        &::after {
          content: "";
          position: absolute;
          bottom: -23.5px;
          left: 44px;
          border-right: 16px solid transparent;
          border-left: 16px solid transparent;
          border-bottom: 16px solid $color-gray-500;
          display: block;
          margin: auto;
          transition: all .1s;
          opacity: 1 !important;
          @media (max-width: 991px) {
            left: 61px;
            bottom: -20.5px;
          }

          // @include media-breakpoint(xl) {
          //   left: 54px;
          // }
          // @media (max-width :1040px) {
          //   left: 60px;
          // }
          @include media-breakpoint(md) {
            margin-top: 0 !important;
          }
         
          @media (max-width: 389px) {
            display: none;
          }
        }
  
        &.activity_width::after {
          transform: translateX(0);
          -webkit-transform: translateX(0);
          animation: moveLeft 900ms ease;
          opacity: 1 !important;

          @include media-breakpoint(sm) {
            display: none;
          }
  
          @include media-breakpoint(md) {
            animation: moveLeft-md 900ms ease;
          }
          @include media-breakpoint(sm) {
            animation: moveLeft-sm 900ms ease;
          }
        }
  
        &.classroom_width::after {
          animation: moveRight 900ms ease-in-out;
          transform: translateX(142px);
          -webkit-transform: translateX(142px);
  
          @include media-breakpoint(lg) {
            animation: moveRight-md 900ms ease-in-out;
            transform: translateX(130px);
            -webkit-transform: translateX(130px);
          }
          @include media-breakpoint(sm) {
            display: none;
          }
        }

        &.classroom-admin-arrow::after {
          animation: moveRight2 900ms ease-in-out;
          transform: translateX(79px);
          -webkit-transform: translateX(79px);
        }
  
        &.management_width::after {
          animation: moveRightMng 900ms ease-in-out;
          transform: translateX(237px);
          -webkit-transform: translateX(237px);
          opacity: 1 !important;
          @include media-breakpoint(lg) {
            animation: moveRightMng-lg 900ms ease-in-out;
            transform: translateX(229px);
            -webkit-transform: translateX(229px);
          }
          @include media-breakpoint(md) {
            animation: moveRightMng-md 900ms ease-in-out;
          }
          @include media-breakpoint(sm) {
            animation: moveRightMng-sm 900ms ease-in-out;
            transform: translateX(240px);
            -webkit-transform: translateX(240px);
          }
          @include media-breakpoint(sm) {
            display: none;
          }
        }
      }
    }
  }
}

.navbar-light .navbar-nav .nav-link {
  @include flexcenter-both;
  height: 33px;
  color: $color-blue-700;
  padding: 2px 13.5px;
  font-size: 18px;
  line-height: 17px;
  margin: 0 2px;
  border-radius: 30px;
  transition: all 0.2s ease;
  opacity: 1;

  @include safari-suport {
    padding: 0 13.5px 4px 13.5px;
  }

  @media only screen and (max-width: 1199.9px) {
    padding: 2px 13.5px;
  }

  @include media-breakpoint(md) {
    margin: 5px 5px 0 0;
    font-size: 14px;
    padding: 2px 12px;
  }

  @media (max-width: 389px) {
    justify-content: flex-start;
    margin-right: 0;
  }

  &:nth-child(1) {
    svg {
      @include safari-suport {
        width: 20px;
        height: auto;
      }
      @include media-breakpoint(md) {
        width: 18px;
      }
    }
  }

  &:nth-child(2) {
    svg {
      @include safari-suport {
        width: 18.72px;
        height: auto;
      }

      @include media-breakpoint(md) {
        width: 16.72px;
      }
    }
  }

  &:nth-child(3) {
    @include media-breakpoint(md) {
      margin-right: 0;
    }

    svg {
      @include safari-suport {
        width: 17.5px;
        height: auto;
      }

      @include media-breakpoint(md) {
        width: 15.5px;
      }
    }
  }

  svg {
    fill: currentColor;
    transition: all 0.2s;
    height: 18px;
  }

  span {
    margin-left: 10px;

    @include safari-suport {
      transform: translateY(-1px);
    }

    @include media-breakpoint(md) {
      margin-left: 8px;
    }
  }

  &:hover {
    color: darken($color-blue-700, 10%);
  }
}

.nav-item.nav-link.active {
  background-color: $theme-bg-color-dark;
  opacity: 1;

  &:hover {
    background-color: darken($color-blue-700, 10%);
  }
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: $color-white;
}

.muskogee-box {
  position: absolute;
  left: 118px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 53px;

  img {
    max-width: 65px;
    max-height: 53px;
    object-fit: cover;
  }
}

.district-logo {
  @include media-breakpoint(xl) {
    display: none;
  }

  img {
    max-width: 62px;
    max-height: 62px;
    height: auto;
  }
}

.login-user {
  &-info {
    width: 147px;
    padding-left: 8px;
    color: $color-blue-700;
    margin-right: 17px;

    @include media-breakpoint(lg) {
      flex: 1;
      width: calc(100% - 82px);
    }

    p {
      color: inherit;
    }
  }

  &-name {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 2px;
    padding-right: 6px;
    @include ellipsis;

    @include media-breakpoint(md) {
      font-size: 16px;
    }
  }

  &-detail {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0;

    @include media-breakpoint(md) {
      font-size: 12px;
    }
  }

  // Users dropdown Menu
  &-dropdown {
    display: block;
    width: 100%;
    background-color: $black-transparent;
    border-radius: $border-radius-md;
    color: $color-white;
    box-shadow: $section-shadow;
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
    transition: all linear 0.3s;
    z-index: 99999;
    right: 0 !important;

    @include media-breakpoint(md) {
      font-size: 14px;
    }

    &.show {
      inset: unset !important;
      opacity: 1;
      visibility: visible;
      transform: translateY(0) !important;
    }

    &.cms-dropdown{
      @include media-breakpoint(xxl) {
        width: 200px;
        transform: translateY(78px) !important;
      }

      &.show {
        @include media-breakpoint(xxl) {

          transform: translateY(48px) !important;
        }
      }
    }

    li a , span {
      color: $color-white;
      transition: all 0.3s;
      cursor: pointer;

      @include safari-suport {
        padding: 3px 16px 5px 16px;
    }

      &:hover {
        background-color: $theme-bg-color-lighter;
        color: $color-white !important;
      }
    }
  }
}

.user-dropdown-icon{
  margin-left: auto;
  margin-right: 17px;

  @include media-breakpoint(md) {
    margin-right: 5px;
  }

  img {
    @include media-breakpoint(md) {
      max-width: 16px;
      height: auto;
    }
  }
}

.header__navbar .login-account-profile {
  display: flex;
  align-items: center;

  @include media-breakpoint(lg) {
    padding: 6px;
  }
}

.dropdown-toggle.login-account-profile::after {
  display: none;
}

.login-account-info {
  width: 247px;

  @include media-breakpoint(lg) {
    align-self: flex-end;
    width: 100%;
    border: 1px solid $color-blue-100;
    border-radius: $border-radius-md;
    margin-top: 2px;
    margin-bottom: 20px;
  }

  &.dropdown-menu {
    width: 100%;
    background-color: $color-blue-100;
    color: $color-white;

    .dropdown-item {
      color: $color-white;
    }
  }
}

.dropdown-support-pin {
  display: flex;
  // height: 16px;
  align-items: center;

  span {
    background: $color-yellow;
    margin-left: 18px;
    border-radius: 20px;
    color: $black-transparent;
    font-size: 14px;
    padding: 0px 5px;
  }
}

.navbar-main {

  @include media-breakpoint(lg) {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    padding: 14px 0;
  }

  &-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-opacity: 1;

    @include media-breakpoint(xxl) {
      padding-right: 16px;
    }

    @include media-breakpoint(lg) {
      order: 2;
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      padding-right: 0;
    }

    @media (max-width: 389px) {
      width: 100%;
    }
  }

  .navbar-nav {
    flex-direction: row;
    justify-content: center;
    opacity: 1 !important;
    -webkit-opacity: 1 !important;

    @media (max-width: 389px) {
      display: block;
    }
  }
}

.dropdown-separator {
  height: 0;
  margin: var(--bs-dropdown-item-padding-y) 10px;
  overflow: hidden;
  border-top: 1px solid #D8D8D8;
  opacity: 0.16;
}

.color-panel {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
}

.color-box {
  width: 31px;
  height: 31px;
  border: 2px solid transparent;
  margin-right: 14px;
  cursor: pointer;
}

.color-box.selected {
  border-color: white;
}
