.btn {
  font-family: inherit;
  font-size: 16px;
  color: $color-white;
  line-height: 22px;
  padding: 7px 16px;
  border-radius: $border-radius-md;
  transition: all 0.3s;

  @include safari-suport {
    padding-top: 5px;
    padding-bottom: 9px;
  }

  @include media-breakpoint(md) {
    font-size: 14px;
    padding: 5px 12px;
  }

  &-lg {
    padding-right: 22px;
    padding-left: 22px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &-xl {
    padding-right: 26px;
    padding-left: 26px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &-disabled {
    opacity: 0.52;
    cursor: unset !important;
  }

  &:disabled {
    opacity: 0.52;
  }

  &-medium {
    padding-right: 18px;
    padding-left: 18px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &-sm {
    padding-left: 14px;
    padding-right: 14px;

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &.font-extra-sm {
    @include custom-break-poitn(500px) {
      font-size: 12px;
    }
  }

  &-test-log {
    position: absolute;
    right: 8px;
    bottom: 0;
    background-color: $color-white;
    color: $color-blue-200;

    @include media-breakpoint(md) {
      position: unset;
      right: unset;
      padding-top: 7px;
      padding-bottom: 7px;
    }

    @include media-breakpoint(sm) {
      width: 100%;
      margin-top: 10px;
    }
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.test-log-button {
  padding-top: 6px;
  line-height: 16px;
  height: 33px;
}

.success-btn {
  background-color: $color-green !important;
  color: #fff !important;
  border: none;

  &.disabled {
    opacity: 0.23;
  }

  &:hover {
    color: $color-white;
    background-color: darken($color-green, 5%);
  }
}

.great-btn {
  background-color: #4398a1 !important;
  color: #fff !important;
  border: none;
  width: 84px !important;
  height: 33px !important;
  margin-right: 0 !important;
  border-radius: $border-radius-md !important;

  &.disabled {
    opacity: 0.23;
  }

  &:hover {
    color: $color-white;
    background-color: darken($color-green, 5%);
  }
}

.survey-no-thanks-btn {
  background-color: #f74733 !important;
  color: #fff !important;
  border: none;

  &:hover {
    color: #fff;
    background-color: #f35341;
  }

  &.disabled {
    opacity: 0.23;
  }
}

.survey-later-btn {
  background-color: $color-yellow-200 !important;
  color: #424242 !important;
  border: none;

  &.disabled {
    opacity: 0.23;
  }

  &:hover {
    color: $color-yellow-200;
    background-color: darken($color-green, 5%);
  }
}

.speech-btn {
  @include flexcenter-both;
  border: none;
  outline: none;
  background-color: $color-gray-900;
  width: 33.4px;
  height: 33.4px;
  border-radius: 50%;
  color: $color-white;
  padding: 0;
  margin-right: 9px;
  overflow: hidden;
  transition: all 0.2s ease;

  &.light {
    background-color: $color-white;
    color: $color-gray-900;
  }

  &.stop {
    background-color: $color-white;
  }

  &.speech-active {
    background-color: $color-black;
    color: $color-white;
  }

  &.filter-active {
    background-color: $color-white;
    color: $color-black;
    box-shadow: inset 0 0 3px 2px $color-black;
  }
}

.speech-btn:disabled {
  background-color: #d4d4d4;
}

.btn.danger-btn {
  background-color: $color-orange-100;
  padding-right: 22px;
  padding-left: 22px;
  color: $color-gray-950;

  &:focus-visible {
    border: 1px solid white !important;
    background-color: $color-orange-100;
  }

  &:active {
    background-color: $color-orange-100 !important;
  }

  @include media-breakpoint(xl) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include media-breakpoint(md) {
    padding-right: 12px;
    padding-left: 12px;
  }

  &:hover {
    color: $color-white;
    background-color: darken($color-red, 5%);
  }

  &-lg {
    padding-right: 22px;
    padding-left: 22px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &.btn-medium {
    padding-right: 18px;
    padding-left: 18px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &.btn-sm {
    padding-right: 17px;
    padding-left: 17px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

.orange-btn {
  background-color: $color-orange-100;
  color: $color-black;

  &.light {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  &:hover {
    color: $color-black;
    background-color: darken($color-orange-100, 5%);
  }
}

.revert-orange-btn {
  background-color: $color-orange-100 !important;
  color: white;
  padding-left: 19px;
  padding-right: 19px;

  &.light {
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  &:hover {
    color: $color-black;
    background-color: darken($color-orange-100, 5%);
  }
}

.cancel-btn {
  background-color: $theme-bg-color-dark;
  padding-right: 22px;
  padding-left: 22px;
  border: 1px solid $theme-modal-cancel-btn-border;

  &:active {
    background-color: $theme-bg-color-dark !important;
    color: white !important;
    border-color: white !important;
  }

  &:focus-visible {
    background-color: $theme-bg-color-dark !important;
  }

  @include media-breakpoint(xl) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include media-breakpoint(md) {
    padding-right: 12px;
    padding-left: 12px;
  }

  &:hover {
    color: $color-white;
    background-color: darken($color-blue-100, 5%);
  }

  &.btn-lg {
    padding-right: 25px;
    padding-left: 25px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

.back-cancel-btn {
  background-color: $color-blue-100 !important;
  color: white !important;
  padding-right: 23px;
  padding-left: 23px;

  @include media-breakpoint(xl) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include media-breakpoint(md) {
    padding-right: 12px;
    padding-left: 12px;
  }

  &:hover {
    color: $color-white;
    background-color: darken($color-blue-100, 5%);
  }

  &.btn-lg {
    padding-right: 25px;
    padding-left: 25px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

.outline-btn {
  border: 1px solid $color-white !important;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 33px;

  @include media-breakpoint(xxl) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @media only screen and (max-width: 800px) {
    padding-right: 8px;
    padding-left: 8px;
  }

  &.disabled {
    opacity: 0.26;
  }

  &-lg {
    padding-right: 26px;
    padding-left: 26px;

    @include media-breakpoint(xxl) {
      padding-right: 20px;
      padding-left: 20px;
    }

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &-sm {
    padding-right: 13px;
    padding-left: 13px;

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &:hover {
    border-color: darken($color-white, 10%);
    color: darken($color-white, 10%);
  }
}

.danger-un-enroll-btn {
  border: none;
  background-color: rgba(255, 0, 0, 0.4);
  font-size: 16px;
  padding: 4px 22px;

  @include safari-suport {
    padding-top: 3px;
    padding-bottom: 5px;
  }

  &:disabled {
    background-color: rgba(255, 0, 0, 0.35);
    color: $color-white;
  }

  &:hover,
  &:active {
    color: $color-white;
    background-color: rgba($color-red, 0.4);
  }
}

.enroll-btn {
  border: none;
  background-color: rgba($color-red, 0.29);
  font-size: 12px;
  padding: 4px 7px;

  @include safari-suport {
    padding-top: 3px;
    padding-bottom: 5px;
  }

  &:hover,
  &:active {
    color: $color-white;
    background-color: rgba($color-red, 0.4);
  }
}

.unenroll-button-class {
  border: 1px solid #858585;
  border-radius: $border-radius-md;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  color: #323233;

  &:hover,
  &:active {
    border: 1px solid #858585;
    border-radius: $border-radius-md;
  }
}

.to-classroom-btn {
  margin-left: 10px;
}

.unenroll-btn {
  font-size: 14px;
  padding: 2px 14px;
  border: 1px solid white;
  border-radius: $border-radius-md;

  @include safari-suport {
    padding-top: 3px;
    padding-bottom: 5px;
  }

  &:hover,
  &:active {
    color: $color-white;
    border: 1px solid white;
    border-radius: $border-radius-md;
  }
}

.gray-btn {
  background-color: $color-gray-850;
  padding-right: 22px;
  padding-left: 22px;

  @include media-breakpoint(xl) {
    padding-right: 16px;
    padding-left: 16px;
  }

  &:hover {
    color: $color-white;
    background-color: darken($color-gray-850, 5%);
  }

  &.btn-lg {
    padding-right: 25px;
    padding-left: 25px;

    @include media-breakpoint(xl) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.text-btn {
  border: 0;
  background-color: transparent;

  &.disabled {
    cursor: unset !important;
    opacity: 0.26;
  }
}

// pills
.pill-success {
  display: inline-block;
  background-color: $color-green;
  width: 58px;
  font-size: 16px;
  color: $color-white;
  text-align: center;
  border-radius: $border-radius-md;
  font-weight: bold;
  line-height: 22px;

  @include media-breakpoint(md) {
    font-size: 14px;
  }
}

.pill-danger {
  display: inline-block;
  background-color: rgba($color-red, 0.29);
  width: 58px;
  font-size: 16px;
  color: $color-white;
  text-align: center;
  border-radius: $border-radius-md;
  font-weight: bold;
  line-height: 22px;

  @include media-breakpoint(md) {
    font-size: 14px;
  }
}

.pill-until {
  display: inline-block;
  background-color: #9080ff;
  width: 58px;
  font-size: 16px;
  color: $color-white;
  text-align: center;
  border-radius: $border-radius-md;
  font-weight: bold;
  line-height: 22px;

  @include media-breakpoint(md) {
    font-size: 14px;
  }
}

.pill-warning {
  display: inline-block;
  background-color: $color-yellow;
  width: 60px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: $color-black;
  line-height: 20px;
  border-radius: $border-radius-md;
  margin-left: 13px;
}

.pill-circle {
  display: inline-block;
  background-color: $color-yellow;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 7px;

  &.danger {
    background-color: $color-orange-100;
  }
}

// Counter pills
.pill-counter {
  @include flexcenter-both;
  min-width: 29px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 9px;
  padding-top: 1px;

  @include safari-suport {
    padding-bottom: 2px;
  }

  &.success {
    background-color: $theme--bg-color-seaGreen-light;
  }

  &.pill-text-adjust {
    font-size: 13px;
  }
}

.pill-status {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  transform: translateY(3px);
  // box-shadow: 2px 2px 1px rgba($color-black, 0.22);

  @include media-breakpoint(md) {
    width: 24px;
    height: 24px;
  }

  &.offline {
    background-color: #686868;
  }

  &.online {
    background-color: $color-green;
  }

  &.submitted {
    background-color: $color-yellow;
  }
}

// Download report button
.download-report {
  margin-top: 22px;

  &.closed {
    display: none;
  }

  &__btn {
    @include flexcenter-both;
    width: 275px;
    height: 42px;
    background-color: $modal-bg;
    padding: 10px 13px 10px 16px;
    font-size: 16px;
    color: $color-white;
    font-weight: bold;
    line-height: 20px;
    box-shadow: 3px 3px 6px rgba($color-black, 0.55);
    border-radius: $border-radius-md;
    border: none;
    opacity: 0.87;
    margin-right: 16px;
    transition: all 1.5s;

    &.change-color {
      background-color: $color-green;
      justify-content: space-between;
      padding-left: 28px;
    }

    &-flashing {
      position: relative;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color-light-800;
      transform: translate(14px, 3px);
      margin-right: auto;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;

      &::after,
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $color-light-800;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::before {
        left: -8px;
      }

      &::after {
        left: 8px;
        animation-delay: 1s;
      }
    }

    &-text {
      position: relative;
    }

    &-close {
      display: inline-block;
      background-color: $color-gray-350;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      color: $color-gray-750;
      font-weight: bold;
      line-height: 0.92;
      padding: 1px;
    }
  }
}

.pacing-clear-color-fix {
  color: #fff !important;
}
