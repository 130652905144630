@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

.StudentSummativeReport {
    @include section;
    margin-top: 22px;

    @media print {
        box-shadow: unset;
    }

    &.student-summative-report-dropdown {

        .report__header {
            flex-wrap: wrap;
            gap: 10px;

            .table-dropdown-container {
                width: 270px;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: unset;

                @media (max-width: 1075px) {
                    position: unset;
                    margin: 0;
                }
            }

            .report__header-buttons {
                @media (max-width: 610px) {
                    margin-left: auto;
                }
            }
        }
    }

    &-content {
        background-color: $color-gray-500;
    }

    &__uperRow {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 26px;

        @include media-breakpoint(lg) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        @media print {
            position: relative;
            padding: 0;
        }

        &__searchbar {
            display: flex;
            align-items: center;
            width: 212px;
            padding-top: 5px;

            @include media-breakpoint(lg) {
                order: 3;
            }

            input, input::placeholder {
                color: $color-gray-650;
                font-size: 14px;
                font-family: 'Nunito', sans-serif;
                font-weight: 500;
                height: 27px;
                padding-top: 3px;
            }

            img {
                margin-right: 10px;
            }
        }

        &__centerText {
            padding-top: 10px;
            color: $color-gray-850;
            line-height: 20px;

            @include media-breakpoint(lg) {
                padding-top: 4px;
            }

            @media print {
                display: none;
            }
        }

        &__score {
            display: flex;
            justify-content: flex-end;
            width: 214px;

            @include media-breakpoint(lg) {
                margin: 14px 0 10px 0;
            }

            @media print {
                position: absolute;
                top: 20px;
                right: 0;
                width: 100px;

                @include mozilla-suport {
                    width: 70px;
                }
            }
        }

        &__colorBox {
            width: 192px;
            height: 70px;
            background-color: $color-white-C7C7C7;
            border: 1px solid $color-gray-750;
            padding: 10px;
            padding-left: 50px;

            @media print {
                width: 100px;
                padding-left: 0;
                background-color: transparent;
                border: unset;
                -webkit-print-color-adjust: exact;

                @include mozilla-suport {
                    width: 70px;
                }
            }

            &__correct {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                span {
                    margin-top: -1px;
                }

                .greenBox {
                    width: 16px;
                    height: 16px;
                    background: $color-green;
                    box-shadow: 2px 2px 1px rgba($color-black, .22);
                    margin-right: 7px;
                    margin-top: -2px;

                    @media print {
                        box-shadow: unset;

                        @include mozilla-suport {
                            width: 12px;
                            height: 12px;
                            font-size: 10px !important;
                            line-height: 1.5;
                        }
                    }
                }

                span {
                    @media print {
                        @include mozilla-suport {
                            font-size: 10px !important;
                            line-height: 1.5;
                        }
                    }
                }
            }

            &__Incorrect {
                align-items: center;
                display: flex;

                .redBox {
                    width: 16px;
                    height: 16px;
                    background: $color-red-600;
                    box-shadow: 2px 2px 1px rgba($color-black, .22);
                    margin-right: 7px;
                    margin-top: -1px;

                    @media print {
                        box-shadow: unset;

                        @include mozilla-suport {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                span {
                    @media print {
                        @include mozilla-suport {
                            font-size: 10px !important;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }

    &__tableContainer {
        padding-top: 47px;
        overflow-x: auto;

        @media print {
            padding-top: 30px;
        }

        table {
            min-width: 1000px;

            @include media-breakpoint(xl) {
                min-width: auto;
            }
        }

        thead tr th {
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            padding-bottom: 5px;

            @media print {
                @include mozilla-suport {
                    font-size: 10px;
                    line-height: 1.5;
                }
            }
        }

        &__body {
            &__row {
                td:nth-child(3) {
                    color: $color-black;
                }

                &:nth-child(odd) {
                    background-color: $color-gray;

                    @media print {
                        background-color: transparent;
                    }
                }

                .questionContent {
                    display: inline-block;
                    width: 82px;
                    text-align: center;
                    font-weight: bold;

                    @media print {
                        @include mozilla-suport {
                            width: 54px;
                        }
                    }
                }

                td {
                    font-size: 16px;
                    line-height: 21px;
                    vertical-align: top;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    @media print {
                        @include mozilla-suport {
                            font-size: 10px;
                            line-height: 1.5;
                            padding-top: 6px;
                            padding-bottom: 6px;
                        }
                    }
                }

                .objective-data {
                    display: inline-block;
                    width: 71px;
                    text-align: center;

                    @media print {
                        @include mozilla-suport {
                            text-align: left;
                            width: auto;
                        }
                    }
                }
            }
        }

        .textAlignmentTableContent {
            text-align: center;
            padding-top: 8px !important;
            padding-bottom: 8px !important;

            @media print {
                @include mozilla-suport {
                    padding-top: 6px !important;
                    padding-bottom: 6px !important;
                }
            }
        }

        .square {
            display: inline-block;
            align-items: center;
            width: 24px;
            height: 24px;
            color: $color-white;
            line-height: 24px;
            font-size: 14px;
            font-family: 'Cabin', sans-serif;

            @media print {
                line-height: 26px !important;
            }

            @media print {
                @include mozilla-suport {
                    width: 16px;
                    height: 16px;
                    font-size: 10px !important;
                    line-height: 16px !important;
                    padding: 0;
                    transform: translateY(4px);
                }
            }

            &.correct {
                background-color: $color-green;

                @media print {
                    -webkit-print-color-adjust: exact;
                    background-color: $color-green;
                }
            }

            &.incorrect {
                background-color: $color-red-600;

                @media print {
                    -webkit-print-color-adjust: exact;
                }
            }
        }
        

        &__question {
            width: 200px;
            text-align: right;

            @include media-breakpoint(xl) {
                width: 120px;
            }

            @media print {
                @include mozilla-suport {
                    width: 120px;
                }
            }
        }

        &__answers {
            width: 121px;
            text-align: center;

            @media print {
                @include mozilla-suport {
                    width: 80px;
                }
            }
        }
        &__objectiveDescription {
            width: 642px;
            padding-right: 20px;

            @include media-breakpoint(xl) {
                width: calc(100% - 346px);
            }

            @media print {
                @include mozilla-suport {
                    padding-right: 30px;
                    width: 380px;
                }
            }
        }

        &__objective {
            min-width: 105px;
        }
    }
}
