.risk-modal {

    &-body {
        max-width: 764px;
        width: 100%;
        margin: 8px auto 0px auto;
        padding-bottom: 0px;
    }

    .modal__footer {
        margin-top: 0 !important;
    }

    &__table {
        background-color: $theme-bg-color-lighter-variant2;
        color: $color-white;

        &-wrapper {
            border-radius: $border-radius-md;
            overflow: hidden;
        }

        &-container {
            width: 100%;
            max-height: 455px;
            border-radius: $border-radius-md;
            scrollbar-width: 12px;
            overflow-y: auto;

            @include media-breakpoint(lg) {
                scrollbar-width: 8px;
            }
        
            @include media-breakpoint(md) {
                scrollbar-width: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: $theme-bg-color-lighter-variant2;
            }
        
            &::-webkit-scrollbar {
                width: 12px;

                @include media-breakpoint(lg) {
                    width: 8px;
                }
            
                @include media-breakpoint(md) {
                    width: 6px;
                }
            }
        
            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 10px;
                background-color: rgba($color-black, 0.23);
                border: 2px solid rgba($color-blue-100, 0.4);

                @include media-breakpoint(lg) {
                    width: 6px;
                    border: 1px solid rgba($color-blue-100, 0.4);
                }
            
                @include media-breakpoint(md) {
                    width: 4px;
                }
            }
        }

        thead {
            position: sticky;
            top: 0;
        }

        &-headerRow {
            background-color: $theme-bg-color-dark-variant3;
        }

        th {
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            text-shadow: $text-shadow;
            padding-top: 5px;
            padding-bottom: 2px;
        }

        &-row:nth-child(even) {
            background-color: $theme-bg-color-lighter;
        }

        td {
            vertical-align: middle;
            font-size: 16px;
            line-height: 21px;
            padding-top: 8px;
            padding-bottom: 8px;

            @include safari-suport {
                padding-top: 6px;
                padding-bottom: 10px;
            }

            @include media-breakpoint(md) {
                font-size: 14px;
            }
        }

        &-count {
            position: absolute;
            left: 0;
            top: 0;
            background-color: $color-gray-900;
            min-width: 38px;
            height: 24px;
            text-align: center;
            border-top-left-radius: 8px;
            padding: 2px 14px !important;
            text-shadow: unset !important;
            z-index: 10;
        }

        &-name {
            width: 148px;
            text-align: right;
            vertical-align: bottom;

            @media only screen and (max-width: 850px) {
                min-width: 100px;
                width: 100px;
            }
        }

        &-last {
            min-width: 123px;
            width: 123px;
            padding-left: 18px;
            vertical-align: bottom;
        }

        &-id {
            min-width: 110px;
            width: 113px;
            text-align: center;
            vertical-align: bottom;
        }

        &-date {
            width: 115px;
            text-align: center;
            padding-left: 18px;
            padding-right: 18px;
        }

        &-proficiency {
            min-width: 78px;
            width: 78px;
            vertical-align: bottom;
            text-align: center;
        }

        &-questions {
            min-width: 115px;
            width: 115px;
            text-align: center;
        }
    }
}
