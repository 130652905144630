// Switch
.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 30px;
  margin-bottom: 6px;

  @include media-breakpoint(xl) {
    width: 41px;
    height: 24px;
  }

  &.disabled {
    opacity: 0.26;

    .switch-slider {
      cursor: default;
    }
  }

  &-container {
    position: relative;
  }

  &-small {
    width: 32px;
    height: 20px;
  }

  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #c9c9c9;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      background: $color-white;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      box-shadow: 0 3px 1px rgb($color-black, 0.06);
      transition: 0.3s;

      @include media-breakpoint(xl) {
        width: 20px;
        height: 20px;
      }
    }

    &-small::before {
      height: 16px;
      width: 16px;
    }
  }

  &-checkbox {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .switch-slider {
      background-color: $color-green;
    }

    &:checked + .switch-danger {
      background-color: $color-orange-100 !important;
    }

    &:checked + .switch-slider::before {
      transform: translateX(20px);

      @include media-breakpoint(xl) {
        transform: translateX(16px);
      }
    }

    &:checked + .switch-slider-small::before {
      transform: translateX(11px);
    }
  }
}

// Tabs switch
.tabs-switch {
  position: relative;
  background-color: $color-gray-500;
  width: 188px;
  height: 39px;
  // height: 31px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  margin: 0px auto;
  padding-left: 5px;
  padding-right: 5px;
  // border: 1px solid $theme-border-color;

  * {
    z-index: 2;
  }

  &-radio {
    display: none;
    &:checked {
      & + label {
        font-size: 16px;
        font-weight: 400;
        color: white;

        @include safari-suport {
          padding-top: 5px;
          padding-bottom: 7px;
        }
      }
    }
  }

  .custom-padding {
    display: none;
    &:checked {
      & + label {
      
      }
    }
  }

  input[id="radio-1"] {
    &:checked {
      & ~ .tabs-switch-slider {
        transform: translateX(0);
      }
    }
  }

  input[id="radio-2"] {
    &:checked {
      & ~ .tabs-switch-slider {
        width: 106.42px;
        min-width: 106.42px !important;
        transform: translateX(72px);
      }
    }
  }

  &-tab {
    @include flexcenter-both;
    height: 33px;
    font-size: 16px;
    color: $color-gray-950;
    line-height: 20px;
    border-radius: 40px;
    padding: 7px 11px 6px;
    transition: color 0.15s ease-in;
    cursor: pointer;
  }

  .tab-disable {
    opacity: 0.4;
  }

  &-slider {
    position: absolute;
    display: flex;
    height: 33px;
    width: 55px;
    background: $theme-bg-color-dark;
    transition: all 0.4s;
    border-radius: 40px;
    // box-shadow: 1px 1px 3px rgba($color-black, 0.52);
    box-shadow: unset;
    z-index: 1;
    // background-color: unset;
    // border: 2px solid #ffffff;
  }
}

// Report switch
.report-switch {
  position: relative;
  background-color: $color-gray-800;
  width: 300px;
  height: 33px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;

  &.aog-switch {
    width: auto;
  }

  &.oag-report-switch {
    @include custom-break-poitn(504px) {
      width: 443px;
    }
  }

  &.sm {
    width: 134px;
  }

  * {
    z-index: 2;
  }

  &-radio {
    display: none;
    visibility: hidden;
  }

  input[type="radio"] {
    display: none;
  }

  input[id="radio-1"] {
    &:checked {
      & ~ .report-switch-slider {
        transform: translateX(0);
      }
    }
  }

  input[id="radio-2"] {
    &:checked {
      & ~ .report-switch-slider {
        width: 147px;
        transform: translateX(147px);
      }

      & ~ .report-switch-slider.sm {
        width: 64px;
        transform: translateX(64px);
      }
    }
  }

  input[id="radio-3"] {
    &:checked {
      & ~ .report-switch-slider {
        width: 147px;
        transform: translateX(294px);
      }

      & ~ .report-switch-slider.sm {
        width: 64px;
        transform: translateX(64px);
      }
    }
  }
  input[id="radio-4"] {
    &:checked {
      & ~ .report-switch-slider {
        width: 147px;
        transform: translateX(441px);
      }

      & ~ .report-switch-slider.sm {
        width: 64px;
        transform: translateX(64px);
      }
    }
  }
  input[id="radio-5"] {
    &:checked {
      & ~ .report-switch-slider {
        width: 147px;
        transform: translateX(588px);
      }

      & ~ .report-switch-slider.sm {
        width: 64px;
        transform: translateX(64px);
      }
    }
  }
  input[id="radio-6"] {
    &:checked {
      & ~ .report-switch-slider {
        width: 147px;
        transform: translateX(735px);
      }

      & ~ .report-switch-slider.sm {
        width: 64px;
        transform: translateX(64px);
      }
    }
  }

  &-tab {
    @include flexcenter-both;
    width: 148px;
    height: 27px;
    border-radius: 40px;
    padding: 0;
    transition: color 0.15s ease-in;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    overflow: hidden;

    &.sm {
      width: 64px;
      text-align: center;
      padding: 3px 3px;
    }
  }

  &-slider {
    position: absolute;
    display: flex;
    height: 27px;
    width: 148px;
    background: $theme-bg-color-dark;
    transition: all 0.4s ease;
    border-radius: 40px;
    box-shadow: 1px 1px 3px rgba($color-black, 0.52);
    z-index: 1;

    &.sm {
      width: 64px;
    }
  }
}

.add-tool-modal {
  .tabs-switch {
    width: 226px !important;

    input[id="radio-2"] {
      &:checked {
        & ~ .tabs-switch-slider {
          width: 90px;
          min-width: 90px !important;
          transform: translateX(65px);
        }
      }
    }

    input[id="radio-3"] {
      &:checked {
        & ~ .tabs-switch-slider {
          width: 50px;
          min-width: 50px !important;
          transform: translateX(166px);
        }
      }
    }
  }
  .tabs-switch-tab {
    font-size: 16px;
    font-weight: 400;
  }
}

.student-tabs {
  .tabs-switch {
    width: 200px;
  }

  .tabs-switch input[id="radio-1"]:checked ~ .tabs-switch-slider {
    min-width: 84px !important;
  }

  .tabs-switch input[id="radio-2"]:checked ~ .tabs-switch-slider {
    width: 115px;
    transform: translateX(79px);
  }
}

.add-resources-tabs {
  
  .tabs-switch {
    margin-top: 15px;
  }
}

.add-class-admin-tabs,
.add-school-admin-tabs,
.add-district-admin-tabs {
  .tabs-switch {
    width: 200px;
    margin-top: 15px;
  }

  .tabs-switch input[id="radio-2"]:checked ~ .tabs-switch-slider {
    width: 109.27px;
    transform: translateX(81px);
  }
}

.activity-progress-tab {
  position: absolute;
  left: 0;
  right: 0;

  @media (max-width : 797px) {
    left: 0px;
    right: 0px;
  }

  .tabs-switch {
    width: 251px;
    // width: 256px;
    // margin: auto;
    // background-color: unset;

    // .tabs-switch-tab {
    //   color: white;
    // }

    input[id="radio-1"]:checked ~ .tabs-switch-slider {
      min-width: 124px !important;
    }

    input[id="radio-2"]:checked ~ .tabs-switch-slider {
      min-width: 60px !important;
      width: 60px;
      transform: translateX(130px);
    }

    input[id="radio-3"]:checked ~ .tabs-switch-slider {
      min-width: 46px !important;
      width: 46px;
      transform: translateX(195px);
    }

    #radio-label-1 {
      width: 124px !important;
    }

    #radio-label-2 {
      width: 60px !important;
    }
  }
  @include media-breakpoint(sm) {
    top: 60px !important;
  }
}

.showProgressReportButton {

  @media (max-width: 820px) {
    padding-bottom: 70px !important;
  }

  .activity-progress-tab {
    
    @media (max-width: 1092px) {
      left: unset;
      right: 403px;
    }

    @media (max-width: 820px) {
      top: 70px;
      left: 0;
      right: 0;
    }
  }
}

.monitor__table {
  .monitor__table-row {
    .switch-checkbox:checked + .switch-slider-small::before {
      transform: translateX(12px);
    }
  }
}

.small-toggle {
  @include flex-box(flex-start, center);

  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.37px;
    color: $color-gray-950;
    margin-bottom: 0;
    margin-right: 8px;
    margin-top: -2px;
  }

  .switch {
    width: 31px;
    height: 18px;

    .switch-slider {
      &::before {
        width: 15px;
        height: 15px;
        top: 1px;
        left: 1px;
      }
    }

    .switch-checkbox:checked + .switch-slider::before {
      transform: translateX(13px);
    }
  }
}

.small-common-toggle {
  @include flex-box(flex-start, center);

  margin-right: 20px;

  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.37px;
    color: $color-gray-950;
    margin-bottom: 0;
    margin-right: 8px;
    margin-top: -8px;
  }

  .switch {
    width: 31px;
    height: 18px;

    .switch-slider {
      &::before {
        width: 15px;
        height: 15px;
        top: 1px;
        left: 1px;
      }
    }

    .switch-checkbox:checked + .switch-slider::before {
      transform: translateX(13px);
    }
  }
}

.customFieldsFocused-active {
  &:focus-visible {
    width: 100%;
    height: 100%;
    outline: 1px solid #000; 
     opacity: 1;
  }
}

.customFieldsFocused-active-white {
  &:focus-visible {
    width: 100%;
    height: 100%;
    outline: 1px solid #fff; 
     opacity: 1;
  }
}
