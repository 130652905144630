.resources{

    &__header{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid $theme-border-color;
        padding: 35px 26px 13px 35px;

        @include media-breakpoint(xl) {
            padding: 14px 18px;
        }

        &-search{
            display: flex;
            align-items: center;

            @include media-breakpoint(xl) {
                flex-direction: column;
                width: 100%;
            }
        }

        &-button{
            padding-bottom: 3px;
        }
    }

    &__tabs{
        align-self: flex-end;
        margin-left: 22px;
        padding-bottom: 4.5px;

        @include media-breakpoint(xl) {
            order: -1;
            align-self: center;
            margin-bottom: 14px;
        }

        @include media-breakpoint(md) {
            margin-left: 0;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        &-btn{
            border: none;
            background-color: transparent;
            border-radius: 30px;
            font-size: 18px;
            color: $color-white;
            margin: 0 2px;

            @include media-breakpoint(md) {
                font-size: 16px;
            }

            &:hover,
            &:focus {
            background-color: $theme-bg-color-dark-variant2;
            border-color: $theme-border-color;
            color: $color-white;
            }

            &.active {
            color: $color-white;
            background-color: $theme-bg-color-dark-variant2;
            border-color: $theme-border-color;
            }
        }

        &-contentRow {
            display: flex;
            font-size: 16px;
            color: $color-white;
            border-radius: $border-radius-md;
      
            &:nth-child(odd) {
              background-color: #003584;
            }
      
            @media (max-width: 768px) {
              width: 768px;
            } 
        }
        
    }

    &__files{
        .zip-icon{
            width: 36px;
            height: 44px;
        }
        &-list{
            margin: 0;
            padding: 0;
            &.padding-bottom{
                padding-bottom: 91px;

                @include media-breakpoint(md) {
                    overflow-x: auto;
                }
            }
        }

        &-item{
            display: flex;
        }

        &-name{
            display: flex;
            align-items: center;
            min-width: 276px;
            width: 276px;
            // background-color: $color-blue;
            padding: 8px 21px 8px 28px;

            // &.light{
            //     background-color: rgba($color-blue-300, .46);
            // }

            .icon{
                margin-right: 14px;
            }

            a{
                display: block;
            }
        }

        &-title{
            @include ellipsis;
            font-weight: bold;
            width: 100%;
            margin: 0;

            @include safari-suport {
                margin-top: -1px;
            }
        }

        &-animate{
            transition: left 1s linear, width 3s linear !important;
            position: relative;
            left: 0;
        }

        &-desc{
            margin-right: 20px;
            margin-bottom: 0;
            
            @include safari-suport {
                margin-top: -1px;
            }
        }

        &-description{
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: rgba($color-blue-300, .46);
            padding: 8px 10px 8px 21px;
            cursor: default;

            @include media-breakpoint(md) {
                min-width: 426px;
            }
            &.dark{
                background-color: $color-blue;
            }
        }

        &-buttons{
            display: flex;
            align-items: center;
        }

        &-edit{
            
            img{
                margin: 0 9px;
                cursor: pointer;
            }
        }
        &-drag{
            margin-left: 32px;
            cursor: grabbing;
        }

    }

    &__empty{
        @include flexcenter-both;
        min-height: 313px;

        &-content{
            display: flex;
            align-items: center;
        }

        &-icon{
            opacity: .26;
            margin-right: 24px;
        }

        &-paragraph{
            font-weight: bold;
            margin: 0;
        }
    }
}