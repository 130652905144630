.ProfessionalDev {
  &__devSummary {
    margin-top: 14px;
    border-radius: $border-radius-md;
    overflow: inherit;
    box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
    position: relative;

    &__header {
      background-color: #537ab7;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding-left: 20px;
      padding-right: 31px;
      padding-top: 6.5px;
      padding-bottom: 5.5px;
      height: 60px;

      @media (max-width: 991px) {
        padding-right: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        height: auto;
      }

      // @media (max-width:767px) {
      //     padding-top: 15px;
      //     padding-bottom: 15px;
      // }

      &__rightElements {
        cursor: pointer;
        padding: 12px 17px;
        border-radius: 40px;

        .tab-icon {
          height: auto;

          @media (max-width: 991px) {
            height: 22px;
          }

          @media (max-width: 860px) {
            height: 21px;
          }

          @media (max-width: 665px) {
            height: 18px;
          }

          svg {
            @media (max-width: 665px) {
              height: 18px;
            }
          }
        }

        .tabsText {
          margin-left: 16px;

          @media (max-width: 1200px) {
            margin-left: 12px;
          }

          @media (max-width: 991px) {
            margin-left: 8px;
          }
        }

        @media (max-width: 1199px) {
          padding: 8px 12px;
        }

        @media (max-width: 991px) {
          padding: 8px 14px;
        }

        @media (max-width: 860px) {
          padding: 5px 14px;
        }

        img {
          // width: 30px;
          // height: 25px;

          @media (max-width: 991px) {
            width: 22px;
            height: auto;
          }

          @media (max-width: 860px) {
            width: 18px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: white;

          @media (max-width: 1199px) {
            font-size: 14px;
          }

          @media (max-width: 991px) {
            font-size: 16px;
          }

          @media (max-width: 860px) {
            font-size: 13px;
          }
        }

        @media (max-width: 665px) {
          width: 100%;
          max-width: 165px;
          justify-content: center;
        }

        @media (max-width: 400px) {
          max-width: 100%;
          justify-content: flex-start;
        }
      }

      .HeaderPacingIcon img {
        position: relative;
        top: -2px;
      }
      &__rightElements.disabled {
        opacity: 0.56;
        pointer-events: none;
      }

      .active {
        background-color: #194792;
      }
      .headerTabs {
        margin-top: 1px;
        position: relative;

        @media (max-width: 872px) and (min-width: 861px) {
          max-width: 320px;
          justify-content: space-between !important;
          svg {
            width: 18px;
            height: 18px;
          }
        }

        @media (max-width: 796px) and (min-width: 768px) {
          max-width: 290px;
          justify-content: space-between !important;
          svg {
            width: 17px;
            height: 17px;
          }
        }

        @media (max-width: 665px) {
          width: 100%;
          max-width: 350px;
          margin: 0 auto;
        }

        @media (max-width: 400px) {
          max-width: 100%;
        }

        .classRoom-toolTip {
          position: absolute;
          bottom: -138px;
          left: 196px;
          right: 0;
          width: 100%;
          max-width: 349px;
          margin: 0 auto;
          z-index: 99999999;
          animation-name: first-Tooltip;
          animation-duration: 1s;

          @keyframes first-Tooltip {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          .toolTip-body {
            position: relative;
            display: flex;
            align-items: center;
            padding: 3px 29px 4px 23px;
            background-color: #ffed7f;
            border-radius: 44.5px;
            height: 89px;

            .toolTip-arrow {
              position: absolute;
              top: -40px;
              left: 61px;
              border-radius: $border-radius-md;
            }

            .aplus-img {
              width: 53.3px;
              height: 49.75px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            p {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              color: #3e3e3e;
              margin-bottom: 0;
              width: 100%;
              max-width: 243px;
              padding-left: 16px;
            }
          }
        }
      }
    }

    &__container {
      &__header {
        padding-left: 20px;
        padding-right: 32px;
        background-color: #2a5391;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        padding-top: 4px;
        padding-bottom: 4px;

        .weekHeader {
          width: 36%;
        }

        .goalHeader {
          width: 32%;
          padding-left: 32px;
        }

        .ToolsHeader {
          width: 30%;
          padding-left: 47px;

          @media (max-width: 1199px) {
            padding-left: 42px;
          }
        }
      }

      &__body {
        background-color: #537ab7;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        min-height: 250px;

        .classroom {
          width: 100%;
          max-width: 1240px;

          &__tabs-header {
            @media (max-width: 768px) {
              align-items: flex-start;
            }
          }

          .resources-section {
            box-shadow: none;
            margin-top: 0;
          }
        }
        .weekSec {
          width: 36.4%;
          height: 250px;
          overflow: hidden;

          .weekHeader {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            color: white;
            padding-left: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: #2a5391;
            margin-bottom: 0px;
            text-shadow: 0px 3px 6px #00000089;
            height: 30px;
            overflow: hidden;
          }

          @media (max-width: 991px) {
            width: 50%;
          }

          @media (max-width: 767px) {
            width: 100%;
          }

          .thisWeekBody {
            height: 220px;
            overflow: hidden;
            padding: 12px 14px 12px 15px;

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 21.2px;
              height: auto;
              margin-bottom: 12px;
            }

            ul {
              li {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                height: auto;
                margin-bottom: 12px;
              }
            }

            .week-no-desc {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            }

            .no-data-bg {
              background-color: $theme-bg-color-lighter;
            }
          }
          .week-skeleton {
            padding: 12px 14px 12px 15px;

            .no-data-bg {
              background-color: $theme-bg-color-lighter;
              position: relative;

              .skeleton:empty {
                background: linear-gradient(
                  0.25turn,
                  transparent,
                  #5d83c0,
                  transparent
                );
                background-repeat: no-repeat;
                background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
                animation: loading 1.5s infinite;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: $border-radius-md;
              }

              @keyframes loading {
                from {
                  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
                }
                to {
                  background-position: 315px 0, 0 0, 0 190px, 50px 195px;
                }
              }
            }
          }
        }

        .goalSec {
          width: 31.6%;
          border-left: 1px solid #2a5391;
          border-right: 1px solid #2a5391;
          height: 250px;

          @media (max-width: 991px) {
            width: 50%;
          }

          @media (max-width: 767px) {
            width: 100%;
          }

          .goalHeader {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            color: white;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: #2a5391;
            margin-bottom: 0px;
            text-shadow: 0px 3px 6px #00000089;
            height: 30px;
            overflow: hidden;

            span:last-child {
              font-size: 14px;
              font-weight: 400;
              text-shadow: none;
            }
          }

          .goalBody {
            height: 220px;
            overflow: hidden;
            padding: 6px 8px 4px 8px;

            p {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .goal-check-box {
              align-items: center;
              padding: 6px 6px 6px 3px;
              cursor: pointer;
              background-color: $theme-bg-color-lighter;
              margin-bottom: 7px;
              border-radius: $border-radius-md;
              height: 47px;

              .checkbox {
                width: 25px;
                height: 25px;
                margin-right: 8px;
                position: relative;
                top: 0px;
                filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.29));
              }

              label {
                font-size: 16px;
                font-weight: 400;
                color: white;
                padding-left: 10px;
              }

              span {
                font-size: 15px;
                font-weight: 400;
                line-height: 17.5px;
                color: white;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                width: calc(100% - 33px);
                position: relative;
                top: 0.5px;
                overflow: hidden;
              }
            }

            .goal-check-box:last-child {
              margin-bottom: 0;
            }

            .goal-check-box [type="checkbox"]:checked + span:before {
              position: absolute;
              top: 3px;
              right: 0;
              left: 0;

              @include mozilla-suport {
                top: 4px !important;
              }
            }

            .no-data-goals {
              height: 100%;
              display: flex;
              align-items: center;

              .no-goals-data {
                width: 100%;
                height: 47px;
                background-color: $theme-bg-color-lighter;
                border-radius: $border-radius-md;
                padding: 11px 6px 10px 13px;
                position: relative;
                top: -3px;

                .checked-img {
                  width: 25px;
                  height: 25px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 18px;
                  color: white;
                  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                  margin-left: 9px;
                  margin-bottom: 0;
                }
              }
            }
          }

          .loader-skeleton {
            display: block;
            padding: 6px 8px 0;

            .no-data-sekelton {
              width: 100%;
              height: 47px;
              background-color: $theme-bg-color-lighter;
              border-radius: $border-radius-md;
              margin-bottom: 7px;
              position: relative;

              span:empty {
                background: linear-gradient(
                  0.25turn,
                  transparent,
                  #5d83c0,
                  transparent
                );
                background-repeat: no-repeat;
                background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
                animation: loading 1.5s infinite;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: $border-radius-md;
              }
            }

            @keyframes loading {
              from {
                background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
              }
              to {
                background-position: 315px 0, 0 0, 0 190px, 50px 195px;
              }
            }
          }
        }

        .toolSec {
          width: 32.4%;
          height: 250px;
          overflow: hidden;

          @media (max-width: 991px) {
            width: 100%;
          }

          @media (max-width: 767px) {
            width: 100%;
          }

          .toolHeader {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            color: white;
            padding-left: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            background-color: #2a5391;
            margin-bottom: 0px;
            text-shadow: 0px 3px 6px #00000089;
            height: 30px;
            overflow: hidden;
          }

          .ToolsBody {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-left: 28px;
            padding-right: 45px;
            height: 220px;
            overflow: hidden;
            p {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            }

            @media (max-width: 991px) {
              padding-right: 28px;
            }
          }

          .tools-room {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 20px 11px 20px;
            height: 220px;
            justify-content: space-between;

            .tools-no-data {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              gap: 15px 0px;
              width: 100%;
              padding: 0px 27px;

              @media (max-width: 1130px) {
                padding: 0;
              }

              .tools {
                width: 100%;
                max-width: 121px;
                margin-right: 10px;

                @media (max-width: 375px) {
                  max-width: 115px;
                }

                .tool-img {
                  text-align: center;

                  img {
                    width: auto;
                    margin: 0 auto;
                  }
                }

                .tool-skeleton {
                  width: 100%;
                  height: 22px;
                  background-color: $theme-bg-color-lighter;
                  border-radius: $border-radius-md;
                  margin-top: 12px;
                  position: relative;

                  span:empty {
                    background: linear-gradient(
                      0.25turn,
                      transparent,
                      #537ab7,
                      transparent
                    );
                    background-repeat: no-repeat;
                    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
                    animation: loading 2.5s infinite;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: $border-radius-md;
                  }
                }
              }

              @keyframes loading {
                from {
                  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
                }
                to {
                  background-position: 315px 0, 0 0, 0 190px, 50px 195px;
                }
              }
            }
          }

          .tools-no-data {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 15px 0px;
            width: 100%;
            padding: 0px 27px;

            .tools {
              width: 100%;
              max-width: 121px;
              margin-right: 10px;

              .tool-img {
                height: 50px;
                text-align: center;

                img {
                  width: auto;
                  margin: 0 auto;
                }
              }

              .tool-skeleton {
                width: 100%;
                height: 22px;
                background-color: $theme-bg-color-lighter;
                border-radius: $border-radius-md;
                margin-top: 12px;
                position: relative;

                span:empty {
                  background: linear-gradient(
                    0.25turn,
                    transparent,
                    #5d83c0,
                    transparent
                  );
                  background-repeat: no-repeat;
                  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
                  animation: loading 1.5s infinite;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  border-radius: $border-radius-md;
                }
              }
            }

            @keyframes loading {
              from {
                background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
              }
              to {
                background-position: 315px 0, 0 0, 0 190px, 50px 195px;
              }
            }
          }

          .load-data {
            padding: 20px 47px;
          }
        }

        .toolsBodyBox {
          width: 46%;
          text-align: center;
          // padding: 0 9px;

          p {
            margin-bottom: 12px;
          }
        }

        .toolsBodyBox textarea {
          width: 100%;
          background-color: #e4e4e4;
          border-radius: $border-radius-md;
          resize: none;
          height: 65px;
          padding: 8px;
        }

        .option {
          width: 100%;
          max-width: 50%;
          padding: 0 5px;
          text-align: center;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: white;
            margin-top: 9px;
            margin-bottom: 0px;
            width: 150px;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .option-img {
            height: 50px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          img {
            width: auto;
            height: auto;
          }
        }
      }
    }

    &__toolTip {
      position: absolute;
      bottom: -150px;
      left: -5px;
      right: 0;
      width: 100%;
      max-width: 349px;
      margin: 0 auto;
      z-index: 99999999;
      animation-name: first-Tooltip;
      animation-duration: 1s;

      @keyframes first-Tooltip {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .toolTip-body {
        position: relative;
        display: flex;
        align-items: center;
        padding: 12px 14px 16px 24px;
        background-color: #ffed7f;
        border-radius: 44.5px;
        height: 89px;

        .toolTip-arrow {
          position: absolute;
          top: -39px;
          left: 67px;
          border-radius: $border-radius-md;
        }

        .aplus-img {
          width: 54.77px;
          height: 49.75px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #3e3e3e;
          margin-bottom: 0;
          width: calc(100% - 55px);
          padding-left: 16px;
        }
      }
    }

    &__toolTip.activity-tooltip-position {
      bottom: -73px;
      left: -126px;
      max-width: 372px;

      .toolTip-body {
        .aplus-img {
          width: 54.77px;
        }

        p {
          width: calc(100% - 54.77px);
          max-width: unset;
        }
      }
    }

    &__toolTip.classroom-tooltip-position {
      bottom: -73px;
      left: 160px;
      max-width: 394px;

      .toolTip-body {
        .aplus-img {
          width: 54.77px;
        }

        p {
          width: calc(100% - 54.77px);
          max-width: unset;
          margin-bottom: 2px;
        }
      }
    }

    &__toolTip.management-tooltip-position {
      bottom: -73px;
      left: 472px;
      max-width: 372px;
    }

    &__toolTip.open-master-report {
      bottom: unset;
      left: unset;
      right: 0px;
      top: 95px;

      @media (max-width: 1399px) {
        top: 90px;
      }

      .toolTip-arrow {
        left: 225px;

        @media (max-width: 991px) {
          left: 250px;
        }
      }
    }

    &__toolTip.students-toggle {
      bottom: unset;
      left: unset;
      right: 45px;
      top: 155px;

      @media (max-width: 1399px) {
        right: 27px;
      }

      .toolTip-arrow {
        left: 155px;
      }
    }

    &__toolTip.summative-a1-mark-completed {
      bottom: unset;
      left: unset;
      right: 0px;
      top: 205px;

      .toolTip-arrow {
        left: 260px;

        @media (max-width: 991px) {
          left: 270px;
        }
      }
    }

    &__toolTip.summativeb-toggle {
      bottom: unset;
      left: unset;
      right: 45px;
      top: 260px;

      @media (max-width: 1399px) {
        right: 25px;
      }

      .toolTip-arrow {
        left: 155px;
      }
    }

    &__toolTip.incomplete-formative {
      bottom: unset;
      left: unset;
      right: 0px;
      top: 210px;

      .toolTip-arrow {
        left: 262px;

        @media (max-width: 991px) {
          left: 268px;
        }
      }
    }

    &__toolTip.summative-a2-mark-completed {
      bottom: 80px;
      left: unset;
      right: 0px;
      top: unset;

      .toolTip-arrow {
        left: 262px;
        top: 105px;

        @media (max-width: 991px) {
          left: 270px;
        }
      }
    }

    &__toolTip.summative-a2-toggle {
      bottom: unset;
      left: unset;
      right: 45px;
      top: 155px;

      @media (max-width: 1399px) {
        right: 25px;
      }

      .toolTip-arrow {
        left: 155px;
        top: 105px;
      }
    }

    &__toolTip.summative-b-mark-completed {
      bottom: unset;
      left: unset;
      right: 0px;
      top: 110px;

      .toolTip-arrow {
        left: 260px;
        top: 105px;

        @media (max-width: 991px) {
          left: 270px;
        }
      }
    }

    &__toolTip.goals-tour-step {
      bottom: unset;
      left: unset;
      right: 845px;
      top: 156px;

      .toolTip-arrow {
        left: 365px;
        top: 20px;
      }
    }

    &__toolTip.pacing-calender-position {
      top: 100px;
      bottom: 151px;
      left: -126px;
      max-width: 372px;
    }

    &__toolTip.pacing-calender-position-assessment {
      top: -23px;
      left: 220px;
      right: unset;
      max-width: 320px;

      .toolTip-arrow {
        left: -25px;
        top: 32px;
      }
    }

    .permission {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 56px;
      background-color: rgb(83 122 183 / 60%);
      border-radius: $border-radius-md;
      z-index: 1;
      &.tab-permission {
        padding-top: 0;
        top: 38px;
      }
      &-text {
        background-color: rgba($color-black, 0.46);
        width: 524px;
        height: 101px;
        border-radius: $border-radius-md;
        padding: 19px 33px 21px 33px;
        padding-bottom: 21px;

        p {
          font-size: 16px;
          line-height: 22px;
          font-weight: bold;
          text-shadow: 0px 3px 6px rgba($color-black, 0.56);
          margin-bottom: 0;
        }
      }

      .classroom-permission {
        position: relative;
      }
      &:after {
        position: absolute;
        content: "";
      }
    }

    // .toolTip-bg {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: #000000;
    //     opacity: 57%;
    //     border-radius: $border-radius-md;
    //     animation-name: toolTipbgAnimate;
    //     animation-duration: 1s;

    //     @keyframes toolTipbgAnimate {
    //         from {opacity: 0%;}
    //         to {opacity: 57%;}
    //     }
    // }

    // .toolTip-bg-transparent {
    //     background-color: unset;
    // }
  }

  &__weekHeaderBlock {
    display: flex;
    align-items: center;
    height: 28px;
    margin-top: 14px;
    position: relative;

    &__weekHeaderDate {
      font-size: 14px;
      line-height: 18px;
      color: #424242;
      padding: 5px 10px;
      background-color: $color-gray-300;
      border-radius: 20px;
      margin-bottom: 0px;
    }

    &__weekHeaderAdvance {
      font-size: 14px;
      line-height: 18px;
      color: white;
      padding: 5px 10px;
      border-radius: 20px;
      margin-bottom: 0px;
    }

    &__weekHeaderPlayIcon {
      margin-top: 10px;

      @include media-breakpoint(sm) {
        margin-top: 7px;
      }
      
    }
    &__weekHeaderEmptyMargin {
      margin-top: 22px !important;
    }
  }

  .lessons-sec {
    margin-top: 22px;
    border-radius: $border-radius-md;
    background-color: #537ab7;
    -webkit-box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
    box-shadow: 0 3px 12px rgb(0 0 0 / 22%);
    overflow: hidden;
    position: relative;

    .table-header {
      padding-top: 13px;
      padding-bottom: 14px;
      padding-left: 22px;
      padding-right: 33px;
      flex-wrap: wrap;
      height: 60px;

      @media (max-width: 1045px) {
        padding-right: 18px;
      }

      @media (max-width: 991px) {
        height: auto;
      }

      @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media (max-width: 425px) {
        flex-direction: column;
      }

      .headerBtnContainer.sumative-dropdown {
        .css-b62m3t-container {
          .css-18xkw7e-control {
            height: 33px;
            border: 1px solid white;

            .css-1hb7zxy-IndicatorsContainer {
              .css-1gstp3d-indicatorContainer {
                padding-top: 5px !important;

                .css-tj5bde-Svg {
                  fill: white;
                }
              }
            }

            .css-1wy0on6 {
              .css-1gstp3d-indicatorContainer {
                padding-top: 5px !important;

                .css-8mmkcg {
                  fill: white;
                }
              }
            }

            .css-1fdsijx-ValueContainer {
              .css-h23rnz-singleValue {
                margin-bottom: 0;
                padding-bottom: 3px;
              }
            }

            .css-hlgwow {
              .css-h23rnz-singleValue {
                margin-bottom: 0;
                padding-bottom: 3px;
              }
            }
          }

          .css-7clvlv-control {
            height: 33px;
            box-shadow: unset;

            .css-1hb7zxy-IndicatorsContainer {
              .css-171jtbm-indicatorContainer {
                padding-top: 5px !important;

                .css-tj5bde-Svg {
                  fill: white;
                }
              }
            }

            .css-1wy0on6 {
              .css-171jtbm-indicatorContainer {
                padding-top: 5px !important;

                .css-8mmkcg {
                  fill: white;
                }
              }
            }

            .css-1fdsijx-ValueContainer {
              .css-h23rnz-singleValue {
                margin-bottom: 0;
                padding-bottom: 3px;
              }
            }
          }
        }
      }
    }

    .lesson-heading {
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
      color: white;
      margin-right: 20px;
      font-family: $font-openSans;
      margin-bottom: 0;

      @media (max-width: 1199px) {
        font-size: 18px;
        margin-bottom: 0px;
      }

      @media (max-width: 1032px) and (min-width: 992px) {
        margin-right: 15px;
      }

      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .paced-switch {
      width: 127px;

      @media (max-width: 425px) {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      .mark-comp {
        top: -1px;
        font-size: 16px;

        @media (max-width: 991px) {
          font-size: 14px;
          margin-right: 8px !important;
        }
      }
    }

    .paced-switch label {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: white;
      width: 32px;
    }

    .lesson-desc {
      width: 100%;
      max-width: 330px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #d5d5d5;
      margin-bottom: 0px;
      position: relative;
      left: -8px;
      top: 1px;

      @media (max-width: 1199px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media (max-width: 991px) {
        font-size: 12px;
        line-height: 16px;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .headerBtnContainer {
      @media (max-width: 425px) {
        width: 100%;
      }
    }

    .headerBtnContainerFormative {
      @media (max-width: 931px) {
        margin-top: 15px;
      }

      @media (max-width: 767px) {
        margin-top: 0px;
      }

      @media (max-width: 649px) {
        margin-top: 15px;
        width: 100%;
      }
    }

    .header-btns {
      border: 1px solid white;
      border-radius: $border-radius-md;
      padding: 6px 13px 5px 13px;
      height: 33px;
      font-size: 16px;
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1199px) {
        font-size: 14px;
      }

      @media (max-width: 1032px) and (min-width: 992px) {
        font-size: 12px;
      }

      @media (max-width: 991px) {
        font-size: 10px;
        padding: 6px 6px;
        border-radius: $border-radius-md;
        height: 27px;
      }
    }

    @media (min-width: 576px) {
      .objective-btn {
        margin-left: 17px;
      }
    }

    .schedule-tooltip {
      position: absolute;
      top: 178px;
      left: 109px;
      right: 0;
      width: 100%;
      max-width: 349px;
      margin-left: 0;
      z-index: 9;

      .toolTip-body {
        position: relative;
        display: flex;
        align-items: center;
        padding: 3px 29px 4px 23px;
        background-color: #ffed7f;
        border-radius: 44.5px;
        height: 89px;
        animation-name: first-Tooltip;
        animation-duration: 1s;

        @keyframes first-Tooltip {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .toolTip-arrow {
          position: absolute;
          top: 21px;
          left: -40px;
          border-radius: $border-radius-md;
        }

        .aplus-img {
          width: 53.3px;
          height: 49.75px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #3e3e3e;
          margin-bottom: 0;
          width: 100%;
          max-width: 234px;
          padding-left: 12px;
        }
      }
    }

    .student-toogle-tooltip {
      position: absolute;
      top: 151px;
      right: 296px;
      width: 100%;
      max-width: 349px;
      margin-right: 0;
      z-index: 9;

      @media (max-width: 1098px) {
        right: 110px;
      }

      .toolTip-body {
        position: relative;
        display: flex;
        align-items: center;
        padding: 3px 29px 4px 23px;
        background-color: #ffed7f;
        border-radius: 44.5px;
        height: 89px;
        animation-name: first-Tooltip;
        animation-duration: 1s;

        @keyframes first-Tooltip {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .toolTip-arrow {
          position: absolute;
          top: 22px;
          right: -40px;
          border-radius: $border-radius-md;
        }

        .aplus-img {
          width: 53.3px;
          height: 49.75px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: #3e3e3e;
          margin-bottom: 0;
          width: 100%;
          max-width: 243px;
          padding-left: 12px;
        }
      }
    }

    // .toolTip-bg {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: #000000;
    //     opacity: 57%;
    //     border-radius: $border-radius-md;
    //     animation: toolTipbgAnimate 5s;

    //     @keyframes toolTipbgAnimate {
    //         from {opacity: 0%;}
    //         to {opacity: 57%;}
    //     }
    // }

    // .toolTip-bg-transparent {
    //     background-color: unset;
    //     animation-name: tooltip-transparent-animate;
    //     animation-duration: 1s;

    //     @keyframes tooltip-transparent-animate {
    //         from {background-color: #000000;}
    //         to {background-color: unset;}
    //     }
    // }
  }

  .lesson-divider {
    border-top: 1px solid $theme-border-color;
  }

  .search-formative {
    padding-left: 20px;
    padding-right: 28px;
    padding-top: 10px;
    padding-bottom: 10px;

    .all-Summatives-dropdown {
      border-radius: $border-radius-md;
      width: 208px;
      padding: 0px 5px;
    }

    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .search-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    position: relative;
    top: -4px;
  }

  .assessment {
    width: 185px;

    @media (max-width: 767px) {
      width: 120px;
      margin-right: auto;
    }

    input {
      width: 100%;
      height: 26px;
      padding: 4px 12px 3px;
      background-color: white;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      color: #6e6e6e;
      border: 0px;
      border-radius: $border-radius-md;

      @media (max-width: 767px) {
        font-size: 11px;
        line-height: 14px;
        padding: 5px;
      }
    }
  }

  .show-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    margin-bottom: 0px;
    margin-right: 13px;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .all-formatives-dropdown {
    width: 208px;
    height: 26px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #01041b;
    border-radius: $border-radius-md;

    @media (max-width: 767px) {
      font-size: 12px;
      width: 130px;
      margin-left: auto;
    }
  }

  .pace-column {
    width: 53px;

    :last-child {
      border-bottom-left-radius: 8px;
    }

    .pace-header {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: white;
      height: 64px;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 3px;
      text-shadow: 0 3px 6px rgb(0 0 0 / 52%);
    }

    .pace-date {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px !important;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      transform: rotate(-90deg);
      @-moz-document url-prefix() {
        transform: rotate(-90deg) scale(0.99) !important;
      }
      flex-direction: column;
      position: relative;
    }

    .light-green {
      background-color: #3c809f;
    }

    .yellow {
      background-color: #eadb4a;

      .day,
      .date {
        color: #3e3e3e;
      }
    }

    .blue {
      background-color: #3260d4;
    }

    .pace-date {
      margin-bottom: 0;
      padding: 0;
      margin-top: 2px;
      min-width: 92px;
      padding-left: 2px;
      margin-left: -5px;
    }

    .pace-column-bg {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 28px;
    }
    .column-align {
      padding-top: 41px;
    }

    .column-box-shadow {
      box-shadow: -2px 0px 1px 0px RGBA(0, 0, 0, 0.29);
      border-radius: 0px 0px 8px 8px;
      padding: 3px 0;
      width: 92px;
      height: 48px;
      padding-top: 10px;

      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 17px;
      }
    }

    .pace-column-bg:nth-child(even) {
      background-color: $theme-bg-color-lighter;
    }
  }

  .report-detail-column {
    width: calc(100% - 53px);

    .report-detail-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-left: 20px;
      padding-right: 50px;
      padding-bottom: 8px;
    }

    .objective-desc {
      width: 285px;
    }

    .last-activity {
      width: 92px;
      padding: 0 10px;
    }

    .assessed {
      width: 120px;
      padding: 0 10px;
    }

    .proficiency {
      width: 110px;
      padding: 0 10px;
    }

    .guide {
      width: 70px;
      padding: 0 10px;
    }

    .add-ans {
      width: 110px;
      padding: 0 10px;
    }

    .report-type {
      width: 70px;
      padding: 0 10px;
    }

    .available-student {
      width: 110px;
      padding: 0 10px;
    }

    .monitor {
      width: 95px;
    }

    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: white;
      margin-bottom: 0px;
    }

    .report-detail-body {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-left: 20px;
      padding-right: 50px;
      padding-top: 5px;
      padding-bottom: 12px;

      .objective-desc {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: white;
      }

      .last-activity,
      .assessed {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: white;
        padding: 0 10px;
      }

      .assessed {
        .track {
          background-color: #537ab7;
          border-radius: $border-radius-md;
          height: 38px;
          position: relative;
          overflow: hidden;
        }

        .fill {
          background-color: #4069ab;
          border-radius: 8px 0px 0px 8px;
          height: 100%;
        }

        p {
          position: absolute;
          width: 100%;
          height: 100%;
          padding-top: 9px;
        }
      }

      .proficiency {
        padding: 0 10px;

        .track {
          background-color: #537ab7;
          border-radius: $border-radius-md;
          height: 38px;
          position: relative;
          overflow: hidden;
        }

        .fill {
          background-color: #3c809f;
          border-radius: 8px 0px 0px 8px;
          height: 100%;
        }

        p {
          position: absolute;
          width: 100%;
          height: 100%;
          padding-top: 9px;
        }
      }

      .report-type {
        padding: 0 10px;

        .report-name {
          width: auto;
          border: 1px solid white;
          border-radius: $border-radius-md;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: white;
          height: 33px;
        }
      }

      .monitor {
        .monitor-btn {
          background-color: #46afb8;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: white;
          border-radius: $border-radius-md;
          border: 0px;
          width: 100%;
          height: 33px;
        }
      }
    }

    .report-detail-body:nth-child(even) {
      background-color: $theme-bg-color-lighter;
    }
  }

  .mark-comp {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: white;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    top: -3px;
    text-shadow: none;
  }

  .mark-switch {
    width: 31px;
    height: 18px;
    left: -8px;
    margin-right: 35px !important;

    @media (max-width: 991px) {
      left: 0px;
    }

    .switch-slider::before {
      width: 16px;
      height: 16px;
      top: 1px;
      left: 1px;
    }

    .switch-checkbox:checked + .switch-slider::before {
      transform: translateX(12px);
    }
  }

  .activiy-data p {
    font-weight: 400;
    text-shadow: none;
    color: white;
  }

  .full-deta,
  .full-desc {
    // height: 64px;
    overflow: hidden;
    padding-left: 20px !important;
    padding-bottom: 8px !important;
  }

  .turn-on {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    text-shadow: none;
    position: relative;

    .arrowElement {
      position: absolute;
      top: -1px;
      left: -12px;
    }
  }

  .not-avail {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: white;
  }

  .summative {
    &__table-row {
      td {
        padding: 8px 5px;
        padding-bottom: 14px !important;
        &:last-child {
          min-width: 146px;
        }

        .table-dropdown.dropdown {
          .table-dropdown-toggle {
            padding: 4px 11px 3px 11px;
          }
        }
      }

      .markComp {
        padding-bottom: 8px !important;
      }
    }
  }

  .tableRowHeight {
    height: 64px;
    min-height: 64px;

    .full-decsTextContainer {
      .full-descText {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-shadow: unset;
        font-weight: 400;
      }
    }
  }

  .tableRowHeight.light-row .assessed .track {
    background-color: $theme-bg-color-lighter;
  }

  .tableRowHeight.light-row .proficiency .track {
    background-color: $theme-bg-color-lighter;
  }

  .full-desc-details {
    font-weight: normal;
    color: #d5d5d5;
    text-shadow: none;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  .pg-heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    font-family: $font-openSans;

    @media (max-width: 1199px) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (max-width: 860px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 800px) {
      width: 200px;
    }

    @media (max-width: 767px) {
      width: auto;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .objective-data {
    padding-left: 20px !important;
  }

  .assessed {
    .track {
      background-color: #537ab7;
      border-radius: $border-radius-md;
      height: 38px;
      width: 100px;
      position: relative;
      overflow: hidden;
    }

    .untrack {
      background-color: #496faa !important;
    }

    .fill {
      background-color: #4069ab;
      border-radius: 8px 0px 0px 8px;
      height: 100%;
    }

    p {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: white;
      text-shadow: none;
    }
  }

  .proficiency {
    .track {
      background-color: #537ab7;
      border-radius: $border-radius-md;
      height: 38px;
      width: 100px;
      position: relative;
      overflow: hidden;
    }

    .fill {
      border-radius: 8px 0px 0px 8px;
      height: 100%;
    }

    .succeed {
      background-color: #3c809f;
    }

    .failed {
      background-color: #69659c;
    }

    p {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: white;
      text-shadow: none;
    }
  }

  .goal-check-box {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: white;
  }

  .goal-check-box input {
    display: none;
    /* Hide the default checkbox */
  }

  /* Style the artificial checkbox */
  .goal-check-box .checkbox {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    background-color: #b9b9b9;
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }

  /* Style its checked state...with a ticked icon */
  .goal-check-box [type="checkbox"]:checked + span:before {
    // content: "\2714";
    content: url("../../img/check-white.png");
    position: unset;
    top: -3px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;

    @-moz-document url-prefix() {
      position: relative;
      top: 3px;
    }
  }

  .goal-check-box [type="checkbox"]:checked + .checkbox {
    background-color: #46afb8;
  }

  .table-responsive {
    border-bottom-right-radius: 8px;
    // overflow-y: hidden !important;
    // overflow: unset !important;

    // @media (max-width: 1280px) {
    //     overflow-x: auto !important;
    // }
  }
}

.pacing-calendar-wrapper {
  margin-top: 0 !important;
  .PacingCalender__container {
    border-radius: 0 0 8px 8px !important;
    .PacingCalender__container__header {
      box-shadow: none !important;
    }
  }
}

.IrrBlock {
  background-color: #537ab7;
  position: relative;
  left: 6px;
  padding: 6px 13px 5px 13px;
}

.discussions-wrapper {
  margin-top: 0 !important;
  .Discussions__container {
    border-radius: 0 0 8px 8px !important;
    .Discussions__container__header {
      box-shadow: none !important;
    }
  }
}

#summative-container {
  .pace-date {
    margin-top: 4px;
  }
}
