@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes borderRadius {
  from {
    border-radius: 8px 8px 0 0;
  }

  to {
    border-radius: $border-radius-md;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #cacfd8;
  }
  50%,
  100% {
    background-color: #2a303a;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 1;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 1;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
  }
}

@keyframes reports-footer {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.94;
  }
}

@keyframes zoom-out {
  from {
    opacity: 0;
    transform: scale(0.5) translateY(4rem);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes slideup {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes calulator-buttons {
  0% {
    background: $calculator-btn-bg;
    color: $calculator-btn-color;
    transform: scale(1);
  }

  50% {
    background-color: $calculator-btn-color;
    color: $calculator-btn-bg;
    transform: scale(0.95);
  }

  100% {
    background: $calculator-btn-bg;
    color: $calculator-btn-color;
    transform: scale(1);
  }
}

@keyframes calulator-equal-button {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.zoomAnimation {
  animation: leftInFade 1s ease;
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      animation-delay: 0.001s;
    }
  }
}
@keyframes leftInFade {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
  }
}

@keyframes ellipsis-animation {
  0% {
    left: 0;
    width: 100%;
  }

  50% {
    left: -100%;
    width: 200%;
  }

  100% {
    left: 0;
    width: 100%;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
    border: 3px solid $color-blue-200;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    border: 3px solid $color-blue-200;
  }
}


@keyframes moveRight {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(142px);
    -webkit-transform: translateX(142px);
  }
}

@keyframes moveRight2 {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(79px);
    -webkit-transform: translateX(79px);
  }
}


@keyframes moveRightMng {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(237px);
    -webkit-transform: translateX(237px);
  }
}

@keyframes moveRightMng-lg {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(229px);
    -webkit-transform: translateX(229px);
  }
}

@keyframes moveRightMng-md {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(228px);
    -webkit-transform: translateX(228px);
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(134px);
    -webkit-transform: translateX(134px);
  }

  to {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
}

@keyframes moveRight-md {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(154px);
    -webkit-transform: translateX(154px);
  }
}

@keyframes moveLeft-md {
  from {
    transform: translateX(154px);
    -webkit-transform: translateX(154px);
  }

  to {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
}

@keyframes moveRight-sm {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(110px);
    -webkit-transform: translateX(110px);
  }
}

@keyframes moveLeft-sm {
  from {
    transform: translateX(110px);
    -webkit-transform: translateX(110px);
  }

  to {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }
}


@keyframes moveRightMng-sm {
  from {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
  }

  to {
    transform: translateX(240px);
    -webkit-transform: translateX(240px);
  }
}
