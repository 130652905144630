// Data graph
.data-graph {
  position: relative;
  background-color: $color-blue-100;
  @include flexcenter-both;
  width: 100px;
  height: 38px;
  border-radius: $border-radius-md;
  margin: 0 auto;
  overflow: hidden;

  @include media-breakpoint(xl) {
    width: 90px;
    height: 36px;
  }

  &-bg{
    position: absolute;
    left: 0;
    height: 100%;
    background-color: $color-blue-400;
    border-radius: 8px 0 0px 8px;

    &.succeed{
      background-color: $color-green-300;
    }

    &.failed{
      background-color: $color-dark-red;
    }
  }

  &-dark {
    background-color: $theme-bg-color-lighter;
  }

  &-data {
    position: relative;
    z-index: 99;

    @include safari-suport {
      transform: translateY(-1.5px);
    }
  }

}

// Graphs
.graph {
  display: flex;
  align-items: flex-end;
  
  @include media-breakpoint(xl) {
    padding-bottom: 6px;
  }
  @media only screen and (max-width: 1023px) {
    height: 221px;
  }

  @include media-breakpoint(md) {
    flex-direction: column;
    height: 219px;
  }

  .stateChart{
    padding-bottom: 16px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    width: 100%;

    @include media-breakpoint(md) {
      width: 100%;
    }

    canvas{
      min-width: 746px !important;
      width: 87% !important;
      height: 238px !important;
      margin-right: auto;

      @include media-breakpoint(lg) {
        min-width: 87% !important;
      }

      @media (max-width: 767px) {
        min-width: 79% !important;
        width: 79% !important;
      }

      @media (max-width: 575px) {
        min-width: 77% !important;
        width: 77% !important;
      }

      @media (max-width: 400px) {
        min-width: 72% !important;
        width: 72% !important;
      }
    }
    #legend-container {
      position: absolute;
      bottom: 16px;
      right: -4px;
      width: 152px;

      @media (max-width: 767px) {
        bottom: 22px;
        right: 4px;
      }

      @media (max-width: 425px) {
        right: 8px;
        width: 128px;
      }
      
      ul {
        flex-direction: column !important;

        li {

          p {
            font-size: 12px;

            @media (max-width: 425px) {
              font-size: 9px;
            }
          }

          img {
            width: 40px;
            margin-right: 7px;
          }

          span {
            margin-right: 7px !important;
          }
        }
      }
    }
  }
  .canvasjs-chart-credit{
    display: none;
  }
  &__weeks {
    padding-bottom: 30px;
    width: 120px;

    @include media-breakpoint(md) {
      display: flex;
      width: 100%;
      padding: 0 15px 10px 15px;
    }

    &-check {
      position: relative;
      display: flex;
      align-items: center;
      height: 28px;
      padding-left: 38px;
      margin-top: 12px;
      cursor: pointer;
      font-size: 16px;
      color: $color-white;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @include media-breakpoint(xl) {
        padding-left: 34px;
      }

      @include media-breakpoint(md) {
        margin-top: 0;
        margin-right: 20px;
        padding-left: 30px;
      }

      .text {
        @include safari-suport {
          transform: translateY(-1px);
        }
      }
    }

    &-circle {
      position: absolute;
      top: 0;
      left: 0;
      @include flexcenter-both;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      border: 3px solid $color-green;
      box-shadow: 1px 1px 3px rgba($color-black, 0.56);

      @include media-breakpoint(md) {
        width: 24px;
        height: 24px;
      }
    }

    &-dot {
      @include flexcenter-both;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: $color-blue-100;
      box-shadow: inset 1px 1px 3px rgba($color-black, 0.55);

      @include media-breakpoint(md) {
        width: 18px;
        height: 18px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .graph__weeks-innerDot {
          background-color: $color-green;
          box-shadow: 1px 1px 3px rgba($color-black, 0.55);
        }
      }
    }

    &-innerDot {
      width: 16px;
      height: 16px;
      border-radius: 50%;

      @include media-breakpoint(md) {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.mask-graph-scale-one {
  height: 100px;
  width: 100%;
}
.mask-graph-scale-two {
  height: 170px;
  width: 100%;
}
.mask-graph-scale-three {
  height: 140px;
  width: 100%;
}
.mask-graph-scale-four {
  height: 110px;
  width: 100%;
}
.mask-graph-scale-five {
  height: 60px;
  width: 100%;
}
.mask-graph-scale-six {
  height: 140px;
  width: 100%;
}
.mask-graph-scale-seven {
  height: 50px;
  width: 100%;
}
.mask-cl-one {
  background-color: $color-gray-800;
}
.days-date {
  margin-top: 15px;
}
.days-date h4 {
  font-size: 14px;
  font-weight: 600;
}
.mask-cl-two {
  background-color: $color-gray-900;
}
.weeks-box {
  color: $color-white;
  width: 15%;
  margin: 0 1%;
}
.mask-graph span {
  width: 10px;
  height: 10px;
  display: block;
  background-color: $color-green;
  border-radius: 50px;
  position: relative;
}
.graph-line-two span:after,
.graph-line-two span:before {
  position: absolute;
  height: 2px;
  content: "";
  background-color: $color-gray-750;
  z-index: 999;
}
.graph-line-two span:after {
  transform: rotate(20deg);
  top: 22px;
  left: 6px;
  width: 100px;
}
.graph-line-two span:before {
  transform: rotate(-18deg);
  top: 22px;
  right: 8px;
  width: 100px;
}
.graph-line-three span:after,
.graph-line-three span:before {
  position: absolute;
  height: 2px;
  content: "";
  background-color: $color-gray-750;
  z-index: 999;
}
.graph-line-three span:after {
  transform: rotate(35deg);
  top: 39px;
  left: -1px;
  width: 115px;
}
.graph-line-three span:before {
  transform: rotate(-22deg);
  top: 25px;
  right: 7px;
  width: 102px;
}
.graph-line-four span:after,
.graph-line-four span:before {
  position: absolute;
  height: 2px;
  content: "";
  background-color: $color-gray-750;
  z-index: 999;
}
.graph-line-four span:after {
  transform: rotate(21deg);
  top: 24px;
  left: 6px;
  width: 100px;
}
.graph-line-four span:before {
  transform: rotate(-26deg);
  top: 29px;
  right: 3px;
  width: 106px;
}
.mask-graph.graph-line-node-one span {
  position: relative;
  top: 22px;
}
.mask-graph {
  position: relative;
  padding: 15px 0;
}
