.risk-report {
  @include section;
  margin-top: 22px;

  @media print {
    box-shadow: unset;
  }

  &-content {
    background-color: $color-gray-500;
    border-radius: $border-radius-md;
  }

  &__search {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 15px 25px;

    @include media-breakpoint(lg) {
      flex-direction: column;
      align-items: center;
    }

    &-wrapper {
      position: absolute;
      top: 15px;
      left: 25px;
      display: flex;
      align-items: center;

      @include media-breakpoint(lg) {
        order: 2;
        position: relative;
        left: auto;
        top: auto;
        margin-top: 40px;
      }

      input {
        font-size: 14px;
        width: 185px;
      }
    }
  }

  &__info {
    max-width: 430px;
    width: 100%;
    margin-top: 17px;

    &-text {
      color: $color-gray-850;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  &-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 0 100px 0;

    @include media-breakpoint(md) {
      overflow-x: auto;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    color: $color-black;

    @include media-breakpoint(lg) {
      max-width: 100%;
    }

    @include media-breakpoint(md) {
      max-width: 768px;
    }

    &-row {
      display: flex;
      width: 100%;
      border-radius: $border-radius-md;

      @include media-breakpoint(lg) {
        border-radius: 0;
      }

      @include media-breakpoint(md) {
        width: 768px;
      }

      &:nth-child(even) {
        background-color: $color-gray;
      }
    }

    th {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      padding-top: 2px;
      padding-bottom: 3px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }
    }

    td {
      font-size: 16px;
      line-height: 20px;
      padding-top: 10px;
      padding-bottom: 8px;

      @include safari-suport {
        padding-top: 8px;
        padding-bottom: 10px;
      }

      @include media-breakpoint(md) {
        font-size: 14px;
      }
    }

    &-objective {
      max-width: 100px;
      min-width: 100px;
      text-align: right;
      border-radius: 8px 0 0 8px;

      @include media-breakpoint(lg) {
        border-radius: 0;
      }
    }

    &-description {
      flex: 1;
      padding-left: 20px;
      padding-right: 16px;

      &.margin {
        margin: 0 2px;
      }

      @include media-breakpoint(md) {
        flex: 0 0;
        min-width: 300px;
        max-width: 300px;
      }
    }

    &-students {
      min-width: 118px;
      max-width: 118px;
      text-align: center;
    }

    &-proficient {
      min-width: 130px;
      max-width: 130px;
      text-align: center;
      padding: 0 15px;

      &.proficient-data {
        display: flex;
        justify-content: center;
        padding-top: 6px;
        padding-bottom: 6px;
      }

      &.margin {
        margin: 0 2px;
      }
    }

    &-view {
      max-width: 96px;
      min-width: 96px;
      text-align: center;
      padding-right: 26px;
      border-radius: 0 8px 8px 0;

      @include media-breakpoint(lg) {
        border-radius: 0;
      }
    }
  }

  &__proficient {
    position: relative;
    background-color: $color-red-200;
    width: 98px;
    height: 29px;
    border-radius: $border-radius-md;
    overflow: hidden;

    &-text {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      text-align: center;
      font-size: 16px;
      color: $color-white;
      line-height: 21px;
      padding: 4px 0;
      z-index: 4;
    }
    &-blue {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $color-blue-100;
      z-index: 2;
    }
  }

  &__print-table {
    thead th {
      vertical-align: bottom;
      padding-bottom: 5px;

      &.print-id,
      &.print-firstName,
      &.print-lastName {
        @media print {
          @include mozilla-suport {
            font-size: 8px !important;
          }
        }
      }
    }
  }
}

// Print
.report-risk-print {
  width: 100%;
  max-width: 978px;
  page-break-after: always;

  &__objective-col {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    @media print {
      @-moz-document url-prefix() {
        p {
          font-size: 10px !important;
          line-height: 1.2 !important;
        }
      }
    }
  }

  @media print {
    @-moz-document url-prefix() {
      &__objective-col {
        width: 70px !important;
        min-width: 70px !important;
        max-width: 70px !important;
      }
    }
  }

  &__proficient-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    text-align: center;

    @media print {
      @include mozilla-suport {
        width: 60px;
        min-width: 60px;
        max-width: 60px;
      }

      @-moz-document url-prefix() {
        p {
          font-size: 12px;
        }
      }
    }
  }

  &__description-col {
    width: calc(100% - 260px);
    justify-content: space-between;
    align-items: flex-end;
    @media print {
      @include mozilla-suport {
        width: calc(100% - 130px);
      }

      @-moz-document url-prefix() {
        p {
          font-size: 10px !important;
        }
      }
    }
  }

  &__header-description {
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 260px);
    @media print {
      @include mozilla-suport {
        width: calc(100% - 130px);
      }
      @-moz-document url-prefix() {
        p {
          font-size: 10px !important;
        }
      }
    }
  }

  &__non-proficient-col {
    width: 120px;
    min-width: 120px;
    text-align: center;

    &.col-print-desc {
      @media print {
        @include mozilla-suport {
          width: 80px;
          min-width: 80px;
          font-size: 8px !important;
          transform: translateY(-4px);
        }
      }
    }
  }

  @media print {
    @-moz-document url-prefix() {
      &__non-proficient-col {
        font-size: 12px !important;
      }
    }
  }

  &__studentLast {
    padding-right: 16px;
    text-align: right;

    @media print {
      @include mozilla-suport {
        font-size: 12px !important;
      }
    }
  }

  &__student-table {
    display: flex;
    justify-content: space-between;
    page-break-inside: avoid;
  }

  .inner-table {
    width: 100%;
    max-width: 741px;
    border: 1px solid $color-gray-100;
    border-radius: $border-radius-md;
    padding-right: 50px !important;
    page-break-inside: avoid;
    // page-break-after: always;
  }

  .width88 {
    width: 88px;
    font-size: 14px;
    text-align: center;

    @media print {
      @include mozilla-suport {
        width: 66px;
      }
    }
  }

  @media print {
    @-moz-document url-prefix() {
      .inner-table {
        max-width: 100%;
        padding-right: 30px !important;
        padding-left: 20px !important;
        table {
          tr {
            td {
              font-size: 8px !important;
              white-space: nowrap !important;
              margin-right: 10px !important;
            }
            .width88 {
              font-size: 8px !important;
            }
          }
        }
      }
    }
  }

  .modal__footer {
    &__risk-report {
      padding-top: 22px !important;
    }
  }
}

.header-plain-text {
  @media print {
    font-weight: bold;
    color: $color-black;
    line-height: 20px;
  }
}

@media print {
  @-moz-document url-prefix() {
    .header-plain-text {
      font-size: 10px !important;
      line-height: 1.2 !important;
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .report-risk-print {
      .sub-heading {
        transform: translateX(-22px);
        font-size: 8px !important;
        line-height: 1.2 !important;
      }
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .report-risk-print {
      .numeric-values {
        font-size: 8px !important;
        line-height: 1.2 !important;
      }
    }
  }
}
