.accordion {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: $color-blue-100;
    border-bottom: 1px solid $theme-border-color;
    position: relative;

    @include section;
    border-radius: 8px 8px 0 0;
    margin-top: 34px;
    margin-bottom: 0px;
    padding: 0px 34px 9px 12px;
    cursor: pointer;
    
    &.select-school-text{
      .accordion__header-text {
       position: unset;
      //  padding-left: 15px;
       @media(max-width:1118px){
        display: none !important;
       }
      }
      .accordion__header-buttons{
        flex-wrap: wrap;
        gap: 12px 0px;
      }
     }

    &-noBorderBottom {
      border-bottom: 0px;
    }

    @include media-breakpoint(xl) {
      padding: 0px 18px 7px 12px;
      margin-top: 7px;
    }

    @include media-breakpoint(md) {
      padding-right: 12px;
      padding-left: 10px;
    }

    @media (max-width: 639px) {
      display: block;
    }

    &.taller {
      padding: 10px 36px 10px 16px;

      @include media-breakpoint(xl) {
        padding: 10px 18px 10px 16px;
      }
    }

    &.district-csv-upload {
      @include custom-break-poitn(460px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .district-csv-btns {
        @include custom-break-poitn(460px) {
          margin-left: auto;
        }
      }
    }

    &.student-scv {
      position: relative;
      overflow: visible;

      @media only screen and (max-width: 900px) {
        flex-flow: row wrap;
      }
    }

    &.school-admin-header {
      @include custom-break-poitn(404px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .accordion__header-buttons {
        @include custom-break-poitn(404px) {
          margin-left: auto;
        }
      }
    }

    &.schools-header-overflow {
      overflow: visible !important;
    }

    &.schools-header {
      @include custom-break-poitn(455px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .schools-btns {
        @include custom-break-poitn(455px) {
          margin-left: auto;
        }
      }
    }

    &.school-detail-header {
      @include custom-break-poitn(620px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .school-detail-btns {
        @include custom-break-poitn(620px) {
          margin-left: auto;
        }
      }
    }

    &.students-header {
      @include custom-break-poitn(438px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .students-header-btns {
        @include custom-break-poitn(438px) {
          margin-left: auto;
        }
      }
    }

    &.class-detail-header {
      border-bottom: 1px solid $theme-border-color;
      @include media-breakpoint(sm) {
        flex-direction: column;
        align-items: flex-start;
      }

      .class-detail-btns {
        @include media-breakpoint(sm) {
          margin-left: auto;
        }
      }
    }

    &.closeDelay {
      // border-radius: $border-radius-md;
    }

    &.open {
      border-radius: 8px 8px 0 0 !important;
      margin-bottom: 0
      // box-shadow: 0 -1px 12px rgba($color-black, 0.22);
    }

    &-button {
      @include flexcenter-both;
      min-width: 40px;
      max-width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      transition: all 0.3s;
      cursor: pointer;
      margin-right: 10px;

      @include media-breakpoint(md) {
        min-width: 36px;
        max-width: 36px;
        height: 36px;
      }

      &.table-btn {
        min-width: 28px;
        max-width: 28px;
        height: 28px;

        img {
          max-width: 100%;
          transform: rotate(-90deg);
        }

        .rotate {
          transform: rotate(90deg);
        }
      }

      img {
        max-width: 40px;
        margin-right: 1px;
        transition: all 0.3s;
        transform: rotate(-180deg);

        @include media-breakpoint(md) {
          max-width: 40px;
        }

        &.rotate,
        &.rotating {
          transform: rotate(0deg);
        }

        &.rotate-90 {
          transform: rotate(-90deg);
        }
      }

      &:hover {
        background-color: $color-blue-600;
      }
    }

    &-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.max-width {
        max-width: 400px;
      }

      &.open {
        padding: 7px 34px 7px 12px;
      }

      h6 {
        text-align: left;
        font-size: 22px;
        font-family: $font-openSans;
        font-weight: 700;
        line-height: 29.96px;
        text-transform: capitalize;
        margin-bottom: 0;
        width: 212px;

        // @include media-breakpoint(md) {
        //   font-size: 20px;
        //   margin-left: 4px;
        // }
        @include media-breakpoint(sm) {
            font-size: 20px;
            margin-left: 4px;
          }
      }

      .district-name-heading {
        width: 380px;
      }
    }

    &-search {
      display: flex;
      align-items: center;
      margin-left: 12px;

      @include media-breakpoint(md) {
        display: none;
      }

      img {
        margin-right: 10px;
      }

      &.animated {
        animation: fadeIn 800ms ease-in-out;
      }

      .form-control {
        padding: 4px 12px 3px 12px;
        width: 185px;
        height: 26px;
      }
    }

    &-text p {
      color: white;
      margin-bottom: 0;

      @include media-breakpoint(xxl) {
        font-size: 15px;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.district-admin {
        @include media-breakpoint(xl) {
          flex-flow: row wrap;
          justify-content: flex-end;
          max-width: 410px;
          width: 100%;
          gap: 10px 0;
        }
        @include media-breakpoint(md) {
          max-width: 100%;
        }
      }

      &.district {
        @media only screen and (max-width: 1020px) {
          flex-flow: row wrap;
          justify-content: flex-end;
          max-width: 300px;
          width: 100%;
        }

        @media (max-width: 767px) {
          max-width: 455px;
        }
      }

      &.csv-buttons {
        @media only screen and (max-width: 900px) {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding-right: 8px;
          padding-top: 6px;
        }
      }
    }

    &-btnContainer {
      margin-left: 20px;

      button {
        height: 33px;
        line-height: 18px;

        @media (max-width: 767px) {
          height: 29px;
        }
        
        @include media-breakpoint(lg) {
          white-space: nowrap;
        }
      }

      @include media-breakpoint(lg) {
        margin-left: 16px;
      }

      @include media-breakpoint(md) {
        margin-left: 10px;
      }

      // &.district {
      //   @media only screen and (max-width: 1020px) {
      //     margin-bottom: 8px;
      //   }
      // }

      &.import-csv-btn {
        @include custom-break-poitn(402px) {
          margin-top: 8px;
        }
      }
    }

    .students-header-btns {
      .accordion__header-buttons {
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 10px 15px;
        padding-left: 10px;

        .btn {
          &:focus-visible {
            outline: 2px solid black !important;
          }
        }
        .accordion__header-btnContainer {
          margin-left: 0; 
        }
      }
    }

    &-text {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      pointer-events: none;
      text-align: center;

      p {
        display: inline-block;
        text-align: left;
      }
    }

    .text-with-searchbar {
      position: unset;
      margin: unset;
      max-width: unset;
      width: auto;
    }

    .student-instructional-text{
      @media (max-width:1118px){
        display: none !important;
      }
    }

    &.management-internal-table-header {
      border-color: $theme-border-color;
    }

    &.school-detail-header {
      border-color: $theme-border-color;
    }
  }

  &__border-top-bottom {
    border-bottom: 1px solid $theme-border-color;
    border-top: 1px solid $theme-border-color;
  }

  &__subheader {
    // background-color: $color-blue-600;
    padding: 4px 22px;

    @include safari-suport {
      padding-top: 3px;
      padding-bottom: 5px;
    }

    p {
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  &__content {
    background-color: $color-gray-500;
    border-radius: $border-radius-md;
    // border-top: 1px solid $theme-border-color;
    // box-shadow: 0 6px 10px rgba($color-black, 0.2);
    border: 1px solid $theme-border-color;
    overflow: hidden;

    &.print {
      background-color: transparent;
      box-shadow: unset;
      border: none;
      overflow: visible;
    }

    .section-search {
    }

    .district-admin-table-container,
    .district-table-container,
    .school-table-container,
    .class-table-container,
    .students-table-container,
    .students-forclass-table-container,
    .admins-table-container {
      table {
        thead {
          tr {
            th {
              font-size: $md;
              font-weight: 700;
              line-height: 21.82px;
              color: $color-gray-950;
              text-shadow: unset;
            }
          }
        }

        tbody {
          tr {
            td {
              color: $color-gray-950;
              position: relative;
              padding: 7px 13px 7px 16px;
              background-color: unset;

              &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                max-width: 1141px;
                height: 1px;
                background-color: $color-gray;
              }

              .students__clever_text_button {
                color: $color-gray-950 !important;
              }
            }

            td:first-child {
              &::before {
                width: calc(100% - 19px);
                right: 0;
                left: unset;
              }
            }

            td:last-child {
              &::before {
                width: calc(100% - 19px);
              }
            }

            .book-access-data {
              padding-left: 0;
              padding-right: 0;
            }

            .skeleton-td {
              padding-right: 0 !important;

              span {

                .react-loading-skeleton {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &__student {
    background-color: $color-gray-500;
    border-radius: 0 0 8px 8px;
    box-shadow: $section-shadow;
    margin-top: 1px;
    padding: 9px 22px;

    &_box-radius {
      border-radius: $border-radius-md;
    }

    &-item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      p {
        color: $color-black;
        margin: 0;
        line-height: 20px;
      }
    }

    &-status {
      width: 99px;
      display: flex;
      justify-content: flex-end;
      padding-right: 12px;
    }
  }
}

.school-detail-header,
.class-detail-header {
  .accordion__header-heading {
    h6 {
      width: auto;
      max-width: 425px;
    }

    .management__table-editBtn {
      margin-left: 10px;
    }
  }
}

.school-detail-header{
  @media (max-width: 767px){
    display: flex !important;
    flex-wrap: wrap;
    gap: 4px;
    .school-detail-btns{
      margin-left: auto;
    }
  }
  @media (max-width: 879px){
    .accordion__header-heading{
      h6{
        font-size: 20px;
      }
    }
  }
}

.management-internal-table-dark {
  background-color: unset;
  box-shadow: unset;
  border: none;

  .checo-table-container {
    table {
      tbody {
        tr {
          td:first-child {
            border-radius: 8px 0px 0px 8px;
            display: table-cell;
          }

          td:last-child {
            border-radius: 0px 8px 8px 0px;
            display: table-cell;
          }
        }
      }
    }
  }
}
.btn-focus-visible {
  &:focus-visible {
    outline: 2px solid black !important;
  }
}
.super-admin-district {
  @media (max-width: 639px) {
    display: block;
  }

  .accordion__header-buttons.district {
    max-width: 100%;
    flex-wrap: wrap;
    gap: 8px 0;
    justify-content: flex-end;
    
    button {
      &:focus-visible {
        outline: 2px solid black;
      }
    }
    @media (max-width: 639px) {
      .accordion__header-btnContainer {
        margin-top: 8px;
      }
    }
  }
}

.manage-objective-container {
  .accordion__header {
    background-color: unset;
    box-shadow: unset;
    padding-top: 4px;
    padding-right: 0;
    border-color: $theme-border-color;

    @include media-breakpoint(lg) {
      margin-top: 0;
    }

    @media (max-width: 450px) {
      flex-wrap: wrap;
    }

    .accordion__header-buttons {

      @media (max-width: 450px) {
        margin-top: 10px;
      }

      button {
        padding-left: 22px;
        padding-right: 22px;
      }
    }
  }

  .accordion__content {
    background-color: unset;
    box-shadow: unset;
    margin-top: 13px;
    overflow: unset !important;
    border: none;
  }
}
