.WayToReport {
  margin-top: 15px;

  @media print {
    box-shadow: none;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 12px 25px 11px 11px;

    @media print {
      background-color: unset;
    }

    @media (max-width: 992px) {
      padding: 15px 15px 11px 15px;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      height: auto;
      padding: 15px 10px 11px 10px;
    }
    .heading {
      display: flex;
      align-items: center;

      @media (max-width: 576px) {
        width: 100%;
      }

      .back-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        transition: all 0.3s;
        cursor: pointer;
        margin-right: 10px;
      }

      .back-arrow:hover {
        background-color: #2a5391;
      }

      h3 {
        color: #fff;
        font-weight: 500;
        line-height: 25.78px;
        font-family: "Roboto";

        @media (max-width: 576px) {
          font-size: 16px;
          margin-left: 12px;
        }

        @media print {
          color: "#000";
        }
      }

      img {
        height: 21px;
        position: relative;
        top: 0px;

        @media (max-width: 576px) {
          width: 12px;
        }

        @media print {
          display: none;
        }
      }
    }
    .printButton {
      border: 1px solid #fff;
      color: #fff;
      border-radius: $border-radius-md;
      background: transparent;
      height: 33px;
      width: 74px;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.82px;
      padding: 6px 19px 5px;
      position: relative;
      top: 1px;
      @media (max-width: 576px) {
        width: 100%;
        margin-top: 10px;
      }

      @media print {
        display: none;
      }
    }
  }
  &__body {
    background-color: #b9b9b9;
    border-radius: $border-radius-md;
    padding: 35px;

    @media print {
      background-color: unset;
    }

    @media (max-width: 992px) {
      padding: 20px 15px;
    }

    .textInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      p {
        width: 100%;
        max-width: 342px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #6e6e6e;
        margin-top: 2px;
        margin-bottom: 0px;
        font-family: "Nunito", sans-serif !important;
        font-style: normal;
        position: relative;
        top: -1px;
        word-spacing: -0.5\px;
        letter-spacing: 0px;

        @media (max-width: 576px) {
          max-width: 100%;
        }
      }
    }
    &__anylasisData {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-left: 3px;

      @-moz-document url-prefix() {
        @media print {
          margin: 0 -20px;
        }
      }

      &__anylasis {
        border: 1px solid #707070;
        width: 49.3%;
        height: auto;
        border-radius: $border-radius-md;
        padding: 12px 20px 15px 24px;
        margin-bottom: 17px;
        position: relative;

        @media (max-width: 1160px) {
          padding: 13px 15px 15px 15px;
          height: auto;
        }
        @media (max-width: 1040px) {
          height: auto;
        }
        @media (max-width: 768px) {
          width: 100%;

          @media print {
            width: 49.3%;
          }
        }

        .anylasisHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          &__name {
            font-size: 16px;
            line-height: 22px;
            color: #424242;
            font-weight: 700;
            margin-bottom: 0px;

            @-moz-document url-prefix() {
              @media print {
                font-size: 12px;
              }
            }
          }
          &__tag {
            font-size: 14px;
            padding: 2px 12.3px;
            border-radius: 24px;
            margin-bottom: 0;
            line-height: 19px;

            @-moz-document url-prefix() {
              @media print {
                font-size: 12px;
              }
            }
          }

          .onTrack {
            background-color: #3b792f;
            color: #fff;
          }

          .attention {
            background-color: #eadb4a;
            color: #424242;
          }
        }
        .anylasisBody {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          @media (max-width: 576px) {
            flex-direction: column;

            @media print {
              flex-direction: row;
            }
          }

          &__goals {
            margin-left: -2px;
            height: 163px;
            width: 170px;
            position: relative;
            top: -12px;
            // padding: 10px;

            @media (max-width: 1160px) {
              margin-left: 4px;
            }

            @media (max-width: 576px) {
              margin-left: auto;
              margin-right: auto;

              @media print {
                margin-right: 0;
                margin-left: 0;
              }
            }

            @-moz-document url-prefix() {
              @media print {
                width: 90px !important;
                height: 93px !important;
                margin-left: -8px;
              }
            }

            canvas {
              @-moz-document url-prefix() {
                @media print {
                  width: 110px !important;
                  height: 103px !important;
                }
              }
            }
          }
          &__Info {
            width: 100%;
            max-width: 345px;

            @media (max-width: 1060px) {
              width: 245px;
            }
            @media (max-width: 768px) {
              width: 70%;
            }
            @media (max-width: 576px) {
              width: 100%;
              max-width: 100%;
              margin-top: 10px;
              max-width: 100%;
            }

            @-moz-document url-prefix() {
              @media print {
                max-width: 180px !important;
              }
            }

            p {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 0px;
              color: #424242;

              @-moz-document url-prefix() {
                @media print {
                  font-size: 10px;
                  line-height: 12px;
                }
              }
            }
          }
        }
        .anylasisFooter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 61.77%;
          margin-left: auto;
          position: absolute;
          bottom: 15.5px;
          right: 15.5px;

          @-moz-document url-prefix() {
            @media print {
              margin-top: -20px !important;
            }
          }

          @media (max-width: 1160px) {
            position: unset;
            margin-top: 0;
            flex-direction: column;
            justify-content: center;
          }
          @media (max-width: 1020px) {
            width: 100%;
            margin-top: 15px;
          }
          @media (max-width: 768px) {
            width: 350px;
            align-items: flex-start;
            margin-top: -40px;

            @media print {
              width: 351px;
              margin-left: auto;
              right: 0px;
            }
          }
          @media (max-width: 576px) {
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin-top: 16px;

            @media print {
              width: 350px;
              align-items: flex-start;
              margin-top: -40px;
            }
          }

          @-moz-document url-prefix() {
            @media print {
              max-width: 187px !important;
            }
          }

          &__markers {
            display: flex;
            align-items: center;
            padding-left: 6px;
            @media (max-width: 576px) {
              margin-bottom: 10px;
              width: auto;
            }
          }
          .circle-icons {
            border-radius: 40px;
          }
          .identificationBox {
            height: 16px;
            width: 16px;
            background-color: #3b792f;
            filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.224));
            margin-right: 7px;

            @-moz-document url-prefix() {
              @media print {
                width: 10px;
                height: 10px;
                margin-right: 4px;
              }
            }
          }

          p {
            position: relative;
            top: 1px;
            margin-bottom: 0px;
            color: #424242;
            font-size: 14px;

            @-moz-document url-prefix() {
              @media print {
                font-size: 9px;
                top: 0px;
              }
            }
          }
          &__viewBtn {
            font-size: 16px;
            font-weight: 400;
            height: 26px;
            background-color: #46afb8;
            border-radius: $border-radius-md;
            border: unset;
            color: #fff;
            padding: 0px 24px;
            margin-left: 10px;

            @media (max-width: 1160px) {
              margin-top: 10px;
              margin-left: 6px;
            }
            @media (max-width: 1020px) {
              margin-top: 10px;
            }
            @media (max-width: 576px) {
              width: 100%;
            }
            @media print {
              display: none;
            }
          }

          .partialComp {
            flex-wrap: wrap;
            width: 245px;
          }
        }

        .anylasisFooterEnd {
          align-items: flex-end;
        }
      }
    }
  }
  page-break-inside: avoid;
}

.wayReport-modal {
  .modal-content {
    // width: auto !important;
    // max-width: 847px;
    // padding: 12px 23px 23px 23px;
    // transition: width 2s ease, max-width 2s ease;
    border-radius: 10.5px;

    .modal-header {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
      border-bottom: 0;
      padding-bottom: 16px;

      .backArrow {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -7px;
        cursor: pointer;
        position: relative;
        left: -12px;

        img {
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
        }
      }

      .backArrow:hover {
        background-color: #2a5391;
      }

      img {
        width: 22.5px;
        height: 20px;
        margin-top: 0px;
      }

      .modal__header-title {
        line-height: 30px;
        top: 0px;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      .header-desc {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: white;
        font-family: $font-nunito;
        margin-top: 1px;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }

    .see-all-goals {
      display: flex;
      gap: 0 10px;
      justify-content: space-around;

      @media (max-width: 767px) {
        flex-direction: column;
        gap: 20px 0px;
      }

      .see-goals-sec {
        width: 100%;
        max-width: 395px;
        border-radius: $border-radius-md;
        position: relative;

        @media (max-width: 767px) {
          max-width: 100%;
        }

        .goalsHeading {
          background-color: #2a5391;
          padding: 7px 22px 4px 22px;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          color: white;
          margin-bottom: 0px;
          border-radius: 8px 8px 0px 0px;
        }

        .see-goals-body::-webkit-scrollbar {
          display: none;
        }

        .see-goals-body {
          height: auto;
          max-height: 490px;
          overflow-y: auto;
          border-radius: 0px 0px 8px 8px;
          background-color: #537ab7;

          .available-date {
            .available-header {
              padding: 5px 23px 4px 23px;
              background-color: #194792;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: white;
              margin-bottom: 0;
            }

            .available-body {
              padding: 3px 0px 10px 0px;
              background-color: #537ab7;

              .availibility {
                margin-top: 7px;
                border-radius: $border-radius-md;
                padding: 0 7px;

                p {
                  background-color: #b9b9b9;
                  padding: 0 13px;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19px;
                  color: #424242;
                  margin-bottom: 0px;
                  border-radius: 8px 8px 0px 0px;
                }

                .status {
                  margin: 0;
                  display: flex;
                  align-items: center;
                  background-color: $theme-bg-color-lighter;
                  padding: 6px 12px;
                  height: 47px;
                  border-radius: 0px 0px 8px 8px;

                  .loader {
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 17.5px;
                    color: white;
                    margin-bottom: 0px;
                    background-color: unset;
                    padding: 0;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    width: 100%;
                    max-width: calc(100% - 35px);
                    position: relative;
                    top: 0.5px;

                    @media (max-width: 576px) {
                      font-size: 13px;
                      line-height: 15px;
                    }
                  }

                  .goals-modal-checkbox {
                    input {
                      display: none;
                    }

                    .checkbox {
                      width: 25px;
                      height: 25px;
                      margin-right: 9px;
                      position: relative;
                      border-radius: 50%;
                      background-color: #b9b9b9;
                      display: inline-block;
                      filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.29));
                    }

                    label {
                      font-size: 16px;
                      font-weight: 400;
                      color: white;
                      padding-left: 10px;
                    }

                    span {
                      font-size: 15px;
                      font-weight: 400;
                      line-height: 18px;
                      color: white;
                      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                  }

                  .goals-modal-checkbox [type="checkbox"]:checked + .checkbox {
                    background-color: #46afb8;
                  }

                  .goals-modal-checkbox
                    [type="checkbox"]:checked
                    + span:before {
                    // content: "\2714";
                    content: url("../../img/check-white.png");
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @-moz-document url-prefix() {
                      position: relative;
                      top: 6px;
                      left: 0;
                    }
                  }
                }
              }

              .disabled {
                opacity: 0.49;
              }
            }

            .available-body:last-child {
              border-radius: 0px 0px 8px 8px;
            }
          }
        }
      }
    }

    .no-data-goal {
      width: 791px;
      height: 411px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .way-report-modal-table-wrapper {
      height: 100%;
      max-height: 500px;
      overflow: auto;
      margin-bottom: 10px;
      border-radius: $border-radius-md;
    }

    .PacingCalender__AssessmentModel__container {
      margin: 14px 0px 15.5px 20px;
      position: relative;

      .modal-table {
        .report-No {
          padding: 2.5px 13px;
          background-color: #3e3e3e;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          color: white;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: $border-radius-md;
        }

        .headrow {
          td {
            padding-top: 3px !important;
            padding-bottom: 0px !important;
            vertical-align: bottom;
            text-shadow: unset;
            font-size: 14px;
            font-weight: 700 !important;
            color: white;
          }
        }

        .td1 {
          width: 79px;
          min-width: 79px;
          max-width: 79px;
          padding-left: 8px;
          padding-right: 8px;
        }

        .td2 {
          width: 55px;
          min-width: 55px;
          max-width: 55px;
          padding-left: 8px;
          padding-right: 8px;
        }

        .td3 {
          width: 45px;
          min-width: 45px;
          max-width: 45px;
          padding-left: 8px;
          padding-right: 8px;
        }

        .td4 {
          width: 160px;
          min-width: 160px;
          max-width: 160px;
          padding-left: 8px;
          padding-right: 8px;

          p {
            width: 92px;
            text-align: center;
            margin-bottom: 0px;
          }
        }
      }

      .PacingCalender__AssessmentModel__container__main {
        min-width: 741px;
        width: 741px;
      }

      tbody {
        tr {
          height: 37px;

          td {
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 16px;
            font-weight: 400;
            color: white;
          }
        }

        tr:last-child {
          border-radius: 0px 0px 8px 8px;
        }
      }
    }

    .no-data-goal-skeleton {
      width: 791px;
      height: 487px;
    }
  }

  .okayBtn {
    background-color: #537ab7;
    width: 100%;
    max-width: 100px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    font-weight: 400;
    color: white;
    box-shadow: none;
    border-radius: $border-radius-md;
    border: 0px;
    font-family: "Nunito", sans-serif !important;
  }
}

.seeAllModal {
  .modal-content {
    // width: auto !important;
    // max-width: 847px;
    // animation-name: widthAnimate;
    // transition: width 2s ease;
  }

  // @keyframes widthAnimate {
  //     0% {
  //         max-width: 847px;
  //     }

  //     100% {
  //         max-width: 437px;
  //     }
  // }

  // @keyframes widthAnimate {
  //     0% {
  //         width: 847px;
  //     }

  //     100% {
  //         width: 437px;
  //     }
  // }
}

.way-to-report-goals {
  .modal-content {
    width: 847px !important;
    transition: width 1s ease;
    padding: 12px 25px 23px 23px;
    border-radius: 10.5px;
  }
}

.way-to-report-goals.seeAllModal {
  .modal-content {
    overflow-x: hidden;
    width: 437px !important;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    border-radius: 10.5px;
  }
}

@media print {
  .way-to-report-print {
    display: none;
    box-shadow: none;
  }

  // .way-report-title-print {
  //     padding-left: 24px;
  //     margin-top: 30px;
  // }

  .way-to-report-footer-print {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
  }

  .way-to-report-body-print {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
}

// @page {
//     margin: 0;
// }

.way-to-report-goals {
  .modal-header {
    .checkbox-title {
      input {
        display: none;
      }

      .checkbox {
        height: 23px;
        width: 23px;
        display: inline-block;
        position: relative;
        top: 2px;
        background-color: #b9b9b9;
        border-radius: 50%;
      }

      input[type="checkbox"]:checked + span:before {
        content: url("../../img/check-white.png");
        position: unset;
        top: 3px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        width: 23px;
        height: 23px;
        padding-top: 3px;
        border-radius: 50%;

        @media (max-width: 575px) {
          top: -4px;
          left: 1px;
          font-size: 26px;
        }
      }

      input[type="checkbox"]:checked + span:before {
        background-color: #46afb8;
      }
    }
  }

  .way-report-modal-table-wrapper {
    .PacingCalender__AssessmentModel__container {
      .PacingCalender__AssessmentModel__container__main {
        background-color: #2a5391;

        .no-goals-found {
          min-height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #537ab7;
          border-radius: 0px 0px 8px 8px;

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 0;
          }
        }
      }

      .PacingCalender__AssessmentModel__container__table {
        .PacingCalender__AssessmentModel__container__table__head {
          .headrow {
            .td1.goal-th {
              width: 320px;
              min-width: 320px;
              padding-left: 28px;
            }

            .td2.goal-th {
              width: 100px;
              min-width: 100px;

              p {
                width: 100%;
                max-width: 100px;
                text-align: center;
                margin-bottom: 0;
              }
            }

            .td3.goal-th {
              width: 250px;
              min-width: 250px;

              p {
                width: 100%;
                max-width: 100px;
                text-align: center;
                margin-bottom: 0;
              }
            }

            .goal-th {
              padding-top: 8px !important;
              padding-bottom: 2px !important;
            }
          }
        }

        .PacingCalender__AssessmentModel__container__table__body {
          .goals-available-date {
            position: relative;
            width: 100%;
            height: 30px;

            div {
              width: 100%;
              background-color: #194792;
              height: 30px;
              position: absolute;
              padding-left: 28px;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              color: white;
              padding-top: 7px;
            }
          }

          .td1 {
            width: 320px;
            min-width: 320px;
          }

          .td2 {
            width: 100px;
            min-width: 100px;
            text-align: center;

            p {
              width: 100%;
              max-width: 100px;
              text-align: center;
              margin-bottom: 0;
            }
          }

          .td3 {
            width: 250px;
            min-width: 250px;
            text-align: center;

            p {
              width: 100%;
              max-width: 100px;
              text-align: center;
              margin-bottom: 0;
            }
          }

          td {
            .goal-check-box {
              padding-left: 20px;

              input {
                display: none;
              }

              .checkbox {
                height: 23px;
                width: 23px;
                display: inline-block;
                position: relative;
                top: -1px;
                background-color: #b9b9b9;
                border-radius: 50%;
                margin-right: 9px;
              }

              span {
                font-size: 16px;
                font-weight: 400;
                color: white;

                b {
                  font-weight: 700;
                }
              }

              input[type="checkbox"]:checked + span:before {
                content: url("../../img/check-white.png");
                position: unset;
                top: 3px;
                left: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                width: 23px;
                height: 23px;
                padding-top: 3px;
                border-radius: 50%;

                @media (max-width: 575px) {
                  top: -4px;
                  left: 1px;
                  font-size: 26px;
                }
              }

              input[type="checkbox"]:checked + span:before {
                background-color: #46afb8;
              }
            }
          }

          tr:last-child {
            td:last-child {
              border-radius: 0px 0px 8px 0px;
            }
          }
        }
      }
    }
  }
}

.see-all-goals-modal {
  .modal-content {
    width: 100%;
    max-width: 886px;
    padding: 10px 16px;
    border-radius: 10.5px;

    .modal-header {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
      border-bottom: 0;
      padding-bottom: 24px;

      .backArrow {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);
        }
      }

      .backArrow:hover {
        background-color: #2a5391;
      }

      img {
        margin-top: 0px;
      }

      .modal__header-title {
        line-height: 30px;
        top: 0px;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 18px;
        }
      }

      .header-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: white;
        font-family: $font-openSans;
        margin-top: 0px;

        @media (max-width: 576px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }

    .see-all-goals {
      .see-goals-sec {
        .goalsHeading {
          background-color: #2a5391;
          padding: 7px 22px 4px 22px;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          color: white;
          margin-bottom: 0px;
          border-radius: 8px 8px 0px 0px;
        }

        .see-goals-body::-webkit-scrollbar {
          display: none;
        }

        .see-goals-body {
          .available-date {
            margin-bottom: 14px;

            .available-header {
              font-size: 16px;
              font-weight: 700;
              line-height: 22px;
              color: white;
              margin-left: 30px;
              margin-bottom: 5px;
            }

            .available-body {
              .availibility {
                margin-top: 7px;
                border-radius: $border-radius-md;
                padding: 0 7px;

                p {
                  background-color: #b9b9b9;
                  padding: 0 13px;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19px;
                  color: #424242;
                  margin-bottom: 0px;
                  border-radius: 8px 8px 0px 0px;
                }

                .status {
                  margin: 0;
                  display: flex;
                  align-items: center;
                  background-color: $theme-bg-color-lighter;
                  padding: 6px 12px;
                  height: 47px;
                  border-radius: 0px 0px 8px 8px;

                  .loader {
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 17.5px;
                    color: white;
                    margin-bottom: 0px;
                    background-color: unset;
                    padding: 0;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    width: 100%;
                    max-width: calc(100% - 35px);
                    position: relative;
                    top: 0.5px;
                  }
                  .see-goals-body::-webkit-scrollbar {
                    display: none;
                  }

                  .see-goals-body {
                    .available-date {
                      margin-bottom: 14px;

                      :last-child {
                        margin-bottom: 0;
                      }

                      .available-header {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 22px;
                        color: white;
                        margin-left: 30px;
                        margin-bottom: 5px;
                      }

                      .available-body {
                        .availibility {
                          margin-top: 7px;
                          border-radius: $border-radius-md;
                          padding: 0 7px;

                          p {
                            background-color: #b9b9b9;
                            padding: 0 13px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            color: #424242;
                            margin-bottom: 0px;
                            border-radius: 8px 8px 0px 0px;
                          }

                          .status {
                            margin: 0;
                            display: flex;
                            align-items: center;
                            background-color: $theme-bg-color-lighter;
                            padding: 6px 12px;
                            height: 47px;
                            border-radius: 0px 0px 8px 8px;

                            .loader {
                              width: 25px;
                              height: 25px;
                              margin-right: 10px;

                              svg {
                                width: 100%;
                                height: 100%;
                              }
                            }

                            p {
                              font-size: 15px;
                              font-weight: 400;
                              line-height: 17.5px;
                              color: white;
                              margin-bottom: 0px;
                              background-color: unset;
                              padding: 0;
                              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                              width: 100%;
                              max-width: calc(100% - 35px);
                              position: relative;
                              top: 0.5px;

                              @media (max-width: 576px) {
                                font-size: 13px;
                                line-height: 15px;
                              }
                            }

                            .goals-modal-checkbox {
                              input {
                                display: none;
                              }

                              .checkbox {
                                width: 25px;
                                height: 25px;
                                margin-right: 9px;
                                position: relative;
                                border-radius: 50%;
                                background-color: #b9b9b9;
                                display: inline-block;
                                filter: drop-shadow(
                                  0px 2px 1px rgba(0, 0, 0, 0.29)
                                );
                              }

                              label {
                                font-size: 16px;
                                font-weight: 400;
                                color: white;
                                padding-left: 10px;
                              }

                              span {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 18px;
                                color: white;
                                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                              }
                            }

                            .goals-modal-checkbox
                              [type="checkbox"]:checked
                              + .checkbox {
                              background-color: #46afb8;
                            }

                            .goals-modal-checkbox
                              [type="checkbox"]:checked
                              + span:before {
                              // content: "\2714";
                              content: url("../../img/check-white.png");
                              position: absolute;
                              top: 5px;
                              left: 5px;
                              display: flex;
                              justify-content: center;
                              align-items: center;

                              @-moz-document url-prefix() {
                                position: relative;
                                top: 6px;
                                left: 0;
                              }
                            }
                          }
                        }

                        .disabled {
                          opacity: 0.49;
                        }
                      }

                      .available-body:last-child {
                        background-color: #424242;
                        border-radius: $border-radius-md;
                        padding: 14px 13px;
                        @include flex-box(flex-start, flex-start);
                        flex-wrap: wrap;
                        gap: 14px;

                        .goalsCard {
                          border-radius: $border-radius-md;
                          position: relative;

                          .card-title {
                            margin-bottom: 4px;
                          }

                          .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #00000033;
                          }

                          .completed-date-label {
                            height: 23px;
                            background-color: #306fdc;
                            padding: 1px 12px;
                            border-radius: $border-radius-md;
                            display: inline-block;
                            margin-left: 17px;
                            margin-bottom: 7px;

                            p {
                              color: #fff;
                              text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
                              font-size: $sm;
                              font-style: normal;
                              font-weight: 400;
                              margin-bottom: 0;
                            }
                          }

                          .card-body-height {
                            height: calc(100% - 59px);
                          }
                        }
                      }
                    }
                  }

                  .goals-modal-checkbox {
                    input {
                      display: none;
                    }

                    .checkbox {
                      width: 25px;
                      height: 25px;
                      margin-right: 9px;
                      position: relative;
                      border-radius: 50%;
                      background-color: #b9b9b9;
                      display: inline-block;
                      filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.29));
                    }

                    label {
                      font-size: 16px;
                      font-weight: 400;
                      color: white;
                      padding-left: 10px;
                    }

                    span {
                      font-size: 15px;
                      font-weight: 400;
                      line-height: 18px;
                      color: white;
                      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                  }

                  .goals-modal-checkbox [type="checkbox"]:checked + .checkbox {
                    background-color: #46afb8;
                  }

                  .goals-modal-checkbox
                    [type="checkbox"]:checked
                    + span:before {
                    // content: "\2714";
                    content: url("../../img/check-white.png");
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @-moz-document url-prefix() {
                      position: relative;
                      top: 6px;
                      left: 0;
                    }
                  }
                }
              }

              .disabled {
                opacity: 0.49;
              }
            }

            .available-body {
              background-color: #424242;
              border-radius: $border-radius-md;
              padding: 14px 13px;
              @include flex-box(flex-start, flex-start);
              flex-wrap: wrap;
              gap: 14px;

              .goalsCard {
                border-radius: $border-radius-md;
                position: relative;

                .overlay {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: #00000033;
                }

                .completed-date-label {
                  font-size: 14px;
                  line-height: 19px;
                  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
                  height: 23px;
                  background-color: #306fdc;
                  padding: 3px 12px 1px;
                  border-radius: $border-radius-md;
                  display: inline-block;
                  margin-left: 17px;
                  margin-bottom: 7px;

                  p {
                    color: #fff;
                    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
                    font-size: $sm;
                    font-style: normal;
                    font-weight: 400;
                    margin-bottom: 0;
                  }
                }

                .card-body-container {
                  height: calc(100% - 64px);
                }
              }
            }
          }
        }
      }
    }

    .no-data-goal {
      width: 791px;
      height: 411px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .way-report-modal-table-wrapper {
      height: 100%;
      max-height: 500px;
      overflow: auto;
      margin-bottom: 10px;
      border-radius: $border-radius-md;
    }

    .PacingCalender__AssessmentModel__container {
      margin: 14px 0px 15.5px 20px;
      position: relative;

      .modal-table {
        .report-No {
          padding: 2.5px 13px;
          background-color: #3e3e3e;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          color: white;
          position: absolute;
          top: 0;
          left: 0;
        }

        .headrow {
          td {
            padding-top: 3px !important;
            padding-bottom: 0px !important;
            vertical-align: bottom;
            text-shadow: unset;
            font-size: 14px;
            font-weight: 700;
            color: white;
          }
        }

        .td1 {
          width: 79px;
          min-width: 79px;
          max-width: 79px;
          padding-left: 8px;
          padding-right: 8px;
        }

        .td2 {
          width: 55px;
          min-width: 55px;
          max-width: 55px;
          padding-left: 8px;
          padding-right: 8px;
        }

        .td3 {
          width: 45px;
          min-width: 45px;
          max-width: 45px;
          padding-left: 8px;
          padding-right: 8px;
        }

        .td4 {
          width: 160px;
          min-width: 160px;
          max-width: 160px;
          padding-left: 8px;
          padding-right: 8px;

          p {
            width: 92px;
            text-align: center;
            margin-bottom: 0px;
          }
        }
      }

      .PacingCalender__AssessmentModel__container__main {
        min-width: 741px;
        width: 741px;
      }

      tbody {
        tr {
          height: 37px;

          td {
            padding-top: 8px;
            padding-bottom: 6px;
            font-size: 16px;
            font-weight: 400;
            color: white;
          }
        }

        tr:last-child {
          border-radius: 0px 0px 8px 8px;
        }
      }
    }

    .no-data-goal-skeleton {
      width: 791px;
      height: 487px;
    }
  }
}

.assesment-remediated {
  .modal-content {
    max-width: 818px;
    padding: 15px 17px 18px 18px;
    border-radius: 10.5px;

    @include media-breakpoint(lg) {
      max-width: 97%;
    }

    .modal-header {
      img {
        position: relative;
        top: 2px;
        margin-right: 10px;
      }
    }

    .way-report-modal-table-wrapper {
      max-height: 328px;
      margin-bottom: 15px;

      .PacingCalender__AssessmentModel__container {
        margin-top: 0;
        margin-right: 22px;

        @media (max-width: 576px) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .way-report-modal-table-wrapper::-webkit-scrollbar {
      display: none;
    }

    .remediate-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-top: 4px;
      padding-left: 37px;

      @media (max-width: 767px) {
        padding-left: 20px;
      }

      @media (max-width: 639px) {
        display: block;
        padding-left: 0px;
      }

      .label {
        background-color: #3c4046;
        padding: 0 13px;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        color: white;
        text-align: center;
        border-radius: 40px;
        position: absolute;
        top: -4px;

        @media (max-width: 639px) {
          font-size: 12px;
        }
      }

      .yellow {
        margin-left: 60px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #eadb4a;

        @media (max-width: 767px) {
          margin-left: 55px;
        }

        @media (max-width: 639px) {
          width: 14px;
          height: 14px;
        }
      }

      .status {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 0px;
        padding-top: 1px;
        margin-left: 9px;
        width: auto;

        @media (max-width: 639px) {
          font-size: 12px;
          margin-left: 7px;
        }
      }

      .label-date {
        background-color: #d9d9d9;
        padding: 2px 9px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #424242;
        text-align: center;
        border-radius: 40px;
        margin-left: 14px;

        @media (max-width: 639px) {
          font-size: 12px;
          padding: 2px 8px;
          margin-left: 8px;
        }
      }

      .info {
        width: 24px;
        height: 24px;
        border: 2px solid white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $md;
        font-weight: 800;
        color: white;
        font-family: $font-nunito;
        margin-top: 1px;
        padding-top: 1px;

        @media (max-width: 639px) {
          width: 14px;
          height: 14px;
          border-width: 1px;
          font-size: 10px;
          margin-top: 10px;
        }
      }

      .okayBtn {
        width: 200px;
        margin-left: 15px !important;

        @media (max-width: 767px) {
          font-size: 14px;
          margin-left: 10px;
        }

        @media (max-width: 639px) {
          font-size: 12px;
          padding: 2px 14px;
          margin-top: 10px;
        }
      }

      .assign-attempt-btn {
        background-color: #46afb8;
        font-size: 16px;
        color: white;
        border: 0;
        border-radius: $border-radius-md;
        padding: 4px 18.4px;
        width: 198px;
        height: 33px;
        margin-left: 14px;

        @media (max-width: 767px) {
          font-size: 14px;
          margin-left: 10px;
        }

        @media (max-width: 639px) {
          font-size: 12px;
          padding: 2px 10px;
          margin-top: 10px;
          width: 150px;
        }
      }
    }
  }
}

.remediate-labels {
  background-color: rgb(255 255 255 / 17%);

  .modal-content {
    padding: 11px 17px 15px !important;
    background-color: rgba($modal-bg, 0.87);
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.55));
    border-radius: 10.5px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between !important;

    .back-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      margin-right: 10px;
      margin-left: -3px;
      cursor: pointer;

      img {
        width: auto;
        height: auto;
        margin: 0;
        position: unset;
      }
    }

    .back-arrow:hover {
      background-color: #2a5391;
    }

    .print-btn {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: $border-radius-md;
      color: #fff;
      height: 33px;
      outline: none;
      padding: 3px 18.5px;
      margin-top: 6px;

      &:focus-visible {
        outline: 2px solid blue;
      }
    }
  }

  .proficient-perc-label {
    margin-bottom: 16px;
    background-color: #d9d9d9;
    border-radius: 40px;
    padding: 6px 13px 4px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #3e3e3e;
    display: inline-flex;
    margin-left: 20px;
  }

  .way-report-modal-table-wrapper {
    width: 100%;
    max-width: 741px;
    margin: 0 auto;
    min-height: 158px;
    height: 100%;
    max-height: 328px !important;
    margin-bottom: 18px !important;

    .PacingCalender__AssessmentModel__container {
      margin: 0 !important;

      .PacingCalender__AssessmentModel__container__main {
        width: 100%;
        min-width: none;
        background-color: unset;
      }

      .PacingCalender__AssessmentModel__container__table {
        .PacingCalender__AssessmentModel__container__table__head {
          .headrow {
            height: 47px;
          }

          .td1 {
            width: 140px;
            min-width: 140px;
          }

          .td2 {
            width: 107px;
            min-width: 107px;
          }

          .td3 {
            width: 125px;
            min-width: 125px;
            text-align: left;

            .attempt {
              padding-left: 1px;

              .score {
                padding-left: 15px;
              }
            }
          }

          .td4 {
            width: 66px;
            min-width: 66px;
            text-align: center;
            padding-right: 28px;

            p {
              width: 100%;
            }
          }
        }

        .PacingCalender__AssessmentModel__container__table__body {
          .td1 {
            width: 140px;
            min-width: 140px;
            vertical-align: middle;
          }

          .td2 {
            width: 107px;
            min-width: 107px;
            vertical-align: middle;
          }

          .td3 {
            width: 125px;
            min-width: 125px;
            // text-align: center;
            position: relative;
            vertical-align: middle;
            padding-left: 18px;
            padding-right: 0;

            p {
              color: $color-gray-950;
              text-align: center;
              margin-bottom: 0px;
            }

            div {
              p:first-child {
                width: 44px;
              }
            }

            .label-date {
              background-color: #d9d9d9;
              padding: 2px 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: #424242;
              text-align: center;
              border-radius: 40px;
              position: absolute;
              left: 71px;
              z-index: 1;
              width: auto;
              max-width: 54px;
            }

            .skeleton-loading {
              .label-date {
                left: 48px;
              }
            }

            .yellow-frame {
              width: 44px;
              @include flex-box(center, center);

              .yellow {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #ebdd39;
              }
            }
          }

          .td4 {
            width: 66px;
            min-width: 66px;
            text-align: center;
            vertical-align: middle;
            padding-right: 28px;

            .checked-img {
              width: 22px;
              height: 22px;
              margin-inline: auto;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          tr {
            background-color: unset;

            &:first-child {
              td:first-child {
                border-radius: 8px 0px 0px 0px;
              }
            }

            &:first-child {
              td:last-child {
                border-radius: 0px 8px 0px 0px;
              }
            }

            td {
              position: relative;
              color: $color-gray-950 !important;
              background-color: $color-gray-500;

              &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $color-gray;
              }
            }

            td:first-child {
              &::before {
                width: calc(100% - 19px);
                right: 0;
                left: unset;
              }
            }

            td:last-child {
              &::before {
                width: calc(100% - 19px);
              }
            }
          }
        }
      }
    }
  }
}

.remediate-modal-info {
  background-color: rgb(255 255 255 / 17%);

  .modal-content {
    padding: 11px 16px 16px 18px !important;
    background-color: #363636;
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.55));
    top: 125px;
    border-radius: 10.5px;

    .modal__header {
      padding: 0;

      .modal__header-icon {
        margin-right: 8px;
      }

      .modal__header-title {
        line-height: 26px;
      }
    }

    .modal__body {
      padding: 15px 21px 0px 2px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 21.18px;
      }
    }

    .modal__footer {
      margin-top: 11px !important;
      padding: 0px;

      button {
        padding: 8px 16.5px;
      }
    }
  }
}
