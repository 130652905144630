.classroom {
  .resources-section {
    margin-top: 0;

    &__tabs_content {
      &__empty_table {
        background-color: $theme-bg-color-medium;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        // text-shadow: 0 3px 6px rgba(0, 0, 0, 0.52);
        margin-bottom: 0;
        border-radius: $border-radius-md;
      }
    }
  }

  &__tabs {
    display: flex;
    @include media-breakpoint(lg) {
      flex-direction: column;
    }

    &-left {
      width: 165px;
      border-right: 1px solid $theme-border-color;
      padding: 40px 32px;

      @include media-breakpoint(xl) {
        width: 141px;
        padding: 40px 20px;
      }

      @include media-breakpoint(lg) {
        width: 100%;
        border-right: none;
        padding: 16px;
        padding-bottom: 0;
      }
    }

    &-leftContent {
      display: flex;
      flex-direction: column;
      height: 100%;

      @include media-breakpoint(xl) {
        justify-content: space-between;
      }

      @include media-breakpoint(lg) {
        flex-direction: row;
        justify-content: center;
      }

      img {
        margin-bottom: 8px;

        @include media-breakpoint(lg) {
          margin-bottom: 0;
          margin-right: 10px;
        }

        @include media-breakpoint(md) {
          max-width: 20px;
          height: auto;
          margin-right: 8px;
        }
      }
    }

    &-leftLink {
      @include flexcenter-both;
      flex-direction: column;
      text-decoration: none;
      color: $color-white;
      text-align: center;
      width: 100px;
      height: 100px;
      border-radius: $border-radius-md;
      padding: 5px;
      transition: all 0.4s;

      @include media-breakpoint(lg) {
        width: 136px;
        height: auto;
        flex-direction: row;
        padding: 12px 16px;
        margin: 0 8px;
      }

      @include media-breakpoint(md) {
        width: 106px;
        font-size: 14px;
        padding: 8px;
        margin: 0 5px;
      }

      &:hover:not(&.active) {
        background-color: $theme-bg-color-dark;

        @include media-breakpoint(xl) {
          background-color: transparent;
        }
      }

      &:not(:last-child) {
        margin-bottom: 28px;

        @include media-breakpoint(lg) {
          margin-bottom: 0;
        }
      }

      &:hover {
        color: darken($color-white, 10%);
      }

      &.active {
        background-color: $theme-bg-color-dark;

        &:hover {
          background-color: darken($color-blue-700, 5%);
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    &-container {
      position: relative;
      flex: 1;
      padding: 0px;

      @include media-breakpoint(md) {
        padding: 0 15px;
      }

      @include media-breakpoint(sm) {
        padding: 0 12px;
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      padding: 0px 0 18px 0;
      align-items: flex-end;
      // border-bottom: 1px solid $theme-border-color;

      .grade-wrapper {
        float: unset;
        width: 248px;

        label {
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          color: white;
          padding-left: 19px;
          display: inline-block;
          margin-bottom: 2px;
        }

        .grade-selects__wrapper {
          width: 100%;
          height: 33px;

          .grade-selects__control {
            width: 100%;
            height: 100%;
            font-size: 15px;
            font-weight: 400;
            color: $blue-500;
            padding-right: 7px;

            .grade-selects__indicators {
              background-image: url(../../../img/select-icon-blue.svg);
              background-position-y: 13px;
            }
          }

          .grade-selects__value-container {
            padding-top: 1px !important;
            padding-left: 19px !important;

            .grade-selects__single-value {
              font-size: 15px;
              font-weight: 400;
              color: $blue-500 !important;
            }
          }
        }

        @media (max-width: 991px) {
          position: relative;
        }
      }
    }

    .resources-content {
      position: relative;
      justify-content: space-between;
      padding-left: 28px;

      @media (max-width: 1023px) {
        padding-top: 85px;
      }

      @include media-breakpoint(md) {
        padding-left: 0;
        gap: 12px;
      }

      @media (max-width: 450px) {
        flex-direction: column;
      }

      &__tabs-buttons button {
        font-size: 18px;

        @include media-breakpoint(sm) {
          font-size: 15px;
          padding: 3px 10px;
        }
      }

      .grade-wrapper {
        @media (max-width: 450px) {
          width: 100%;
        }
      }

      .search-box {
        @media (max-width: 450px) {
          width: 100%;

          input {
            width: calc(100% - 27px);
          }
        }
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      min-height: 280px;
      height: 100%;
    }

    &-buttons {
      display: flex;
      border: none;
      margin: 30px auto 0;

      @media (max-width: 1023px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        justify-content: center;
        margin-top: 15px;
      }
      .nav-link {
        border: 1px solid transparent;
        border-radius: 30px;
        margin: 0 7px;
        transition: all 0.3s;

        &:hover {
          background-color: transparent;
          border-color: transparent;
          color: $color-white;

          @include desktop-up {
            background-color: $theme-bg-color-dark;
            border-color: $theme-border-color;
            color: $color-white;
          }
        }

        &:disabled {
          color: #ffffff;
        }

        &.active {
          background-color: $theme-bg-color-dark;
          border-color: $theme-border-color;
          color: $color-white;
        }
      }
    }

    .search-box {
      margin-bottom: 3px;
    }

    &-contentRow {
      display: flex;
      font-size: 16px;
      color: $color-white;
      border-radius: $border-radius-md;

      &:nth-child(odd) {
        background-color: $theme-bg-color-dark-variant2;
      }

      @media (max-width: 768px) {
        width: 768px;
      }
    }

    &-contentPdf {
      display: flex;
      align-items: center;
      width: 286px;
      padding: 5.5px 0px 5.5px 26px;
      cursor: pointer;

      @include media-breakpoint(md) {
        width: 200px;
        padding: 8px 15px;
      }

      @include media-breakpoint(sm) {
        padding: 8px;
      }

      img {
        display: block;
        max-width: 34px;
        height: auto;
        margin-right: 15px;

        @include media-breakpoint(md) {
          margin-right: 10px;
        }

        @include media-breakpoint(sm) {
          max-width: 24px;
          margin-right: 6px;
        }
      }

      p {
        font-weight: 700;
        @include ellipsis;
        margin-bottom: 0;
      }
    
      .resource-name-animate {
          transition: left 1s linear, width 3s linear !important;
          position: relative;
          left: 0;
      }
    }

    &-contentText {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 8px 20px;

      @include media-breakpoint(md) {
        padding: 8px 15px;
      }

      @include media-breakpoint(sm) {
        padding: 8px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .dark-background {
      background-color: $color-blue;
    }

    .light-background {
      background-color: rgba($color-blue-300, 0.46);
    }

    .disabled-resources {
      pointer-events: none;
      opacity: 0.38;
    }

    .permission {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 56px;
      &.tab-permission {
        padding-top: 0;
        top: 48px;
      }
      &-text {
        background-color: rgba($color-black, 0.46);
        width: 524px;
        height: 101px;
        border-radius: $border-radius-md;
        padding: 20px 36px;
        padding-bottom: 21px;

        p {
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          text-shadow: 0px 3px 6px rgba($color-black, 0.56);
          margin-bottom: 0;
        }
      }
    }

    .permission-resources-box {
      height: 310px;
      padding-top: 80px;
    }

    &-content {
      height: 548px;
      overflow-y: auto;
      scrollbar-width: 12px;
      scrollbar-color: rgba($color-black, 0.23);

      @include media-breakpoint(lg) {
        scrollbar-width: 8px;
      }

      @include media-breakpoint(md) {
        scrollbar-width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($color-black, 0.12);
      }

      &::-webkit-scrollbar {
        width: 12px;

        @include media-breakpoint(lg) {
          width: 8px;
        }

        @include media-breakpoint(md) {
          width: 6px;
        }
      }

      &::-webkit-scrollbar-thumb {
        width: 8px;
        border-radius: 10px;
        background-color: rgba($color-black, 0.23);
        border: 2px solid rgba($color-blue-100, 0.4);

        @include media-breakpoint(lg) {
          width: 6px;
          border: 1px solid rgba($color-blue-100, 0.4);
        }

        @include media-breakpoint(md) {
          width: 4px;
        }
      }
    }

    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 227px;
      border-radius: 0px 0px 8px 8px;

      p {
        font-weight: bold;
        // text-shadow: $text-shadow;
        margin-bottom: 0;
      }
    }

    &-footer {
      margin-top: auto;
      padding: 12px 0;

      p {
        text-align: center;
        font-size: 16px;
        color: $color-gray-300;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }
}

// Summative
.summative {
  &__table {
    &-row {
      th {
        padding-top: 10px;
        vertical-align: bottom;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-shadow: $text-shadow;
        margin-bottom: 0;

        @include media-breakpoint(md) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .summativeObejct {
        position: relative;
        left: 0px;

        @media (max-width: 1280px) {
          left: 0px;
        }
      }

      td {
        padding: 8px 10px;
        padding-bottom: 21px;

        @include media-breakpoint(xxl) {
          padding: 8px 5px;
          padding-bottom: 21px;
        }

        @include media-breakpoint(md) {
          padding-bottom: 18px;
        }
      }

      .full-data {
        padding-left: 23px;
        padding-top: 7px;
        padding-bottom: 13px;
        color: $color-gray-300;

        @include media-breakpoint(xxl) {
          font-size: 15px;
          padding-left: 18px;
        }
      }

      .full-data-date {
        padding-left: 23px;
        color: $color-gray-300;

        @include media-breakpoint(xxl) {
          padding-left: 18px;
        }
      }

      .available-data-text {
        position: absolute;
        left: 0;
        bottom: -18px;
        width: 100%;
        font-size: 12px;
        font-weight: bold;
        text-shadow: none;

        &.lower {
          bottom: -6px;

          @include media-breakpoint(md) {
            bottom: 0;
          }
        }
      }
    }

    &-objective {
      min-width: 315px;
      width: 315px;
      padding: 6px 0 3px 23px;
      vertical-align: bottom;

      @include media-breakpoint(xxl) {
        min-width: 306px;
        padding: 6px 5px 4px 18px;
      }
    }

    &-last {
      width: 125px;
      padding-bottom: 3px;

      @include media-breakpoint(xxl) {
        width: 110px;
        min-width: 110px;
      }
    }

    &-proficiency {
      width: 122px;
      padding: 6px 10px 3px 10px;
      vertical-align: bottom;

      @include media-breakpoint(xxl) {
        width: 110px;
        padding: 6px 5px 4px 5px;
      }

      @include media-breakpoint(xl) {
        min-width: 100px;
      }
    }

    &-review {
      width: 60px;
      min-width: 60px;
      padding-bottom: 3px;
    }

    &-edit {
      width: 94px;
      min-width: 75px;
      padding-bottom: 3px;
    }

    &-summative-reports {
      min-width: 147px;
      width: 143px;
      padding: 6px 10px 3px 10px;
      vertical-align: bottom;

      @include media-breakpoint(xxl) {
        padding: 6px 5px 4px 5px;
      }
    }

    &-reports {
      min-width: 80px;
      width: 80px;
      padding: 6px 10px 3px 10px;
      vertical-align: bottom;

      @include media-breakpoint(xxl) {
        padding: 6px 5px 4px 5px;
      }
    }

    &-available {
      width: 100px;
      min-width: 100px;
      padding-bottom: 3px;
    }

    &-monitor {
      width: 200px;
      padding: 6px 36px 3px 10px;
      vertical-align: bottom;

      @include media-breakpoint(xxl) {
        width: 141px;
        padding: 6px 18px 4px 5px;
      }

      @include media-breakpoint(xl) {
        width: 110px;
        min-width: 110px;
      }
    }
    &-remediate {
      padding-bottom: 3px;
    }

    .objective-data {
      max-width: 315px;
      padding-right: 0;
      padding-left: 23px;
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;

      span p {
        @include ellipsis;
        text-shadow: none;
      }

      @include safari-suport {
        padding-top: 5px !important;
        padding-bottom: 21px !important;
      }

      @include media-breakpoint(xxl) {
        padding-left: 18px;
      }

      &-name {
        display: block;
        margin: 6px 0;
        margin-top: 5px;
      }

      &-highlight {
        display: inline-block;
        background-color: rgb(50 96 212 / 56%);
        border-radius: $border-radius-md;
        font-size: 14px;
        line-height: 19px;
        padding: 2px 10px;
        text-shadow: 1px 1px 3px rgba($color-black, 0.52);
        font-weight: normal;

        @include safari-suport {
          padding-top: 1px;
          padding-bottom: 3px;
        }
      }
    }

    .activiy-data {
      @include safari-suport {
        padding-top: 6px !important;
        padding-bottom: 20px !important;
      }
    }

    .last_activity_date {
      min-width: 80px;
    }
  }
}

// Monitor Table
.monitor {
  &.Random {
    width: 82px;
    height: 20px;
    background: $color-yellow-FFA500 0% 0% no-repeat padding-box;
    border-radius: $border-radius-md;
    opacity: 1;
    color: $color-brown;
    text-align: center;
    justify-content: center;
    text-shadow: none;
  }
  &__table {
    th {
      padding: 5px 0 3px 0;
      color: white !important;
      font-weight: 700 !important;
      vertical-align: bottom;
    }

    th,
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      // text-shadow: $text-shadow;
      color: $color-gray-950;
      line-height: 21.82px;
      margin-bottom: 0;
      word-break: break-word;

      @include media-breakpoint(md) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    tbody {
      tr {
        height: 37px;
        background-color: unset;
      }

      // tr:nth-child(odd) {
      //   background-color: $color-gray-500;
      // }

      // tr:nth-child(even) {
      //   background-color: $color-gray-500;
      // }
    }

    &-row {
      &:first-child {
        td:first-child {
          border-top-left-radius: 8px;
          display: table-cell;
        }

        td:last-child {
          border-top-right-radius: 8px;
          display: table-cell;
        }
      }

      &:last-child {
        td:first-child {
          border-bottom-left-radius: 8px;
          display: table-cell;
        }

        td:last-child {
          border-bottom-right-radius: 8px;
          display: table-cell;
        }
      }

      td {
        padding: 0px 0 2px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: $color-gray-950;
        position: relative;
        background-color: $color-gray-500;

        @include safari-suport {
          padding-top: 1px;
          vertical-align: middle;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 1px;
          width: 100%;
          max-width: 1141px;
          height: 1px;
          background-color: $color-gray;
        }

        .variantNumber {
          padding-top: 1px;
        }

        .variant {
          display: inline-block;
          margin-bottom: 2px;
          margin-left: 4px;
          border: 1px solid #3260d4;
          border-radius: 8px;
          padding: 1px 6px;
          font-size: 12px;
          line-height: 16px;
          color: #3260d4;
          text-align: center;
          height: 19px;

          // @include safari-suport {
          //   margin-bottom: 1px;
          // }
        }
      }

      td:first-child {
        &::before {
          width: calc(100% - 19px);
          right: 0;
          left: unset;
        }
      }

      td:last-child {
        &::before {
          width: calc(100% - 19px);
        }
      }

      .assessment-label-report {
        p {
          padding: 2px 10px 1px 10px;
          background-color: $theme-bg-color-lighter-variant3;
          border-radius: $border-radius-md;
          text-align: center;
          font-size: $sm;
          font-weight: 400;
          line-height: 19px;
          color: white;
          display: inline-block;
        }
      }

      .assessment-label-summ {
        p {
          padding: 1px 10px 0px 10px;
          background-color: $theme-bg-color;
          border-radius: $border-radius-md;
          text-align: center;
          font-size: $md;
          font-weight: 400;
          line-height: 21.82px;
          color: white;
          display: inline-block;
        }
      }

      .status-data {
        padding-left: 14px;

        @include media-breakpoint(xxl) {
          padding-left: 10px;
        }

        .status-table-data {
          width: 15px;
          margin-left: 27px;
          position: relative;
          padding-bottom: 1px;
        }

        .status-duration {
          display: inline-block;
          position: absolute;
          top: 12px;
          left: 27px;
          width: 52px;
          transform: translateY(-50%);
          text-align: left;
          font-size: 12px;
          line-height: 16.37px;
          color: $color-gray-950;
        }
      }

      .student-name {
        width: 160px;
        max-width: 160px;
      }

      .id-data {
        text-align: center;
        font-size: $md;
        font-weight: 400;
        color: $color-gray-950;
        @include media-breakpoint(xxl) {
        }
      }

      .progress-data {
        min-width: 390px;
        width: 390px;
        padding-left: 38px;

        .pill {
          position: absolute;
          top: 7px;
          left: 8px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: $color-yellow-FFA500;
          font-size: $sm;
          font-weight: 400;
          line-height: 19px;
          color: #000000;
          @include flex-box(center, center);
        }
      }

      .progress-data-formative {
        padding-left: 38px;

        .pill {
          position: absolute;
          top: 8px;
          left: 8px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background-color: $color-yellow-FFA500;
          font-size: $sm;
          font-weight: 400;
          line-height: 18px;
          color: #000000;
          padding-top: 2px;
          
          @include flex-box(center, center);
        }

        .progress__ratings {

          li {
            line-height: 18px;

            div {
              line-height: 18px;
              margin-top: -1px;
            }
          }
        }
      }

      .score-data {
        text-align: center;

        .scoreBox {
          padding: 5px 5px 3px 5px;
          background-color: #ffe000;
          font-size: $sm + 1;
          font-weight: 400;
          color: #1e1e1e;
          line-height: 20.46px;
          min-width: 28px;
          height: 28px;
          display: inline-block;
        }
      }

      .options-data {
        padding-left: 49px;
      }

      .options-right-data {
        padding-left: 18px;
        .attempt {
          width: 143px;
          padding-top: 4px;
          padding-bottom: 4px;
          font-size: 14px;

          @include safari-suport {
            padding-top: 3px;
            padding-bottom: 5px;
          }
        }

        .new_attempts_button {
          height: 26px;
          border: 1px solid $color-gray-1000 !important;
          color: $color-gray-950;
          padding-top: 2px;
          padding-bottom: 2px;
        }

        .table-dropdown-toggle {
          border: 1px solid $color-gray-1000 !important;
          border-radius: $border-radius-md !important;
        }
      }

      .selects-data {
        padding-left: 42px !important;

        .attempt {
          width: 143px;
          padding-top: 4px;
          padding-bottom: 4px;
          font-size: 14px;
          border-color: $color-gray-950;
          color: $color-gray-950;
          height: 26px;

          @include safari-suport {
            padding-top: 3px;
            padding-bottom: 5px;
          }
        }
      }
    }

    &-status {
      padding-left: 25px !important;
      width: 125px;
      min-width: 125px;
      text-align: center;
      vertical-align: bottom;
      text-align: left;

      @include media-breakpoint(xxl) {
        width: 125px;
        min-width: 125px;
        padding-left: 25px !important;
      }
    }

    &-available {
      min-width: 92px;
      width: 92px;
      text-align: center;
    }

    &-name {
      min-width: 160px;
      width: 160px;
      text-align: right;
      vertical-align: bottom;
    }

    &-id {
      min-width: 105px;
      width: 105px;
      padding-left: 0px !important;
      text-align: center;
    }

    &-attempt {
      min-width: 95px;
      width: 95px;
      padding-left: 15px !important;
      text-align: center;
      vertical-align: bottom;
    }

    &-progress {
      width: 224px;
      padding-left: 36px !important;
      vertical-align: bottom;

      @include media-breakpoint(xxl) {
        padding-left: 20px !important;
      }

      .icon {
        margin-left: 8px;
        cursor: pointer;
        transform: translateY(-3px);

        img {
          max-width: 24px;
          height: auto;
        }
      }
    }

    &-largeProgress {
      width: 390px;
      min-width: 390px;
      padding-left: 38px !important;
      vertical-align: bottom;

      .icon {
        margin-left: 8px;
        cursor: pointer;
        transform: translateY(0px);

        img {
          max-width: 24px;
          height: auto;

          @include media-breakpoint(md) {
            max-width: 20px;
          }
        }
      }
    }

    &-score {
      min-width: 83px;
      width: 83px;
      text-align: center;
      vertical-align: bottom;
    }

    &-options {
      min-width: 172px;
      padding-left: 50px !important;
      vertical-align: bottom;

      @include media-breakpoint(xxl) {
        padding-left: 44px !important;
        min-width: 204px;
      }
    }

    &-optionsRight {
      width: 175px;
      min-width: 175px;
      padding-left: 25px !important;
      vertical-align: bottom;
    }

    .status-data.skeleton {
      width: 125px;
      min-width: 125px;
      padding-top: 6px;
      padding-left: 37px;
    }

    .name-skeleton {
      width: 160px;
      min-width: 160px;
      text-align: right;
      padding-top: 4px;
    }

    .id-data.skeleton {
      width: 105px;
      min-width: 105px;
      text-align: center;
      padding-top: 4px;
    }

    .assessment-label-report.skeleton {
      width: 105px;
      min-width: 105px;
      text-align: center;
      padding-top: 4px;
    }

    .attempt-skeleton {
      width: 70px;
      min-width: 70px;
      text-align: center;
      padding-top: 4px;
      padding-left: 16px;
    }

    .progress-data-formative.skeleton {
      width: 390px;
      min-width: 390px;
      text-align: left;
      padding-left: 38px;

      ul {
        gap: 7px;
      }
    }

    .score-data.skeleton {
      width: 83px;
      min-width: 83px;
      text-align: center;
    }

    .options-right-data.skeleton {
      width: 175px;
      min-width: 175px;
      padding-left: 18px;

      .table-dropdown {
        opacity: 0.4;
      }
    }
  }
}

// Answers Table
.answer {
  &__table {
    margin-top: 30px;

    &.dark-text-color {
      color: $color-gray-950;
    }

    &-row {
      position: relative;
      th {
        padding: 0;
        padding-bottom: 5px;
      }

      &-border::after {
        content: "";
        position: absolute;
        left: 26px; /* Adjust for padding on the left */
        right: 26px; /* Adjust for padding on the right */
        bottom: 0;
        height: 1px;
        background-color: #adadad;
      }

      th,
      p {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-shadow: $text-shadow;
        margin-bottom: 0;

        &.remove-shadow {
          text-shadow: none !important;
        }

        @include media-breakpoint(md) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      td {
        padding: 7px 0px;
        line-height: 22px;

        @include safari-suport {
          padding: 6px 0 8px 0;
        }

        .variant {
          display: block;
          margin-left: 4px;
          border: 1px solid #3260d4;
          border-radius: 8px;
          padding: 1px 6px;
          font-size: 12px;
          line-height: 16px;
          color: #3260d4;
          text-align: center;
          height: 19px;
        }

      }
      .arrow-data {
        padding-left: 19px;
        padding-top: 3px;
        padding-bottom: 3px;
      }

      .arrow {
        img {
          width: 28px;
          margin-left: 1px;
        }
      }

      .name-data {
        text-align: right;
        padding-right: 48px;
        padding-left: 26px;

        @include media-breakpoint(xl) {
          padding-right: 30px;
          padding-left: 16px;
        }
      }

      .summativeName-data {
        text-align: right;
        padding-right: 48px;
        padding-left: 10px;

        @include media-breakpoint(xl) {
          padding-right: 30px;
        }
      }

      .summativeId-data {
        text-align: center;
        padding-right: 20px;
      }

      .log-data {
        text-align: center;
      }

      .delete-data {
        padding-left: 42px;

        @include media-breakpoint(xl) {
          padding-left: 32px;
        }
      }

      .delete-dash {
        display: inline-block;
        padding-left: 11px;
      }
      .disable-btn {
        opacity: 28%;
        cursor: default;
      }
    }

    &-tools {
      text-align: center;
      font-size: 14px;
      padding-left: 115px !important;
    }

    &-arrow {
      min-width: 55px;
      width: 55px;
      padding-left: 15px !important;
    }

    &-name {
      position: relative;
      width: 252px;
      min-width: 252px;
      text-align: right;
      vertical-align: bottom;
      padding-right: 48px !important;
      padding-left: 26px !important;

      @include media-breakpoint(xl) {
        padding-right: 30px !important;
        padding-left: 16px !important;
      }
    }

    &-summativeName {
      position: relative;
      width: 220px;
      min-width: 220px;
      text-align: right;
      vertical-align: bottom;
      padding-right: 48px !important;
      padding-left: 10px !important;

      @include media-breakpoint(xl) {
        padding-right: 30px !important;
      }
    }

    &-id {
      min-width: 121px;
      width: 121px;
      text-align: center;
      padding-right: 20px !important;
      vertical-align: bottom;
    }

    &-attempt {
      width: 95px;
      min-width: 95px;
      text-align: center;
      vertical-align: bottom;
    }

    &-date {
      width: 167px;
      min-width: 150px;
      text-align: center;
    }

    &-score {
      width: 65px;
      min-width: 65px;
      text-align: center;
      vertical-align: bottom;
    }

    &-log {
      min-width: 80px;
      width: 128px;
      text-align: center;
      vertical-align: bottom;
      padding-right: 4px !important;
    }

    &-edit {
      text-align: center;
      min-width: 100px;
      width: 112px;
      vertical-align: bottom;
    }

    &-add {
      min-width: 104px;
      width: 104px;
      text-align: center;
    }

    &-delete {
      min-width: 85px;
      padding-left: 32px !important;
      vertical-align: bottom;

      @include media-breakpoint(xl) {
        padding-left: 20px !important;
      }
    }
  }
}

.log-modal-header {
  padding: 10px 3px;
}
// logs Table
.logs {
  display: flex;
  flex-direction: column;
  color: $color-white;

  @include media-breakpoint(md) {
    overflow-x: auto;
  }

  span {
    margin-bottom: 10px;
  }

  &__table {
    margin-top: 12px;
    @include media-breakpoint(md) {
      width: 714px;
    }

    .gray-row {
      background-color: rgba($color-gray-800, 0.38);
    }
    &-row {
      th {
        font-size: 16px;
        font-weight: bold;
        text-shadow: $text-shadow;
        line-height: 20px;
        padding: 0;
        padding-bottom: 5px;

        @include media-breakpoint(md) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      td {
        padding: 7px 0px;
        line-height: 23px;

        @include safari-suport {
          padding: 5px 0 9px 0;
        }
      }
      .teacherBg {
        display: inline-block;
        background: $color-yellow;
        height: 22px;
        border-radius: $border-radius-md;
        text-align: center;
        font-size: 16px;
        color: $color-brown;
        font-weight: bold;
        line-height: 20px;
        padding: 1px 16px;
      }
      .studentBg {
        display: inline-block;
        background: $color-green;
        height: 22px;
        border-radius: $border-radius-md;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        padding: 1px 16px;
      }
      .classroomBg {
        display: inline-block;
        background: $color-yellow;
        height: 22px;
        border-radius: $border-radius-md;
        text-align: center;
        font-size: 16px;
        color: $color-brown;
        font-weight: bold;
        line-height: 20px;
        padding: 1px 16px;
      }
      .schoolBg {
        display: inline-block;
        background: $color-blue-800;
        height: 22px;
        border-radius: $border-radius-md;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        padding: 1px 16px;
      }
      .date-data {
        text-align: left;
        padding-left: 42px;
        width: 222px;
      }

      .action-data {
        text-align: left;
        width: 242px;
      }

      .name-data {
        width: 158px;
      }

      .reason-data {
        text-align: center;
        min-width: 80px;
        padding-right: 26px;
      }
      .attempt-data {
        width: 86px;
        padding-left: 18px;
      }
      .attempt {
        padding-left: 18px;
      }
      .disable-btn {
        opacity: 28%;
        cursor: default;
      }
    }
  }

  &__textarea {
    width: 391px;
    margin: 20px auto 0 auto;

    textarea {
      min-height: 118px;
    }
  }
}

// Edit Availiblity
.edit__availiblity {
  &-checks {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    p {
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  &-search {
    display: flex;
    align-items: center;
    padding-left: 5px;

    .form-control {
      width: 185px;
      margin-left: 10px;
    }
  }

  &-content {
    margin: 21px 0 2px 0;
    height: 378px;
    scrollbar-width: 12px;
    overflow-y: auto;

    @include media-breakpoint(lg) {
      scrollbar-width: 8px;
    }

    @include media-breakpoint(md) {
      scrollbar-width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-black, 0.12);
    }

    &::-webkit-scrollbar {
      width: 12px;

      @include media-breakpoint(lg) {
        width: 8px;
      }

      @include media-breakpoint(md) {
        width: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      width: 8px;
      border-radius: 10px;
      background-color: rgba($color-black, 0.23);
      border: 2px solid rgba($modal-bg, 0.4);

      @include media-breakpoint(lg) {
        width: 6px;
        border: 1px solid rgba($color-blue-100, 0.4);
      }

      @include media-breakpoint(md) {
        width: 4px;
      }
    }
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
    }
    .loading {
      margin: 0;
      position: absolute;
      top: 60%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba($color-gray-800, 0.38);
    padding: 10px 20px;
    padding-right: 9px;

    &.darker {
      background-color: rgba($color-brown, 0.38);
    }

    h3 {
      font-size: 18px;
      color: $color-white;
      line-height: 22px;
      font-weight: 400;
    }
  }

  &-submitted {
    display: block;
    background-color: rgba($color-red, 0.29);
    border-radius: $border-radius-md;
    text-decoration: none;
    font-size: 16px;
    color: $color-white;
    font-weight: bold;
    line-height: 20px;
    padding: 1px 10px;
    margin-bottom: 0;
    margin-right: 26px;
  }
}

//Student CUnENroll
.edit__student-copy-unenroll {
  &-checks {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 10px;

    p {
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  &-search {
    display: flex;
    align-items: center;
    padding-left: 5px;

    .form-control {
      width: 185px;
      margin-left: 10px;
    }
  }

  &-content {
    margin: 10px 0 2px 0;
    scrollbar-width: 12px;
    overflow-y: auto;

    @include media-breakpoint(lg) {
      scrollbar-width: 8px;
    }

    @include media-breakpoint(md) {
      scrollbar-width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($color-black, 0.12);
    }

    &::-webkit-scrollbar {
      width: 12px;

      @include media-breakpoint(lg) {
        width: 8px;
      }

      @include media-breakpoint(md) {
        width: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      width: 8px;
      border-radius: 10px;
      background-color: rgba($color-black, 0.23);
      border: 2px solid rgba($modal-bg, 0.4);

      @include media-breakpoint(lg) {
        width: 6px;
        border: 1px solid rgba($color-blue-100, 0.4);
      }

      @include media-breakpoint(md) {
        width: 4px;
      }
    }
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
    }
    .loading {
      margin: 0;
      position: absolute;
      top: 60%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba($color-gray-800, 0.38);
    padding: 10px 20px;
    padding-right: 9px;

    &.darker {
      background-color: rgba($color-brown, 0.38);
    }

    h3 {
      font-size: 16px;
      color: $color-white;
      line-height: 22px;
      font-weight: 400;
    }
  }

  &-submitted {
    display: block;
    background-color: rgba($color-yellow, 1);
    border-radius: $border-radius-md;
    text-decoration: none;
    font-size: 14px;
    color: $color-black;
    font-weight: bold;
    line-height: 20px;
    padding: 1px 8px;
    margin-bottom: 0;
    margin-right: 5px;
  }

  &-corners {
    border-radius: $border-radius-md;
  }
}

// Black Progress modal content
.black__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 25px 7px 25px;

  @media only screen and (max-width: 852.99px) {
    padding: 12px 0 7px 0;
  }

  &-icon {
    max-width: 20px;
    height: auto;
    margin-right: 0px;
  }

  &-list {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    width: 750px;
    padding: 0;
    margin: 0 auto;

    @media only screen and (max-width: 800px) {
      width: 726px;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      .active {
        border: 1px solid $color-white;
      }

      #monitorShowProgressModal,
      span {
        margin-top: 3px;
        margin-bottom: 3px;
        display: block;
        text-decoration: none;
        width: 24px;
        height: 24px;
        text-align: center;
        font-size: 14px;
        color: $color-white;
        line-height: 1;
        padding: 5px 0;

        @media only screen and (max-width: 800px) {
          width: 23px;
          height: 23px;
        }
      }

      a {
        background-color: $color-green;
        margin: 3px;
      }

      span {
        margin: 0 3px;
      }
    }
  }
}
.MonitorProgress {
  margin-top: 4px;
}

.turnMonitor {
  font-weight: 400 !important;
  font-size: 12px;
  max-width: 400px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-shadow: none !important;

  @media (max-width: 1240px) {
    font-size: 10px !important;
  }
}

.turnMonitorContainer {
  display: flex;
  position: relative;
  top: -1px;

  svg {
    color: $color-gray-900 !important;
  }

  p {
    color: $color-gray-900;
  }
}

.notAvailable {
  font-size: 16px !important;
  font-weight: 400 !important;
  text-shadow: none !important;
  padding-left: 1px;
}

.summative-table-container {
  width: 100%;
  .table-responsive {
    height: 100%;

    @media (min-width: 1281px) {
      overflow-x: inherit;
      overflow-y: inherit !important;
    }
  }

  table {
    height: 100%;
  }
  // table {
  //   thead {
  //     th:first-child {
  //       padding-left: 0;
  //       position: relative;
  //       left: -20px;
  //     }
  //   }
  // }
}

.summative-table-container {
  .notAvailable {
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fomative-assessment-table {
  table {
    thead {
      th:first-child {
        position: unset;
        padding-left: 20px;
      }
    }
  }
}

@-moz-document url-prefix() {
  @media print {
    @page {
      scale: 65%;
    }
  }
}

@media print {
  .remediation-report {
    width: 100%;
    max-width: 1240px;
    font-family: $font-nunito;

    .remediate-header {
      padding: 7.5px 0px;
      border-bottom: 1px solid $theme-border-color;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .remediate-header-title {
        width: 55%;

        .remediate-title {
          font-size: 22px;
          font-weight: 500;
          line-height: 26px;
          color: #000000;
          font-family: $font-openSans;
          margin-bottom: 2px;
        }

        .remediate-desc {
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          color: #000000;
          font-family: $font-openSans;
          margin-bottom: 0px;
        }

        .remediate-date {
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          color: #000000;
          font-family: $font-openSans;
          margin-bottom: 2px;
          margin-left: auto;
        }
      }

      .remediate-status-date {
        width: 45%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: 45px;

        .remediate-status {
          display: flex;
          align-items: center;

          .remediate-key-label {
            width: 50px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 6px;
            background-color: #3c4046;
            border-radius: 40px;
            font-size: 14px;
            font-weight: 700;
            color: white;
            position: relative;
            top: -12px;
          }

          .remediate-yellow {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: #eadb4a;
            margin-left: 10px;
          }

          .remediate-progress {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #000000;
            margin-left: 9px;
            margin-bottom: 0px;
            padding-top: 2px;
          }

          .remediate-date-label {
            width: 48px;
            height: 23px;
            border-radius: 80px;
            border: 1px solid #424242;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 3px;
            padding-top: 3px;
            font-size: 14px;
            font-weight: 400;
            color: #424242;
            line-height: 19px;
            margin-left: 14px;
          }
        }

        .remediate-date-name {
          .remediate-class {
            font-size: 22px;
            font-weight: 500;
            line-height: 22px;
            color: #000000;
            font-family: $font-openSans;
            margin-bottom: 2px;
            text-align: right;
          }

          .remediate-stu-name {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            color: #000000;
            font-family: $font-openSans;
            margin-bottom: 0px;
            text-align: right;
          }
        }
      }
    }

    .proficient-perc-label {
      margin-bottom: 16px;
      border: 1px solid #424242;
      border-radius: 40px;
      padding: 6px 13px 4px 15px;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: #3e3e3e;
      display: inline-flex;
      margin-left: 300px;
      margin-top: 39px;
    }

    .remediate-table {
      width: 100%;
      max-width: 850px;
      margin: 0 auto;
      margin-top: 0px;

      .remediate-th {
        font-size: 16px;
        font-weight: 700;
        color: #000000;
        font-family: $font-nunito;
      }

      .remediate-fst-column {
        width: 250px;
        text-align: right;
        padding-right: 45px;
      }

      .remediate-scnd-column {
        width: 160px;
        text-align: center;
      }

      .remediate-thrd-column {
        width: 160px;
        text-align: center;
      }

      .remediate-td {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        color: #101010;
        padding-top: 20px;
      }

      .remediate-td-disabled {
        opacity: 0.4;
      }

      .remediate-scnd-column {
        .remediate-attempt-score {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .remediate-perc {
            font-weight: 400;
          }

          .remediate-score {
            position: absolute;
            left: 110px;
            border: 1px solid #424242;
            padding: 3px 9px 1px;
            border-radius: 80px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #424242;
          }

          .remediate-yellow {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: #eadb4a;
          }
        }
      }
    }
  }
}

.search-box {
  @include flex-box(flex-start, center);
  gap: 10px;

  input {
    width: 185px;
    height: 26px;
    border: 1px solid #f1f1f1;
    border-radius: $border-radius-md;
    font-size: $sm;
    font-weight: 400;
    color: $color-gray-650;
    padding-left: 12px;
  }
}
.btn {
  &:focus-visible {
    outline: 1px solid black !important;
  }
}