.cmsFooter{
    position: fixed;
	bottom: 0;
    left: 0;
	background-color: $color-light-100;
    width: 100%;
	height: 59px;
	z-index: 120;

    &-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        @include media-breakpoint(xl) {
            padding-left: 10px;
        }
    }

    &__search{
        display: flex;
        align-items: center;
        gap: 10px;
        height: 61px;
        padding: 3px 0 4px 38px;

        @include media-breakpoint(xxl) {
            padding-left: 16px;
        }

        @include media-breakpoint(xl) {
            padding-left: 0;
        }

        &-icon{
            cursor: pointer;
        }

        &-text{
            font-size: 19px;
            color: $color-gray-900;
            line-height: 20px;
            padding-left: 19px;

            @include media-breakpoint(xl) {
                padding-left: 16px;
            }

            @include media-breakpoint(lg) {
                font-size: 18px;
            }
        }
        
        .edit-options-tooltip {
            display: flex;
            align-items: center;
            position: absolute;
            top: -65px;
            left: 20px;
            background-color: #eeeeee;
            border: 2px solid #888888;
            border-radius: 10px;
            padding: 0px 20px 0px 5px;

            button {
                padding: 0;
                background-color: unset;
                font-size: 18px;
                font-family: $font-openSans;
                border: 0;
                color: #888888;
                white-space: nowrap;
            }

            .closeTooltipBtn {
                position: absolute;
                top: -16px;
                right: -14px;
                width: 28px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &__edit{
        display: flex;
        align-items: center;
        padding-left: 16px;

        &-icon{
            opacity: .26;
            margin-right: 14px;
        }
    }


    &__tools {
        @include flexcenter-both;
        text-align: center;
        border-radius: $border-radius-md;
        width: 54px;
        height: 53px;

        &-border {
            background-color: $color-light-400;
            box-shadow: inset 1px 1px 2px rgba($color-black, .16);
        }	
    }

    .cmsFooter__tools:nth-child(1) {
        margin-right: 0 !important;
    }
    
    &__buttons{
        background-color: $color-yellow;

        &-wrapper{
            display: flex;
            padding: 14px 13px;

            .btn:not(:last-child){
                margin-right: 8px;
            }

            .show-hide-btn {
                display: flex;
                justify-content: center;
                width: 130px;
                padding-right: 0;
                padding-left: 0;
            }

            .btn {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
}
.footer-size {
    @include flexcenter-both;
    text-align: center;
    border-radius: $border-radius-md;
    width: 54px;
    height: 53px;
}

.footer__content-copyright{
    display: block !important;
}

@media (max-width:575px){
    .cmsFooter{
        .pg-container{
            .cmsFooter-wrapper{
                // margin-top: -9px;
                .cmsFooter__buttons-wrapper{
                    padding: 14px 8px;
                    .show-hide-btn{
                        width: 120px;
                        // margin-right: 4px;
                        margin-right: 8px;
                        @media (max-width:374px){
                            margin-right: 4px;
                        }
                    }
                    .cancel-btn {
                        margin-right: 8px;
                        padding-right: 8px;
                        padding-left: 8px;
                        @media (max-width:374px){
                            margin-right: 4px;
                            padding-right: 6px;
                            padding-left: 6px;
                        }
                    }
                }
                @media (max-width:374px){
                    .cmsFooter__search{
                        gap: 4px;
                        .cmsFooter__tools {
                            width: 39px;
                        }
                    }
                }
            }
    
        }
    }
}

@media print {
	.footer{
		display: none;
	}

	.footer__content{
		box-shadow: none;
	}
    .footer__content-copyright{
        display: block;
    }
}
