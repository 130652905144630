.stl{
    &__dropdown{
        &-container{
            cursor: pointer;
            border: 1px solid $color-white;
            padding: 4px 10px;
            width: 210px;
            border-radius: $border-radius-md;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
        }

        &-placeholder{
            color: $color-white;
        }
    }

    &__menu {
        width: 210px;
        height: auto;
        background-color: $color-white;
        border-radius: 2px;
        position: absolute;
        z-index: 999;
        border-radius: $border-radius-md;
        margin-top: 5px;

        &__list {
            list-style: none;
            padding: 4px 0px;
            margin-bottom: 0px;
        }

        .listItem {
            padding: 8px 12px;
            color: $color-blue-200;

            &:hover {
                background-color: $light-blue-background ;
                cursor: pointer;
            }
        }
    }
}