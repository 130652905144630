.footer {
  position: absolute;
  max-width: 1240px;
  margin: auto;
  margin-bottom: 0;
  margin-top: 98px;
  z-index: 9;
  bottom: 0;

  @media (max-width: 1265px) {
    padding-right: 12px;
  }

  @media (max-width: 768px) {
    position: relative;
    padding-right: 0;
  }

  @media (max-width: 600px) {
    margin-top: 40px;
  }

  &.login-page {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 600px) {
      position: unset;
    }
  }

  
  &__content {
    background-color: $color-gray-500;
    border-radius: 8px 8px 0 0;
    font-size: 12px;
    color: $color-black;
    line-height: 20px;
    padding: 12px 36px;
    box-shadow: $section-shadow;

    @include media-breakpoint(xl) {
      padding: 12px 16px;
    }


    &.login-page-footer {
      background-color: rgba($color-gray-500, 0.77);
    }

    &-copy {
      position: relative;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 2px;
    }

    &-copyright {
      position: relative;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 2px;

      @include safari-suport {
        font-weight: 500;
      }

      @include mozilla-suport {
        font-size: 10px;
        line-height: 16px;
      }

      span {
        display: inline-block;
      }

      .benchmark {
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: left;
        letter-spacing: unset;
        font-size: 16px;
        color: $color-gray-850;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 2px;

        @include mozilla-suport {
          font-size: 10px;
          line-height: 1.5;
          margin-bottom: 0;
        }
      }
    }

    &-description {
      text-align: center;
      color: $color-black;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 0;

      a {
        font-weight: bold;
        color: $color-black;
        transition: all .3s ease;

        &:hover {
          color: darken($color-black, 20%);
        }

        &:focus-visible {
          border: 1px solid black;
        }
      }
    }

    .toolTip-bg {
      border-radius: 8px 8px 0px 0px;
    }
  }
}

.cmsAssessmentFooter {

  button {
    border: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }
    
  .success-btn {
    padding-left: 19px;
    padding-right: 19px;
  }
}
