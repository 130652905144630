.transfer {
    &__header {
        display: flex;
        padding: 8px 2px 5px 2px;

        &-name {
            width: 271px;
        }

        &-select {
            flex: 1;
            padding: 0 23px;

            @include media-breakpoint(md) {
                padding: 0 15px;
            }
        }

        &-text {
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            text-shadow: $text-shadow;
            margin-bottom: 0;

            @include media-breakpoint(md) {
                font-size: 14px;
            }
        }
    }

    &__body {
        @include media-breakpoint(md) {
            overflow-x: auto;
        }

        &-container {
            @include media-breakpoint(md) {
                width: 700px;
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        background-color: rgba(#646464, .38);
        border-radius: $border-radius-md;
        padding: 4px 16px;

        @include media-breakpoint(md) {
            padding: 4px 8px;
        }

        &:nth-child(even) {
            background-color: rgba($color-gray-800, .38);
        }

        &.radius {
            border-radius: 0;
        }

        &-wrapper {
            border-radius: $border-radius-md;
            overflow: hidden;
        }

        &-name {
            width: 255px;

            span {
                display: block;
                overflow: hidden;
            }
        }

        &-select {
            width: 296px;
            padding: 0 23px;

            @include media-breakpoint(md) {
                padding: 0 15px;
            }
        }

        &-label {
            flex: 1;
        }

        &-pill {
            display: inline-block;
            background-color: $color-yellow;
            border-radius: $border-radius-md;
            font-size: 12px;
            color: $color-black;
            font-weight: bold;
            line-height: 20px;
            padding: 0 8px;

            @include safari-suport {
                line-height: 16px;
                padding-top: 1px;
                padding-bottom: 4px;
            }
        }

        &-text {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 0;

            @include media-breakpoint(md) {
                font-size: 14px;
            }

            &.animated {
                position: relative;
                left: 0;
                width: 100%;
                text-align: right;
                @include ellipsis;
                animation: ellipsis-animation 10000ms ease;
                animation-delay: 3s;
                transition: left 3s linear, width 3s linear;
                cursor: pointer;

                &:hover {
                    left: -100%;
                    width: 200%;
                }
            }

            @include safari-suport {
                transform: translateY(-1px);
            }
        }
    }

    &-select {
        -webkit-appearance: none;
        appearance: none;
        background-image: url('../../../../assets/img/select-icon-lg.svg');
        background-repeat: no-repeat;
        background-position: center right 12px;
        max-width: 340px;
        font-size: 18px;
        color: $color-blue-200;
        line-height: 23px;
        padding: 4px 25px 4px 12px !important;
        border: 1px solid $color-blue-200;

        @include safari-suport {
            padding: 3px 25px 6px 12px !important;
            line-height: 22px;
        }

        &:disabled {
            background-color: #e9ecef;
            opacity: .36;
        }
    }
}
