// Nunito fonts
@import url("https://p.typekit.net/p.css?s=1&k=rot5qrh&ht=tk&f=15811.15812.15813.37499.41767.41769&a=2647286&app=typekit&e=css");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "nunito-for-safari";
  src: url("https://use.typekit.net/af/8a163f/00000000000000007735a0ed/30/l?subset_id=2&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8a163f/00000000000000007735a0ed/30/d?subset_id=2&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8a163f/00000000000000007735a0ed/30/a?subset_id=2&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "nunito-for-safari";
  src: url("https://use.typekit.net/af/2782c2/00000000000000007735a0e9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2782c2/00000000000000007735a0e9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2782c2/00000000000000007735a0e9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "nunito-for-safari";
  src: url("https://use.typekit.net/af/898fcc/00000000000000007735a106/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/898fcc/00000000000000007735a106/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/898fcc/00000000000000007735a106/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "nunito-for-safari";
  src: url("https://use.typekit.net/af/5ec933/00000000000000007735a0f3/30/l?subset_id=2&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5ec933/00000000000000007735a0f3/30/d?subset_id=2&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5ec933/00000000000000007735a0f3/30/a?subset_id=2&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "nunito-for-safari";
  src: url("https://use.typekit.net/af/d13228/00000000000000007735a0e7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d13228/00000000000000007735a0e7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d13228/00000000000000007735a0e7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "nunito-for-safari";
  src: url("https://use.typekit.net/af/f48a1b/00000000000000007735a10a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f48a1b/00000000000000007735a10a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f48a1b/00000000000000007735a10a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

// Roboto fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500&display=swap");

// Cabin fonts
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;600;700&display=swap');

// Arimo font
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap');

// Open sans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// Fonts
$font-nunito: "Nunito", sans-serif;
$font-roboto: "Roboto", sans-serif;
$font-cabin: 'Cabin', sans-serif;
$font-arimo: 'Arimo', sans-serif;
$font-openSans: "Open Sans", sans-serif;

h2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 43px;
  margin-bottom: 0;

  @include media-breakpoint(md) {
    font-size: 26px;
    line-height: 34px;
  }
}

h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 0;

  @include media-breakpoint(md) {
    font-size: 20px;
    line-height: 27px;
  }
}

h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;

  @include media-breakpoint(md) {
    font-size: 16px;
    line-height: 22px;
  }
  
}

h5 {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 0;

  @include media-breakpoint(md) {
    font-size: 14px;
    line-height: 20px;
  }
}

p {
  color: $color-white;
  line-height: 22px;

  @include media-breakpoint(md) {
    font-size: 14px;
    line-height: 20px;
  }
}

.font-12 {
  font-size: 12px !important;
}
