.react-tabs {
    min-height: 100%;
    -webkit-tap-highlight-color: transparent;
    
    &__tab-list {
        display: flex;
        font-family: $font-nunito;
        width: 100%;
        max-width: 564px;
        float: right;
        margin-bottom: 0px;
        padding-left: 0px;
    }
  
    &__tab {
        background-color:$color-light-100;
        width: 50%;
        height: 60px;
        font-size: 24px;
        line-height: 20px;
        color: $color-black;
        display: flex;
        justify-content: center;
        align-items: center;
        border: unset;
        cursor: pointer;
        position: relative;
    
        &--selected {
            background: $color-green-100;
        }
    
        &--disabled {
            color: $color-gray-100;
            cursor: default;
        }
    
        &:focus {
            outline: none;
    
            &:after {
            content: "";
            position: absolute;
            height: 5px;
            left: -4px;
            right: -4px;
            bottom: -5px;
            background: $color-white;
            }
        }
    }
  
    &__tab-panel {
      display: none;
  
        &--selected {
            display: block;
        }
    }

    &-addtab {
        background-color: $color-blue-100;
        padding: 6px 16px;
        color: $color-white;
        font-size: 16px;
        border-radius: $border-radius-md;
        border: none;
        padding-top: 8px;
    }
}

.object-tabs {
    .react-tabs {
        &__tab-list {
            position: relative;
            float: none;
            background-color: $color-gray-800;
            width: 211px;
            height: 39px;
            padding: 3px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 40px;
            margin: 0 auto 30px;
        }

        &__radio{
            display: none;
        }
        
        input[id="objective-radio"] {
            &:checked {
                & ~ .react-tabs__slider{
                    width: 122px;
                    transform: translateX(82px);
                }
            }
        }

        &__button{
            @include flexcenter-both;
            height: 33px;
            font-size: 16px;
            color: $color-white;
            line-height: 20px;
            border-radius: 40px;
            padding: 6px 16px;
            transition: color 0.15s ease-in;
            cursor: pointer;
            z-index: 2;
        }

        &__slider{
            position: absolute;
            display: flex;
            height: 33px;
            width: 81px;
            background: $theme-bg-color-dark;
            transition: all 0.4s;
            border-radius: 40px;
            box-shadow: 1px 1px 3px rgba($color-black, 0.52);
            z-index: 1;
        }

        &__tab {
            background-color: transparent;
            font-size: 18px;
            height: 33px;
            width: auto;

            &::after{
                display: none;
            }

            &--selected span{
                font-size: 18px;
                padding: 6px 13px;
            }
        }
    }
}

.already {
    padding: 9px 22px;
    height: 42px;
    border-radius: $border-radius-md;
    background-color: $color-gray-650;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    div {
        display: flex;
        align-items: center;

        span {
            font-size: 18px;
            line-height: 20px;
            color: $color-white;
        }
    }
    .circle {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: $color-orange-100;
        display: inline-block;
        margin-right: 7px;
    }

    svg {
        fill: $color-gray-350;
    }
}
  