.results {
  padding: 22px;
  padding-bottom: 27px;

  &.add-radius {
    border-radius: 8px;
  }

  @include media-breakpoint(lg) {
    padding: 15px;
    padding-bottom: 20px;
  }

  &-content {
    background-color: $theme-bg-color-lighter-variant2;
    @include section;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    background-color: $color-gray-900;
    border-bottom: 1px solid $color-gray-900;
    padding: 8px 20px;

    @include media-breakpoint(xl) {
      padding: 8px 18px;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-right: 9px;
    }

    &-select {
      display: flex;
      align-items: center;

      select {
        background-position: right 3px center;
        margin: 0 10px;
        height: 26px;
        line-height: 1;
        padding: 0 17px 0 5px;
      }
    }
  }

  &__empty {
    @include flexcenter-both;
    width: 100%;
    height: 139px;

    &-paragraph {
      font-weight: bold;
      margin-bottom: 0;
      text-shadow: $text-shadow;
    }
  }

  &__subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-500;
    padding: 4px 20px;

    @include safari-suport {
      padding-top: 3px;
      padding-bottom: 5px;
    }

    &-heading {
      display: flex;
      align-items: center;
    }

    &-title {
      font-size: 16px;
      color: $color-black;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 0;
    }

    img {
      max-width: 16px;
      height: auto;
      margin-left: 8px;
    }

    &-error {
      color: $color-orange-100;
      font-weight: bold;
      text-shadow: $text-shadow;
      margin-bottom: 0;
    }
  }

  &__nested-container {
    display: flex;
    justify-content: flex-end;
  }

  &__nested-table {
    max-width: 974px;
    width: 100%;

    @media only screen and (max-width: 1059px) {
      max-width: 100%;
    }
  }
}

// Results Table
.results__table {
  &-row {
    th {
      padding: 4px 0;
      line-height: 21px;
      font-weight: bold;
      text-shadow: $text-shadow;
      margin-bottom: 0;

      @include safari-suport {
        padding-top: 2px;
        padding-bottom: 6px;
      }
    }

    td {
      padding-top: 9px;
      padding-bottom: 9px;

      @include safari-suport {
        padding-top: 6px;
        padding-bottom: 12px;
      }
    }

    &.blue-row {
      background-color: $theme-bg-color-dark-variant3;
    }

    .name-data {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 25px;
    }

    .subject-data {
      padding-left: 27px;
    }
    .firstName-data {
      padding-left: 31px;
    }
  }

  &-name {
    width: 252px;
    text-align: right;
    padding-right: 25px !important;

    @include media-breakpoint(xl) {
      min-width: 240px;
    }
  }

  &-grade {
    width: 53px;
    min-width: 53px;
    text-align: center;
  }

  &-subject {
    width: 114px;
    min-width: 114px;
    padding-left: 27px !important;
  }

  &-teacher {
    width: 96px;
    min-width: 96px;
    text-align: right;
  }

  &-firstName {
    width: 143px;
    min-width: 143px;
    padding-left: 30px !important;
  }

  &-email {
    width: 239px;
    min-width: 239px;
  }

  &-options {
    min-width: 90px;
    width: 90px;
  }

  &-error {
    min-width: 110px;
  }
}

// Nested Table
.nested__table {
  &-row {
    &.blue-row {
      // background-color: $color-blue-600;
      background-color: $theme-bg-color-dark-variant3;
    }

    &.rounded {
      border-top-left-radius: 8px;
    }

    th {
      font-size: 14px;
      font-weight: bold;
      text-shadow: $text-shadow;
      margin-bottom: 0;
      padding-top: 3px;
      padding-bottom: 3px;

      @include safari-suport {
        padding-top: 2px;
        padding-bottom: 4px;
      }
    }

    td {
      padding-top: 6px;
      padding-bottom: 6px;

      @include safari-suport {
        padding-top: 4px;
        padding-bottom: 8px;
      }
    }

    .firstName-data {
      padding-left: 18px;
    }

    .admin-data {
      padding-right: 32px;
    }
  }

  &-count {
    background-color: $color-gray-900;
    width: 38px;
    min-width: 38px;
    text-align: center;
    border-top-left-radius: 8px;
    text-shadow: none !important;

    @media only screen and (max-width: 1059px) {
      border-top-left-radius: 0;
    }
  }

  &-student {
    width: 108px;
    min-width: 108px;
    text-align: right;
  }

  &-firstName {
    width: 130px;
    min-width: 130px;
    padding-left: 18px !important;
  }

  &-id {
    width: 126px;
    min-width: 126px;
  }

  &-notes {
    width: 242px;
    min-width: 242px;
  }

  &-options {
    width: 97px;
    min-width: 97px;
  }

  &-admin {
    width: 178px;
    min-width: 178px;
    text-align: right;
    padding-right: 32px !important;
    border-top-left-radius: 8px;
    overflow: hidden;

    @include media-breakpoint(xl) {
      padding-right: 30px !important;
    }

    @media only screen and (max-width: 1059px) {
      border-top-left-radius: 0;
    }
  }

  &-name {
    width: 102px;
    min-width: 102px;
  }

  &-email {
    min-width: 275px;
    width: 275px;
  }

  &-principle {
    width: 107px;
    min-width: 107px;
  }
  &-superPrinciple {
    width: 134px;
    min-width: 134px;
  }

  &-error {
    min-width: 186px;
  }
}

// Test Reslut Table
.testResult__table {
  &-row {
    &.blue-row {
      // background-color: $color-blue-600;
      background-color: $theme-bg-color-dark-variant3;
    }

    th {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      text-shadow: $text-shadow;
      margin-bottom: 0;
      padding-top: 5px;
      padding-bottom: 5px;

      @include media-breakpoint(md) {
        font-size: 14px;
      }

      @include safari-suport {
        padding-top: 4px;
        padding-bottom: 6px;
      }
    }

    td {
      padding-top: 7px;
      padding-bottom: 7px;

      @include safari-suport {
        padding-top: 6px;
        padding-bottom: 8px;
      }
    }

    .name-data {
      text-align: right;
      padding-right: 54px;

      @include media-breakpoint(xl) {
        padding-right: 30px;
      }
    }
  }

  &-name {
    width: 277px;
    text-align: right;
    padding-right: 54px !important;

    @include media-breakpoint(xl) {
      min-width: 260px;
      padding-right: 30px !important;
    }
  }

  &-option {
    width: 97px;
    min-width: 97px;
  }

  &-error {
    min-width: 240px;
  }
}
