.adminProfessional {
  .pdAdminBox {
    position: relative;
    left: -17px;
  }
  .react-tabs {
    &__tab {
      background-color: unset;
      width: unset;
      height: 48px;

      &::after {
        display: none;
      }

      &:focus-visible {

        &::after {
          display: block;
        }
      }

      @media (max-width: 576px) {
        width: 100%;
        justify-content: flex-start;
      }
    }
    .react-tabs__tab-list {
      max-width: unset;
      li:nth-child(1) {
        div {
          padding-right: 17px;
          p {
            margin-left: 17px !important;
          }
        }
      }
      li:nth-child(2) {
        div {
          padding-left: 21px;
          padding-right: 17px;
          p {
            margin-left: 14px !important;
          }

          span {
            width: 29px;
          }
        }
      }
      li:nth-child(3) {
        div {
          padding-left: 19px;
          p {
            margin-left: 17px !important;
          }
        }
      }
      li:nth-child(4) {
        div {
          p {
            margin-left: 17px !important;
          }
        }
      }
      li:nth-child(5) {
        div {
          p {
            margin-left: 18px !important;
          }
        }
      }
      li:nth-child(6) {
        div {
          p {
            margin-left: 18.5px !important;
          }
        }
      }
      @media (max-width: 992px) {
        flex-wrap: wrap;
      }
      @media (max-width: 576px) {
        flex-wrap: nowrap;
        flex-direction: column;
        padding-left: 20px;
      }
    }
  }

  &__container {
    margin-top: 26px;
    // box-shadow: 0 3px 12px rgb(0 0 0 / 22%);

    &__header {
      background-color: #424242;
      padding: 14px 33px 22px 33px;
      border-radius: 8px 8px 0px 0px;

      @media (max-width: 1023px) {
        padding-bottom: 80px;
      }

      @media (max-width: 767px) {
        padding: 14px 20px 80px 20px;
      }

      @media (max-width: 575px) {
        padding-bottom: 150px;
      }

      &.resources-rounded-border {
        border-radius: $border-radius-md;
      }

      &.pacing-rounded-border {
        border-radius: $border-radius-md;
      }

      .pd-admin-heading {
        margin-bottom: 0;
        padding-left: 0;
      }

      &__backAdmin {
        padding: 0;

        &__back {
          height: auto;

          @media (max-width: 767px) {
            width: 36px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 22px;
          font-weight: 500;
          line-height: 29px;
          color: white;
          margin-bottom: 0;
          font-family: $font-openSans;

          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }

      &__notchDropDown {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 339px;
        margin: 0 auto;
        background-color: #b9b9b9;
        border-radius: 40px;
        position: absolute;
        top: 0;
        left: 6px;
        right: 0;
        padding: 16px 15px;

        @media (max-width: 1023px) {
          max-width: 285px;
          padding: 5px 10px;
        }

        @media (max-width: 767px) {
          position: unset;
          max-width: 100%;
        }

        button:disabled {
          opacity: 0.4;
        }

        &__arrowLeft {
          width: 15px;
          margin-right: 11px;
          border: 0;
          padding: 0;
          background-color: unset;
          padding-top: 0px;

          @media (max-width: 1023px) {
            width: 20px;
          }

          img {
            width: 100%;
          }
        }

        &__arrowRight {
          width: 15px;
          margin-left: 11px;
          border: 0;
          padding: 0;
          background-color: unset;

          @media (max-width: 1023px) {
            width: 20px;
          }

          img {
            width: 100%;
          }
        }

        &__dropDown {
          width: 173px;
          height: 33px;

          @media (max-width: 767px) {
            width: 100%;
          }

          select {
            width: 100%;
            height: 100%;
            border-radius: $border-radius-md;
            border: 1px solid #4788ff;
            font-size: 15px;
            font-weight: 400;
            color: #4788ff;
            padding: 5px 33px 5px 12px;
            outline: none;
            background-color: white;
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
          }

          .dropDownArrow {
            position: absolute;
            top: 14px;
            right: 15px;
            width: 14px;
            pointer-events: none;
          }
        }
      }

      &__tabs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 21px;
        position: relative;
        left: -18px;

        @media (max-width: 767px) {
          display: block;
        }

        div {
          padding: 12px 23px 13px 21px;
          padding-left: 18px;
          padding-right: 17px;
          border-radius: 40px;
          cursor: pointer;
          height: 48px;

          @media (max-width: 1280px) {
            padding: 13px 15px;
          }

          span {
            width: auto;

            img {
              width: 100%;
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: white;
            margin-bottom: 0;
          }
        }

        .active {
          background-color: #b9b9b9;
          border-radius: 40px;
          &:focus {
            &::after {
              background-color: unset;
            }
          }

          p {
            color: #424242;
          }

          img {
            filter: brightness(0.2);
          }
        }

        .disabled {
          img {
            filter: brightness(0.4);
          }

          p {
            color: #fff;
            opacity: 0.5;
          }
        }
      }

      &__desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #b9b9b9;
        width: 100%;
        max-width: 34.8%;
        margin-top: 20.5px;
        margin-bottom: 0px;

        @media (max-width: 1120px) {
          max-width: 32.8%;
        }

        @media (max-width: 1023px) {
          max-width: 100%;
        }

        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 18px;
        }

        @media (max-width: 576px) {
          padding-left: 0px;
        }
      }
    }

    .resources-no-padding {
      @media (max-width: 575px) {
        padding-bottom: 40px;
      }
    }

    &__body {
      &__monthDropDown {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-gray-500;
        border-radius: 40px 40px 0px 0px;
        width: 100%;
        max-width: 339px;
        margin: 0 auto;
        position: absolute;
        left: 6px;
        right: 0;
        top: -68px;
        padding: 17.5px 15px;

        @media (max-width: 1023px) {
          left: -50px;
          top: -68px;
          max-width: 285px;
        }

        @media (max-width: 991px) {
          right: auto;
          left: 0;
          top: -53px;
          padding: 10px 15px;

          &.border-rounded-month-dropdown {
            border-bottom-left-radius: $border-radius-md;
          }
        }

        @media (max-width: 575px) {
          max-width: 100%;
          border-radius: 0;
          padding: 12px 20px;
          padding-right: 50px;
          top: -57px;
          left: 0;
          right: 0;

          &.border-rounded-month-dropdown {
            border-bottom-left-radius: $border-radius-md;
            border-bottom-right-radius: $border-radius-md
          }
        }

        // button:disabled {
        //     opacity: 0.4;
        // }

        &__arrowLeft {
          width: 26px;
          margin-right: 16px;
          border: 0;
          padding: 0;
          background-color: unset;

          @media (max-width: 767px) {
            width: 15px;
          }

          img {
            width: 100%;
          }
        }

        &__arrowRight {
          width: 26px;
          margin-left: 16px;
          border: 0;
          padding: 0;
          background-color: unset;

          @media (max-width: 767px) {
            width: 15px;
          }

          img {
            width: 100%;
          }
        }

        &__dropDown {
          width: 173px;
          height: 33px;

          @media (max-width: 767px) {
            width: 100%;
          }

          select {
            width: 100%;
            height: 100%;
            border-radius: $border-radius-md;
            border: 1px solid #4788ff;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #4788ff;
            padding: 5px 33px 5px 12px;
            outline: none;
          }

          select:disabled {
            opacity: 0.6;
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
          }

          .dropDownArrow {
            position: absolute;
            top: 14px;
            right: 15px;
            width: 14px;
            pointer-events: none;
          }
        }

        .delDataBtn {
          position: absolute;
          top: 23px;
          right: -37px;

          .deleteIcon {
            // filter: brightness(0) invert(1);
            height: 21px;
            width: 19px;
          }

          .allDataDel {
            height: 22px;
            width: 18px;
            position: relative;
          }

          .disabled {
            opacity: 0.4;
          }

          @media (max-width: 575px) {
            top: 14px;
            right: 13px;
          }
        }
      }

      &__searchSchedule {
        display: flex;
        justify-content: flex-end;
        background-color: $color-gray-500;
        border-radius: 40px 0px 0px 0px;
        width: 100%;
        max-width: 278px;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: -68px;
        padding: 23px 26px 19px 14px;

        &.border-rounded-pacing-search {
          border-bottom-right-radius: $border-radius-md;
        }

        @media (max-width: 1023px) {
          max-width: 230px;
          top: -62px;
          padding: 18px 15px;
        }

        @media (max-width: 911px) {
          padding: 13.5px 20px;
          top: -53px;
        }

        @media (max-width: 575px) {
          max-width: 100%;
          top: -113px;
          border-radius: 0;
          justify-content: flex-start;
          padding: 12px 20px;
          align-items: center;

          &.border-rounded-pacing-search {
            border-bottom-right-radius: 0;
          }
        }

        &__search {
          width: 16px;
          height: auto;
          margin-right: 10px;

          @media (max-width: 575px) {
            margin-top: -5px;
            display: inline-block;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__input {
          width: 100%;
          max-width: 185px;
          height: 26px;

          @media (max-width: 575px) {
            max-width: 90%;
            height: 33px;
          }

          input {
            width: 100%;
            height: 100%;
            border-radius: $border-radius-md;
            padding: 4px 12px 3px;
            font-size: 14px;
            font-weight: 400;
            color: #6e6e6e;
            border: 0;
          }
        }
      }
    }

    &__thisWeek {
      overflow: hidden;

      &__boxes {
        width: 100%;
        overflow: hidden;

        &:first-child {
          .adminProfessional__container__thisWeek__boxes__contentHeader {
            border-radius: 0px 0px 8px 8px;
          }
        }

        &__contentHeader {
          padding: 6px 28px 5px;
          background-color: $theme-bg-color-dark-variant2;
          height: 33px;
          border-radius: 40px;

          @include media-breakpoint(sm) {
            justify-content: space-between !important;
            padding: 4px 10px 3px;
          }

          p {
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            color: white;
            margin-bottom: 0;
            min-width: 211px;

            @media (max-width: 767px) {
              font-size: 14px;
              line-height: 18px;
            }

            @include media-breakpoint(sm) {
              min-width: unset;
            }
          }

          .bttns {
            width: 68px;
            margin-left: 18px;

            button {
              @include flex-box(center, center);
              background-color: unset;
              border: 0;
              padding: 0;

              img {
                width: 100%;
              }
            }

            button:disabled {
              opacity: 0.4;
            }

            button:nth-child(even) {
              width: auto;
              position: relative;
              margin-left: 14px;

              @media (max-width: 575px) {
                margin-left: 6px;
              }
            }
          }
        }

        &__contentBody {
          padding: 15px 20px 11px 12px;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          min-height: 134px;

          @include media-breakpoint(md) {
            padding: 15px 0px 11px 0px;
          }

          .option {
            margin-right: 0 !important;

            @include media-breakpoint(md) {
              // margin: 0 auto !important;
            }
          }

          &__bodyText {
            width: 100%;

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 21.3px;
              color: white;
              margin-bottom: 12px;
              word-break: break-word;

              @media (max-width: 1023px) {
                font-size: 13px;
                line-height: 18px;
              }

              @media (max-width: 767px) {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 10px;
              }
            }

            .tool-box {
              text-align: center;
              max-width: 165px;
              margin-top: 9px;
              margin-right: 40px;

              &:last-child {
                margin-right: 0px;
              }

              .tool-icon.skeleton {
                height: 16px;

                span {
                  display: block;
                  height: 16px;
                }
              }
            }

            .option {
              width: auto;
              min-width: 75px;
              max-width: 165px;
              padding: 15px 0 0;
              text-align: center;
              margin-right: 40px;
              overflow: hidden;

              @include media-breakpoint(sm) {
                max-width: 145px;
                padding-top: 17px;
              }

              &:last-child {
                margin-right: 0;
              }

              div {
                margin: 0px;
                position: relative;
                display: inline-flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                height: 60px;

                button {
                  width: 18px;
                  height: 18px;
                  position: absolute;
                  right: -15px;
                  top: -18px;
                  border-width: 0px;
                  background-color: unset;
                  padding: 0;

                  img {
                    margin: 0 auto;
                  }
                }
              }

              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: white;
                margin-top: 5px;
                margin-bottom: 0px;
                width: 165px;
                margin-left: auto;
                margin-right: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                left: 0;
                white-space: nowrap;
                position: relative;
                transition: left 1s linear, width 1s linear;

                @media (max-width: 575px) {
                  font-size: 14px;
                  line-height: 16px;
                }
              }

              img {
                width: 100%;
                height: auto;
              }
            }
          }

          .addToolBox {
          }
        }

        .tools-body {
          @media (max-width: 375px) {
            flex-direction: column-reverse;
          }

          .tools-section-responsive {
            gap: 10px 0px;

            @include media-breakpoint(sm) {
              gap: 10px 5px;
            }
          }
        }
      }

      // &__boxes:nth-child(2) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(3) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(6) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(7) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(10) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(11) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(14) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(15) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(18) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(19) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(22) {
      //     background-color: $theme-bg-color-lighter;
      // }
      // &__boxes:nth-child(23) {
      //     background-color: $theme-bg-color-lighter;
      // }

      .left-corner {
        border-radius: 0px 0px 0px 8px;

        @media (max-width: 767px) {
          border-radius: 0px;
        }
      }

      .right-corner {
        border-radius: 0px 0px 8px 0px;

        @media (max-width: 767px) {
          border-radius: 0px 0px 8px 8px;
        }
      }
    }

    &__adminResources {
      // background-color: #537ab7;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0px 3px 12px #00000038;
      min-height: 487px;

      &__tabs {
        display: flex;
        @include media-breakpoint(lg) {
          flex-direction: column;
        }

        &-left {
          width: 165px;
          border-right: 1px solid $theme-border-color;
          padding: 40px 32px;

          @include media-breakpoint(xl) {
            width: 141px;
            padding: 40px 20px;
          }

          @include media-breakpoint(lg) {
            width: 100%;
            border-right: none;
            padding: 16px;
            padding-bottom: 0;
          }
        }

        &-leftContent {
          display: flex;
          flex-direction: column;
          height: 100%;

          @include media-breakpoint(xl) {
            justify-content: space-between;
          }

          @include media-breakpoint(lg) {
            flex-direction: row;
            justify-content: center;
          }

          img {
            margin-bottom: 8px;

            @include media-breakpoint(lg) {
              margin-bottom: 0;
              margin-right: 10px;
            }

            @include media-breakpoint(md) {
              max-width: 20px;
              height: auto;
              margin-right: 8px;
            }
          }
        }

        &-leftLink {
          @include flexcenter-both;
          flex-direction: column;
          text-decoration: none;
          color: $color-white;
          text-align: center;
          width: 100px;
          height: 100px;
          border-radius: $border-radius-md;
          padding: 5px;
          transition: all 0.4s;

          @include media-breakpoint(lg) {
            width: 136px;
            height: auto;
            flex-direction: row;
            padding: 12px 16px;
            margin: 0 8px;
          }

          @include media-breakpoint(md) {
            width: 106px;
            font-size: 14px;
            padding: 8px;
            margin: 0 5px;
          }

          &:hover:not(&.active) {
            background-color: $theme-bg-color-dark;

            @include media-breakpoint(xl) {
              background-color: transparent;
            }
          }

          &:not(:last-child) {
            margin-bottom: 28px;

            @include media-breakpoint(lg) {
              margin-bottom: 0;
            }
          }

          &:hover {
            color: darken($color-white, 10%);
          }

          &.active {
            background-color: $theme-bg-color-dark;

            &:hover {
              //   background-color: darken($theme-bg-color-dark, 5%);
              background-color: $theme-bg-color-dark;
            }
          }

          p {
            margin-bottom: 0;
          }
        }

        &-container {
          position: relative;
          flex: 1;
          padding: 0px;

          @include media-breakpoint(md) {
            padding: 0 15px;
          }

          @include media-breakpoint(sm) {
            padding: 0 12px;
          }
        }

        &-header {
          display: flex;
          justify-content: center;
          padding: 0px 0 18px 0;
          align-items: flex-end;
          border-bottom: 1px solid $theme-border-color;
        }

        .resources-content {
          padding-left: 28px;

          @media (max-width: 991px) {
            display: block;
          }

          &__tabs-buttons button {
            font-size: 18px;
          }

          .grade-wrapper {
            right: 22px;
            float: right;
            margin-top: 0px;
          }
        }

        &-inner {
          display: flex;
          flex-direction: column;
          min-height: 280px;
          height: 100%;
        }

        &-buttons {
          display: flex;
          border: none;
          margin: 30px auto 0;
          .nav-link {
            border: 1px solid transparent;
            border-radius: 30px;
            margin: 0 7px;
            transition: all 0.3s;

            &:hover {
              background-color: transparent;
              border-color: transparent;
              color: $color-white;

              @include desktop-up {
                background-color: $theme-bg-color-dark;
                border-color: $theme-border-color;
                color: $color-white;
              }
            }

            &.active {
              background-color: $theme-bg-color-dark;
              border-color: $theme-border-color;
              color: $color-white;
            }
          }
        }

        .search-box {
          margin-bottom: 10px;
        }

        &-contentRow {
          display: flex;
          font-size: 16px;
          color: $color-white;
          border-radius: $border-radius-md;

          &:nth-child(odd) {
            background-color: #003584;
          }

          @media (max-width: 768px) {
            width: 768px;
          }
        }

        &-contentPdf {
          display: flex;
          align-items: center;
          width: 286px;
          padding: 7px 26px;
          cursor: pointer;

          @include media-breakpoint(md) {
            width: 200px;
            padding: 8px 15px;
          }

          @include media-breakpoint(sm) {
            padding: 8px;
          }

          img {
            display: block;
            max-width: 34px;
            height: auto;
            margin-right: 15px;

            @include media-breakpoint(md) {
              margin-right: 10px;
            }

            @include media-breakpoint(sm) {
              max-width: 24px;
              margin-right: 6px;
            }
          }

          p {
            font-weight: 700;
            @include ellipsis;
            margin-bottom: 0;
          }
        }

        &-contentText {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 8px 20px;

          @include media-breakpoint(md) {
            padding: 8px 15px;
          }

          @include media-breakpoint(sm) {
            padding: 8px;
          }

          p {
            margin-bottom: 0;
          }
        }

        .dark-background {
          background-color: $color-blue;
        }

        .light-background {
          background-color: rgba($color-blue-300, 0.46);
        }

        .disabled-resources {
          pointer-events: none;
          opacity: 0.38;
        }

        .permission {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 56px;
          &.tab-permission {
            padding-top: 0;
            top: 48px;
          }
          &-text {
            background-color: rgba($color-black, 0.46);
            width: 524px;
            height: 101px;
            border-radius: $border-radius-md;
            padding: 20px 36px;
            padding-bottom: 21px;

            p {
              text-align: center;
              font-size: 16px;
              line-height: 20px;
              font-weight: bold;
              text-shadow: 0px 3px 6px rgba($color-black, 0.56);
              margin-bottom: 0;
            }
          }
        }

        &-content {
          height: 548px;
          overflow-y: auto;
          scrollbar-width: 12px;
          scrollbar-color: rgba($color-black, 0.23);

          @include media-breakpoint(lg) {
            scrollbar-width: 8px;
          }

          @include media-breakpoint(md) {
            scrollbar-width: 6px;
          }

          &::-webkit-scrollbar-track {
            background-color: rgba($color-black, 0.12);
          }

          &::-webkit-scrollbar {
            width: 12px;

            @include media-breakpoint(lg) {
              width: 8px;
            }

            @include media-breakpoint(md) {
              width: 6px;
            }
          }

          &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 10px;
            background-color: rgba($color-black, 0.23);
            border: 2px solid rgba($color-blue-100, 0.4);

            @include media-breakpoint(lg) {
              width: 6px;
              border: 1px solid rgba($color-blue-100, 0.4);
            }

            @include media-breakpoint(md) {
              width: 4px;
            }
          }
        }

        &-empty {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 227px;
          border-radius: 0px 0px 8px 8px;

          p {
            font-weight: bold;
            text-shadow: $text-shadow;
            margin-bottom: 0;
          }
        }

        &-footer {
          margin-top: auto;
          padding: 12px 0;

          p {
            text-align: center;
            font-size: 16px;
            color: $color-gray-300;
            line-height: 22px;
            margin-bottom: 0;
          }
        }
      }

      &__header {
        padding: 25px 25px 15px 25px;
        border-bottom: 1px solid $theme-border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media (max-width: 1024px) {
          justify-content: space-between;
        }

        @media (max-width: 991px) {
          padding-left: 15px;
          padding-right: 15px;
        }

        @media (max-width: 767px) {
          display: block;
          padding: 12px 20px;
        }

        @media (max-width: 575px) {
          display: block;
          text-align: right;
        }

        &__tabs {
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 575px) {
            justify-content: flex-start;
            flex-wrap: wrap;
          }

          button {
            padding: 4.5px 13.2px;
            border-radius: 40px;
            border: 0px;
            font-size: 18px;
            font-weight: 400;
            color: white;
            margin-right: 6px;
            background-color: unset;

            @media (max-width: 1024px) {
              font-size: 15px;
            }

            @media (max-width: 767px) {
              padding: 3px 10px;
              margin-right: 4px;
              margin-top: 3px;
              margin-bottom: 3px;
            }
          }

          .active {
            background-color: #194792;
          }
        }

        &__addResourceBtn {
          padding: 4.5px 18px;
          background-color: unset;
          border: 1px solid white;
          border-radius: $border-radius-md;
          position: absolute;
          right: 25px;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: white;

          @media (max-width: 991px) {
            right: 12px;
          }

          @media (max-width: 767px) {
            position: unset;
            padding: 3px 16px;
            font-size: 14px;
            line-height: 18px;
            width: 122px;
            margin-left: auto;
            margin-top: 8px;
            display: block;
          }

          @media (max-width: 575px) {
            margin-top: 12px;
          }
        }
      }

      &__booksContent {
        // padding-bottom: 91px;
        overflow: auto;

        table {
          width: 100%;

          @media (max-width: 767px) {
            width: 750px;
          }

          td {
            // background-color: #537ab7;
          }

          .pdf {
            padding: 6px 30px;
            padding-right: 15px;
            // background-color: #4c72ae;
            width: 293px;
            min-width: 293px;

            @media (max-width: 767px) {
              padding: 6px 20px;
            }

            span {
              width: 36px;
              height: 44px;

              img, svg {
                width: 100%;
                height: 100%;
              }
            }

            p {
              width: 100%;
              font-size: 16px;
              font-weight: 700;
              line-height: 22px;
              color: white;
              margin-bottom: 0;
              padding-left: 15px;
            }
          }

          .pdfDesc {
            width: 75%;
            min-width: 75%;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 16px;
            font-weight: 400;
            vertical-align: middle;
            color: white;
            // background-color: #4E75B1;
            overflow: hidden;
            p {
              // height: 10px;
              margin-bottom: 0;

              @media (max-width: 991px) {
                font-size: 14px;
                line-height: 20px;
                padding-right: 10px;
              }
            }
          }

          // .pdfActions {
          //     background-color: #4E75B1;
          // }

          .contentRow {
            font-size: 16px;
            color: $color-white;
            border-radius: $border-radius-md;

            &:nth-child(odd) {
              background-color: $theme-bg-color-darker;
            }

            @media (max-width: 768px) {
              width: 768px;
            }
          }

          // .pdfActionsColumn {
          // background-color: transparent;
          // }

          td {
            button {
              background-color: unset;
              border: 0px;
              padding: 0;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .editBtn {
              width: 21px;
              height: 21px;
              margin-right: 20px;
              margin-top: 4px;
            }

            .delBtn {
              width: 17px;
              height: 21px;
              margin-right: 16px;
              margin-top: 4px;
            }

            .dots {
              width: 18px;
              height: 36px;
              margin-right: 9px;
              margin-left: 25px;

              @media (max-width: 767px) {
                margin-left: 0px;
              }
            }
          }
          .resources__empty {
            background-color: $theme-bg-color-medium;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.52);
            margin-bottom: 0;
            border-radius: $border-radius-md;
          }
        }
      }
    }

    &__goals {
      // background-color: $theme-bg-color-medium;
      border-radius: 0px 0px 8px 8px;
      box-shadow: 0px 3px 12px #00000038;

      @media (max-width: 767px) {
        display: block;
      }

      &__goalsBody {
        padding-bottom: 33px;

        @media (max-width: 767px) {
          width: 100%;
        }

        .date {
          background-color: $theme-bg-color-dark-variant3;
          padding: 4px 18px;

          p {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            color: white;
            margin-bottom: 0px;

            @media (max-width: 575px) {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }

        .elements {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: white;
          padding: 8px 18px;

          @media (max-width: 575px) {
            font-size: 12px;
            padding-right: 10px;
          }
        }

        .elements:nth-child(even) {
          background-color: $theme-bg-color-lighter;
        }

        .elements input {
          display: none;
          /* Hide the default checkbox */
        }

        /* Style the artificial checkbox */
        .elements .checkbox {
          height: 21px;
          width: 21px;
          border: 2px solid white;
          display: inline-block;
          position: relative;
          margin-right: 10px;
        }

        /* Style its checked state...with a ticked icon */
        .elements [type="checkbox"]:checked + span:before {
          content: url("../../img/check-icon.svg");
          position: absolute;
          top: -3px;
          left: 0px;
          color: #27c1c9;
          font-size: 27px;

          @media (max-width: 575px) {
            top: -4px;
            left: 1px;
            font-size: 26px;
          }
        }
      }
    }
  }
}

.roundedBorder {
  border-radius: 0 0 8px 8px;
  min-height: 487px;
}

.addToolModal {
  .modal {
    &-content {
      border-radius: 10.5px;
    }

    &__header {
      padding: 0px;
    }

    &__header-title {
      line-height: 25px;
    }

    &__body {
      padding: 16px 5px;

      .body-icon {
        padding-right: 50px;

        p {
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          color: white;
          margin-right: 20px;
          margin-bottom: 0px;
        }

        .toolImg {
          width: 75px;
          text-align: center;
          padding-top: 40px;

          img {
            margin: 0 auto;
          }
        }
      }

      .change-icon {
        padding: 7px 15px;
        background-color: #46afb8;
        font-size: 16px;
        font-weight: 400;
        color: white;
        border-radius: $border-radius-md;
        display: block;
        margin-top: 10px;
      }

      .modal-form {
        .dropDown {
          position: relative;
          margin-top: 23px;

          select {
            -webkit-appearance: none;
            border: 0;
            height: 42px;
            padding: 8px 14px 8px 21px;
            border-radius: $border-radius-md;
          }
          select:focus-visible {
            border: 1px solid blue;

          }

          select:focus {
            outline: none;
          }
         
          img {
            position: absolute;
            top: 17px;
            right: 19px;
            width: 22px;
          }
        }
      }

      .dragIcon {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 0px;
        margin-top: 16px;
      }
    }
  }

  .tabs-switch {
    margin: 20px auto 2px auto;
    width: 226px;
  }

  .orange-btn {
    background-color: #c44538;
    font-size: 16px;
    font-weight: 400;
    color: white;
    padding: 4px 26px;
  }

  .add-btn {
    padding: 7px 33px;
  }

  .grades-toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 15px 7px;

    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      margin-right: 8px;
      margin-bottom: 0px;
    }

    .switch {
      width: 31px;
      height: 18px;

      .switch-slider::before {
        height: 16px;
        left: 1px;
        top: 1px;
        width: 16px;
      }
    }

    .mark-switch .switch-checkbox:checked + .switch-slider:before {
      transform: translateX(12px);
    }
  }
}

.changeIconModal {
  .modal__header {
    padding: 0px 9px;
    display: block;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      margin-top: 7px;
    }
  }

  .modal__body {
    padding: 4px 32px 20px 32px;

    .toolImgs {
      width: 91px;
      height: 91px;
      text-align: center;
      border-radius: $border-radius-md;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: 0 auto;
      }
    }

    .toolImgs.selected {
      background-color: #537ab7;
    }
  }

  .orange-btn {
    background-color: #c44538;
    font-size: 16px;
    font-weight: 400;
    color: white;
    padding: 5px 26px;
  }

  .add-btn {
    padding: 7px 33px;
  }
}

.empty-pdAdmin {
  background-color: #0000001a;
  width: 100%;
  height: 487px !important;
}

.discussions-admin {
  flex: 1;

  .frame_setting {
    width: 100%;
    height: 800px;
    border: none;
  }
}

.goals-card-data {
  // background-color: #537ab7;

  .adminProfessional__container__goals {
    box-shadow: unset;

    .adminProfessional__container__goals__goalsBody {
      padding-bottom: 0;
      position: relative;

      .date {
        padding: 4px 19px;
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;

        p {
          line-height: 22px;
        }
      }

      &:first-child {

        .date {
          border-radius: 0;
        }
      }

      .goals-body-scroll {
        padding-left: 0px;
        padding-top: 0px;
        width: 100%;
        // max-width: 375px;

        .availibility {
          margin-bottom: 7px;

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #424242;
            background-color: #b9b9b9;
            border-radius: 8px 8px 0px 0px;
            margin-bottom: 0;
            padding: 0 12px;
          }

          .status {
            height: 47px;
            display: flex;
            align-items: center;
            padding: 5px 20px 5px 13px;
            background-color: $theme-bg-color-lighter;
            border-radius: 0px 0px 8px 8px;
            margin: 0px;

            p {
              font-size: 15px;
              font-weight: 400;
              line-height: 18px;
              text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              color: white;
              background-color: unset;
              border-radius: 0px;
              margin-left: 10px;
              padding: 0;
              width: calc(100% - 25px);
            }

            .goals-modal-checkbox {
              input {
                display: none;
              }
            }

            .goals-modal-checkbox .checkbox {
              height: 25px;
              width: 25px;
              border-radius: 50%;
              background-color: #c9c9c9;
              display: inline-block;
              position: relative;
              filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.29));
            }

            /* Style its checked state...with a ticked icon */
            .goals-modal-checkbox [type="checkbox"]:checked + span:before {
              // content: "\2714";
              content: url("../../img/check-white.png");
              position: unset;
              top: -3px;
              left: 0px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 3px;

              @-moz-document url-prefix() {
                position: relative;
                top: 3px;
              }
            }

            .goals-modal-checkbox [type="checkbox"]:checked + .checkbox {
              background-color: #46afb8;
            }
          }
        }

        .goals-sec {

          .cardsContainer {

            .splide {

              .goals-card-pagination {
                position: unset;
                margin-bottom: 19px;
              }

                .splide__pagination {

                  li {
                    width: 16px;
                    height: 16px;
                  }

                  .goals-pagination-dot {
                    width: 16px;
                    height: 16px;
                    margin: 0;
                    background-color: #707070;
                    opacity: 1;
              
                    &.is-active {
                      background-color: #ffe000;
                    }
                  }
              
                  .goals-pagination-dot.is-active {
                    transform: unset !important;
                  }
                }
            }

            .goalsCard {

              .completed-date-label {
                font-size: 14px;
                line-height: 19px;
                text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
                height: 23px;
                background-color: #306fdc;
                padding: 2px 12px 1px;
                border-radius: $border-radius-md;
                display: inline-block;
                margin-left: 17px;
                margin-bottom: 7px;

                p {
                  color: #fff;
                  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
                  font-size: $sm;
                  font-style: normal;
                  font-weight: 400;
                  margin-bottom: 0;
                }
              }

              .card-body-container {
                height: calc(100% - 64px);
              }
            }
          }
        }
      }

      .goals-body-scroll::-webkit-scrollbar {
        display: none;
      }

      .gradient-scroll::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0px;
        width: 100%;
        max-width: 357px;
        height: 50px;
        background: linear-gradient(
          180deg,
          rgba(217, 217, 217, 0) 0%,
          #d9d9d9 105%
        );
        opacity: 0.2;
        backdrop-filter: blur(1px);
        pointer-events: none;
      }
    }

    .adminProfessional__container__goals__goalsBody:nth-child(odd) {
      // border-right: 1px solid $theme-bg-color-dark;
    }
  }
}
