.loading-skeleton {
    width: 100%;
    height: 47px;
    background-color: #a4a4a4;
    border-radius: $border-radius-md;
    margin-bottom: 7px;
    position: relative;

    span {
        background: 
        linear-gradient(0.25turn, transparent, #c4c4c4, transparent);
        background-repeat: no-repeat;
        background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
        animation: loading 1.5s infinite;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: $border-radius-md;
    }
}   

@keyframes loading {  
    from {
        background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
    }
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}